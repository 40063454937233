<template>
    <!-- v-b-tooltip.hover="link"  -->
    <div class="row justify-content-center aling-items-center base" :class="{'disabled-opacity': disabled}">

        <!-- Link -->
        <div class="col-9 col-md-9 d-none link-area">
            <a class="link-element cell-height d-flex align-items-center overflow-text rem-size-2" href="#" target="_blank"
                rel="noopener noreferrer"></a>
        </div>
        <!-- edit button -->
        <div class="col-3 col-md-3 d-flex align-items-center link-cursor justify-content-center" @click="showDropdownLink">
            <!-- Dropdown -->
            <b-dropdown lazy :disabled="disabled" variant="btn-edit text-decoration-none fs-6 text-secondary p-0"
                menu-class="dropdown-link-menu" @hide="setZIndex" @show="setFormValue($event, true)" ref="dropdownlink">
                <template #button-content>
                    <span class="link-icon-container"></span>
                </template>
                <b-dropdown-form style="width: 20vw" @submit.prevent="save" @keypress.enter.stop.prevent>
                    <h5 class="mt-1 mb-2">Link</h5>
                    <b-form-group label="Digite a URL" label-for="dropdown-form-link" class="mb-3" @submit.stop.prevent>
                        <b-form-input id="dropdown-form-link" class="form-input" placeholder="http://www..." debounce="200"
                            @keydown.enter="tabToLinkLabel"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Texto do link" label-for="dropdown-form-label" class="mb-3" @submit.stop.prevent>
                        <b-form-input id="dropdown-form-link-label" ref="dropdown-form-link-label" class="form-input"
                            placeholder="Nome do Link" debounce="200" @keydown.enter="$event.target.submit()"
                            ></b-form-input>
                    </b-form-group>
                    <div class="mb-3 row" @submit.stop.prevent>
                        <div class="col-8">
                            <b-button type="submit" variant="success" size="sm">Salvar</b-button>
                        </div>
                        <div class="col-4">
                            <b-button type="submit" variant="info" size="sm" @click.prevent="reset">Limpar</b-button>
                        </div>
                    </div>

                </b-dropdown-form>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
import showAlert from "../../alerts";
import axios from 'axios';
import { mapActions } from "vuex";
export default {
    data() {
        return {
            tempValue: '',
            link: '',
            linkLabel: '',
            haveLink: false,
            dropdownLinkShow: false,
        }
    },
    props: {
        disabled: {
            default: false,
        },
        column: {
            default: {},
        },
        columnValue: {
            default: {},
        },
        task: {
            default: {},
        },
        group: {
            default: {},
        },
        linkValue: {
            default: ''
        },
        linkLabelValue: {
            default: ''
        },
        disabled: {
            default: false
        }
    },
    computed: {
        required() {
            return this.column.required;
        }
    },
    methods: {
        ...mapActions('columnValues', ['updateColumnValue']),
        ...mapActions('tasks', ['updateTaskColumnValue']),
        setLink(event) {
            this.tempValue = this.link;
            this.setZIndex(event)
            // this.save();
        },
        showDropdownLink(save) {
            this.dropdownLinkShow = !this.dropdownLinkShow;
            if (!!this.dropdownLinkShow) {
                this.$refs.dropdownlink.show();
            } else {
                this.$refs.dropdownlink.show();
            }
        },
        tabToLinkLabel(event) {
            this.$refs['dropdown-form-link-label'].focus();
        },
        setIconLinkEdit(){
            let container = this.$el.querySelector('.link-icon-container')
            let element = document.createElement('i');
            element.classList.add('fas');
            element.classList.add('fa-pencil-alt');
            container.innerHTML = '';
            container.appendChild(element)
        },
        setIconLinkNew(){
            let container = this.$el.querySelector('.link-icon-container')
            let element = document.createElement('i');
            element.classList.add('mdi');
            element.classList.add('mdi-link');
            element.classList.add('fs-4');
            container.innerHTML = '';
            container.appendChild(element)
        },
        setFormValue(event) {
            this.setZIndex(event, true);
            setTimeout(()=>{
                let inputLink = event.target.querySelector('input#dropdown-form-link');
                let inputLabel = event.target.querySelector('input#dropdown-form-link-label');
                let linkElement = this.$el.querySelector('.link-element');
                let link = '';
                let linkLabel = '';

                if(linkElement) {
                    link = linkElement.getAttribute('href');
                    linkLabel = linkElement.textContent;
                }

                if(inputLabel) {
                    inputLabel.value = linkLabel;
                }

                if(inputLink) {
                    inputLink.value = link;
                }

                if (this.column && Object.keys(this.column) > 0) {
                    this.required = this.column.required;
                }
            }, 500);
            return

        },
        async save(event) {
            let inputLink = event.target.querySelector('input#dropdown-form-link');
            let inputLabel = event.target.querySelector('input#dropdown-form-link-label');
            let link = '';
            let label = '';

            if(inputLink) {
                link = inputLink.value;
            }

            if(inputLabel) {
                label = inputLabel.value;
            }

            let data = {
                group_id: this.task.workflow_group_id,
                task_id: this.task.id,
                column_type_id: this.column.workflow_column_type_id,
                column_value_id: this.columnValue.id,
                value: link,
                description: label,
            }

            let validate = this.validator(data, this.required);
            if (!validate) {
                return
            }

            let haveLink = !!label;
            let linkElement = this.$el.querySelector('.link-element');
            let linkArea = this.$el.querySelector('.link-area');

            if(linkElement) {
                linkElement.setAttribute('href', link);
                linkElement.textContent = label;
            }

            if(linkArea && haveLink) {
                this.setIconLinkEdit();
                linkArea.classList.remove('d-none')
            }

            this.hideDropdown();

            let response = await this.updateColumnValue(data);
            if (!response) {
                showAlert(true, 'Erro ao salvar');
                return;
            } else {
                this.updateTaskColumnValue(data);
            }
        },
        async reset() {
            let inputLink = event.target.querySelector('input#dropdown-form-link');
            let inputLabel = event.target.querySelector('input#dropdown-form-link-label');
            let linkElement = this.$el.querySelector('.link-element');
            let linkArea = this.$el.querySelector('.link-area');
            let linkTemp = '';
            let labelTemp = '';

            if(inputLink) {
                linkTemp = inputLink.value;
            }

            if(inputLabel) {
                labelTemp = inputLabel.value;
            }

            if(linkElement) {
                linkElement.setAttribute('href', '#');
                linkElement.textContent = '';
            }

            if(linkArea) {
                linkArea.classList.add('d-none');
            }

            this.setIconLinkNew();
            this.hideDropdown();

            let data = {
                column_value_id: this.columnValue.id,
                value: '',
                description: '',
            }

            let response = await this.updateColumnValue(data);
            if (!response) {
                showAlert(true, 'Erro ao Limpar');
                inputLink.value = linkTemp;
                inputLabel.value = labelTemp;

                linkElement.setAttribute('href', linkTemp);
                linkElement.textContent = labelTemp;
                linkArea.classList.remove('d-none');
                this.setIconLinkEdit();
                return;
            }


        },
        validator(data, required) {
            if (!required) return true;

            let errorCount = 0;
            let element = null;
            let errorElement = null;

            element = window.document.body.querySelector(`#required_item_${this.column.id}_${this.group.id}_${this.columnValue.id}`);

            if ((data.value === '' || data.value === null || data.value === undefined)) {
                errorCount++;
                if (element)
                    element.closest('.validate').classList.add('invalid')
            } else {
                if (element)
                    element.closest('.validate').classList.remove('invalid')
            }

            if (errorCount === 1) {
                errorElement = element;
            }

            if (errorElement) {
                errorElement.focus();
                this.$scrollTo(errorElement, 1000, {
                    container: '.container-task-group .simplebar-wrapper .simplebar-mask .simplebar-offset .simplebar-content-wrapper',
                    x: true,
                    y: false,
                    offset: -400,
                });
            }

            showAlert(true, `O campo "${this.column.name}" é obrigatório`, 2000);

            this.link = this.tempValue;

            setTimeout(()=>{
                if(this.link !== '' || this.link !== null || this.link !== undefined){
                    element.closest('.validate').classList.remove('invalid');
                }
            }, 3000);
            return errorCount == 0;
        },
        hideDropdown(){
            setTimeout(()=>{
                this.$refs.dropdownlink.hide(true)
            }, 200);
        },
    },

    created() {
    },
    mounted() {
        let haveLink = !!this.columnValue.description && !!this.columnValue.value;
        let linkElement = this.$el.querySelector('.link-element');
        let linkArea = this.$el.querySelector('.link-area');

        if(linkElement) {
            let link = this.columnValue.value ? this.columnValue.value : '';
            let linkLabel = this.columnValue.description ? this.columnValue.description : '';
            linkElement.setAttribute('href', link);
            linkElement.textContent = linkLabel;
            if(this.required) {
                linkElement.setAttribute('required', true)
            }
        }

        if(linkArea && haveLink) {
            linkArea.classList.remove('d-none')
        }

        if(haveLink) {
            this.setIconLinkEdit();
        } else {
            this.setIconLinkNew();
        }
    }
}
</script>
<style scoped>
.show-on-hover {
    display: none;
}

.show-on-hover:hover {
    display: block;
}

.overflow-text {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
}
</style>
<style>
.dropdown-link-menu {
    top: -53px !important;
    /* bottom: 30px !important; */
    right: 0px !important;
    left: -38vh !important;
}
</style>
