import axios from "axios";

export const state = {
    // data
    usersList: [],
    collaboratorsList: [],
    responsiblesList: [],
    searchResponsiblesList: [],
    searchCollaboratorsList: [],

    // load
    loadUsersList: false,
    loadCollaboratorsList: false,
    loadResponsiblesList: false,
    loadSearchResponsiblesList: false,
    loadSearchCollaboratorsList: false,
}

export const mutations = {
    // data
    CHANGE_WORKFLOW_USERS(state, data) {
        state.usersList = data;
    },
    CHANGE_WORKFLOW_COLLABORATORS(state, data) {
        state.collaboratorsList = data;
    },
    CHANGE_WORKFLOW_RESPONSIBLES(state, data) {
        state.responsiblesList = data;
    },
    CHANGE_SEARCH_RESPONSIBLES(state, data) {
        state.searchResponsiblesList = data;
    },
    CHANGE_SEARCH_COLLABORATORS(state, data) {
        state.searchCollaboratorsList = data;
    },

    ADD_WORKFLOW_COLLABORATOR(state, data) {
        state.searchCollaboratorsList = state.searchCollaboratorsList.filter((collaborator)=> collaborator.id != data.id);
        state.collaboratorsList = state.collaboratorsList.filter((collaborator)=> collaborator.id != data.id);
        state.searchCollaboratorsList.push(data);
        state.collaboratorsList.push(data);
    },
    REMOVE_WORKFLOW_COLLABORATOR(state, data) {
        state.searchCollaboratorsList = state.searchCollaboratorsList.filter((collaborator)=> collaborator.id != data.id);
        state.collaboratorsList = state.collaboratorsList.filter((collaborator)=> collaborator.id != data.id);
    },

    // load
    LOAD_WORKFLOW_USERS(state, data) {
        state.loadUsersList = data;
    },
    LOAD_WORKFLOW_COLLABORATORS(state, data) {
        state.loadCollaboratorsList = data;
    },
    LOAD_WORKFLOW_RESPONSIBLES(state, data) {
        state.loadResponsiblesList = data;
    },
    LOAD_SEARCH_RESPONSIBLES(state, data) {
        state.loadSearchResponsiblesList = data;
    },
    LOAD_SEARCH_COLLABORATORS(state, data) {
        state.loadSearchCollaboratorsList = data;
    },

}

export const actions = {

    getUsersList({commit}, workflowId) {

        axios.post(window.location.origin + "/api/v1/workflow/search/users/", {workflow_id: workflowId, search: ''})
        .then((response) => {
          commit('CHANGE_WORKFLOW_USERS', response.data.data)
        })
        .catch((error) => {
            commit('CHANGE_WORKFLOW_USERS', []);
        });
    },

    getResponsibleList({commit}, workflowId) {

        if(workflowId != 'undefined' && workflowId !== undefined) {
            axios.get(window.location.origin + "/api/v1/workflow/task/responsibles/" + workflowId)
            .then((response) => {
              commit('CHANGE_WORKFLOW_RESPONSIBLES', response.data.data)
            })
            .catch((error) => {
                commit('CHANGE_WORKFLOW_RESPONSIBLES', []);
            });
        }

    },
    /**
     *
     * @param {*} vuex
     * @param {*} data {search, workflow_id}
     */
    async getSearchResponsibles({ commit }, {search, workflowId}) {
      commit('LOAD_SEARCH_RESPONSIBLES', true)
      let response = await axios.get(`${window.location.origin}/api/v1/workflow/task/search/responsibles/${workflowId}/${search}`)
      commit('CHANGE_SEARCH_RESPONSIBLES', response.data.data);
      commit('LOAD_SEARCH_RESPONSIBLES', false)
    },

    /**
     *
     * @param {*} vuex
     * @param {*} data {search, workflow_id}
     */
    async getSearchCollaborators({ commit }, {search, workflowId, taskId}) {
        try {
            commit('LOAD_SEARCH_COLLABORATORS', true)

            let response = await axios.get(`${window.location.origin}/api/v1/workflow/task/search/collaborators/${workflowId}/${taskId}/${search}`)
            if(!response.data.error)
              commit('CHANGE_SEARCH_COLLABORATORS', response.data.data);
            // commit('CHANGE_SEARCH_COLLABORATORS', []);

            commit('LOAD_SEARCH_COLLABORATORS', false)
        } catch(error) {
            console.log(error);
            commit('LOAD_SEARCH_COLLABORATORS', false)
        }
    },

    async getCollaboratorList({commit}, workflowId) {

        if(workflowId != 'undefined' && workflowId !== undefined) {
            await axios.get(window.location.origin + "/api/v1/workflow/task/collaborators/all/" + workflowId)
            .then((response) => {
              commit('CHANGE_WORKFLOW_COLLABORATORS', response.data.data)
            })
            .catch((error) => {
                commit('CHANGE_WORKFLOW_COLLABORATORS', [])
            });
        }

    },


    async saveWorkflowTaskCollaborator({ commit, state }, {taskId, userId}) {
      try {
        // save collaborator into api
        var data = { task_id: taskId, user_id: userId }
        let response = await axios.post(`${window.location.origin}/api/v1/workflow/task/collaborator`, data)
        if(!response.data.error) {
            commit('ADD_WORKFLOW_COLLABORATOR', response.data.data);
        }
        return true;
      } catch (error) {
        console.error('erro ao salvar', error)
        commit('REMOVE_WORKFLOW_COLLABORATOR', userId);
        return false;
      }

    },

    async removeWorkflowTaskCollaborator({ commit },  {taskId, user}) {
      try {

        // save collaborator into api
        let response = await axios.delete(`${window.location.origin}/api/v1/workflow/task/collaborator/${taskId}/${user.user_id}`)
        if(!response.data.error) {
            commit('REMOVE_WORKFLOW_COLLABORATOR', user.id);
        }

        return true;
      } catch (error) {
        console.log('erro ao remover', error);
        commit('ADD_WORKFLOW_COLLABORATOR', user);
        return false;
      }

    },

}
