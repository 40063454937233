<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import ContentBox from "../../../components/content-box";
import axios from "axios";
import Multiselect from "vue-multiselect";
import showAlert from "../../../components/alerts";
import config from "../../../config/config";
import bxIcons from "../../../config/bxIconsClasses";
import draggable from "vuedraggable";
import {
    required,
    minLength
} from "vuelidate/lib/validators";

export default {
    components: {
        Multiselect,
        VueFormWizard: FormWizard,
        TabContent,
        draggable,
        ContentBox
    },
    props: {
        workflowId: {
            type: Number,
        },
        fastMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            bxIconsClasses: bxIcons,
            showShortcutContainer: false,
            user: null,
            submitted: false,
            editMode: false,
            shortcuts: [],
            checklists: [],
            createdBy: '',
            shortcutOptions: [],
            saveChecklistDisabled: false,
            showOptions: false,
            drag: false,
            selectedShortcut: {
                name: '', id: null,
                options: [],
                defaultOption: true
            },
            shortcut: {
                id: null,
                name: '',
                message: '',
                additional_time: '',
                operator: true,
                icon_class: '',
                icon_color: '',
                file: null
            },
            colors:{
                'bg-secondary': {name: 'Cinza', textClass: 'text-secondary'},
                'bg-success': {name: 'Verde', textClass:'text-success'},
                'bg-info': {name: 'Azul', textClass: 'text-info'},
                'bg-warning': {name: 'Amarelo', textClass: 'text-warning'},
                'bg-danger': {name: 'Vermelho', textClass: 'text-danger'},
            },
        };
    },
    validations: {

        shortcut: {
            name: {
                required,
                minLength: minLength(2)
            },
            message: {
                minLength: minLength(2)
            },
            additional_time: {
                minLength: minLength(2)
            }
        }
    },
    methods: {
        getUser() {

            let url = window.location.origin + '/api/v1/user';
            const userData = axios.post(url, null, config.getHeaders)
                .then(response => {
                    this.user = response.data;
                });

        },
        // shortcut methods
        saveShortcut(workflowId, name, shortcutId = null) {

            if (!workflowId) {
                showAlert(false, 'Workflow não encontrado');
            }

            if (name) {
                this.saveChecklistDisabled = true;
                this.submitted = true;
                this.$v.$touch();
                if (!this.$v.$anyError) {
                    let data = {
                        workflow_id: this.workflowId,
                        workflow_shortcut_id: shortcutId,
                        name: this.shortcut.name,
                        message: this.shortcut.message,
                        additional_time: this.shortcut.additional_time,
                        file: this.shortcut.file,
                        icon_class: this.shortcut.icon_class,
                        icon_color: this.shortcut.icon_color,
                        operator: this.shortcut.operator,
                    }
                    const getStates = axios.post(window.location.origin + '/api/v1/workflow/shortcut', data)
                        .then(response => {
                            var data = response.data;
                            if (!data.error) {
                                this.editMode = true;
                                if(shortcutId){
                                    this.$root.$emit('bv::hide::modal', 'modal-shortcuts-edit')
                                } else{
                                    var shortcut = data.data;

                                    var shortcutObject = {
                                        value: shortcut,
                                        text: shortcut.name,
                                        name: shortcut.name,
                                        message: shortcut.message,
                                        additional_time: shortcut.additional_time,
                                        operator: shortcut.operator,
                                        workflowId: shortcut.workflow_id,
                                        creator: shortcut.creator,
                                        updater: shortcut.updater,
                                        created_at: shortcut.created_at,
                                        updated_at: shortcut.updated_at,
                                    }

                                    this.shortcutOptions.push(shortcutObject)
                                    this.shortcut = shortcut
                                }

                                showAlert(false, data.message);

                            }
                            this.saveChecklistDisabled = false;
                        }).catch(error => {
                            if (error.response) {
                                showAlert(true, error.response.data.message);
                            }
                            this.saveChecklistDisabled = false;
                        });
                }
            }
        },
        getShortcuts(workflowId) {
            if (!workflowId) {
                showAlert(false, 'Tarefa não selecionada');
            }

            const getShortcuts = axios.get(window.location.origin + '/api/v1/workflow/shortcut/availables/' + workflowId)
                .then(response => {
                    var data = response.data.data;
                    if (!data.error) {
                        // this.shortcuts = data;
                        this.shortcutOptions = [];
                        data.forEach((shortcut, key) => {
                            var shortcutObject = {
                                    value: shortcut,
                                    text: shortcut.name,
                                    name: shortcut.name,
                                    message: shortcut.message,
                                    workflowId: shortcut.workflow_id,
                                    creator: shortcut.creator,
                                    updater: shortcut.updater,
                                    created_at: shortcut.created_at,
                                    updated_at: shortcut.updated_at,
                                }

                            this.$set(this.shortcutOptions, key, shortcutObject);
                        });
                    }
                }).catch(error => {
                    if (error.response) {
                        showAlert(true, error.response.data.message);
                    }
                });
        },
        deleteShortcut(shortcutId) {
            if (shortcutId) {

                this.$swal
                    .fire({
                        title: "Excluir atalho " + this.shortcut.name + "?",

                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Sim, deletar!",
                        cancelButtonText: "Cancelar",
                    })
                    .then((result) => {
                        if (result.value) {
                            const getStates = axios.delete(window.location.origin + '/api/v1/workflow/shortcut/' + shortcutId)
                                .then(response => {
                                    var data = response.data;
                                    if (!data.error) {
                                        showAlert(false, data.message);
                                        this.$root.$emit('bv::hide::modal', 'modal-shortcuts')
                                    }
                                    this.getShortcuts(this.workflowId);
                                    setTimeout(() => {
                                        if (this.shortcutOptions.length > 0) {
                                            this.shortcut = this.shortcutOptions[1].value;
                                            this.selectedShortcut = this.shortcutOptions[1];
                                            this.checkOptions();
                                        } else {
                                            this.shortcut = this.shortcutOptions[0].value;
                                            this.selectedShortcut = this.shortcutOptions[0];
                                            this.checkOptions();
                                        }
                                    }, 100);
                                }).catch(error => {
                                    if (error.response) {
                                        showAlert(true, error.response.data.message);
                                    }
                                    this.getShortcuts(this.workflowId);
                                });
                        }
                    });
            } else {
                showAlert(true, 'Selecione um shortcut')
            }
        },
        // shortcut option methods
        saveChecklistOption(checklist_id, option, checked, option_id, isButtonNew = false, alert = false, index = false) {
            if (!option) {
                showAlert(true, 'digite uma opção');
                return;
            } else {
                var isUpdate = !!option_id;
                var data = { checklist_id: checklist_id, options: [{ option: option, checked: checked, checklist_option_id: option_id }] }
                const getStates = axios.post(window.location.origin + '/api/v1/workflow/columns/checklist/option', data)
                    .then(response => {
                        var data = response.data;
                        if (!data.error) {
                            if(alert){
                                showAlert(false, data.message);
                            }

                            this.$set(this.selectedShortcut.options, index, data.data[0])

                            if (isButtonNew) {
                                this.addOption();
                            }

                        }
                    }).catch(error => {
                        if (error.response) {
                            showAlert(true, error.response.data.message);
                        }
                    });
            }

        },
        deleteChecklistOption(option_id) {
            if (option_id) {
                const getStates = axios.delete(window.location.origin + '/api/v1/workflow/columns/checklist/option/' + option_id)
                    .then(response => {
                        var data = response.data;
                        if (!data.error) {
                            showAlert(false, data.message);
                        }
                    }).catch(error => {
                        if (error.response) {
                            showAlert(true, error.response.data.message);
                        }
                    });
            }

        },
        addOption: function () {
            this.selectedShortcut.options.push({ option: "", checked: false, defaultOption: true});
        },
        deleteOption: function (index, option_id) {
            this.deleteChecklistOption(option_id)
            this.selectedShortcut.options.splice(index, 1);
        },
        checkOptions: function () {
            if (this.shortcut) {
                this.showOptions = true;
                this.shortcut = this.shortcutOptions.find(e => e.id == this.shortcut);
                this.createdBy = this.selectedShortcut.creator.name;
                if (this.selectedShortcut.options.length == 0 && this.fastMode) {
                    // this.editMode = true;
                    this.addOption();
                }
            } else {
                this.showOptions = false;
                this.selectedShortcut = this.shortcutOptions[0];
                this.createdBy = '';
            }
        },

        // sequence
        updateSequence(list) {
            this.drag = false
            var data = [];

            list.forEach((el, key)=>{
                if(el.id == 'undefined' || el.id == null){
                    list.splice(key, 1);
                }
           });
            list.forEach((option, key) => {
                data.push({ option_id: option.id, sequence: key })
            });

            data = { sequence: data };

            const updateGroup = axios
                .patch(
                    window.location.origin + "/api/v1/workflow/columns/checklist/option/sequence",
                    data,
                    config.getHeaders
                )
                .then((response) => {

                    if (!response.data.error) {
                        this.getShortcuts(this.shortcutId);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response) {
                        showAlert(true, error.response.data.message);
                    }
                });
        },
        toggleEditMode(){
            this.editMode = !this.editMode;
            if(this.editMode){

                var shortcut = this.shortcutOptions[0];
                var shortcutObject = {
                    value: shortcut,
                    text: shortcut.name
                }

                this.shortcut = shortcut.value

            } else {
                this.shortcut = {};
            }
            // var len = this.selectedShortcut.options.length;
            // if(!this.editMode){
            //     this.selectedShortcut.options.forEach((el, key) =>{
            //         if(!el.id){
            //             // this.selectedShortcut.options.splice(key, 1);
            //        }
            //     });
            // }
        },
        toggleChecklistOption(shortcut, optionId, index){
            this.$refs[`checklist_option_${optionId}`][0].$el.querySelector('input').click();
            var field = this.selectedShortcut.options.find(option =>  option.id == optionId);
            setTimeout(()=>{
                this.saveChecklistOption(shortcut, field.option, field.checked, optionId, false,false, index)
            }, 200);
        },
        toggleTimeOperator(){
            this.$set(this.shortcut, 'operator', !this.shortcut.operator)
        },
        setShortcutColor(colorKey){
            this.$set(this.shortcut, 'icon_color', this.colors[`${colorKey}`].textClass);
        },
        setShortcutIcon(e){
            this.shortcut.icon_class = e.class;
            this.showShortcutContainer = false;
        },
        toggleContentBoxShortcut(){
            this.showShortcutContainer = !this.showShortcutContainer
            if(this.showShortcutContainer){
               setTimeout(()=>{
                   this.$refs['content-box-shortcut'].$el.focus();
               }, 50);
            }
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 40,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        }
    },
    mounted() {
        this.getShortcuts(this.workflowId);
        this.getUser();
    },
};
</script>
<template>
    <div class="container">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card">

                <div class="card-body pt-0">
                    <div>
                        <div class="row mb-3 mt-3">
                            <div class="col-12">
                                <!-- New -->
                                <div class="form-group row mb-3 pb-3" v-if="!editMode">
                                    <!-- <label class="col-md-2 col-12 col-form-label" for="itemName">Novo atalho</label> -->
                                    <b-form-group
                                        for="itemName"
                                        class="col-form-label "
                                        :class="{'col-md-10 col-10': shortcutOptions.length > 0, 'col-md-11 col-11': shortcutOptions.length == 0}"
                                        label="Novo atalho"
                                    >
                                        <b-input size="sm" id="itemName" type="text" class="form-control" name="itemName"
                                            placeholder="Nome"
                                            :class="{ 'is-invalid': submitted && $v.shortcut.name.$error }"
                                            v-model="shortcut.name" ></b-input>

                                        <div v-if="submitted && $v.shortcut.name.$error" class="invalid-feedback">
                                            <span v-if="!$v.shortcut.name.required">Insira um nome para o atalho</span>
                                            <span v-else-if="!$v.shortcut.name.minLength">O nome do atalho deve conter no mínimo 2 caracteres</span>
                                        </div>
                                    </b-form-group>

                                    <b-form-group
                                        for="new"
                                        class="col-form-label col-md-1 col-1 hide-label"
                                        label="_"
                                    >
                                        <b-button variant=" text-decoration-none"  size="sm"
                                            @click.prevent="saveShortcut(workflowId, shortcut.name)"
                                            :disabled="saveChecklistDisabled">
                                            <i class="fas fa-plus-square fs-5"></i>
                                        </b-button>
                                    </b-form-group>
                                    <b-form-group
                                        for="edit"
                                        class="col-form-label col-md-1 col-1 hide-label"
                                        label="_"
                                        v-if="shortcutOptions.length > 0"
                                    >
                                        <b-button id="edit" size="sm" variant=" text-decoration-none" @click.prevent="toggleEditMode()" v-if="!editMode">
                                            <i class="fas fa-pencil-alt pen-color fs-5"
                                                :class="{ 'bx-tada': !editMode , 'text-info': editMode }"></i>
                                        </b-button>


                                    </b-form-group>
                                </div>

                                <!-- Edit -->
                                <div class="row border-bottom font-size-12  mb-3 pb-3" v-if="editMode">
                                    <b-form-group
                                        for="edit_select"
                                        class="col-form-label col-md-10 col-10"
                                        label="Selecione"
                                    >
                                        <b-select size="sm" id="edit_select" v-model="shortcut"
                                            class="form-select font-size-12" :options="shortcutOptions">
                                            <template #first>
                                                <b-select-option :value="null">Selecione</b-select-option>
                                            </template>
                                        </b-select>

                                    </b-form-group>
                                    <b-form-group
                                        for="edit_delete"
                                        class="col-form-label col hide-label"
                                        label="_"
                                    >
                                        <b-button size="sm" id="edit_delete" variant=" text-decoration-none"
                                            @click.prevent="deleteShortcut(shortcut.id)">
                                            <i class="fas fa-trash-alt fs-5 trash-color"></i>
                                        </b-button>
                                    </b-form-group>
                                    <b-form-group
                                        for="edit_new"
                                        class="col-form-label col hide-label"
                                        label="_"
                                    >
                                        <b-button id="edit_new" size="sm" variant=" text-decoration-none" @click.prevent="toggleEditMode()">
                                            <i class="fas fa-plus-square fs-5 add-color"></i>
                                        </b-button>
                                    </b-form-group>
                                </div>

                                <!-- {{shortcut}} -->
                                <div  v-if="editMode">
                                        <div class="form-group row">
                                            <b-form-group
                                                for="edit_message"
                                                class="col-form-label col-md-12 col-12"
                                                label="Mensagem"
                                            >
                                                <b-input size="sm" id="edit_message" type="text" class="form-control font-size-12" name="edit_message"
                                                    placeholder="Mensagem"
                                                    :class="{ 'is-invalid': submitted && $v.shortcut.message.$error }"
                                                    v-model="shortcut.message" ></b-input>

                                                <div v-if="submitted && $v.shortcut.message.$error" class="invalid-feedback">
                                                    <span v-if="!$v.shortcut.message.required">Insira uma mensagem</span>
                                                    <span v-else-if="!$v.shortcut.message.minLength">A mensagem deve conter no mínimo 2 caracteres</span>
                                                </div>
                                            </b-form-group>
                                        </div>
                                        <div class="form-group row">
                                            <b-form-group
                                                for="edit_time"
                                                class="col-form-label col-md-11 col-11"
                                                label="Tempo"
                                            >
                                                <b-input size="sm" id="itemName" type="time" v-mask="'##:##'" class="form-control font-size-12" name="itemName"
                                                    placeholder="Nome"
                                                    :class="{ 'is-invalid': submitted && $v.shortcut.additional_time.$error }"
                                                    v-model="shortcut.additional_time"></b-input>

                                                <div v-if="submitted && $v.shortcut.additional_time.$error" class="invalid-feedback">
                                                    <span v-if="!$v.shortcut.additional_time.required">Insira um nome para o atalho</span>
                                                    <span v-else-if="!$v.shortcut.additional_time.minLength">O nome do atalho deve conter no mínimo 2 caracteres</span>
                                                </div>

                                            </b-form-group>
                                            <b-form-group
                                                for="edit_time"
                                                class="col-form-label col hide-label"
                                                label="_"
                                                v-b-tooltip.left="'Adicionar ou remover tempo'"
                                            >
                                                <b-button size="sm" class="font-size-12" variant="outline-danger" @click.prevent="toggleTimeOperator()" v-show="!shortcut.operator">-</b-button>
                                                <b-button size="sm" class="font-size-12" variant="outline-success" @click.prevent="toggleTimeOperator()" v-show="shortcut.operator">+</b-button>
                                            </b-form-group>
                                        </div>
                                        <div class="form-group row">
                                            <b-form-group
                                                for="edit_time"
                                                class="col-form-label col-md-11 col-11"
                                                label="Icone"
                                            >
                                                <transition name="jump" tag="div" class="emojis">
                                                    <ContentBox @select="setShortcutIcon" :items="bxIconsClasses.bxIcons" :searchable="false" title="Atalhos para mensagem" right="38%" v-show="showShortcutContainer" class="content-box-position" tabindex="0"  @focusout.native="showShortcutContainer = false" ref="content-box-shortcut"></ContentBox>
                                                </transition>
                                                <b-button size="sm" variant="btn btn-outline-light d-flex justify-content-around  align-items-center" @mousedown.left="toggleContentBoxShortcut"  class="btn-label">
                                                    <i class="label-icon" :class="[{'bx bx-smile ': !shortcut.icon_class}, shortcut.icon_class, shortcut.icon_color]"></i>
                                                    <span class="ps-4 font-size-12">
                                                        <span class="ps-1">Selecione um icone para o atalho</span>
                                                    </span>
                                                </b-button>
                                                <b-button @mousedown.left="toggleContentBoxShortcut" variant=" text-decoration-none border-unset  p-0 m-0"></b-button>
                                            </b-form-group>

                                            <b-form-group
                                                for="edit_time"
                                                class="col-form-label col hide-label"
                                                label="_"
                                            >
                                                <b-dropdown lazy v-model="shortcut.icon_color" size="sm" variant="text-decoration-none d-flex align-items-center justify-content-center p-0" offset="-500" dropleft menu-class="margin-end">
                                                    <template #button-content>
                                                        <div class="d-flex align-items-center">
                                                            <div
                                                                class="rounded-circle circle-color-select"
                                                                :class="[{'bg-secondary': !colors[shortcut.icon_color]}, shortcut.icon_color? shortcut.icon_color.replace('text', 'bg'): '']"
                                                                alt=""
                                                            ></div>
                                                        </div>
                                                    </template>

                                                    <b-dropdown-item v-for="(color, keyColor) in colors" :key="keyColor" @click="setShortcutColor(keyColor)">
                                                        <div class="row align-items-center">
                                                            <b-col cols="3"><div class="rounded-circle circle-color" :class="[keyColor]" > </div></b-col>
                                                            <b-col cols="9" class=" ml-3 my-auto">{{ color.name }}</b-col>
                                                        </div>
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                            </b-form-group>

                                        </div>

                                </div>
                            </div>
                            <!-- end col -->
                        </div>
                        <!-- end row -->
                    </div>
                </div>
                <span v-show="createdBy">Criado por: {{ createdBy }}</span>
                <b-button v-show="editMode" type="button" variant="outline-light mt-4" @click.prevent="saveShortcut(workflowId, shortcut.name?shortcut.name: null , shortcut.id?shortcut.id: null)">Salvar</b-button>
                <!-- end card-body -->
            </div>
            <!-- end card -->
        </div>
    </div>
</template>
<style>
.margin-end{
    left: -95px !important;
}
</style>
<style scoped>
.content-box-position{
    position: absolute;
    right: 38%;
    bottom: -45px;
    z-index: 50;
}
.circle-color {
    height: 1rem;
    width: 1rem;
}

.circle-color-select {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 20px;
}

@media (max-width: 576px) {
    .circle-color-select {

        height: 1rem;
        width: 1rem;
        font-size: 10px;
    }
}

.scr-right::-webkit-scrollbar {
    width: 6px;
}

.scr-right::-webkit-scrollbar-track {
    background: transparent;
    color: black;
    border-radius: 10px;
}

.scr-right::-webkit-scrollbar-thumb:hover {
    background: black;
    opacity: 0.6;
    border-radius: 10px;
}

.scr-right::-webkit-scrollbar-thumb {
    background: #c3cbe4;
    opacity: 0.5;
    padding-left: 5px;
    border-radius: 10px;
}

.margin-left-2px {
    margin-left: 2px;
}

.trash-color:hover {
    color: #f46a6a;
}
.add-color:hover {
    color: #34c38f;
}

.pen-color:hover {
    color: #50a5f1;
}

/* drag and drop effects */
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.3;
    background: #2a3144;
}
.hide-label legend {
    visibility: hidden;
}
</style>
