import axios from "axios";

let baseEndpoint = `${window.location.origin}/api/v1/workflow`;

export const state = {
    workflow: {id:0},
    workflows: [],
    selectedFormUuid: '',
    selectedFormGroup: null,
    workflowHeaderItems: [
        { text: "Workflow", href: "/" },
        { text: '', active: true },
    ],
    workflowsProcessing:{
        loadWorkflow: false,
        loadWorkflows: false,
    }
}

export const mutations = {

    CHANGE_WORKFLOWS(state, data) {
        state.workflows = data
    },

    CHANGE_WORKFLOW(state, data) {
        state.workflow = data
    },

    DELETE_WORKFLOW(state, id) {
        state.workflows = state.workflows.filter((workflow)=> workflow.id != id);
        if(state.workflow && state.workflow.id == id) {
            state.workflow = {};
        }
    },

    STORE_WORKFLOW(state, data){
        state.workflows.push(data);
    },

    UPDATE_WORKFLOW(state, data){

        let workflow = state.workflows.find((workflow)=> workflow.id != data.id);
        if(workflow && workflow.id == data.id) {
            let keys = Object.keys(workflow);
            keys.forEach((key)=>{
                workflow[key] = data[key];
            });
        }

        if(state.workflow && state.workflow.id == data.id) {
            state.workflow = data;
        }
    },

    CHANGE_FORM_UUID(state, data) {
      state.selectedFormUuid = data;
    },

    CHANGE_FORM_SELECTED_GROUP(state, data) {
      state.selectedFormGroup = data;
    },

    CHANGE_WORKFLOW_HEADER_ITEMS(state, data) {
      state.workflowHeaderItems = [
        { text: "Workflow", href: "/" },
        { text: data, active: true },
      ];
    },
    CHANGE_WORKFLOWS_PROCESSING(state, data) {
      state.workflowsProcessing = data;
    },
    CHANGE_WORKFLOW_PROCESSING_LOAD(state, data) {
      state.workflowsProcessing.loadWorkflow = data;
    },
    CHANGE_WORKFLOWS_PROCESSING_LOAD(state, data) {
      state.workflowsProcessing.loadWorkflow = data;
    },
}

export const actions = {

    async listWorkflows({ state, commit, dispatch }, workflowId = null) {
            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', true);
            const response = axios.get(baseEndpoint)
                .then((response)=>{
                    if(!response.data.error) {
                        commit('CHANGE_WORKFLOWS',  response.data.data)
                        if(workflowId) dispatch('selectWorkflow', workflowId)
                    }

                    commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', false);
                })
                .catch((error)=>{

                    commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', false);
                })

    },

    async findWorkflow({ state, commit }, id) {
        try {

            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', true);
            const response = await axios.get(`${baseEndpoint}/find/${id}`);
            if(!response.data.error) {
                commit('CHANGE_WORKFLOW', response.data.data)
            }

            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', false);
        } catch(error) {
            console.log('error', error);
            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', false);

        }

    },

    selectWorkflow({ state, commit, dispatch }, id) {
        try {

            commit('CHANGE_WORKFLOW_PROCESSING_LOAD', true)
            let workflow = state.workflows.find((workflow) => workflow.id == id);
            if(workflow) {
                commit('CHANGE_WORKFLOW', workflow)
                commit('CHANGE_WORKFLOW_HEADER_ITEMS', workflow.name)

                return;
            }

            dispatch('findWorkflow', id)
            commit('CHANGE_WORKFLOW_PROCESSING_LOAD', false)
        } catch(error) {
            commit('CHANGE_WORKFLOW_PROCESSING_LOAD', false)
            console.log('error', error);
        }

    },

    async deleteWorkflow({ state, commit }, id) {
        try {

            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', true);
            const response = await axios.delete(`${baseEndpoint}/${id}`);
            if(!response.data.error) {
                commit('DELETE_WORKFLOW', id)
            }


            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', false);
        } catch(error) {

            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', false);
        }
    },

    async storeWorkflow({ state, commit }, data) {
        try {

            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', true);

            const response = await axios.post(baseEndpoint, data);
            if(!response.data.error) {
                commit('STORE_WORKFLOW', response.data.data)
            }

            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', false);
        } catch(error) {
            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', false);
        }
    },

    async updateWorkflow({ state, commit }, data) {
        try {
            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', true);

            const response = await axios.post(`${baseEndpoint}`, data);
            if(!response.data.error) {
                commit('UPDATE_WORKFLOW', response.data.data)
            }

            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', false);
        } catch(error) {
            commit('CHANGE_WORKFLOWS_PROCESSING_LOAD', false);
        }
    },

    async setWorkflow({ commit }, workflow) {
        commit('CHANGE_WORKFLOW', workflow);
    },
    async clearWorkflow({ commit }) {
        commit('CHANGE_WORKFLOW', {});
    },
    async setWorkflowHeaderItems({ commit }, workflowName) {
        commit('CHANGE_WORKFLOW_HEADER_ITEMS', workflowName);
    },
    async setSelectedFormUuid({ commit }, formUuid) {
        commit('CHANGE_FORM_UUID', formUuid);
    },
    async setSelectedFormGroup({ commit }, groupId) {
        commit('CHANGE_FORM_SELECTED_GROUP', groupId);
    },
    clearWorkflows({ commit }) {
        commit('CHANGE_WORKFLOWS', []);
    },
    clearWorkflow({ commit }) {
        commit('CHANGE_WORKFLOW', {id:0});
    }
}
