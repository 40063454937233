import axios from "axios";

let baseEndpoint = `${window.location.origin}/api/v1/workflow/column`;

export const state = {
    totalWidth: 0,
    column: {},
    columns: [],
    columnsProcessing:{
        loadColumnId: 0,
        loadColumn: false,
        loadColumnSequence: false
    },
}

export const mutations = {

    CHANGE_COLUMNS(state, data) {
        state.columns = data
    },

    CHANGE_COLUMN(state, data) {
        state.column = data
    },
    CHANGE_TOTAL_WIDTH(state, data) {
        state.totalWidth = data
    },

    DELETE_COLUMN(state, dataId) {
        state.columns = state.columns.filter((column)=> column.id != dataId);
        if(state.column && state.column.id == dataId) {
            state.column = {};
        }
    },

    STORE_COLUMN(state, data){
        state.columns.push(data);
    },

    UPDATE_COLUMN(state, data){

        let column = state.columns.find((column)=> column.id != data.id);
        if(column && column.id == data.id) {
            let keys = Object.keys(column);
            keys.forEach((key)=>{
                column[key] = data[key];
            });
        }

        if(state.column && state.column.id == data.id) {
            state.column = data;
        }
    },

    SEARCH_COLUMN(state, data) {
        state.columns = data;
        let clearName = '';
        let clearSearchData = data.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        state.columns = state.columns.filter((item)=>{
            if(typeof item.name === 'string' || item.name instanceof String){
                clearName = item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                if(clearName.indexOf(clearSearchData) != -1 || item.id.toString().indexOf(data) != -1){
                    return true;
                }

            } else {

                if(item.name.toString().indexOf(data.toLowerCase()) != -1 || item.id.toString().indexOf(data) != -1){
                    return true;
                }

            }
        })
    },

    CHANGE_COLUMNS_PROCESSING(state, data) {
      state.columnsProcessing.loadColumn = data;
    },
    CHANGE_COLUMNS_PROCESSING_COLUMN_SEQUENCE(state, data) {
      state.columnsProcessing.loadColumnSequence = data;
    },
    CHANGE_COLUMNS_PROCESSING_COLUMN_ID(state, data) {
      state.columnsProcessing.loadColumnId = data;
    },

    UNHIDE_COLUMN(state, data) {
        let column = state.columns.find((column)=> column.id == data);
        if(column) {
            column.hidden = false;
        }
    },

    UPDATE_COLUMNS_SEQUENCE(state, columns) {
        let header = {sequence: 0};
        state.columns.forEach((column)=>{
            header = columns.find((item)=> item.id == column.id);
            column.sequence = header.sequence
        });

        state.columns.sort((a, b)=>{
            if(a.sequence < b.sequence) {
                return -1;
            }
            if(a.sequence > b.sequence) {
                return 1;
            }
            return 0
        });

    }
}

export const actions = {

    listColumns({ state, commit, dispatch }, workflowId) {

        commit('CHANGE_COLUMNS_PROCESSING', true);

        const response = axios.get(`${baseEndpoint}/list/${workflowId}`)
        .then((response)=>{
            if(!response.data.error) {
                commit('CHANGE_COLUMNS', response.data.data);
                dispatch('calcTotalWidth', response.data.data);
            }

            commit('CHANGE_COLUMNS_PROCESSING', false);

        })
        .catch((error)=>{
            commit('CHANGE_COLUMNS_PROCESSING', false);

        })

    },
    calcTotalWidth({commit}, columns) {
        let data = 0;
        columns.forEach((column)=>{
            if(!column.hidden) {
                if(column.workflow_column_type_id == 9) {
                    if(column.width < 282){
                        data += 282;
                    } else if(column.width > 682){
                        data += 682;
                    } else {
                        data += column.width;
                    }

                } else {
                    if(column.width < 168){
                        data += 168;
                    } else if(column.width > 400){
                        data += 400;
                    } else {
                        data += column.width;
                    }
                }
            }

        });
        commit('CHANGE_TOTAL_WIDTH', data);
    },
    // async listColumns({ state, commit }, workflowId) {
    //     try {
    //         commit('CHANGE_COLUMNS_PROCESSING', true);

    //         const response = await axios.get(`${baseEndpoint}/list/${workflowId}`);
    //         if(!response.data.error) {
    //             commit('CHANGE_COLUMNS',  response.data.data)
    //         }

    //         commit('CHANGE_COLUMNS_PROCESSING', false);
    //     } catch(error) {
    //         commit('CHANGE_COLUMNS_PROCESSING', false);
    //     }
    // },

    async findColumn({ state, commit }, columnId) {
        try {
            commit('CHANGE_COLUMNS_PROCESSING', true);

            const response = await axios.get(`${baseEndpoint}/${columnId}`);
            if(!response.data.error) {
                commit('CHANGE_COLUMN', response.data.data)
            }

            commit('CHANGE_COLUMNS_PROCESSING', false);
        } catch(error) {
            commit('CHANGE_COLUMNS_PROCESSING', false);
        }
    },

    async deleteColumn({ state, commit }, columnId) {
        try {
            commit('CHANGE_COLUMNS_PROCESSING_COLUMN_ID', columnId);

            const response = await axios.delete(`${baseEndpoint}/${columnId}`);
            if(!response.data.error) {
                return true;
            }

            commit('CHANGE_COLUMNS_PROCESSING_COLUMN_ID', 0);
            return false;
        } catch(error) {
            commit('CHANGE_COLUMNS_PROCESSING_COLUMN_ID', 0);
            return false;
        }
    },
    removeColumn({ commit }, columnId) {
        commit('DELETE_COLUMN', columnId)
    },

    async storeColumn({ state, commit }, data) {
        try {
            commit('CHANGE_COLUMNS_PROCESSING', true);

            const response = await axios.post(`${baseEndpoint}/${columnId}`, data);
            if(!response.data.error) {
                commit('STORE_COLUMN', response.data.data)
            }

            commit('CHANGE_COLUMNS_PROCESSING', false);
        } catch(error) {
            commit('CHANGE_COLUMNS_PROCESSING', false);
        }
    },

    async updateColumn({ state, commit }, data) {
        try {
            commit('CHANGE_COLUMNS_PROCESSING', true);

            const response = await axios.patch(`${baseEndpoint}/${columnId}`, data);
            if(!response.data.error) {
                commit('UPDATE_COLUMN', response.data.data)
            }

            commit('CHANGE_COLUMNS_PROCESSING', false);
        } catch(error) {
            commit('CHANGE_COLUMNS_PROCESSING', false);
        }
    },

    async searchColumn({ state, commit }, data) {
        try {
            commit('CHANGE_COLUMNS_PROCESSING', true);

            const response = await axios.get(`${baseEndpoint}/search/${data}`);
            if(!response.data.error) {
                commit('SEARCH_COLUMN', response.data.data)
            }

            commit('CHANGE_COLUMNS_PROCESSING', false);
        } catch(error) {
            commit('CHANGE_COLUMNS_PROCESSING', false);
        }
    },

    updateColumnWidth: async function updateColumnWidth({ state, commit, dispatch }, {colId, width}) {

        clearTimeout(updateColumnWidth.timeout);
        updateColumnWidth.timeout = setTimeout(() => {
        if (width > 0) {
            const updateColumnWidth = axios
            .post(
                window.location.origin + "/api/v1/workflow/item/width",
                { column_id: colId, width: width }
            )
            .then((response) => {
                // this.$set(this.resizerWidths, colId, width);

                // setTimeout(() => {

                //     window.document.querySelectorAll(`.resizer_width_${colId}`).forEach((element, key) => {
                //         if (element.classList.value.indexOf('mnw-col-title-sub') > -1) {
                //             element.style.width = `${width - 42}px`;
                //         } else {
                //             element.style.width = `${width}px`;
                //         }
                //         });
                //         Vue.refs[`resizer_component_width_${colId}`].forEach((component, key) => {
                //             component.$el.style.width = `${width}px`;
                //         });
                // }, 1);

                let columns = JSON.parse(JSON.stringify(state.columns));
                columns.map((column)=> {
                    if(column.id == colId) {
                        column.width = width;
                    }
                    return column;
                });

                commit('CHANGE_COLUMNS', columns)
                dispatch('calcTotalWidth', columns);
                return true
            })
            .catch((error) => {
                // showAlert(true, error.response.data.message);
                console.log(error);
                return false
            });
        }
        }, 500);
    },
    updateColumnSequence({ commit, state }) {

        commit('CHANGE_COLUMNS_PROCESSING_COLUMN_SEQUENCE', true)
        let data = [];
        state.columns.forEach((header, key) => {
            data.push({ header_id: header.id, sequence: key })
        });

        axios.patch(`${baseEndpoint}/sequence`, data)
        .then((response) => { commit('CHANGE_COLUMNS_PROCESSING_COLUMN_SEQUENCE', false);})
        .catch((error) => {
          console.log(error);
        });

    },
    async changeColumnsSequence({ commit, state }, columns) {
        commit('CHANGE_COLUMNS_PROCESSING_COLUMN_SEQUENCE', true)
        await commit('UPDATE_COLUMNS_SEQUENCE', columns)
        setTimeout(()=>{
            commit('CHANGE_COLUMNS_PROCESSING_COLUMN_SEQUENCE', false)
        }, 100)
    },
    setColumns({ commit, state }, columns) {
        commit('CHANGE_COLUMNS', columns)
    },
    unhideColumn({ commit, state }, columnId) {
        axios.post(window.location.origin + "/api/v1/workflow/item/unhideItem", { column_id: columnId })
        .then((response) => {
            commit('UNHIDE_COLUMN', columnId);
        })
        .catch((error) => {
            showAlert(true, error.response.data.message);
            console.log(error);
        });
    }
}

export const getters = {
    hasTicketId(state) {
      return !!state.columns.find((column)=>{
          return column.tag == 'workflow_ticket_id';
      })
    }
  }
