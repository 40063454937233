import axios from "axios";

const userMenu = [
    // {
    //     id: 1,
    //     isWorkflow: false,
    //     label: "menuitems.menu.text",
    //     isTitle: true,
    // },
    {
        id: 2,
        isWorkflow: false,
        label: "menuitems.dashboards.list.default",
        icon: "fas fa-chart-bar",
        link: "/",
    },
    {
        id: 3,
        isWorkflow: false,
        label: "menuitems.manage.Called.list.userview",
        icon: "fas fa-ticket-alt",
        link: "/workflow/user/view",
    }
];

const baseMenu = [
    // {
    //     id: 1,
    //     isWorkflow: false,
    //     label: "menuitems.menu.text",
    //     isTitle: true
    // },
    {
        id: 2,
        isWorkflow: false,
        label: "menuitems.dashboards.list.default",
        icon: "fas fa-chart-bar",
        link: "/",
    },
];
const workflowSection = [
    {
        id: 3,
        isWorkflow: false,
        label: "menuitems.workflows.text",
        isTitle: true
    },
    {
        id: 4,
        isWorkflow: false,
        icon: "bx bx-plus",
        label: "Novo",
        link: null,
        menu_category_id: 3,
        parent_menu_id: 0,
        disable: false,
        type: 1,
        link: "/workflow/new",
    }
];

const menuConfig = [
    {
        id: 2,
        isWorkflow: false,
        label: "Home",
        icon: "bx bx-home-circle",
        link: "/",
    },
    {
        id: 5,
        isWorkflow: false,
        label: "menuitems.configuration.text",
        icon: "bx bx-cog",
        subItems: [
            // {
            //     id: 6,
            //     label: "menuitems.configuration.list.clients",
            //     link: "/maintenance/clients/",
            //     parentId: 5
            // },
            {
                id: 7,
                icon: 'bx bx-user',
                label: "menuitems.configuration.list.users",
                link: "/maintenance/users",
                parentId: 5
            },
            {
                id: 8,
                icon: 'bx bx-user-plus',
                label: "menuitems.configuration.list.person",
                link: "/maintenance/person",
                parentId: 5
            },
            // {
            //     id: 8,
            //     label: "menuitems.configuration.list.contracts",
            //     link: "/maintenance/contracts",
            //     parentId: 5
            // },
            // {
            //     id: 9,
            //     label: "menuitems.configuration.list.departments",
            //     link: "/maintenance/departments",
            //     parentId: 5
            // },
            {
                id: 10,
                icon: 'bx bx-building-house',
                label: "menuitems.configuration.list.company",
                link: "/maintenance/company",
                parentId: 5
            },
        ]
    }
]
export const state = {
    menuData: [],
    menuItems: [],
    isColapsedMenu: false,
}

export const mutations = {
    UPDATE_MENU(state, data) {
        state.menuItems = data;
    },
    CHANGE_MENU(state, data) {
        state.menuItems = data;
    },
    CHANGE_MENU_STATUS(state, data) {
        state.isColapsedMenu = data;
    },
}

export const actions = {
    setMenuStatus({ commit }, data){
        localStorage.removeItem('isColapsedMenu');
        localStorage.setItem('isColapsedMenu', data);
        commit('CHANGE_MENU_STATUS', data)
    },

    async getMenu({ commit }) {
        try {

            let user = await axios.post(window.location.origin + '/api/v1/user');
            const workflows = await axios.get(window.location.origin + '/api/v1/workflow');
            var uri = window.location.pathname;

            let menu = [];
            let favorites = [];
            baseMenu.forEach((base)=>{
                menu.push(base)
            });

            if (uri.indexOf('maintenance') < 0) {
                workflowSection.forEach((item)=>{
                    menu.push(item)
                })
                workflows.data.data.sort((a,b,)=>{
                    if(a.name > b.name) {
                        return 1
                    }
                    if(a.name < b.name) {
                        return -1
                    }

                    return 0;
                });
                workflows.data.data.forEach((workflow, key) => {
                    let menuItem = menuByWorkflow(workflow);
                    menu.push(menuItem);
                });
            }

            if (uri.indexOf('maintenance') > -1) {
                commit('CHANGE_MENU', menuConfig);
                return;

            }

            if(user.data.user_type_id > 2 && user.data.user_type_id != 4) {
                commit('CHANGE_MENU', userMenu);
                return;
            }
            if(user.data.user_type_id == 2 || user.data.user_type_id == 4) {
                menu = menu.filter((item) => item.id != 4);
            }

            commit('CHANGE_MENU', menu);

        } catch (error) {
            commit('CHANGE_MENU', menuConfig);
        }
    },
    openMenu({dispatch}){
        document.body.classList.add("sidebar-enable");

        if (window.screen.width >= 992) {
            // eslint-disable-next-line no-unused-vars
            document.body.classList.remove("vertical-collpsed");
        } else {
            // eslint-disable-next-line no-unused-vars
            document.body.classList.add("vertical-collpsed");
        }

        dispatch('setMenuStatus', false);

      },
      closeMenu({dispatch}){
        document.body.classList.remove("sidebar-enable");

        if (window.screen.width >= 992) {
            // eslint-disable-next-line no-unused-vars
            document.body.classList.add("vertical-collpsed");
        } else {
            // eslint-disable-next-line no-unused-vars
            document.body.classList.remove("vertical-collpsed");
        }

        dispatch('setMenuStatus', true);
      }
}

// local helper
function menuByWorkflow(workflow) {
    let menuItem = {};
    menuItem.isWorkflow = true;
    menuItem.icon = 'mdi mdi-timetable';
    menuItem.label = workflow.name;
    menuItem.link = '/workflow/show/' + workflow.id;
    menuItem.menu_category_id = 3;
    menuItem.parent_menu_id = 0;
    menuItem.workflowId = workflow.id;
    menuItem.workspaceId = workflow.workspace_id;
    menuItem.favorite = workflow.is_favorite;
    return menuItem;
}
