import axios from "axios";
import showAlert from "../../components/alerts";

export const state = {
  selectedWorkspaceId: 0,
  workspace: {
    id: 0,
    name: '',
    color: '',
    icon: '',
    workflows: [],
    created_at: ''
  },
  workspaces: [],
  workspacesLoad: false,
}

export const mutations = {
  CHANGE_WORKSPACES(state, data) {
    state.workspaces = data;
  },
  CHANGE_WORKSPACE(state, data) {
    state.workspace = data;
    localStorage.setItem('workspace-selected', data.id);
  },
  CHANGE_WORKSPACES_LOAD(state, data) {
    state.workspacesLoad = data;
  },
  UPDATE_WORKSPACE(state, data) {
    if(data.id) {
      let workspace = state.workspaces.find((workspace)=> workspace.id = data.id);
      if(workspace) {
          workspace = data;
      }
    }
    state.workspace = data;
  },
  DELETE_WORKSPACE(state, id) {
    state.workspaces = state.workspaces.filter((workspace) => workspace.id != id);
    if (state.workspace.id == id) {
      state.workspace = {};
    }
  },
}

export const actions = {
  async getWorkspaces({ commit }, desc = false) {
    const response = await axios.get(window.location.origin + '/api/v1/workspace/all/'+desc);
    commit('CHANGE_WORKSPACES', response.data.data);
  },

  async getWorkspace({ commit }, id) {
    const response = await axios.get(window.location.origin + '/api/v1/workspace/show/'+id);
    commit('CHANGE_WORKSPACE', response.data.data);
  },

  async saveWorkspace({ commit }, {id, name, color, icon, workflows }) {
    try {

      let data = { id: id, name: name, color: color, icon: icon, workflows: workflows};
      let response = null;

      if (id) {
        response = await axios.patch(window.location.origin + '/api/v1/workspace', data);
      } else {
        response = await axios.post(window.location.origin + '/api/v1/workspace', data);
      }

      if (response.data.error) {
        commit('CHANGE_WORKSPACE', {});
      }

      commit('CHANGE_WORKSPACE', response.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  async deleteWorkspace({ commit }, id) {
    try {
      commit('CHANGE_WORKSPACES_LOAD', true);
      const response = await axios.delete(window.location.origin + '/api/v1/workspace/' + id);
      if (response) {
        if (response.status > 199 && response.status < 300) {
          commit('DELETE_WORKSPACE', id);
          showAlert(false, 'Sucesso');
        }

      }
      commit('CHANGE_WORKSPACES_LOAD', false);
    } catch (error) {
      commit('CHANGE_WORKSPACES_LOAD', false);
    }
  },

  selectWorkspace({commit}, data) {
    commit('CHANGE_WORKSPACE', data)
  }

}
