const icons = [{"class": "bx bx-sticker", "title": "a"},
{"class": "bx bx-shield-quarter", "title": "a"},
{"class": "bx bx-upside-down", "title": "a"},
{"class": "bx bx-laugh", "title": "a"},
{"class": "bx bx-meh-blank", "title": "a"},
{"class": "bx bx-happy-beaming", "title": "a"},
{"class": "bx bx-shocked", "title": "a"},
{"class": "bx bx-sleepy", "title": "a"},
{"class": "bx bx-confused", "title": "a"},
{"class": "bx bx-wink-smile", "title": "a"},
{"class": "bx bx-dizzy", "title": "a"},
{"class": "bx bx-happy-heart-eyes", "title": "a"},
{"class": "bx bx-angry", "title": "a"},
{"class": "bx bx-smile", "title": "a"},
{"class": "bx bx-tired", "title": "a"},
{"class": "bx bx-cool", "title": "a"},
{"class": "bx bx-happy-alt", "title": "a"},
{"class": "bx bx-wink-tongue", "title": "a"},
{"class": "bx bx-meh-alt", "title": "a"},
{"class": "bx bx-food-menu", "title": "a"},
{"class": "bx bx-food-tag", "title": "a"},
{"class": "bx bx-female-sign", "title": "a"},
{"class": "bx bx-male-sign", "title": "a"},
{"class": "bx bx-female", "title": "a"},
{"class": "bx bx-male", "title": "a"},
{"class": "bx bx-clinic", "title": "a"},
{"class": "bx bx-health", "title": "a"},
{"class": "bx bx-shekel", "title": "a"},
{"class": "bx bx-yen", "title": "a"},
{"class": "bx bx-won", "title": "a"},
{"class": "bx bx-pound", "title": "a"},
{"class": "bx bx-euro", "title": "a"},
{"class": "bx bx-rupee", "title": "a"},
{"class": "bx bx-ruble", "title": "a"},
{"class": "bx bx-lira", "title": "a"},
{"class": "bx bx-bitcoin", "title": "a"},
{"class": "bx bx-tone", "title": "a"},
{"class": "bx bx-bolt-circle", "title": "a"},
{"class": "bx bx-cake", "title": "a"},
{"class": "bx bx-spa", "title": "a"},
{"class": "bx bx-dish", "title": "a"},
{"class": "bx bx-fridge", "title": "a"},
{"class": "bx bx-image-add", "title": "a"},
{"class": "bx bx-image-alt", "title": "a"},
{"class": "bx bx-space-bar", "title": "a"},
{"class": "bx bx-alarm-add", "title": "a"},
{"class": "bx bx-archive-out", "title": "a"},
{"class": "bx bx-archive-in", "title": "a"},
{"class": "bx bx-add-to-queue", "title": "a"},
{"class": "bx bx-border-radius", "title": "a"},
{"class": "bx bx-check-shield", "title": "a"},
{"class": "bx bx-label", "title": "a"},
{"class": "bx bx-file-find", "title": "a"},
{"class": "bx bx-face", "title": "a"},
{"class": "bx bx-extension", "title": "a"},
{"class": "bx bx-exit", "title": "a"},
{"class": "bx bx-conversation", "title": "a"},
{"class": "bx bx-sort-z-a", "title": "a"},
{"class": "bx bx-sort-a-z", "title": "a"},
{"class": "bx bx-printer", "title": "a"},
{"class": "bx bx-radio", "title": "a"},
{"class": "bx bx-customize", "title": "a"},
{"class": "bx bx-brush-alt", "title": "a"},
{"class": "bx bx-briefcase-alt-2", "title": "a"},
{"class": "bx bx-time-five", "title": "a"},
{"class": "bx bx-pie-chart-alt-2", "title": "a"},
{"class": "bx bx-gas-pump", "title": "a"},
{"class": "bx bx-mobile-vibration", "title": "a"},
{"class": "bx bx-mobile-landscape", "title": "a"},
{"class": "bx bx-border-all", "title": "a"},
{"class": "bx bx-border-bottom", "title": "a"},
{"class": "bx bx-border-top", "title": "a"},
{"class": "bx bx-border-left", "title": "a"},
{"class": "bx bx-border-right", "title": "a"},
{"class": "bx bx-dialpad-alt", "title": "a"},
{"class": "bx bx-filter-alt", "title": "a"},
{"class": "bx bx-brightness", "title": "a"},
{"class": "bx bx-brightness-half", "title": "a"},
{"class": "bx bx-wifi-off", "title": "a"},
{"class": "bx bx-credit-card-alt", "title": "a"},
{"class": "bx bx-band-aid", "title": "a"},
{"class": "bx bx-hive", "title": "a"},
{"class": "bx bx-map-pin", "title": "a"},
{"class": "bx bx-line-chart", "title": "a"},
{"class": "bx bx-receipt", "title": "a"},
{"class": "bx bx-purchase-tag-alt", "title": "a"},
{"class": "bx bx-basket", "title": "a"},
{"class": "bx bx-palette", "title": "a"},
{"class": "bx bx-no-entry", "title": "a"},
{"class": "bx bx-message-alt-dots", "title": "a"},
{"class": "bx bx-message-alt", "title": "a"},
{"class": "bx bx-check-square", "title": "a"},
{"class": "bx bx-log-out-circle", "title": "a"},
{"class": "bx bx-log-in-circle", "title": "a"},
{"class": "bx bx-doughnut-chart", "title": "a"},
{"class": "bx bx-building-house", "title": "a"},
{"class": "bx bx-accessibility", "title": "a"},
{"class": "bx bx-user-voice", "title": "a"},
{"class": "bx bx-cuboid", "title": "a"},
{"class": "bx bx-cube-alt", "title": "a"},
{"class": "bx bx-polygon", "title": "a"},
{"class": "bx bx-square-rounded", "title": "a"},
{"class": "bx bx-square", "title": "a"},
{"class": "bx bx-error-alt", "title": "a"},
{"class": "bx bx-shield-alt-2", "title": "a"},
{"class": "bx bx-paint-roll", "title": "a"},
{"class": "bx bx-droplet", "title": "a"},
{"class": "bx bx-street-view", "title": "a"},
{"class": "bx bx-plus-medical", "title": "a"},
{"class": "bx bx-search-alt-2", "title": "a"},
{"class": "bx bx-bowling-ball", "title": "a"},
{"class": "bx bx-dna", "title": "a"},
{"class": "bx bx-cycling", "title": "a"},
{"class": "bx bx-shape-circle", "title": "a"},
{"class": "bx bx-down-arrow-alt", "title": "a"},
{"class": "bx bx-up-arrow-alt", "title": "a"},
{"class": "bx bx-right-arrow-alt", "title": "a"},
{"class": "bx bx-left-arrow-alt", "title": "a"},
{"class": "bx bx-lock-open-alt", "title": "a"},
{"class": "bx bx-lock-alt", "title": "a"},
{"class": "bx bx-cylinder", "title": "a"},
{"class": "bx bx-pyramid", "title": "a"},
{"class": "bx bx-comment-dots", "title": "a"},
{"class": "bx bx-comment", "title": "a"},
{"class": "bx bx-landscape", "title": "a"},
{"class": "bx bx-book-open", "title": "a"},
{"class": "bx bx-transfer-alt", "title": "a"},
{"class": "bx bx-copy-alt", "title": "a"},
{"class": "bx bx-run", "title": "a"},
{"class": "bx bx-user-pin", "title": "a"},
{"class": "bx bx-grid", "title": "a"},
{"class": "bx bx-code-alt", "title": "a"},
{"class": "bx bx-mail-send", "title": "a"},
{"class": "bx bx-ghost", "title": "a"},
{"class": "bx bx-shape-triangle", "title": "a"},
{"class": "bx bx-shape-square", "title": "a"},
{"class": "bx bx-video-recording", "title": "a"},
{"class": "bx bx-notepad", "title": "a"},
{"class": "bx bx-bug-alt", "title": "a"},
{"class": "bx bx-mouse-alt", "title": "a"},
{"class": "bx bx-edit-alt", "title": "a"},
{"class": "bx bx-chat", "title": "a"},
{"class": "bx bx-book-content", "title": "a"},
{"class": "bx bx-message-square-dots", "title": "a"},
{"class": "bx bx-message-square", "title": "a"},
{"class": "bx bx-slideshow", "title": "a"},
{"class": "bx bx-wallet-alt", "title": "a"},
{"class": "bx bx-memory-card", "title": "a"},
{"class": "bx bx-message-rounded-dots", "title": "a"},
{"class": "bx bx-message-dots", "title": "a"},
{"class": "bx bx-bar-chart-alt-2", "title": "a"},
{"class": "bx bx-store-alt", "title": "a"},
{"class": "bx bx-buildings", "title": "a"},
{"class": "bx bx-home-circle", "title": "a"},
{"class": "bx bx-money", "title": "a"},
{"class": "bx bx-walk", "title": "a"},
{"class": "bx bx-repeat", "title": "a"},
{"class": "bx bx-font-family", "title": "a"},
{"class": "bx bx-joystick-button", "title": "a"},
{"class": "bx bx-paint", "title": "a"},
{"class": "bx bx-unlink", "title": "a"},
{"class": "bx bx-brush", "title": "a"},
{"class": "bx bx-rotate-left", "title": "a"},
{"class": "bx bx-badge-check", "title": "a"},
{"class": "bx bx-show-alt", "title": "a"},
{"class": "bx bx-caret-down", "title": "a"},
{"class": "bx bx-caret-right", "title": "a"},
{"class": "bx bx-caret-up", "title": "a"},
{"class": "bx bx-caret-left", "title": "a"},
{"class": "bx bx-calendar-event", "title": "a"},
{"class": "bx bx-magnet", "title": "a"},
{"class": "bx bx-rewind-circle", "title": "a"},
{"class": "bx bx-card", "title": "a"},
{"class": "bx bx-help-circle", "title": "a"},
{"class": "bx bx-test-tube", "title": "a"},
{"class": "bx bx-note", "title": "a"},
{"class": "bx bx-sort-down", "title": "a"},
{"class": "bx bx-sort-up", "title": "a"},
{"class": "bx bx-id-card", "title": "a"},
{"class": "bx bx-badge", "title": "a"},
{"class": "bx bx-grid-small", "title": "a"},
{"class": "bx bx-grid-vertical", "title": "a"},
{"class": "bx bx-grid-horizontal", "title": "a"},
{"class": "bx bx-move-vertical", "title": "a"},
{"class": "bx bx-move-horizontal", "title": "a"},
{"class": "bx bx-stats", "title": "a"},
{"class": "bx bx-equalizer", "title": "a"},
{"class": "bx bx-disc", "title": "a"},
{"class": "bx bx-analyse", "title": "a"},
{"class": "bx bx-search-alt", "title": "a"},
{"class": "bx bx-dollar-circle", "title": "a"},
{"class": "bx bx-football", "title": "a"},
{"class": "bx bx-ball", "title": "a"},
{"class": "bx bx-circle", "title": "a"},
{"class": "bx bx-transfer", "title": "a"},
{"class": "bx bx-fingerprint", "title": "a"},
{"class": "bx bx-font-color", "title": "a"},
{"class": "bx bx-highlight", "title": "a"},
{"class": "bx bx-file-blank", "title": "a"},
{"class": "bx bx-strikethrough", "title": "a"},
{"class": "bx bx-photo-album", "title": "a"},
{"class": "bx bx-code-block", "title": "a"},
{"class": "bx bx-font-size", "title": "a"},
{"class": "bx bx-handicap", "title": "a"},
{"class": "bx bx-dialpad", "title": "a"},
{"class": "bx bx-wind", "title": "a"},
{"class": "bx bx-water", "title": "a"},
{"class": "bx bx-swim", "title": "a"},
{"class": "bx bx-restaurant", "title": "a"},
{"class": "bx bx-box", "title": "a"},
{"class": "bx bx-menu-alt-right", "title": "a"},
{"class": "bx bx-menu-alt-left", "title": "a"},
{"class": "bx bx-video-plus", "title": "a"},
{"class": "bx bx-list-ol", "title": "a"},
{"class": "bx bx-planet", "title": "a"},
{"class": "bx bx-hotel", "title": "a"},
{"class": "bx bx-movie", "title": "a"},
{"class": "bx bx-taxi", "title": "a"},
{"class": "bx bx-train", "title": "a"},
{"class": "bx bx-bath", "title": "a"},
{"class": "bx bx-bed", "title": "a"},
{"class": "bx bx-area", "title": "a"},
{"class": "bx bx-bot", "title": "a"},
{"class": "bx bx-dumbbell", "title": "a"},
{"class": "bx bx-check-double", "title": "a"},
{"class": "bx bx-bus", "title": "a"},
{"class": "bx bx-check-circle", "title": "a"},
{"class": "bx bx-rocket", "title": "a"},
{"class": "bx bx-certification", "title": "a"},
{"class": "bx bx-slider-alt", "title": "a"},
{"class": "bx bx-sad", "title": "a"},
{"class": "bx bx-meh", "title": "a"},
{"class": "bx bx-happy", "title": "a"},
{"class": "bx bx-cart-alt", "title": "a"},
{"class": "bx bx-car", "title": "a"},
{"class": "bx bx-loader-alt", "title": "a"},
{"class": "bx bx-loader-circle", "title": "a"},
{"class": "bx bx-wrench", "title": "a"},
{"class": "bx bx-alarm-off", "title": "a"},
{"class": "bx bx-layout", "title": "a"},
{"class": "bx bx-dock-left", "title": "a"},
{"class": "bx bx-dock-top", "title": "a"},
{"class": "bx bx-dock-right", "title": "a"},
{"class": "bx bx-dock-bottom", "title": "a"},
{"class": "bx bx-dock-bottom", "title": "a"},
{"class": "bx bx-world", "title": "a"},
{"class": "bx bx-selection", "title": "a"},
{"class": "bx bx-paper-plane", "title": "a"},
{"class": "bx bx-slider", "title": "a"},
{"class": "bx bx-loader", "title": "a"},
{"class": "bx bx-chalkboard", "title": "a"},
{"class": "bx bx-trash-alt", "title": "a"},
{"class": "bx bx-grid-alt", "title": "a"},
{"class": "bx bx-command", "title": "a"},
{"class": "bx bx-window-close", "title": "a"},
{"class": "bx bx-notification-off", "title": "a"},
{"class": "bx bx-plug", "title": "a"},
{"class": "bx bx-infinite", "title": "a"},
{"class": "bx bx-carousel", "title": "a"},
{"class": "bx bx-hourglass", "title": "a"},
{"class": "bx bx-briefcase-alt", "title": "a"},
{"class": "bx bx-wallet", "title": "a"},
{"class": "bx bx-station", "title": "a"},
{"class": "bx bx-collection", "title": "a"},
{"class": "bx bx-tv", "title": "a"},
{"class": "bx bx-closet", "title": "a"},
{"class": "bx bx-paperclip", "title": "a"},
{"class": "bx bx-expand", "title": "a"},
{"class": "bx bx-pen", "title": "a"},
{"class": "bx bx-purchase-tag", "title": "a"},
{"class": "bx bx-images", "title": "a"},
{"class": "bx bx-pie-chart-alt", "title": "a"},
{"class": "bx bx-news", "title": "a"},
{"class": "bx bx-downvote", "title": "a"},
{"class": "bx bx-upvote", "title": "a"},
{"class": "bx bx-globe-alt", "title": "a"},
{"class": "bx bx-store", "title": "a"},
{"class": "bx bx-hdd", "title": "a"},
{"class": "bx bx-skip-previous-circle", "title": "a"},
{"class": "bx bx-skip-next-circle", "title": "a"},
{"class": "bx bx-chip", "title": "a"},
{"class": "bx bx-cast", "title": "a"},
{"class": "bx bx-body", "title": "a"},
{"class": "bx bx-phone-outgoing", "title": "a"},
{"class": "bx bx-phone-incoming", "title": "a"},
{"class": "bx bx-collapse", "title": "a"},
{"class": "bx bx-rename", "title": "a"},
{"class": "bx bx-rotate-right", "title": "a"},
{"class": "bx bx-horizontal-center", "title": "a"},
{"class": "bx bx-ruler", "title": "a"},
{"class": "bx bx-import", "title": "a"},
{"class": "bx bx-calendar-alt", "title": "a"},
{"class": "bx bx-battery", "title": "a"},
{"class": "bx bx-server", "title": "a"},
{"class": "bx bx-task", "title": "a"},
{"class": "bx bx-folder-open", "title": "a"},
{"class": "bx bx-film", "title": "a"},
{"class": "bx bx-aperture", "title": "a"},
{"class": "bx bx-phone-call", "title": "a"},
{"class": "bx bx-up-arrow", "title": "a"},
{"class": "bx bx-undo", "title": "a"},
{"class": "bx bx-timer", "title": "a"},
{"class": "bx bx-support", "title": "a"},
{"class": "bx bx-subdirectory-right", "title": "a"},
{"class": "bx bx-", "title": "a"},
{"class": "bx bx-right-arrow", "title": "a"},
{"class": "bx bx-revision", "title": "a"},
{"class": "bx bx-repost", "title": "a"},
{"class": "bx bx-reply", "title": "a"},
{"class": "bx bx-reply-all", "title": "a"},
{"class": "bx bx-redo", "title": "a"},
{"class": "bx bx-radar", "title": "a"},
{"class": "bx bx-poll", "title": "a"},
{"class": "bx bx-list-check", "title": "a"},
{"class": "bx bx-like", "title": "a"},
{"class": "bx bx-left-arrow", "title": "a"},
{"class": "bx bx-joystick-alt", "title": "a"},
{"class": "bx bx-history", "title": "a"},
{"class": "bx bx-flag", "title": "a"},
{"class": "bx bx-first-aid", "title": "a"},
{"class": "bx bx-export", "title": "a"},
{"class": "bx bx-down-arrow", "title": "a"},
{"class": "bx bx-dislike", "title": "a"},
{"class": "bx bx-crown", "title": "a"},
{"class": "bx bx-barcode", "title": "a"},
{"class": "bx bx-user", "title": "a"},
{"class": "bx bx-user-x", "title": "a"},
{"class": "bx bx-user-plus", "title": "a"},
{"class": "bx bx-user-minus", "title": "a"},
{"class": "bx bx-user-circle", "title": "a"},
{"class": "bx bx-user-check", "title": "a"},
{"class": "bx bx-underline", "title": "a"},
{"class": "bx bx-trophy", "title": "a"},
{"class": "bx bx-trash", "title": "a"},
{"class": "bx bx-text", "title": "a"},
{"class": "bx bx-sun", "title": "a"},
{"class": "bx bx-star", "title": "a"},
{"class": "bx bx-sort", "title": "a"},
{"class": "bx bx-shuffle", "title": "a"},
{"class": "bx bx-shopping-bag", "title": "a"},
{"class": "bx bx-shield", "title": "a"},
{"class": "bx bx-shield-alt", "title": "a"},
{"class": "bx bx-share", "title": "a"},
{"class": "bx bx-share-alt", "title": "a"},
{"class": "bx bx-select-multiple", "title": "a"},
{"class": "bx bx-screenshot", "title": "a"},
{"class": "bx bx-save", "title": "a"},
{"class": "bx bx-pulse", "title": "a"},
{"class": "bx bx-power-off", "title": "a"},
{"class": "bx bx-plus", "title": "a"},
{"class": "bx bx-pin", "title": "a"},
{"class": "bx bx-pencil", "title": "a"},
{"class": "bx bx-pin", "title": "a"},
{"class": "bx bx-pencil", "title": "a"},
{"class": "bx bx-paste", "title": "a"},
{"class": "bx bx-paragraph", "title": "a"},
{"class": "bx bx-package", "title": "a"},
{"class": "bx bx-notification", "title": "a"},
{"class": "bx bx-music", "title": "a"},
{"class": "bx bx-move", "title": "a"},
{"class": "bx bx-mouse", "title": "a"},
{"class": "bx bx-minus", "title": "a"},
{"class": "bx bx-microphone-off", "title": "a"},
{"class": "bx bx-log-out", "title": "a"},
{"class": "bx bx-log-in", "title": "a"},
{"class": "bx bx-link-external", "title": "a"},
{"class": "bx bx-joystick", "title": "a"},
{"class": "bx bx-italic", "title": "a"},
{"class": "bx bx-home-alt", "title": "a"},
{"class": "bx bx-heading", "title": "a"},
{"class": "bx bx-hash", "title": "a"},
{"class": "bx bx-group", "title": "a"},
{"class": "bx bx-git-repo-forked", "title": "a"},
{"class": "bx bx-git-pull-request", "title": "a"},
{"class": "bx bx-git-merge", "title": "a"},
{"class": "bx bx-git-compare", "title": "a"},
{"class": "bx bx-git-commit", "title": "a"},
{"class": "bx bx-git-branch", "title": "a"},
{"class": "bx bx-font", "title": "a"},
{"class": "bx bx-filter", "title": "a"},
{"class": "bx bx-file", "title": "a"},
{"class": "bx bx-edit", "title": "a"},
{"class": "bx bx-diamond", "title": "a"},
{"class": "bx bx-detail", "title": "a"},
{"class": "bx bx-cut", "title": "a"},
{"class": "bx bx-cube", "title": "a"},
{"class": "bx bx-crop", "title": "a"},
{"class": "bx bx-credit-card", "title": "a"},
{"class": "bx bx-columns", "title": "a"},
{"class": "bx bx-cog", "title": "a"},
{"class": "bx bx-cloud-snow", "title": "a"},
{"class": "bx bx-cloud-rain", "title": "a"},
{"class": "bx bx-cloud-lightning", "title": "a"},
{"class": "bx bx-cloud-light-rain", "title": "a"},
{"class": "bx bx-cloud-drizzle", "title": "a"},
{"class": "bx bx-check", "title": "a"},
{"class": "bx bx-cart", "title": "a"},
{"class": "bx bx-calculator", "title": "a"},
{"class": "bx bx-bold", "title": "a"},
{"class": "bx bx-award", "title": "a"},
{"class": "bx bx-anchor", "title": "a"},
{"class": "bx bx-album", "title": "a"},
{"class": "bx bx-adjust", "title": "a"},
{"class": "bx bx-x", "title": "a"},
{"class": "bx bx-table", "title": "a"},
{"class": "bx bx-duplicate", "title": "a"},
{"class": "bx bx-windows", "title": "a"},
{"class": "bx bx-window", "title": "a"},
{"class": "bx bx-window-open", "title": "a"},
{"class": "bx bx-wifi", "title": "a"},
{"class": "bx bx-voicemail", "title": "a"},
{"class": "bx bx-video-off", "title": "a"},
{"class": "bx bx-usb", "title": "a"},
{"class": "bx bx-upload", "title": "a"},
{"class": "bx bx-alarm", "title": "a"},
{"class": "bx bx-tennis-ball", "title": "a"},
{"class": "bx bx-target-lock", "title": "a"},
{"class": "bx bx-tag", "title": "a"},
{"class": "bx bx-tab", "title": "a"},
{"class": "bx bx-spreadsheet", "title": "a"},
{"class": "bx bx-sitemap", "title": "a"},
{"class": "bx bx-sidebar", "title": "a"},
{"class": "bx bx-send", "title": "a"},
{"class": "bx bx-pie-chart", "title": "a"},
{"class": "bx bx-phone", "title": "a"},
{"class": "bx bx-navigation", "title": "a"},
{"class": "bx bx-mobile", "title": "a"},
{"class": "bx bx-mobile-alt", "title": "a"},
{"class": "bx bx-message", "title": "a"},
{"class": "bx bx-message-rounded", "title": "a"},
{"class": "bx bx-map", "title": "a"},
{"class": "bx bx-map-alt", "title": "a"},
{"class": "bx bx-lock", "title": "a"},
{"class": "bx bx-lock-open", "title": "a"},
{"class": "bx bx-list-minus", "title": "a"},
{"class": "bx bx-list-ul", "title": "a"},
{"class": "bx bx-list-plus", "title": "a"},
{"class": "bx bx-link", "title": "a"},
{"class": "bx bx-link-alt", "title": "a"},
{"class": "bx bx-layer", "title": "a"},
{"class": "bx bx-laptop", "title": "a"},
{"class": "bx bx-home", "title": "a"},
{"class": "bx bx-heart", "title": "a"},
{"class": "bx bx-headphone", "title": "a"},
{"class": "bx bx-devices", "title": "a"},
{"class": "bx bx-globe", "title": "a"},
{"class": "bx bx-gift", "title": "a"},
{"class": "bx bx-envelope", "title": "a"},
{"class": "bx bx-download", "title": "a"},
{"class": "bx bx-dots-vertical", "title": "a"},
{"class": "bx bx-dots-vertical-rounded", "title": "a"},
{"class": "bx bx-dots-horizontal", "title": "a"},
{"class": "bx bx-dots-horizontal-rounded", "title": "a"},
{"class": "bx bx-dollar", "title": "a"},
{"class": "bx bx-directions", "title": "a"},
{"class": "bx bx-desktop", "title": "a"},
{"class": "bx bx-data", "title": "a"},
{"class": "bx bx-compass", "title": "a"},
{"class": "bx bx-crosshair", "title": "a"},
{"class": "bx bx-terminal", "title": "a"},
{"class": "bx bx-cloud", "title": "a"},
{"class": "bx bx-cloud-upload", "title": "a"},
{"class": "bx bx-cloud-download", "title": "a"},
{"class": "bx bx-chart", "title": "a"},
{"class": "bx bx-calendar", "title": "a"},
{"class": "bx bx-calendar-x", "title": "a"},
{"class": "bx bx-calendar-minus", "title": "a"},
{"class": "bx bx-calendar-check", "title": "a"},
{"class": "bx bx-calendar-plus", "title": "a"},
{"class": "bx bx-buoy", "title": "a"},
{"class": "bx bx-bulb", "title": "a"},
{"class": "bx bx-bluetooth", "title": "a"},
{"class": "bx bx-bug", "title": "a"},
{"class": "bx bx-building", "title": "a"},
{"class": "bx bx-broadcast", "title": "a"},
{"class": "bx bx-briefcase", "title": "a"},
{"class": "bx bx-bookmark-plus", "title": "a"},
{"class": "bx bx-bookmark-minus", "title": "a"},
{"class": "bx bx-", "title": "a"},
{"class": "bx bx-book", "title": "a"},
{"class": "bx bx-book-bookmark", "title": "a"},
{"class": "bx bx-block", "title": "a"},
{"class": "bx bx-basketball", "title": "a"},
{"class": "bx bx-bar-chart", "title": "a"},
{"class": "bx bx-bar-chart-square", "title": "a"},
{"class": "bx bx-bar-chart-alt", "title": "a"},
{"class": "bx bx-at", "title": "a"},
{"class": "bx bx-archive", "title": "a"},
{"class": "bx bx-zoom-out", "title": "a"},
{"class": "bx bx-zoom-in", "title": "a"},
{"class": "bx bx-x-circle", "title": "a"},
{"class": "bx bx-volume", "title": "a"},
{"class": "bx bx-volume-mute", "title": "a"},
{"class": "bx bx-volume-low", "title": "a"},
{"class": "bx bx-volume-full", "title": "a"},
{"class": "bx bx-video", "title": "a"},
{"class": "bx bx-vertical-center", "title": "a"},
{"class": "bx bx-up-arrow-circle", "title": "a"},
{"class": "bx bx-trending-up", "title": "a"},
{"class": "bx bx-trending-down", "title": "a"},
{"class": "bx bx-toggle-right", "title": "a"},
{"class": "bx bx-toggle-left", "title": "a"},
{"class": "bx bx-time", "title": "a"},
{"class": "bx bx-sync", "title": "a"},
{"class": "bx bx-stopwatch", "title": "a"},
{"class": "bx bx-stop", "title": "a"},
{"class": "bx bx-stop-circle", "title": "a"},
{"class": "bx bx-skip-previous", "title": "a"},
{"class": "bx bx-skip-next", "title": "a"},
{"class": "bx bx-show", "title": "a"},
{"class": "bx bx-search", "title": "a"},
{"class": "bx bx-rss", "title": "a"},
{"class": "bx bx-right-top-arrow-circle", "title": "a"},
{"class": "bx bx-right-indent", "title": "a"},
{"class": "bx bx-right-down-arrow-circle", "title": "a"},
{"class": "bx bx-right-arrow-circle", "title": "a"},
{"class": "bx bx-reset", "title": "a"},
{"class": "bx bx-rewind", "title": "a"},
{"class": "bx bx-rectangle", "title": "a"},
{"class": "bx bx-radio-circle", "title": "a"},
{"class": "bx bx-radio-circle-marked", "title": "a"},
{"class": "bx bx-question-mark", "title": "a"},
{"class": "bx bx-plus-circle", "title": "a"},
{"class": "bx bx-play", "title": "a"},
{"class": "bx bx-play-circle", "title": "a"},
{"class": "bx bx-pause", "title": "a"},
{"class": "bx bx-pause-circle", "title": "a"},
{"class": "bx bx-moon", "title": "a"},
{"class": "bx bx-minus-circle", "title": "a"},
{"class": "bx bx-microphone", "title": "a"},
{"class": "bx bx-menu", "title": "a"},
{"class": "bx bx-left-top-arrow-circle", "title": "a"},
{"class": "bx bx-left-indent", "title": "a"},
{"class": "bx bx-left-down-arrow-circle", "title": "a"},
{"class": "bx bx-left-arrow-circle", "title": "a"},
{"class": "bx bx-last-page", "title": "a"},
{"class": "bx bx-key", "title": "a"},
{"class": "bx bx-align-justify", "title": "a"},
{"class": "bx bx-info-circle", "title": "a"},
{"class": "bx bx-image", "title": "a"},
{"class": "bx bx-hide", "title": "a"},
{"class": "bx bx-fullscreen", "title": "a"},
{"class": "bx bx-folder", "title": "a"},
{"class": "bx bx-folder-plus", "title": "a"},
{"class": "bx bx-folder-minus", "title": "a"},
{"class": "bx bx-first-page", "title": "a"},
{"class": "bx bx-fast-forward", "title": "a"},
{"class": "bx bx-fast-forward-circle", "title": "a"},
{"class": "bx bx-exit-fullscreen", "title": "a"},
{"class": "bx bx-error", "title": "a"},
{"class": "bx bx-error-circle", "title": "a"},
{"class": "bx bx-down-arrow-circle", "title": "a"},
{"class": "bx bx-copyright", "title": "a"},
{"class": "bx bx-copy", "title": "a"},
{"class": "bx bx-coffee", "title": "a"},
{"class": "bx bx-code", "title": "a"},
{"class": "bx bx-code-curly", "title": "a"},
{"class": "bx bx-clipboard", "title": "a"},
{"class": "bx bx-chevrons-left", "title": "a"},
{"class": "bx bx-chevrons-right", "title": "a"},
{"class": "bx bx-chevrons-up", "title": "a"},
{"class": "bx bx-chevrons-down", "title": "a"},
{"class": "bx bx-chevron-right", "title": "a"},
{"class": "bx bx-chevron-left", "title": "a"},
{"class": "bx bx-chevron-up", "title": "a"},
{"class": "bx bx-chevron-down", "title": "a"},
{"class": "bx bx-checkbox-square", "title": "a"},
{"class": "bx bx-checkbox", "title": "a"},
{"class": "bx bx-checkbox-checked", "title": "a"},
{"class": "bx bx-captions", "title": "a"},
{"class": "bx bx-camera", "title": "a"},
{"class": "bx bx-camera-off", "title": "a"},
{"class": "bx bx-bullseye", "title": "a"},
{"class": "bx bx-bookmarks", "title": "a"},
{"class": "bx bx-bookmark", "title": "a"},
{"class": "bx bx-bell", "title": "a"},
{"class": "bx bx-bell-plus", "title": "a"},
{"class": "bx bx-bell-off", "title": "a"},
{"class": "bx bx-bell-minus", "title": "a"},
{"class": "bx bx-arrow-back", "title": "a"},
{"class": "bx bx-align-right", "title": "a"},
{"class": "bx bx-align-middle", "title": "a"},
{"class": "bx bx-align-left", "title": "a"}]

export default {
    bxIcons: icons
}
