export const state = {
    isLayoutMobile: false,
}

export const mutations = {
    CHANGE_IS_LAYOUT_MOBILE(state, isLayoutMobile) {
      state.isLayoutMobile = isLayoutMobile;
    },
}

export const actions = {
  changeIsLayoutMobile({ commit }, isLayoutMobile) {
    commit('CHANGE_IS_LAYOUT_MOBILE', isLayoutMobile);
  },
}
