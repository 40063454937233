import axios from "axios";
import Vue from "vue";

let baseEndpoint = `${window.location.origin}/api/v1/filter`;

export const state = {
  showFilterDropdown: false,
  conditionList: [],
  advancedFilter: {
    id: null,
    data: [
      {
        column: null,
        condition: null,
        value: null,
        isDate: false
      }
    ]
  },
}

export const mutations = {

  CHANGE_ADVANCED_FILTERS(state, data) {
    state.advancedFilter = data;
  },

  CHANGE_CONDITION_LIST(state, data) {
    state.conditionList = data;
  },

  CHANGE_OPTION_LIST(state, data) {
    state.optionList = data;
  },


  ADD_FILTER_DATA(state) {
    let condition = null;
    if(state.conditionList.length > 0) {
        condition = 1;
    }

    state.advancedFilter.data.push({ column: null, condition: condition, value: null });
  },
  REMOVE_FILTER_DATA(state, index) {
    state.advancedFilter.data.splice(index, 1)
  },

  CHECK_IS_DATE(state, {column, index}) {

    let filter =  state.advancedFilter.data.find((data, key) => key == index);
    if (column) {
      if (column.workflow_column_type_id == 5) {
        return Vue.set(filter, 'isDate', true);
      }
    }

    Vue.set(filter, 'isDate', false);
  },

  UPDATE_FILTER_DATA_COLUMN(state, {column, index}) {
    let filter =  state.advancedFilter.data.find((data, key) => key == index);
    if(filter) {
      Vue.set(filter, 'column', column);
    }

  },
  UPDATE_FILTER_DATA_CONDITION(state, {condition, index}) {
    let filter =  state.advancedFilter.data.find((data, key) => key == index);
    if(filter) {
      Vue.set(filter, 'condition', condition);
    }

  },
  UPDATE_FILTER_DATA_VALUE(state, { data, index }) {
    let filter =  state.advancedFilter.data.find((data, key) => key == index);
    if(filter) {
      Vue.set(filter, 'value', data);
    }

  },
  CHANGE_FILTER_DROPDOWN(state, data) {
    state.showFilterDropdown = data;

  }
}

export const actions = {

  saveFilter({ commit }) {
    // console.log("saveFilter action :>> ");

  },

  getContitionList({ commit }) {
    axios.get(`${baseEndpoint}/conditions`)
      .then((response) => {
        var data = response.data.data;
        let conditions = [];
        data.forEach((filterCondition) => {
          var content = {
            value: filterCondition.id,
            label: filterCondition.name,
          };
          conditions.push(content);
        });

        commit('CHANGE_CONDITION_LIST', conditions);
      })
      .catch((error) => {
        if (error.response) {
          console.log('Get condition list error :>> ', error.response.data.message);
        }
      });
  },

  setAdvancedFilter({commit}, data) {
    commit('CHANGE_ADVANCED_FILTER', data);
  },
  addFilterData({commit}) {
    commit('ADD_FILTER_DATA');
  },
  removeFilterData({state, dispatch, commit}, {index, id}) {
      commit('REMOVE_FILTER_DATA', index);
      if(state.advancedFilter.data.length == 0) {
        dispatch('addFilterData');
      }
  },
  checkFilterDateColumn({commit}, {column, index}) {
    commit('CHECK_IS_DATE', {column, index})
  },
  setFilterColumn({commit, dispatch, state}, {column, index}) {
    let condition = null;
    if(state.conditionList.length > 0) {
        condition = 1;
    }

    dispatch('setFilterCondition', {condition: condition, index});
    dispatch('setFilterValue', {data: null, index});
    dispatch('checkFilterDateColumn', {column, index});
    commit('UPDATE_FILTER_DATA_COLUMN', {column, index})
  },
  setFilterCondition({commit}, {condition, index}) {
    commit('UPDATE_FILTER_DATA_CONDITION', {condition, index})
  },
  setFilterValue({commit}, {data, index}) {
    commit('UPDATE_FILTER_DATA_VALUE', {data, index})
  },
  setFilterDropdown({commit}, data) {
    commit('CHANGE_FILTER_DROPDOWN', data);
  },
  clearAdvancedFilters({commit}) {
    let filter = {
        id: null,
        data: [
          {
            column: null,
            condition: null,
            value: null,
            isDate: false
          }
        ]
    }
    commit('CHANGE_ADVANCED_FILTERS', filter);
  }

}

export const getters = {
  advancedFiltersData(state) {
    return state.advancedFilter.data.filter((data)=>{
        return !!data.column;
    })
  }
}
