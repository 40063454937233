<template>
    <div class="row overflow-group" :style="{ 'width': !group.show_task ? '' : `${groupDefaultWidth}` }">
        <div class="col-12 pe-0 ps-0">
            <!-- BEGIN: Card -->
            <div class="card card-min-width" :class="{'all-groups-colapsed': allGroupsColapsed}" :style="!allGroupsColapsed ? `min-width: ${totalWidth + 128}px !important` : ''" >
                <!-- style="width: fit-content;" -->
                 <!-- BEGIN: Card header -->
                <div class="card-header h-100 p-0 border-shadow group-drag-handle sticky-top group-top-sticky-distance bg-default"
                  :style="[{ 'z-index': doubleGroupsLength }]">
                  <div class="d-flex group-title justify-content-between align-items-center" > <!-- style="width: 100vw;" -->
                    <div class="d-flex justify-content-start align-items-center left-freez ps-0  left-70 group-header-height">
                        <!-- BEGIN: group indicator -->
                      <!-- <div :class="[{ 'bg-secondary': !group.color }, `bg-${group.color}`]"
                        class="group-indicator group-indicator-border-radius-top group-indicator-height-top">
                      </div> -->
                      <!--  BEGIN: Group color indicator -->
                      <div class="d-flex align-items-center group-header-height position-relative">
                          <div :class="[{ 'bg-secondary': !group.color }, `bg-${group.color}`]" class="group-indicator p-0 m-0 group-indicator-height-middle group-indicator-border-radius-top  group-indicator-height-top unset-top">
                          </div>
                      </div>

                       <!-- BEGIN: group toggle indicator -->
                      <a href="#" @click.prevent="toggleVisibility(group)"
                        class="btn-link text-decoration-none rem-size-3 text-secondary group-indicator-item-margin ps-1 p-2 font-size-20">
                        <i
                          :class="[{ 'bx bx-chevron-right': !group.show_tasks || hideAllTasks }, { 'bx bx-chevron-down': group.show_tasks || !hideAllTasks }, `text-${group.color}`]"></i>
                      </a>

                      <b-dropdown lazy variant="text-decoration-none"
                        v-show="editShow">
                        <template #button-content>
                          <div class="d-flex align-items-center my-auto">
                            <div class="rounded-circle circle-color-select bg-secondary"
                              :class="[`bg-${group.color ? group.color : 'secondary'}`]">
                            </div>
                          </div>
                        </template>

                        <b-dropdown-item v-for="(color, keyColor) in Object.keys(colors)" :key="keyColor"
                          @click="localGroupUpdate(group, 'color', color)">
                          <div class="row align-items-center" v-b-tooltip="color">
                            <div  class="col-3">
                              <div class="rounded-circle circle-color-select" :class="[`bg-${color}`]">
                              </div>
                            </div>
                            <div class="col-9 ml-3 my-auto">{{ colors[color]
                            }}</div>
                          </div>
                        </b-dropdown-item>
                      </b-dropdown>

                      <!-- BEGIN: group title -->
                      <span class="card-title fw-bold text-white-solid rem-size-3 group-name group m-0"
                        v-show="!editShow"
                        @click="editShow = !editShow">
                          {{ title }}
                      </span>

                    <!-- BEGIN: group title edit -->
                      <span class="card-title fw-bold text-white-solid rem-size-3 group-title-edit m-0"
                        v-show="editShow">
                        <b-input @keyup.enter="localGroupUpdate(group, 'title', title)" type="text" ref="text"
                          v-model="title" :lazy="true" name="group-title-text" class="group-title-text">{{
                            title
                          }}</b-input>
                        <b-input class="d-none group-title-key" :value="groupKey">
                        </b-input>
                        <b-input class="d-none group-title-id" :value="group.id">
                        </b-input>
                      </span>

                      <span class="link-cursor bx bx-save  fs-5 ms-2" @click="localGroupUpdate(group, 'title', title)"
                        v-show="editShow"></span>

                    <div v-if="workflowForms != null">
                        <b-button
                            v-if="workflowForms.length == 1"
                            @click.prevent="showFormCreateTask(workflowForms[0].uuid, group.id)"
                            data-toggle="tooltip"
                            data-placement="top"
                            variant=" text-decoration-none p-0 ms-2 d-flex"
                            title="Novo"

                        ><i class="bx bx-plus text-success rem-size-5 fw-bold"></i></b-button>
                        <b-dropdown lazy
                            v-if="workflowForms.length > 1"
                            class="ps-2"
                            variant=" text-decoration-none p-0 ms-2 d-flex"
                            id="new-content"
                            >
                            <template v-slot:button-content>
                                <i class="bx bx-plus text-success rem-size-5 fw-bold"></i>
                            </template>
                            <b-dropdown-item v-for="(form, formKey) in workflowForms" :key="formKey" @click="showFormCreateTask(form.uuid, group.id)" >{{form.title}}</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    <!-- BEGIN: group menu -->
                      <b-dropdown right
                        variant="btn-link text-decoration-none rem-size-3 text-secondary group-indicator-item-margin ps-1 p-0 menu-button-height"
                        id="new-content" class="m-md-2">
                        <template v-slot:button-content>
                          <i class="dripicons-dots-3 d-flex"></i>
                          <!-- <i class="mdi mdi-dots-vertical"></i> -->
                        </template>

                        <b-dropdown-item @click="toggleAllGroups({show: false, workflow_id: group.workflow_id})">Contrair Todos</b-dropdown-item>
                        <b-dropdown-item @click="toggleAllGroups({show: true, workflow_id: group.workflow_id})">Expandir Todos</b-dropdown-item>
                        <b-dropdown-item @click="destroyGroup(group)">Excluir</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <div class="row group-title justify-content-between align-items-center ms-0 mt-n3"
                    v-if="!group.show_tasks || hideAllTasks">
                    <div
                      class="d-flex justify-content-start align-items-center left-freez ps-0  left-70 max-w-148">
                      <div :class="[{ 'bg-secondary': !group.color }, `bg-${group.color}`]"
                        class="group-indicator group-indicator-border-radius-bottom group-indicator-height-bottom">
                      </div>
                      <span class="rem-size-2 pt-1" style="margin-left: 32px;"
                        :class="{ 'color-transparent': editShow }">{{
                          group.tasks_quantity + (group.tasks_quantity != 1 ? ' Tarefas' : ' Tarefa')
                        }} </span>
                    </div>
                  </div>
                </div>
              <!-- END: Card header  -->
              <!-- BEGIN: Card body -->
              <div class="card-body pe-3 p-0 group-drag-handle" v-if="!group.show_tasks"><span
                  :style="{ 'width': `${groupDefaultWidth}` }"
                  style="color: transparent; height: 1px; display: block;"></span>
              </div>
              <div
                v-show="group.show_tasks"
                :class="{'d-none': hideAllTasks}"
                class="card-body pe-3 p-0 card-body-group"
                :style="{ 'z-index':   doubleGroupsLength - 1 }"
                >

                <slot name="columns-headers" />

                <slot v-if="group.show_tasks && !hideAllTasks" />
            </div>
               <!-- END: Card body -->
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import ShowForm from "@views/workflows/form/show-form.vue";

    export default {
        components: { ShowForm },
        data() {
            return {
                title: '',
                color: '',
                editShow: false,
                groupDefaultWidth: '100%',
                colors: {
                    'success': 'Verde',
                    'info': 'Azul',
                    'warning': 'Amarelo',
                    'danger': 'Vermelho',
                    'secondary': 'Cinza'
                },
            }
        },
        props: ['group', 'groupKey', 'groupsLength', 'tasksLength', 'workflowForms'],
        computed:{
            ...mapGetters('groups',['allGroupsColapsed']),
            ...mapState('tasks', {
                hideAllTasks: (state) => state.hideAllTasks
            }),
            ...mapState('columns', {
                totalWidth: (state) => state.totalWidth
            }),
            groupZIndex() {
                // return this.groupKey - 1;
                // return this.groupsLength - this.groupKey;
            },
            doubleGroupsLength() {
                let keyValue = (this.groupKey) * 2;
                return (this.groupsLength * 2) - keyValue;
            },
        },
        methods: {
            ...mapActions('groups', ['toggleShowTasksByGroup', 'deleteGroup', 'updateGroup', 'toggleAllGroups']),
            ...mapActions('workflows', ['setSelectedFormUuid', 'setSelectedFormGroup']),
            localGroupUpdate(group, handle, value) {
                let data = {
                    group_id: group.id,
                    name: handle == 'title' ?  value : group.name,
                    color: handle == 'color' ? value : group.color,
                    show_tasks:  handle == 'show_tasks' ? value : group.show_tasks
                }

                this.updateGroup(data)
                this.editShow = false;
            },
            toggleVisibility(group) {
                let show = !group.show_tasks;
                this.toggleShowTasksByGroup({groupId: group.id, show: show});
                this.localGroupUpdate(group, 'show_tasks', show)
            },
            showFormCreateTask(uuid, groupId){
                this.setSelectedFormUuid(uuid)
                this.setSelectedFormGroup(groupId)
                this.$root.$emit('bv::show::modal', 'modal-form-show');
            },
            async destroyGroup(group) {
                await this.$swal
                    .fire({
                    title: `Remover o grupo ${group.name}?`,
                    text: "Todo conteudo relacionado será excluído",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sim, remover!",
                    cancelButtonText: "Cancelar",
                    })
                    .then(async (result) => {
                        if (result.value) {
                            // open load modal
                            this.$swal
                                .fire({
                                    position: "center",
                                    html: "<i class='bx bx-loader-alt bx-spin' style='font-size: 5rem;'></i><p>Removendo</p>",
                                    showCancelButton: false,
                                    showConfirmButton: false,
                                    width: 300,
                                    padding: 50,
                                });

                            let response = await this.deleteGroup(group.id);

                            // close load modal
                            this.$swal.close();
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            console.log(error.response);
                            showAlert(true, error.response.data.message);
                        }

                        // close load modal
                        this.$swal.close();
                    });
            }

        },
        created() {
            this.title = this.group.name
        }

    }
</script>

<style lang="scss" scoped>
.card-min-width {
    min-width: calc(100vw - 74px) !important;
    // left: 8px;
}
.group-header-height {
    height: 36px;
}
.group-top-sticky-distance {
    top: 86px;
    transition: all 0.2s ease;
}
.container-group-top-distance-menu * .group-top-sticky-distance {
    // top: 132px !important;
    top: 141px !important;
}
.menu-button-height {
    height: 20px;
}
.max-w-148 {
    max-width: 148px;
}
</style>
