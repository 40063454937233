import IMask from "imask";

const time = ["HH:MM", "HHH:MM", "-HH:MM", "-HHH:MM"].map((data) => {
  return {
    mask: data,
    translation: {
      HHH: {
        recursive: true,
      },
      MM: {
        recursive: true,
      },
    },
    blocks: {
      HH: {
        mask: IMask.MaskedRange,
        placeholderChar: "00",
        from: -99,
        to: 99,
        maxLength: 2,
      },
      HHH: {
        mask: IMask.MaskedRange,
        placeholderChar: "000",
        from: -999,
        to: 999,
        maxLength: 3,
      },
      MM: {
        mask: IMask.MaskedRange,
        placeholderChar: "00",
        from: 0,
        to: 59,
        maxLength: 2,
      },
    },
    lazy: true
  };
});

const currency = {
  lazy: true,
  mask: 'R$ num',
  blocks: {
    num: {
      mask: Number,
      min: 0,
      thousandsSeparator: '.',
      scale: 2,
      fixed: true,
      radix: ',',
      mapToRadix: [','],
      normalizeZeros: false,
      padFractionalZeros: false,
      rightAlign: true
    },
  }
};

const number = {
    mask: Number,
    scale: 0,
    thousandsSeparator: '',
    padFractionalZeros: false,
    normalizeZeros: true,
    // min: -2147483648,
    // max: 2147483647,
}


export const MASKS = {
  time: time,
  currency: currency,
  number: number,
};
