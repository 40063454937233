<script>

import axios from 'axios';
import showAlert from '../../components/alerts'
import config from '../../config/config'
import Recaptcha from '../../components/Recaptcha.vue'
import {
    required,
    email,
    minLength,
    sameAs,
    maxLength,
} from "vuelidate/lib/validators";

export default {
    components: {Recaptcha},
  data() {
    return {
        title: "Recuperar Senha",
        isResetError: false,
        submitted: false,
        companyLogo: null,
        showRecaptcha: true,
        alert:{
            span: false,
            isErro: false,
            message: null,
        },
        form:{
          email: "",
          token: "",
          password: "",
          password_confirmation: "",
          recaptcha_token: ""
      },
    };
  },
  validations:{
      form:{
          email:{
              required,
              email,
              minLength: minLength(6),
          },
          password:{
              required,
              minLength: minLength(8),
              maxLength: maxLength(20),
          },
          password_confirmation:{
              required,
              minLength: minLength(8),
              maxLength: maxLength(20),
              sameAsPassword: sameAs("password")
          }
      }
  },

  props: {
        submitUrl: {
            type: String,
            required: true
        },
        email: {
            type: String,
            required: false,
            default: () => null
        },
        error: {
            type: String,
            required: false,
            default: () => null
        },
        resetToken: {
            type: String,
            required: true
        },
    },
    mounted() {
      this.getCompanyLogoFilepath()
      this.isResetError = !!this.error;
      this.form.email = this.email;

      document.body.classList.add("auth-body-bg");
      document.body.classList.add("min-height-unset");
  },
  methods:{
      recaptchaCallback(token){
          this.form.recaptcha_token = token;
      },
      formValidate(e) {
        this.submitted = true;
        this.$v.$touch();
        e.preventDefault();


        if(!this.$v.$anyError){
            this.form.token = this.resetToken;
            const register = axios.post(this.submitUrl, this.form)
            .then( response => {
                if(response.status == 201 || response.status == 200){
                    showAlert(false, 'Senha redefinida com sucesso');
                    window.location.href = '/logout';
                    this.$router.push({path: "/logout"});
                }

            }).catch(error => {
                if(error.response) {
                    showAlert(true, error.response.data.message)
                }
                this.showRecaptcha = false;
                setTimeout(() => {
                    this.showRecaptcha = true;
                }, 200);
            })
        }
      },
      getCompanyLogoFilepath(){
        const getCompany = axios.get('/api/v1/company/logo/filepath')
            .then( response => {
                if(response.status > 199 || response.status < 300){
                    if(response.data.data){
                    var path = response.data.data.path;
                    this.companyLogo = path;
                    localStorage.setItem('logo',  window.location.origin+"/"+path);
                  }
                }

            }).catch(error => {
                console.log(error);
            })
    },
  }

};
</script>

<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col-xl-9">
          <div class="auth-full-bg pt-lg-5 p-4">
            <div class="w-100">
              <div class="bg-overlay"></div>
              <div class="d-flex h-100 flex-column">
                <div class="p-4 mt-auto">
                  <div class="row justify-content-center">
                    <div class="col-lg-7">
                      <div class="text-center">
                        <h4 class="mb-3">
                          <i
                            class="bx bxs-quote-alt-left text-primary h1 align-middle mr-3"
                          ></i
                          ><span class="text-primary">80%</span>+ Em aumento de produtividade

                        </h4>

                        <div dir="ltr">
                          <b-carousel id="carousel-1" :interval="5000">
                            <b-carousel-slide>
                              <p class="font-size-16 mb-4 text-white">
                                "Sistema fantástico com uma tonelada de opções. Economizou diversas horas de trabalho e me ajudou
                               a ter fins de semanas mais tranquilos."
                              </p>
                              <div>
                                <h4 class="font-size-16 text-primary">
                                  Roberto
                                </h4>
                                <p class="font-size-14 mb-0 text-white">Arquiteto de software</p>
                              </div>
                            </b-carousel-slide>


                            <b-carousel-slide>
                              <p class="font-size-16 mb-4 text-white">
                                "Se todos os funcionarios da empresa são tão solícitos quanto a
                                  equipe de suporte, o desenvolvimento será uma experiência agradável.
                                   Vocês são maravilhosos. Mantenham o bom trabalho. "
                              </p>
                              <div>
                                <h4 class="font-size-16 text-primary">
                                  Larissa
                                </h4>
                                <p class="font-size-14 mb-0 text-white">Gerente de projetos</p>
                              </div>
                            </b-carousel-slide>
                          </b-carousel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-xl-3">
          <div class="auth-full-page-content p-md-5 p-4">
            <div class="w-100">
              <div class="d-flex flex-column h-100">
                <div class="mb-5 mt-2 mb-md-5 mt-md-0" v-if="companyLogo">
                  <a href="/" class="d-block auth-logo d-flex justify-content-center">
                    <img
                      :src="companyLogo? companyLogo : '/images/dalth-logo.png'"
                      alt=""
                      width="55%"
                      class="auth-logo-dark"
                    />
                    <img
                      :src="companyLogo? companyLogo : '/images/dalth-logo.png'"
                      alt=""
                      width="55%"
                      class="auth-logo-light"
                    />
                  </a>
                </div>
                <div class="my-auto">
                  <div>
                    <h5 class="text-primary">Recuperação de senha</h5>
                    <p class="text-muted">Defina uma nova senha</p>
                  </div>

                  <div class="mt-4">
                    <div v-if="alert.span">

                          <div v-if="alert.isError" class="alert text-center alert-danger mb-4">
                              <span>{{alert.message}}</span>
                          </div>
                          <div v-else class="alert text-center alert-success mb-4">
                            <span>{{alert.message}}</span>
                          </div>
                      </div>
                     <b-form class="p-2" :action="submitUrl" method="POST" @submit.prevent="formValidate">
                        <slot />
                      <b-form-group id="email-group" label="E-mail" label-for="email" class="mb-3">
                        <input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        :class="{ 'is-invalid': submitted && $v.form.email.$error }"
                        placeholder="Digite seu e-mail"
                        v-model="form.email"
                        />

                        <div
                            v-if="submitted && $v.form.email.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.form.email.required">Insira um e-mail</span>
                            <span v-else-if="!$v.form.email.email">Insira um e-mail válido</span>
                            <span v-else-if="!$v.form.email.minLength">O email deve conter no mínimo 6 caracteres</span>
                        </div>
                    </b-form-group>
                    <!--  -->
                    <!-- <b-form-group id="email-group" label="E-mail" label-for="email" class="mb-3">
                        <b-form-input
                        type="email"
                        class="form-control"
                        id="email"
                        name="email"
                        placeholder="Digite seu e-mail"
                        :class="{ 'is-invalid': submitted && $v.form.email.$error }"
                        v-model="form.email"
                        ></b-form-input>
                        <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                            <span v-if="!$v.form.email.required">Insira um e-mail</span>
                            <span v-else-if="!$v.form.email.email">Insira um e-mail válido</span>
                            <span v-else-if="!$v.form.email.minLength">O email deve conter no mínimo 6 caracteres</span>
                        </div>
                    </b-form-group> -->
                    <!--  -->
                    <div class="row mb-3">

            <b-form-group id="password-group" label="Senha" label-for="password" class="col-md-12 col-12 mb-3">
              <b-form-input
                id="password"
                v-model="form.password"
                name="password"
                type="password"
                placeholder="Digite uma Senha"
                :class="{ 'is-invalid': submitted && $v.form.password.$error }"
              ></b-form-input>
              <div v-if="submitted && $v.form.password.$error" class="invalid-feedback">
                <span v-if="!$v.form.password.required">Insira uma senha</span>
                <span v-else-if="!$v.form.password.minLength">A senha deve conter no mínimo 6 caracteres</span>
                <span v-else-if="!$v.form.password.maxLength">A senha deve conter no mínimo 20 caracteres</span>
                <span v-else-if="!$v.form.password.sameAsPassword">As senhas são diferentes</span>
              </div>
            </b-form-group>
            </div>
            <div class="row mb-3">
            <b-form-group label="Confirme a Senha" label-for="password-confirm" class="col-md-12 col-12 mb-3">
              <b-form-input
                id="password-confirm"
                v-model="form.password_confirmation"
                name="password_confirmation"
                type="password"
                placeholder="Confirme a senha"
                :class="{ 'is-invalid': submitted && $v.form.password_confirmation.$error }"
              ></b-form-input>
              <div v-if="submitted && $v.form.password_confirmation.$error" class="invalid-feedback">
                <span v-if="!$v.form.password_confirmation.required">Insira uma senha</span>
                <span v-else-if="!$v.form.password_confirmation.minLength">A senha deve conter no mínimo 6 caracteres</span>
                <span v-else-if="!$v.form.password_confirmation.maxLength">A senha deve conter no mínimo 20 caracteres</span>
                <span v-else-if="!$v.form.password_confirmation.sameAsPassword">As senhas são diferentes</span>
              </div>
            </b-form-group>
        </div>
        <b-form-group class="mb-3 login-recaptcha">
                        <Recaptcha :sitekey="siteKey" :callback="recaptchaCallback" v-if="showRecaptcha" />
                    </b-form-group>

                      <div class="form-group row mb-0">
                        <div class="col-12 text-center">
                          <button
                            class="col-12 btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Redefinir
                          </button>
                        </div>
                      </div>
                    </b-form>
                    <div class="mt-3 text-center">
                      <p>
                        Recordou ?
                        <a href="/login" class="fw-medium text-primary">
                          Faça login aqui</a
                        >
                      </p>
                    </div>
                  </div>
                </div>

                <div class="text-center">
                  <p class="mb-0">
                     © {{ new Date().getFullYear() }} Dalth. Criado por Dalth soluções
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container-fluid -->
  </div>
</template>

<style lang="scss" scoped>
::v-deep .carousel-caption {
  position: relative !important;
  right: 0;
  bottom: 1.25rem;
  left: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #495057;
  text-align: center;
}

::v-deep .carousel-indicators li {
  background-color: rgba(85, 110, 230, 0.25);
}
</style>
