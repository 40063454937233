<template>
    <div>
        <b-dropdown-item v-if="checkPlugin(id) && Object.keys(plugin).length > 0" @click="exportReport(plugin.id, taskIds)">{{ plugin.name }}</b-dropdown-item>
    </div>
</template>

<script>
import { pluginsMethods, pluginsComputed } from '@state/helpers';
import showAlert from '../../components/alerts';
import axios from 'axios';
export default {
    data() {
        return {
        }
    },
    props:{
        id:{
            type: Number
        },
        taskIds: {
            type: Array
        }
    },
    computed:{
        ...pluginsComputed,
        plugin() {
            const plugin = this.plugins.find((plugin)=> plugin.id == this.id);
            if(plugin) {
                return plugin;
            }

            return {}
        }
    },
    methods: {
        ...pluginsMethods,
        async exportReport(id, taskIds) {
            let data = {
                plugin_id: id,
                tasks: taskIds
            }

            try {
                let response = await axios.post(window.location.origin + "/api/v1/plugins/report", data, {responseType: 'arraybuffer', Accept: 'application/json'});
                var filename = response.headers['content-disposition'].split('=')[1].replace(/^\"+|\"+$/g, '');
                var url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}))
                var link = document.createElement('a')
                link.href = url
                link.setAttribute('download', filename)
                document.body.appendChild(link)
                link.click()

            } catch(error) {
                const decoder = new TextDecoder();
                const stringsDecoded =  decoder.decode(error.response.data);
                const errorData = JSON.parse(stringsDecoded);
                showAlert(true, errorData.message)
            }
        }
    },
    created() {

    }
}
</script>

<style lang="scss" scoped>

</style>
