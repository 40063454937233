<template>
  <div class="base" :class="{'disabled-opacity': disabled}">
    <span
      @click="toggleShow(true)"
      class="currency-label form-control-plaintext cell-title cell-input text-white-solid p-0 cell-height align-items-center justify-content-center d-flex rem-size-2"
      v-once>0,00</span>

    <input
      @keyup.enter="$event.target.blur()"
      @blur="save()"
      @keyup.esc="cancel"
      type="text"
      :disabled="disabled"
      class="currency-input form-control cell-input cell-edit p-0 ps-1 cell-height text-end d-none"
      placeholder="R$ 0,00"
      v-once
    />
  </div>
</template>

  <script>
import IMask from "imask";
import config from "../../../config/config";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      backupValue: "",
      value: "",
    };
  },
  props: {
    column: {
      default: {},
    },
    columnValue: {
      default: {},
    },
    task: {
      default: {},
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    ...mapActions("columnValues", ["updateColumnValue"]),
    ...mapActions('tasks', ['updateTaskColumnValue']),
    cancel(e) {
      let base = this.$el;

      if (base) {
        let span = base.querySelector(".currency-label");
        let input = base.querySelector(".currency-input");
        input.value = this.backupValue;
        span.textContent = this.backupValue;
        span.classList.remove("d-none");
        span.classList.add("d-flex");
        input.classList.remove("d-flex");
        input.classList.add("d-none");
      }
    },
    toggleShow(showInput = false) {
      let base = this.$el;

      if (base) {
        let input = base.querySelector(".currency-input");
        let span = base.querySelector(".currency-label");

        if (showInput) {
          this.backupValue = input.value;
          let value = parseFloat(
            input.value
              .replace("R$ ", "")
              .replaceAll(".", "")
              .replaceAll(",", ".")
          ).toFixed(2);
          IMask(input, config.masks.currency);
          span.classList.remove("d-flex");
          span.classList.add("d-none");
          input.classList.remove("d-none");
          input.classList.add("d-flex");
          input.focus();
        } else {
          span.classList.remove("d-none");
          span.classList.add("d-flex");
          input.classList.remove("d-flex");
          input.classList.add("d-none");
          input.value = input.value.indexOf(',') != -1 ? input.value : `${input.value},00`
        //   input.value = input.value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
          span.textContent = input.value;
          const imaskInstance = input.dataset.imask;
          if (imaskInstance) imaskInstance.destroy();
        }
      }
    },
    save() {
      let base = this.$el;
      if (base) {
        let input = base.querySelector(".currency-input");
        if (input) {
          let value = parseFloat(
              input.value
                .replace("R$ ", "")
                .replaceAll(".", "")
                .replaceAll(",", ".")
            ).toFixed(2);
          let data = {
            group_id: this.task.workflow_group_id,
            task_id: this.task.id,
            column_type_id: this.column.workflow_column_type_id,
            column_value_id: this.columnValue.id,
            value: value,
            description: "",
          };
          this.updateColumnValue(data);
          this.updateTaskColumnValue(data);
        }
      }
      this.toggleShow();
    },
    clearValue(value) {
        value = value.replaceAll(',', '_');
        value = value.replace('.', ',')
        value = value.replaceAll('_', '.')
        return value
    }
  },
  mounted() {
    this.backupValue = this.columnValue.currency;
    let input = this.$el.querySelector(".currency-input");
    let span = this.$el.querySelector(".currency-label");
    input.value = this.columnValue.currency;
    let clearValue = this.clearValue(this.columnValue.currency);

    if (span && this.columnValue.currency) {
      span.innerHTML = `R$ ${clearValue}`  ;
    }
    if (input) {
        setTimeout(()=>{ input.value = clearValue });
    }
  },
};
</script>

  <style lang="scss" scoped>
</style>
