<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="card" :class="{'dahboard-card-border-radius border-start border-end border-bottom': isDashboard}">
        <div class="card-body">
          <p class="text-start" v-show="showTitle">
            <span class="fs-5 fw-bold text-white">Calendário</span>
            <span class="col rem-size-2 ps-2 text-danger font-size-12" v-if="errorMessage"
              >{{ errorMessage }}*</span
            >
          </p>
          <div class="row">
            <!-- Workflows -->
            <b-form-group
              label="Selecione um Workflow"
              label-for="list-workflows-show"
              class="mb-2 mt-2 col-lg-3 col"
              @submit.stop.prevent
            >
              <b-form-select
                v-model="workflowId"
                @change="getData($event)"
                id="list-workflows-show"
                name="list-workflows-show"
                class="form-select form-select font-size-12"
                size="sm"
              >
                <template #first>
                  <b-form-select-option :value="0"
                    >Selecione</b-form-select-option
                  >
                </template>
                <b-form-select-option
                  v-for="workflow in workflowsWithDeadline"
                  :key="workflow.id"
                  :value="workflow.id"
                  >{{ workflow.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <!-- Users -->
            <b-form-group
              label="Selecione um usuário"
              label-for="list-workflows-show"
              class="mb-2 mt-2 col-lg-3 col"
              @submit.stop.prevent
              v-if="user && user.user_type_id == 1"
            >
              <b-form-select
                v-model="workflowUserId"
                @change="getData()"
                id="list-workflows-show"
                name="list-workflows-show"
                class="form-select form-select font-size-12"
                size="sm"
              >
                <template #first>
                  <b-form-select-option :value="0">Todos</b-form-select-option>
                </template>
                <b-form-select-option
                  v-for="user in usersList"
                  :key="user.id"
                  :value="user.id"
                  >{{ user.full_name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </div>

          <div
            class="row mt-1 dashboard-calendar-container"
            :class="{
              'justify-content-center align-items-center':
                loadDashboardCalendar.data,
            }"
          >
            <span v-if="loadDashboardCalendar.data" class="text-center">
              <i class="bx bx-loader-alt bx-spin fs-1"></i
            ></span>
            <!-- v-show="!loadDashboardCalendar.data" -->
            <div
              v-else
              class="dashboard-full-calendar justify-content-center align-items-center"
            >
              <FullCalendar
                id="dashboard-full-calendar"
                ref="dashboardFullCalendar"
                :options="calendarOptions"
              ></FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- info Modal -->
    <b-modal
      id="dashboard-modal-info"
      title="Informações"
      title-class="text-white font-18"
      hide-footer
      body-class="p-3"
    >
        <SimpleInfo :data="selectedEvent" modal-id="dashboard-modal-info" @close="closeModal" />

    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

// Main Lib
import FullCalendar from "@fullcalendar/vue";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import enLocale from "@fullcalendar/core/locales/en-gb";

//Plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import { mapActions, mapState } from "vuex";
import showAlert from "../../alerts";
import SimpleInfo from "./simple-info"

let fullCalendarLocale = ptBrLocale;
switch (window.document.documentElement.lang.toLocaleLowerCase()) {
  default:
    fullCalendarLocale = enLocale;
    break;
  case "pt-br":
    fullCalendarLocale = ptBrLocale;
    break;
}

const categories = [
  {
    name: "Vencido",
    value: "bg-danger",
  },
  {
    name: "A vencer",
    value: "bg-info",
  },
  {
    name: "Hoje",
    value: "bg-warning",
  },
];

export default {
  components: {
    FullCalendar,
    SimpleInfo
  },
  props: {
    showTitle: {
      default: true,
    },
    isDashboard: {
        default: false
    }
  },
  data() {
    return {
      today: new Date(),
      loadDashboardCalendar: {
        data: true,
      },
      errorMessage: "",
      workflowId: 0,
      workflowUserId: 0,
      calendarOptions: {
        // height: "85vh",
        // contentHeight: 600,
        locale: fullCalendarLocale,
        nowIndicator: true,
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        initialView: "dayGridMonth",
        themeSystem: "bootstrap",
        initialEvents: [],
        editable: false,
        droppable: false,
        eventResizableFromStart: false,
        eventClick: this.showEvent,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        events: this.eventSource,
        displayEventTime: false
      },
      currentEvents: [],
      showModal: false,
      infoModal: false,
      categories: categories,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: "",
        category: "",
      },
      selectedEvent: {
        title: "",
        description: "",
        redirect: "",
        deadline: "",
        complete: false,
        folder: null,
      },
    };
  },
  computed: {
    ...mapState("workflows", {
      workflows: (state) => state.workflows,
    }),
    ...mapState("user", {
      user: (state) => state.user,
    }),
    ...mapState("usersOptions", {
      usersList: (state) => state.usersList,
    }),
    computedWorkflowId() {
      return this.workflowId;
    },
    computedWorkflowUserId() {
      return this.workflowUserId;
    },
    workflowsWithDeadline() {
      return this.workflows.filter((workflow) => workflow.has_event_monitor);
    },
  },
  methods: {
    ...mapActions("workflows", ["listWorkflows"]),
    ...mapActions("usersOptions", ["getUsersList"]),
    ...mapActions("user", ["getUser"]),
    eventSource(fetchInfo, successCallback, failureCallback) {
      let url = `${window.location.origin}/api/v1/dashboard/calendar/events`;
      let vue = this;
      let data = {
        workflow_user_id: this.computedWorkflowUserId,
        workflow_id: this.computedWorkflowId,
        start: fetchInfo.start,
        end: fetchInfo.end,
      };

      axios
        .post(url, data)
        .then(function (response) {
          successCallback(response.data.data);
        })
        .catch(function (error) {
          if (error.response) {
            vue.errorMessage = error.response.data.message;
          }
          failureCallback(error);
        });
    },
    getData(workflowId = null) {
      this.loadDashboardCalendar.data = true;
      if (workflowId) {
        this.workflowUserId = 0;
        this.errorMessage = "";
        this.getUsersList(workflowId);
      }

      setTimeout(() => {
        this.loadDashboardCalendar.data = false;
      }, 100);
    },

    removeAll() {
      let calendarApi = this.$refs.dashboardFullCalendar.getApi();
      calendarApi.removeAllEvents();
    },

    /**
     * Modal open for show event
     */
    showEvent(info) {
      let data = info.event;
      this.selectedEvent.title = data.title;
      this.selectedEvent.deadline = data.start;
      this.selectedEvent.folder = data.extendedProps.folder ? data.extendedProps.folder : null;
      this.selectedEvent.complete = data.extendedProps.complete;
      this.selectedEvent.description = data.extendedProps.description
        ? data.extendedProps.description
        : "";
      this.selectedEvent.redirect = data.extendedProps.redirect_to
        ? data.extendedProps.redirect_to
        : "";
      this.infoModal = true;
      this.$root.$emit("bv::show::modal", "dashboard-modal-info");
    },
    closeModal() {
      this.$root.$emit("bv::hide::modal", "dashboard-modal-info");
      this.selectedEvent = {
        title: "",
        description: "",
        deadline: "",
        redirect: "",
        complete: false,
        folder: null,
      };
    },
  },
  mounted() {
    this.listWorkflows();
    if (this.workflowsWithDeadline.length > 0) {
      this.workflowId = this.workflowsWithDeadline[0].id;
      this.getData(this.workflowId);
    }

    this.$watch(
      () => this.workflowsWithDeadline,
      (att, old) => {
        if (old.length == 0 && att.length > 0) {
          this.workflowId = att[0].id;
          this.getData(this.workflowId);
        }
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.dashboard-calendar-container {
  min-height: 34vh;
}

</style>
<style>
.calendar-item {
  cursor: pointer !important;
  font-size: 12px !important;
  height: 15px;
}

.dashboard-full-calendar * .fc-event-title {
  font-weight: lighter;
}

/* .dashboard-full-calendar * .fc-daygrid-day-frame {
  min-height: 93px;
} */

.info .fc-daygrid-event-dot {
  border-color: var(--bs-info);
}

.success .fc-daygrid-event-dot {
  border-color: var(--bs-success);
}

.warning .fc-daygrid-event-dot {
  border-color: var(--bs-warning);
}

.danger .fc-daygrid-event-dot {
  border-color: var(--bs-danger);
}

.info {
  background-color: rgba(80, 165, 241, 0.25) !important;
}

.success {
  background-color: rgba(52, 195, 143, 0.25) !important;
}

.warning {
  background-color: rgba(241, 180, 76, 0.25) !important;
}

.danger {
  background-color: rgba(244, 106, 106, 0.25) !important;
}

.fc-day-today {
  background: rgb(255, 255, 255, 0.1) !important;
}

.fc-popover-body {
  background: var(--bs-gray-200);
}

.fc-popover-header {
  background: var(--bs-gray-300);
  color: var(--bs-gray-600);
}

.fc-timegrid-event.fc-v-event {
  padding: 0 !important;
}
.fc .fc-list-event:hover td {
  background-color: rgb(0, 0, 0, 0.1) !important;
}
.fc-list-day-cushion {
  background-color: var(--bs-gray-300);
  color: rgb(255, 255, 255);
}

#dashboard-full-calendar
  *
  table.fc-scrollgrid-sync-table
  tbody
  tr:nth-child(6) {
  display: none;
}

#dashboard-full-calendar * .fc-toolbar-title {
    font-size: 16px !important;
}
#dashboard-full-calendar * .btn {
    padding: .25rem .5rem;
    font-size: 12px;
    line-height: 1.5;
}
</style>
