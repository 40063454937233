<script>
import Vertical from "./vertical";
import Horizontal from "./horizontal";

export default {
  components: { Vertical, Horizontal },
  data() {
    return {};
  },
  props: {
      isConfig: {
        type: Boolean,
        default: false,
        }
    },
  computed: {
    layoutType() {
        return this.$root.layout?.type;
    }
  },
  methods: {},
  mounted() {
    // document.querySelector("html").setAttribute('dir', 'rtl');
  }
};
</script>

<template>
  <div>
    <Vertical :is-config="isConfig" v-if="layoutType === 'vertical'">
      <slot />
    </Vertical>

    <Horizontal :is-config="isConfig" v-if="layoutType === 'horizontal'">
      <slot />
    </Horizontal>
  </div>
</template>
