<script>
import NavBar from "../components/nav-bar";
import SideBar from "../components/side-bar";
import RightBar from "../components/right-bar";
import Footer from "../components/footer";
import { menusComputed, menusMethods, utilsMethods, utilsComputed } from "../state/helpers";

export default {
    components: { NavBar, SideBar, RightBar, Footer },
    data() {
        return {
            isMenuCondensed: false,
            hideFooter: false,
            userArea: false
        };
    },
    created: () => {
        document.body.removeAttribute("data-layout", "horizontal");
        document.body.removeAttribute("data-topbar", "dark");
    },
    mounted() {
        const layout = JSON.parse(localStorage.getItem("layout")) || {};
        if (layout.loader == true) {
            document.getElementById("preloader").style.display = "block";
            document.getElementById("status").style.display = "block";

            setTimeout(function() {
                document.getElementById("preloader").style.display = "none";
                document.getElementById("status").style.display = "none";
            }, 2500);
        } else {
            document.getElementById("preloader").style.display = "none";
            document.getElementById("status").style.display = "none";
        }

        let mainContent = document.querySelector('.page-content-base')
        // if(this.$route.path.indexOf("/workflow/show") >= 0 || this.$route.path.indexOf("/workflow/user/view") >= 0 ) {
        if(this.$route.path.indexOf("/workflow/show") >= 0) {
            this.hideFooter = true;
            document.body.style.overflow = 'auto';
            // document.body.classList.add('body-fit-content')
            if(mainContent) {
                setTimeout(()=>{
                    mainContent.classList.remove('page-content');
                    mainContent.classList.add('page-content-workflow');
                    mainContent.classList.add('container-group-top-distance');
                },100);
            }
        } else if(this.$route.path.indexOf("/workflow/user/view") >= 0 ) {
            this.hideFooter = true;
            document.body.style.overflow = 'hidden';
            // document.body.classList.remover('body-fit-content')
            if(mainContent) {
                mainContent.classList.add('page-content');
                mainContent.classList.remove('page-content-workflow');
            }
        } else {
            this.hideFooter = false;
            document.body.style.overflow = 'auto';
            // document.body.classList.remover('body-fit-content')
            if(mainContent) {
                mainContent.classList.add('page-content');
                mainContent.classList.remove('page-content-workflow');
            }
        }
        if(this.$route.path.indexOf("/workflow/user/view") >= 0) {
            this.userArea = true;
        } else {
            this.userArea = false;
        }

        this.checkViewPort();
        this.$nextTick(() => {
            window.addEventListener('resize', () => { this.checkViewPort()});
        })
    },
    props: {
      isConfig: {
        type: Boolean,
        default: false,
        }
    },
    methods: {
        ...menusMethods,
        ...utilsMethods,
        toggleMenu() {
            document.body.classList.toggle("sidebar-enable");

            if (window.screen.width >= 992) {
                // eslint-disable-next-line no-unused-vars
                document.body.classList.toggle("vertical-collpsed");
            } else {
                // eslint-disable-next-line no-unused-vars
                document.body.classList.remove("vertical-collpsed");
            }
            if(document.body.classList.value.indexOf('sidebar-enable') != -1){
                this.setMenuStatus(false);
            } else {
                this.setMenuStatus(true);
            }
            this.isMenuCondensed = !this.isMenuCondensed;
        },
        toggleRightSidebar() {
            document.body.classList.toggle("right-bar-enabled");
        },
        hideRightSidebar() {
            document.body.classList.remove("right-bar-enabled");
        },
        checkViewPort(){
            if(window.innerWidth <= 990 ){
                this.changeIsLayoutMobile(true);
            } else {
                this.changeIsLayoutMobile(false);
            }
        },
    },
    computed: {
        ...utilsComputed,
    }
};
</script>

<template>
    <div>
        <div id="preloader">
            <div id="status">
                <div class="spinner-chase">
                    <div class="chase-dot"></div>
                    <div class="chase-dot"></div>
                    <div class="chase-dot"></div>
                    <div class="chase-dot"></div>
                    <div class="chase-dot"></div>
                    <div class="chase-dot"></div>
                </div>
            </div>
        </div>
        <div id="layout-wrapper">
            <NavBar />
            <SideBar :is-config="isConfig" :is-condensed="isMenuCondensed" :is-fixed="hideFooter" />
            <!-- ============================================================== -->
            <!-- Start Page Content here -->
            <!-- ============================================================== -->

            <div class="main-content">
                <div class="page-content-base page-content fill-content-sm" :class="{'fit-contentdd pe-1 ps-0': hideFooter}" >
                    <!-- Start Content-->
                    <div class="container-fluid layout-container"  :class="{'p-0': hideFooter && !isLayoutMobile, 'position-user-mobile': isLayoutMobile && userArea}" >
                        <slot />
                    </div>
                </div>
                <Footer v-if="!hideFooter" />
            </div>
            <!-- <RightBar /> -->
        </div>
    </div>
</template>
<style scoped>
.fit-content{
    width: -webkit-fill-available;
    width: -moz-available;
    height: -webkit-fill-available;
    height: -moz-available;
}
/* .body-overflow{
    overflow: hidden;
} */
.page-content-workflow{
    position: absolute;
    /* top: 185px; */
    left: 60px;
    width: 100%;
    /* top: 110px; */
    /* top: 70px;
    top: 72px;
    left: 70px; */
    /* overflow-x: auto;
    height: 90vh;
    margin-top: 71px; */
}
.top-185 {
    top: 185px;
}
.top-110 {
    top: 110px;
}
</style>
