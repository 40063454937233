<script>
import Layout from "../layouts/main.vue";
import PageHeader from "../components/page-header.vue";
import PieChart from "../components/charts/PieChart.vue";
import axios from 'axios'
import config from "../config/config.js";
import showAlert from "../components/alerts.js";
import Stat from "../components/widgets/stat.vue";
import usersUpdateFormVue from "./maintenance/users/components/users-update-form.vue";
import DashboardEdit from "../components/home/dashboard/edit.vue";
import DashboardCalendar from "../components/home/dashboard/calendar.vue";
import inactivityDashboard from "../components/home/dashboard/inactivity.vue";
import productivityDashboard from "../components/home/dashboard/productivity.vue";
import {userMethods, userComputed} from '../state/helpers'
import UserHomeComponent from '../components/home/user-home-component.vue'
import {  mapGetters } from "vuex";

 // App Plugins
 import plugins from '@plugins/';


/**
 * Contacts-Profile component
 */
export default {
  components: { Layout, PageHeader, Stat, usersUpdateFormVue,DashboardEdit, DashboardCalendar, PieChart, UserHomeComponent, AaspDashboard: plugins.dashboard, inactivityDashboard, productivityDashboard },
  props:{
    verified:{
        type: String,
        required: false,
        default: ''
    }
  },
  pageTitle() {
    return this.title;
  },
  data() {
    return {
      customDashboard: false,
      title: "Home",
      items: [
        {
          text: "Home",
          active: true
        }
      ],
      modalTitle: '',
      statData: [],
    //   user: null,
      workflows: [],
      workflowSelected: null,
      formUuids: null,
      userWorkflows: null,
      tasksCount: 0,
      tasksForWorkflows: {},
      tableData: [],
      dashboardOptions: [],
      dashboardsData: [],
      pieChartData: {},
      pieChartDataFiltred: {},
      pieChartLoad: false,
      filteredTasksIds: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      filter: null,
      filterOn: [],
      maskSearch: '',
      sortBy: "id",
      sortDesc: false,
      fields: [],
      filters:{
        id: null,
        name: null,
        show: false,
        save: true,
        default: true,
        subjectId: null,
        conditions: [],
        columns: [],
        options: [],
        search: '',
        data: [
            {
                column: null,
                condition: null,
                value: null,
                isDate: false
            }
        ]
      },
      load:{
        dashboards: false,
        dashboardData: false,
        tableData: false,
      },
      ranking:{
        interactions: []
      },
      isDashboardUpdate: false,
      formSearch: {
        search: "",
        dashboard: null
      },
      defaultDashboard: null,
      selectDefaultDashboard: true,
      dashboardTabIndex: 0
    };
  },
  methods: {
    ...userMethods,
    checkMask(param) {
      this.maskSearch = ''
    },
    async getWorkflowsTasks() {
        try {
          const getTasks = await axios.get(window.location.origin + "/api/v1/workflow/task/byuser", null, config.getHeaders);
          var data = response.data.data;
          var uuids = [];

          this.workflows = data.workflows;
          this.formUuids = data.formUuids;

          this.tasksCount = 0;
          var openTasksCount = 0;

          var keys = Object.keys(this.workflows);
          keys.forEach((workflow_key, key) => {
            this.workflows[workflow_key].forEach((workflow, key) => {
              this.tasksCount += workflow.tasks.length;
              this.tasksForWorkflows[`${workflow.id}`] = workflow.tasks.length;
              workflow.tasks.forEach((task, taskKey) => {
                var created_at = this.getDateFormat(task.created_at);
                var status = task.status ? task.status : '-';
                this.tableData.push({ id: task.id, workflow_id: workflow.id, workflow: workflow.name, title: task.name, status: status, created: created_at });
                if (!task.finalized && task.user_id == this.user.id) {
                  openTasksCount++;
                }
              });
            });
          });

        } catch(error){
            if (error.response) {
              showAlert(true, error.response.data.message);
            }
        }
    },
    getUserWorkflows() {
      const getTasks = axios
        .get(
          window.location.origin + "/api/v1/user-workflows",
          null,
          config.getHeaders
        )
        .then((response) => {
          var data = response.data;
          this.userWorkflows = data;
        })
        .catch((error) => {
          if (error.response) {
            showAlert(true, error.response.data.message);
          }
        });
    },
    getWorkflowStatusStats(workflowId){
        // this.pieChartLoad = true;
        const getStatus = axios.get("/api/v1/workflow/status/"+workflowId)
        .then((response)=>{
            var data = response.data.data;
            if(!response.data.error) {
                var labels = [];
                var pieData = [];
                var backgroundColor = [];
                var hoverBackgroundColor = [];
                var hoverBorderColor = [];
                var tasksIds = [];

                data.forEach((stat, key)=>{
                    labels[key] = stat.name
                    pieData[key] = stat.value
                    backgroundColor[key] = stat.color
                    hoverBackgroundColor[key] = stat.color
                    tasksIds[key] = stat.tasks_ids
                });

               this.$set(this.pieChartData,'labels', labels);
               this.$set(this.pieChartData,'content', []);
               this.pieChartData.content.push({
                        data: pieData,
                        tasksIds: tasksIds,
                        backgroundColor: backgroundColor,
                        hoverBackgroundColor: hoverBackgroundColor,
                        hoverBorderColor: '#000',
                    });

                // this.pieChartLoad = false;
            }
        }).catch();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    filterTable(row, filters){

        var response = true;
        filters.forEach((filter)=>{

            if(filter.column){
                if(!(typeof row[`${filter.column.key}`].content == undefined || typeof row[`${filter.column.key}`].content == 'undefined' || (row[`${filter.column.key}`].content == '-'  || row[`${filter.column.key}`].content == ''))){
                    this.filters.options.push({key: filter.column.value, value: row[`${filter.column.key}`].content, label: row[`${filter.column.key}`].content});

                    this.filters.data.forEach((data, key) =>{
                        if(data.column) {
                            if(data.column.column_type_id == 5){
                                this.$set(this.filters.data[key], 'isDate', true);
                            } else {
                                this.$set(this.filters.data[key], 'isDate', false);
                            }
                        }
                    });

                }
            }

            if(filter.column && filter.condition){
                if(typeof row[`${filter.column.key}`].content == undefined || typeof row[`${filter.column.key}`].content == 'undefined'){
                    row[`${filter.column.key}`].content = '-';
                }
                switch (filter.condition) {
                    case 1:
                        if(filter.column.column_type_id != 5){
                            if(row[`${filter.column.key}`].content != filter.value){
                                response = false;
                            }
                        } else {
                            if(row[`${filter.column.key}`].content && filter.value){
                                var base_date = row[`${filter.column.key}`].content.split("/");
                                var check_date = filter.value.split("-");

                                base_date = new Date(base_date[2], parseInt(base_date[1])-1, base_date[0]);
                                check_date = new Date(check_date[0], parseInt(check_date[1])-1, check_date[2]);
                                base_date = `${base_date.getFullYear()}/${base_date.getMonth()}/${base_date.getDay()}`
                                check_date = `${check_date.getFullYear()}/${check_date.getMonth()}/${check_date.getDay()}`

                                if(base_date != check_date){
                                    response = false;
                                }
                            }
                        }
                        break;
                    case 2:
                        if(filter.column.column_type_id == 5){
                            if(row[`${filter.column.key}`].content && filter.value){
                                var base_date = row[`${filter.column.key}`].content.split("/");
                                var check_date = filter.value.split("-");

                                base_date = new Date(base_date[2], parseInt(base_date[1])-1, base_date[0]);
                                check_date = new Date(check_date[0], parseInt(check_date[1])-1, check_date[2]);
                                base_date = `${base_date.getFullYear()}/${base_date.getMonth()}/${base_date.getDay()}`
                                check_date = `${check_date.getFullYear()}/${check_date.getMonth()}/${check_date.getDay()}`

                                if(base_date == check_date){
                                    response = false;
                                }
                            }
                        } else {
                            if(row[`${filter.column.key}`].content == filter.value){
                                response = false;
                            }
                        }
                        break;
                    case 3:
                        if(!(typeof row[`${filter.column.key}`].content == undefined || typeof row[`${filter.column.key}`].content == 'undefined' || (row[`${filter.column.key}`].content == '-'  || row[`${filter.column.key}`].content == ''))){
                            response = false;
                        }
                        break;
                    case 4:
                        if(typeof row[`${filter.column.key}`].content == undefined || typeof row[`${filter.column.key}`].content == 'undefined' || (row[`${filter.column.key}`].content == '-'  || row[`${filter.column.key}`].content == '')){
                            response = false;
                        }
                        break;
                    case 5:

                        if(row[`${filter.column.key}`].content){

                            if(filter.column.column_type_id == 5 && filter.value){
                                var base_date = row[`${filter.column.key}`].content.split("/");
                                var check_date = filter.value.split("-");

                                if(base_date.length == 3 && check_date.length == 3){

                                    base_date = new Date(base_date[2], parseInt(base_date[1])-1, base_date[0]);
                                    check_date = new Date(check_date[0], parseInt(check_date[1])-1, check_date[2]);
                                    base_date = `${base_date.getFullYear()}/${base_date.getMonth()}/${base_date.getDay()}`
                                    check_date = `${check_date.getFullYear()}/${check_date.getMonth()}/${check_date.getDay()}`

                                    if(base_date.indexOf(check_date)  < 0){
                                        response = false;
                                    }
                                } else {
                                    response = false;
                                }
                            } else {
                                var base = row[`${filter.column.key}`].content.toLowerCase();
                                var search = filter.value? filter.value.toLowerCase() : '';
                                if(base.indexOf(search)  < 0){
                                    response = false;
                                }
                            }
                        }
                        break;
                    case 6:
                        if(filter.column.column_type_id == 5){

                            if(row[`${filter.column.key}`].content && filter.value){
                                var base_date = row[`${filter.column.key}`].content.split("/");
                                var check_date = filter.value.split("-");

                                base_date = new Date(base_date[2], parseInt(base_date[1])-1, base_date[0]);
                                check_date = new Date(check_date[0], parseInt(check_date[1])-1, check_date[2]);

                                if(base_date <= check_date){
                                    response = false;
                                }
                            }
                        }

                        if(filter.column.column_type_id == 13){
                            if(parseInt(row[`${filter.column.key}`].content) < parseInt(filter.value) ){
                                response = false;
                            }

                        }

                        if(filter.column.column_type_id != 5 && filter.column.column_type_id != 12 && filter.column.column_type_id != 13) {
                            if( (row[`${filter.column.key}`].content < filter.value)){
                                response = false;
                            }
                        }
                        break;
                    case 7:
                        if(filter.column.column_type_id == 5){
                            if(row[`${filter.column.key}`].content && filter.value){
                                var base_date = row[`${filter.column.key}`].content.split("/");
                                var check_date = filter.value.split("-");

                                base_date = new Date(base_date[2], parseInt(base_date[1])-1, base_date[0]);
                                check_date = new Date(check_date[0], parseInt(check_date[1])-1, check_date[2]);

                                if( (base_date >= check_date)){
                                    response = false;
                                }
                            }
                        } else if(row.column_type_id == 13){
                            if(parseInt(row[`${filter.column.key}`].content) > parseInt(filter.value)){
                                response = false;
                            }
                        } else {
                            if( (row[`${filter.column.key}`].content > filter.value)){
                                response = false;
                            }
                        }
                        break;
                }
                if(!response){
                    return response;
                }
            }
        });

        const parsed_array = this.filters.options.map(val=>{return JSON.stringify(val)})
        const filtered_array = parsed_array.filter((value, ind)=> parsed_array.indexOf(value) == ind).map((val)=>{return JSON.parse(val)})
        this.$set(this.filters, 'options', filtered_array);

        if(response) {
            this.filteredTasksIds.push(row.id)
        } else {
            this.filteredTasksIds.splice(this.filteredTasksIds.indexOf(row.id), 1);
        }

        this.filteredTasksIds = [...new Set(this.filteredTasksIds)];

        return response;

    },
    handleInput(value, data) {
      this.editableDataItems[data.index][data.field.key] = value;
    },
    openModal(id, isDashboardUpdate = false){
        this.modalTitle = 'Dashboards';
        this.isDashboardUpdate = isDashboardUpdate;
        this.$root.$emit('bv::show::modal', 'modal-dashboard-edit');
    },

    //dashboards
    getDashboards(selectDefaultDashboard = true){
        this.load.dashboards = true;
        this.selectDefaultDashboard = selectDefaultDashboard;
        const getTasks = axios
        .get(window.location.origin + "/api/v1/dashboard")
        .then((response) => {
          var data = response.data.data;
          var dashboardIds = [];
          this.$set(this, 'dashboardOptions', data)
          data.forEach((content, key)=>{
              dashboardIds.push(content.id)
              this.fields[content.id] = [];
              this.filters.columns[content.id] = [];
          });

          data.forEach((content, key)=>{

            this.$set( this.fields[content.id], 0, { key: "workflow_id", label: "workflow_id", sortable: true, class: "text-center align-middle checkbox cell-dark overflow-text d-none" })
            this.$set( this.fields[content.id], 0, { key: "column_type_id", label: "column_type_id", sortable: true, class: "text-center align-middle checkbox cell-dark overflow-text d-none" })
            // this.$set( this.fields[content.id], 1, { key: "workflow_name", label: "Workflow", sortable: true, class: "text-center align-middle checkbox cell-dark" })
              content.columns.forEach((item, itemKey)=>{
                var itemKeyValue = item.name.replace(/[^a-zA-Z\s]/g, "").replaceAll(" ", "_").toLowerCase();

                  this.filters.columns[content.id].push({key: itemKeyValue, value: item.workflow_column_id, label: item.name, column_type_id: item.workflow_column_type_id});
                  this.$set(this.fields[content.id], itemKey+2, {key: itemKeyValue, label: item.name, sortable: true, class: "text-center align-middle checkbox cell-dark overflow-text"});

              });

              if(content.default) {
                this.defaultDashboard = content.id;
              }
          });

          this.getDashboardData(dashboardIds);

        this.load.dashboards = false;
        })
        .catch((error) => {
          if (error.response) {
            showAlert(true, error.response.data.message);
          }
        });
    },
    getDashboardData(dashboardsIds){
        this.load.dashboardData = true;

        const getDashboardData = axios
        .post(window.location.origin + "/api/v1/dashboard/workflows",{dashboards_ids: dashboardsIds})
        .then((response) => {
          var data = response.data.data;
            if(!response.data.error){
                this.dashboardsData = data;
                this.load.dashboardData = false;
            }

        })
        .catch((error) => {
          if (error.response) {
            showAlert(true, error.response.data.message);
          }
        });
    },
    deleteDashboard(id){
        var dashboard = this.dashboardsData[id];
        if(dashboard){
            this.$swal
            .fire({
              title: "Confirma a exclusão?",
              text: `Remover o dashboard "${dashboard.name}"?`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Sim, deletar!",
              cancelButtonText: "Cancelar",
            })
            .then((result) => {
              if (result.value) {

                const deleteColumn = axios
                  .delete(
                    window.location.origin + "/api/v1/dashboard/" + id,
                    config.getHeaders
                  )
                  .then((response) => {
                    if (!response.data.error) {
                        this.workflowSelected = null;
                        this.filters.subjectId = null;
                        this.dashboardOptions =  this.dashboardOptions.filter((option)=>{return option.id != id});
                        this.pieChartLoad = true;
                        this.resetPieChart()
                        this.resetStats()
                        setTimeout(() => {
                            this.pieChartLoad = false;
                        }, 100);
                        this.$delete(this.fields[content.id])
                        this.$delete(this.dashboardsData[id])
                        showAlert(false, response.data.message);
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      showAlert(true, error.response.data.message);
                    }
                  });
              }
            });
        }
    },
    setTableData(dashboardId){
        if(!dashboardId){
            this.resetPieChart();
            this.$set(this.ranking, 'interactions', []);
            return;
        }
        this.load.tableData = true;
        var dashboard = this.dashboardsData[dashboardId];

        this.tasksCount = 0;
          var openTasksCount = 0;

            this.tasksCount += dashboard.workflow.tasks.length;
            this.tasksForWorkflows[`${dashboard.workflow.id}`] = dashboard.workflow.tasks.length;
            this.$set(this, 'tableData', []);
            this.$set(this, 'workflowSelected', dashboard.workflow);
            dashboard.workflow.tasks.forEach((task, taskKey) => {
                if(!task.heritage) {
                    var tableDataObject = {};
                    var created_at = this.getDateFormat(task.created_at);
                    var status = task.status ? task.status : '-';
                    tableDataObject['id'] = task.id;
                    tableDataObject['workflow_id'] = dashboard.workflow.id;

                    var taskValuesKeys = Object.keys(task.values);
                    taskValuesKeys.forEach((key)=>{
                        tableDataObject['color'] = task.values[key].color;
                        tableDataObject['column_type_id'] = task.values[key].workflow_column_type_id;
                        tableDataObject[`${task.values[key].name.replace(/[^a-zA-Z\s]/g, "").replaceAll(" ", "_").toLowerCase()}`] = {
                            column_type_id: task.values[key].workflow_column_type_id,
                            content: task.values[key].content,
                            color: task.values[key].color,
                            sequence: task.values[key].sequence
                        };
                    });
                    this.tableData.push(tableDataObject);

                    if (!task.finalized && task.user_id == this.user.id) {
                      openTasksCount++;
                    }
                }
            });

            this.statData = [];
            if (openTasksCount != undefined) {
              this.statData.push({
              //   icon: "bx bx-hourglass",
                icon: "bx bx-folder-open",
                title: "Tickets abertos",
                value: (openTasksCount).toString()
              });
            }
            if (openTasksCount != undefined) {
              this.statData.push({
              //   icon: "bx bx-package",
                icon: "bx bx-check-double",
                title: "Concluidos",
                value: (this.tasksCount - openTasksCount).toString()
              });
            }
            if (openTasksCount != undefined) {
              this.statData.push({
                icon: "bx bx-package",
                title: "Total de tickets",
                value: (this.tasksCount).toString()
              });
            }

            this.getDefaultFilter(dashboardId);
            if(dashboard.workflow.id){
                this.getWorkflowStatusStats(dashboard.workflow.id);
                this.rankingInteractions(dashboard.workflow.id);
            }
            this.load.tableData = false;
    },
    showFilter(){
        if(!this.filters.subjectId){
            this.$refs['select-column-field'].$el.classList.add('shake');
            this.$refs['select-column-field'].$el.focus();
            setTimeout(()=>{
                this.$refs['select-column-field'].$el.classList.remove('shake');
            }, 400);
            return;
        }
       this.$set(this.filters, 'show',  !this.filters.show)
    },
    addFilter(){
        this.filters.data.push({column: null, condition: null, value: null});
    },
    removeFilter(key, id = null){
        this.$delete(this.filters.data, key);

        if(id){
            const deleteFilter = axios.delete('api/v1/filter/item/'+id).catch((error) => {
          if (error.response) {
            showAlert(true, error.response.data.message);
          }
        });
        }
    },
    setFilterOptions(e, filterKey){
        this.$set(this.filters, 'options', []);
        setTimeout(()=>{
            this.$set(this.filters.data[filterKey], 'column', e);
        }, 500);
    },
    clearFilterOptions(){
        this.saveFilter();
        this.$set(this.filters, 'options', []);
    },
    saveFilter(){
        var data = {
            id: this.filters.id ? this.filters.id : null,
            subject_id: this.filters.subjectId? this.filters.subjectId : null,
            name: this.filters.name ? this.filters.name : null,
            // filter_type_id: 1,
            autosave: this.filters.save? true : false,
            default: this.filters.default? true : false ,
            columns: []
        };
        if(this.filters.save){
            this.filters.data.forEach((item)=>{
                var filter_column_id = (typeof item.id != undefined && typeof item.id != 'undefined') ? item.id: null;
                var filter = {
                    id: item.id,
                    column_id: item.column.value,
                    filter_operator_id: item.condition,
                    value: item.value
                }
                data.columns.push(filter);
            });
        }

        const saveFilter = axios.post('api/v1/filter', data)
        .then((response)=>{
            var data = response.data.data
            if(!response.data.error) {
                this.$set(this.filters, 'id', data.id)
                this.$set(this.filters, 'name', data.name)
                this.$set(this.filters, 'save', data.autosave)
                this.$set(this.filters, 'default', data.default)
                this.$set(this.filters, 'filter_type_id', data.filter_type_id)
                this.$set(this.filters, 'subjectId', data.subject_id)
                if(this.filters.save){
                    this.$set( this.filters, 'data', [])

                    data.columns.forEach((item)=>{
                        var itemKey = item.name.replace(/[^a-zA-Z\s]/g, "").replaceAll(" ", "_").toLowerCase();
                        this.filters.data.push({
                            id: item.id,
                            column: {
                                key: itemKey,
                                label: item.name,
                                value: item.column_id,
                                column_type_id: item.item_type_id
                            },
                            condition: item.filter_operator_id,
                            value: item.value,
                            isDate: item.is_date

                        });
                    });
                }

            }

        }).catch((error) => {
          if (error.response) {
            showAlert(true, error.response.data.message);
          }
        });

    },
    getDefaultFilter(id){
        var data = {subject_id: id, filter_type_id: 1};
        const saveFilter = axios.post('api/v1/filter/default', data)
        .then((response)=>{
            var data = response.data.data
            if(!response.data.error) {
                this.$set( this.filters, 'data', [])
                if(Array.isArray(data) && !data.length){
                    return;
                }

                this.$set(this.filters, 'id', data.id)
                this.$set(this.filters, 'name', data.name)
                this.$set(this.filters, 'save', data.autosave)
                this.$set(this.filters, 'default', data.default)


                data.columns.forEach((item)=>{
                    var itemKey = item.name.replace(/[^a-zA-Z\s]/g, "").replaceAll(" ", "_").toLowerCase();
                    this.filters.data.push({
                        id: item.id,
                        column: {
                            key: itemKey,
                            label: item.name,
                            value: item.column_id,
                            column_type_id: item.item_type_id
                        },
                        condition: item.filter_operator_id,
                        value: item.value,
                        isDate: item.is_date

                    });
                });

                setTimeout(()=>{this.pieChartDataProcess(this.filters.data);},500);
            }

        }).catch((error) => {
          if (error.response) {
            showAlert(true, error.response.data.message);
          }
        });
    },
    getFilterConditions(){
        const saveFilter = axios.get('api/v1/filter/conditions')
        .then((response)=>{
            var data = response.data.data;
            this.$set(this.filters, 'conditions', []);
            data.forEach((filterCondition)=>{
                var condition = {
                    value: filterCondition.id,
                    label: filterCondition.name
                }
                this.filters.conditions.push(condition);
            });
        }).catch((error) => {
          if (error.response) {
            showAlert(true, error.response.data.message);
          }
        });
    },
    compareContent(value, filterItemKey){
        if(this.filters.data.length > 0) {
          if(this.filters.data[filterItemKey]) {
            if(this.filters.data[filterItemKey].column != null){
              if(this.filters.data[filterItemKey].column.value == value) {
                  return false;
              }
            }
          }
        }

        return true;
    },
    rankingInteractions(workflowId = 0){
        // let workflowId = this.workflowSelected ? this.workflowSelected.id : 0;
        const rankingInteractions = axios.get('api/v1/dashboard/ranking/interactions/' + workflowId)
        .then((response)=>{
            var data = response.data.data;
            if(!response.data.error){
                this.$set(this.ranking, 'interactions', data);
            }
        }).catch((error) => {
          if (error.response) {
            showAlert(true, error.response.data.message);
          }
        });
    },
    resetPieChart(){
        var labels = ['Completo', 'Em processo', 'Atrasado', 'Pendente'];
        var content = [{data: [0,0,0,0], backgroundColor: ['#20c997','#f1b44c','#f46a6a','#50a5f1'],hoverBackgroundColor: ['#20c997', '#f1b44c', '#f46a6a', '#50a5f1'],hoverBorderColor: '#000', 'tasksIds': []}];
        this.$set(this.pieChartData, 'labels', labels)
        this.$set(this.pieChartData, 'content', content)
    },
    resetStats(){
        this.$set(this, 'statData', [])
        this.statData.push({
            icon: "bx bx-folder-open",
            title: "Tickets abertos",
            value: "0"
        });

        this.statData.push({
            icon: "bx bx-check-double",
            title: "Concluidos",
            value: "0"
        });

        this.statData.push({
            icon: "bx bx-package",
            title: "Total de tickets",
            value:"0"
        });
    },
    sortCompare(aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
        if(aRow[key] !== undefined) {
            const a = aRow[key];
            const b = bRow[key];
            if(a.column_type_id == 3 || a.column_type_id == 8) {
                if(a.sequence > b.sequence) {
                    return 1;
                }
                if(a.sequence < b.sequence) {
                    return -1;
                }
                return 0;
            }
        }
    },
    pieChartDataProcess: function pieChartDataProcess(filterData) {

        this.pieChartLoad = true;
        clearTimeout(pieChartDataProcess.timeout);

        pieChartDataProcess.timeout = setTimeout(() => {
            let pie = this.pieChartData;
            let data = pie.content[0].data;
            let backgroundColor = pie.content[0].backgroundColor;
            let hoverBackgroundColor = pie.content[0].hoverBackgroundColor;
            let hoverBorderColor = pie.content[0].hoverBorderColor;
            let tasksIds = pie.content[0].tasksIds;
            let filteredTasksIds = this.filteredTasksIds;
            let labels = pie.labels;
            let datalocal = [];
            let indexes = [];
            let index = -1;
            let checkTaskIds = (tasksIds, filteredTasksIds, teste = 0) =>{
                let datafilter = tasksIds.filter((value)=> filteredTasksIds.includes(value))
                return datafilter.length
            };


            filterData.forEach((filter)=> {

                if(filter.column){
                    if(filter.column.column_type_id == 3) {
                        switch(filter.condition) {
                            case 1:
                                index = labels.indexOf(filter.value);
                                labels = labels.filter((label, key) =>  key == index && checkTaskIds(tasksIds[key], filteredTasksIds));
                                backgroundColor = backgroundColor.filter((label, key) => key == index && checkTaskIds(tasksIds[key], filteredTasksIds));
                                hoverBackgroundColor = hoverBackgroundColor.filter((label, key) => key == index && checkTaskIds(tasksIds[key], filteredTasksIds));
                                datalocal = [];
                                data.forEach((value, key)=>{
                                    let checkTasks = checkTaskIds(tasksIds[key], filteredTasksIds);
                                    if(key == index && checkTasks) {
                                        datalocal.push(checkTasks);
                                    }
                                });
                                data = datalocal;
                                break;
                            case 2:
                                index = labels.indexOf(filter.value);
                                labels = labels.filter((label, key) => key != index && checkTaskIds(tasksIds[key], filteredTasksIds));
                                datalocal = [];
                                data.forEach((value, key)=>{
                                    let checkTasks = checkTaskIds(tasksIds[key], filteredTasksIds);

                                    if(key != index && checkTasks) {
                                        datalocal.push(checkTasks);
                                    }
                                });
                                data = datalocal;
                                backgroundColor = backgroundColor.filter((label, key) => key != index && checkTaskIds(tasksIds[key], filteredTasksIds));
                                hoverBackgroundColor = hoverBackgroundColor.filter((label, key) => key != index && checkTaskIds(tasksIds[key], filteredTasksIds));
                                break;

                            case 3:
                                indexes = [];
                                labels.forEach((label, key)=> {
                                    if(!label.replaceAll(' ', '') || label.toLowerCase().replaceAll(' ', '') == 'semstatus') {
                                        indexes.push(key);
                                    }
                                });

                                labels = labels.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                data = data.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                backgroundColor = backgroundColor.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                hoverBackgroundColor = hoverBackgroundColor.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                break;

                            case 4:
                                indexes = [];
                                labels.forEach((label, key)=> {
                                    if(label.replaceAll(' ', '') != '' && label.toLowerCase().replaceAll(' ', '') != 'semstatus' && label.replaceAll(' ', '') != null) {
                                        indexes.push(key);
                                    }
                                });

                                labels = labels.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                backgroundColor = backgroundColor.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                hoverBackgroundColor = hoverBackgroundColor.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                datalocal = [];
                                data.forEach((value, key)=>{
                                    let checkTasks = checkTaskIds(tasksIds[key], filteredTasksIds);
                                    if(indexes.indexOf(key) != -1  && checkTasks) {
                                        datalocal.push(checkTasks);
                                    }
                                });
                                data = datalocal;
                                break;

                            case 5:
                                indexes = [];
                                var search = filter.value? filter.value.toLowerCase() : '';
                                labels.forEach((label, key)=> {
                                    var base = label.toLowerCase();
                                    if(base.indexOf(search) != -1) {
                                        indexes.push(key);
                                    }
                                });

                                labels = labels.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                data = data.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                backgroundColor = backgroundColor.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                hoverBackgroundColor = hoverBackgroundColor.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                break;

                            case 6:
                                indexes = [];
                                var search = filter.value? filter.value.toLowerCase() : '';
                                labels.forEach((label, key)=> {
                                    var base = label.toLowerCase();
                                    if(base > search){
                                        indexes.push(key);
                                    }
                                });

                                labels = labels.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                data = data.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                backgroundColor = backgroundColor.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                hoverBackgroundColor = hoverBackgroundColor.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                break;

                            case 7:
                                indexes = [];
                                var search = filter.value? filter.value.toLowerCase() : '';
                                labels.forEach((label, key)=> {
                                    var base = label.toLowerCase();
                                    if(base < search){
                                        indexes.push(key);
                                    }
                                });

                                labels = labels.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                data = data.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                backgroundColor = backgroundColor.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                hoverBackgroundColor = hoverBackgroundColor.filter((label, key) => indexes.indexOf(key) != -1  && checkTaskIds(tasksIds[key], filteredTasksIds));
                                break;
                        }
                    }
                }

            });

            let content = [
                {
                    data: data,
                    backgroundColor:backgroundColor,
                    hoverBackgroundColor:hoverBackgroundColor,
                    hoverBorderColor: hoverBorderColor
                }
            ];

            this.$set(this.pieChartDataFiltred, 'labels', labels);
            this.$set(this.pieChartDataFiltred, 'content', content);

            this.pieChartLoad = false;

        }, 500);

  },
},
computed: {
    ...userComputed,
    ...mapGetters('plugins', ['checkPlugin']),
    rows() {
        return this.tableData.length;
    },
    tableDataFiltred(){
        return this.tableData.filter((data, key)=>{

            var keys = Object.keys(data);
            try {
                var response = false;
                keys.forEach((keyObject)=>{
                    if(typeof data[keyObject] === 'object' || data[keyObject] instanceof Object){
                        if(typeof data[keyObject].content === 'string' || data[keyObject] instanceof String){
                            if(data[keyObject].content.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(this.filters.search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) != -1){
                                response = true;
                            }

                        } else {
                            if(data[keyObject] == this.filters.search.toLowerCase()){
                                response = true;
                            }
                        }
                    } else {
                        if(data[keyObject] == this.filters.search.toLowerCase()){
                            response = true;
                        }
                    }
                });
                return response;
            } catch (error) {
                console.log(error);
            }
        })
    }
  },
  created(){
    // this.resetPieChart()
    // this.resetStats()
    // this.rankingInteractions()

    // this.getFilterConditions();

  },
  mounted() {
    this.totalRows = this.items.length;
    this.getUser();
    // this.getWorkflowsTasks();
    // this.getUserWorkflows();
    // this.getDashboards();
    this.$watch(
        () => this.load.dashboardData,
        (toParams, previousParams)=>{
            if(!toParams && this.selectDefaultDashboard) {
                this.filters.subjectId = this.defaultDashboard;
                this.setTableData(this.defaultDashboard);
            }
        }
    );

    let alertMessage = {};

    if(this.$route.params.verified == 'ZW1haWxfdmVyaWZpY2F0aW9uX3N1Y2Nlc3M=' || this.verified == 'ZW1haWxfdmVyaWZpY2F0aW9uX3N1Y2Nlc3M=') {
    alertMessage = {
        icon: 'success',
        title: 'Parabéns',
        text: 'Seu e-mail foi verificado com sucesso, utilize suas credenciais e aproveite!',
    }
    }
    if(this.$route.params.verified == 'ZW1haWxfdmVyaWZpY2F0aW9uX2Vycm9y' || this.verified == 'ZW1haWxfdmVyaWZpY2F0aW9uX2Vycm9y') {
    alertMessage = {
        icon: 'warning',
        title: 'atenção!',
        text: 'Seu e-mail não pôde ser verificado no momento, entre e tente novamente!',
    }
    }
    if(Object.keys(alertMessage).length > 0) {
    this.$swal
    .fire({
    title: alertMessage.title,
    text: alertMessage.text,
    icon: alertMessage.icon,
    showCancelButton: false,
    confirmButtonColor: "#34c38f",
    confirmButtonText: "Continuar!",
    })
    .then((result) => {
        this.$router.push({ path: "/home"});
    })
    }

    this.$watch(()=> this.filters.data, (att, old)=> {
        this.pieChartDataProcess(att);
    })

  }
};
</script>

<template>
  <Layout>

    <div v-if="user.id" @click="closeParentMenu">
      <div class="row d-none" @mouseenter="filters.show = false">
        <b-col cols="12">
          <div class="card overflow-hidden">
            <div class="bg-soft bg-primary profile-height">
              <div class="row">
                <div class="col-12">
                  <div class="text-white p-3">
                    <h5 class="text-white overflow-text">Bem vindo(a) de volta {{ user.name }} !</h5>
                  </div>
                </div>

              </div>
            </div>
            <div class="card-body pt-0 card-top-height">
              <div class="row">
                <b-col cols="8">
                  <div class="avatar-xl profile-user-wid mb-4">
                    <img v-if="user.avatar" :src="user.avatar" alt
                      class="img-thumbnail rounded-circle img-square" />
                      <b-avatar variant="info" :text="`${user.name?user.name[0]:''}${user.last_name?user.last_name[0]:''}`" class="mr-3 bg-light text-white img-square font-avatar" v-else></b-avatar>
                  </div>
                </b-col>
                <b-col cols="4" class="d-flex justify-content-end mt-n5">
                  <b-img src="/images/profile-img.png" alt class="img-margin-top img-profile-position" width="260px"></b-img>
                </b-col>
              </div>
            </div>
          </div> <!-- end card -->
        </b-col>
      </div> <!-- end row profile -->
      <div  v-if="(user.user_type_id == 1 || user.user_type_id == 2 || user.user_type_id == 4)">
        <b-tabs content-class="" v-model="dashboardTabIndex">
          <b-tab title="Calendário" active>
              <DashboardCalendar :is-dashboard="true" :show-title="false" v-if="dashboardTabIndex == 0" />
          </b-tab>
          <b-tab title="Distribuições" v-if="checkPlugin(1)">
              <AaspDashboard :is-dashboard="true" :show-title="false" v-if="checkPlugin(1) && dashboardTabIndex == 1" />
          </b-tab>
          <b-tab title="Personalizado" class="d-none" v-if="customDashboard">
            <div v-if="dashboardTabIndex == 2 || (!checkPlugin(1) && dashboardTabIndex == 1)">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="card">
                      <div class="card-body">
                          <div class="row mb-2">
                              <div class="col-6 d-flex justify-content-start  align-items-center">
                                  <div class="col-11 col-md-3 d-flex justify-content-start align-items-center">
                                      <b-form-group class="form-text" name="search" label-for="search">
                                        <b-select class="form-control font-size-12" ref="select-column-field" size="sm" v-model="filters.subjectId" @change="setTableData" :disabled="load.dashboards || load.dashboardData || load.tableData">
                                            <b-select-option :value="null">Selecione um dashboard</b-select-option>
                                            <b-select-option v-for="(dashboardOption, dashboardOptionKey) in dashboardOptions" :key="dashboardOptionKey" :value="dashboardOption.id">{{ dashboardOption.name}}</b-select-option>
                                        </b-select>
                                      </b-form-group>
                                  </div>
                                  <div class="col-3 col-md-2 d-flex justify-content-start align-items-center">
                                      <b-form-group class="form-text d-flex m-0" name="search" label=" " label-for="search">
                                          <b-dropdown right variant="text-decoration-none text-secondary">
                                          <template v-slot:button-content>
                                              <span><i class="dripicons-dots-3 fs-6 d-flex align-items-center"></i></span>
                                          </template>
                                          <b-dropdown-item @click="openModal(filters.subjectId, true)" :disabled="!filters.subjectId" :class="{'blocked': !filters.subjectId}">
                                              <div class="row d-flex align-items-center">
                                                  <div class="col-2"><i class="fas fa-pencil-alt fs-6 d-flex align-items-center"></i></div><div class="col">Editar</div>
                                              </div>
                                          </b-dropdown-item>
                                          <b-dropdown-item @click="deleteDashboard(filters.subjectId)" :disabled="!filters.subjectId" :class="{'blocked': !filters.subjectId}">
                                              <div class="row d-flex align-items-center">
                                                  <div class="col-2"><i class="fas fa-trash-alt fs-6 d-flex align-items-center"></i></div><div class="col">Excluir</div>
                                              </div>
                                          </b-dropdown-item>
                                      </b-dropdown>
                                    </b-form-group>
                                  </div>
                              </div>
                              <div class="col-6 d-flex justify-content-end  align-items-center">
                                  <div class="col-1 d-flex justify-content-end align-items-center">
                                      <b-form-group class="form-text d-flex m-0" name="search" label=" " label-for="search">
                                          <b-button variant="outline-success d-flex p-1" size="sm" @click="openModal"><i class="bx bx-plus-medical"></i></b-button>
                                    </b-form-group>
                                  </div>
                              </div>
                          </div>
                          <div class="row gy-1 gx-3 align-items-center justify-content-start form-inline mt-1 border-top" v-show="filters.subjectId">
                                <div class="d-flex justify-content-start align-items-center" style="width: 5vh;">
                                      <b-form-group class="form-text d-flex m-0" name="search" label=" " label-for="search">
                                          <b-button variant=" text-decoration-none border-unset border-none d-flex p-1 font-size-12" no-caret size="sm" @click="showFilter"><i class="fas fa-filter"></i></b-button>
                                    </b-form-group>
                                  </div>
                              <div class="col d-flex justify-content-start align-items-center">
                                  <span class="filter-tag mb-0" :class="{'d-none': !filter.column}" v-for="(filter, filterKey) in filters.data" :key="filterKey">
                                      <span>{{filter.column? filter.column.label: ''}} </span> <i class="filter-tag-icon mdi mdi-close-thick" @click="removeFilter(filterKey, (typeof filter.id != undefined && typeof filter.id != 'undefined'? filter.id : null ))"></i>
                                  </span>
                              </div>
                          </div>
                          <transition name="fade">
                              <div v-show="filters.show"  @mouseenter="filters.show = true">
                                  <div class="row gy-2 gx-3 align-items-center justify-content-start form-inline mt-3" v-for="(filter, filterKey) in filters.data" :key="filterKey">
                                    <div class="col-12 col-md-4">
                                        <b-select class="form-control font-size-12" size="sm" v-model="filters.data[filterKey].column" @change="clearFilterOptions()">
                                            <b-select-option :value="null">Selecione uma coluna</b-select-option>
                                            <b-select-option v-for="(field, fieldKey) in filters.columns[filters.subjectId]" :key="fieldKey" :value="field">{{ field.label }}</b-select-option>
                                        </b-select>
                                    </div>
                                    <div class="col-12 col-md-3">
                                        <b-select class="form-control font-size-12" size="sm" v-model="filters.data[filterKey].condition" @change="clearFilterOptions()">
                                            <b-select-option :value="null">Condição</b-select-option>

                                                <b-select-option v-for="(condition, conditionKey) in filters.conditions" :key="conditionKey" :value="condition.value" :class="{'d-none': condition.value == 5 && filters.data[filterKey].isDate}">{{ condition.label}}</b-select-option>
                                        </b-select>
                                    </div>
                                    <div class="col-12 col-md-5">
                                      <div class="row">

                                          <div class="col-10 col-md-11" v-show="filters.data[filterKey].condition != 3 && filters.data[filterKey].condition != 4">

                                                  <b-select class="form-control font-size-12" size="sm" v-model="filters.data[filterKey].value" v-show="filters.data[filterKey].condition != 5 && !filters.data[filterKey].isDate" @change="saveFilter">
                                                      <b-select-option :value="null">Valor</b-select-option>
                                                      <b-select-option v-for="(filterOption, filterOptionKey) in filters.options" :key="filterOptionKey" :value="filterOption.value" :class="{'d-none': compareContent(filterOption.key, filterKey)}">{{ filterOption.label }}</b-select-option>
                                                  </b-select>

                                                  <b-input placeholder="Valor" v-show="filters.data[filterKey].condition == 5 && !filters.data[filterKey].isDate" v-model="filters.data[filterKey].value" @blur="saveFilter"></b-input>

                                              <b-datepicker
                                                  set-button
                                                  today-button
                                                  locale="pt-br"
                                                  class="filter-datepicker"
                                                  :date-format-options="{ day: 'numeric', month: 'numeric', year: '2-digit'}"
                                                  v-show="filters.data[filterKey].isDate"
                                                  v-model="filters.data[filterKey].value"
                                                  @change="saveFilter"
                                              ></b-datepicker>
                                          </div>
                                          <div class="col-2 col-md-1  d-flex justify-content-end align-items-center">
                                              <b-form-group class="form-text d-flex m-0" name="search" label=" " label-for="search">
                                                  <b-button variant=" text-decoration-none border-unset border-none" size="sm" @click="removeFilter(filterKey, (typeof filter.id != undefined && typeof filter.id != 'undefined'? filter.id : null ))"><span class="mdi mdi-close-thick"></span></b-button>
                                              </b-form-group>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row gy-2 gx-3 align-items-center justify-content-start form-inline mt-3">
                                      <div class="col-6 d-flex justify-content-start  align-items-center">
                                          <div class="col-5 d-flex justify-content-start align-items-center">
                                              <b-form-group class="form-text d-flex m-0" name="search" label=" " label-for="search">
                                                  <b-button variant="outline-success  d-flex align-items-center p-1 font-size-12" size="sm" @click="addFilter"><i class="bx bx-plus-medical"></i> <span class="ps-1" style="margin-top: 1px;">Adicionar Filtro</span> </b-button>
                                          </b-form-group>
                                          </div>
                                      </div>
                                      <div class="col-6">
                                          <div class="row justify-content-end  align-items-center">
                                              <div class="col-3 d-flex justify-content-end font-size-12">
                                                  <label for="save-filters" class="my-auto">Salvar filtros</label>
                                              </div>
                                              <div class="col-1 d-flex justify-content-end align-items-center">
                                                  <div class="form-check form-switch">
                                                      <input
                                                      class="form-check-input"
                                                      type="checkbox"
                                                      id="save-filters"
                                                      checked
                                                      v-model="filters.save"
                                                      @change="saveFilter"
                                                      />
                                                  </div>
                                              </div>

                                          </div>
                                      </div>
                                    </div>
                              </div>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div> <!-- end row filters -->


                <div class="row" @mouseenter="filters.show = false">
                  <div class="col-xl-12">

                    <div class="card">
                      <div class="card-body activity">
                          <div class="row justify-content-between">
                              <div class="col">
                                  <h4 class="card-title mb-4 font-size-16">Meus tickets</h4>
                              </div>
                              <div class="col d-flex justify-content-end" >
                              </div>
                          </div>

                      <transition name="fade">
                          <div class="row justify-content-start align-items-center mb-2" v-if="workflowSelected">
                              <div class="col-sm-auto col-md-3">
                                  <b-form-group class="form-text" name="search" :label="`Workflow: ${(workflowSelected? workflowSelected.name: '')}`" label-for="search">
                                      <b-form-input id="search font-size-12" size="sm" class="mb-3" placeholder="Buscar" v-model.lazy="filters.search"></b-form-input>
                                  </b-form-group>
                                  </div>
                              <div class="col d-flex justify-content-end" >
                                  <div id="tickets-table_length" class="dataTables_length">
                              <label class="d-inline-flex align-items-center">
                                Mostrar&nbsp;
                                <b-form-select v-model="perPage" size="sm" class="form-select form-select-sm font-size-12"
                                  :options="pageOptions"></b-form-select>
                              </label>
                            </div>
                              </div>
                          </div>
                      </transition>
                        <div class="table-responsive mb-0 table-height scr-right scr-bottom">

                          <b-table id="dashboard-table" class="datatables text-decoration-none text-light font-size-12" :items="tableDataFiltred" :fields="filters.subjectId? fields[filters.subjectId] : []" responsive="sm" :per-page="perPage"
                            :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-compare="sortCompare"
                            :filter="filters.data" :filter-included-fields="filterOn" @filtered="onFiltered" :filter-function="filterTable" sticky-header hover
                            v-if="filters.subjectId"
                            >

                            <template v-slot:cell()="data" >
                              <router-link :to="{ path: `workflow/show/${data.item.workflow_id}/${data.item.id}` }" class="text-white link font-size-12">
                                <span class="font-size-12" v-if="data.value.column_type_id != 3 && data.value.column_type_id != 8">{{ data.value.content }}</span>
                                <span class="badge radius-button-border font-size-12 status-width d-flex justify-content-center align-items-center" :class="[data.value.color]" v-else>{{ data.value.content }}</span>
                            </router-link>
                            </template>

                          </b-table>
                          <div class="col-12  d-flex align-items-center justify-content-center h-100" v-if="!filters.subjectId" ref="selectWorkflowButton">
                              <div class="card bg-card alert-height col-12">
                                  <div class="card-body  d-flex align-items-center justify-content-center">
                                      <h5>Selecione uma dashboard</h5>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div class="row  mt-3" v-if="filters.subjectId">
                          <div class="col">
                            <div class="dataTables_paginate paging_simple_numbers float-end">
                              <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" aria-controls="dashboard-table" id="dashboard-table-pagination"></b-pagination>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-modal id="modal-user" size="xl" right :title="'Editar usuário'" title-class="font-18" hide-footer
                      @hide="getUser()">
                      <usersUpdateFormVue :userId="user.id" :isProfile="true" />
                    </b-modal>
                  </div>
                </div> <!-- end row table -->
                <div class="row" v-if="pieChartDataFiltred.content && false" @mouseenter="filters.show = false">

                  <div class="col-12 col-md-8">
                      <div class="card" style="min-height: 69vh;">
                          <div class="card-body">
                              <h4 class="card-title mb-4">Status</h4>
                              <PieChart
                                  ref="pie-chart"
                                  :height="163"
                                  :labels="pieChartDataFiltred.labels"
                                  :datasets="pieChartDataFiltred.content"

                                  v-if="!pieChartLoad"
                                  />
                                  <div v-else class="col-12 d-flex align-items-center justify-content-center vh-39">
                                      <div class="lds-dual-ring"></div>
                                  </div>
                              <div class="row text-center pt-5">
                                  <div class="col" v-for="(data, keyData) in pieChartDataFiltred.content[0].data" :key="keyData">
                                      <h5 class="mb-0">{{ data }}</h5>
                                      <p  :style="{'color': pieChartDataFiltred.content[0].backgroundColor[keyData]}">{{pieChartDataFiltred.labels[keyData]}}</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <!-- Rank more interactions -->
                  <div class="col-12 col-md-4">
                      <div class="card" style="min-height: 69vh;">
                          <div class="card-body">
                              <h4 class="card-title mb-4">Top 5 usuários ativos</h4>
                              <div class="col-12" v-if="pieChartLoad">
                                <div class="col-12 d-flex align-items-center justify-content-center vh-39">
                                    <div class="lds-dual-ring"></div>
                                </div>
                              </div>
                              <div class="col-12 table-responsive" v-if="ranking.interactions.length > 0 && !pieChartLoad">
                                  <table class="table table-hover" v-if="!pieChartLoad">
                                      <thead>
                                          <th class="text-center">#</th>
                                          <th>Name</th>
                                          <th>Quantidade</th>
                                      </thead>
                                      <tbody>
                                          <tr v-for="(user, index) in ranking.interactions" :key="user.id">
                                            <th class="text-center" v-if="index < 3"><div class=" bg-trophy"><i class="bx bx-trophy fs-5" :class="{'gold': index == 0, 'silver': index == 1, 'bronze': index == 2}"></i></div></th>
                                            <th class="text-center" v-else>{{ index +1 }}</th>
                                            <td class="text-start ps-0">{{(user.name? user.name + ' ': '') + (user.last_name? user.last_name : '' )}}</td>
                                            <td class="text-start">{{user.ranking_interactions}}</td>
                                          </tr>
                                      </tbody>
                                  </table>

                              </div>
                              <div class="col-12 d-flex justify-content-center align-items-center" style="min-height: 50vh;" v-else-if="!pieChartLoad && ranking.interactions.length < 0">
                                <div class="d-flex justify-content-center align-items-end">
                                    <i class="bx bx-trophy silver font-size-45"></i>
                                    <i class="bx bx-trophy gold font-size-60"></i>
                                    <i class="bx bx-trophy bronze font-size-40"></i>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
          </b-tab>
          <b-tab  title="Inativos">
            <inactivityDashboard :is-dashboard="true"  :show-title="false"  v-if="dashboardTabIndex == 3 || ((!checkPlugin(1) || !customDashboard && checkPlugin(1)) && dashboardTabIndex == 2) || (!customDashboard && !checkPlugin(1) && dashboardTabIndex == 1)"  />
          </b-tab>
          <b-tab  title="Produtividade">
            <productivityDashboard :is-dashboard="true"  :show-title="false"  v-if="dashboardTabIndex == 4 || ((!checkPlugin(1) || !customDashboard && checkPlugin(1) ) && dashboardTabIndex == 3) || (!customDashboard && !checkPlugin(1) && dashboardTabIndex == 2)"  />
          </b-tab>
        </b-tabs>
      </div>
      <UserHomeComponent :userId="user.id" v-else />
    </div>
    <!-- dashboard edit modal -->
    <b-modal id="modal-dashboard-edit"  size="xl" :title="modalTitle" title-class="font-18" scrollable hide-footer :lazy="true"  @hide="getDashboards(false)">
            <DashboardEdit :id="filters.subjectId" :edit="isDashboardUpdate" />
        </b-modal>
  </Layout>
</template>
<style>
table#dashboard-table tbody td {
    padding-top: .2rem;
    padding-bottom: .2rem;
    max-width: 380px;
}
</style>
<style scoped>
.font-avatar {
    font-size: 4em;
}
.img-square {
  width: 100% !important;
  height: 100% !important;
}
.vh-39{
    height: 39vh;
}

.card-height {
  height: 62vh;
  max-height: 62vh;
}

.table-height {
  height: 76vh;
}

.scr-right::-webkit-scrollbar {
  width: 6px;
}

.scr-bottom::-webkit-scrollbar {
  height: 6px;
}

.scr-right::-webkit-scrollbar-track {
  background: transparent;
  color: black;
  border-radius: 10px;
}

.scr-right::-webkit-scrollbar-thumb:hover {
  background: black;
  opacity: 0.6;
  border-radius: 10px;
}

.scr-right::-webkit-scrollbar-thumb {
  background: #c3cbe4;
  opacity: 0.5;
  padding-left: 5px;
  border-radius: 10px;
}

.profile-height {
  height: 11vh;
}

.img-margin-top {
  /* margin-top: -155px; */
  bottom: 0;
  right: 0;
}
.img-profile-position {
    position: absolute;
    max-width: 180px;
    max-height: 115px;
    height: auto !important;
    width: auto !important;
}

.card-top-height {
  height: 124px;
}
.status-width {
    width: 95%;
    height: 19px;
}
</style>
<style>
/* Filter */
 .filter-datepicker button {
    display: flex !important;
    justify-content: flex-end !important;
    word-break: break-all !important;
    word-wrap: break-word !important;
 }

</style>
<style>
.modal-width-size{
    max-width: 93vw !important;
}
.activity .table-responsive .b-table-sticky-header {
    overflow-y: unset;
    max-height: 300px;
}
.cell-dark{
    background: #2A3042 !important;
    color: #c3cbe4 !important;
    width: 12%;
}
.alert-height{
    height: 72vh !important;
    border: 2px dashed;
    border-radius: 10px;
}
.filter-tag{
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: rgba(85, 110, 230, 0.25);
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
.filter-tag-icon{
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all .2s ease;
    border-radius: 5px;
    opacity: 0.5;
}
.filter-tag-icon:hover{
    opacity: 1;
}

.shake:focus {
  animation: treme 0.1s;
  animation-iteration-count: 3;
}

@keyframes treme {
  0% {margin-left: 0;}
  25% {margin-left: 5px;}
  50% {margin-left: 0;}
  75% {margin-left: -5px;}
  100% {margin-left: 0;}
}
.link{
    opacity: 0.9;
}
.link:hover{
    opacity: 1;
}
.blocked:hover {
    opacity: 0.5;
    cursor: not-allowed! important;
    color: #c3cbe4;
}

/* Trophy colors */
.bg-trophy{
    height: 50px;
    width: 50px;
    display: contents;
    border-radius: 50px;
}
.bronze{
  background: linear-gradient(45deg,  rgba(223,182,103,1) 0%,rgba(249,243,232,1) 56%,rgba(231,192,116,1) 96%);
  -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

.silver{
  background: linear-gradient(45deg,  rgba(160,160,160,1) 0%,rgba(232,232,232,1) 56%);
  -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

.gold{
    background: linear-gradient(45deg,  rgba(242,215,12,1) 0%,rgba(255,255,255,1) 56%,rgba(252,235,0,1) 96%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.stick-search {
    position: fixed !important;
    top: 11vh;
    right: 23px;
    left: 14.8vw;
    z-index: 5001;
}
.page-item.active {
    z-index: 0;
}

.font-size-40 {
    font-size: 40px;
}
.font-size-45 {
    font-size: 45px;
}
.font-size-60 {
    font-size: 60px;
}
.footer {
    display: none;
}
.page-content-base.page-content {
    padding-bottom: 0;
}
#dashboard-table tr {
    height: 25px;
}
#dashboard-table * td {
    padding: 1px;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

#dashboard-table > thead tr th {
    padding: 3px;
}

#dashboard-table > thead tr th::before {
    top: -2px;
}

#dashboard-table > thead tr th::after {
    top: 3px;
}
</style>
