<template>
  <div class="base">
    <span
      @click="toggleShow(true)"
      class="d-flex form-control-plaintext cell-title cell-input text-white-solid p-0 cell-height align-items-center justify-content-center rem-size-2"
    >
      <span v-if="value && hasDeadline(column)" class="text-danger-scoped rem-size-2" :class="{
          'text-warning':
            new Date(value).getDate() == today.getDate() &&
            new Date(value).getMonth() == today.getMonth() &&
            new Date(value).getFullYear() == today.getFullYear() &&
            new Date(value) > today,
          'text-white':
            ((new Date(value).getDate() != today.getDate() ||
            new Date(value).getMonth() != today.getMonth() ||
            new Date(value).getFullYear() != today.getFullYear()) &&
            new Date(value) > today) || task.finalized,
        }">{{ getDateFormat(value) }}</span>
        <span v-else-if="value && !hasDeadline(column)" class="text-white rem-size-2">{{ getDateFormat(value) }}</span>
      <i class="bx bx-calendar fs-4 link-cursor rem-size-3" v-else></i>
    </span>
    <DatePicker
      :class="{ 'd-flex': showEdit, 'd-none': !showEdit }"
      class="cell-input cell-edit p-0 ps-1 cell-height justify-content-center"
      @close="saveDate(value, task, column)"
      @keyup.enter="$event.target.blur()"
      @blur="saveDate(value, task, column)"
      @keyup.esc="cancelEdit()"
      :ref="`cell_${columnValue.id}`"
      v-model="value"
      :lang="datePikerLang"
      type="datetime"
      format="DD/MM/YYYY HH:mm"
      value-type="YYYY-MM-DD H:mm:ss"
      :range="false"
      :default-value="new Date()"
      :disabled-date="disableDateFromCompanyConfig"
      placeholder="00/00/0000 00:00:00"
      :open="showEdit"
      @pick="selectDate(columnValue.id)"
      v-if="showEdit"
    >
      <template #footer>
        <div
          class="row justify-content-center align-items-start pt-1"
          v-show="hasDeadline(column)"
        >
          <div
            class="col-12 col-md-12 d-flex align-items-center justify-content-center"
          >
            <label class="col-form-label text-center rem-size-2 deadline-advance-label" for="deadline-advance"
              >Antecedência</label
            >
          </div>
        </div>
        <div class="row justify-content-between pt-1">
          <div class="col-3 d-flex justify-content-start align-items-center">
            <a
              href="javascript:void(0)"
              class="btn btn-outline-info rem-size-2 input-sm-height d-flex align-items-center"
              @click="save(null)"
              >Limpar</a>
          </div>
          <div
            class="col-2 d-flex justify-content-center align-items-center"
            v-if="hasDeadline(column)"
          >
            <a
              href="javascript:void(0)"
              class="pe-1"
              :class="{ 'disabled-opacity': deadlineAdvance === null }"
              @click.prevent="subDeadlineAdvance(deadlineAdvance)"
              >-</a
            >
            <b-input
              type="text"
              class="px-0 text-center"
              id="deadline-advance"
              v-mask="['#', '##', '###', '####', '#####', '######']"
              v-model="deadlineAdvance"
              style="width: 35px"
            ></b-input>
            <a
              href="javascript:void(0)"
              class="ps-1"
              :class="{ 'disabled-opacity': deadlineAdvance === null }"
              @click.prevent="addDeadlineAdvance(deadlineAdvance)"
              >+</a
            >
          </div>
          <div class="col-3 d-flex justify-content-end align-items-center">
            <a
              href="javascript:void(0)"
              class="btn btn-success rem-size-2 input-sm-height d-flex align-items-center"
              @click="saveDate(value, task, column)"
              >Salvar</a
            >
          </div>
        </div>
      </template>
    </DatePicker>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import showAlert from "../../../components/alerts";

export default {
  data() {
    return {
      backupValue: "",
      value: "",
      deadlineAdvance: 0,
      showEdit: false,
    };
  },
  props: {
    task: {
      default: {},
    },
    group: {
      default: {},
    },
    column: {
      default: {},
    },

    columnValue: {
      default: {},
    },
    disabled: {
      default: false,
    },
    disableDateFromCompanyConfig: {
      default: false,
    },
  },
  computed: {
    today() {
        return new Date();
    },
    columnValueComputed: {
      get() {
        return this.columnValue;
      },
      set(value) {
        let data = {
          column_value_id: this.columnValue.id,
          value: this.columnValue.value,
          description: this.columnValue.description,
        };
        this.$store.commit("updateTask", data);
      },
    },
  },
  methods: {
    ...mapActions("columnValues", ["updateColumnValue"]),
    ...mapActions("tasks", ["updateTask"]),
    cancelEdit(e) {
      this.value = this.backupValue;
      this.showEdit = false
    },
    saveDate(value, task, column) {
        this.backupValue = value;
        let hasDeadline = this.hasDeadline(column);
        let data = task;

        if(!task.finalized || !hasDeadline) {
            this.save(value);

            if(hasDeadline) {
                data.task_id = task.id;
                data.deadline_advance = this.deadlineAdvance;
                this.updateTask(data);
            }

            this.showEdit = false;
            return;
        }

        this.toggleShow();
        showAlert(true, "Tarefa finalizada");
        return
    },
    hasDeadline(column){
        if(column.event_monitor) {
          if(column.event_monitor.active) {
              return true
          }
        }

        return false;
    },
    selectDate(cellId){
        this.selectedDatepicker = cellId
    },
    toggleShow(showInput = false) {
        this.showEdit = showInput;
        if(showInput)  this.backupValue = this.value;
    },
    save(value) {
      let base = this.$el;
      if (base) {
          let data = {
            column_value_id: this.columnValue.id,
            value: value,
            description: "",
          };

          this.updateColumnValue(data);

      }
      if(!value) {
        this.value = value
      }
      this.toggleShow();
    },
    addDeadlineAdvance(deadlineAdvance){
        if(deadlineAdvance !== null) {
            this.deadlineAdvance = deadlineAdvance + 1;
            return;
        }
        this.deadlineAdvance = 0;
        return;
    },
    subDeadlineAdvance(deadlineAdvance){
        if(deadlineAdvance != null && deadlineAdvance > 0) {
            this.deadlineAdvance = deadlineAdvance - 1;
            return;
        }
        this.deadlineAdvance = deadlineAdvance - 0;
        return;
    },
  },
  mounted() {
    this.value = this.columnValue.value;
    this.deadlineAdvance = this.task.deadline_advance;
  },
  beforeDestroy() {
    this.backupValue = "";
    this.value = "";
    this.$el.parentNode.removeChild(this.$el);
  },
};
</script>

<style lang="scss" scoped>
.text-danger-scoped {
    --bs-bg-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
}
.deadline-advance-label {
    height: 11px;
}
</style>
