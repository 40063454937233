
<script>
import i18n from "../i18n";
import simplebar from "simplebar-vue";
import axios from 'axios';
import config from "../config/config";
import {searchComputed, searchMethods, userComputed, userMethods, companiesComputed, companiesMethods, menusMethods, menusComputed, pluginsMethods, pluginsComputed, columnsComputed, usersOptionsComputed} from '../state/helpers'
import { mapState, mapActions } from "vuex";
/**
 * Nav-bar Component
 */
export default {
  components: { simplebar },
  data() {
    return {
      languages: [
        {
          flag: "/images/flags/brasil.png",
          language: "br",
          title: "Português",
        },
        // {
        //   flag: "/images/flags/us.jpg",
        //   language: "en",
        //   title: "English",
        // },
        // {
        //   flag: "/images/flags/french.jpg",
        //   language: "fr",
        //   title: "French",
        // },
        // {
        //   flag: "/images/flags/spain.jpg",
        //   language: "es",
        //   title: "Spanish",
        // },
        // {
        //   flag: "/images/flags/chaina.png",
        //   language: "zh",
        //   title: "Chinese",
        // },
        // {
        //   flag: "/images/flags/arabic.png",
        //   language: "ar",
        //   title: "Arabic",
        // },
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      loadObject: false,
      companyLogo: localStorage.getItem('logo'),
      companyLogoIcon: localStorage.getItem('logo_icon'),
      localFilter: {
        search: '',
        status: '',
        period: 0,
        dateStart: '',
        column: null,
        columnOption: null,
        dateEnd: '',
        unread: false,
        searchWithToken: false,
        conditions: [],
        columns: [],
        options: [],
        search: '',
        data: [
            {
                column: null,
                condition: null,
                value: null,
                isDate: false
            }
        ]
      }
    };
  },
  methods: {
    ...searchMethods,
    ...userMethods,
    ...companiesMethods,
    ...menusMethods,
    ...pluginsMethods,
    ...mapActions('tasks', ['setFilter', 'resetCalledPageByGroups']),
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    setLanguage(locale, country, flag) {

      this.lan = "br";
      this.text = "Português";
      this.flag = "/images/flags/brasil.png";
      i18n.locale = "br";
      localStorage.setItem("locale", "br");
    //   this.lan = locale;
    //   this.text = country;
    //   this.flag = flag;
    //   i18n.locale = locale;
    //   localStorage.setItem("locale", locale);
    },
    async markAsRead(notification){
        if(!notification.read){
            let data = {};
            let keys = Object.keys(notification);
            keys.forEach((key)=>{
                data[key] = notification[key];
            });
            data.read = true;
            await this.updateNotification(data)
        }
        // this.$router.push({path: 'http://alansilva.localhost:8000/workflow/show/10/116/1364'})
        if(notification.action_link.indexOf('workflow/show/') != -1 || notification.action_link.indexOf('user/view/') != -1) {
            let splitParameter = 'workflow/show/';
            let routeName = 'workflow-show';

            if(notification.action_link.indexOf('user/view/') != -1) {
                splitParameter = '/workflow/user/view/';
                routeName = 'workflow-user';
            }

            let splitUrl = notification.action_link.split(splitParameter)
            let url = splitUrl[0]+'workflow/show';
            let splitParams = splitUrl[1].split('/');
            let params = {};
            if(typeof splitParams[0] !== undefined) {
                if(notification.action_link.indexOf('user/view/') != -1)
                    params.workflowId = splitParams[0];
                else
                    params.id = splitParams[0];
            }
            if(typeof splitParams[1] !== undefined) {
                if(notification.action_link.indexOf('user/view/') != -1)
                    params.taskId = splitParams[1];
                else
                    params.workflow_task_id = splitParams[1];
            }
            if(typeof splitParams[2] !== undefined) {
                params.activity = splitParams[2];
            }

            this.$router.push({name: routeName, params: params})
            .catch(err => {
                if ( err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location') ) {
                    console.error(err);
                }
            });

            return;
        }

        this.$router.push({path: notification.action_link})
        .catch(err => {
            if ( err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location') ) {
                console.error(err);
            }
        });
    },
    async markAllAsRead(){

        this.$swal
        .fire({
          title: 'Marcar como Lido?',
          text: 'Todas as notificações serão marcadas como lidas',
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: 'Ok',
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            this.markAllNotificationsAsRead()
          }
        });

    },
    scrollToTopNotificationsDropdown(e){
        let simplebar = this.$refs['notifications-simplebar'];
        simplebar = simplebar? simplebar.$el.querySelector('.simplebar-content-wrapper') : null;
        if(simplebar) {
            setTimeout(()=>{ simplebar.scrollTo({ top: 0, behavior: "smooth" });}, 100);

        }
    },
    clearLocalFilter() {
        this.search = '';
        this.localFilter = {
            search: '',
            status: '',
            period: this.workflow.filter_period,
            column: null,
            columnOption: null,
            dateStart: '',
            dateEnd: '',
            unread: false,
        }

        this.filterContent();


    },
    searchContent: function searchContent() {
        clearTimeout(searchContent.timeout)
        searchContent.timeout = setTimeout(()=>{
            this.localFilter.search = this.search;
            this.localFilter.searchWithToken = true;
            this.filterContent();

        }, 1000);
    },
    filterContent() {
        this.setFilter({
            workflowId: this.workflow.id,
            limit: this.tasksLimit,
            search: this.localFilter.search,
            period: this.localFilter.period,
            status: this.localFilter.status,
            dateStart: this.localFilter.dateStart,
            dateEnd: this.localFilter.dateEnd,
            unread: this.localFilter.unread,
            column: this.localFilter.column,
            columnOption: this.localFilter.columnOption,
            searchWithToken: this.localFilter.searchWithToken,

        })

        let dropdown = this.$refs['dropdown-filter-navbar'];
        if(dropdown) {
            dropdown.hide();
        }

        this.localFilter.searchWithToken = false;
    },
    clearColumnOption() {
        let data = null;
        this.loadObject = false;
        if(this.localFilter.column) {
            if(this.localFilter.column.workflow_column_type_id == 5) {
                data = { dateStart: '', dateEnd: '' }
                this.$set(this.localFilter, 'columnOption', data);
                this.loadObject = true;
                return;
            }
        }
        this.$set(this.localFilter, 'columnOption', data);
    },
    selectFilterColumn(){
        let data = this.columns.find((column) => column.workflow_column_type_id == 9);
        let dataPlugin = null;

        if(this.checkPlugin(1)) {
            dataPlugin = this.columns.find((column) => column.workflow_column_type_id == 13 && column.tag == "aasp_updates_folder");
        }

        if(dataPlugin) {
            data = dataPlugin;
        }

        this.$set(this.localFilter, 'column', data);
    }
  },
  computed:{
    ...searchComputed,
    ...userComputed,
    ...companiesComputed,
    ...pluginsComputed,
    ...menusComputed,
    ...columnsComputed,
    ...usersOptionsComputed,
    ...mapState('tasks', {
        filter: (state)=> state.filter,
        tasksLimit: (state)=> state.tasksLimit,
    }),
    ...mapState('workflows', {
        workflow: (state)=> state.workflow
    }),
    ...mapState('groups', {
        groups: (state)=> state.groups
    }),
    search:{
      get() {
        return this.$store ? this.$store.state.search.searchNavbar : {} || {};
      },
      set(search) {
        this.changeSearchNavbar({
            searchNavbar: search,
        });
      },
    },
    statusList() {
    let column = this.columns.find((column) => column.workflow_column_type_id == 3);
    if(!column) return [];

    return column.options;

    },
    showSearch() {
        return this.$route.path.indexOf("/workflow/show") >= 0;
    }
},
  created(){

    // this.getMenu();
    if(Object.keys(this.company) < 1 || !localStorage.getItem('logo') || !localStorage.getItem('logo_icon')) {
        this.getCompany();
    }
    if(Object.keys(this.user) < 1) {
        this.getUser();
    }
    if(this.plugins.length < 1) {
        this.getPlugins();
    }

    this.$watch(()=> this.user.id, (att, old)=>{
        if(old != undefined){
            Echo.leave(`user.notifications.${this.currentSubdomain}.${old}`);

        }
        if(typeof att !== undefined){
            Echo.private(`user.notifications.${this.currentSubdomain}.${att}`)
            .listen('.UserNotification', (data) => {
                this.addNotification(data.notification)
                this.playNotificationAudio();
            });
        }

    });
  },
  mounted() {
    this.closeMenu();
    this.value = this.languages.find((x) => x.language === i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;


    // Select Column
    setTimeout(()=>{
        this.selectFilterColumn();
    },2000);

    this.$watch(()=> this.$route.params.id, (att)=> {
        this.selectFilterColumn();
    });
    this.$watch(()=> this.activePlugins, (att)=> {
        this.selectFilterColumn();
    });
    this.$watch(()=> this.columns, (att)=> {
        this.selectFilterColumn();
    });

  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div v-if="company.processing"><i class="bx bx-loading bx-spin"></i></div>
        <div v-else>
            <div class="navbar-brand-box navbar-brand-box-width logo-size" v-if="companyLogo && !company.processing">
                <router-link to="/" class="logo logo-dark">
                <span class="logo-sm">
                  <img :src="`${companyLogoIcon}`" alt height="40" width="40" />
                </span>
                <span class="logo-lg logo-lg-navbar">
                  <img :src="`${companyLogo}`" alt height="48" />
                </span>
                </router-link>

                <router-link to="/" class="logo logo-light">
                    <span class="logo-sm ms-n3">
                    <img :src="`${companyLogoIcon}`" alt height="40" width="40" />
                    </span>
                    <span class="logo-lg logo-lg-navbar">
                    <img :src="`${companyLogo}`" alt height="48" />
                    </span>
                </router-link>
            </div>
            <div class="navbar-brand-box navbar-brand-box-width logo-size" v-else>
                <router-link to="/" class="logo logo-dark">
                <span class="logo-sm">
                  <img src="/images/dalth-logo.svg" alt height="20" />
                </span>
                <span class="logo-lg">
                  <img src="/images/dalth-logo.png" alt height="40" />
                </span>
                </router-link>

                <router-link to="/" class="logo logo-light">
                    <span class="logo-sm ms-n3">
                    <img src="/images/dalth-logo.png" alt height="20" />
                    </span>
                    <span class="logo-lg">
                    <img src="/images/dalth-logo.png" alt height="40" />
                    </span>
                </router-link>
            </div>

        </div>

        <button
          id="vertical-menu-btn"
          type="button"
          class="btn btn-sm px-2 menu-arrow-size header-item d-flex justify-content-start align-items-center rem-size-5"
          @click="toggleMenu"
        >
          <!-- <i class="fa fa-fw fa-bars"></i> -->
          <i class="bx bx-chevrons-right toggle-menu-button" id="toggle-menu-icon"></i>
        </button>

        <!-- App Search-->
        <div class="d-none d-lg-flex align-items-center  justify-content-center ms-0 pe-2" v-if="showSearch">
            <b-form-select size="sm" id="filter-column-select" class="form-select rem-size-2 bg-default" v-model.lazy="localFilter.column" @change="clearColumnOption">
                <template #first>
                    <b-form-select-option :value="null">Selecione</b-form-select-option>
                </template>
                <b-form-select-option v-for="column in columns" :key="column.id" :value="column" :class="{'d-none': [3, 5, 6, 7, 8, 11].indexOf(column.workflow_column_type_id) != -1 || column.hidden}">{{ column.name }}</b-form-select-option>
            </b-form-select>

            <b-dropdown lazy variant="white" ref="dropdown-filter-navbar" right toggle-class="header-item d-none align-items-center" menu-class="filter-dimensions" v-if="workflow" style="z-index: 10001;">
                <template v-slot:button-content>
                    <i class="text-secondary fas fa-filter link-cursor rem-size-2"></i>
                </template>
                <b-dropdown-form class="form-width" @submit.prevent="filterContent()">

                    <!-- Column -->
                    <div class="row">
                        <b-form-group label="Pesquisar na coluna" label-for="filter-column-select" class="mb-2 mt-2" @submit.stop.prevent>
                            <b-form-select size="sm" id="filter-column-select" class="form-select rem-size-2" v-model.lazy="localFilter.column" @change="clearColumnOption">
                                <template #first>
                                    <b-form-select-option :value="null">Selecione</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="column in columns" :key="column.id" :value="column" :class="{'d-none': [3, 6, 11].indexOf(column.workflow_column_type_id) != -1}">{{ column.name }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <!-- Column Options -->
                    <div class="row" v-if="localFilter.column">
                        <b-form-group label="Opções da coluna" label-for="filter-column-option-select" class="mb-2 mt-2" @submit.stop.prevent  v-if="localFilter.column.options.length > 0 && [2, 3, 5].indexOf(localFilter.column.workflow_column_type_id) < 0  ||  localFilter.column.workflow_column_type_id == 4">
                            <b-form-select size="sm" id="filter-column-option-select" class="form-select rem-size-2" v-model.lazy="localFilter.columnOption">
                                <template #first>
                                    <b-form-select-option :value="null">Selecione</b-form-select-option>
                                </template>

                                <b-form-select-option v-for="item in localFilter.column.options" :key="item.id" :value="item.id" :class="{'d-none': localFilter.column.workflow_column_type_id == 4 || localFilter.column.workflow_column_type_id == 2}">{{ item.option }}</b-form-select-option>

                                <b-form-select-option :value="true" :class="{'d-none': localFilter.column.workflow_column_type_id != 4}">Sim</b-form-select-option>
                                <b-form-select-option :value="false" :class="{'d-none': localFilter.column.workflow_column_type_id != 4}">Não</b-form-select-option>

                                <b-form-select-option v-for="responsible in responsiblesList" :key="responsible.id" :value="responsible.id" :class="{'d-none': localFilter.column.workflow_column_type_id != 2}">{{ responsible.full_name }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>

                        <div class="col-12" v-if="localFilter.column.workflow_column_type_id == 5 && loadObject">
                            <div class="row">
                                <b-form-group label="De" label-for="dropdown-form-email" class="mb-2 mt-2 col-6" @submit.stop.prevent >
                                    <b-form-input
                                        size="sm"
                                        type="text"
                                        id="date_start"
                                        name="date_start"
                                        placeholder="Início"
                                        maxlength="5"
                                        max="9999-12-31"
                                        min="1902-01-01"
                                        class="rem-size-2"
                                        v-model.lazy="localFilter.columnOption.dateStart"
                                        @focus="($event.target.type = 'date', $event.target.max = '9999-12-31', $event.target.min = '1902-01-01')"
                                    ></b-form-input>
                                </b-form-group>

                                <!-- To -->
                                <b-form-group size="sm" label="Até" label-for="dropdown-form-email" class="mb-2 mt-2 col-6 rem-size-2" @submit.stop.prevent>
                                    <b-form-input
                                        size="sm"
                                        type="text"
                                        id="date_end"
                                        name="date_end"
                                        placeholder="Fim"
                                        v-model.lazy="localFilter.columnOption.dateEnd"
                                        maxlength="5"
                                        max="9999-12-31"
                                        min="1902-01-01"
                                        class="rem-size-2"
                                        @focus="($event.target.type = 'date', $event.target.max = '9999-12-31', $event.target.min = '1902-01-01')"
                                        @keyup.enter="$event.target.blur()"
                                ></b-form-input>
                                </b-form-group>

                            </div>
                        </div>
                        <!-- From -->
                    </div>

                    <!-- Status -->
                    <div class="row">
                        <b-form-group label="Status" label-for="filter-status-select" class="mb-2 mt-2" @submit.stop.prevent>
                            <b-form-select size="sm" id="filter-status-select" class="form-select rem-size-2" v-model.lazy="localFilter.status">
                                <template #first>
                                    <b-form-select-option value="">Selecione</b-form-select-option>
                                </template>
                                <b-form-select-option v-for="status in statusList" :key="status.id" :value="status">{{ status.option }}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <!-- Período -->
                    <div class="row" v-if="!this.checkPlugin(1)">
                        <b-form-group label="Período" label-for="dropdown-form-email" class="mb-2 mt-2" @submit.stop.prevent>
                            <b-form-select size="sm" class="form-select rem-size-2" v-model.lazy="localFilter.period" :options="filter.periods">
                                <template #first>
                                    <b-form-select-option value="0">Todos os dias</b-form-select-option>
                                </template>
                                <b-form-select-option value="Personalizado">Personalizado</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </div>

                    <div class="row" v-if="localFilter.period == 'Personalizado'">
                        <!-- From -->
                        <b-form-group label="De" label-for="dropdown-form-email" class="mb-2 mt-2 col-6" @submit.stop.prevent>
                            <b-form-input
                                size="sm"
                                type="text"
                                id="date_start"
                                name="date_start"
                                placeholder="Início"
                                maxlength="5"
                                class="rem-size-2"
                                v-model.lazy="localFilter.dateStart"
                                @focus="($event.target.type = 'date')"
                            ></b-form-input>
                        </b-form-group>

                        <!-- To -->
                        <b-form-group size="sm" label="Até" label-for="dropdown-form-email" class="mb-2 mt-2 col-6" @submit.stop.prevent>
                            <b-form-input
                                size="sm"
                                type="text"
                                id="date_end"
                                name="date_end"
                                placeholder="Fim"
                                v-model.lazy="localFilter.dateEnd"
                                maxlength="5"
                                @focus="($event.target.type = 'date')"
                                @keyup.enter="$event.target.blur()"
                        ></b-form-input>
                        </b-form-group>
                    </div>

                    <!-- Activities -->
                    <div class="row">
                        <div class="col-1 d-flex justify-contet-center align-items-center">
                            <b-checkbox v-model.lazy="localFilter.unread"></b-checkbox>
                        </div>
                        <div class="col d-flex justify-contet-start align-items-center rem-size-2">
                            Não lidas
                        </div>
                    </div>
                    <div class="row justify-content-between mb-2 mt-2">
                        <div class="col-6">
                            <b-button size="sm" type="submit" variant="success rem-size-2">Filtrar</b-button>
                        </div>
                        <div class="col-6">
                            <b-button size="sm" variant="info rem-size-2" @click.prevent="clearLocalFilter">Limpar</b-button>
                        </div>
                    </div>


                </b-dropdown-form>
            </b-dropdown>

        </div>
        <div class="app-search search-radius d-none d-lg-flex align-items-center">
          <div class="position-relative d-flex align-items-center" v-if="showSearch">
            <b-input size="sm" @input="searchContent" type="text" class="form-control input-search-sm-height mh-unset  rem-size-2 bg-default" v-model="search" debounce="100" :placeholder="$t('navbar.search.text')" />
            <span class="bx bx-search-alt input-search-sm-height"></span>
          </div>
        </div>
      </div>

      <div class="d-flex">

        <b-dropdown variant="white" right toggle-class="header-item d-flex align-items-center">
          <template v-slot:button-content>
            <img class :src="flag" alt="Header Language" height="13" />
          </template>
          <b-dropdown-item
            class="notify-item"
            v-for="(entry, i) in languages"
            :key="`Lang${i}`"
            :value="entry"
            @click="setLanguage(entry.language, entry.title, entry.flag)"
            :class=" {'active' : lan === entry.language}"
          >
            <img :src="`${entry.flag}`" alt="user-image" class="me-1" height="12" />
            <span class="align-middle">{{ entry.title }}</span>
          </b-dropdown-item>
        </b-dropdown>

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button type="button" class="btn header-item noti-icon d-flex align-items-center" @click="initFullScreen">
            <i class="bx bx-fullscreen rem-size-3"></i>
          </button>
        </div>

        <b-dropdown
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
          toggle-class="header-item noti-icon noti-icon-navbar d-flex align-items-center"
          variant="black"
          @show="scrollToTopNotificationsDropdown"
          v-if="user"
        >
          <template v-slot:button-content>
            <i class="bx bx-bell rem-size-3" :class="{'bx-tada': user.unread_notifications > 0 && !user.mute_notifications, 'd-none': user.mute_notifications}"></i>
            <i class="bx bx-volume-mute rem-size-3" :class="{'d-none': !user.mute_notifications}"></i>
            <span
              class="badge bg-danger rounded-pill"
            v-show="user.unread_notifications > 0">{{ user.unread_notifications }}</span> <!-- Not read notifications quantity -->
          </template>

          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                    <a href="#" @click.prevent="muteNotifications(!user.mute_notifications)" v-b-tooltip="!user.mute_notifications? 'Silenciar' : 'Notificar'">
                        <i class="bx bx-volume-mute pe-2" :class="{'text-muted': !user.mute_notifications, 'text-warning': user.mute_notifications}"></i>
                    </a>
                   <span class="rem-size-2">{{ $t('navbar.dropdown.notification.text')}}</span>
                </h6>
              </div>
              <div class="col-auto">
                <a href="#" class="fw-bold"  @click.prevent="markAllAsRead()">
                  <span v-show="!userLoad.readAll" class="rem-size-2">Marcar como lido</span>
                  <span v-show="userLoad.readAll"><i class="bx bx-loader-alt bx-spin rem-size-2" ></i></span>
                </a>
              </div>
            </div>
          </div>

        <!-- Notifications -->
          <simplebar style="max-height: 65vh; overflow-x: hidden; overflow-y: auto;" class="scroll" v-if="(typeof user.notifications != 'undefined')" ref="notifications-simplebar" :autoHide="false" :data-simplebar-force-visible="true" >
            <a :href="notification.action_link" @click.prevent="markAsRead(notification)" class="text-reset notification-item" v-for="notification in user.notifications.data" :key="notification.uuid">
              <div class="media"  :class="{'unread-media': !notification.read, 'read-opacity': notification.read}">
                <div class="media-body word-wrapper">
                  <h6 class="mt-0 mb-1 rem-size-2">{{ notification.title }}</h6>
                  <div class="rem-size-2 text-muted">
                    <p class="mb-1">{{ notification.message}}</p>
                    <p class="mb-0">
                        <i class="mdi mdi-clock-outline me-2"></i> {{ notification.create }}
                    </p>
                  </div>
                </div>
                <div class="xxs ms-3">
                      <span class="avatar-title bg-success rounded-circle font-size-12" :class="{'bg-danger': !notification.read}" v-show="!notification.read">
                        <!-- <i class="mdi mdi-exclamation-thick text-white"></i> -->
                      </span>
                    </div>
              </div>
            </a>
          </simplebar>
         <div class="p-2 border-top d-grid" v-if="typeof user.notifications != 'undefined' && user.notifications.current_page < user.notifications.last_page">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)" @click.prevent="getMoreNotifications">
              <i class="mdi mdi-arrow-down-circle me-1" v-show="!userLoad.notification"></i>
              <i class="bx bx-loader-alt bx-spin rem-size-2" v-show="userLoad.notification"></i>
              <span key="t-view-more" class="rem-size-2" v-show="!userLoad.notification"> {{ $t('navbar.dropdown.notification.button')}} </span>
            </a>
          </div>
        </b-dropdown>
        <!-- endnotification -->

        <!-- profile -->
        <b-dropdown right variant="black" toggle-class="header-item" menu-class="dropdown-menu-end">
          <template v-slot:button-content>
            <img v-if="user.avatar"
              class="rounded-circle header-profile-user"
              :src="user.avatar"
              alt="Header Avatar"
            />
            <b-avatar  variant="info" :text="`${user.name?user.name[0]:''}${user.last_name?user.last_name[0]:''}`" class="mr-3 bg-light text-white square-30 rem-size-2" v-else></b-avatar>
            <span class="d-none d-xl-inline-block ms-1 rem-size-2">{{ user.name }}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </template>
          <!-- item-->

          <b-dropdown-item  :to="{path: '/contacts/profile'}" active-class="active" class="font-size-12">
                <i class="bx bx-user align-middle me-1 w-25"></i>
                <span class="link w-75 text-start"> {{ $t('navbar.dropdown.henry.list.profile') }}</span>
          </b-dropdown-item>

          <b-dropdown-item  :to="{name: 'maintenance-users'}" class="d-block font-size-12" v-if="user.user_type_id == 1" active-class="active">
            <i class="bx bx-wrench align-middle me-1"></i>
            {{ $t('navbar.dropdown.henry.list.settings') }}
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>
          <a href="/logout" @click.prevent="logout" class="dropdown-item text-danger font-size-12">
            <i class="bx bx-power-off align-middle me-1 text-danger"></i>
            {{ $t('navbar.dropdown.henry.list.logout') }}
          </a>
        </b-dropdown>
        <!-- Endprofile -->

        <!-- <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon right-bar-toggle toggle-right"
            @click="toggleRightSidebar"
          >
            <i class="bx bx-cog bx-spin toggle-right"></i>
          </button>
        </div> -->
      </div>
    </div>
  </header>
</template>
<style scoped>
.link a{
  color:#bfc8e2 !important;
  text-decoration:none !important;
}
.xxs{
    height: 0.6rem;
    width: 0.6rem;
}
.unread-media {
    opacity: 1;
}
.read-opacity{
    opacity: 0.6;
}
.menu-arrow-size {
    font-size: 27px;
}
.rotate-180 {
    animation:  rotate180 0.5s forwards linear;
    -moz-animation: rotate180 0.5s forwards linear;
    -webkit-animation: rotate180 0.5s forwards linear;

}
.rotate-360 {
    animation:  rotate360 0.5s forwards linear;
    -moz-animation: rotate360 0.5s forwards linear;
    -webkit-animation: rotate360 0.5s forwards linear;

}

#toggle-menu-icon {
    transition: all 0.25s 0.25s;
}

.word-wrapper{
    word-wrap: break-word;
    max-width: 100%;
}
/* .form-width {
    width: 340px;
} */
.navbar-brand-box-width {
    width: 242px;
}
.logo-lg-navbar {
    max-width: 200px;
    max-height: 40px;
    height: auto;
    width: auto;
}
.square-30 {
    height: 30px !important;
    width: 30px !important;
}
.search-radius .form-control {
    border-radius: 0px 30px 30px 0px;
}
.input-search-sm-height {
    height: 1.7rem !important;
}
.mh-unset {
    min-height: unset !important;
    max-height: unset !important;
}
.app-search * .bx.bx-search-alt::before {
    display: flex;
    position: absolute;
    height: 1.8rem;
    top: -4px;
    left: 132px;
}
.app-search .form-control {
  padding-left: 10px !important;
}

/* Toggle menu button rotate effect */
.sidebar-enable * .toggle-menu-button{
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
</style>
