<template>
  <b-dropdown
    no-caret
    dropup
    size="lg"
    toggle-class="text-decoration-none"
    variant="btn text-decoration-none text-secondary"
    menu-class="bottom-0 scr-right"
    v-b-tooltip.hover="'Exportar documento'"
    :class="{
      'bg-info text-white': btnLoad,
      'text-secondary': !btnLoad && !success,
      'bg-success text-white': success,
      'bg-danger text-white': error,
    }"
  >
    <template v-slot:button-content>
      <span v-if="!btnLoad"
        ><i class="bx bx-export align-middle rem-size-3"></i>
       </span>
      <span v-if="btnLoad"><i class="bx bx-spin bx-loader"></i></span>
      <div class="sub-drawer-menu">Documento</div>
    </template>
    <pluginExportItem
      v-for="(item, itemKey) in items"
      :key="itemKey"
      :id="item"
      :taskIds="taskIds"
    />
  </b-dropdown>
</template>

<script>
import pluginExportItem from "./plugin-export-item.vue";
export default {
  components: {
    pluginExportItem,
  },
  data() {
    return {
      items: [2, 3, 4, 5, 6, 7],
      btnLoad: false,
      success: false,
      error: false,
    };
  },
  props: {
    taskIds: {
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-0 {
    bottom: 0 !important;
}
</style>
