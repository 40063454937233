<script>
import { personsComputed, personsMethods, userComputed, userMethods } from '../../../state/helpers'
import { mapActions, mapState, mapGetters } from 'vuex';
import { FormWizard, TabContent } from "vue-form-wizard";
import axios from "axios";
import Multiselect from "vue-multiselect";
import config from "../../../config/config";
import showAlert from "../../../components/alerts";
import { Money } from "../../../asset/v-money";
import draggable from "vuedraggable";
import {
  required,
  minLength,
  requiredIf
} from "vuelidate/lib/validators";
import { identity } from '@fullcalendar/core';

export default {
  components: {
    Multiselect,
    VueFormWizard: FormWizard,
    TabContent,
    Money,
    draggable
  },
  props: {
    workflowId: {
      type: Number,

    },
    colId: {
      type: Number,

    }
  },
  data() {
    return {
      defaultValueError: false,
      submitted: false,
      workflows: [],
      optionsDeleteds: [],
      workflowCollaborators: null,
      workflowResponsibles: [],
      workflowPersons: [],
      personTypes: [],
      selectedUser: null,
      selectedPerson: null,
      typeOptions: [{ value: null, text: 'selecione' }],
      slicedTypeOptions: [],
      zipcodeOptionsData: [],
      textColumns: [],
      textColumnsFiltered: [],
      showOptions: false,
      showCloseOptions: false,
      value: {},
      hidden: {},
      pendencyOptionSelected: 0,
      finalizationOptionSelected: 0,
      delayOptionSelected: 0,
      type: null,
      dateType: {
        showInput: false
      },
      currentPersonType: '',
      disableDefaultBehavior: false,
      hasMonitorData: false,
      readonlyUserId: 0,
      childWorkflow: null,
      childWorkflowSearchColumn: null,
      workflowChildTask: null,
      selectTaskId: null,
      selectedMask: null,
      form: {
        colId: null,
        workflowId: null,
        name: null,
        type: null,
        options: [],
        finalizationOption: null,
        pendencyOption: null,
        delayOption: null,
        message: '',
        readonly: false,
        hidden: false,
        defaultValue: '',
        useDefaultValue: false,
        personType: '',
        clearPersons: false,
        showResult: false,
        monitor: false,
        advance: null,
        required: false,
        defaultBehavior: true,
        unique: false,
        iterable: false,
        ticketId: false,
        childWorkflow: null,
        childWorkflowSearchColumn: null,
        maskId: null,
        isZipcode: false,
        additionalConfigurationData: null,
        isDateStart: false,
      },
      masks: [],
      colors: {
        'bg-success': 'Verde',
        'bg-info': 'Azul',
        'bg-warning': 'Amarelo',
        'bg-danger': 'Vermelho'
      },
      info: {
        pendencyStatus: 'Selecione uma opção para o status de pendência',
        finishingStatus: 'Selecione uma opção que encerrará a tarefa',
        delayStatus: 'Selecione uma opção que a tarefa assumirá se estiver atrasada',
        finishingMessage: 'Digite uma mensagem de solicitação de encerramento de tarefa',
        endDtate: 'Antecedência padrão',
        defaultBehaviorMessage: 'A coluna fará interações com o sistema de maneira automática',
        defaultBehaviorDisabledMessage: 'Somente 1 coluna do mesmo tipo pode ser selecionada',
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false
      },
      drag:{
        status: false,

      }
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(2)
      },
      type: {
        required
      }
    }
  },
  methods: {
    ...personsMethods,
    ...mapActions('tasks', ['searchTasks']),
    getOptions: async function () {
      const getStates = await axios.get(window.location.origin + '/api/v1/workflow/item/types/' + this.workflowId, config.getHeaders)
        .catch(error => {
          if (error.response) {
            this.isRegisterError = true
            this.axRegError = error.response.data.message;
          }
        });

        getStates.data.data.forEach(element => {
            var option = { value: element.id, text: element.name, count_cols: element.count_cols }
            this.typeOptions.push(option)

          });
          var selectOption = this.typeOptions.filter(el => { return el.text == 'selecione' ? el : null });
          var options = this.typeOptions.filter(el => { return el.text != 'selecione' ? el : null });

          this.typeOptions = options.sort(function (a, b) {

            if (a.text == 'selecione') {
              return 1;
            }
            if (a.text < b.text) {
              return -1;
            }
            return 0;
          });
          this.typeOptions.unshift(selectOption[0]);
          if (this.form.colId) {
            this.getColumn(this.form.colId);
          }
    },
    async getColumn(colId) {
      const response = await axios.get(window.location.origin + '/api/v1/workflow/item/show/' + colId, config.getHeaders)
      .catch(error => {
          if (error.response) {
            this.isRegisterError = true
            this.axRegError = error.response.data.message;
          }
        });

        var data = response.data.data;

          this.form.name = data.name;
          this.form.type = parseInt(data.workflow_column_type_id);
          this.form.readonly = data.readonly ? true : false;
          this.form.hidden = data.hidden ? true : false;
          this.type = parseInt(data.workflow_column_type_id);
          this.form.message = data.message;
          this.form.useDefaultValue = data.use_default_value;
          this.form.showResult = data.show_result;
          this.form.required = data.required;
          this.form.personType = data.person_type;
          this.form.defaultBehavior = !data.custom;
          this.form.iterable = data.iterable;
          this.form.ticketId = !!data.tag;
          this.form.unique = data.unique;
          this.form.maskId = data.mask_id;
          this.form.isZipcode = data.is_zipcode;
          this.form.isDateStart = data.is_date_start;

          this.disableDefaultBehavior = data.disable_custom && data.custom;
          this.currentPersonType = data.person_type;
          this.readonlyUserId = data.readonly_user_id;
          this.childWorkflow =  data.child_workflow ? data.child_workflow : null;
          this.$set(this.form, 'childWorkflow', data.child_workflow ? data.child_workflow.id : null);

          if (this.form.type == 1 && this.form.isZipcode) {
              let  additionalConfigurationData = data.additional_configuration_data
                ? data.additional_configuration_data
                : [];

             let zipcodeOptionsData = [];
              additionalConfigurationData.forEach((configData)=>{
                let columns = JSON.parse(JSON.stringify(this.textColumns));
                let column = columns.find((column)=> column.id == configData.column_id);
                configData.value = column ? column : null;
                zipcodeOptionsData.push(configData);
              })

              this.zipcodeOptionsData = zipcodeOptionsData;
              this.textColumnsFiltered = this.textColumns.filter((textColumn)=>
            (this.zipcodeOptionsData.map((data)=> data.column_id)).filter((columnId)=> !!columnId).indexOf(textColumn.id) < 0);
          }

          if(this.form.childWorkflow) {

            this.$set(this.form, 'childWorkflowSearchColumn', JSON.parse(data.child_workflow_search_columns));
            this.childWorkflowSearchColumn =  this.childWorkflow.columns
              ? this.childWorkflow.columns.find((column)=> JSON.parse(data.child_workflow_search_columns).indexOf(column.id) != -1)
              : null

            if(this.childWorkflowSearchColumn) {
                this.searchWorkflowChildTask('', data.default_value);
                this.selectTaskId = data.default_value;
            }

          }

          if(this.form.maskId) {
            this.selectedMask = this.masks.find((mask)=> mask.id == this.form.maskId);
          }
          this.selectPersonType(this.form.personType);
          if (this.form.type == 3) {
            this.showCloseOptions = true;
          } else {
            this.showCloseOptions = false;
          }

          if (this.form.type == 2) {
            this.selectedUser = this.workflowResponsibles.find((user) => { return user.id == data.default_value  })

          } else if (this.form.type == 10) {
            this.selectedUser = this.workflowCollaborators.find((user) => { return user.id == data.default_value })
            this.$set(this.form, 'defaultValue', data.default_value)
          } else if (this.form.type == 14) {
            this.selectedPerson = this.personsOptions.find((person) => { return person.id == data.default_value })
            this.$set(this.form, 'defaultValue', data.default_value)
          } else if (this.form.type != 3 || this.form.type != 8) {
            this.$set(this.form, 'defaultValue', data.default_value)
          }
          data.options.forEach((element, key) => {
            let actionOption = {
                id: element.id,
                option: element.option,
                color: element.color,
                finalizationOption: element.finalization_option ? true : false,
                pendencyOption: element.pendency_option ? true : false,
                delayOption: element.delay_option ? true : false
            };

            this.form.options.push(actionOption);
            if (this.form.type == 3 || this.form.type == 8) {
              if (element.id == data.default_value) {
                this.$set(this.form, 'defaultValue', { key: key, value: element.option })
              }
            }
            if (element.finalization_option) {
              this.form.finalizationOption = actionOption;
              if(this.form.finalizationOption) this.finalizationOptionSelected = this.form.finalizationOption.id;
            }
            if (element.pendency_option) {
              this.form.pendencyOption = actionOption;
              if(this.form.pendencyOption) this.pendencyOptionSelected = this.form.pendencyOption.id;
            }
            if (element.delay_option) {
              this.form.delayOption = actionOption;
              if(this.form.delayOption) this.delayOptionSelected = this.form.delayOption.id;
            }
          });
          if (data.options.length > 0 && this.form.type != 2 && this.form.type != 7) {
            this.showOptions = true
          }

          var types = [];
          var slicedTypes = [];
          this.typeOptions.forEach((element, key) => {
            if (element.count_cols > 0 && (element.value == 3 || element.value == 2 || element.value == 9 || element.value == 11 || element.value == 6)) {
              slicedTypes.push(element)

            } else {

              types.push(element)
            }
          });
          this.typeOptions = types;
          if (this.type == 3 || this.type == 2 || this.type == 9 || this.type == 11 || this.type == 6) {
            this.typeOptions = slicedTypes;
            this.typeOptions.find(el => el.value == this.type ? this.typeOptions = [el] : null);
          }

          // Event Monitor
          if(data.event_monitor){
            this.form.monitor = data.event_monitor.active;
            this.form.advance = data.event_monitor.advance;
          }
    },
    addOption: function (index) {
      this.form.options.push({ option: "", color: "", finalizationOption: false, pendencyOption: false });
      setTimeout(()=> {
        let element = this.$el.querySelector(`.input-option-${index + 1}`);
        if(element) element.focus();
      }, 300);
    },
    deleteOption: function (index) {
      this.optionsDeleteds.push(this.form.options[index]);
      this.form.options.splice(index, 1);
    },
    checkType: function () {
      this.form.defaultValue = null;

      if (this.form.type == 3) {
        this.showCloseOptions = true;
      } else {
        this.showCloseOptions = false;
      }
      if (this.form.type == 8 || this.form.type == 3) {
        if (this.form.options.length < 1) {
          this.form.options.push({ option: "" });
        }
        this.showOptions = true;
      } else {
        this.showOptions = false;
      }
    },
    setColor: function (color, index) {
      this.form.options[index].color = color;
      this.form.options.push();
    },
    setItem(type) {

        switch(type) {
            case 'pendency':
                this.form.options.find(el => el.pendencyOption == true ? el.pendencyOption = false : null);
                this.pendencyOptionSelected = this.form.pendencyOption.id
                this.form.options.find(el => el.id == this.pendencyOptionSelected ? el.pendencyOption = true : null);
                break;
            case 'delay':
                this.form.options.find(el => el.delayOption == true ? el.delayOption = false : null);
                this.delayOptionSelected = this.form.delayOption.id
                this.form.options.find(el => el.id == this.delayOptionSelected ? el.delayOption = true : null);
                break;
            default:
                this.form.options.find(el => el.finalizationOption == true ? el.finalizationOption = false : null);
                this.finalizationOptionSelected = this.form.finalizationOption.id;
                this.form.options.find(el => el.id == this.finalizationOptionSelected ? el.finalizationOption = true : null);
                break

        }
    },
    postForm: async function (e) {
      this.submitted = true;
      this.$v.$touch();
      e.preventDefault();
      if (!this.$v.$anyError) {
        // Check if default value is not null
         this.defaultValueError = false
        if(!this.form.defaultValue && this.form.useDefaultValue && this.form.type != 4) {
            this.defaultValueError = true
            showAlert(true, 'Insira um valor padrão');
            return
        }

        // checks if the person type has changed
        if(this.type == 14){
            var checkPerson = await this.checkIfChangePersonType(this.currentPersonType, this.form.personType)
            if(checkPerson === null){ // no change
                this.form.clearPersons = false;

            } else if(!checkPerson && checkPerson !== null) { // there has been a change but it has not been confirmed
                this.form.clearPersons = false;
                return;

            } else { // has change and has been confirmed
                this.form.clearPersons = true;
            }
        }

        if (this.type == 9 || this.type == 3 || this.type == 2) {
          this.form.type = this.type;
        }
        this.form.options.forEach((option, key)=>{
            option.sequence = key + 1;
        });

        this.form.custom = !this.form.defaultBehavior;

        if(this.childWorkflow) {
            this.form.childWorkflow = this.childWorkflow.id
        }
        if(this.childWorkflowSearchColumn) {
            this.form.childWorkflowSearchColumn = [this.childWorkflowSearchColumn.id]
        }

        this.form.maskId = null;
        if(this.selectedMask) {
            this.form.maskId = this.selectedMask.id
        }

        if(this.form.isZipcode) {
            this.form.additionalConfigurationData = [];
            this.zipcodeOptionsData.forEach((zipcodeData)=>{
                this.form.additionalConfigurationData.push({
                    id: zipcodeData.id,
                    tag: zipcodeData.tag,
                    label: zipcodeData.label,
                    column_id: zipcodeData.value ? zipcodeData.value.id : null ,
                })
            })
        }

        const postForm = axios
          .post(
            window.location.origin + "/api/v1/workflow/item",
            this.form,
            config.getHeaders
          )
          .then((response) => {
            if (!response.data.error) {
              showAlert(false, response.data.message);
              this.getPersonsByWorkflow(this.workflowId);
              this.$emit('save')
            }
          })
          .catch((error) => {
            if (error.response) {
              showAlert(true, error.response.data.message);
            }

            if(error.response.status == 500 && typeof error.response.data.option !== undefined) {
                this.optionsDeleteds.forEach((option)=>{
                    this.form.options.push(option)
                })

                this.optionsDeleteds = [];
            }
          });
      }
    },
    async checkIfChangePersonType(currentType, newType){
        if(currentType == newType){
            return null
        }

        var response =  await this.$swal
        .fire({
          title: "Confirma a modificação?",
          text: `O tipo de pessoa "${currentType}" foi alterado para "${newType}". Isso fara com que todos as pessoas vinculadas anteriormente sejam removidos.`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Sim!",
          cancelButtonText: "Cancelar",
        });
        return response.value;
    },
    numberDecrement(value) {
      var base = value ? value : 0;
      if (parseInt(base) > 0) {
        return parseInt(base) - 1;
      }
      return value
    },
    numberIncrement(value) {
      var base = value ? value : 0;
      return parseInt(base) + 1
    },
    toggleDateInput() {
      if (!this.form.defaultValue && this.form.defaultValue !== 0) {
        this.form.defaultValue = 0
      }
      this.dateType.showInput = !this.dateType.showInput;
      setTimeout(() => {
        this.$refs['date-input-number'].focus({ focusVisible: true });
      }, 100);
    },
    timeMask(value) {
      const hours = [
        /[0-9]/,
        value.charAt(0) === '2' ? /[0-9]/ : /[0-9]/,
      ];
      const minutes = [/[0-5]/, /[0-9]/];
      return value.length > 2
        ? [...hours, ':', ...minutes]
        : hours;
    },
    async getResponsibleOptions(workflow_id) {
      const getResponsibleOptions = await axios
        .get(
          window.location.origin + "/api/v1/workflow/task/responsibles/" + workflow_id,
          config.getHeaders
        )
        .then((response) => {
          var data = response.data.data;
          this.workflowResponsibles = [];
          data.forEach((user) => {
            user['full_name'] = this.getFullName({ name: user.name, last_name: user.last_name });
            this.workflowResponsibles.push(user);

          });
        })
        .catch((error) => {
          if (error.response) {
            this.isRegisterError = true;
            this.axRegError = error.response.data.message;
          }
        });
    },
    async getCollaboratorOptions(workflow_id) {
      const getCollaboratorOptions = await axios
        .get(
          window.location.origin + "/api/v1/workflow/task/collaborators/all/" + workflow_id,
          config.getHeaders
        )
        .then((response) => {
          var data = response.data.data;
          this.workflowCollaborators = [];
          data.forEach((user) => {
            user['full_name'] = this.getFullName({ name: user.name, last_name: user.last_name });
            this.workflowCollaborators.push(user);

          });

        })
        .catch((error) => {
          if (error.response) {
            this.isRegisterError = true;
            this.axRegError = error.response.data.message;
          }
        });
    },
    getFullName({ name, last_name }) {
      var fullName = '';
      if (name) {
        fullName += name + ' ';
      }
      if (last_name) {
        fullName += last_name + ' ';
      }

      return fullName;
    },
    getMaskName({label}) {
        return label
    },
    getColumnName({name}) {
        return name
    },
    selectUser(user) {
      this.form.defaultValue = user.id
    },
    clearDefaultValue() {
      this.form.defaultValue = null
    },
    selectById(item) {
      this.form.defaultValue = item.id
    },
    selectPersonType(type) {
        if(type){
            this.workflowPersons = this.personsOptions.filter((person) => person.type == type);
        } else {
            this.workflowPersons = this.personsOptions;
        }
        if(this.selectedPerson) {
            if(this.selectedPerson.type != type) {
                this.selectedPerson = null;
            }
        }
    },
    startDrag(e){
        e.target.classList.add('grabbing')
    },
    getPersonTypes(){
        const getTypes = axios.post('/api/v1/person/types')
        .then( response => {
            response.data.data.forEach((element) => {
                this.personTypes.push(element.type)
            });

        }).catch(error => {
            if(error.response) {
                this.isRegisterError = true
                this.axRegError = error.response.data.message;
            }
        });
      },
      async checkIfHasEventMonitor(workflowId, colId){
        try{
            let data = {workflow_id: workflowId, exceptions: [colId]}
            const response = await axios.post(window.location.origin + '/api/v1/monitor/has', data)
            this.hasMonitorData = response.data.data.has_event_monitor;
        } catch (error) {
            this.hasMonitorData = true
        }
      },
      setNow(event, form) {
        this.$set(form, 'defaultValue', event);
      },
      customLabelWorkflowName({name}) {
        return `${name}`
      },
      customLabelWorkflowColumnName({name}) {
        return `${name}`
      },
      async localListWorkflows() {
        axios.post( `${window.location.origin}/api/v1/workflow/with/columns`, { with_columns: true })
            .then((response)=>{
                if(!response.data.error) {
                    this.workflows = response.data.data;
                }
            })
            .catch((error)=>{
                console.log('local listWorkflows :>> ', error);
            })
    },
    getMasks() {
        axios.get(`${window.location.origin}/api/v1/workflow/column/masks`)
        .then((response)=> {
            if(!response.data.error) {
                this.masks = response.data.data
            }
        })
        .catch((error) =>{
            console.log('getMasks error :>> ', error);
        });
    },
    zipcodeOptions() {
        axios.get(`${window.location.origin}/api/v1/workflow/column/zipcode/options`)
        .then((response)=> {
            if(!response.data.error) {
                this.zipcodeOptionsData = response.data.data
            }
        })
        .catch((error) =>{
            console.log('zipcodeOptions error :>> ', error);
        });
    },
    getTextColumns(workflowId) {
        axios.get(`${window.location.origin}/api/v1/workflow/column/type/${workflowId}/1`)
        .then((response)=> {
            if(!response.data.error) {
                this.textColumns = response.data.data
            }
        })
        .catch((error) =>{
            console.log('getTextColumns error :>> ', error);
        });
    },
    selectZipcode(checked) {
        if(checked) {
            this.selectedMask = this.masks.find((mask)=> mask.id == 4);
            if(this.selectedMask) this.form.maskId = this.selectedMask.id
        }
    },
    selectZipcodeColumn(data, key) {
        this.$set(this.zipcodeOptionsData[key], 'value', data);
    },
    filterTextColumns(column, key) {
        let selectedColumnsIds = (this.zipcodeOptionsData.map((data)=> data.value ? data.value.id : null )).filter((columnId)=> !!columnId);
        selectedColumnsIds[key] = column.id;
        this.textColumnsFiltered = this.textColumns.filter((textColumn)=>
            selectedColumnsIds.indexOf(textColumn.id) < 0);
    },
    touchDefaultValueError(evt) {
        if(!evt) {
            this.defaultValueError = false
        }
    },

    searchWorkflowChildTask: function searchWorkflowChildTask(data, id = null) {
      clearTimeout(searchWorkflowChildTask.timeout);

      searchWorkflowChildTask.timeout = setTimeout(() => {
        let workflowId = this.childWorkflow ? this.childWorkflow.id : null
        let childWorkflowSearchColumn = this.childWorkflowSearchColumn ? this.childWorkflowSearchColumn.id : null
        if(!workflowId) {
            showAlert(true, 'Selecione um workflow');
            return
        }

        if(!childWorkflowSearchColumn) {
            showAlert(true, 'Selecione uma coluna');
            return
        }

        this.searchTasks({
            workflowId: workflowId,
            columnId: !!this.form.colId ? this.form.colId : childWorkflowSearchColumn,
            search: data,
            taskId: id,
            newColumn: !this.form.colId
        });

      }, 600);
    },
    selectWorkflowTask(id) {

    }
  },
  computed:{
    ...personsComputed,
    ...userComputed,
    dragOptions() {
      return {
        animation: 200,
        group: "options",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    checkIfNowValue(){
        if(this.form.defaultValue == 'now') {
            return true;
        }
        return false
    },
    deadlineDefaultValueMask(){
        if(this.checkIfNowValue) {
            return '';
        }
        return '#########';
    },
    childWorkflowcolumnsFiltered() {
        if(typeof this.childWorkflow.columns === 'undefined') {
            return [];
        }

        return this.childWorkflow.columns.filter((column)=>{
            return [4, 5, 6, 7,  9, 11, 14, 15].indexOf(column.workflow_column_type_id) == -1;
        });
    },
    ...mapState('tasks', {
        tasksProcessing: (state)=> state.tasksProcessing,
        searchTasksList: (state)=> state.searchTasksList
    }),
    ...mapGetters('columns',['hasTicketId'])
  },
  mounted() {
    // if (this.form.colId) {
    //   this.getColumn(this.form.colId);
    // }
    this.getOptions(this.workflowId);
    this.$watch(
        ()=>this.form.personType,
        (att, old)=>{
            this.selectPersonType(att)
        }
    );
    this.$watch(
        ()=>this.form.type,
        (att, old)=>{
            if(att == 16) {
                this.localListWorkflows()
            }
        }
    );
    this.$watch(
        ()=>this.searchTasksList,
        (att, old)=>{
            if(this.selectTaskId) {
                this.workflowChildTask = att.find((task)=> task.id == this.selectTaskId);
                this.selectTaskId = null;
            }
        }
    );
  },
  created() {
    this.form.workflowId = this.workflowId ? this.workflowId : null;
    this.form.colId = this.colId ? this.colId : null;
    this.getMasks();
    this.zipcodeOptions();
    this.getTextColumns(this.workflowId);
    this.checkIfHasEventMonitor(this.workflowId, this.colId)
    this.getResponsibleOptions(this.workflowId);
    this.getCollaboratorOptions(this.workflowId);
    this.getPersonTypes();
  }
};
</script>
<template>
  <div class="container">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card">
        <b-form @submit="postForm">

          <div class="card-body">
            <div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group row mb-3 align-items-center">
                    <label class="col-12 col-md-12 col-lg-3 col-form-label" for="itemName">Nome da coluna</label>
                    <div class="col-12 col-md-12 col-lg-9">
                      <b-input id="itemName" size="sm" type="text" class="form-control font-size-12" name="itemName" placeholder="nome da coluna"
                        :class="{ 'is-invalid': submitted && $v.form.name.$error }" v-model="form.name" ></b-input>
                      <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
                        <span v-if="!$v.form.name.required">Insira um nome para a coluna</span>
                        <span v-else-if="!$v.form.name.minLength">O nome da coluna deve conter
                          no mínimo 2 caracteres</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-3 align-items-center">
                    <label class="col-12 col-md-12 col-lg-3 col-form-label" for="itemName">Tipo da coluna</label>
                    <div class="col-12 col-md-12 col-lg-9">
                      <b-form-select
                        size="sm"
                        @change="checkType"
                        v-model="form.type"
                        class="form-select font-size-12"
                        :class="{'is-invalid': submitted && $v.form.type.$error}"
                        :disabled="form.type == 9 || form.type == 3 || form.type == 2 || form.type == 6"
                        >
                        <b-select-option v-for="(typeOption, keyTypeOptions) in typeOptions" :key="keyTypeOptions"
                          :value="typeOption.value"
                          :class="{  'd-none': (((typeOption.value == 2 && form.type != 2) || (typeOption.value == 3 && form.type != 3) || (typeOption.value == 9 && form.type != 9) || (typeOption.value == 11 && form.type != 11) || (typeOption.value == 6 && form.type != 6)) && typeOption.count_cols > 0) }">{{
                            typeOption.text
                          }}</b-select-option>
                      </b-form-select>
                      <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
                        <span v-if="!$v.form.type.required">Selecione um Tipo de coluna</span>
                      </div>

                    </div>
                  </div>

                  <div v-show="showOptions">
                    <draggable
                        class="options-group hover-grab"
                        draggable=".option"
                        :list="form.options"
                        v-bind="dragOptions"
                        @start="startDrag"
                        >
                        <transition-group  type="transition" :name="!drag.status? 'flip-list' : null">
                            <div class="form-group row mb-2 option" v-for="(field, index) in form.options" :key="index">
                              <b-col cols="12" md="12" lg="3" class="d-flex align-items-center">
                                <label class="col-form-label" for="itemName">Adicionar opção</label>
                              </b-col>

                              <b-col cols="7" md="7" lg="6" class="d-flex align-items-center">
                                <i class="mdi mdi-drag-vertical-variant fs-4 text-end"></i>
                                <input :class="`input-option-${index}`" id="itemName" type="text" class="form-control" name="itemName" maxlength="19"
                                  placeholder="Digite uma opção" v-model="field.option" @keydown.enter.prevent="addOption(index)" />
                              </b-col>
                              <b-col cols="2" md="2" lg="1" class="d-flex align-items-center justify-content-end p-0">
                                <b-dropdown v-model="field.color" variant="text-decoration-none">
                                  <template #button-content>
                                    <div class="d-flex align-items-center my-auto">
                                      <div class="rounded-circle circle-color-select"
                                        :class="[{ 'bg-secondary': !colors[field.color] }, field.color]" alt=""></div>
                                    </div>
                                  </template>

                                  <b-dropdown-item v-for="(color, keyColor) in colors" :key="keyColor"
                                    @click="setColor(keyColor, index)">
                                    <div class="row align-items-center" v-b-tooltip.hover.bottomright="color">
                                      <b-col cols="3">
                                        <div class="rounded-circle circle-color" :class="[keyColor]"> </div>
                                      </b-col>
                                      <b-col cols="9" class=" ml-3 my-auto">{{ color }}</b-col>
                                    </div>
                                  </b-dropdown-item>
                                </b-dropdown>

                              </b-col>
                              <b-col cols="3" md="3" lg="2" class="d-flex align-items-center justify-content-center p-0">
                                <div class="row p-0 m-0">
                                    <div class="col-6 d-flex">
                                        <b-button variant="text-decoration-none my-auto option-button-size" @click="deleteOption(index)" :disabled="form.options.length == 1"><b
                                            class="mdi mdi-close-thick text-danger fs-5"></b></b-button>
                                    </div>
                                    <div class="col-6 d-flex" :class="{'visibility-hidden': index != (form.options.length - 1)}">
                                        <b-button variant="text-decoration-none my-auto option-button-size" @click="addOption(index)"><i
                                            class="bx bx-plus-medical"></i></b-button>
                                    </div>
                                </div>
                              </b-col>
                            </div>
                        </transition-group>
                    </draggable>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
              <div v-show="showCloseOptions" class="row">
                <hr class="opacity-01">
                <!-- <h4 class="mb-3 mt-2 ">Finalização</h4> -->
                <b-col cols="12">
                  <b-row class="mb-3">
                    <b-col cols="12" md="3" class="d-flex align-items-center">
                      <label class="col-form-label" for="pendency-option">Pendência</label>
                    </b-col>
                    <b-col cols="8" md="7" class="d-flex align-items-center">
                      <b-form-select v-model="form.pendencyOption" class="form-select font-size-12" size="sm" id="pendency-option"
                        @change="setItem('pendency')">
                        <b-form-select-option v-for="(item, itemKey) in form.options" :key="itemKey" :value="item"
                          ref="pendency-option" :class="{ 'd-none': finalizationOptionSelected == item.id || delayOptionSelected == item.id }">{{
                            item.option
                          }}</b-form-select-option>
                      </b-form-select>
                    </b-col>
                    <b-col cols="2" md="1" class="d-flex justify-content-end align-items-center">
                      <i class="far fa-question-circle font-size-15 link-cursor"
                        v-b-tooltip.left="info.pendencyStatus"></i>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="12" md="3" class="d-flex align-items-center">
                      <label class="col-form-label" for="finalization-option">Conclusão</label>
                    </b-col>
                    <b-col cols="8" md="7" class="d-flex align-items-center">
                      <b-form-select v-model="form.finalizationOption" class="form-select font-size-12" size="sm" id="finalization-option"
                        @change="setItem('')">
                        <b-form-select-option v-for="(item, itemKey) in form.options" :key="itemKey" :value="item"
                          :class="{ 'd-none': pendencyOptionSelected == item.id || delayOptionSelected == item.id }">{{
                            item.option
                          }}</b-form-select-option>
                      </b-form-select>
                    </b-col>
                    <b-col cols="2" md="1" class="d-flex justify-content-end align-items-center">
                      <i class="far fa-question-circle font-size-15 link-cursor"
                        v-b-tooltip.left="info.finishingStatus"></i>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="12" md="3" class="d-flex align-items-center">
                      <label class="col-form-label" for="finalization-option">Atraso</label>
                    </b-col>
                    <b-col cols="8" md="7" class="d-flex align-items-center">
                      <b-form-select v-model="form.delayOption" class="form-select font-size-12" size="sm" id="finalization-option"
                        @change="setItem('delay')">
                        <b-form-select-option v-for="(item, itemKey) in form.options" :key="itemKey" :value="item"
                          :class="{ 'd-none':  finalizationOptionSelected == item.id || pendencyOptionSelected == item.id }">{{
                            item.option
                          }}</b-form-select-option>
                      </b-form-select>
                    </b-col>
                    <b-col cols="2" md="1" class="d-flex justify-content-end align-items-center">
                      <i class="far fa-question-circle font-size-15 link-cursor"
                        v-b-tooltip.left="info.delayStatus"></i>
                    </b-col>
                  </b-row>
                  <b-row class="mb-3">
                    <b-col cols="12" md="3" class="d-flex align-items-center">
                      <label class="col-form-label" for="itemName">Menssagem de conclusão</label>
                    </b-col>
                    <b-col cols="8" md="7" class="d-flex flex-column align-items-start justify-content-start">
                      <b-textarea v-model="form.message" class="form-control w-100 scr-right font-size-12" name="updateDescription" size="sm"
                        id="updateDescription" ref="description"
                        placeholder="Digite uma mensagem para conclusão de ticket..." rows="3" max-rows="3"
                        no-resize></b-textarea>
                      <span class="margin-left-2px font-size-12">Para incluir o status ao seu
                        texto digite --Status--</span>
                    </b-col>
                    <b-col cols="2" md="1" class="d-flex justify-content-end align-items-center">
                      <i class="far fa-question-circle font-size-15 link-cursor"
                        v-b-tooltip.left="info.finishingMessage"></i>
                    </b-col>
                  </b-row>
                </b-col>
              </div>

              <div class="row" v-if="form.type == 16">
                <b-col cols="3" class="mb-3">
                  <label class="col-form-label" for="itemName">workflow</label>
                </b-col>
                <b-col cols="9" class="mb-3">
                  <!-- Workflow -->
                  <multiselect v-if="form.type == 16 && workflows" v-model="childWorkflow"
                    :options="workflows"
                    :searchable="true"
                    :multiple="false"
                    :preserve-search="true"
                    :show-labels="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    placeholder="Selecione um workflow"
                    :custom-label="customLabelWorkflowName"
                    class="multiselect-sm">
                    <template slot="option" slot-scope="props">
                      <div class="avatar-group-item">
                        <span class="ps-2">{{ props.option.name }}</span>
                      </div>
                    </template>
                    <span slot="noResult">Não encontrado.</span>
                    <span slot="noOptions">Ninguem por aqui.</span>
                  </multiselect>
                </b-col>
                <b-col cols="3" v-if="childWorkflow">
                  <label class="col-form-label" for="itemName">Coluna de Pesquisa</label>
                </b-col>
                <b-col cols="9" v-if="childWorkflow">
                  <!-- Workflow -->
                  <multiselect v-if="childWorkflow.columns" v-model="childWorkflowSearchColumn"
                    :options="childWorkflowcolumnsFiltered"
                    :searchable="true"
                    :multiple="false"
                    :preserve-search="true"
                    :show-labels="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    @select="searchWorkflowChildTask('')"
                    placeholder="Selecione um workflow"
                    :custom-label="customLabelWorkflowColumnName"
                    class="multiselect-sm">
                    <template slot="option" slot-scope="props">
                      <div class="avatar-group-item">
                        <span class="ps-2">{{ props.option.name }}</span>
                      </div>
                    </template>
                    <span slot="noResult">Não encontrado.</span>
                    <span slot="noOptions">Ninguem por aqui.</span>
                  </multiselect>
                </b-col>
              </div>
              <div class="row mt-3">
                <hr class="opacity-01" />
                <b-row class="mb-3">
                  <b-col cols="12" md="12" lg="3" class="d-flex align-items-center">
                    <label class="col-form-label" for="pendency-option">Configurações</label>
                  </b-col>
                  <b-col cols="10" md="10" lg="9" class="d-flex align-items-center">
                    <b-row >

                        <!-- Readonly -->
                        <b-col lg="1" cols="2" class="d-flex align-items-center  justify-content-start" :class="{'disabled-opacity': ((form.readonly && user.user_type_id != 1) && (form.readonly && user.id != readonlyUserId))}">
                          <b-checkbox id="readonly" size="sm" v-model="form.readonly" :disabled="((form.readonly && user.user_type_id != 1) && (form.readonly && user.id != readonlyUserId))"></b-checkbox>
                        </b-col>
                        <b-col lg="3"  cols="10" class="d-flex align-items-center  justify-content-start" :class="{ 'disabled-opacity': ((form.readonly && user.user_type_id != 1) && (form.readonly && user.id != readonlyUserId))}">
                          <label class="col-form-label" for="readonly">Somente leitura</label>
                        </b-col>

                        <!-- Required -->
                        <b-col lg="1" cols="2" class="d-flex align-items-center  justify-content-start" :class="{'disabled-opacity': ((form.readonly && user.user_type_id != 1) && (form.readonly && user.id != readonlyUserId))}">
                          <b-checkbox id="required" size="sm" v-model="form.required" :disabled="((form.readonly && user.user_type_id != 1) && (form.readonly && user.id != readonlyUserId))"></b-checkbox>
                        </b-col>
                        <b-col lg="3" cols="10" class="d-flex align-items-center  justify-content-start">
                          <label class="col-form-label" for="required">Obrigatório</label>
                        </b-col>

                        <!-- Hidden -->
                        <b-col lg="1" cols="2" class="d-flex align-items-center  justify-content-start">
                          <b-checkbox id="hidden" size="sm" v-model="form.hidden"></b-checkbox>
                        </b-col>
                        <b-col lg="3" cols="10" class="d-flex align-items-center  justify-content-start">
                          <label class="col-form-label" for="hidden">Ocultar coluna</label>
                        </b-col>

                        <!-- Default value -->
                        <b-col lg="1" cols="2" class="d-flex align-items-center  justify-content-start"
                          v-if="form.type != 6 && form.type">
                          <b-checkbox id="default-value" size="sm" v-model="form.useDefaultValue" @change="touchDefaultValueError"></b-checkbox>
                        </b-col>
                        <b-col lg="3" cols="10" class="d-flex align-items-center  justify-content-start"
                          v-if="form.type != 6 && form.type">
                          <label class="col-form-label" for="default-value">Valor padrão</label>
                        </b-col>

                        <!-- Unique -->
                        <b-col lg="1" cols="2" class="d-flex align-items-center  justify-content-start"
                          v-if="(form.type == 1 || form.type == 9 || form.type == 5 || form.type == 7 || form.type == 12) && form.type">
                          <b-checkbox id="default-unique" size="sm" v-model="form.unique"></b-checkbox>
                        </b-col>
                        <b-col lg="3" cols="10" class="d-flex align-items-center  justify-content-start"
                          v-if="(form.type == 1 || form.type == 9 || form.type == 5 || form.type == 7 || form.type == 12) && form.type">
                          <label class="col-form-label" for="default-unique">Único</label>
                        </b-col>

                        <!-- Iterable -->
                        <b-col lg="1" cols="2" class="d-flex align-items-center  justify-content-start"
                          v-if="form.type == 13 && form.type">
                          <b-checkbox id="default-iterable" size="sm" v-model="form.iterable"  @change="()=> form.ticketId = !form.iterable ? form.iterable : form.ticketId"></b-checkbox>
                        </b-col>
                        <b-col lg="3" cols="10" class="d-flex align-items-center  justify-content-start"
                          v-if="form.type == 13 && form.type">
                          <label class="col-form-label" for="default-iterable">Iterável</label>
                        </b-col>

                        <!-- Ticket Id -->
                        <b-col lg="1" cols="2" class="d-flex align-items-center  justify-content-start"
                          v-if="form.type == 13 && form.type && !colId && !hasTicketId && false">
                          <b-checkbox id="default-ticket-id" size="sm" v-model="form.ticketId" @change="()=> form.iterable = form.ticketId ? form.ticketId : form.iterable"></b-checkbox>
                        </b-col>
                        <b-col lg="3" cols="10" class="d-flex align-items-center  justify-content-start"
                          v-if="form.type == 13 && form.type && !colId && !hasTicketId && false">
                          <label class="col-form-label" for="default-ticket-id">Ticket ID</label>
                        </b-col>

                        <!-- Default Behavior -->
                        <b-col lg="1" cols="2" class="d-flex align-items-center  justify-content-start" v-b-tooltip.top.hover="disableDefaultBehavior? info.defaultBehaviorDisabledMessage : info.defaultBehaviorMessage" :class="{'disabled-opacity': disableDefaultBehavior}"
                          v-if="form.type == 7 && form.type">
                          <b-checkbox id="total" size="sm" v-model="form.defaultBehavior" :disabled="disableDefaultBehavior"></b-checkbox>
                        </b-col>
                        <b-col lg="3" cols="10" class="d-flex align-items-center justify-content-start" v-b-tooltip.top.hover="disableDefaultBehavior? info.defaultBehaviorDisabledMessage : info.defaultBehaviorMessage" :class="{'disabled-opacity': disableDefaultBehavior}"
                          v-if="form.type == 7 && form.type">
                          <label class="col-form-label" for="total">Comportamento padrão</label>
                        </b-col>

                        <!-- Show Result -->
                        <b-col lg="1" cols="2" class="d-flex align-items-center  justify-content-start"
                          v-if="form.type == 3 || form.type == 5  || form.type == 7 || form.type == 12 || form.type == 13 && form.type">
                          <b-checkbox id="total" size="sm" v-model="form.showResult"></b-checkbox>
                        </b-col>
                        <b-col lg="3" cols="10" class="d-flex align-items-center  justify-content-start"
                          v-if="form.type == 3 || form.type == 5  || form.type == 7 || form.type == 12 || form.type == 13 && form.type">
                          <label class="col-form-label" for="total">Total</label>
                        </b-col>

                        <!-- Date Limit -->
                        <b-col md="1" cols="2" class="d-flex align-items-center  justify-content-start"
                          v-if="(form.type == 5 && !hasMonitorData) || form.type == 11">
                          <b-checkbox id="end-date" size="sm" v-model="form.monitor"></b-checkbox>
                        </b-col>
                        <b-col lg="3" cols="10" class="d-flex align-items-center  justify-content-start"
                          v-if="(form.type == 5 && !hasMonitorData) || form.type == 11">
                          <label class="col-form-label" for="end-date">Prazo</label>
                        </b-col>

                        <!-- Date Base -->
                        <b-col md="1" cols="2" class="d-flex align-items-center  justify-content-start"
                          v-if="form.type == 5">
                          <b-checkbox id="is-start-date" size="sm" v-model="form.isDateStart"></b-checkbox>
                        </b-col>
                        <b-col lg="3" cols="10" class="d-flex align-items-center  justify-content-start"
                          v-if="form.type == 5">
                          <label class="col-form-label" for="is-start-date">Data base</label>
                        </b-col>

                        <!-- Zipcode -->
                        <b-col lg="1" cols="2" class="d-flex align-items-center  justify-content-start"
                          v-if="form.type == 1">
                          <b-checkbox id="is-zipcode" size="sm" v-model="form.isZipcode" @change="selectZipcode"></b-checkbox>
                        </b-col>
                        <b-col lg="3" cols="10" class="d-flex align-items-center  justify-content-start"
                          v-if="form.type == 1">
                          <label class="col-form-label" for="is-zipcode">CEP</label>
                        </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>

              <!-- default values -->
              <div class="row mb-3" v-if="form.type != 6 && form.type && form.useDefaultValue">
                <hr class="opacity-01" />
                <b-col cols="12" md="12" lg="3">
                  <label class="col-form-label" for="itemName">Valor padrão</label>
                  <!-- <div class="row">
                        <div class="col-9">
                        </div>
                        <div class="col-3">
                            <b-checkbox id="hidden" size="sm" v-model="form.useDefaultValue"></b-checkbox>
                        </div>
                    </div> -->
                </b-col>
                <b-col cols="12" md="12" lg="9" class="d-flex align-items-center">
                    <!-- <div class="row"> -->
                    <!-- date -->
                        <div class="p-0" v-if="form.type == 5">
                          <b-button size="sm" @click="(form.defaultValue = numberDecrement(form.defaultValue))"
                            variant="outline-danger" :disabled="checkIfNowValue">-</b-button>
                        </div>
                        <div class="p-0 m-2 col-2 d-flex justify-content-center" v-if="form.type == 5" :class="{ 'w-15': dateType.showInput }">
                          <b-input size="sm" type="text" v-mask="deadlineDefaultValueMask" ref="date-input-number"
                            :class="{ 'd-none': !dateType.showInput }" class="form-control col-2 text-end font-size-12"
                            @blur="dateType.showInput = !dateType.showInput" v-model="form.defaultValue" :disabled="checkIfNowValue"></b-input>
                          <b-button variant="outline-success" :class="{ 'd-none': dateType.showInput }"
                            @click="toggleDateInput" :disabled="checkIfNowValue">
                            <!-- <span v-if="checkIfNowValue">Agora</span> -->
                            <span class="text-white">Hoje + {{
                              form.defaultValue && form.defaultValue != 'now' ? form.defaultValue : 0
                            }} dias</span>
                            </b-button>
                        </div>
                        <div class="p-0" v-if="form.type == 5">
                          <b-button size="sm" @click="(form.defaultValue = numberIncrement(form.defaultValue))"
                            variant="outline-success" :disabled="checkIfNowValue">+</b-button>
                        </div>
                        <div class="ps-3 col-2 col-md-2" v-if="form.type == 5">
                            <div class="d-flex align-items-center">
                                <b-form-checkbox size="sm" class="d-flex align-items-center checkbox-item" v-model="form.defaultValue" @change="setNow($event, form)" :value="'now'" :unchecked-value="0"><span class="ps-2">Agora</span></b-form-checkbox>
                            </div>
                        </div>
                        <div class="col-12 col-md-12">
                        <!-- Text -->
                        <b-input v-if="form.type == 9 || form.type == 1" v-model.lazy="form.defaultValue" placeholder="insira um valor padrão" :class="{'is-invalid': defaultValueError}"></b-input>
                        <!-- Acepptance -->
                        <b-button v-if="form.type == 4" class=""
                          :class="{ 'bg-success': form.defaultValue, 'bg-danger': !form.defaultValue }"
                          v-model.lazy="form.defaultValue" @click="form.defaultValue = !form.defaultValue" size="sm"><span
                            class="text-white">{{
                              form.defaultValue == 1 && form.defaultValue ? 'Sim' : 'Não'
                            }}</span></b-button>

                        <!-- Money -->
                        <money v-bind="money" v-model.lazy="form.defaultValue" class="input-title form-control cell-input cell-edit input-currency"  :class="{'is-invalid': defaultValueError}"
                          v-if="form.type == 12"></money>

                        <!-- Number -->
                        <div class="p-0" v-if="form.type == 13">
                          <b-button size="sm" @click="(form.defaultValue = numberDecrement(form.defaultValue))"
                            variant="outline-danger">-</b-button>
                        </div>
                        <div class="p-0 m-2 w-10" v-if="form.type == 13">
                          <b-input size="sm" type="text" v-mask="'#########'" ref="date-input-number font-size-12"
                            class="form-control col-2 input-currency" v-model="form.defaultValue"></b-input>
                        </div>
                        <div class="p-0" v-if="form.type == 13">
                          <b-button @click="(form.defaultValue = numberIncrement(form.defaultValue))"
                            variant="outline-success">+</b-button>
                        </div>

                        <!-- List -->
                        <b-select v-model="form.defaultValue" class="form-select font-size-12" size="sm" :class="{'is-invalid': defaultValueError}" v-if="form.type == 8 || form.type == 3">
                          <b-select-option :value="null">Selecione uma opção</b-select-option>
                          <b-select-option v-for="(item, keyItems) in form.options" :key="keyItems"
                            :value="{ key: keyItems, value: item.option }">{{
                              item.option
                            }}</b-select-option>
                        </b-select>

                        <!-- Time -->
                        <b-input v-mask="timeMask" placeholder="--:--" v-if="form.type == 7" size="sm" class="font-size-12" :class="{'is-invalid': defaultValueError}"
                          v-model.lazy="form.defaultValue"></b-input>

                        <!-- Responsible -->
                        <multiselect v-if="form.type == 2 && workflowResponsibles" v-model="selectedUser"
                          :options="workflowResponsibles.filter((user) => { return user.user_type_id == 1 || user.user_type_id == 2 || user.user_type_id == 4})"
                          :searchable="true" :custom-label="getFullName" track-by="full_name" @select="selectUser($event)" @remove="clearDefaultValue" :class="{'is-invalid': defaultValueError}" class="multiselect-sm">
                          <template slot="option" slot-scope="props">
                            <div class="avatar-group-item" v-b-tooltip="props.option.full_name">
                              <img v-if="props.option.avatar" :src="props.option.avatar" class="rounded-circle avatar-xs" alt />
                                <b-avatar
                                    variant="info"
                                    :text="`${ props.option.name ? props.option.name[0] : ''}${props.option.last_name
                                        ? props.option.last_name[0]
                                        : ''
                                    }`"
                                    class="mr-3 bg-light text-white avatar-circle font-size-10"
                                    v-else
                                ></b-avatar>
                                <span class="ps-2">{{ props.option.full_name }}</span>
                            </div>
                          </template>
                          <span slot="noResult">Não encontrado.</span>
                          <span slot="noOptions">Ninguem por aqui.</span>
                        </multiselect>

                        <!-- Collaborator -->
                        <multiselect v-if="form.type == 10 && workflowCollaborators" v-model="selectedUser" :class="{'is-invalid': defaultValueError}"
                          :options="workflowCollaborators" :searchable="true" :custom-label="getFullName" track-by="full_name"
                          @select="selectUser($event)" class="multiselect-sm">
                          <template slot="option" slot-scope="props">
                            <div class="avatar-group-item" v-b-tooltip="props.option.full_name">
                              <img v-if="props.option.avatar" :src="props.option.avatar" class="rounded-circle avatar-xs" alt />
                              <b-avatar
                                    variant="info"
                                    :text="`${ props.option.name ? props.option.name[0] : ''}${props.option.last_name
                                        ? props.option.last_name[0]
                                        : ''
                                    }`"
                                    class="mr-3 bg-light text-white avatar-circle font-size-10"
                                    v-else
                                ></b-avatar>
                              <span class="ps-2">{{ props.option.full_name }}</span>
                            </div>
                          </template>
                          <span slot="noResult">Não encontrado.</span>
                          <span slot="noOptions">Ninguem por aqui.</span>
                        </multiselect>

                        <!-- Person -->
                        <multiselect v-if="form.type == 14 && workflowPersons" v-model="selectedPerson"
                          :class="{'is-invalid': defaultValueError}"
                          :options="workflowPersons"
                          :searchable="true"
                          :custom-label="getFullName"
                          :multiple="false"
                          :preserve-search="true"
                          :show-labels="false"
                          :close-on-select="true"
                          :clear-on-select="false"
                          track-by="name"
                          @select="selectById($event)"
                          class="col-12 multiselect-sm">
                          <template slot="option" slot-scope="props">
                            <div class="avatar-group-item" v-b-tooltip="props.option.full_name">
                              <img v-if="props.option.avatar" :src="props.option.avatar" class="rounded-circle avatar-xs" alt />
                              <b-avatar
                                  variant="info"
                                  :text="`${ props.option.name ? props.option.name[0] : ''}${props.option.last_name
                                    ? props.option.last_name[0]
                                    : ''
                                  }`"
                                  class="mr-3 bg-light text-white avatar-circle font-size-10"
                                  v-else
                              ></b-avatar>
                              <span class="ps-2">{{ `${props.option.name} ${props.option.last_name}` }}</span>
                            </div>
                          </template>
                          <span slot="noResult">Não encontrado.</span>
                          <span slot="noOptions">Ninguem por aqui.</span>
                        </multiselect>

                        <!-- Workflow task -->
                        <multiselect v-if="searchTasksList && form.type == 16" v-model="workflowChildTask"
                            :options="searchTasksList"
                            :searchable="true"
                            :internal-search="false"
                            @search-change="searchWorkflowChildTask"
                            :loading="tasksProcessing.search"
                            :multiple="false"
                            :preserve-search="true"
                            :show-labels="false"
                            :close-on-select="true"
                            :clear-on-select="false"
                            placeholder="Selecione"
                            :custom-label="customLabelWorkflowColumnName"
                            @select="selectById($event)"
                            class="multiselect-sm">
                            <template slot="option" slot-scope="props">
                            <div class="avatar-group-item">
                                <span class="ps-2">{{ props.option.name }}</span>
                            </div>
                            </template>
                            <span slot="noResult">Não encontrado.</span>
                            <span slot="noOptions">Ninguem por aqui.</span>
                        </multiselect>

                         <!-- Checklist -->
                         <span  v-if="form.type == 11">
                             <b-checkbox size="sm" :checked="form.useDefaultValue" :disabled="true" class="d-none font-size-12" v-model="form.defaultValue"> </b-checkbox><span>Copiar checklists da última tarefa do grupo</span>
                         </span>

                         <!-- Link -->
                         <b-col cols="12">
                             <b-input id="link" type="url" v-if="form.type == 15" size="sm" class="font-size-12" :class="{'is-invalid': defaultValueError}" v-model.lazy="form.defaultValue" placeholder="http://..."></b-input>
                             <label class="invalid-feedback" v-show="defaultValueError">Insira um valor padrão</label>
                         </b-col>
                         <label class="invalid-feedback" v-show="defaultValueError">Insira um valor padrão</label>
                    </div>
                    <!-- </div> -->
                </b-col>
              </div>
              <div class="row mb-3" v-if="form.type == 14">
                <b-col cols="3">
                  <label class="col-form-label" for="itemName">Tipo de pessoa</label>
                </b-col>
                <b-col cols="9">
                  <!-- Person type -->
                  <multiselect v-if="form.type == 14 && personTypes" v-model="form.personType"
                    :options="personTypes"
                    :searchable="true"
                    :multiple="false"
                    :preserve-search="true"
                    :show-labels="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    placeholder="Selecione um tipo"
                    @select="selectPersonType($event)"
                    class="multiselect-sm">
                    <template slot="option" slot-scope="props">
                      <div class="avatar-group-item" v-b-tooltip="props.option.full_name">
                        <span class="ps-2">{{ props.option }}</span>
                      </div>
                    </template>
                    <span slot="noResult">Não encontrado.</span>
                    <span slot="noOptions">Ninguem por aqui.</span>
                  </multiselect>
                </b-col>
              </div>
              <div class="row mb-3" v-if="(form.monitor && !hasMonitorData) || (form.type == 11 && form.monitor)">
                <hr class="opacity-01" />
                <b-col cols="3" class="d-flex align-items-end">
                  <label class="col-form-label" for="end-date">Antecedência:</label>
                </b-col>
                <b-col cols="3">
                  <!-- advance -->
                  <b-input size="sm" class="font-size-12" min='0' id="end-date" type="number" v-mask="'#########'" placeholder="Dias" v-model="form.advance"></b-input>
                </b-col>
                <b-col cols="2" md="1" class="d-flex justify-content-start align-items-center">
                  <i class="far fa-question-circle font-size-15 link-cursor"
                    v-b-tooltip.bottomright="info.endDtate"></i>
                </b-col>
              </div>
              <div class="row mb-3" v-if="form.type == 1">
                <hr class="opacity-01" />
                <b-col cols="3" class="d-flex align-items-end">
                  <label class="col-form-label" for="end-date">Máscara:</label>
                </b-col>
                <b-col cols="9">
                 <!--Mask -->
                 <multiselect
                    class="mask-multiselect multiselect-sm"
                    v-model="selectedMask"
                    :options="masks"
                    :searchable="true"
                    :multiple="false"
                    :preserve-search="true"
                    :show-labels="false"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :custom-label="getMaskName"
                    :disabled="form.isZipcode"
                    placeholder="Selecione uma máscara">
                    <template slot="option" slot-scope="props">
                      <div class="avatar-group-item" v-b-tooltip="props.option.label">
                        <span class="ps-2">{{ props.option.label }}</span>
                      </div>
                    </template>
                    <span slot="noResult">Não encontrado.</span>
                    <span slot="noOptions">Nada por aqui.</span>
                  </multiselect>
                </b-col>
              </div>

              <div class="row" v-if="form.isZipcode">
                <div class="col">
                    <div class="row mb-3" v-for="(option, optionKey) in zipcodeOptionsData" :key="option.id">
                        <b-col cols="3" class="d-flex align-items-end">
                            <label class="col-form-label" for="end-date">{{ option.label }}:</label>
                        </b-col>
                        <b-col cols="9">
                            <!-- Column -->
                            <multiselect
                                v-model="option.value"
                                :options="textColumnsFiltered"
                                :searchable="true"
                                :multiple="false"
                                :preserve-search="false"
                                :show-labels="false"
                                :close-on-select="true"
                                :clear-on-select="false"
                                label="name"
                                placeholder="Selecione uma coluna"
                                @select="filterTextColumns($event, optionKey)"
                                class="multiselect-sm">
                                <template slot="option" slot-scope="props">
                                <div class="avatar-group-item" v-b-tooltip="props.option.name">
                                    <span class="ps-2">{{ props.option.name }}</span>
                                </div>
                                </template>
                                <span slot="noResult">Não encontrado.</span>
                                <span slot="noOptions">Nada por aqui.</span>
                            </multiselect>
                        </b-col>
                    </div>
                </div>
              </div>

            </div>
          </div>
          <b-button type="submit" variant="outline-light mt-4">Salvar</b-button>
        </b-form>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
  </div>
</template>
<style scoped>
.w-15 {
  width: 15%
}

.w-10 {
  width: 10%
}

.input-currency {
  text-align: right;
}

.circle-color {
  height: 1rem;
  width: 1rem;
}

.circle-color-select {
  height: 2rem;
  width: 2rem;
}

@media (max-width: 576px) {
  .circle-color-select {

    height: 1rem;
    width: 1rem;
  }
}

.scr-right::-webkit-scrollbar {
  width: 6px;
}

.scr-right::-webkit-scrollbar-track {
  background: transparent;
  color: black;
  border-radius: 10px;
}

.scr-right::-webkit-scrollbar-thumb:hover {
  background: black;
  opacity: 0.6;
  border-radius: 10px;
}

.scr-right::-webkit-scrollbar-thumb {
  background: #c3cbe4;
  opacity: 0.5;
  padding-left: 5px;
  border-radius: 10px;
}

.margin-left-2px {
  margin-left: 2px;
}

.opacity-01 {
  opacity: 0.1;
}

/* drag and drop effects */
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #222736;
}
.hover-grab{
    cursor: grab;
}
.hover-grab:active{
    cursor: grabbing !important;
}
.disabled-opacity{
    opacity: 0.6;
}
.option-button-size {
    width: 41px;
}
.visibility-hidden {
  visibility: hidden;
}
</style>
<style>
.checkbox-item label{
    margin: 0;
}
.mask-multiselect.multiselect.multiselect--disabled, .mask-multiselect.multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
    background: transparent !important;
}
</style>
