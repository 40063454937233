import axios from "axios";

export const state = {
    workflows: [],
    workflow: {},
    workflowId: 0,
    workflowUsers: [],
    workflowResponsibles: [],
    workflowCollaborators: [],
    headerItems: [],
    title: '',
    sortTask: {},
    ordination: {
        load: false,
        ordinate: false,
        orderAsc: true,
        columnId: 0,
        typeId: 0
    },
}

export const getters = {
    // all
    getItems(state) {
        return state.workflow.columns;
    },
    getGroups(state) {
        return state.workflow.groups;
    },
    getTasks(state) {
        return state.workflow.tasks;
    },

    // Find
    findItem: (state) => (id) => {
        return state.workflow.columns.find((item) => item.id == id)
    },

    findGroup: (state) => (id) => {
        return state.workflow.groups.find((group) => group.id == id)
    },

    findTask: (state) => (id) => {
        const groups = Object.keys(state.workflow.groups);
        const task = {};
        groups.forEach(group => {
            task = state.workflow.tasks[group].find((task => { if (id == task.id) return task }));
            if (!task) {
                task = state.workflow.tasks[group].find((task) => { return task.subtasks.find((subtask) => { return id == subtask.id }) });
                task = task.subtasks.find((subtask) => { return id == subtask.id });
            }
        });

        return task
    },

    // QTT
    getItemsLength(state) {
        return state.workflow.columns.length;
    },
    getGroupsLength(state) {
        return state.workflow.groups.length;
    },
    getTasksLength(state) {
        return state.workflow.tasks.length;
    },

    findItemWidth: (state) => (id) => {
        let item = state.workflow.columns.find((item) => item.id == id)
        return item ? item.width : 0;
    },

}

export const actions = {
    async getWorkflows({commit}) {
        const response = await axios.get(window.location.origin + "/api/v1/workflow/");
        commit('CHANGE_WORKFLOWS', response.data.data);

    },
    async changeWorkflow({ commit, dispatch }, content) {
        let data = {
            workflow_id: content.workflow_id,
            period: content.period,
            data_start: content.data_start,
            data_end: content.data_end,
            status: content.status,
            limit: content.limit
        };

        const response = await axios.post(window.location.origin + "/api/v1/workflow/show/filter", data);
        const workflow = response.data.data;
        const headerItems = [
            { text: "Workflow", href: "/" },
            { text: workflow.name, active: true },
        ];

        commit('CHANGE_WORKFLOW', workflow);
        commit('CHANGE_WORKFLOW_ID', workflow.id);
        commit('CHANGE_workflow_columnS', headerItems);
        commit('CHANGE_WORKFLOW_TITLE', workflow.name);
        commit('CHANGE_WORKFLOW', workflow);
        dispatch('responsibleOptions', workflow.id);
        dispatch('collaboratorOptions', workflow.id);
        // dispatch('ordinate');
    },
    setWorkflow({ commit, dispatch, state }, workflow) {

        if (Object.keys(workflow).length > 0) {
            const headerItems = [
                { text: "Workflow", href: "/" },
                { text: workflow.name, active: true },
            ];
            commit('CHANGE_WORKFLOW', workflow);
            commit('CHANGE_WORKFLOW_ID', workflow.id);
            commit('CHANGE_workflow_columnS', headerItems);
            commit('CHANGE_WORKFLOW_TITLE', workflow.name);
        }
        dispatch('responsibleOptions', workflow.id);
        dispatch('collaboratorOptions', workflow.id);
        // dispatch('ordinate');
    },

    setOrdination({ commit, dispatch }, ordination) {
        commit('CHANGE_ORDINATION', ordination);
        // dispatch('ordinate');
    },

    // Ordinate
    ordinate({ commit, state, getters }) {
        var groupKeys = Object.keys(getters.getTasks);
        groupKeys.forEach((key) => {

            commit('ORDINATE_TASKS', key);
            // commit('CHANGE_TASKS_BY_GROUP', {tasks: state.workflow.tasks, group: key});
        });

        // if(this.updateCluster && !this.firstCharge){
        //     if(this.ordination.ordinate) {
        //         setTimeout(() => { this.toggleCluster() }, 200);
        //     } else {
        //         this.toggleCluster()
        //     }
        //   this.updateCluster = false;
        //   this.ordination.ordinate = false;
        // }

        commit('CHANGE_SORT_TASK', state.workflow.tasks);
    },
    usersOptions({commit}, workflowId) {

        axios.post(window.location.origin + "/api/v1/workflow/search/users/", {workflow_id: workflowId, search: ''})
        .then((response) => {
          commit('CHANGE_WORKFLOW_USERS', response.data.data)
        })
        .catch((error) => {
            commit('CHANGE_WORKFLOW_USERS', []);
        });
    },
    responsibleOptions({commit}, workflowId) {

        if(workflowId != 'undefined' && workflowId !== undefined) {
            axios.get(window.location.origin + "/api/v1/workflow/task/responsibles/" + workflowId)
            .then((response) => {
              commit('CHANGE_WORKFLOW_RESPONSIBLES', response.data.data)
            })
            .catch((error) => {
                commit('CHANGE_WORKFLOW_RESPONSIBLES', []);
            });
        }

    },
    async collaboratorOptions({commit}, workflowId) {

        if(workflowId != 'undefined' && workflowId !== undefined) {
            await axios.get(window.location.origin + "/api/v1/workflow/task/collaborators/all/" + workflowId)
            .then((response) => {
              commit('CHANGE_WORKFLOW_COLLABORATORS', response.data.data)
            })
            .catch((error) => {
                commit('CHANGE_WORKFLOW_COLLABORATORS', [])
            });
        }

    },
}


export const mutations = {
    CHANGE_WORKFLOWS(state, data) {
        state.workflows = data;
    },
    CHANGE_WORKFLOW_TITLE(state, data) {
        state.title = data;
    },
    CHANGE_workflow_columnS(state, data) {
        state.headerItems = data;
    },
    CHANGE_WORKFLOW(state, data) {
        state.workflow = data;
    },
    CHANGE_WORKFLOW_ID(state, data) {
        state.workflowId = data;
    },

    CHANGE_SORT_TASK(state, data) {
        state.sortTask = data;
    },
    CHANGE_TASKS_BY_GROUP(state, { tasks, group }) {
        state.workflow.tasks[group] = tasks;
    },
    CHANGE_WORKFLOW_USERS(state, data) {
        state.workflowUsers = data;
    },
    CHANGE_WORKFLOW_COLLABORATORS(state, data) {
        state.workflowCollaborators = data;
    },
    CHANGE_WORKFLOW_RESPONSIBLES(state, data) {
        state.workflowResponsibles = data;
    },

    // ORDINATION
    CHANGE_ORDINATION(state, data) {
        if (typeof data.load !== undefined) state.ordination.load = data.load;
        if (typeof data.ordinate !== undefined) state.ordination.ordinate = data.ordinate;
        if (typeof data.orderAsc !== undefined) state.ordination.orderAsc = data.orderAsc;
        if (typeof data.columnId !== undefined) state.ordination.columnId = data.columnId;
        if (typeof data.typeId !== undefined) state.ordination.typeId = data.typeId;
    },
    ORDINATE_TASKS(state, group) {
        state.workflow.tasks[group].sort((a, b) => {

            switch (state.ordination.typeId) {
                default:
                    var a_value = a.sequence;
                    var b_value = b.sequence;

                    if (state.ordination.orderAsc) {
                        if (a_value < b_value) {
                            return -1;
                        }
                        if (a_value > b_value) {
                            return 1;
                        }
                        return 0;
                    } else {
                        if (a_value > b_value) {
                            return -1;
                        }
                        if (a_value < b_value) {
                            return 1;
                        }
                        return 0;
                    }
                    break;
                case 0:
                    break;
                case 9:
                    if (state.ordination.orderAsc) {

                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1
                        }
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1
                        }
                        return 0
                    } else {
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return -1
                        }
                        if (a.name.toLowerCase() < b.name.toLowerCase()) {
                            return 1
                        }
                        return 0
                    }
                    break;
                case 1:
                    var a_value = a.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })
                    if (state.ordination.orderAsc) {
                        if (a_value.value.toLowerCase() < b_value.value.toLowerCase()) {
                            return -1;
                        }
                        if (a_value.value.toLowerCase() > b_value.value.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    } else {
                        if (a_value.value.toLowerCase() > b_value.value.toLowerCase()) {
                            return -1;
                        }
                        if (a_value.value.toLowerCase() < b_value.value.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    }
                    break;
                case 2:
                    // Ordenate for name
                    var a_value = a.responsibles.length > 0 ? a.responsibles[0].name + ' ' + a.responsibles[0].last_name : ''
                    var b_value = b.responsibles.length > 0 ? b.responsibles[0].name + ' ' + b.responsibles[0].last_name : ''

                    if (state.ordination.orderAsc) {
                        if (a_value.toLowerCase() < b_value.toLowerCase()) {
                            return -1;
                        }
                        if (a_value.toLowerCase() > b_value.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    } else {
                        if (a_value.toLowerCase() > b_value.toLowerCase()) {
                            return -1;
                        }
                        if (a_value.toLowerCase() < b_value.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    }
                    break;
                case 3:
                    // Ordenate for sequence
                    var a_value = a.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })

                    if (state.ordination.orderAsc) {
                        var a_option = a_value.options.find((item) => { return item.id == a_value.value });
                        var b_option = b_value.options.find((item) => { return item.id == b_value.value });
                        var a_option_value = a_option == undefined ? a_value.options.length + 2 : parseInt(a_option.sequence);
                        var b_option_value = b_option == undefined ? b_value.options.length + 2 : parseInt(b_option.sequence);

                        if (a_option_value < b_option_value) {
                            return -1;
                        }

                        if (a_option_value > b_option_value) {
                            return 1;
                        }

                        return 0;
                    } else {
                        var a_option = a_value.options.find((item) => { return item.id == a_value.value });
                        var b_option = b_value.options.find((item) => { return item.id == b_value.value });
                        var a_option_value = a_option == undefined ? a_value.options.length + 2 : a_option.sequence;
                        var b_option_value = b_option == undefined ? b_value.options.length + 2 : b_option.sequence;

                        if (a_option_value > b_option_value) {
                            return -1;
                        }

                        if (a_option_value < b_option_value) {
                            return 1;
                        }
                        return 0;
                    }
                    break;

                case 8:
                    // Ordenate for option name
                    var a_value = a.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })

                    if (state.ordination.orderAsc && a_value.value) {

                        var a_option = a_value.options.find((item) => { return item.id == a_value.value });
                        var b_option = b_value.options.find((item) => { return item.id == b_value.value });
                        var a_option_value = a_option == undefined ? '' : a_option.option.toLowerCase();
                        var b_option_value = b_option == undefined ? '' : b_option.option.toLowerCase();

                        if (a_option_value < b_option_value) {
                            return -1;
                        }

                        if (a_option_value > b_option_value) {
                            return 1;
                        }

                        return 0;
                    } else {
                        var a_option = a_value.options.find((item) => { return item.id == a_value.value });
                        var b_option = b_value.options.find((item) => { return item.id == b_value.value });
                        var a_option_value = a_option == undefined ? '' : a_option.option.toLowerCase();
                        var b_option_value = b_option == undefined ? '' : b_option.option.toLowerCase();

                        if (a_option_value > b_option_value) {
                            return -1;
                        }

                        if (a_option_value < b_option_value) {
                            return 1;
                        }
                        return 0;
                    }
                    break;
                case 4:
                case 5:
                case 7:
                    var a_value = a.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })
                    if (state.ordination.typeId == 4) {
                        var a_value = parseInt(a_value.value) ? 0 : 1;
                        var b_value = parseInt(b_value.value) ? 0 : 1;
                    } else {
                        var a_value = a_value.value;
                        var b_value = b_value.value;
                    }

                    if (state.ordination.orderAsc) {
                        if (a_value < b_value) {
                            return -1;
                        }
                        if (a_value > b_value) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if (a_value > b_value) {
                            return -1;
                        }
                        if (a_value < b_value) {
                            return 1;
                        }
                        return 0;

                    }
                    break;
                case 6:
                    var a_value = a.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })

                    var a_value = a_value.files.length;
                    var b_value = b_value.files.length;

                    if (state.ordination.orderAsc) {
                        if (a_value < b_value) {
                            return -1;
                        }
                        if (a_value > b_value) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if (a_value > b_value) {
                            return -1;
                        }
                        if (a_value < b_value) {
                            return 1;
                        }
                        return 0;

                    }
                    break;
                case 10:
                    var a_value = a.collaborators.length;
                    var b_value = b.collaborators.length;

                    if (state.ordination.orderAsc) {
                        if (a_value < b_value) {
                            return -1;
                        }
                        if (a_value > b_value) {
                            return 1;
                        }
                        return 0;
                    } else {
                        if (a_value > b_value) {
                            return -1;
                        }
                        if (a_value < b_value) {
                            return 1;
                        }
                        return 0;
                    }
                    break;
                case 11:
                    var a_value = a.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })

                    if (state.ordination.orderAsc) {
                        if (a_value.checklistValue < b_value.checklistValue) {
                            return -1;
                        }
                        if (a_value.checklistValue > b_value.checklistValue) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if (a_value.checklistValue > b_value.checklistValue) {
                            return -1;
                        }
                        if (a_value.checklistValue < b_value.checklistValue) {
                            return 1;
                        }
                        return 0;

                    }
                    break;
                case 12:
                    var a_value = a.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })
                    if (state.ordination.orderAsc) {
                        if (a_value.currency < b_value.currency) {
                            return -1;
                        }
                        if (a_value.currency > b_value.currency) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if (a_value.currency > b_value.currency) {
                            return -1;
                        }
                        if (a_value.currency < b_value.currency) {
                            return 1;
                        }
                        return 0;

                    }
                    break;
                case 13:
                    var a_value = a.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value) => { return value.workflow_column_id == state.ordination.columnId })
                    if (state.ordination.orderAsc) {
                        if (parseInt(a_value.number) < parseInt(b_value.number)) {
                            return -1;
                        }
                        if (parseInt(a_value.number) > parseInt(b_value.number)) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if (parseInt(a_value.number) > parseInt(b_value.number)) {
                            return -1;
                        }
                        if (parseInt(a_value.number) < parseInt(b_value.number)) {
                            return 1;
                        }
                        return 0;

                    }
                    break;

            }
            return 0
        })
    }

}
