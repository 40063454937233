import Text from './Text.vue';
import Title from './Title.vue';
import Link from './Link.vue';
import Person from './Person.vue';
import Time from './Time.vue';
import Currency from './Currency.vue';
import List from './List.vue';
import Date from './Date.vue';
import Responsible from './Responsible.vue';
import Collaborator from './Collaborator.vue';
import ListComponent from './ListComponent.vue';
import Acceptance from './Acceptance.vue';
import File from './File.vue';
import Number from './Number.vue';
import Checklist from './Checklist.vue';
import Workflow from './Workflow.vue';

export const TextColumn = Text;
export const LinkColumn = Link;
export const PersonColumn = Person;
export const TimeColumn = Time;
export const CurrencyColumn = Currency;
export const TitleColumn = Title;
export const ListColumn = List;
export const DateColumn = Date;
export const ResponsibleColumn = Responsible;
export const CollaboratorColumn = Collaborator;
export const ListComponentColumn = ListComponent;
export const AcceptanceColumn = Acceptance;
export const FileColumn = File;
export const NumberColumn = Number;
export const ChecklistColumn = Checklist;
export const WorkflowColumn = Workflow;
