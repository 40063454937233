<script>
import axios from 'axios';
import showAlert from '../../components/alerts'
import Recaptcha from '../../components/Recaptcha.vue'
import config from '../../config/config'
import {
    required,
    email,
    minLength,
    sameAs,
    maxLength,
} from "vuelidate/lib/validators";



export default {
  data() {
    return {
        register:{
            load: false
        },
        title: "Cadastra",
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        submitted: true,
        axRegError: "",
        isRegisterError: false,
        registerSuccess: false,
        dateInputMax: '9999-12-31',
        dateInputMin: '1902-01-01',
        showRecaptcha: true,
        companyLogo: null,
        form:{
          email: "",
          name: "",
          last_name: "",
          birth: "",
          cellphone: "",
          password: "",
          password_confirmation: "",
          avatar: "",
          recaptcha_token: ''
      },

    };
  },
  validations: {
      form:{
          email:{
              required,
              email,
              minLength: minLength(6),
          },
          name:{
              required,
              minLength: minLength(3),
              maxLength: maxLength(15)
          },
          last_name:{
              required,
              minLength: minLength(3),
              maxLength: maxLength(20)
          },
          birth:{
              required,
              minLength: minLength(10),
              maxLength: maxLength(10)
          },
          cellphone:{
              required,
              minLength: minLength(14),
              maxLength: maxLength(15)
          },
          password:{
              required,
              minLength: minLength(8),
              maxLength: maxLength(20),
          },
          password_confirmation:{
              required,
              minLength: minLength(8),
              maxLength: maxLength(20),
              sameAsPassword: sameAs("password")
          }
      }
  },
   props: {
    submitUrl: {
      type: String,
      required: true,
    },
    regError: {
      type: String,
      required: false,
      default: () => null,
    },
  },
  components: {Recaptcha},

  methods: {
      formValidate(e) {
        this.submitted = true;
        this.$v.$touch();
        e.preventDefault();


        if(!this.$v.$anyError){
            this.register.load = true;
            const register = axios.post(this.submitUrl, this.form)
            .then( response => {
                if(response.status == 201){
                    // showAlert(false, 'Cadastro efetuado com sucesso')
                    this.$swal
                        .fire({
                        title: "Parabéns",
                        text: "Sua conta foi criada com sucesso e um email de verificação foi enviado para seu email. verifique-o para prosseguir",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Continuar!",
                        })
                        .then((result) => {
                            window.location = window.location.origin+'/login'
                            this.register.load = false;
                        })
                    // this.$router.push({path: `/login`});
                    // this.singIn();
                    // this.getCompany(null, e);

                }

            }).catch(error => {
                this.register.load = false;
                if(error.response) {
                    this.isRegisterError = true
                    this.axRegError = error.response.data.message;
                    showAlert(true, error.response.data.message)

                }
                this.showRecaptcha = false;
                setTimeout(() => {
                    this.showRecaptcha = true;
                }, 200);
            })
        }
    },
    singIn(){

        this.form.disable_rc =  process.env.MIX_REACAPTCHA_SITE_KEY;
        const singIn = axios.post(window.location.origin + "/api/v1/login", this.form, config.getHeaders)
            .then( response => {
                if(response.data.access_token){
                    document.cookie = 'token='+response.data.access_token+';SameSite=Lax';
                    document.cookie = 'loggedIn=dHJ1ZQ==;SameSite=Lax';
                    window.location.href = 'home';
                    this.getCompany(null, e);
                }
            }).catch(error => {
                console.log(error);
                if(error.response.status == 401){
                    showAlert(true, 'E-mail ou senha incorretos')
                } else {
                    showAlert(true, error.response.data.message)
                }
            });
    },
    async getCompany(event, externalEvent = false){
        this.processing = true;
        const getCompany = await axios.get(window.location.origin+"/api/v1/company")
            .then( response => {
                if(response.status > 199 || response.status < 300){
                    if(response.data.data){
                    var path = response.data.data.logo_filepath;
                    this.companyLogo = window.location.origin+"/"+path;
                    localStorage.setItem('logo',  window.location.origin+"/"+path);
                  }
                  if(externalEvent){
                    externalEvent.target.submit();
                  }
                }

            }).catch(error => {
                console.log(error);
            }).finally(()=>{
                this.processing = false
            })
    },
    setRangeDateInput(){
        var date = new Date();
        var year = 1000*60*60*24*365;
        var day = 1000*60*60*24;
        let minAge = 16; // years
        this.dateInputMax = this.formatDate(new Date(date.getTime() - ((year * minAge) + ((Math.floor(minAge/4)) * day))));
        this.form.birth = this.dateInputMax;
        date.setFullYear(date.getFullYear() - 120);
        this.dateInputMin = this.formatDate(date);
    },
    formatDate(date) {
        return `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())}`;
    },
    pad(valor) {
        return valor.toString().padStart(2, '0');
    },
    recaptchaCallback(token){
        this.form.recaptcha_token = token;
    },
    getCompanyLogoFilepath(){
        const getCompany = axios.get('/api/v1/company/logo/filepath')
            .then( response => {
                if(response.status > 199 || response.status < 300){
                    if(response.data.data){
                    var path = response.data.data.path;
                    this.companyLogo = path;
                    localStorage.setItem('logo',  window.location.origin+"/"+path);
                  }
                }

            }).catch(error => {
                console.log(error);
            })
    },
  },
  mounted() {
    this.getCompanyLogoFilepath();
    this.setRangeDateInput();
    this.isRegisterError = !!this.regError;
    document.body.classList.add("auth-body-bg");
    document.body.classList.add("min-height-unset");
    // fill page
    var page = document.body.querySelector(".account-pages");
    var container = document.body.querySelector(".account-pages .container");
    container.classList.remove('container')
    container.classList.add('container-fluid')
    page.classList.remove("pt-5")
    page.classList.remove("my-5")

  },
};
</script>

<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col-xl-7">
          <div class="auth-full-bg pt-lg-5 p-4">
            <div class="w-100">
              <div class="bg-overlay"></div>
              <div class="d-flex h-100 flex-column">
                <div class="p-4 mt-auto">
                  <div class="row justify-content-center">
                    <div class="col-lg-7">
                      <div class="text-center">
                        <h4 class="mb-3">
                          <i
                            class="bx bxs-quote-alt-left text-primary h1 align-middle mr-3"
                          ></i
                          ><span class="text-primary">80%</span>+ Em aumento de produtividade

                        </h4>

                        <div dir="ltr">
                          <b-carousel id="carousel-1" :interval="5000">
                            <b-carousel-slide>
                              <p class="font-size-16 mb-4 text-white">
                                "Sistema fantástico com uma tonelada de opções. Economizou diversas horas de trabalho e me ajudou
                               a ter fins de semanas mais tranquilos."
                              </p>
                              <div>
                                <h4 class="font-size-16 text-primary">
                                  Roberto
                                </h4>
                                <p class="font-size-14 mb-0 text-white">Arquiteto de software</p>
                              </div>
                            </b-carousel-slide>


                            <b-carousel-slide>
                              <p class="font-size-16 mb-4 text-white">
                                " Se todos os funcionarios da empresa são tão solícitos quanto a
                                  equipe de suporte, o desenvolvimento será uma experiência agradável.
                                   Vocês são maravilhosos. Mantenham o bom trabalho. "
                              </p>
                              <div>
                                <h4 class="font-size-16 text-primary">
                                  Larissa
                                </h4>
                                <p class="font-size-14 mb-0 text-white">Gerente de projetos</p>
                              </div>
                            </b-carousel-slide>
                          </b-carousel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-xl-5">
          <div class="auth-full-page-content pe-md-4 ps-md-4 p-4 pb-0 pt-0">
            <div class="w-100">
              <div class="d-flex flex-column h-100">
                <div class="mb-4 mb-md-5" v-if="companyLogo">
                  <a href="/" class="d-block auth-logo d-flex justify-content-center">
                    <img
                      :src="companyLogo? companyLogo : '/images/dalth-logo.png'"
                      alt=""
                      width="25%"
                      class="auth-logo-dark"
                    />
                    <img
                      :src="companyLogo? companyLogo : '/images/dalth-logo.png'"
                      alt=""
                      width="25%"
                      class="auth-logo-light"
                    />
                  </a>
                </div>
                <div class="my-auto">
                  <div>
                    <h5 class="text-primary">Registro de Usuários</h5>
                    <!-- <p class="text-muted">Registre-se agora e aproveite nossos serviços</p> -->
                  </div>

                  <div class="mt-4">
                    <b-form class="p-2" :action="submitUrl" method="POST" @submit="formValidate">
            <slot />

            <b-form-group id="email-group" label="E-mail" label-for="email" class="mb-3">
                <b-form-input
                type="email"
                class="form-control"
                id="email"
                name="email"
                placeholder="Digite seu e-mail"
                :class="{ 'is-invalid': submitted && $v.form.email.$error }"
                v-model="form.email"
                ></b-form-input>
              <div
                v-if="submitted && $v.form.email.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.form.email.required">Insira um e-mail</span>
                <span v-else-if="!$v.form.email.email">Insira um e-mail válido</span>
                <span v-else-if="!$v.form.email.minLength">O email deve conter no mínimo 6 caracteres</span>
              </div>
             </b-form-group>

            <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    class="mb-3"
                    label="Nome"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      type="text"
                      placeholder="Digite seu nome"
                      v-model="form.name"
                      :class="{ 'is-invalid': submitted && $v.form.name.$error }"
                    ></b-form-input>
                    <div
                v-if="submitted && $v.form.name.$error"
                class="invalid-feedback"
                    >
                        <span v-if="!$v.form.name.required">Insira seu nome</span>
                        <span v-else-if="!$v.form.name.minLength">O nome deve conter no mínimo 3 caracteres</span>
                        <span v-else-if="!$v.form.name.maxLength">O nome deve conter no mínimo 15 caracteres</span>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-group
                      class="mb-3"
                      label="Sobrenome"
                      label-for="lastname"

                    >
                      <b-form-input
                        id="lastname"
                        type="text"
                        placeholder="Digite seu sobrenome"
                        v-model="form.last_name"
                       :class="{ 'is-invalid': submitted && $v.form.last_name.$error }"
                      ></b-form-input>
                      <div v-if="submitted && $v.form.last_name.$error" class="invalid-feedback">
                        <span v-if="!$v.form.last_name.required">Insira seu sobrenome</span>
                        <span v-else-if="!$v.form.last_name.minLength">O sobrenome deve conter no mínimo 3 caracteres</span>
                        <span v-else-if="!$v.form.last_name.maxLength">O sobrenome deve conter no máximo 20 caracteres</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </div>


        <div class="row mb-3">

            <b-form-group id="birth-group" label="Nascimento" label-for="birth" class="col-md-6">
              <b-form-input
                id="birth"
                v-model="form.birth"
                name="birth"
                type="date"
                placeholder="00/00/0000"
                :max="dateInputMax"
                :min="dateInputMin"
                :class="{ 'is-invalid': submitted && $v.form.birth.$error }"
              ></b-form-input>
              <div v-if="submitted && $v.form.birth.$error" class="invalid-feedback">
                <span v-if="!$v.form.birth.required">Insira sua Data de nascimento</span>
                <span v-else-if="!$v.form.birth.minLength">A data de nascimento deve conter 8 caracteres</span>
                <span v-else-if="!$v.form.birth.maxLength">A data de nascimento deve conter 8 caracteres</span>
              </div>
            </b-form-group>
            <b-form-group id="cellphone-group" v-mask="'(##) #####-####'" label="Telefone" label-for="cellphone" class="col-md-6">
              <b-form-input
                id="cellphone"
                v-model="form.cellphone"
                name="cellphone"
                type="text"
                placeholder="(00) 00000-0000"
                :class="{ 'is-invalid': submitted && $v.form.cellphone.$error }"
              ></b-form-input>
              <div v-if="submitted && $v.form.cellphone.$error" class="invalid-feedback">
                <span v-if="!$v.form.cellphone.required">Insira um telefone válido</span>
                <span v-else-if="!$v.form.cellphone.minLength">O telefone deve conter no mínimo 10 caracteres</span>
                <span v-else-if="!$v.form.cellphone.maxLength">O telefone deve conter no máximo 11 caracteres</span>
              </div>
            </b-form-group>

        </div>
        <div class="row mb-3">

            <b-form-group id="password-group" label="Senha" label-for="password" class="col-md-6 mb-3">
              <b-form-input
                id="password"
                v-model="form.password"
                name="password"
                type="password"
                placeholder="Digite uma Senha"
                :class="{ 'is-invalid': submitted && $v.form.password.$error }"
              ></b-form-input>
              <div v-if="submitted && $v.form.password.$error" class="invalid-feedback">
                <span v-if="!$v.form.password.required">Insira uma senha</span>
                <span v-else-if="!$v.form.password.minLength">A senha deve conter no mínimo 6 caracteres</span>
                <span v-else-if="!$v.form.password.maxLength">A senha deve conter no mínimo 20 caracteres</span>
                <span v-else-if="!$v.form.password.sameAsPassword">As senhas são diferentes</span>
              </div>
            </b-form-group>
            <b-form-group label="Confirme a Senha" label-for="password-confirm" class="col-md-6 mb-3">
              <b-form-input
                id="password-confirm"
                v-model="form.password_confirmation"
                name="password_confirmation"
                type="password"
                placeholder="Confirme a senha"
                :class="{ 'is-invalid': submitted && $v.form.password_confirmation.$error }"
              ></b-form-input>
              <div v-if="submitted && $v.form.password_confirmation.$error" class="invalid-feedback">
                <span v-if="!$v.form.password_confirmation.required">Insira uma senha</span>
                <span v-else-if="!$v.form.password_confirmation.minLength">A senha deve conter no mínimo 6 caracteres</span>
                <span v-else-if="!$v.form.password_confirmation.maxLength">A senha deve conter no mínimo 20 caracteres</span>
                <span v-else-if="!$v.form.password_confirmation.sameAsPassword">As senhas são diferentes</span>
              </div>
            </b-form-group>
        </div>
        <div class="row mb-3 register-recaptcha">
            <b-form-group class="col-md-6 mb-3">
                <Recaptcha :sitekey="siteKey" :callback="recaptchaCallback" v-if="showRecaptcha" />
            </b-form-group>
        </div>
            <div class="d-grid">
              <b-button type="submit" variant="primary" class="btn-block" :disabled="register.load"><span v-if="!register.load">Cadastrar</span> <span v-if="register.load" class="loader-dotted"></span></b-button>
            </div>

            <div class="mt-4 text-center">
              <p class="mb-0">

                Ao se registrar, você concorda com o nossos
                <a
                  href="javascript: void(0);"
                  class="text-primary"
                >Termos de uso</a>
              </p>
            </div>
          </b-form>

                    <div class="text-center">
                      <p>
                        Já possui conta ?
                        <a href="/login" class="fw-medium text-primary">
                          Entrar</a
                        >
                      </p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container-fluid -->
  </div>
</template>

<style lang="scss" scoped>
::v-deep .carousel-caption {
  position: relative !important;
  right: 0;
  bottom: 1.25rem;
  left: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #495057;
  text-align: center;
}

::v-deep .carousel-indicators li {
  background-color: rgba(85, 110, 230, 0.25);
}

.text-white{
    color: #eee;
}
body{
    min-height: none !important;
}
.lds-dual-ring, .lds-dual-ring:after {
    width: 20px;
    height: 20px;
    /* font-weight: 100 !important; */
}
</style>
<style>

.register-recaptcha .recaptcha-col-sm .g-recaptcha {
    transform: scale(1.22);
    transform-origin: 0 0;
}
.loader-dotted {
  content: " ";
  display: inline-block;
  color: #ffffff;
  font-size: 10px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  /* margin: 72px auto; */
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
