<template>
      <Layout >
        <a href="" class="d-none download" download></a>
        <span style="visibility: hidden" id="enter-blur" tabindex="0"></span>
        <!-- BEGIN: Header -->
        <!-- @mouseenter="showHeaderMenu(true, true)" -->
        <div @click="closeParentMenu" v-if="groups.length != 0 && tasks.length != 0" :style="`z-index: ${maxGroupZIndex}`" class="header-sticky z-index-header d-flex">
            <PageHeader :title="workflow.name" :items="workflowHeaderItems" class="ms-2 me-1 workflow-header w-100 pe-md-5">
                <div class="d-flex justify-content-start align-items-center col">
                    <span v-b-tooltip.top="'Editar workflow'">
                        <b-button @click.prevent="openWorkflowEdit($event)" right
                        variant="btn-edit text-decoration-none fs-6 text-white"><i class="fas fa-pencil-alt rem-size-2"></i>
                        </b-button>
                    </span>
                    <span v-b-tooltip.top="'Expandir grupos'" v-if="workflow && allGroupsColapsed">
                        <b-button @click.prevent="toggleAllGroups({show: true, workflow_id: workflow.id})" right
                        variant="btn-edit text-decoration-none text-white py-0 d-flex flex-column">
                        <!-- <i class="fas bx bx-redo bx-rotate-90 fw-bold"></i> -->
                        <i class="bx bx-chevron-up fw-bold"></i>
                        <i class="bx bx-chevron-down mt-n1 fw-bold"></i>
                        </b-button>
                    </span>
                    <span v-b-tooltip.top="'Contrair grupos'" v-if="workflow && !allGroupsColapsed">
                        <b-button @click.prevent="toggleAllGroups({show: false, workflow_id: workflow.id})" right
                        variant="btn-edit text-decoration-none text-white py-0 d-flex flex-column">
                        <!-- <i class="fas bx bx-redo bx-rotate-270 fw-bold"></i> -->
                        <i class="bx bx-chevron-down fw-bold"></i>
                        <i class="bx bx-chevron-up mt-n1 fw-bold"></i>
                        </b-button>
                    </span>
                    <transition name="fade">
                        <Favorite :isFavorite="workflow.is_favorite" :workflowId="workflow.id" v-if="hasWorkflow" class="py-0 px-3 d-flex" />
                    </transition>
                    <span v-b-tooltip.top="'Filtrar'">
                        <b-dropdown v-model="showFilterDropdown" @show="setFilterDropdown(true)" lazy variant="white" ref="dropdown-filter-navbar" right toggle-class="d-flex align-items-center" menu-class="workflow-filter-dimensions mw-100" v-if="workflow" style="z-index: 10001;">
                            <template v-slot:button-content>
                                <i class="text-white fas fa-filter link-cursor rem-size-2"></i>
                            </template>
                            <FilterBuilder :filter.sync="localFilter" :columns="columns" :simple-date="true" />
                        </b-dropdown>
                    </span>
                    <FilterBadges class="col" />
                </div>
                <div class="d-flex justify-content-center align-items-center col">

                </div>
                <div class="d-flex justify-content-end align-items-center col">
                    <span v-b-tooltip.top="'Importar'">
                        <aasp :workflow-id="workflow.id" v-if="checkPlugin(1) && hasWorkflow" class="p-2" />
                    </span>
                    <span v-b-tooltip.top="'Distribuições'">
                        <aaspConsult :workflow-id="workflow.id" v-if="checkPlugin(1) && hasWorkflow" class="p-2" />
                    </span>
                    <span v-b-tooltip.top="'Criar'">
                        <b-dropdown size="sm" lazy variant="btn-edit text-decoration-none fs-6 text-white p-2" menu-class="right-0">
                            <template v-slot:button-content>
                                <i class="bx bx-list-plus rem-size-3 d-flex"></i>
                            </template>
                            <b-dropdown-item id="new-group" class="rem-size-2"
                            @click="menuShow.inptCreateGroup = !menuShow.inptCreateGroup, menuShow.inptFilter = false">
                            Grupo</b-dropdown-item>
                            <!-- <b-dropdown-item class="rem-size-2" @click="openForm">Formulário</b-dropdown-item> -->

                        </b-dropdown>
                    </span>
                    <span v-b-tooltip.top="'Exportar'">
                        <b-button @click.prevent="exportReport(workflow.id)" right
                        variant="btn-edit text-decoration-none fs-6 text-white p-2" :disabled="exportLoad">
                            <i v-if="exportLoad" class="bx bx-spin bx-loader rem-size-3 d-flex"></i>
                            <i v-else class="bx bx-export rem-size-3 d-flex"></i>
                        </b-button>
                    </span>
                    <span v-b-tooltip.topright="'Formulários'">
                        <b-dropdown size="sm" lazy variant="btn-edit text-decoration-none fs-6 text-white p-2" menu-class="right-0">
                            <template v-slot:button-content>
                                <i class="bx bx-list-ol rem-size-3 d-flex text-white"></i>
                            </template>
                            <b-dropdown-item class="rem-size-2" @click="openForm">Novo Formulário</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item class="rem-size-2" v-for="(workflowForm, keyForm) in workflowForms" :key="keyForm"
                                @click="openForm(workflowForm)" >{{ workflowForm.title }}</b-dropdown-item>

                        </b-dropdown>

                    </span>
                </div>
            </PageHeader>
        </div>
        <b-modal id="new-group-modal" size="sm" v-model="menuShow.inptCreateGroup" title="Criar Grupo" scrollable title-class="rem-size-3" hide-footer>
            <b-form v-if="menuShow.inptCreateGroup" class="form form-horizontal" role="form" name="form-set-group"
                id="form-set-group" @submit.prevent="setGroup">
                <div class="input-group">
                    <b-form-input class="rem-size-2" size="sm" for="text" id="group_name" name="group_name" placeholder="Nome do grupo"></b-form-input>
                    <button class="btn btn-success btn-sm" type="submit" form="form-set-group">
                    <span class="bx bx-plus-medical"></span>
                    </button>
                </div>
            </b-form>
        </b-modal>

        <!-- BEGIN: Base wrapper -->
        <div class="show-base">
            <transition-group name="fade" tag="div"  v-if="groups.length == 0 || tasks.length == 0 || columns.length == 0 || tasksProcessing.loadTask">
                <div class="row splash-load w-100 h-75 d-flex justify-content-center align-items-center p-0 m-0" :style="`z-index: ${maxGroupZIndex}`" v-show="columnsProcessing.loadColumn || tasksProcessing.loadTask || columnValuesProcessing.loadColumnValue"  @click="closeParentMenu" key="0">
                    <b-img class="splash-load-image" :src="company.companyLogo" v-if="company.companyLogo"/>
                    <!-- <b-img class="splash-load-image" src="/images/logo.png" v-else/> -->
                    <!-- <b-progress v-model.lazy="loadProgressValue" class="splash-load-progress" variant="info" striped :animated="true" :max="100"></b-progress> -->
                    <b-progress value="100" class="splash-load-progress" variant="info" striped :animated="true" :max="100">
                    </b-progress>
                    <h5 class="text-center" v-if="groups.length == 0">carregando grupos...</h5>
                    <h5 class="text-center" v-else-if="tasks.length == 0">carregando...</h5>
                </div>
            </transition-group>
            <div v-else class="show-base-overflow-x" @click="showHeaderMenu(false, false)">
                <draggable group="groups" class="list-group" v-model="groupsDragList"
                    @start="groupStartDrag(true)" @end="groupStartDrag(false)" v-bind="dragOptions"  handle=".group-drag-handle" >
                    <GroupComponent v-for="(group, groupKey) in groups" :key="group.id" :group="group" :group-key="groupKey" :groups-length="groups.length" :workflow-forms="workflowForms" :tasks-length="typeof tasks[`${group.id}`] != 'undefined'? tasks[`${group.id}`].length : 0" :style="[`width: 100%;height: 100%;`]">
                        <template #columns-headers>
                            <ColumnsHeadersComponent @changeColumn="showItemOptions" :columns="columns" :workflow="workflow" :groupColor="group.color" :groupId="group.id" />
                        </template>

                        <!-- BEGIN: No Task Drag and Drop handle -->
                        <div
                            v-if="(typeof tasks[group.id] !== 'undefined')"
                            class="task-item handle"
                            :data-id="null"
                            :data-group-id="group.id"
                            :data-index="0"
                            :style="`z-index: ${maxGroupZIndex}`"
                            @dragend="handleDrop($event, true)"
                            @dragenter="handleDrag($event, 0, true)"
                            @dragleave="handleDrag($event, 0, false)"
                        >
                            <div v-if="tasks[`${group.id}`].length == 0" v-observe-visibility="{
                                    callback: (isVisible, entry) => infiniteHandlerMoreTasks(isVisible, entry, group.id, 0, 1),
                                    once: false
                            }" class="row infinite-handler-more-tasks no-task-drag-handle"></div>

                        </div>

                                <DynamicScroller
                                    key="0"
                                    key-field="id"
                                    direction="vertical"
                                    class="task-group-item item cluster-toggle task-observer drop-zone margin-n1"
                                    ref="scroller"
                                    cache="100"
                                    :id="`sortable-list-${group.id}`"
                                    :style="[`width: 100%;height: 100%;`]"
                                    :items="tasks[`${group.id}`]"
                                    :min-item-size="30"
                                    :page-mode="true"
                                    :buffer="50"
                                    :emit-update="false"
                                >
                                <template v-slot="{ item: row, index: rowKey, active  }">
                                    <DynamicScrollerItem
                                        :active="active"
                                        :data-active="active"
                                        :item="{id: row.id}"
                                        :index="parseInt(row.id)"
                                        :data-index="row.id"
                                        :size-dependencies="[
                                            30 + ((row.show_subtasks || openSubtasks.indexOf(row.id) != -1) ? row.subtasks.length : 0),
                                        ]"
                                        >

                                <div
                                    :key="row.id"
                                    class="task-item handle cell-height"
                                    :data-id="row.id"
                                    :data-group-id="group.id"
                                    :data-index="rowKey"
                                    v-show="!hideAllTasks"
                                    draggable="true"
                                    style="min-height: 1px;"
                                    @dragend="handleDrop($event, true)"
                                    @dragenter="handleDrag($event, rowKey, true)"
                                    @dragleave="handleDrag($event, rowKey, false)"
                                    @dragstart="handleDragStart($event)"
                                    >


                                    <div class="border-bottom border-top d-flex task_item cell-height task_drag" :key="row.id">
                                            <RowComponent :group="group" :row="row">
                                                <div v-for="(columnValue, columnValueKey) in row.column_values" :key="columnValue.id"
                                                    :class="[{
                                                        'd-none': columnsById[columnValue.workflow_column_id].hidden,
                                                        'left-freez bg-default': columnValueKey == 0,
                                                        'mnw-col': columnValueKey != 0,
                                                        'mnw-col-title left-140': columnValueKey == 0 && !row.heritage,
                                                        'mnw-col-title-sub left-task': columnValueKey == 0 && row.heritage,
                                                    },  `resizer_width_${columnValue.workflow_column_id}`]"
                                                    :style="{ 'width': `${columnsById[columnValue.workflow_column_id].width}px` }"
                                                    :ref="`resizer_width_${columnValue.workflow_column_id}`"
                                                    :data-workflow-column-id="columnValue.workflow_column_id"
                                                    :data-coll-type="columnValue.workflow_column_type_id"
                                                    class="border-start border-end resize_width col-normalizer task-row-shadow is-task-row cell-height validate column-value-item">
                                                    <RenderColumns
                                                        :disabled="checkDisableItem(false, columnValue.workflow_column_id)"
                                                        :column="columnsById[`${columnValue.workflow_column_id}`]"
                                                        :column-value="columnValue"
                                                        :task="row"
                                                        :group="group"
                                                        :dropright="columnValueKey  + 2 >= columns.length"
                                                        :user="user"
                                                        :user-item-disable="userItemDisable"
                                                        :disable-date-from-company-config="disableDateFromCompanyConfig"
                                                        :extensions-accept="extensionsAccept"
                                                        @openDrawer="rightDrawer"
                                                        v-if="columnValue && !columnsById[`${columnValue.workflow_column_id}`].hidden && !columnUpdateLoad"
                                                    />

                                                </div>
                                            </RowComponent>
                                    </div>
                                </div>

                                        <draggable :draggable="`.subtasks_${row.id}`"  :group="`subtasks_group_${row.id}`" :disabled="!workflow.move_tasks"
                                        @start="dragIsSubtask = true"
                                        @dragend.native="handleDropSubtasks($event, row.id)"
                                        @dragenter.native="handleDragSubtasks($event, rowKey, true)"
                                        @dragleave.native="handleDragSubtasks($event, rowKey, false)"
                                        style="min-height: 1px;"
                                        v-bind="dragOptions"
                                        :list="row.subtasks"
                                        class="cluster-toggle-item"
                                        v-if="!columnsProcessing.loadColumnSequence && row.subtasks && (row.show_subtasks || openSubtasks.indexOf(row.id) != -1)"
                                        >
                                        <div v-for="(subtaskRow, subtaskRowKey) in row.subtasks" :key="subtaskRow.id"
                                            class="task-group-item subtask-item"
                                            :id="`subtask_${subtaskRow.id}`"
                                            :class="[`subtasks_${row.id}`]"
                                            :data-id="subtaskRow.id"
                                            :data-group-id="group.id"
                                            :data-index="subtaskRowKey"
                                        >
                                            <RowComponent :group="group" :row="subtaskRow">
                                                <div v-for="(subtaskColumnValue, subtaskColumnValueKey) in subtaskRow.column_values" :key="subtaskColumnValue.id"
                                                    :class="[{
                                                        'd-none': columnsById[subtaskColumnValue.workflow_column_id].hidden,
                                                        'left-freez bg-default': subtaskColumnValue.workflow_column_type_id == 9,
                                                        'mnw-col': subtaskColumnValueKey != 0,
                                                        'mnw-col-title-sub left-task-sub': subtaskColumnValue.workflow_column_type_id == 9,
                                                    },  `resizer_width_${subtaskColumnValue.workflow_column_id}`]"
                                                    :style="{ 'width': subtaskColumnValue.workflow_column_type_id == 9 ? `${columnsById[subtaskColumnValue.workflow_column_id].width - 42}px`: `${columnsById[subtaskColumnValue.workflow_column_id].width}px` }"
                                                    :ref="`resizer_width_${subtaskColumnValue.workflow_column_id}`"
                                                    :data-workflow-column-id="subtaskColumnValue.workflow_column_id"
                                                    :data-coll-type="subtaskColumnValue.workflow_column_type_id"
                                                    class="border-start border-end resize_width col-normalizer task-row-shadow cell-height validate">
                                                    <RenderColumns
                                                        :disabled="checkDisableItem(false, subtaskColumnValue.workflow_column_id)"
                                                        :column="columnsById[`${subtaskColumnValue.workflow_column_id}`]"
                                                        :column-value="subtaskColumnValue"
                                                        :task="subtaskRow"
                                                        :group="group"
                                                        :dropright="subtaskColumnValueKey  + 2 >= columns.length"
                                                        :user="user"
                                                        :user-item-disable="userItemDisable"
                                                        :disable-date-from-company-config="disableDateFromCompanyConfig"
                                                        :extensions-accept="extensionsAccept"
                                                        @openDrawer="rightDrawer"
                                                        v-if="subtaskColumnValue && !columnsById[`${subtaskColumnValue.workflow_column_id}`].hidden && !columnUpdateLoad"
                                                    />
                                                </div>
                                            </RowComponent>
                                        </div>
                                        <TaskAdd
                                            :taskDefaultValue="taskDefaultValue"
                                            :hideAllTasks="hideAllTasks"
                                            :group="group"
                                            :columnSum="columnSum[`${group.id}`]"
                                            :workflow-cells="cells"
                                            :workflowColumns="columns"
                                            :parent="row"
                                            ></TaskAdd>
                                        </draggable>


                                <div v-if="(((rowKey % (tasksLimit / 2) == 0) || rowKey == tasks[group.id].length - 1) && rowKey > rowPage[group.id] )"  v-observe-visibility="{
                                    callback: (isVisible, entry) => infiniteHandlerMoreTasks(isVisible, entry, group.id, rowKey, calledPage[group.id] + 1),
                                    once: true
                                }" class="row infinite-handler-more-tasks"></div>
                                </DynamicScrollerItem>

                                </template>

                            </DynamicScroller>


                    <!-- row add task -->
                    <TaskAdd
                      :taskDefaultValue="taskDefaultValue"
                      :hideAllTasks="hideAllTasks"
                      :group="group"
                      :columnSum="columnSum[`${group.id}`]"
                      :workflow-cells="cells"
                      :workflowColumns="columns"
                      ></TaskAdd>

                    </GroupComponent>
                </draggable>
            </div>
            <!-- BEGIN: DRAWERS AREA -->

            <!-- BEGIN: BOTTOM MENU DRAWER -->
            <Drawer @close="clearSelectedTasks" :align="'down'" :closeable="true" :maskClosable="true" :mask="false"
              :zIndex="1002" style="position:absolute" id="drawer-elements"
              :class="{
                'drawer-elements-plugin-export': checkPlugin(2) || checkPlugin(3) || checkPlugin(4) || checkPlugin(5),
                'drawer-elements-default': !checkPlugin(2) && !checkPlugin(3) && !checkPlugin(4) && !checkPlugin(5) }">

              <div v-if="Object.keys(tasksSelecteds).length > 0 || showBottomMenu" class="bg-elements-drawer">
                <div class="row justify-content-center align-items-center mt-n3">
                  <!-- Counter -->
                  <div class="col d-flex justify-content-center">
                    <span class="btn text-decoration-none text-secondary fs-3 align-middle">{{
                      Object.keys(tasksSelecteds).length
                    }}</span>
                  </div>

                   <!-- Duplicate -->
                   <div class="col d-flex justify-content-center">
                    <b-dropdown lazy size="lg" toggle-class="text-decoration-none"
                      variant="btn text-decoration-none text-secondary" no-caret dropup v-b-tooltip.hover="'Duplicar'">
                      <template #button-content>
                        <i class="bx bx-copy-alt align-middle rem-size-3"></i>
                        <div class="sub-drawer-menu">Duplicar</div>
                      </template>
                      <b-dropdown-item href="javascript:void(0)"
                        @click="duplicateSelectedRows({withData: true})">Tarefa</b-dropdown-item>
                      <b-dropdown-item href="javascript:void(0)" @click="duplicateSelectedRows({withData: true, withInteractions: true})">Tarefa
                        +
                        interações</b-dropdown-item>
                    </b-dropdown>
                  </div>

                  <!-- Convert -->
                  <div class="col d-flex justify-content-center" v-if="showConvertToSubtask">
                    <b-dropdown
                      lazy
                      size="lg"
                      toggle-class="text-decoration-none"
                      variant="btn text-decoration-none text-secondary"
                      menu-class="dropdown-bottom-0 scr-right"
                      no-caret dropup v-b-tooltip.hover="'Converter para subtarefa'"
                      @hide="searchDropdown = ''"
                      v-if="convertToSubtask"
                      >
                      <template #button-content>
                        <i class="fas fa-level-down-alt align-middle rem-size-3"></i>
                        <div class="sub-drawer-menu">Converter</div>
                      </template>
                      <b-dropdown-form @submit.stop.prevent
                        @keypress.enter.stop.prevent class="bg-default dropdown-search-input">
                        <b-form-group @submit.stop.prevent @keypress.enter.stop.prevent>
                          <b-form-input class="form-input" placeholder="Buscar"
                            v-model="searchDropdown" debounce="200"></b-form-input>
                        </b-form-group>
                      </b-dropdown-form>

                      <b-dropdown-item href="javascript:void(0)" v-for="task in filterConvertTasksOptions" :key="task.id" @click="convertSelectedTasks({ convertToSubtask, parentTaskId: task.id })">{{task.name}}</b-dropdown-item>
                    </b-dropdown>
                    <b-button @click.prevent="convertSelectedTasks({convertToSubtask})"
                      variant="btn dropdown-toggle btn-btn text-decoration-none text-secondary btn-lg text-decoration-none dropdown-toggle-no-caret" v-else>
                      <i class="fs-4 align-middle" :class="{'fas fa-level-down-alt': convertToSubtask, 'fas fa-level-up-alt': !convertToSubtask}" v-b-tooltip.hover="(convertToSubtask?'Converter para subtarefa':'Converter para tarefa')"></i>
                      <div class="sub-drawer-menu">Converter</div>
                    </b-button>
                  </div>

                    <!-- Move -->
                    <div class="col d-flex justify-content-center" v-if="!hasSubtasksSelected">
                        <b-dropdown
                            lazy
                            size="lg"
                            toggle-class="text-decoration-none"
                            variant="btn text-decoration-none text-secondary"
                            menu-class="dropdown-bottom-0 scr-right"
                            no-caret dropup v-b-tooltip.hover="'Mover entre grupos'"
                            @hide="searchDropdown = ''"
                        >
                        <template #button-content>
                            <i class="far fa-arrow-alt-circle-right align-middle rem-size-3"></i>
                            <div class="sub-drawer-menu">Mover</div>
                        </template>
                        <b-dropdown-form @submit.stop.prevent
                            @keypress.enter.stop.prevent class="bg-default dropdown-search-input">
                            <b-form-group @submit.stop.prevent @keypress.enter.stop.prevent>
                            <b-form-input class="form-input" placeholder="Buscar"
                                v-model="searchDropdown" debounce="100"></b-form-input>
                            </b-form-group>
                        </b-dropdown-form>
                        <b-dropdown-item href="javascript:void(0)" v-for="group in filterMoveGroupOptions" :key="group.id" @click="moveSelectedTasks(group)">{{group.name}}</b-dropdown-item>
                        </b-dropdown>
                    </div>

                  <!-- Plugin report export -->
                  <div class="col d-flex justify-content-center" v-if="checkPlugin(2) || checkPlugin(3) || checkPlugin(4) || checkPlugin(5) || checkPlugin(6) || checkPlugin(7)">
                    <pluginExports :taskIds="Object.keys(tasksSelecteds)" />
                  </div>
                    <!-- Delete -->
                    <div class="col d-flex justify-content-center me-5">
                    <b-button @click="deleteSelectedTasks"
                      variant="btn dropdown-toggle btn-btn text-decoration-none text-secondary btn-lg text-decoration-none dropdown-toggle-no-caret"><i
                        class="fas fa-trash-alt align-middle rem-size-3" v-b-tooltip.hover="'Excluir'"></i>
                      <div class="sub-drawer-menu">Excluir</div>
                    </b-button>
                  </div>

                </div>
              </div>
            </Drawer>
            <!-- END: BOTTOM MENU DRAWER -->

            <!-- BEGIN: TASK ACTIVITIES DRAWER -->
            <Drawer @close="rightDrawer" :align="'right'" :closeable="false" :maskClosable="true" :mask="false"
                :zIndex="1002" class="chat-drawer">
                <div v-if="openRightDrawer">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-1 pe-0" style="width: 12px">
                      <b-dropdown lazy toggle-class="nav-btn" menu-class="dropdown-menu-custom" right variant="white" offset="90">
                        <template v-slot:button-content>
                          <i class="bx bx-dots-vertical"></i>
                        </template>
                        <b-dropdown-item @click="showEditTaskFile(drawerData.rowId, drawerData.groupId, true)">Arquivos
                        </b-dropdown-item>
                        <b-dropdown-item @click="showActivityLog = !showActivityLog">
                            <span :class="{'text-log-button': showActivityLog}">Logs</span>
                        </b-dropdown-item>
                      </b-dropdown>

                    </div>
                    <div class="col-8">
                        <div class="row task-activity-title">
                            <div class="d-flex align-items-center">
                                <h5 class="offcanvas-title overflow-text" id="offcanvasExampleLabel">
                                    <span v-if="drawerData.title.length > 26" v-b-tooltip.bottom="drawerData.title">{{ drawerData.title }}</span>
                                    <span v-else>{{ drawerData.title }}</span>
                                </h5>
                                <i
                                  class="bx bx-link-alt opacity-hover link-cursor ps-1 link-cursor font-size-15"
                                  :class="{'text-success': copying}"
                                  v-b-tooltip.bottom="!copying ? `${localeOrigin}/workflow/show/${workflow.id}/${drawerData.rowId}` : 'Copiado!'"
                                  @click.prevent="copyToClipboard(`${localeOrigin}/workflow/show/${workflow.id}/${drawerData.rowId}`)"
                                  ></i>
                            </div>
                            <span class="sub d-inline pt-1">
                                <span><i class="bx bx-task"></i> {{ drawerData.rowId }}</span>
                                <span><i class="bx bx-user ms-1"></i> {{ drawerData.creator }}</span>
                                <span><i class="bx bx-calendar ms-1"></i> {{ getDateFormat(drawerData.created_at) }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="col-3 d-flex justify-content-end">
                      <div @click="rightDrawer(null, null)" class="close-btn custom-close-btn">
                        <div class="leftright"></div>
                        <div class="rightleft"></div>
                      </div>
                    </div>
                  </div>
                  <div class="border-bottom pb-3">
                    <div class="col-12" >
                      <div class="position-relative" >
                        <!-- :disabled="textDisable"  -->
                        <b-textarea v-model="drawerData.description" debounce="500" @click="editDescrition"
                          @blur="toggleDescriptionButton(textDisable, drawerData.rowId, drawerData.name, drawerData.description)"
                          :class="{ 'is-invalid': !drawerData.description, 'light-text-area': textDisable }"
                          class="form-control bg-text-area w-100 scr-right" name="updateDescription" id="updateDescription"
                          ref="description" placeholder="Digite uma descrição..." rows="3" max-rows="3"
                          no-resize></b-textarea>
                        <div v-if="!drawerData.description" class="invalid-feedback">
                          <span class="pl-2">Adicione uma descrição</span>
                        </div>
                      </div>
                      <div class="row justify-items-end">
                        <span class="text-end mb-n3 "
                            :class="{ 'mt-n1': drawerData.description, 'mt-n4': !drawerData.description }">
                            <b-button
                            ref="description-update-task"
                            v-if="drawerData.rowUserId == user.id"
                            right
                            variant="btn-edit text-decoration-none fs-6 text-secondary mt-n5 margin-description-button">
                            <i v-show="textDisable" class="fas fa-pencil-alt description-edit" @click="editDescrition"></i>
                            <i v-show="!textDisable"  class="fas fa-arrow-circle-right description-save" @click="toggleDescriptionButton(textDisable, drawerData.rowId, drawerData.name, drawerData.description)"></i>
                            </b-button>
                        </span>
                      </div>
                    </div>
                  </div>
                  <TaskHistory :show-log.sync="showActivityLog" :taskId="drawerData.rowId" class="chat-start-content-description-top"/>
                </div>
            </Drawer>
            <!-- END: TASK ACTIVITIES DRAWER -->

            <!-- BEGIN: MODAL AREA -->

            <!-- build form modal -->
            <b-modal  ref="modal-select-limit" id="modal-select-limit" size="md" :title="'Limite de seleção'" ok-only title-class="font-18" @hide="toggleTasksLimitModal(false)">
                Para realizar esta ação selecione no máximo {{ tasksSelectedLimit }} tarefas.
            </b-modal>

            <!-- add col modal -->
            <b-modal id="modal-set-item" size="xl" :title="modalTitle" scrollable title-class="font-18" hide-footer v-if="hasWorkflow">
                <SetItem :workflowId="workflow.id" :colId="selectedColId" @save="updateModalColumns(workflow.id, selectedColId)" />
            </b-modal>

            <!-- Edit task files modal -->
            <b-modal id="modal-edit-task-files" size="xl" :title="taskDataOptions.modalTitle" title-class="font-18" hide-footer
                @hide="findTask(taskDataOptions.taskSelected)" v-if="hasWorkflow">
                <EditFiles
                    :id="taskDataOptions.taskSelected"
                    :isChat="taskDataOptions.isChat"
                    :isAdminArea="true"
                    :isRequired="taskDataOptions.taskSelectedItemRequired"
                    :itemName="taskDataOptions.taskSelectedItemName"
                />
            </b-modal>

            <!-- checklist modal -->
            <b-modal id="modal-checklist" class="scr-right" size="xl" :title="taskDataOptions.modalTitle" scrollable title-class="font-18"
            hide-footer @hide="findTask(taskDataOptions.taskSelected)">
            <EditTaskChecklist :taskId="taskDataOptions.taskSelected" :workflowId="parseInt($route.params.id)"
                :fastMode="taskDataOptions.fastMode" />
            </b-modal>

            <!-- edit workflow modal -->
            <b-modal id="workflow-edit" size="lg" title="Editar" title-class="font-18" hide-footer
                :no-enforce-focus="true" @hide="getResponsibleList(workflow.id), getCollaboratorList(workflow.id)" v-if="hasWorkflow">
                <WorkflowEdit :workflowId="workflow.id" :searchUsers="usersList" @change="changeWorkflow" />
            </b-modal>

            <!-- build form modal -->
            <b-modal id="modal-form-build" size="xl" :title="formModalTitle" scrollable title-class="font-18" hide-footer @hide="clearWorkflowForm(workflow.id)" v-if="hasWorkflow">
                <BuildForm :workflow-id="workflow.id" :workflow-form-uuid="workflowFormId" />
            </b-modal>

            <!-- Show form modal -->
            <b-modal id="modal-form-show" size="xl" title="Formulário" scrollable title-class="font-18" hide-footer  no-close-on-backdrop>
                <ShowForm :prop-workflow-form-id="selectedFormUuid" :force-group-id="selectedFormGroup" :internal="true"/>
            </b-modal>

            <!-- END: MODAL AREA -->
        </div>

    </Layout>
</template>

<script>
    // Conf
    import axios from "axios";
    import config from '../../config/config';
    import showAlert from "../../components/alerts";

    // App Plugins
    import plugins from '@plugins/';

    // External Components
    import draggable from "vuedraggable";
    import Drawer from "vue-simple-drawer";

    // Internal Components
    import Layout from "@layouts/vertical.vue";
    import PageHeader from '@components/page-header.vue';
    import Favorite from '@components/favorite.vue';
    import GroupComponent from "@components/workflows/tasks/group.vue";
    import ColumnsHeadersComponent from "@components/workflows/tasks/columns-headers.vue";
    import RowComponent from "@components/workflows/tasks/row.vue";
    import RenderColumns from '@components/workflows/columns/RenderColumns.vue'
    import TaskAdd from "@components/workflows/tasks/add.vue";
    import TaskHistory from "@components/workflows/tasks/activity.vue";
    import FilterBuilder from "@components/filter-builder.vue";
    import FilterBadges from "@components/filter-badges.vue";

    // Modals
    import SetItem from "@components/workflows/management/column.vue";
    import EditFiles from "@components/workflows/management/files.vue";
    import EditTaskChecklist from "@components/workflows/management/checklist.vue";
    import WorkflowEdit from "@components/workflows/management/workflow.vue";
    import BuildForm from "./form/build-form";
    import ShowForm from "./form/show-form.vue";

    // Workflow Columns Components
    import {
        TitleColumn,
        LinkColumn,
        PersonColumn,
        TimeColumn,
        CurrencyColumn,
        ListColumn,
        DateColumn,
        ResponsibleColumn,
        CollaboratorColumn,
        TextColumn,
        ListComponentColumn
    } from "@components/workflows/columns";

    // Store Methods
    import {
        personsMethods,
        userMethods,
        pluginsMethods,
        usersOptionsMethods,
        workflowsMethods,
        tasksMethods,
        groupsMethods,
        columnsMethods,
        columnValuesMethods,
        filtersMethods,
        searchMethods

    } from '@state/helpers';

    // Store Computed
    import {
        searchComputed,
        personsComputed,
        companiesComputed,
        userComputed,
        pluginsComputed,
        usersOptionsComputed,
        workflowsComputed,
        tasksComputed,
        groupsComputed,
        columnsComputed,
        columnValuesComputed,
        filtersComputed,
    } from '@state/helpers';

    export default {
        name: 'ShowComponent',
        components:{
            // External Components
            draggable,
            Drawer,

            // Internal Components
            Layout,
            PageHeader,
            Favorite,
            GroupComponent,
            ColumnsHeadersComponent,
            RowComponent,
            RenderColumns,
            TaskAdd,
            TaskHistory,
            FilterBuilder,
            FilterBadges,

            // Modals
            SetItem,
            EditFiles,
            EditTaskChecklist,
            WorkflowEdit,
            BuildForm,
            ShowForm,

            // Workflow Columns Components
            RenderColumns,
            TitleColumn,
            LinkColumn,
            PersonColumn,
            TimeColumn,
            CurrencyColumn,
            ListColumn,
            DateColumn,
            ResponsibleColumn,
            CollaboratorColumn,
            TextColumn,
            ListComponentColumn,

            // App Plugins
            aasp: plugins.fileImport,
            aaspConsult: plugins.consult,
            pluginExports: plugins.pluginExports,
        },
        props:{

        },
        data() {
            return {
                // Search & filter
                localFilter: {
                    search: '',
                    status: '',
                    period: 0,
                    dateStart: '',
                    column: null,
                    columnOption: null,
                    dateEnd: '',
                    unread: false,
                    conditions: [],
                    columns: [],
                    options: [],
                    search: '',
                    data: [
                        {
                            column: null,
                            condition: null,
                            value: null,
                            isDate: false
                        }
                    ]
                },

                // Menu
                formModalTitle: '',
                workflowFormId: '',
                workflowForms: [],
                exportLoad: false,
                showHeader: false,
                menuShow: {
                    btnCreate: true,
                    inptCreateGroup: false,
                    btnFilter: true,
                    inptFilter: false,
                    btnForms: true,
                    btnExports: true
                },

                // Workflow edit Header button
                compare: {},

                // drag and drop
                taskSortedElement: '',
                taskSortToElement: '',
                subtaskSortToElement: '',
                dragOverAfterElementId: 0,
                draggingItemTransform: '',
                dragIsSubtask: false,

                selectedColId: null,
                selectedColType: null,
                modalTitle: '',

                // Files Modal
                taskGroupSelected: null,
                taskSelected: null,
                isChat: false,
                taskSelectedItemName: '',
                taskSelectedItemRequired: false,

                // Task Activities Drawer
                showActivityLog: false,
                openRightDrawer: false,
                textDisable: false,
                drawerData: {
                    title: '',
                    description: '',
                    groupId: 0,
                    userId: null,
                    rowId: null,
                },

                // Menu Drawer
                searchDropdown: '',
                openElementsDrawer: false,
                showConvertToSubtask: true,
                convertToSubtaskGroup: false,
                elementSelectedsIds: [],

                extensionsAccept: '',
                // hideAllTasks: false,
                cells: {
                    value: "",
                    index: null
                },

                // page control
                calledPageLoaded: false,

                // Load states
                columnUpdateLoad: false,

                // Query Navigation
                queryNavigation: {
                    task: 0,
                    checklist: 0
                }
            }
        },
        computed:{
            ...searchComputed,
            ...personsComputed,
            ...companiesComputed,
            ...userComputed,
            ...pluginsComputed,
            ...usersOptionsComputed,
            ...workflowsComputed,
            ...tasksComputed,
            ...groupsComputed,
            ...columnsComputed,
            ...columnValuesComputed,
            ...filtersComputed,

            // showHeader() {
            //     return this.advancedFiltersData.length > 0
            // },
            groupsDragList: {
                get() {
                    return this.groups;
                },
                set(value) {
                    this.updateGroupsSequence(value)
                }
            },
            tasksDragList: {
                get() {
                    return this.tasks
                },
                set(value) {
                    this.setTasks(value)
                }
            },
            hasWorkflow() {
                if(!this.workflow) {
                    return false
                }
                return typeof this.workflow.id !== undefined
            },
            columnsById(){
                let columns = {};
                this.columns.forEach((column)=>{
                    columns[column.id] = column;
                });

                return columns;
            },
            userItemDisable() {
                let disable = {};
                let user = {};
                user.id = 0;
                if(typeof this.user != 'undefined') {
                    user = this.user;
                }
                this.columns.forEach((item)=>{
                    if((this.user.id == item.readonly_user_id)) {
                        disable[item.id] = false;
                    } else {
                        disable[item.id] = true;
                    }
                });

                return disable;
            },
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
            statusList() {
                let column = this.columns.find((column) => column.workflow_column_type_id == 3);
                if(!column) return [];

                return column.options;

            },
            taskDefaultValue() {
                let columnTitle = this.columns.find((column) => column.workflow_column_id == 9);
                if(!columnTitle) return '';

                return columnTitle.default_value
            },
            filterConvertTasksOptions() {
                var name = '';
                let selectedTasks = Object.keys(this.tasksSelecteds);
                return this.tasks[this.convertToSubtaskGroup].filter((task) => {

                    return selectedTasks.indexOf(task.id.toString()) === -1 && task.name.toLowerCase().indexOf(this.searchDropdown.toLowerCase()) != -1;
                })
            },
            filterMoveGroupOptions() {
                return this.groups.filter((group) => {
                    return  group.name.toLowerCase().indexOf(this.searchDropdown.toLowerCase())  != -1;
                })
            },
            maxGroupZIndex() {
                return typeof this.groups != 'undefined'? this.groups.length * 2 + 1 : 1
            },
            queryParamTaskId() {
                let taskId = this.$route.params.workflow_task_id ? this.$route.params.workflow_task_id : null;
                return !taskId && this.$route.query.task ? this.$route.query.task : taskId;
            }
        },
        methods:{
            ...tasksMethods,
            ...personsMethods,
            ...userMethods,
            ...pluginsMethods,
            ...usersOptionsMethods,
            ...workflowsMethods,
            ...groupsMethods,
            ...columnsMethods,
            ...columnValuesMethods,
            ...filtersMethods,
            ...searchMethods,

            handleDragStart(event) {
                // let element = event.target.closest('.task-item');
                // element.classList.add('task-item-ghost');
                // this.taskSortedElement = element
            },
            handleDrag(event, index = 0, dragenter = true){
                if(this.dragIsSubtask) return;

                let element = event.target.closest('.task-item');

                if(element) {
                    if(dragenter) {
                        if(index == 0) {
                            element.classList.add('task-drag-sort-index-0')
                        } else {
                            element.classList.add('task-drag-sort')
                        }
                        // element.parentNode.insertBefore(this.taskSortedElement, element)
                        this.taskSortToElement = element;
                    } else {
                        if(element.dataset.id != this.taskSortToElement.dataset.id && element.dataset.index != this.taskSortToElement.dataset.index) {
                            element.classList.remove('task-drag-sort');
                            element.classList.remove('task-drag-sort-index-0');
                        } else if (element.dataset.groupId == this.taskSortToElement.dataset.groupId && element.dataset.id != this.taskSortToElement.dataset.id) {
                            element.classList.remove('task-drag-sort');
                            element.classList.remove('task-drag-sort-index-0');

                        }
                    }

                }

            },
            handleDrop(event, b,c, dragenter = true) {
                let taskSortToElement = this.taskSortToElement;
                if(!taskSortToElement) return;

                let taskSortFromElement = event.target.closest('.task-item');
                if(!taskSortFromElement) return;

                let fromTask = {
                    taskId: taskSortFromElement.dataset.id,
                    groupId: taskSortFromElement.dataset.groupId,
                    index: taskSortFromElement.dataset.index,
                };

                let toTask = {
                    taskId: taskSortToElement.dataset.id,
                    groupId: taskSortToElement.dataset.groupId,
                    index: taskSortToElement.dataset.index,
                };

                this.sortTaskSequence({
                    from: fromTask,
                    to: toTask
                });

                let data = {
                    orderAsc: true,
                    columnId: 0,
                    typeId: 0
                }

                let workflow = JSON.parse(JSON.stringify(this.workflow));
                workflow.order_by_column_id = null;
                workflow.order_desc = false;
                workflow.workflow_id = workflow.id;
                this.updateWorkflow(workflow)
                this.setOrdinationTasks(data);

            },
            handleDragSubtasks(event, index = 0, dragenter = true){
                let element = event.target.closest('.subtask-item');
                if(element) {
                    if(!dragenter) {
                        this.subtaskSortToElement = element;
                    }
                }
            },
            handleDropSubtasks(event, parentId) {
                this.dragIsSubtask = false;

                let subtaskSortToElement = this.subtaskSortToElement;
                if(!subtaskSortToElement) return;

                let taskSortFromElement = event.target.closest('.subtask-item');
                if(!taskSortFromElement) return;

                let fromTask = {
                    taskId: taskSortFromElement.dataset.id,
                    groupId: taskSortFromElement.dataset.groupId,
                    index: taskSortFromElement.dataset.index,
                };

                let toTask = {
                    taskId: subtaskSortToElement.dataset.id,
                    groupId: subtaskSortToElement.dataset.groupId,
                    index: subtaskSortToElement.dataset.index,
                };

                this.sortTaskSequence({
                    from: fromTask,
                    to: toTask,
                    parentId: parentId
                });
            },
            // Menu
            showHeaderMenu(showHeader = false, mouseOverHeader = false) {
                this.mouseOverHeader = mouseOverHeader;
                if (showHeader) {
                    setTimeout(() => {
                        if (this.mouseOverHeader) {
                            this.showHeader = showHeader;
                        }
                    }, 500);
                } else {
                    this.showHeader = showHeader;
                }
            },
            getForms(workflowId) {
                if(this.workflowId != 'undefined') {
                    const getResponsibleOptions = axios
                    .get(this.localeOrigin + "/api/v1/workflow/form/options/" + workflowId)
                    .then((response) => {
                        this.workflowForms = response.data.data;
                    })
                    .catch((error) => {
                        if (error.response) {
                        this.isRegisterError = true;
                        this.axRegError = error.response.data.message;
                        }
                    });
                }
            },
            openForm(workflowForm = null) {
                this.workflowFormId = workflowForm ? workflowForm.uuid : workflowForm;
                this.formModalTitle = workflowForm ? "Editar Formulário" : "Novo formulário";

                this.$root.$emit('bv::show::modal', 'modal-form-build')
            },
            clearWorkflowForm(workflowId) {
                this.workflowFormId = null;
                this.getForms(workflowId);
            },
            exportReport(workflowId) {
                this.exportLoad = true;
                let tasksIds = Object.keys(this.tasksSelecteds);
                if(tasksIds.length == 0) {
                    showAlert(true, 'Selecione tarefas para exportar')
                    this.exportLoad = false;
                    return;
                }
                axios.post( this.localeOrigin + "/api/v1/export/workflow", { workflow_id: workflowId, filter: this.filter,  selecteds: tasksIds })
                .then((response) => {

                    var fileLink = document.querySelector('a.download');
                    fileLink.href = response.data.data.url;
                    fileLink.setAttribute('download', response.data.data.file_name);
                    fileLink.setAttribute('target', "_blank");
                    fileLink.click();
                    setTimeout(()=>{this.exportLoad = false;},500);
                })
                .catch((error) => {
                    setTimeout(()=>{this.exportLoad = false;},500);
                    if (error.response) {
                    this.isRegisterError = true;
                    this.axRegError = error.response.data.message;
                    }
                });
            },
            async setGroup(e) {
                e.preventDefault();
                var data = {
                    workflow_id: this.$route.params.id,
                    group_name: e.target.group_name.value,
                };

                // let response = await this.storeGroup(data);
                let response = await this.storeGroup(data);
                if(response) {
                    this.appendTaskGroup(response);
                    this.menuShow.inptCreateGroup = false;
                }

            },

            setTitle(data) {
                this.setNewTitlePage(data);
            },
            // Workflow edit Header button
            openWorkflowEdit() {
                this.modalTitle = "Editar workflow"
                this.compare = {
                    filter_period: this.workflow.filter_period,

                }
                this.$root.$emit('bv::show::modal', 'workflow-edit')
            },
            changeWorkflow(workflow) {
                this.setWorkflow(workflow)
                this.setTitle(workflow.name);
                if(this.compare.filter_period != workflow.filter_period) {
                    this.setFilter({workflowId: workflow.id,limit: this.tasksLimit, period: workflow.filter_period})
                }
            },
            checkDisableItem(readonly, workflow_column_id, invertResult = false){
                let result = ((readonly && this.user.user_type_id != 1) && (readonly && this.userItemDisable[workflow_column_id]));
                if(invertResult) {
                    return !result;
                }
                return result;
            },
            disableDateFromCompanyConfig(date){
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                let range_time = 0;
                if(this.company.default_limit_date) {
                    range_time = this.company.default_limit_date * 24 * 3600 * 1000
                    return date > new Date(today.getTime() + range_time);
                }

                return false;
            },

            infiniteHandlerMoreTasks: function infiniteHandlerMoreTasks(isVisible, entry, groupId, index, page = 1){

                if(isVisible) {
                    var data = JSON.parse(JSON.stringify(this.filter));
                    data.workflow_id = this.$route.params.id;
                    data.group_id = groupId;
                    data.limit = this.tasksLimit;
                    data.index = index;
                    data.page = Math.ceil(page);

                    clearTimeout(infiniteHandlerMoreTasks.timeout);
                    infiniteHandlerMoreTasks.timeout = setTimeout(async ()=>{
                        let response = await this.loadMoreTasks(data);

                    }, 100);
                }

            },

            // Chat Drawer
            rightDrawer(task = null, cell = null) {
                this.openRightDrawer = false;
                if (!task) {
                    this.openRightDrawer = false;
                    this.textDisable = true;
                    this.findTask(this.drawerData.rowId)
                } else {
                    setTimeout(() => { this.openRightDrawer = false }, 100);
                    setTimeout(() => { this.openRightDrawer = true }, 100);
                    this.drawerData.creator = task.creator;
                    this.drawerData.created_at = task.created_at;
                    this.drawerData.userId = task.user_id;
                    this.drawerData.description = task.description;
                    this.drawerData.groupId = task.workflow_group_id;
                    this.drawerData.rowUserId = task.user_id;
                    this.drawerData.finalized = task.finalized;
                    this.drawerData.rowId = task.id;
                    this.drawerData.title = task.name;
                }

            },

            // Menu Drawer
            checkSelectedSubtasks() {

                let keys = Object.keys(this.tasksSelecteds);
                var have_subtasks = 0;
                var subtasks = 0;
                var tasks = 0;
                var groups = {};
                this.elementSelectedsIds = [];


                var show = {task: true, group: true, have_subtasks: false};
                keys.forEach((key)=>{
                let element = this.tasksSelecteds[key];
                    this.elementSelectedsIds.push(element.id);

                    if(element.subtask){
                        subtasks++;
                    } else {
                        tasks++;
                    }

                    if(element.have_subtask) {
                        have_subtasks++;
                    }

                    groups[`${element.group_id}`] = element.group_id;

                })

                if(subtasks == 0 && tasks > 0){
                    show.task = true;
                    this.convertToSubtask = true;
                } else if(tasks == 0 && subtasks > 0){
                    show.task = true;
                    this.convertToSubtask = false;

                } else {
                    show.task = false;
                }

                if(Object.keys(groups).length > 1) {
                    show.group = false;
                } else {
                    show.group = true;
                    this.convertToSubtaskGroup = Object.keys(groups)[0];
                }

                if(have_subtasks > 0){
                    show.have_subtasks = true;
                } else {
                    show.have_subtasks = false;

                }

                this.showConvertToSubtask = show.task && show.group && !show.have_subtasks;

            },

            showItemOptions(colId, type = null, title = '') {
                this.selectedColId = colId;
                this.selectedColType = type;
                this.modalTitle = title;
                setTimeout(()=>{
                    this.$root.$emit('bv::show::modal', 'modal-set-item')

                },100);
            },

            async updateModalColumns(workflowId, colId = false) {
                this.columnUpdateLoad = true
                let changeType = false;
                var initialFilters = JSON.parse(JSON.stringify(this.filter));
                initialFilters.workflowId = workflowId;
                initialFilters.limit = this.tasksLimit;
                initialFilters.tasksIds = [];

                let groups = Object.keys( this.tasks);
                groups.forEach((groupId)=> {
                    initialFilters.tasksIds = [...this.tasks[groupId].map((task) => task.id), ...initialFilters.tasksIds];
                });

                await this.listColumns(workflowId);
                let column = this.columns.find((column)=> column.id == colId);
                if(column) {
                    if(column.workflow_column_type_id != this.selectedColType) {
                        changeType = true;
                    }
                }

                if(!colId || changeType) this.setFilter(initialFilters);

                if(colId) {
                    await this.findColumn(colId)
                }

                this.$root.$emit('bv::hide::modal', 'modal-set-item')
                setTimeout(()=>{ this.columnUpdateLoad = false}, 100)
            },

            showEditTaskFile(taskId, groupId, isChat = false, columnId = 0) {
                this.isChat = isChat;
                this.taskSelected = taskId;
                this.taskGroupSelected = groupId;
                this.modalTitle = "Arquivos";
                let item = this.columns.find((item)=> item.id == columnId);

                this.taskSelectedItemName = '';
                this.taskSelectedItemRequired = false;
                if(typeof item !== 'undefined') {
                    this.taskSelectedItemName = item.name;
                    this.taskSelectedItemRequired = item.required? true : false;
                }

                this.$root.$emit('bv::show::modal', 'modal-edit-task-files')
            },

            editDescrition(e){
                if(this.drawerData.rowUserId == this.user.id && !this.drawerData.finalized) {
                    this.textDisable = false;

                    setTimeout(()=>{
                        this.$refs['description'].$el.focus()
                    }, 200);
                }
            },
            descriptionUpdateTaskClick(e) {
                let button =  this.$refs['description-update-task'].querySelector('i.description-save');
                if(button) {
                    setTimeout(()=>{
                        button.click()
                    }, 200);
                }
            },

            toggleDescriptionButton(textDisable, taskId, name, description, deadline_advance = null) {
                this.textDisable = true;
                var data = { task_id: taskId };
                if (name) data.name = name
                if (description) data.description = description
                if (deadline_advance !== null && !isNaN(deadline_advance)) data.deadline_advance = deadline_advance
                this.updateTask(data);
                return
            },

            // DND methods

            // Groups ----------
            groupStartDrag(hidde) {
                setTimeout(() => {
                    // this.hideAllTasks = hidde
                    // this.toggleShowTasks(!hidde);
                    this.toggleHideAllTasks(hidde);
                }, 50);
            },

            // Tasks ----------
            taskDragStart(e){
                this.drag = true

                this.$set(this.ordination, 'columnId', 0);
                this.$set(this.ordination, 'typeId', 0);
                this.$set(this.ordination, 'orderAsc', true);
            },
            taskDragEnd(e){
                this.drag = false
            },
            updateTaskSequence(event, updateElement = true, cleanOrdination = false) {
                this.drag = false
                var data = [];

                var groupKeys = Object.keys(this.sortTask);
                groupKeys.forEach((groupKey)=>{
                    this.sortTask[groupKey].forEach((task, key) => {
                        data.push({ task_id: task.id, sequence: key, group_id: task.workflow_group_id })
                    });
                });

                axios.patch(this.localeOrigin + "/api/v1/workflow/task/sequence", data)
                    .then((response) => {
                    if (!response.data.error) {
                        //showAlert(false, response.data.message)
                        // this.getWorkflow(this.$route.params.id);
                        if(updateElement){
                            var element = event.target.closest('.header-option-field');
                            var save = element.querySelector('.save');
                            save.classList.add('bg-success');
                        }
                        data.forEach((taskData)=>{
                            var task = this.sortTask[taskData.group_id].find((task)=>{return task.id == taskData.task_id });
                            if(task){
                                this.$set(task, 'sequence', taskData.sequence);
                            }
                        });

                        if(cleanOrdination) {
                            this.cleanOrdination(event);
                        }
                    }
                    })
                    .catch((error) => {
                    console.log(error);
                    if (error.response) {
                        showAlert(true, error.response.data.message);
                    }
                    });

                },

                checkUpdateTaskGroup(task_id, group_id, oldGroup, event) {
                    var data = [];
                    if(!oldGroup) {
                        return;
                    }

                    let task = this.tasks[group_id].find((task) => task.id == task_id);

                    if(task) {
                        data = [{
                        'id': task_id,
                        'value': true,
                        'subtask': !!task.heritage,
                        'group_id': oldGroup,
                        'have_subtask': task.subtasks.length > 0
                        }]
                        var sequence = task.sequence ? task.sequence : 0;
                        this.elementSelecteds = [];
                        this.elementSelecteds = data;
                        this.moveSelectedRows(this.elementSelecteds, group_id, true, sequence, event)
                    }

                },

                updateTaskGroup(items, taskId, group_id) {

                // var task_el = e.item;
                // var task_id = parseInt(task_el.querySelector(".task-row").id);
                var task_id = taskId;
                var data = [];
                var group = 0;
                var currentGroup = 0;
                var oldGroup = 0;

                if(this.workflow.workflow_group_id) {
                    items.forEach((task, key) => {
                        if (task_id) {
                        if (task_id == task.id) {
                            oldGroup = task.workflow_group_id;
                        }
                        }
                    });
                    this.checkUpdateTaskGroup(task_id, group_id, oldGroup, e)
                    return false;
                }

                items.forEach((task, key) => {
                    if (task_id) {
                    if (task_id == task.id) {
                        oldGroup = task.workflow_group_id;
                    }

                    currentGroup = group_id;
                    group = group_id;
                    this.$set(task, 'workflow_group_id', group_id);
                    data.push({ task_id: task.id, sequence: key, group_id: group })
                    }
                });

                if (task_id) {

                    const updateGroup = axios
                    .patch(this.localeOrigin + "/api/v1/workflow/task/sequence/", data)
                    .then((response) => {
                        if (!response.data.error) {
                        // if group id is true update group total row else update all groups
                        // if (currentGroup) {
                        //     setTimeout(() => { this.getGroupTotal(currentGroup); }, 60);
                        // } else {
                        //     this.groups.forEach(group => {
                        //     setTimeout(() => { this.getGroupTotal(group.id); }, 60);
                        //     });
                        // }

                        // if (oldGroup) {
                        //     setTimeout(() => { this.getGroupTotal(oldGroup); }, 100);
                        // } else {
                        //     this.groups.forEach(group => {
                        //     setTimeout(() => { this.getGroupTotal(group.id); }, 100);
                        //     });
                        // }
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        if (error.response) {
                        showAlert(true, error.response.data.message);
                        }
                    });
                }

                },

                moveSelectedRows(tasks, groupId, dnd = false, sequence = null, event = null){
                    var fromGroups = {};
                    var groupWithTasks = {};
                    var data = {
                        group_id: groupId,
                        tasks_ids: []
                    };

                    this.elementSelecteds.forEach(function (el, key) {
                        groupWithTasks[`${el.group_id}`] = [];
                    });

                this.elementSelecteds.forEach(function (el, key) {
                    if (el.value) {
                    data.tasks_ids.push(el.id);
                    fromGroups[el.id] = el.group_id;
                    groupWithTasks[`${el.group_id}`].push(el.id);
                    }
                });

                var group = this.groups.find((group)=>{ return group.id == groupId });

                //rollback
                const rollback = (vue, gropsSelecteds, groupWithTasks, groupId, sequence = false)=>{
                    gropsSelecteds.forEach(selectedGroupId =>{
                        groupWithTasks[`${selectedGroupId}`].forEach((selectedTask)=>{
                            var localSelectedTask = {};
                            var localSelectedTaskIndex = -1;
                            vue.tasks[`${groupId}`].forEach((task, key)=>{
                                if(task.id == selectedTask){
                                    localSelectedTask = task;
                                    localSelectedTaskIndex = key;
                                }
                            });
                            if(localSelectedTask){
                                vue.$set(localSelectedTask, 'workflow_group_id', fromGroups[localSelectedTask.id]);
                                if(localSelectedTask.subtasks.length > 0){
                                    localSelectedTask.subtasks.forEach((subtask)=>{
                                        vue.$set(subtask, 'workflow_group_id', fromGroups[localSelectedTask.id]);
                                    });
                                }
                            }

                            if(localSelectedTaskIndex > -1){
                                if(sequence !== null) {
                                    vue.tasks[`${selectedGroupId}`].splice(sequence, 0, localSelectedTask)
                                    vue.tasks[`${groupId}`].splice(localSelectedTaskIndex, 1)
                                } else {
                                    vue.tasks[`${selectedGroupId}`].push(localSelectedTask)
                                    vue.tasks[`${groupId}`].splice(localSelectedTaskIndex, 1)
                                }
                            }
                        });
                    });
                };

                this.$swal
                    .fire({
                    title: "Confirma?",
                    text: "Mover tarefas"+(group? ' selecionadas para o grupo "'+group.name + '"?' :'?'),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Sim, mover!",
                    cancelButtonText: "Cancelar",
                    })
                    .then((result) => {
                    if (result.value) {
                        // open load modal
                        this.$swal
                        .fire({
                            position: "center",
                            html: "<i class='bx bx-loader-alt bx-spin' style='font-size: 5rem;'></i><p>Movendo</p>",
                            showCancelButton: false,
                            showConfirmButton: false,
                            width: 300,
                            padding: 50,
                        });
                        } else {
                            if(dnd) {
                                var gropsSelecteds = Object.keys(groupWithTasks);
                                rollback(this, gropsSelecteds, groupWithTasks, groupId, sequence);
                                this.elementSelecteds = [];
                            }
                            return;
                        }

                        if (this.elementSelecteds.length > 0) {
                        var gropsSelecteds = Object.keys(groupWithTasks);
                        // save the modification
                        const moveSelectedRows = axios
                            .post(this.localeOrigin + "/api/v1/workflow/task/move", data)
                            .then((response) => {
                            if (!response.data.error) {
                                showAlert(false, '');
                                this.tasksSelecteds = [];
                                this.openElementsDrawer = false
                                this.elementSelecteds = [];
                                setTimeout(()=>{
                                    this.getGroupTotal(groupId);
                                    gropsSelecteds.forEach((selectedGroupId)=>{
                                        this.getGroupTotal(selectedGroupId);
                                    });
                                }, 100)

                            }

                            // close load modal
                            this.$swal.close();
                            })
                            .catch((error) => {
                            if (error.response) {
                                console.log(error.response);
                                showAlert(true, error.response.data.message);
                            }

                            rollback(this, gropsSelecteds, groupWithTasks, groupId);
                            // close load modal
                            this.$swal.close();

                            });

                            // reorganize if event dispatch from drag and drop
                            if(dnd) {
                                let vue = this;
                                this.elementSelecteds.forEach(function (el, key) {
                                    let task = vue.tasks[`${groupId}`].find((task)=> task.id == el.id);
                                    if(task){
                                    vue.$set(task, 'workflow_group_id', groupId);
                                    if(task.subtasks.length > 0){
                                        task.subtasks.forEach((subtask)=>{
                                            vue.$set(subtask, 'workflow_group_id', groupId);
                                        });
                                    }
                                }
                                });

                                if(event) {
                                    this.updateTaskSequence(event, false, false);
                                }
                                return;
                            }

                            // move the task to new group
                            gropsSelecteds.forEach(selectedGroupId =>{
                            groupWithTasks[`${selectedGroupId}`].forEach((selectedTask)=>{
                            var localSelectedTask = {};
                            var localSelectedTaskIndex = -1;
                            this.tasks[`${selectedGroupId}`].forEach((task, key)=>{
                                if(task.id == selectedTask){
                                    localSelectedTask = task;
                                    localSelectedTaskIndex = key;
                                }
                            });
                            if(localSelectedTask){
                                this.$set(localSelectedTask, 'workflow_group_id', groupId);
                                if(localSelectedTask.subtasks.length > 0){
                                    localSelectedTask.subtasks.forEach((subtask)=>{
                                        this.$set(subtask, 'workflow_group_id', groupId);
                                    });
                                }
                            }
                            if(localSelectedTaskIndex > -1){
                                this.tasks[`${groupId}`].push(localSelectedTask)
                                this.tasks[`${selectedGroupId}`].splice(localSelectedTaskIndex, 1)
                            }
                            });
                        })
                        }
                    });
                },
                setExtensions() {
                    this.extensionsAccept = '';
                    if (config.extensions.length > 0) {
                        config.extensions.forEach((extension) => {
                        this.extensionsAccept += '.' + extension + ','
                        });
                    }
                },
                async reloadWorkflow(workflowId) {
                    this.listWorkflows(workflowId),
                    this.listColumns(workflowId),
                    this.listGroups(workflowId),
                    this.getUser(),
                    this.setExtensions();
                    this.getResponsibleList(workflowId);
                    this.getCollaboratorList(workflowId);
                    this.getForms(workflowId);
                },
                async getWorkflowTasks(workflowId) {
                    var initialFilters = JSON.parse(JSON.stringify(this.filter));
                    initialFilters.dateStart = '';
                    initialFilters.dateEnd = '';
                    initialFilters.status = null;
                    initialFilters.workflowId = workflowId;
                    initialFilters.limit = this.tasksLimit;
                    initialFilters.page = 0;

                    let firstGroup = this.groups.find((group, key)=> key == 0);

                    if(firstGroup) {
                        initialFilters.group_id = firstGroup.id;
                    }

                    if(this.workflow.id) {
                        initialFilters.period = this.workflow.filter_period;
                        if(this.queryParamTaskId) {
                            initialFilters.tasksIds = [this.queryParamTaskId]
                        } else {
                            initialFilters.tasksIds = []
                        }

                        if(this.workflow.order_by_column) {
                            initialFilters.orderBy = this.workflow.order_by_column.id;
                            initialFilters.orderDesc = this.workflow.order_desc;
                            let data = {
                                    load: false,
                                    ordinate: true,
                                    orderAsc: !this.workflow.order_desc,
                                    columnId: this.workflow.order_by_column.id,
                                    typeId: this.workflow.order_by_column.workflow_column_type_id
                                }

                            this.setOrdinationTasks(data)
                        }

                        this.setFilter(initialFilters);
                    }


                },
                openDrawerByTaskId(taskId) {
                    let groups = Object.keys(this.tasks);
                    groups.forEach((groupId)=>{
                        let baseTask = this.tasks[groupId].find((task)=> task.id == taskId);
                        let parentTask = null;

                        if(!baseTask) {
                            parentTask = this.tasks[groupId].find((localTask) => localTask.subtasks.find((subtask)=> taskId == subtask.id));
                        }

                        if(parentTask) {
                            baseTask = parentTask.subtasks.find((subtask)=> taskId == subtask.id);
                        }

                        if(baseTask) {
                            this.toggleShowTasksByGroup({groupId, show: true})
                            if(parentTask) {
                                this.setShowSubtasks({
                                    data: true,
                                    task: parentTask
                                });
                            }
                            setTimeout(()=> {this.rightDrawer(baseTask)}, 500);
                        }

                    });
                },
                openChecklistByTaskId(taskId) {

                    let groups = Object.keys(this.tasks);
                    groups.forEach((groupId)=> {
                        let baseTask = this.tasks[groupId].find((task)=> task.id == taskId);
                        let parentTask = null;

                        if(!baseTask) {
                            parentTask = this.tasks[groupId].find((localTask) => localTask.subtasks.find((subtask)=> taskId == subtask.id));
                        }

                        if(parentTask) {
                            baseTask = parentTask.subtasks.find((subtask)=> taskId == subtask.id);
                        }

                        if(baseTask) {
                            this.setTaskChecklistsOptions({task: baseTask, columnValueId: null, fastMode: true, clear: false});
                            this.$root.$emit('bv::show::modal', 'modal-checklist')
                        }
                    });
                }
        },
        beforeCreate() {


        },
        created(){
            this.calledPageLoaded = false
            this.$watch(()=> this.groupsProcessing.loadGroup, (att, old)=> {
                if(!att && old && !this.calledPageLoaded) {
                    setTimeout(()=>{
                        this.resetCalledPageByGroups(this.groups)
                        this.calledPageLoaded = true
                    }, 700);
                }
            });

            this.reloadWorkflow(this.$route.params.id)
            // this.getWorkflowTasks(this.$route.params.id)
            this.$watch(()=> this.tasksSelecteds, (att, old)=> {
                if(Object.keys(att).length > 0){
                    // this.openElementsDrawer = true;
                    this.checkSelectedSubtasks();
                } else {
                    // this.openElementsDrawer = false;
                    this.selected = {};
                }
            });

            this.$watch(()=> this.$route.params.id, (att, old)=>{
                this.clearSelectedTasks();
                this.clearTasks();
                this.clearGroups();
                this.clearWorkflows();
                this.clearWorkflow();
                this.changeSearchNavbar({searchNavbar: ''});
                this.clearFilter();
                this.clearAdvancedFilters();
                this.rightDrawer(null, null);

                this.reloadWorkflow(att)
            });
            this.$watch(()=> this.workflow.id, (att, old)=>{
                if(att == this.$route.params.id) {
                    this.getWorkflowTasks(att)
                }
            });

            this.$watch(()=> this.tasksProcessing.loadTask, (att, old)=> {
                if(!att && old && this.queryParamTaskId && this.$route.query.checklist) {
                    setTimeout(()=> {
                        this.openChecklistByTaskId(this.queryParamTaskId);
                        this.queryNavigation.checklist = this.$route.query.checklist;
                    }, 700);
                    return;
                }

                if(!att && old && this.queryParamTaskId && this.queryParamTaskId != this.queryNavigation.task) {
                    setTimeout(()=> {
                        this.openDrawerByTaskId(this.queryParamTaskId)
                        this.queryNavigation.task = this.queryParamTaskId;
                    }, 700);
                }
            });

            this.$watch(()=> this.queryParamTaskId, (queryParamTaskId, old) => {
                if(!this.$route.query.checklist && queryParamTaskId) {
                    setTimeout(()=> {
                        this.getWorkflowTasks(this.$route.params.id);
                    }, 700);
                }

                if(this.$route.query.checklist && queryParamTaskId) {
                    this.openChecklistByTaskId(this.queryParamTaskId);
                }
            });

            this.$watch(()=> this.showHeader, (att, old)=>{
                setTimeout(()=>{
                    let container = window.document.querySelector('.page-content-workflow')
                    if(container) {
                        if(att) {
                            container.classList.add('container-group-top-distance-menu')
                            container.classList.remove('container-group-top-distance')
                        } else {
                            container.classList.add('container-group-top-distance')
                            container.classList.remove('container-group-top-distance-menu')
                        }
                    }
                }, 100);
            });

        },
        beforeMount(){
        },
        mounted() {
            // Websocket control
            this.$watch(()=> this.workflow.id, (att, old)=> {
                if(typeof old !== 'undefined') {
                    window.Echo.leave(`workflow.${this.currentSubdomain}.${old}`);
                }
                if(typeof att !== 'undefined'){
                    try{
                        window.Echo.private(`workflow.${this.currentSubdomain}.${att}`)
                        .listen('.WorkflowTaskNotification', (data) => {
                            let check = this.checkUserTaskPermissions(JSON.parse(data.task))
                            if (this.user.id != data.user_id && data.task && check) {
                                let task = JSON.parse(data.task);
                                if(task) {
                                    let preppend = task.user_creator.user_type_id < 3 && task.workflow_form_id;
                                    this.websocketUpdateTask({task, preppend});
                                }
                            }

                            if(!check) {
                               let task = JSON.parse(data.task);
                               this.removeTask(task ? task.id : 0)

                            }
                        });
                    } catch(error) {
                        console.error(error)
                    }
                }
            });

            this.$watch(()=> this.showTasksSelectedLimitModal, (att, old)=>{
                if(att) {
                    this.$root.$emit('bv::show::modal', 'modal-select-limit')
                }
            });

            this.$watch(()=> this.company, (att, old)=> {
                if(Object.keys(att).length > 0){
                    // mixin data set
                    this.datePikerLang.formatLocale.firstDayOfWeek = att.first_day //first_day
                }
            });

            this.$watch(()=> this.showFilterDropdown, (att, old)=> {
                if(!att) {
                    let element = this.$refs['dropdown-filter-navbar'];
                    if(element) {
                        element.hide(true);
                    }
                }
            });

        },
        beforeDestroy() {
            this.clearSelectedTasks();
            this.clearTasks();
            this.clearGroups();
            this.clearWorkflows();
            this.clearWorkflow();
        }

    }
</script>
<style>

/* if group don't have tasks */
.no-task-drag-handle {
    min-height: 8px;
    margin-bottom: -9px;
    position: relative;
}

/* if index equals 0 */
.task-drag-sort-index-0 {
    border-top: 6px solid #50a5f1 !important;
}

.task-drag-sort {
    border-bottom: 6px solid #50a5f1 !important;
}
.task-drag-sort * .column-value-item {
    border-bottom: 6px solid #50a5f1;
}

.task-drag-sort * .column-value-item * .dropdown {
    height: 26px !important;
    margin-bottom: 6px;
}

.task-item-ghost {
    opacity: 0.5;
}
/* Estilos para os itens ordenáveis */
.sortable-item {
  padding: 10px;
  margin: 5px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: grab;
}

.sortable-ghost {
  opacity: 0.5;
  background-color: #ddd;
}
.vue-recycle-scroller__item-wrapper {
    transition: transform 0.2s ease;
}

</style>
<style lang="scss" scoped>
.show-base{
    // overflow-y: auto;
}
// .show-base-overflow-x{
//     overflow: auto;
// }

.task-item.task-dragging {
    opacity: 0.5;
}
.task-item {
    cursor: pointer;
}

.header-sticky {
    // position: sticky;
    position: fixed;
    top: 50px;
    z-index: 1002;
    background-color: #222736;
    margin-left: 0px;
    width: 100%;
    left: 50px;
}
.filter-sticky {
    position: fixed;
    top: 85px;
    z-index: 1002;
    background-color: #222736;
    margin-left: 0px;
    width: 100%;
    left: 50px;
}
.z-index-header {
    z-index: 1001 !important;
}
.top-106 {
    top: 106px !important;
}
.menu-header-top {
    top: 86px !important;
}

.margin-n1 {
    margin-left: -1px !important;
}
.menu-wrapper {
    margin-bottom: 17px;
}

.show-activity-log {
    width: 0.7rem;
    height: 0.7rem;
}
.text-log-button {
    font-weight: 600;
    color: #556ee6;
}
</style>
