<template>
    <div class="base d-flex justify-content-center align-items-center cell-height"  :class="{'disabled-opacity': disabled}">
        <div class="col-10" v-if="task.files.length > 0">
          <div class="row align-items-center">
            <div class="col-9 d-flex  justify-content-start">
              <div v-for="(file, fileKey) in localFiles" :key="fileKey" :class="{ 'd-none': fileKey > 2 }"
                class="col-3 d-flex align-items-center justify-content-center link-cursor">
                <div class="link-cursor" @click="downloadFile(file.uuid)"
                  v-if="fileKey < 3">
                  <i v-if="file.extension == 'pdf'"
                    class="mdi mdi-file-pdf font-size-20"></i>
                  <i v-else-if="file.extension == 'docx'"
                    class="mdi mdi-microsoft-word font-size-20"></i>
                  <i v-else-if="file.extension == 'doc'"
                    class="mdi mdi-microsoft-word font-size-20"></i>
                  <i v-else-if="file.extension == 'xls'"
                    class="mdi mdi-file-table-outline font-size-20"></i>
                  <i v-else-if="file.extension == 'xlsx'"
                    class="mdi mdi-file-table-outline font-size-20"></i>
                  <i v-else-if="file.extension == 'txt'"
                    class="bx bxs-file-txt font-size-20"></i>
                  <i v-else-if="file.extension == 'jpg' || file.extension == 'jpeg' || file.extension == 'png' || file.extension == 'webp' || file.extension == 'gif'"
                    class="fas fa-file-image font-size-20"></i>
                  <i v-else-if="file.extension == 'mp4'"
                    class="fas fa-file-video font-size-20"></i>
                  <i v-else class="bx bxs-file-txt font-size-20"></i>
                </div>
              </div>
              <div :class="{ 'd-none': task.files.length < 3 }"
                class="col-3 d-flex align-items-center justify-content-center link-cursor"
                @click="showEditTaskFile(task, column)">
                <span>...</span>
              </div>
            </div>
            <div class="col-2 d-flex  justify-content-center">
              <div
                class="col-3 d-flex align-items-center justify-content-center link-cursor">
                <div class="link-cursor"
                  @click="showEditTaskFile(task, column)">
                  <i class="fas fa-search"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 task-file-upload d-flex align-items-center cell-height">
          <b-button
            :disabled="disabled"
            variant="btn-edit text-decoration-none fs-6 text-secondary pt-0 p-0"
            @click.prevent="addFileTask($event, columnValue.workflow_task_id)">
            <span> <i class="fas fa-plus-circle text-white"></i>
            </span>
          </b-button>

          <input type="file" :ref="`updateFile_${columnValue.workflow_task_id}`"
            name="updateFile[]"
            class="d-none form-control form-control-sm updateFile"
            :accept="extensionsAccept"
            @change.prevent="uploadFileTask($event, task)" :disabled="disabled">
        </div>

    </div>
</template>

<script>
    import config from '../../../config/config.js'
    import showAlert from '../../alerts.js';
    import { mapActions } from 'vuex';
    import axios from 'axios'

    export default {
        data() {
            return {
                value: false,
                taskFiles: {},
            }
        },
        props: {
            column: {
                default: {},
            },
            task: {
                default: {},
            },
            group: {
                default: {},
            },
            columnValue: {
                default: {},
            },
            disabled: {
                default: false,
            },
            extensionsAccept: {
                default: '',
            },
        },
        computed: {
            localFiles() {
                return this.task.files.filter((file, key)=> key < 3);
            }
        },
        methods: {
            ...mapActions('tasks', ['uploadFileToTask', 'setTaskFilesOptions']),
            addFileTask(evt, id) {
                let button = evt.target;
                if(!button) return;

                let base = button.closest('.task-file-upload');
                if(!base) return;

                let inputFile = base.querySelector('input[type="file"]');
                if(!inputFile) return;

                inputFile.click();
            },
            uploadFileTask(e, task) {
                this.taskFiles[task.id] = e.target.files;
                if (this.taskFiles[task.id].length > 0) {
                    var validate = this.checkFileSize(this.taskFiles[task.id][0]);

                    if (validate.error) {
                    showAlert(true, validate.message);
                    return;
                    }
                }
                var typeExplode = ((this.taskFiles[task.id][0]).name).split('.');

                var extension = typeExplode[typeExplode.length - 1];

                if (this.extensionsAccept.indexOf(extension) < 0) {
                    showAlert(true, 'Tipo não suportado');
                    this.taskFiles[task.id] = null;
                    return;
                }


                this.uploadFileToTask({
                    task: task,
                    files: this.taskFiles[task.id]
                })

                e.target.value = null;
            },
            showEditTaskFile(task, column, isChat = false, clear = false) {
                this.setTaskFilesOptions({task, column, isChat, clear})
                this.$root.$emit('bv::show::modal', 'modal-edit-task-files')
            },
            downloadFile(uuid) {

            const download = axios
                .post(`${window.location.origin}/api/v1/files`, { id: uuid })
                .then((response) => {
                    var downloadUrl = `${document.location.origin}/${response.data.data.path}`;
                    var fileLink = document.querySelector('a.download');
                    fileLink.href = downloadUrl;
                    fileLink.setAttribute('download', response.data.data.file_name);
                    fileLink.setAttribute('target', "_blank");
                    fileLink.click();
                })
                .catch((error) => {
                    console.log('error :>> ', error);
                });
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
