import Vue from 'vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import config from '../config/config'
import store from "../state/store";

Vue.use(VueRouter);

// middlewares
  const saveUri = function  ({to, from, next, router}) {
    const authRequiredOnPreviousRoute = from.matched.some( async  function(from, index) {

        var data = {uri: to.path}

        const saveuri =  axios.post(window.location.origin+'/api/v1/uri', data, config.getHeaders)
        .then(function(response){

            return next();
        })
        .catch(function (error) {
            console.log(error);

        });

    });

    return next();
  }

  const checkPermission = async function ({to, from, next, router}) {
    let userType = await store.getters["user/typeId"];
    if(userType == undefined) {
        const user = await axios.post(window.location.origin+'/api/v1/user/');
        if(user.status > 199 && user.status < 300) {
            userType = user.data.user_type_id;
        }
    }
    if(userType == 1) {
        return next();
    } else {
        return router.push({name: 'home'});
    }
  }

  const checkUserPermission = async function ({to, from, next, router}) {
    let userType = await store.getters["user/typeId"];
    if(userType == undefined) {
        const user = await axios.post(window.location.origin+'/api/v1/user/me');
        if(user.status > 199 && user.status < 300) {
            userType = user.data.user_type_id;
        }
    }

    if(userType > 2) {
        return next();
    } else {
        return router.push({name: 'home'});
    }
  }

  const logOut = async function  ({to, from, next, router}) {

    const logOut = await axios.get(window.location.origin+'/api/v1/logout', {})
    .catch(function (error) {
        console.log(error);
    });

  }

const router =  new VueRouter({
    mode: 'history',
    routes: [
        {
            path: "/chat/att/:task_id?",
            name: "chat att",
            meta: { authRequired: true, middleware: [saveUri] },
            component: () => import(/* webpackChunkName: "chat-att" */'@components/workflows/tasks/activity'),

        },
        {
            path: "/form/build/:uuid?",
            name: "build form",
            meta: { authRequired: true, middleware: [saveUri] },
            component: () => import(/* webpackChunkName: "build-form" */'@views/workflows/form/build-form'),

        },
        {
            path: "/workflow/user/view/:workflowId?/:taskId?/:activity?",
            name: "workflow-user",
            meta: { authRequired: true, middleware: [saveUri, checkUserPermission] },
            component: () => import(/* webpackChunkName: "user-view" */'@views/workflows/user-view'),

        },
        {
            path: "/form/show/:uuid?",
            name: "show-form",
            meta: {middleware: [saveUri] },
            component: () => import(/* webpackChunkName: "show-form" */'@views/workflows/form/show-form'),
        },
        {
            path: "/maintenance/users",
            name: "maintenance-users",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-users" */'@views/maintenance/users/index'),
        },
        {
            path: "/maintenance/users/register",
            name: "maintenance-users-register",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-users-register" */'@views/maintenance/users/users'),
        },
        {
            path: "/maintenance/person",
            name: "maintenance-person",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-person" */'@views/maintenance/person/index'),
        },
        {
            path: "/maintenance/person/register",
            name: "maintenance-person-register",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-person-register" */'@views/maintenance/person/person'),
        },
        {
            path: "/maintenance/company",
            name: "maintenance-company",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-company" */'@views/maintenance/company/index'),
        },
        {
            path: "/maintenance/departments",
            name: "maintenance-departments",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-departments" */'@views/maintenance/departments/index'),
        },
        {
            path: "/maintenance/departments/register",
            name: "maintenance-departments-register",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-departments-register" */'@views/maintenance/departments/departments'),
        },
        {
            path: "/maintenance/clients",
            name: "maintenance-clients",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-clients" */'@views/maintenance/clients/index'),
        },
        {
            path: "/maintenance/clients/register",
            name: "maintenance-clients-register",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-clients-register" */'@views/maintenance/clients/clients'),
        },
        {
            path: "/maintenance/contracts",
            name: "maintenance-contracts",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-contracts" */'@views/maintenance/contracts/index'),
        },
        {
            path: "/maintenance/contracts/register",
            name: "maintenance-contracts-register",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-contracts-register" */'@views/maintenance/contracts/contracts'),
        },
        {
            path: "/workspaces",
            name: "workspaces",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-workspace" */'@views/maintenance/workspaces/index'),
        },
        {
            path: "/maintenance/workspace",
            name: "maintenance-workspace",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-workspace" */'@views/maintenance/workspaces/index'),
        },
        {
            path: "/maintenance/workspace/register",
            name: "maintenance-workspace-register",
            meta: {middleware: [saveUri, checkPermission] },
            component: () => import(/* webpackChunkName: "maintenance-workspace-register" */'@views/maintenance/workspaces/workspace-form'),
        },
        {
            path: "/home/:verified?",
            name: "home-page",
            meta: { authRequired: true, middleware: [saveUri] },
            component: () => import(/* webpackChunkName: "home-page" */"@views/home"),
          },
        {
            path: "/",
            name: "home",
            meta: {authRequired: true, middleware: [saveUri]},
            component: () => import(/* webpackChunkName: "home" */"@views/home"),
        },
        {
          path: "/login/:verified?",
          name: "login",
          component: () => import(/* webpackChunkName: "login" */"@views/account/login"),
          meta: {
            beforeResolve(routeTo, routeFrom, next) {
              // If the user is already logged in
              if (store.getters["auth/loggedIn"]) {
                // Redirect to the home page instead
                next({ name: "home" });
              } else {
                // Continue to the login page
                next();
              }
            },
          },
        },
        {
          path: "/register",
          name: "Register",
          component: () => import(/* webpackChunkName: "Register" */"@views/account/register"),
          meta: {
            beforeResolve(routeTo, routeFrom, next) {
              // If the user is already logged in
              const register =  axios.get(window.location.origin+'/api/v1/user', config.getHeaders)
              .then(function(response){
                if(response) {
                    // Redirect to the home page instead
                    next({ name: "home" });
                } else {
                    // Continue to the login page
                    next();
                }
              });
            },
          },
        },
        {
          path: "/password/reset/:email?",
          name: "reset-password",
          component: () => import(/* webpackChunkName: "reset-password" */"@views/account/reset-password"),
        },
        {
          path: "/email/verify",
          name: "email-verify",
          component: () => import(/* webpackChunkName: "email-verify" */"@views/account/email-verify"),
        },
        {
          path: "/logout",
          name: "logout",
          meta: {
            authRequired: true, middleware: [logOut]
          },
        },
        {
          path: "/404",
          name: "404",
          component: () => import(/* webpackChunkName: "404" */"@views/errors/404"),
        },
        // Redirect any unmatched routes to the 404 page. This may
        // require some server configuration to work in production:
        // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
        {
          path: "*",
          redirect: "404",
        },

        {
          path: "/workflow/show/:id/:workflow_task_id?/:activity?",
          name: "workflow-show",
          meta: { authRequired: true, middleware: [saveUri] },
          component: () => import(/* webpackChunkName: "workflows-show" */"@views/workflows/show"),
        },
        // {
        //   path: "/workflow/show3/:id/:workflow_task_id?/:activity?",
        //   name: "workflow-show3",
        //   meta: { authRequired: true, middleware: [saveUri] },
        //   component: () => import(/* webpackChunkName: "workflows-show3" */"../views/workflows/show3"),
        // },
        {
          path: "/workflow/new",
          name: "workflow New",
          meta: { authRequired: true, middleware: [saveUri] },
          component: () => import(/* webpackChunkName: "workflow-new" */"@views/workflows/new"),
        },
        {
            path: "/contacts/profile",
            name: "Profile",
            meta: { authRequired: true, middleware: [saveUri] },
            component: () => import(/* webpackChunkName: "Profile" */"@views/maintenance/users/user-profile"),
          },

    ]
});
function nextFactory(context, middleware, index){
    const subsequentMiddleware = middleware[index];
    if(!subsequentMiddleware) {
        return context.next;
    }
    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}
router.beforeEach((to, from, next) => {
    if(to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)? to.meta.middleware : [to.meta.middleware];
        const context = {from, next,router,to};
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0] ({...context, next: nextMiddleware})
    }
    return next();
});

export default router;
