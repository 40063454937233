var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-3"},[_c('h6',[_vm._v("Filtros avançados")]),_vm._v(" "),_vm._l((_vm.filter.data),function(localFilter,filterKey){return _c('div',{key:filterKey,staticClass:"row gy-2 gx-3 align-items-center justify-content-start form-inline",class:{
        'mt-1':
          (localFilter.column &&
            [14, 16].indexOf(localFilter.column.workflow_column_type_id) ==
              -1) ||
          !localFilter.column,
      }},[_c('div',{staticClass:"col-12 col-md-4"},[_c('b-select',{staticClass:"form-control rem-size-2",attrs:{"size":"sm","value":localFilter.column},on:{"change":function($event){return _vm.setFilterColumn({ column: $event, index: filterKey })}}},[_c('b-select-option',{attrs:{"value":null}},[_vm._v("Coluna")]),_vm._v(" "),_vm._l((_vm.columns),function(field,fieldKey){return _c('b-select-option',{key:fieldKey,class:{'d-none': field.hidden},attrs:{"value":field}},[_vm._v(_vm._s(field.name))])})],2)],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-2"},[_c('b-select',{staticClass:"form-control rem-size-2",attrs:{"size":"sm","value":localFilter.condition},on:{"change":function($event){return _vm.setFilterCondition({ condition: $event, index: filterKey })}}},[_c('b-select-option',{attrs:{"value":null}},[_vm._v("Condição")]),_vm._v(" "),_vm._l((_vm.conditionList),function(condition,conditionKey){return _c('b-select-option',{key:conditionKey,class:{
              'd-none': condition.value == 5 && localFilter.isDate,
              'd-none': localFilter.column && localFilter.column.workflow_column_type_id == 16 && [6, 7].indexOf(condition.value) !== -1
            },attrs:{"value":condition.value}},[_vm._v(_vm._s(condition.label))])})],2)],1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-9"},[(
                (localFilter.column &&
                  !localFilter.isDate &&
                  [2, 3, 8, 4, 10, 14, 16].indexOf(
                    localFilter.column.workflow_column_type_id
                  ) == -1) ||
                !localFilter.column
              )?_c('b-input',{staticClass:"rem-size-2 input-sm-height",attrs:{"size":"sm","type":localFilter.column &&
                localFilter.column.workflow_column_type_id != 7
                  ? localFilter.column.html_type
                  : null,"placeholder":"Valor","disabled":localFilter.condition == 3 ||
                localFilter.condition == 4 ||
                !localFilter.column},on:{"focus":function($event){return _vm.setMask($event, localFilter.column.workflow_column_type_id)},"blur":function($event){_vm.removeMask($event),
                  _vm.setFilterValue({
                    data: $event.target.value,
                    index: filterKey,
                  })}}}):(
                localFilter.column &&
                [2, 3, 8, 4, 10].indexOf(
                  localFilter.column.workflow_column_type_id
                ) !== -1
              )?_c('b-select',{staticClass:"form-control rem-size-2",attrs:{"size":"sm","value":localFilter.value,"disabled":localFilter.condition == 3 || localFilter.condition == 4},on:{"change":function($event){return _vm.setFilterValue({ data: $event, index: filterKey })}}},[_c('b-select-option',{attrs:{"value":null}},[_vm._v("Valor")]),_vm._v(" "),_c('b-select-option',{class:{
                  'd-none': localFilter.column.workflow_column_type_id != 4,
                },attrs:{"value":false}},[_vm._v("Não")]),_vm._v(" "),_c('b-select-option',{class:{
                  'd-none': localFilter.column.workflow_column_type_id != 4,
                },attrs:{"value":true}},[_vm._v("Sim")]),_vm._v(" "),_vm._l((localFilter.column.options),function(filterOption){return _c('b-select-option',{key:("status_" + (filterOption.id)),class:{
                  'd-none':
                    localFilter.column.workflow_column_type_id != 3 &&
                    localFilter.column.workflow_column_type_id != 8,
                },attrs:{"value":filterOption.id}},[_vm._v(_vm._s(filterOption.option))])}),_vm._v(" "),_vm._l((_vm.responsibles),function(filterOption){return _c('b-select-option',{key:("responsible_" + (filterOption.id)),class:{
                  'd-none': localFilter.column.workflow_column_type_id != 2,
                },attrs:{"value":filterOption.id}},[_vm._v(_vm._s(filterOption.full_name))])}),_vm._v(" "),_vm._l((_vm.collaboratorsList),function(filterOption){return _c('b-select-option',{key:("collaborator_" + (filterOption.id)),class:{
                  'd-none': localFilter.column.workflow_column_type_id != 10,
                },attrs:{"value":filterOption.id}},[_vm._v(_vm._s(filterOption.full_name))])})],2):(
                localFilter.column &&
                localFilter.column.workflow_column_type_id == 16
              )?_c('multiselect',{staticClass:"multiselect-sm mt-2",attrs:{"value":localFilter.value,"disabled":localFilter.condition == 3 || localFilter.condition == 4,"options":_vm.childWorkflow.options,"custom-label":_vm.setWorkflowTaskLable,"track-by":"id","deselect-label":"Remover","select-label":"Selecionar","reset-after":false,"multiple":false,"searchable":true,"internal-search":false,"close-on-select":true,"clear-on-select":false,"options-limit":30,"limit":9000,"limit-text":_vm.limitText,"show-no-results":true,"hide-selected":false,"preserve-search":false,"placeholder":"Selecione uma opção","loading":_vm.workflowDataLoad == localFilter.column.id},on:{"select":function($event){return _vm.setFilterValue({ data: $event, index: filterKey })},"open":function($event){return _vm.searchColumnWorkflow('', localFilter.column)},"search-change":function($event){return _vm.searchColumnWorkflow($event, localFilter.column)}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(props.option.name),expression:"props.option.name"}],staticClass:"avatar-group-item"},[_c('b-avatar',{staticClass:"mr-3 bg-light text-white avatar-circle font-size-10",attrs:{"size":"1.5rem","variant":"info avatar-circle","text":_vm.getWorkflowTaskInitials(props.option.name)}}),_vm._v(" "),_c('span',{staticClass:"ps-2"},[_vm._v(_vm._s(props.option.name))])],1)]}}],null,true)},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Não encontrado.")]),_vm._v(" "),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Ninguem por aqui.")])]):(
                localFilter.column &&
                localFilter.column.workflow_column_type_id == 14
              )?_c('multiselect',{staticClass:"multiselect-sm mt-2",attrs:{"value":localFilter.value,"disabled":localFilter.condition == 3 || localFilter.condition == 4,"options":_vm.persons.options,"label":"full_name","track-by":"id","deselect-label":"Remover","select-label":"Selecionar","reset-after":false,"multiple":false,"searchable":true,"internal-search":false,"close-on-select":true,"clear-on-select":false,"options-limit":30,"limit":9000,"limit-text":_vm.limitText,"show-no-results":true,"hide-selected":false,"preserve-search":false,"placeholder":"Selecione uma opção","loading":_vm.personsLoad == localFilter.column.id},on:{"select":function($event){return _vm.setFilterValue({ data: $event, index: filterKey })},"search-change":function($event){return _vm.searchPersons($event, localFilter.column)},"open":function($event){return _vm.searchPersons('', localFilter.column)}},scopedSlots:_vm._u([{key:"option",fn:function(props){return [_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(props.option.name),expression:"props.option.name"}],staticClass:"avatar-group-item"},[_c('b-avatar',{staticClass:"mr-3 bg-light text-white avatar-circle font-size-10",attrs:{"size":"1.5rem","variant":"info avatar-circle","text":_vm.getWorkflowTaskInitials(props.option.name)}}),_vm._v(" "),_c('span',{staticClass:"ps-2"},[_vm._v(_vm._s(props.option.name))])],1)]}}],null,true)},[_vm._v(" "),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Não encontrado.")]),_vm._v(" "),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v("Ninguem por aqui.")])]):_vm._e(),_vm._v(" "),(_vm.simpleDate)?_c('b-input',{staticClass:"rem-size-2 input-sm-height",class:{
                'd-none': !localFilter.isDate,
              },attrs:{"max":"31-12-9999","type":"date","value":localFilter.value,"disabled":localFilter.condition == 3 || localFilter.condition == 4},on:{"change":function($event){return _vm.setFilterValue({ data: $event, index: filterKey })}}}):_c('b-datepicker',{directives:[{name:"show",rawName:"v-show",value:(localFilter.isDate),expression:"localFilter.isDate"}],staticClass:"filter-datepicker input-sm-height align-items-center",class:{ 'd-flex': localFilter.isDate },attrs:{"set-button":"","today-button":"","locale":"pt-br","date-format-options":{
                day: 'numeric',
                month: 'numeric',
                year: '2-digit',
              },"value":localFilter.value,"disabled":localFilter.condition == 3 || localFilter.condition == 4},on:{"change":function($event){return _vm.setFilterValue({ data: $event, index: filterKey })}}})],1),_vm._v(" "),_c('div',{staticClass:"col-2 col-md-1 d-flex justify-content-end align-items-center p-0"},[_c('b-form-group',{staticClass:"form-text d-flex m-0",attrs:{"name":"search","label":" ","label-for":"search"}},[_c('b-button',{attrs:{"variant":" text-decoration-none border-unset border-none","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.removeFilterData({
                    index: filterKey,
                    id:
                      typeof _vm.filter.id != undefined &&
                      typeof _vm.filter.id != 'undefined'
                        ? _vm.filter.id
                        : null,
                  })}}},[_c('span',{staticClass:"mdi mdi-close-thick text-danger"})])],1)],1),_vm._v(" "),_c('div',{staticClass:"col-2 col-md-1 d-flex justify-content-end align-items-center p-0",class:{
              'visibility-hidden': filterKey + 1 != _vm.filter.data.length,
            }},[_c('b-form-group',{staticClass:"form-text d-flex m-0",attrs:{"name":"search","label":" ","label-for":"search"}},[_c('b-button',{attrs:{"variant":" text-decoration-none border-unset border-none","size":"sm"},on:{"click":_vm.addFilterData}},[_c('span',{staticClass:"bx bx-plus-medical rem-size-2 text-success"})])],1)],1)])])])}),_vm._v(" "),_c('div',{staticClass:"row gy-2 gx-3 align-items-center justify-content-start form-inline mt-3 bottom-filter"},[_c('div',{staticClass:"col-6 col-md-6 d-flex justify-content-start align-items-center"},[_c('div',{staticClass:"col-6 col-md-3"},[_c('b-button',{attrs:{"variant":"success p-1 rem-size-2","size":"sm"},on:{"click":function($event){$event.preventDefault();_vm.setFilter(_vm.mergeFilter), _vm.hideDropdown()}}},[_vm._v("Filtrar\n          ")])],1)]),_vm._v(" "),_c('div',{staticClass:"col-6 col-md-6"},[_c('div',{staticClass:"row justify-content-end align-items-center d-none"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-4 col-md-1 d-flex justify-content-end align-items-center"},[_c('div',{staticClass:"form-check form-switch mt-1"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.save),expression:"save"}],staticClass:"form-check-input",attrs:{"type":"checkbox","id":"save-filters","checked":""},domProps:{"checked":Array.isArray(_vm.save)?_vm._i(_vm.save,null)>-1:(_vm.save)},on:{"change":[function($event){var $$a=_vm.save,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.save=$$a.concat([$$v]))}else{$$i>-1&&(_vm.save=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.save=$$c}},_vm.saveFilter]}})])])])])])],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-8 col-md-3 d-flex justify-content-end rem-size-2"},[_c('label',{staticClass:"my-auto",attrs:{"for":"save-filters"}},[_vm._v("Salvar filtros")])])}]

export { render, staticRenderFns }