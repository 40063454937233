import { menusMethods } from '@state/helpers';
import axios from 'axios';
function getTitle (vm) {
    const { pageTitle } = vm.$options
    if (pageTitle) {
      return typeof pageTitle === 'function'
        ? pageTitle.call(vm)
        : pageTitle
    }
  }
  export default {
    data(){
        return{
            copying: false,
            localeOrigin: window.location.origin,
            pageTitle: '',
            siteKey: process.env.MIX_REACAPTCHA_SITE_KEY,
            datePikerLang : {
                formatLocale: {
                  firstDayOfWeek: 0
                }
            }
        }
    },
    created () {
      const pageTitle = getTitle(this);
      this.setNewTitlePage(pageTitle);
    },
    computed: {
        currentSubdomain() {
            let host = window.location.hostname;
            if(host.indexOf('.') != -1) {
                host = host.split('.')[0];
            }
            return host;
        }
    },
    methods:{
        ...menusMethods,
        setNewTitlePage(pageTitle){
            if (pageTitle) {
              var oldTitle = document.title;
              oldTitle = oldTitle.split(' | ');
              var newTitle = pageTitle;

              if(Array.isArray(oldTitle)){
                if(oldTitle[1]){
                    newTitle = pageTitle +" | "+oldTitle[1];
                }
              }
              document.title = newTitle;
            }
        },
        checkFileSize(file, sizeLimit = 15728640){
            var data = {error: false, message:''};
            if(file.size > sizeLimit){
                var fileSizeMb = file.size /1048576;
                var fileSizeLimitMb = sizeLimit /1048576;
                data.error = true;
                data.message = 'Arquivo acima de '+fileSizeLimitMb.toFixed(2)+'mb, tamanho: '+fileSizeMb.toFixed(2)+'mb';
            }
            return data;
        },
        logout: async function(){
            await axios.get('/api/v1/logout').catch((error)=>{ console.log(error);});
            window.location.href = window.location.origin+'/logout'
        },

        // Set debounce time to execute
        setDebounce(func, timeout = 300){
            let timer;
            return (...args) => {
              clearTimeout(timer);
              timer = setTimeout(() => { func.apply(this, args); }, timeout);
            };
          },
        getDateFormat(date, withTime = true){

            let dateTime =  new Date(date);

            let day = !isNaN(dateTime.getDate()) ? dateTime.getDate().toString() : "00";
            let month = !isNaN(dateTime.getDate()) ? (dateTime.getMonth()+1).toString() : "00";
            let year = !isNaN(dateTime.getDate()) ? dateTime.getFullYear().toString() : "0000";
            let hour = !isNaN(dateTime.getDate()) ? dateTime.getHours().toString() : "00";
            let minute = !isNaN(dateTime.getDate()) ? dateTime.getMinutes().toString() : "00";
            let formatedDate = "";

            if(hour == "00" && minute == "00" || hour == 0 && minute == 0) {
                formatedDate =  `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year.padStart(4, '0')}`;
            } else {
                formatedDate = withTime? `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year.padStart(4, '0')} ${hour.padStart(2, '0')}:${minute.padStart(2, '0')}` : `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year.padStart(4, '0')}`
            }

            return formatedDate;
        },
        contactTooltipData(user) {
            if(user) {
                let render = '';
                let avatar = '';
                let name = '';
                let phone = '';
                let mail = '';
                let initials = `${user.name?user.name[0]:''}${user.last_name?user.last_name[0]:''}`;
                let whatsappMessage = `Olá ${user.name} ${user.last_name}, estou entrando em contato referente a tarefa do sistema ${process.env.MIX_APP_NAME}.`;

                if(user.avatar) {
                    avatar +=   '<div class="col-12 d-flex align-items-center justify-content-center p-2">';
                    avatar +=       `<img data-v-f7391524="" src="${user.avatar}" alt="${user.name} Avatar" class="rounded-circle header-profile-user">`;
                    avatar +=   '</div>';
                } else {
                    avatar +=   '<div class="col-12 d-flex align-items-center justify-content-center p-2">';
                    avatar +=       '<span class="b-avatar mr-3 bg-light text-white avatar-circle font-size-10 badge-info rounded-circle">'
                    avatar +=           `<span class="b-avatar-text"><span>${initials}</span></span>`
                    avatar +=       '</span>'
                    avatar +=   '</div>';
                }

                if(user.name) {
                    name +=   '<div class="col-12 d-flex align-items-center justify-content-start p-1 font-size-14">';
                    name +=       `<i class="bx bx-user font-size-18 me-1"></i> ${user.name} ${user.last_name}`;
                    name +=   '</div>';
                }

                if(user.cellphone) {
                    phone +=   '<div class="col-12 d-flex align-items-center justify-content-start p-1 font-size-14">';
                    phone +=       `<i class="bx bx-phone font-size-18 me-1"></i> <a href="tel:${user.cellphone.replaceAll(['-', '.', '(',')'], '')}">${user.cellphone}</a>`;
                    phone +=   '</div>';

                    phone +=   '<div class="col-12 d-flex align-items-center justify-content-start p-1 font-size-14">';
                    phone +=       `<i class="bx bxl-whatsapp font-size-18 me-1"></i>`
                    phone +=       `<a href="https://api.whatsapp.com/send/?phone=${user.cellphone.replaceAll(['-', '.', '(',')'], '')}&text=${whatsappMessage}&type=phone_number&app_absent=0" target="_blank">${user.cellphone}</a>`;
                    phone +=   '</div>';
                }
                // https://wa.me/5511000000000?text=ola+pessasdf+sdfs
                // https://api.whatsapp.com/send/?phone=5511000000000&text=ola+pessasdf+sdfs&type=phone_number&app_absent=0
                if(user.email) {
                    mail +=   '<div class="col-12 d-flex align-items-center justify-content-start p-1 font-size-14">';
                    mail +=       `<i class="bx bx-mail-send font-size-18 me-1"></i> <a href="mailto:${user.email}">${user.email}</a>`;
                    mail +=   '</div>';
                }

                render += '<div clas="row">';
                render +=  avatar + name + phone + mail
                render += '</div>';

                return render;

            }
        },
        closeParentMenu() {
            this.closeMenu();
        },
        setZIndex(el, show = false){
            let element = el.target;
            let scroller = element.closest('.vue-recycle-scroller__item-view');

            if(scroller) {
               if(show)
                    scroller.style['z-index'] = 1;
               else
                scroller.style['z-index'] = 'auto';
            }
        },
        copyToClipboard(value) {
            navigator.clipboard.writeText(value);
            this.copying = true;
            setTimeout(()=>{ this.copying = false }, 1000);
        }
    },

  }
