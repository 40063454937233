export const state = {
    searchNavbar: '',
}

export const mutations = {
    CHANGE_SEARCH_NAVBAR(state, searchNavbar) {
      state.searchNavbar = searchNavbar;
    },
}

export const actions = {
  changeSearchNavbar({ commit }, { searchNavbar }) {
    commit('CHANGE_SEARCH_NAVBAR', searchNavbar);
  },
}
