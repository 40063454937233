<template>
    <div class="base d-flex justify-content-center align-items-center cell-height"  :class="{'disabled-opacity': disabled}" @click="save(!value)">
        <div class="acceptance-label radius-button-border w-100 bg-success d-flex justify-content-center align-items-center text-white rem-size-2">Sim</div>
    </div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        data() {
            return {
                value: false
            }
        },
        props: {
            column: {
                default: {},
            },
            task: {
                default: {},
            },
            columnValue: {
                default: {},
            },
            disabled: {
                default: false,
            },
        },
        methods: {
            ...mapActions('columnValues', ['updateColumnValue']),
            ...mapActions('tasks', ['updateTaskColumnValue']),
            toggleValue(value) {
                let label = this.$el.querySelector(".acceptance-label");
                this.value = value;
                if(label) {
                    if(value) {
                        label.classList.remove('bg-danger')
                        label.classList.add('bg-success')
                        label.textContent = 'Sim'
                    } else {
                        label.classList.add('bg-danger')
                        label.classList.remove('bg-success')
                        label.textContent = 'Não'

                    }
                }
            },
            save(value) {
                this.toggleValue(value);
                let data = {
                    group_id: this.task.workflow_group_id,
                    task_id: this.task.id,
                    column_type_id: this.column.workflow_column_type_id,
                    column_value_id: this.columnValue.id,
                    value: value,
                    description: '',
                }

                let response = this.updateColumnValue(data)
                if(response) {
                    this.updateTaskColumnValue(data);
                    return;
                }

                this.toggleValue(!value);

            },
        },
        mounted() {
            this.value = this.columnValue.value == '1' || this.columnValue.value == 1 || this.columnValue.value == 'true';
            this.toggleValue(this.value);
        }
    }
</script>

<style lang="scss" scoped>

</style>
