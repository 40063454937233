<template>
  <div>
    <div class="p-3">
      <h6>Filtros avançados</h6>
      <div
        class="row gy-2 gx-3 align-items-center justify-content-start form-inline"
        :class="{
          'mt-1':
            (localFilter.column &&
              [14, 16].indexOf(localFilter.column.workflow_column_type_id) ==
                -1) ||
            !localFilter.column,
        }"
        v-for="(localFilter, filterKey) in filter.data"
        :key="filterKey"
      >
        <div class="col-12 col-md-4">
          <b-select
            class="form-control rem-size-2"
            size="sm"
            :value="localFilter.column"
            @change="setFilterColumn({ column: $event, index: filterKey })"
          >
            <b-select-option :value="null">Coluna</b-select-option>
            <b-select-option
              v-for="(field, fieldKey) in columns"
              :key="fieldKey"
              :value="field"
              :class="{'d-none': field.hidden}"
              >{{ field.name }}</b-select-option
            >
          </b-select>
        </div>
        <div class="col-12 col-md-2">
          <b-select
            class="form-control rem-size-2"
            size="sm"
            :value="localFilter.condition"

            @change="
              setFilterCondition({ condition: $event, index: filterKey })
            "
          >
            <b-select-option :value="null">Condição</b-select-option>

            <b-select-option
              v-for="(condition, conditionKey) in conditionList"
              :key="conditionKey"
              :value="condition.value"
              :class="{
                'd-none': condition.value == 5 && localFilter.isDate,
                'd-none': localFilter.column && localFilter.column.workflow_column_type_id == 16 && [6, 7].indexOf(condition.value) !== -1
              }"
              >{{ condition.label }}</b-select-option
            >
          </b-select>
        </div>
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-12 col-md-9">
              <b-input
                v-if="
                  (localFilter.column &&
                    !localFilter.isDate &&
                    [2, 3, 8, 4, 10, 14, 16].indexOf(
                      localFilter.column.workflow_column_type_id
                    ) == -1) ||
                  !localFilter.column
                "
                @focus="
                  setMask($event, localFilter.column.workflow_column_type_id)
                "
                @blur="
                  removeMask($event),
                    setFilterValue({
                      data: $event.target.value,
                      index: filterKey,
                    })
                "
                size="sm"
                class="rem-size-2 input-sm-height"
                :type="
                  localFilter.column &&
                  localFilter.column.workflow_column_type_id != 7
                    ? localFilter.column.html_type
                    : null
                "
                placeholder="Valor"
                :disabled="
                  localFilter.condition == 3 ||
                  localFilter.condition == 4 ||
                  !localFilter.column
                "
              ></b-input>
              <b-select
                v-else-if="
                  localFilter.column &&
                  [2, 3, 8, 4, 10].indexOf(
                    localFilter.column.workflow_column_type_id
                  ) !== -1
                "
                class="form-control rem-size-2"
                size="sm"
                :value="localFilter.value"
                @change="setFilterValue({ data: $event, index: filterKey })"
                :disabled="
                  localFilter.condition == 3 || localFilter.condition == 4
                "
              >
                <b-select-option :value="null">Valor</b-select-option>
                <b-select-option
                  :value="false"
                  :class="{
                    'd-none': localFilter.column.workflow_column_type_id != 4,
                  }"
                  >Não</b-select-option
                >
                <b-select-option
                  :value="true"
                  :class="{
                    'd-none': localFilter.column.workflow_column_type_id != 4,
                  }"
                  >Sim</b-select-option
                >

                <!-- Status -->
                <b-select-option
                  :class="{
                    'd-none':
                      localFilter.column.workflow_column_type_id != 3 &&
                      localFilter.column.workflow_column_type_id != 8,
                  }"
                  v-for="filterOption in localFilter.column.options"
                  :key="`status_${filterOption.id}`"
                  :value="filterOption.id"
                  >{{ filterOption.option }}</b-select-option
                >

                <!-- Responsibles -->
                <b-select-option
                  :class="{
                    'd-none': localFilter.column.workflow_column_type_id != 2,
                  }"
                  v-for="filterOption in responsibles"
                  :key="`responsible_${filterOption.id}`"
                  :value="filterOption.id"
                  >{{ filterOption.full_name }}</b-select-option
                >

                <!-- Collaborator -->
                <b-select-option
                  :class="{
                    'd-none': localFilter.column.workflow_column_type_id != 10,
                  }"
                  v-for="filterOption in collaboratorsList"
                  :key="`collaborator_${filterOption.id}`"
                  :value="filterOption.id"
                  >{{ filterOption.full_name }}</b-select-option
                >
              </b-select>

              <!-- Workflow -->
              <multiselect
                v-else-if="
                  localFilter.column &&
                  localFilter.column.workflow_column_type_id == 16
                "
                :value="localFilter.value"
                @select="setFilterValue({ data: $event, index: filterKey })"
                :disabled="
                  localFilter.condition == 3 || localFilter.condition == 4
                "
                :options="childWorkflow.options"
                :custom-label="setWorkflowTaskLable"
                track-by="id"
                deselect-label="Remover"
                select-label="Selecionar"
                :reset-after="false"
                :multiple="false"
                :searchable="true"
                :internal-search="false"
                :close-on-select="true"
                :clear-on-select="false"
                :options-limit="30"
                :limit="9000"
                :limit-text="limitText"
                :show-no-results="true"
                :hide-selected="false"
                :preserve-search="false"
                placeholder="Selecione uma opção"
                :loading="workflowDataLoad == localFilter.column.id"
                @open="searchColumnWorkflow('', localFilter.column)"
                @search-change="
                  searchColumnWorkflow($event, localFilter.column)
                "
                class="multiselect-sm mt-2"
              >
                <template slot="option" slot-scope="props">
                  <div
                    class="avatar-group-item"
                    v-b-tooltip="props.option.name"
                  >
                    <b-avatar
                      size="1.5rem"
                      variant="info avatar-circle"
                      :text="getWorkflowTaskInitials(props.option.name)"
                      class="mr-3 bg-light text-white avatar-circle font-size-10"
                    ></b-avatar>
                    <span class="ps-2">{{ props.option.name }}</span>
                  </div>
                </template>
                <span slot="noResult">Não encontrado.</span>
                <span slot="noOptions">Ninguem por aqui.</span>
              </multiselect>

              <!-- Person -->
              <multiselect
                v-else-if="
                  localFilter.column &&
                  localFilter.column.workflow_column_type_id == 14
                "
                :value="localFilter.value"
                @select="setFilterValue({ data: $event, index: filterKey })"
                :disabled="
                  localFilter.condition == 3 || localFilter.condition == 4
                "
                :options="persons.options"
                label="full_name"
                track-by="id"
                deselect-label="Remover"
                select-label="Selecionar"
                :reset-after="false"
                :multiple="false"
                :searchable="true"
                :internal-search="false"
                :close-on-select="true"
                :clear-on-select="false"
                :options-limit="30"
                :limit="9000"
                :limit-text="limitText"
                :show-no-results="true"
                :hide-selected="false"
                :preserve-search="false"
                placeholder="Selecione uma opção"
                :loading="personsLoad == localFilter.column.id"
                @search-change="searchPersons($event, localFilter.column)"
                @open="searchPersons('', localFilter.column)"
                class="multiselect-sm mt-2"
              >
                <template slot="option" slot-scope="props">
                  <div
                    class="avatar-group-item"
                    v-b-tooltip="props.option.name"
                  >
                    <b-avatar
                      size="1.5rem"
                      variant="info avatar-circle"
                      :text="getWorkflowTaskInitials(props.option.name)"
                      class="mr-3 bg-light text-white avatar-circle font-size-10"
                    ></b-avatar>
                    <span class="ps-2">{{ props.option.name }}</span>
                  </div>
                </template>
                <span slot="noResult">Não encontrado.</span>
                <span slot="noOptions">Ninguem por aqui.</span>
              </multiselect>
              <b-input
                max="31-12-9999"
                type="date"
                class="rem-size-2 input-sm-height"
                :class="{
                  'd-none': !localFilter.isDate,
                }"
                :value="localFilter.value"
                v-if="simpleDate"
                :disabled="
                  localFilter.condition == 3 || localFilter.condition == 4
                "
                @change="setFilterValue({ data: $event, index: filterKey })"
              ></b-input>
              <b-datepicker
                v-else
                set-button
                today-button
                locale="pt-br"
                class="filter-datepicker input-sm-height align-items-center"
                :class="{ 'd-flex': localFilter.isDate }"
                :date-format-options="{
                  day: 'numeric',
                  month: 'numeric',
                  year: '2-digit',
                }"
                v-show="localFilter.isDate"
                :value="localFilter.value"
                @change="setFilterValue({ data: $event, index: filterKey })"
                :disabled="
                  localFilter.condition == 3 || localFilter.condition == 4
                "
              ></b-datepicker>
            </div>
            <div
              class="col-2 col-md-1 d-flex justify-content-end align-items-center p-0"
            >
              <b-form-group
                class="form-text d-flex m-0"
                name="search"
                label=" "
                label-for="search"
              >
                <b-button
                  variant=" text-decoration-none border-unset border-none"
                  size="sm"
                  @click.prevent="
                    removeFilterData({
                      index: filterKey,
                      id:
                        typeof filter.id != undefined &&
                        typeof filter.id != 'undefined'
                          ? filter.id
                          : null,
                    })
                  "
                  ><span class="mdi mdi-close-thick text-danger"></span
                ></b-button>
              </b-form-group>
            </div>
            <div
              class="col-2 col-md-1 d-flex justify-content-end align-items-center p-0"
              :class="{
                'visibility-hidden': filterKey + 1 != filter.data.length,
              }"
            >
              <b-form-group
                class="form-text d-flex m-0"
                name="search"
                label=" "
                label-for="search"
              >
                <b-button
                  variant=" text-decoration-none border-unset border-none"
                  size="sm"
                  @click="addFilterData"
                  ><span
                    class="bx bx-plus-medical rem-size-2 text-success"
                  ></span
                ></b-button>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row gy-2 gx-3 align-items-center justify-content-start form-inline mt-3 bottom-filter"
      >
        <div
          class="col-6 col-md-6 d-flex justify-content-start align-items-center"
        >
          <div class="col-6 col-md-3">
            <b-button
              variant="success p-1 rem-size-2"
              size="sm"
              @click.prevent="setFilter(mergeFilter), hideDropdown()"
              >Filtrar
            </b-button>
          </div>
        </div>
        <div class="col-6 col-md-6">
          <div class="row justify-content-end align-items-center d-none">
            <div class="col-8 col-md-3 d-flex justify-content-end rem-size-2">
              <label for="save-filters" class="my-auto">Salvar filtros</label>
            </div>
            <div
              class="col-4 col-md-1 d-flex justify-content-end align-items-center"
            >
              <div class="form-check form-switch mt-1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="save-filters"
                  checked
                  v-model="save"
                  @change="saveFilter"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import showAlert from "./alerts";
import IMask from "imask";
import config from "../config/config";
import Multiselect from "vue-multiselect";

import {
  filtersComputed,
  filtersMethods,
  usersOptionsComputed,
} from "../state/helpers";

import { mapState, mapActions } from "vuex";

export default {
  components: { Multiselect },
  data() {
    return {
      filterLocalValue: {},
      options: [],
      conditions: [],
      save: true,

      // Workflow Column
      childWorkflow: {
        options: [],
      },
      workflowDataLoad: 0,

      // Persons Column
      persons: {
        options: [],
      },
      personsLoad: 0,
    };
  },
  props: {
    simpleDate: {
      default: false,
    },
    columns: {
      default: [],
    },
  },
  computed: {
    ...filtersComputed,
    ...usersOptionsComputed,
    ...mapState("tasks", {
      globalFilter: (state) => state.filter,
    }),
    filter: {
      get() {
        return this.advancedFilter;
      },
      set(value) {
        this.setAdvancedFilter(value);
      },
    },
    responsibles() {
      return [];
    },
    collaborators() {
      return [];
    },
    mergeFilter() {
      let filter = JSON.parse(JSON.stringify(this.globalFilter));
      filter.workflowId = filter.workflow_id;
      filter.orderBy = filter.order_by;
      filter.orderDesc = filter.order_desc;
      filter.advanced = this.advancedFiltersData;
      return filter;
    },
    responsibles() {
        return this.responsiblesList.filter((responsible)=>{
            return responsible.user_type_id == 1 || responsible.user_type_id == 2 || responsible.user_type_id == 4;
        });
    }
  },
  methods: {
    ...filtersMethods,
    ...mapActions("tasks", ["setFilter"]),
    setMask(evt, type) {
      let input = evt.target;
      if (!input) return;

      switch (type) {
        default:
          var mask = IMask(input, { mask: String });
          mask.updateValue()
          break;
        case 7:
          var mask = IMask(input, { mask: String }).destroy();
          mask = IMask(input, config.masks.time);
          mask.updateValue()
          break;
        case 12:
          var mask = IMask(input, { mask: String }).destroy();
          mask = IMask(input, config.masks.currency);
          mask.updateValue()
          break;
      }
    },
    removeMask(evt) {
      let input = evt.target;
      if (!input) return;
      IMask(input, { mask: String });
      // const imaskInstance = input.dataset.imask;
      // if (imaskInstance) imaskInstance.destroy();
    },

    // Worfklow Column
    setWorkflowTaskLable: function (data) {
      return `${data.name}`;
    },
    limitText(count) {
      return `E ${count} outros`;
    },
    searchColumnWorkflow: async function searchColumnWorkflow(search, column) {
      clearTimeout(searchColumnWorkflow.timeout);
      searchColumnWorkflow.timeout = setTimeout(async () => {
        this.workflowDataLoad = column.id;

        let content = {
          workflow_id: column.child_workflow_id,
          workflow_column_id: column.id,
          search,
        };

        axios
          .post(
            `${window.location.origin}/api/v1/workflow/task/search`,
            content
          )
          .then((response) => {
            if (!response.data.error) {
              this.$set(this.childWorkflow, "options", response.data.data);
              this.workflowDataLoad = 0;
              return;
            }

            this.$set(this.childWorkflow, "options", []);
            this.workflowDataLoad = 0;
          })
          .catch((error) => {
            this.workflowDataLoad = 0;
            console.log("searchColumnWorkflow error :>>", error);
          });
      }, 800);
    },
    getWorkflowTaskInitials(text = "") {
      let name = "";
      if (text.length > 0) {
        name = text[0];
      }

      let textSplited = text.split(" ");

      if (textSplited.length > 1) {
        name += textSplited[textSplited.length - 1][0];
      }

      return name;
    },

    // Persons Column
    searchPersons: async function searchPersons(data, column) {
      clearTimeout(searchPersons.timeout);
      searchPersons.timeout = setTimeout(async () => {
        let type = null;
        if (column) {
          this.personsLoad = column.id;
          type = column.person_type;
        }

        axios
          .post(`${window.location.origin}/api/v1/person/search`, {
            search: data,
            type,
          })
          .then((response) => {
            if (!response.data.error) {
              this.$set(this.persons, "options", response.data.data);
              this.personsLoad = 0;
              return;
            }

            this.$set(this.persons, "options", []);
            this.personsLoad = 0;
          })
          .catch((error) => {
            this.personsLoad = 0;
          });
      }, 600);
    },
    hideDropdown() {
        this.setFilterDropdown(false);
    }
  },
  created() {
    this.getContitionList();
    this.$watch(()=> this.advancedFiltersData, (att, old) => {
        if(this.save) {
            this.saveFilter();
        }
    });
  },
  mounted() {
    if(this.advancedFilter.data.length == 1) {
        let localFilter = this.advancedFilter.data[0];
        this.setFilterCondition({ condition: localFilter.condition != null ? localFilter.condition : 1, index: 0 })
    }
  }
};
</script>

<style lang="scss" scoped>
.visibility-hidden {
  visibility: hidden;
}
.bottom-filter {
    bottom: 0;
    position: absolute;
    width: -webkit-fill-available;
    margin: 10px;
}
</style>
