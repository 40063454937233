<template>
  <b-dropdown
    @show="setZIndex($event, true), dropdownStatus($event, true)"
    @hide="setZIndex($event), dropdownStatus($event, false)"
    lazy
    :disabled="disabled"
    variant="btn-edit text-decoration-none fs-6 text-secondary p-0"
    :menu-class="[
      { 'right-0': dropright },
      'dropdown-person-width dropdown-bottom scr-right',
    ]"
    right
  >
    <template #button-content>
      <span>
        <i class="fas fa-plus-circle text-white"></i>
      </span>
    </template>
    <li
      role="presentation"
      class="ps-3 pe-3 border-bottom pb-1"
      v-if="persons.length > 0"
    >
      <span class="sub">Selecionados</span>
      <div class="avatar-group">
        <div
          v-b-tooltip.html="contactTooltipData(person)"
          :id="`user-${person.id}`"
          v-for="(person, keyPerson) in persons"
          :key="keyPerson"
          @click="
            (e) =>
              !disabled && removePerson(columnValue, person)
          "
        >
          <div class="avatar-group-item">
            <a href="javascript: void(0);" class="d-inline-block">
              <img
                :v-lazy="person.avatar"
                v-if="person.avatar"
                class="rounded-circle avatar-circle delete-on-hover"
                alt
              />
              <b-avatar
                variant="info"
                :text="getPersonInitials(person)"
                class="mr-3 bg-light text-white avatar-circle rounded-circle delete-on-hover font-size-10"
                v-else
              />
            </a>
          </div>
        </div>
      </div>
    </li>
    <b-dropdown-form
      style=" z-index: 1"
      @submit.stop.prevent
      @keypress.enter.stop.prevent
      class="bg-default dropdown-search-input w-100"
    >
      <b-form-group>
        <b-form-input
          class="form-input"
          placeholder="Buscar"
          v-model="searchPerson"
          debounce="200"
        ></b-form-input>
      </b-form-group>
    </b-dropdown-form>
    <div v-if="dropdownActive">
        <div
          v-for="(person, personKey) in filterPersons"
          :key="personKey"
          :value="person.id"
        >
          <b-dropdown-item @click="savePerson(columnValue, person)">
            <img
              :src="person.avatar"
              class="rounded-circle avatar-xs mr-1"
              alt
              v-if="person.avatar"
            />
            <b-avatar
              variant="info"
              :text="`${person.name ? person.name[0] : ''}${
                person.last_name ? person.last_name[0] : ''
              }`"
              class="mr-3 bg-light text-white avatar-circle"
              v-else
            ></b-avatar>
            <span class="rem-size-2">
            {{
              (person.name ? person.name + " " : "") +
              (person.last_name ? person.last_name : "")
            }}
            </span>
          </b-dropdown-item>
        </div>
    </div>
  </b-dropdown>
</template>

<script>
import { personsComputed, personsMethods } from "../../../state/helpers";
export default {
  data() {
    return {
      searchPerson: "",
      persons: [],
      dropdownActive: false,
    };
  },
  props: {
    columnValue: {
      default: {},
    },
    selectedPersons: {
      default: [],
    },
    dropright: {
      default: false,
    },
    disabled: {
      default: false,
    },
    personType: {
      default: "",
    },
  },
  computed: {
    ...personsComputed,
    filterPersons() {
      if (!this.dropdownActive) {
        return [];
      }

      let name = "";
      return this.findPersons.filter((data) => {
        let type = true;
        if (this.personType) {
          type = data.type == this.personType;
        }

        var keys = Object.keys(data);
        var response = false;
        keys.forEach((keyObject) => {
          if (
            typeof data[keyObject] === "string" ||
            data[keyObject] instanceof String
          ) {
            name = data["name"] + " " + data["last_name"];
            if (
              data[keyObject]
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .indexOf(
                  this.searchPerson
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ) != -1 ||
              name
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .indexOf(
                  this.searchPerson
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ) != -1
            ) {
              response = true;
            }
          } else {
            if (data[keyObject] == this.searchPerson.toLowerCase()) {
              response = true;
            }
          }
        });

        return type && response;
      });
    },
  },
  methods: {
    ...personsMethods,
    search: function search(data) {
      clearTimeout(search.timeout);

      search.timeout = setTimeout(() => {
        this.getSearchPersons(data, this.personType);
      }, 300);
    },
    getPersonInitials(person) {
      let text = (`${person.name} ${person.last_name}`).trim();
      let name = '';
      if (person.corporate_name) {
        text = person.corporate_name.trim();
      }
      if(text.length > 0) {
          name = text[0].toUpperCase();
      }

      let textSplited = text.split(' ');

      if(textSplited.length > 1) {
          if(textSplited[textSplited.length - 1]) {
              name += textSplited[textSplited.length - 1][0].toUpperCase();
          }
      }

      return name;
    },
    savePerson(columnValue, person) {
      this.$emit("save", { columnValue: columnValue, person: person });
    },
    removePerson(columnValue, person) {
      this.persons = this.persons.filter((item)=> item.id != person.id);
      this.$emit("remove", person.pivot.id, person, columnValue.id);
    },
    dropdownStatus(event, active = false) {
        this.dropdownActive = active
        let base = this.$el.closest('.base');
        if(!base) return
        let element = base.querySelector('.add-person-area');

        if(active && element) {
            element.classList.add('d-flex')
        }

        if(!active && element) {
            element.classList.remove('d-flex')
        }

    }
  },
  beforeMount() {
    this.persons = this.selectedPersons;
  },
  mounted() {
    this.$watch(
      () => this.searchPerson,
      (att, old) => {
        this.search(att);
      }
    );
  },
  beforeDestroy() {
    this.searchPerson = "";
    this.persons = [];
    this.dropdownActive = false;
    // this.$el.parentNode.removeChild(this.$el);
  },
};
</script>



<style lang="css" scoped>
.size-avatar {
  width: 32px;
  height: 32px;
}
.dropdown-search-input {
  width: 15vw;
  position: sticky;
  top: 0;
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.bg-default {
  background-color: #2a3042;
}
</style>
<style>
.dropdown-bottom::-webkit-scrollbar-track {
  background: #2a3042 !important;
}

.dropdown-bottom::-webkit-scrollbar-thumb {
  background: #eee !important;
}

.dropdown-bottom {
  bottom: 0 !important;
  height: 38vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scr-right::-webkit-scrollbar {
  width: 6px !important;
}

.scr-right::-webkit-scrollbar-track {
  background: transparent;
  color: black !important;
  border-radius: 10px !important;
}

.scr-right::-webkit-scrollbar-thumb:hover {
  background: black !important;
  opacity: 0.6 !important;
  border-radius: 10px !important;
}

.scr-right::-webkit-scrollbar-thumb {
  background: #c3cbe4 !important;
  opacity: 0.5 !important;
  padding-left: 5px !important;
  border-radius: 10px !important;
}

.bg-default-base {
  background-color: #222736;
}

.dropdown-person-width {
  width: 300px;
}
</style>
