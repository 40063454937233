<template>
  <div>
    <b-dropdown
        variant="btn-edit text-decoration-none fs-6  p-0 px-1"
        text="Novo"
        ref="aasp-import-button"
        id="new-content"
        size="sm"
        lazy
        no-caret
        right
        menu-class="dropdown-import"

    >
      <template v-slot:button-content>
        <span
        :class="{
            'text-info': btnLoad,
            'text-white': !btnLoad && !uploadSuccess,
            'text-success': uploadSuccess,
            'text-danger': uploadError,
        }">
            <i v-if="btnLoad" class="bx bx-spin bx-loader rem-size-3 d-flex"></i>
            <i v-else class="bx bx-import rem-size-3 d-flex"></i>
        </span>
      </template>
      <b-dropdown-item class="rem-size-2" @click="openImport()">AASP</b-dropdown-item>
    </b-dropdown>
    <input
      type="file"
      class="d-none"
      @change="aaspImport"
      ref="aasp-import-file"
    />
  </div>
</template>

<script>
import axios from "axios";
import showAlert from "@components/alerts";
export default {
  data() {
    return {
      file: [],
      btnLoad: false,
      uploadSuccess: false,
      uploadError: false,
    };
  },
  props: {
    workflowId: {
      type: Number,
      required: true,
    }
  },
  methods: {
    aaspImport(e) {
      let vue = this;
      this.btnLoad = true;

      let input = e.target;
      let formData = new FormData();
      formData.append("file", input.files[0]);
      formData.append("workflow_id", this.workflowId);

      axios
        .post(
          window.location.origin + "/api/v1/plugins/aasp/import",
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        )
        .then((response) => {
          showAlert(false);
          this.btnLoad = false;
          this.uploadSuccess = true;
          this.$refs["aasp-import-file"].value = "";
          setTimeout(() => {
            vue.uploadSuccess = false;
          }, 2000);
        })
        .catch((error) => {
          this.btnLoad = false;
          vue.uploadError = true;
          setTimeout(() => {
            vue.uploadError = false;
          }, 2000);
          showAlert(true, error.response.data.message);
          vue.$refs["aasp-import-file"].value = "";
        });
    },
    openImport() {
      this.btnLoad = false;
      this.$refs["aasp-import-file"].click();
    },
  },
};
</script>

<style lang="scss">
.dropdown-import {
    width: fit-content !important;
    min-width: 130%;
}
</style>
