<template>
  <!-- row add task -->
  <div class=" task-group-item item bg-default base margin-n1-" :class="{'z-index-n1-': !parent, 'z-index-0': parent}" style="position: relative;" v-show="!hideAllTasks">
    <div  class="border-start border-bottom task-row task-row-shadow bg-default row " :class="{'is-task-row': !parent}" :id="null">

        <!-- Subtask indicator -->
        <div class="subtask-ul-border cell-height left-freez bg-default-base m-0 subtask-indicator-left"
            :class="`border-task-${group.color}`" v-show="parent" style="margin-left: 0px !important;">
            <div class="subtask-ul w-100" :class="`border-task-${group.color}`"></div>
        </div>


      <b-col cols="1" class="p-0 border-top left-freez bg-default left-70" :class="{'subtask-checkbox-left subtask-title-left group-indicator-subtask-margin': parent, 'task-checkbox-margin group-indicator-item-margin task-checkbox-left': !parent}">
        <b-row class="justify-content-end h-100  align-items-center m-0" >
            <!-- Group color indicator -->
            <div class="d-flex align-items-center cell-height">
                <div :class="[{ 'bg-secondary': !group.color }, `bg-${group.color}`]" class="group-indicator p-0 m-0 group-indicator-height-middle group-indicator-border-radius-bottom">
                </div>
                <b-col class="d-flex align-items-center w-100 justify-content-center" style="padding: 36px;" :class="{'d-none': !tasksProcessing.loadMore}">
                    <i class="bx bx-spin bx-loader text-dark"></i>

                    <!-- <b-checkbox class="cell-chekbox disabled" disabled></b-checkbox> -->
                </b-col>
            </div>

          <b-col cols="2" class="p-0" style="margin-left: -3px;"></b-col>

        </b-row>
      </b-col>

      <div :data-coll-type="column.workflow_column_type_id" class="border-bottom col-normalizer validate px-0 border-end border-start border-top"
        v-for="(column, columnKey) in workflowColumnsData" :key="columnKey"
        :class="[{'mnw-col': column.workflow_column_type_id != 9,  'ps-0 pe-0': columnKey != 0 && !parent, 'border-end border-start border-top': column.workflow_column_type_id != 3 && column.workflow_column_type_id != 8, 'mnw-col-title-sub left-freez bg-default left-182': column.workflow_column_type_id == 9 && parent, 'mnw-col-title left-freez bg-default left-140': column.workflow_column_type_id == 9 && !parent, 'd-none': column.hidden },`resizer_width_${column.id}`]"
        :style="{ 'width': column.workflow_column_type_id == 9 && parent ? `${column.width - 42}px`: `${column.width}px` }"
        >

        <!-- Title -->
        <div v-if="column.workflow_column_type_id == 9" :id="`required_item_${column.id}_${group.id}`" :tabindex="columnKey">
          <b-col class="col-11">
            <div class="align-items-center cell-height" :class="{'d-none': !storeLoad, 'd-flex': storeLoad}">
              <i class="bx bx-spin bx-loader"></i>
            </div>
            <div class="cell-input-field" v-show="!storeLoad">
                <span  @click="toggleShow(true)" type="text"
                  class="add-task-label overflow-text form-control-plaintext cell-title cell-input cell-height p-0 ps-2 d-flex align-items-center font-size-12"
                >+ Adicionar</span>
                <input name="add-task" v-model.lazy="form.newTask" @blur="addTask(group.id, $event, true, parentId)"
                  @keyup.enter="$event.target.blur()" :ref="`cell_new_${group.id}`" type="text" placeholder="+ Adicionar"
                  class="add-task-input form-control cell-input cell-edit overflow-text cell-height ps-2 d-flex align-items-center d-none font-size-12">
            </div>
          </b-col>
          <b-col cols="1"></b-col>
        </div>

        <TaskAddColumns @select="setForm" :column="column"
          v-if="column.required && showComponents" :id="`required_item_${column.id}_${group.id}`" />

        <span @click="cancel" class="cancel-task link-cursor" v-if="column.workflow_column_type_id == 9"><i
            class="mdi mdi-close-thick text-danger"></i></span>
      </div>
      <b-col cols="1" class="add-task-button d-none new-task-send-button">
        <a href="#" @click.prevent="addTask(group.id, $event, false, parentId)">
            <i class="bx bx-send"></i>
        </a>
      </b-col>
    </div>
    <slot />
  </div> <!-- end row add task -->
</template>

<script>
import { workflowsComputed, columnsComputed, tasksComputed, tasksMethods, columnValuesMethods } from "@state/helpers";
import TaskAddColumns from "./add-columns";
import axios from "axios";
export default {
  components: { TaskAddColumns },
  data() {
    return {
      resizerWidths: {},
      titleWidth: 0,
      showComponents: false,
      dispatchCancel: false,
      storeLoad: false,
      cells: [],
      //   workflowColumnsData: {},
      validateCount: 0,
      requiredReset: [],
      workflowColumns: [],
      form: {
        newTask: "",
        required: [],
      },
    };
  },
  props: {
    // hideAllTasks: {
    //   default: false,
    // },
    group: {},
    parent: {
        default: null
    },
    columnSum: {},
    workflowCells: {
      default: [],
    },

    taskDefaultValue: {},
  },
  computed: {
    ...workflowsComputed,
    ...columnsComputed,
    ...tasksComputed,
    workflowColumnsData() {
      return this.columns;
    },
    parentId() {
        return this.parent ? this.parent.id : false;
    },
    // cellsShow() {
    //   return this.workflowCells;
    // },

    workflowColumnsRequired() {
      return this.workflowColumns.filter((item) => item.required);
    },
  },
  methods: {
    ...tasksMethods,
    ...columnValuesMethods,
    toggleShow(showInput = false) {
        let root = this.$el;
        let base = root.querySelector(".cell-input-field");

      if (!base) {
        return;
      }

      let input = base.querySelector(".add-task-input");

      let span = base.querySelector(".add-task-label");

      let button = root.querySelector('.add-task-button');

      if (showInput) {

        span.classList.remove("d-flex");

        span.classList.add("d-none");

        input.classList.remove("d-none");

        input.classList.add("d-flex");

        button.classList.remove("d-none");

        button.classList.add("d-flex")

        root.classList.add('active-add');

        this.backupValue = input.value;

        this.$set(this.form, 'newTask', this.taskDefaultValue);

        input.focus();

        this.showComponents = true;

      } else {
        span.classList.remove("d-none");

        span.classList.add("d-flex");

        input.classList.remove("d-flex");

        input.classList.add("d-none");

        button.classList.add("d-none");

        button.classList.remove("d-flex")

        root.classList.remove('active-add');

        // span.textContent = input.value;
        span.textContent = '+ Adicionar';

        this.showComponents = false;
      }


    },
    cancel() {
      let root = this.$el
      this.dispatchCancel = true;

      this.$set(this.form, "newTask", "");

      this.$set(this.form, "required", this.requiredReset);

      this.showComponents = false;


    //   this.cellsShow[0].editShow[this.group.id] = false;
      this.toggleShow(false);

      setTimeout(()=>{
          let invalidElements = root.querySelectorAll(".invalid");

          invalidElements.forEach((el) => {
            el.classList.remove("invalid");
          });

      })
    },
    setForm(value) {
      let required = this.form.required.filter((req) => req.id != value.id);

      required.push(value);

      this.$set(this.form, "required", required);
        this.validate()
    },
    async validate() {
      let validate = this.form.required;
      let errorCount = 0;

      let element = null;
      let errorElement = null;
      validate.forEach((item) => {
        element = this.$el.querySelector(
          `#required_item_${item.id}_${this.group.id}`
        );
        if (element && item.workflow_column_type_id != 11) {
          if (
            (item.workflow_column_type_id != 9 &&
              (item.value === "" ||
                item.value === null ||
                item.value === undefined)) ||
            (item.workflow_column_type_id == 9 &&
              (this.form.newTask === "" ||
                this.form.newTask === null ||
                this.form.newTask === undefined))
          ) {
            errorCount++;

            element.closest(".validate").classList.add("invalid");
          } else {
            element.closest(".validate").classList.remove("invalid");
          }

          if (errorCount === 1) {
            errorElement = element;
          }
        }
      });

      if (errorElement) {
        errorElement.focus();
        this.$scrollTo(errorElement, 1000, {
          container: "body",
          x: true,
          y: false,
          offset: -400,
        });
      }
      return errorCount === 0;
    },

    addTask: async function addTask(
      groupId = false,
      event,
      rowAdd = false,
      parentTaskId = false
    ) {
      this.dispatchCancel = false;

      if (this.dispatchCancel) {
        return;
      }

      let validate = await this.validate();

      if (!validate) {
        return;
      }

      if (rowAdd && event) {
        var parent = event.target.closest(".cell-input-field");
        var cellEdit = parent.querySelector(".cell-edit");
        var title = parent.querySelector(".cell-title").textContent;
        parent.querySelector(".cell-title").textContent = "+ Adicionar";

        this.toggleShow();

      }


      if (!this.form.newTask.trim().replace(/^\s+|\s+$/gm, "")) {
        return;
      }

      if (groupId) {
        let requiredItems = [];
        this.form.required.forEach((require) => {
          const item = {
            workflow_column_id: require.id,
            workflow_column_type_id: require.workflow_column_type_id,
            value:
              require.workflow_column_type_id === 9
                ? this.form.newTask
                : require.value,
          };
          requiredItems.push(item);
        });

        let data = new FormData();
        data.append("workflow_id", this.$route.params.id);
        data.append("task_name", this.form.newTask);
        data.append("task_description", "");
        data.append("workflow_group_id", groupId);
        data.append("parent_task_id", parentTaskId ? parentTaskId : "");
        requiredItems.forEach((require, key) => {
          data.append(
            `required_items[${key}][workflow_column_id]`,
            require.workflow_column_id
          );
          data.append(
            `required_items[${key}][workflow_column_type_id]`,
            require.workflow_column_type_id
          );
          if (Array.isArray(require.value)) {
            require.value.forEach((item) => {
              data.append(`required_items[${key}][value][]`, item);
            });
          } else {
            data.append(`required_items[${key}][value][]`, require.value);
          }
        });

        try {
          this.storeLoad = true;
          let taskId = await this.storeTask(data);

          if(taskId) {

            this.findColumnValueByTask(taskId);

          }

          this.storeLoad = false;
        } catch (error) {
          console.error(error);
          this.storeLoad = false;
          this.toggleShow();

          return;
        }
      }

      this.$set(this.form, 'newTask', "");
      this.$set(this.form, "required", this.requiredReset);
      this.showComponents = false;
      this.cancel();
    },
    newCellEditShow(groupId, parent_task_id) {
      if (parent_task_id) {
        this.$set(this.cells, -1, { editShow: {} });
        this.$set(this.cells[-1].editShow, groupId, true);
        this.form.newTask = this.taskDefaultValue;
        setTimeout(() => {
          this.$refs[`cell_new_${groupId}_${parent_task_id}`][0].focus();
          this.$refs[`cell_new_${groupId}_${parent_task_id}`][0].click();
        }, 100);
      } else {
        this.$set(this.cells, 0, { editShow: {} });
        this.$set(this.cells[0].editShow, groupId, true);
        this.form.newTask = this.taskDefaultValue;
        setTimeout(() => {
          this.$refs[`cell_new_${groupId}`][0].focus();
          this.$refs[`cell_new_${groupId}`][0].click();
        }, 100);
      }
      this.showComponents = true;
    },
    // async checkVisibilit() {
    //   if (
    //     typeof this.cellsShow[0] == "undefined" ||
    //     typeof this.cellsShow[0] == undefined
    //   ) {
    //     this.cells = [];
    //     let data = {};
    //     data[this.group.id] = false;
    //     this.cells.push({ editShow: data });
    //   }
    // },
    // async setWtacher() {
    //   await this.checkVisibilit();
    //   this.$watch(
    //     () => this.cellsShow[0],
    //     (att, old) => {
    //       if (typeof att !== "undefined") {
    //         if (!att.editShow[this.group.id]) {
    //           //   this.cancel();
    //           let invalidElements = this.$el.querySelectorAll(".invalid");
    //           invalidElements.forEach((el) => {
    //             el.classList.remove("invalid");
    //           });
    //         }
    //       }
    //     }
    //   );
    // },
  },
  created() {
    // this.setWtacher();
    let items = JSON.stringify(this.columns);
    this.workflowColumns = JSON.parse(items);
    this.cells = this.workflowCells;
    let titleColumn = this.workflowColumns.find(
      (item) => item.workflow_column_type_id == 9
    );
    const required = this.workflowColumns.filter(
      (item) => item.required || item.workflow_column_type_id == 9
    );
    this.$set(this.form, "required", required);
    this.requiredReset = required;

    this.$watch(
      () => this.columns,
      (att, old) => {
        let items = JSON.stringify(att);
        this.workflowColumns = JSON.parse(items);

        const required = this.workflowColumns.filter(
          (item) => item.required || item.workflow_column_type_id == 9
        );
        this.$set(this.form, "required", required);
        this.requiredReset = required;
      }
    );

    if (titleColumn) {
      this.titleWidth = titleColumn.width;
    }

    this.workflowColumns.forEach((column) => {
      this.$set(this.resizerWidths, column.id, column.width);
    });

  },
  mounted() {
    // this.$watch(
    //   () => this.form.required,
    //   (att, old) => {
    //     let show =
    //       typeof this.cellsShow[0] !== "undefined"
    //         ? this.cellsShow[0].editShow[this.group.id]
    //         : false;
    //     if (!this.dispatchCancel && show) {
    //       this.validate();
    //     }
    //   }
    // );
    // try {
    //   this.$watch(
    //     () => this.cellsShow,
    //     (att, old) => {
    //       if (!att) {
    //         // this.cancel();
    //         let invalidElements = this.$el.querySelectorAll(".invalid");
    //         invalidElements.forEach((el) => {
    //           el.classList.remove("invalid");
    //         });
    //       }
    //     }
    //   );
    // } catch (error) {}
  },
};
</script>

<style>
.bg-default-base {
  background-color: #222736;
}

.bg-default {
  background-color: #2a3042;
}

.left-freez {
  position: sticky;
  left: 0px;
  z-index: 2;
}

/* .left-70 {
  left: 70px !important;
} */

.wdt-70 {
  width: 70px;
}

.group-indicator {
  width: 9px;
  position: absolute;
  left: 0;
}

.group-indicator-item-margin {
  margin-left: 7px !important;
}

.group-indicator-height-top {
  height: -webkit-fill-available !important;
  height: -moz-available !important;
  height: 102%;
}

/* .group-indicator-height-middle {
  height: 110% !important;
} */

.group-indicator-height-bottom {
  height: 105%;
}

.group-indicator-border-radius-top {
  border-radius: 4px 0px 0px 0px;
}

.group-indicator-border-radius-bottom {
  border-radius: 0px 0px 0px 4px;
}

.mnw-col-title {
  min-width: 282px;
  max-width: 682px;
}

.mnw-col-title-sub {
  /* min-width: 220px; */
  min-width: 240px;
  max-width: 682px;
}

.overflow-text {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.cell-input {
  margin: 0 auto !important;
  width: 100% !important;
}

.cancel-task {
  position: absolute;
  width: 30px;
  height: 30px;
  right: -4px;
  top: 5px;
  display: none;
}
.active-add * .cancel-task {
   display: unset;
}

.has-subtask-indicator-margin {
    margin-left: 2px !important;
}

.has-subtask-indicator-left {
    left: 73px !important;
}

.task-checkbox-margin {
    margin-left: 11px !important;
}

.new-task-send-button {
    /* width: 40px; */
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 25px;
}
.z-index-n1 {
    z-index: -1;
}

.z-index-0 {
    z-index: 0;
}

</style>
