<script>
import { create } from 'domain';


export default {
    data() {
        return {
            selected: {},
            leftMargin: 0,
            rightMargin: 0
        }
    },
    props:{
        items:{
            default: []
        },
        searchable:{
            default: false
        },
        title:{
            default: ''
        },
        right:{
            default: 0
        },
        left:{
            default: 0
        },
        bottom:{
            default: 0
        },
        top:{
            default: 0
        }
    },
    methods:{
        dispatchSelectEvent (item) {
            this.$emit('select', item)
        },
    },
    mounted(){
        var box = this.$el.querySelector('#box')
        if (this.right !== 0) {
            let margin = isNaN(this.right)? this.right: `${this.right}px`;
            box.style.right = margin;
        }

        if(this.left !== 0){
            let margin  = isNaN(this.left)? this.left: `${this.left}px`;
            box.style.left = margin;
        }

        if(this.bottom !== 0){
            let margin  = isNaN(this.bottom)? this.bottom: `${this.bottom}px`;
            box.style.bottom = margin;
        }

        if(this.top !== 0){
            let margin  = isNaN(this.top)? this.top: `${this.top}px`;
            box.style.top = margin;
        }
    }
}
</script>
<template>
<div>
    <transition name="jump" tag="div" class="emojis">
        <div id="box">
            <div class="header justify-content-center">{{ title }}</div>
            <div class="search" v-if="searchable">
                <div class="search-container">
                    <input type="text" placeholder="Pesquisar..." />
                </div>
            </div>

            <div class="content">
                <div class="content-container scr-right">
                    <div class="content-items">
                        <span v-for="(item, index) in items" :key="index" class="content-item" :class="item.class" v-b-tooltip.top="item.title" @click.prevent="dispatchSelectEvent(item)">{{ item.text != 'undefined'? item.text:'' }}</span>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</div>
</template>

<style scoped>
#box{
    display: inline-flex;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeSpeed;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
    overflow: hidden;
    width: 325px;
    user-select: none;
    position: absolute;
    bottom: 45px;
}
.header{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
    background: #F0F0F0;
    color: #4a4a4a;
    overflow-x: auto;
}
.search-container{
    display: block;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    margin: 5px 0;
    padding: 0 5%;
}
.search-container input{
    width: 100%;
    font-size: 14px;
    padding: 6px 8px;
    box-sizing: border-box;
    border-radius: 8px;
    background: #f6f6f6;
    color: #4a4a4a;
    border: 1px solid #e4e4e4;
}
.search{
    display: block;
    width: 100%;
    max-width: 100%;
}

.content{
    font-family: Twemoji,NotomojiColor,Notomoji,EmojiOne Color,Symbola,Noto,Segoe UI Emoji,OpenSansEmoji,monospace;
    display: block;
    width: 100%;
    max-width: 100%;
    color: #4a4a4a;
    margin: 0;
}
.content-container{
    overflow-x: hidden;
    overflow-y: scroll;
    height: 350px;
}
.content-container{
    overflow-x: hidden;
    overflow-y: scroll;
    height: 350px;
}
.content-items{
    display: grid;
    margin: 5px 0;
    justify-items: center;
    grid-template-columns: repeat(5, 20%);
}
.content-item{
    font-size: 27px;
    line-height: 32px;
    height: 32px;
    width: 32px;
    display: inline-block;
    text-align: center;
    padding: 3px;
    box-sizing: content-box;
    overflow: hidden;
    transition: transform .2s;
    cursor: pointer;
    border: unset !important;
}
.content-item:hover{
    background: #00000010;
    border-radius: 8px;
    transform: scale(1.05);
}
</style>
