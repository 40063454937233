<template>
  <div class="base">
    <div
      class="d-flex cell-height justify-content-start align-items-center"
      v-if="localColumnValue.child_workflow_task && localColumnValue.child_workflow_task.length > 0 && ready"
      >

      <b-col cols="10" class="d-flex">
        <div class="avatar-group">
            <div class="avatar-group-item" v-for="workflowTask in localColumnValue.child_workflow_task" :key="workflowTask.id">
              <a href="javascript: void(0);" class="d-inline-block" data-bs-toggle="tooltip" data-bs-html="true" :title="`${workflowTask.name}`" @click.prevent="remove(workflowTask)">
                <b-avatar
                  variant="info"
                  :text="getWorkflowTaskInitials(workflowTask.name)"
                  class="mr-3 bg-light text-white avatar-circle rounded-circle transform-on-hover font-size-10"

                />
              </a>
            </div>
        </div>
      </b-col>
      <b-col
        cols="2"
        class="justify-content-center align-items-center cell-height add-area"
      >
        <WorkflowDropdown
          v-if="dropdownReady"
          :column-value="localColumnValue"
          :selected-collaborators="collaborators"
          :disabled="disabled"
          :dropright="dropright"
          :workflow-id="column.child_workflow_id"
          :selecteds="selectedIds"
          @save="save"
        />
      </b-col>
    </div>


    <span
      v-else
      class="collaborator-label form-control-plaintext cell-title cell-input text-white-solid p-0 cell-height align-items-center justify-content-center d-flex"
    >
      <WorkflowDropdown
        v-if="dropdownReady"
        :column-value="localColumnValue"
        :selected-collaborators="collaborators"
        :disabled="disabled"
        :dropright="dropright"
        :workflow-id="column.child_workflow_id"
        :selecteds="selectedIds"
        @save="save"
      />
    </span>
  </div>
</template>

<script>
import WorkflowDropdown from './WorkflowDropdown';
import { mapActions } from "vuex";
import showAlert from '../../alerts';

export default {
  components: {
    WorkflowDropdown,
  },
  data() {
    return {
      backupValue: "",
      collaborators: [],
      showBox: false,
      ready: false,
      dropdownReady: true,
      localColumnValue: {},
    //   columnValue: {
    //  child_workflow_task: [{id: 1, name:'Teste'}, {id: 2, name:'Teste 2'} ],
    // },
    };
  },
  props: {
    column: {
      default: {},
    },
    columnValue: {
    },
    disabled: {
      default: false,
    },
    task: {
      default: {},
    },
    dropright: {
      default: false,
    },
    group: {
      default: {},
    },
  },
  computed: {
    selectedIds() {
        if(typeof this.localColumnValue.child_workflow_task === 'undefined') {
            return [];
        }

        return this.localColumnValue.child_workflow_task.map((mapTask)=> mapTask.id);
    }
  },
  methods: {
    ...mapActions('columnValues', ['updateColumnValue']),
    ...mapActions('tasks', ['updateTaskColumnValue']),
    getWorkflowTaskInitials(text = '') {
        let name = '';
        text = text.trim();
        if(text.length > 0) {
            name = text[0].toUpperCase();
        }

        let textSplited = text.split(' ');

        if(textSplited.length > 1) {
            if(textSplited[textSplited.length - 1]) {
                name += textSplited[textSplited.length - 1][0].toUpperCase();
            }
        }

        return name;
    },
    save(task) {
        if(this.selectedIds.indexOf(task.id) != -1) {
            showAlert(true, `${task.name} já inserido(a)`)
            return;
        }

        this.localColumnValue.child_workflow_task.push(task)

        let value = this.localColumnValue.child_workflow_task.map((mapTask)=> mapTask.id);

        let data = {
          group_id: this.task.workflow_group_id,
          task_id: this.task.id,
          column_type_id: this.column.workflow_column_type_id,
          column_value_id: this.columnValue.id,
          value: value.length > 0 ? JSON.stringify(value) : null,
          description: '',
        }

        this.updateColumnValue(data)
        this.updateTaskColumnValue(data);
    },
    remove(task) {
        this.$swal
          .fire({
            title: "Confirma a remoção?",
            text: `Remover ${task.name}?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Sim, remover!",
            cancelButtonText: "Cancelar",
          })
          .then((result) => {
            if (result.value) {

                let newValue = this.localColumnValue.child_workflow_task.filter((workflowTask)=> workflowTask.id != task.id )
                this.$set(this.localColumnValue, 'child_workflow_task', newValue);

                let value = newValue.map((mapTask)=> mapTask.id);
                let data = {
                  group_id: this.task.workflow_group_id,
                  task_id: this.task.id,
                  column_type_id: this.column.workflow_column_type_id,
                  column_value_id: this.columnValue.id,
                  value: newValue.length > 0 ? JSON.stringify(value) : null,
                  description: '',
              }

              this.updateColumnValue(data)
              this.updateTaskColumnValue(data);
            }
        });
    }
  },
  mounted() {
    this.localColumnValue = JSON.parse(JSON.stringify(this.columnValue))
    setTimeout(()=>{
          this.ready = true;
      }, 600)
  }
};
</script>

<style lang="scss" scoped>

  .add-area {
    display: none;
    overflow: visible !important;
  }
  .base:hover * .add-area {
    display: flex;
  }

  .scr-right::-webkit-scrollbar {
    width: 6px !important;
  }

  .scr-right::-webkit-scrollbar-track {
    background: transparent;
    color: black !important;
    border-radius: 10px !important;
  }

  .scr-right::-webkit-scrollbar-thumb:hover {
    background: black !important;
    opacity: 0.6 !important;
    border-radius: 10px !important;
  }

  .scr-right::-webkit-scrollbar-thumb {
    background: #c3cbe4 !important;
    opacity: 0.5 !important;
    padding-left: 5px !important;
    border-radius: 10px !important;
  }
  .dropdown-menu-item-z-index {
    z-index: 10000;
  }
  .base-boder {
    height: calc(28px - 0.25rem) !important;
    margin: 0.13rem;
  }

  .base-boder:hover {
	color: inherit !important;
    background-color: inherit !important;
    border-color: #c3cbe4;
}
.transform-on-hover:hover {
    top: -4px;
    transition: all 0.5s ease ;
}
</style>
