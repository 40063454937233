<template>
    <div class="base">
      <div
        class="row justify-content-start align-items-center"
        v-if="collaborators.length > 0 && ready"
      >
        <b-col cols="9">
          <div class="avatar-group cell-height">
            <!-- persons avatars -->
            <!-- v-b-tooltip.html="contactTooltipData(person)" -->
            <div
              :id="`user-${collaborator.id}`"
              v-for="(collaborator, keyCollaborator) in collaborators"
              :key="keyCollaborator"
              @click="
                (e) =>
                  !disabled &&
                  remove(
                    collaborator,
                    columnValue,
                  )
              "
            >
              <div class="avatar-group-item" v-if="keyCollaborator < 5">
                <a href="javascript: void(0);" class="d-inline-block" data-bs-toggle="tooltip" data-bs-html="true" :title="`${collaborator.name}`">
                  <b-img-lazy class="rounded-circle avatar-circle avatar-xs mr-1 delete-on-hover" :src="collaborator.avatar" v-if="collaborator.avatar"/>
                  <b-avatar
                    variant="info"
                    :text="getInitials(collaborator)"
                    class="mr-3 bg-light text-white avatar-circle rounded-circle delete-on-hover font-size-10"
                    v-else
                  />
                </a>
              </div>
            </div>
          </div>
        </b-col>
        <b-col
          cols="2"
          class="justify-content-center align-items-center cell-height add-area"
        >
          <CollaboratorDropdown
            v-if="dropdownReady"
            :column-value="columnValue"
            :selected-collaborators="collaborators"
            :disabled="disabled"
            :dropright="dropright"
            @save="save"
            @remove="remove"
          />
        </b-col>
      </div>

      <span
        v-else
        class="collaborator-label form-control-plaintext cell-title cell-input text-white-solid p-0 cell-height align-items-center justify-content-center d-flex"
      >
        <CollaboratorDropdown
          v-if="dropdownReady"
          :column-value="columnValue"
          :selected-collaborators="collaborators"
          :disabled="disabled"
          :dropright="dropright"
          @save="save"
          @remove="remove"
        />
      </span>
    </div>
  </template>

    <script>
  import CollaboratorDropdown from "./CollaboratorDropdown";
  import { usersOptionsMethods, columnValuesMethods } from "../../../state/helpers";
  import { mapActions } from "vuex/dist/vuex.common.js";

  export default {
    components: {
      CollaboratorDropdown,
    },
    data() {
      return {
        backupValue: "",
        collaborators: [],
        showBox: false,
        ready: false,
        dropdownReady: true
      };
    },
    props: {
      column: {
        default: {},
      },
      columnValue: {
        default: {},
      },
      disabled: {
        default: false,
      },
      task: {
        default: {},
      },
      dropright: {
        default: false,
      },
      group: {
        default: {},
      },
    },
    methods: {
      ...usersOptionsMethods,
      ...columnValuesMethods,
      ...mapActions('tasks', ['removeTaskCollaborator']),
      getInitials(user) {
        return `${user.name ? user.name[0] : ""}${
            user.last_name ? user.last_name[0] : ""
        }`;
      },
      cancel(e) {
        let base = this.$el;

        if (base) {
          let span = base.querySelector(".collaborator-label");
          let input = base.querySelector(".collaborator-input");
          input.value = this.backupValue;
          span.textContent = this.backupValue;
          span.classList.remove("d-none");
          span.classList.add("d-flex");
          input.classList.remove("d-flex");
          input.classList.add("d-none");
        }
      },
      async save(data) {
          this.collaborators.push(data.collaborator)
          let response = await this.saveWorkflowTaskCollaborator({
            taskId: data.columnValue.workflow_task_id,
            userId: data.collaborator.id,
          })

          if(!response) {
              this.$set(this, `collaborators`, this.collaborators.filter((collaborator)=> collaborator.id != data.collaborator.id));
              this.removeColumnValueCollaborator({columnValue: data.columnValue, collaborator: data.collaborator});
              this.removeTaskCollaborator({task: this.task, user: data.collaborator});
          }

          this.dropdownReady = false;
          setTimeout(()=>{this.dropdownReady = true},200)

          this.addColumnValueCollaborator({columnValue: data.columnValue, collaborator: data.collaborator});
      },
      async remove(collaborator, columnValue) {
        try {
          let name = `${collaborator.name ? collaborator.name : ''}${collaborator.last_name ? ' ' +collaborator.last_name : '' }`;
          if(collaborator.user_id == 'undefined' || collaborator.user_id == undefined) collaborator.user_id = collaborator.id;
          let data = {
            taskId: columnValue.workflow_task_id,
            user: collaborator,
          };
          this.$swal
            .fire({
              title: "Confirma a remoção?",
              text: `${name.trim()} será removido(a)`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Sim, remover!",
              cancelButtonText: "Cancelar",
            })
            .then((result) => {
              if (result.value) {
                let validate = this.validator(
                  { value: this.collaborators.length > 1 ? this.collaborators.length : null },
                  this.required
                );
                if (!validate) {
                  return;
                }
                this.$set(this, `collaborators`, this.collaborators.filter((item)=> item.id != collaborator.id));
                let response = this.removeWorkflowTaskCollaborator(data);
                if(!response) {
                    this.collaborators.push(collaborator);
                }
                this.dropdownReady = false;
                setTimeout(()=>{this.dropdownReady = true},200)
                this.removeColumnValueCollaborator({columnValue: columnValue, collaborator: collaborator});
                this.removeTaskCollaborator({task: this.task, user: collaborator});
              }
            });
        } catch (error) {
          console.log(error);
        }
      },
      validator(data, required) {
          if (!required) return true;

          let errorCount = 0;
          let element = null;
          let errorElement = null;

          element = window.document.body.querySelector(`#required_item_${this.column.id}_${this.group.id}_${this.columnValue.id}`);

          if ((data.value === '' || data.value === null || data.value === undefined)) {

              errorCount++;
              if (element)
                  element.closest('.validate').classList.add('invalid')
          } else {
              if (element)
                  element.closest('.validate').classList.remove('invalid')
          }

          if (errorCount === 1) {
              errorElement = element;
          }

          if (errorElement) {
              errorElement.focus();
              this.$scrollTo(errorElement, 1000, {
                  container: '.container-task-group .simplebar-wrapper .simplebar-mask .simplebar-offset .simplebar-content-wrapper',
                  x: true,
                  y: false,
                  offset: -400,
              });
              showAlert(true, `O campo "${this.column.name}" é obrigatório`, 2000);
          }

          setTimeout(()=>{
              if(this.link !== '' || this.link !== null || this.link !== undefined){
                  element.closest('.validate').classList.remove('invalid');
              }
          }, 3000);
          return errorCount == 0;
      },
    },
    mounted() {
        let collaborators = JSON.stringify(this.task.collaborators);
        collaborators = JSON.parse(collaborators);

      if (this.task.collaborators.length < 4) {
        this.collaborators = collaborators;
      } else {
        this.collaborators.push(collaborators[0]);
        this.collaborators.push(collaborators[1]);
        this.collaborators.push(collaborators[2]);
        this.collaborators.push(collaborators[3]);
      }

      setTimeout(()=>{
          this.ready = true;
      }, 600)
    },
    beforeDestroy () {
      this.backupValue = "";
      this.collaborators = [];
      this.showBox = false;
      this.$el.parentNode.removeChild(this.$el);
    }
  };
  </script>
  <style lang="scss" scoped>
  .add-area {
    display: none;
    overflow: visible !important;
  }
  .base:hover * .add-area {
    display: flex;
  }

  .scr-right::-webkit-scrollbar {
    width: 6px !important;
  }

  .scr-right::-webkit-scrollbar-track {
    background: transparent;
    color: black !important;
    border-radius: 10px !important;
  }

  .scr-right::-webkit-scrollbar-thumb:hover {
    background: black !important;
    opacity: 0.6 !important;
    border-radius: 10px !important;
  }

  .scr-right::-webkit-scrollbar-thumb {
    background: #c3cbe4 !important;
    opacity: 0.5 !important;
    padding-left: 5px !important;
    border-radius: 10px !important;
  }

  .dropdown-menu-item-z-index {
    z-index: 10000;
  }
  </style>
