<template>
  <div class="d-flex justify-content-center">
    <div
      v-if="loadAaspUpdatesList"
      class="d-flex w-100 justify-content-center align-items-center"
      style="height: 70vh"
    >
      <i class="bx bx-loader-alt bx-spin fs-1"></i>
    </div>
    <div v-else class="card w-100">
      <div class="card-body">
        <div class="row">
          <!-- Search -->
          <b-form-group
            label="Buscar"
            label-for="aasp-list-search"
            class="mb-2 mt-2 col-11"
            @submit.stop.prevent
          >
            <b-form-input
              type="text"
              id="aasp-list-search"
              name="aasp-list-search"
              placeholder="Busca..."
              v-model.lazy="search"
              @change="pageControl(currentPage)"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Mostrar"
            label-for="aasp-list-show"
            class="mb-2 mt-2 col-1"
            @submit.stop.prevent
          >
            <b-form-select
              v-model="perPage"
              @change="pageControl(currentPage)"
              id="aasp-list-show"
              name="aasp-list-show"
              class="form-select form-select"
              :options="pageOptions"
            >
            </b-form-select>
          </b-form-group>
        </div>
        <div
          v-if="loadAaspUpdatesListPage"
          class="d-flex justify-content-center align-items-center page-load"
        >
          <i class="bx bx-loader-alt bx-spin fs-1"></i>
        </div>
        <b-table
          id="aasp-list-table"
          :items="aaspImportData"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          selectable
          select-mode="single"
          v-on:row-selected="selectData"
          hover
          sticky-header
          responsive
        >
          <template v-slot:cell()="data">
            <span
              v-if="data.field.key == 'importDate'"
              class="d-flex justify-content-center align-items-center"
            >
              {{ getDateFormat(data.value, true) }}</span
            >

            <span v-else-if="data.field.key == 'notFound'"
              class="row justify-content-center align-items-center"
              href="javascript:void(0)"
            >
              <span class="col d-flex justify-content-end align-items-center">{{
                data.value
              }}</span>
              <span
                class="bx bx-search-alt col d-flex justify-content-start align-items-center ps-0"
              ></span
            ></span>

            <span
              v-else
              class="d-flex justify-content-center align-items-center"
            >
              {{ data.value }}</span
            >
          </template>
        </b-table>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination-nav
                  @click.prevent
                  @change="pageControl"
                  v-model="currentPage"
                  :number-of-pages="page.total"
                  base-url="#?page="
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="aasp-list-table"
                ></b-pagination-nav>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AaspFileImport from "./file-import";
import axios from "axios";

export default {
  components: { AaspFileImport },
  props: {
    workflowId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      aaspUpdateNotFoundSearchParams: {},
      loadAaspUpdatesList: false,
      loadAaspUpdatesListPage: false,
      aaspImportData: [],
      sortBy: "importDate",
      sortDesc: true,
      page: {
        total: 3,
        baseUrl: "",
      },
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      fields: [
        {
          key: "importDate",
          label: "Data Importação",
          sortable: true,
          class: "text-center align-middle overflow-text cell-dark",
        },
        {
          key: "importDateFormat",
          label: "Data Importação",
          sortable: false,
          class: "text-center align-middle overflow-text cell-dark d-none",
        },
        {
          key: "file",
          label: "Arquivo",
          sortable: true,
          class: "text-center align-middle overflow-text cell-dark",
        },
        {
          key: "records",
          label: "Registros",
          sortable: true,
          class: "text-center align-middle overflow-text cell-dark",
        },
        {
          key: "notFound",
          label: "Não Localizados",
          sortable: true,
          class: "text-center align-middle overflow-text cell-dark",
        },
        {
          key: "duplicates",
          label: "Duplicados",
          sortable: true,
          class: "text-center align-middle overflow-text cell-dark",
        },
        {
          key: "processed",
          label: "Processados",
          sortable: true,
          class: "text-center align-middle overflow-text cell-dark",
        },
        {
          key: "notDistributed",
          label: "Não Distribuídos",
          sortable: true,
          class: "text-center align-middle overflow-text cell-dark",
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.aaspImportData.length;
    },
    pageBaseUrl() {
      return `${window.location.origin}/api/v1/plugins/aasp/show/filename?per_page=${this.perPage}&page=`;
    },
  },
  methods: {
    clearAaspUpdateId() {
      this.aaspUpdateId = 0;
    },
    selectData(data) {
      if (data.length > 0) {
        this.$emit("select", data[0]);
      }
    },
    getList(workflowId) {
      this.loadAaspUpdatesList = true;
      axios
        .post(window.location.origin + "/api/v1/plugins/aasp/show/filename", {
          workflow_id: workflowId,
          per_page: this.perPage,
        })
        .then((response) => {
          if (!response.data.error) {
            let page = response.data.data;
            this.currentPage = page.current_page;
            this.page.total = page.last_page;
            this.page.baseUrl = page.path;

            let aaspUpdates = page.data;
            this.aaspImportData = [];
            aaspUpdates.forEach((aaspUpdate) => {
              let item = {
                importDate: aaspUpdate.created_at,
                file: aaspUpdate.file_name,
                records: aaspUpdate.records,
                notFound: aaspUpdate.not_founds,
                duplicates: aaspUpdate.duplicates,
                processed: aaspUpdate.processeds,
                notDistributed: aaspUpdate.not_distributed,
              };
              this.aaspImportData.push(item);
            });
          }
          setTimeout(() => {
            this.loadAaspUpdatesList = false;
          }, 100);
        })
        .catch((error) => {
          console.log("get aasp updates error :>> ", error);
        });
    },
    pageControl(page, search = "") {
      this.loadAaspUpdatesListPage = true;
      let url = `${this.page.baseUrl}?page=${page}&per_page=${this.perPage}`;
      axios
        .post(url, {
          workflow_id: this.workflowId,
          per_page: this.perPage,
          search,
        })
        .then((response) => {
          let page = response.data.data;
          this.currentPage = page.current_page;
          this.page.total = page.last_page;
          this.page.baseUrl = page.path;

          let content = page.data;
          let contentData = [];
          content.forEach((aaspUpdate) => {
            let item = {
              aaspUpdateId: aaspUpdate.id,
              importDate: aaspUpdate.created_at,
              importDateFormat: aaspUpdate.created_date,
              file: aaspUpdate.file_name,
              records: aaspUpdate.records,
              notFound: aaspUpdate.not_founds,
              duplicates: aaspUpdate.duplicates,
              processed: aaspUpdate.processeds,
              notDistributed: aaspUpdate.not_distributed,
            };

            contentData.push(item);
          });

          this.aaspImportData = contentData;
          this.loadAaspUpdatesListPage = false;
        })
        .catch((error) => {
          this.loadAaspUpdatesListPage = false;
          console.error("Erro ao buscar dados:", error);
        });
    },
  },
  mounted() {
    this.getList(this.workflowId);
    this.$watch(
      () => this.search,
      (att, old) => {
        this.pageControl(this.currentPage, att);
      }
    );
  },
};
</script>
<style>
.cell-dark {
  background: #2a3042 !important;
  color: #c3cbe4 !important;
  width: 12%;
}
.page-load {
  height: 66%;
  position: absolute;
  left: 0;
  right: 0;
}
</style>

