
export default function showAlert (isError, msg)  {
    var icontype = isError? 'error': 'success'
    msg =  isError? msg : ''
    Vue.swal.fire({
        icon: icontype,
        title: msg,
        toast: true,
        position: "top-right",
        showConfirmButton: false,
        iconColor: "white",
        timerProgressBar: true,
        timer: 1500,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Vue.swal.stopTimer)
            toast.addEventListener('mouseleave', Vue.swal.resumeTimer)
        },
        customClass: {
            popup: "colored-toast",
        },
    });
}


