import axios from "axios"

export const state = {
    plugins: [],
    activePlugins: [],
    pluginForm: {
        provisionalExecution: false,
    },
    pluginInputError: {
        provisionalExecution: false,
    }
}

export const mutations = {
    CHANGE_PLUGINS(state, data) {
        state.plugins = data;
    },

    CHANGE_ACTIVE_PLUGINS(state, data) {
        state.activePlugins = data;
    },

    CHANGE_PLUGIN_FORM(state, data) {
        let keys = Object.keys(data);

        keys.forEach((key)=>{
            Vue.set(state.pluginForm, key, data[key]);
        });
    },
    CHANGE_PLUGIN_INPUT_ERROR(state, data) {
        let keys = Object.keys(data);

        keys.forEach((key)=>{
            Vue.set(state.pluginInputError, key, data[key]);
        });
    }
}

export const getters = {
    // Find
    checkPlugin: (state) => (id) => {
        return state.activePlugins.indexOf(id) != -1
    },

    // aasp
    hasFolder: (state) => (columns) => {
        return !!columns.find((column)=> column.tag == 'aasp_updates_folder' && column.plugin_id == 1);
    },

}
export const actions = {
    async getPlugins({ commit, dispatch }) {
        try {
            const response = await axios.get(window.location.origin + '/api/v1/plugins/list');
            if(!response.data.error) {
                commit('CHANGE_PLUGINS', response.data.data);
                await dispatch('setActivePlugins');
            }

        } catch (error) {
            commit('CHANGE_PLUGINS', []);
            dispatch('setActivePlugins');
        }
    },

    async setActivePlugins({state, commit}) {
        try {
            const activePlugins = state.plugins.filter((plugin)=> plugin.active);
            const data = [];
            activePlugins.forEach(plugin => {
                data.push(plugin.id);
            });

            commit('CHANGE_ACTIVE_PLUGINS', data);
        } catch(error) {
            commit('CHANGE_ACTIVE_PLUGINS', []);
        }
    },
    changePluginForm({ commit }, data) {
        commit('CHANGE_PLUGIN_FORM', data)
    },
    changePluginInputError({ commit }, data) {
        commit('CHANGE_PLUGIN_INPUT_ERROR', data)
    }

}
