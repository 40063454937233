import { mapState, mapGetters, mapActions } from 'vuex'

// Computed properts
export const workflowsComputed = {
    ...mapState('workflows', {
        workflows: (state) => state.workflows,
        workflow: (state) => state.workflow,
        workflowHeaderItems: (state) => state.workflowHeaderItems,
        workflowsProcessing: (state) => state.workflowsProcessing,
        selectedFormUuid: (state) => state.selectedFormUuid,
        selectedFormGroup: (state) => state.selectedFormGroup,

        // workflowId: (state) => state.workflowId,
        // title: (state) => state.title,
        // columns: (state) => state.columns,
        // ordination: (state) => state.ordination,
        // sortTask: (state) => state.sortTask,
        // workflowUsers: (state) => state.workflowUsers,
        // workflowCollaborators: (state) => state.workflowCollaborators,
        // workflowResponsibles: (state) => state.workflowResponsibles,
    }),
    // ...mapGetters('workflows', ['getItems', 'getGroups', 'getTasks', 'findItem', 'findGroup', 'findTask', 'getTasksLength', 'getGroupsLength', 'getItemsLength', 'findItemWidth', 'getWorkflows']),
}

export const groupsComputed = {
    ...mapState('groups', {
        group: (state) => state.group,
        groups: (state) => state.groups,
        groupsProcessing: (state) => state.groupsProcessing,
        columnSum: (state) => state.columnSum,
        zIndexBase: (state) => state.zIndexBase,
    }),
    ...mapGetters('groups',['allGroupsColapsed'])
}

export const columnsComputed = {
    ...mapState('columns', {
        column: (state) => state.column,
        columns: (state) => state.columns,
        totalWidth: (state) => state.totalWidth,
        columnsProcessing: (state) => state.columnsProcessing,
    }),
}

export const columnValuesComputed = {
    ...mapState('columnValues', {
        columnValue: (state) => state.columnValue,
        columnValues: (state) => state.columnValues,
        columnValuesProcessing: (state) => state.columnValuesProcessing
    }),
}

export const tasksComputed = {
    ...mapState('tasks', {
        task: (state) => state.task,
        tasks: (state) => state.tasks,
        tasksProcessing: (state) => state.tasksProcessing,
        tasksLimit: (state) => state.tasksLimit,
        tasksSelectedLimit: (state) => state.tasksSelectedLimit,
        showTasksSelectedLimitModal: (state) => state.showTasksSelectedLimitModal,
        ordination: (state) => state.ordination,
        filter: (state) => state.filter,
        selectAll: (state) => state.selectAll,
        selectAllTaskGroupId: (state) => state.selectAllTaskGroupId,
        tasksSelecteds: (state) => state.tasksSelecteds,
        showBottomMenu: (state) => state.showBottomMenu,
        taskDataOptions: (state) => state.taskDataOptions,
        page: (state) => state.page,
        calledPage: (state) => state.calledPage,
        rowPage: (state) => state.rowPage,
        openSubtasks: (state) => state.openSubtasks,
        hideAllTasks: (state) => state.hideAllTasks,
    }),
    ...mapGetters('tasks',['hasSubtasksSelected', 'sortTask'])
}

export const userComputed = {
    ...mapState('user', {
        user: (state) => state.user,
        userLoad: (state) => state.userLoad,
        users: (state) => state.users,
        usersLoad: (state) => state.usersLoad,
        workflowUsers: (state) => state.workflowUsers,
    }),
    ...mapGetters('user', ['typeId', 'getNotificationsUnreadByTask', 'checkUserTaskPermissions']),
}

export const usersOptionsComputed = {
    ...mapState('usersOptions', {
        // data
        usersList: (state) => state.usersList,
        collaboratorsList: (state) => state.collaboratorsList,
        responsiblesList: (state) => state.responsiblesList,
        searchResponsiblesList: (state) => state.searchResponsiblesList,
        searchCollaboratorsList: (state) => state.searchCollaboratorsList,

        // load
        loadUsersList: (state) => state.loadUsersList,
        loadCollaboratorsList: (state) => state.loadCollaboratorsList,
        loadResponsiblesList: (state) => state.loadResponsiblesList,
        loadSearchResponsiblesList: (state) => state.loadSearchResponsiblesList,
        loadSearchCollaboratorsList: (state) => state.loadSearchCollaboratorsList,
    }),
}

export const searchComputed = {
    ...mapState('search', {
        searchNavbar: (state) => state.searchNavbar
    })
}

export const personsComputed = {
    ...mapState('persons', {
        maritalStatus: (state) => state.maritalStatus,
        nationalities: (state) => state.nationalities,
        personsOptions: (state) => state.personsOptions,
        selectedsPersons: (state) => state.selectedsPersons,
        findPersons: (state) => state.findPersons,
    }),
    ...mapGetters('persons', ['getPersonsSelecteds']),
}

export const companiesComputed = {
    ...mapState('companies', {
        company: (state) => state.company,
    })
}

// export const workflowsComputed = {
//     ...mapState('workflows', {
//         workflows: (state) => state.workflows,
//         workflow: (state) => state.workflow,
//         workflowId: (state) => state.workflowId,
//         title: (state) => state.title,
//         columns: (state) => state.columns,
//         ordination: (state) => state.ordination,
//         sortTask: (state) => state.sortTask,
//         workflowUsers: (state) => state.workflowUsers,
//         workflowCollaborators: (state) => state.workflowCollaborators,
//         workflowResponsibles: (state) => state.workflowResponsibles,
//     }),
//     ...mapGetters('workflows', ['getItems', 'getGroups', 'getTasks', 'findItem', 'findGroup', 'findTask', 'getTasksLength', 'getGroupsLength', 'getItemsLength', 'findItemWidth', 'getWorkflows']),
// }

export const menusComputed = {
    ...mapState('menus', {
        menuData: (state) => state.menuData,
        menuItems: (state) => state.menuItems,
        isColapsedMenu: (state) => state.isColapsedMenu,
    }),
}

export const workspacesComputed = {
    ...mapState('workspaces', {
        workspace: (state) => state.workspace,
        workspaces: (state) => state.workspaces,
        workspacesLoad: (state) => state.workspacesLoad,
    }),
}

export const pluginsComputed = {
    ...mapState('plugins', {
        plugins: (state) => state.plugins,
        activePlugins: (state) => state.activePlugins,
        pluginForm: (state) => state.pluginForm,
        pluginInputError: (state) => state.pluginInputError,
        ...mapGetters('plugins', ['checkPlugin', 'hasFolder'])
    }),
}

export const utilsComputed = {
    ...mapState('utils', {
        isLayoutMobile: (state) => state.isLayoutMobile,
    }),
}

export const filtersComputed = {
    ...mapState('filters', {
        conditionList: (state) => state.conditionList,
        advancedFilter: (state) => state.advancedFilter,
        showFilterDropdown: (state) => state.showFilterDropdown,
    }),
    ...mapGetters('filters', ['advancedFiltersData'])
}

// Actions

/**Workflows Actions */
export const workflowsMethods = mapActions('workflows', [
    'listWorkflows',
    'findWorkflow',
    'deleteWorkflow',
    'storeWorkflow',
    'updateWorkflow',
    'clearWorkflow',
    'selectWorkflow',
    'setWorkflow',
    'setWorkflowHeaderItems',
    'setSelectedFormUuid',
    'setSelectedFormGroup',
    'clearWorkflows',
    'clearWorkflow'
]);

/**Columns Actions */
export const columnsMethods = mapActions('columns', [
    'listColumns',
    'findColumn',
    'deleteColumn',
    'storeColumn',
    'updateColumn',
    'searchColumn',
    'updateColumnWidth',
    'unhideColumn'
]);

/**Columns Values Actions */
export const columnValuesMethods = mapActions('columnValues', [
    'listColumnValues',
    'setListColumnValues',
    'findColumnValue',
    'findColumnValueByTask',
    'deleteColumnValue',
    'storeColumnValue',
    'updateColumnValue',
    'searchColumnValue',
    'loadMoreColumnValues',
    'addColumnValueCollaborator',
    'removeColumnValueCollaborator'
]);

/**Groups Actions */
export const groupsMethods = mapActions('groups', [
    'listGroups',
    'findGroup',
    'deleteGroup',
    'storeGroup',
    'updateGroup',
    'searchGroup',
    'getAllGroupTotal',
    'setGroups',
    'setDragGroups',
    'updateGroupsSequence',
    'toggleShowTasks',
    'toggleShowTasksByGroup',
    'clearGroups',
    'toggleAllGroups'
]);

/**Tasks Actions */
export const tasksMethods = mapActions('tasks', [
    'listTasksByWorkflow',
    'findTask',
    'loadMoreTasks',
    'deleteTask',
    'storeTask',
    'updateTask',
    'searchTask',
    'setOrdinationTasks',
    'updateTaskResponsible',
    'setShowSubtasks',
    'setSelectAllTasks',
    'clearSelectedTasks',
    'deleteSelectedTasks',
    'duplicateSelectedRows',
    'convertSelectedTasks',
    'moveSelectedTasks',
    'updateTasksColumnSequence',
    'setTasks',
    'onTaskDragOver',
    'onTaskStartDrag',
    'onTaskDrop','onTaskDragEnd',
    'setFilter',
    'clearFilter',
    'appendTaskGroup',
    'setFilterCustom',
    'websocketUpdateTask',
    'sortTaskSequence',
    'toggleTasksLimitModal',
    'sortTasks',
    'saveCurrentTaskSequence',
    'removeColumnValueByColumnId',
    'resetCalledPageByGroups',
    'setRowPage',
    'setTaskChecklistsOptions',
    'clearTasks',
    'toggleHideAllTasks',
    'removeTask'
]);

/**Users Options Actions */
export const usersOptionsMethods = mapActions('usersOptions', [
    'getUsersList',
    'getResponsibleList',
    'getCollaboratorList',
    'getSearchResponsibles',
    'getSearchCollaborators',
    'saveWorkflowTaskCollaborator',
    'removeWorkflowTaskCollaborator'
]);

/**Users Actions */
export const userMethods = mapActions('user', [
    'getUser',
    'getNotifications',
    'getMoreNotifications',
    'addNotification',
    'updateNotification',
    'markAllNotificationsAsRead',
    'getUsers',
    'muteNotifications',
    'playNotificationAudio',
    'markNotificationsAsReadByTask'
]);

/**Search Actions */
export const searchMethods = mapActions('search', [
    'changeSearchNavbar'
]);

/**Persons Actions */
export const personsMethods = mapActions('persons', [
    'getPersons',
    'getSearchPersons',
    'getPersonsByWorkflow',
    'saveWorkflowTaskPerson',
    'removeWorkflowTaskPerson'
]);

/** Companies Actions */
export const companiesMethods = mapActions('companies', [
    'getCompany',
    'changeCompany'
]);

/** Menus Actions */
export const menusMethods = mapActions('menus', [
    'getMenu',
    'setMenuStatus',
    'openMenu',
    'closeMenu'
]);

/** Workspaces Actions */
export const workspacesMethods = mapActions('workspaces', [
    'getWorkspaces',
    'getWorkspace',
    'saveWorkspace',
    'deleteWorkspace',
    'selectWorkspace'
]);

/** Plugins Actions */
export const pluginsMethods = mapActions('plugins', [
    'getPlugins',
    'changePluginForm',
    'changePluginInputError'
]);

/** Utils Actions */
export const utilsMethods = mapActions('utils', [
    'changeIsLayoutMobile'
]);

/** Filters Actions */
export const filtersMethods =  mapActions('filters', [
    'getContitionList',
    'addFilterData',
    'removeFilterData',
    'checkFilterDateColumn',
    'setFilterColumn',
    'setFilterCondition',
    'setFilterValue',
    'saveFilter',
    'setFilterDropdown',
    'clearAdvancedFilters'
]);
