export default (item) => {
    let clearTaskName = '';
    let clearSearchData = data.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    if(typeof item.name === 'string' || item.name instanceof String){
        clearTaskName = item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        if(clearTaskName.indexOf(clearSearchData) != -1 || item.id.toString().indexOf(data) != -1){
            return true;
        }

    } else {

        if(item.name.toString().indexOf(data.toLowerCase()) != -1 || item.id.toString().indexOf(data) != -1){
            return true;
        }

    }
}
