<script>
import {userComputed, userMethods} from '../../state/helpers'
import showAlert from '../../components/alerts'
import Recaptcha from '../../components/Recaptcha.vue'
import config from '../../config/config'
import axios from 'axios'
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  components: {Recaptcha},
  data() {
    return {
        baseUrl: window.location.origin,
        title: "Entrar",
        headers: {},
        tryingToLogIn: false,
        isAuthError: false,
        company: null,
        companyLogo: null,
        processing: false,
        form: {
            email: "",
            password: "",
            recaptcha_token: ''
        },
        showRecaptcha: true,
        submitted: false,
    };
  },
  props: {
    authError: {
      type: String,
      required: false,
      default: () => null,
    },
    verified:{
        type: String,
        required: false,
        default: ''
    }
  },
  methods: {
      ...userMethods,
      async formValidate(e) {
        this.submitted = true;
        this.$v.$touch();
            e.preventDefault();

            if(!this.$v.$anyError){
            this.processing = true
            delete axios.defaults.headers.common['Authorization'];
            this.deleteTokenAuth();
            await axios.get('/sanctum/csrf-cookie')
            await axios.post(window.location.origin+"/api/v1/login", this.form, config.getHeaders)
            .then( async (response) => {

                if(response.data.access_token){

                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('workspace-loads', 0);
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                    document.cookie = 'loggedIn=dHJ1ZQ==;SameSite=Lax';
                    await this.setTokenCookie(response.data.access_token);
                    e.target.submit();
                }

            }).catch(error => {

                this.deleteTokenAuth();
                if(error.response.status == 401){
                    showAlert(true, 'E-mail ou senha incorretos')
                } else {
                    showAlert(true, error.response.data.message)
                }
                this.showRecaptcha = false;
                setTimeout(() => {
                    this.showRecaptcha = true;
                }, 200);

                this.processing = false
            })

        }
    },
    async setTokenCookie(token, tries = 20) {
      let currentToken = config.getToken;
      document.cookie = 'token='+token+';SameSite=Lax';
      if(currentToken != token && tries < 0) {
        this.setTokenCookie(token, tries--);
      }

      return
    },
    deleteTokenAuth() {
      delete axios.defaults.headers.common['Authorization'];
      document.cookie = 'token=a;SameSite=Lax';
      document.cookie = 'token=a; expires=Thu, 18 Dec 2013 12:00:00 UTC';
    },
    getCompanyLogoFilepath(){
        const getCompany =  axios.get('/api/v1/company/logo/filepath')
            .then( response => {
                if(response.status > 199 || response.status < 300){
                    if(response.data.data){
                        if(response.data.data.path) {
                            var path = response.data.data.path;
                            this.companyLogo = path;
                            localStorage.setItem('logo',  path);
                        }
                  }
                }

            }).catch(error => {
                console.log(error);
            })
    },

    recaptchaCallback(token){
        this.form.recaptcha_token = token;
    },
  },
  validations: {
    form: {
      email: {
        required,
        email,
        minLength: minLength(6),
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20),
      },
    },
  },

  mounted() {
      this.getCompanyLogoFilepath();
      this.headers = config.getHeaders;
      this.isAuthError = !!this.authError;
      document.body.classList.add("auth-body-bg");
      document.body.classList.add("min-height-unset");

      let alertMessage = {};

      if(this.$route.params.verified == 'ZW1haWxfdmVyaWZpY2F0aW9uX3N1Y2Nlc3M=' || this.verified == 'ZW1haWxfdmVyaWZpY2F0aW9uX3N1Y2Nlc3M=') {
        alertMessage = {
            icon: 'success',
            title: 'Parabéns',
            text: 'Seu e-mail foi verificado com sucesso, utilize suas credenciais e aproveite!',
        }
      }
      if(this.$route.params.verified == 'ZW1haWxfdmVyaWZpY2F0aW9uX2Vycm9y' || this.verified == 'ZW1haWxfdmVyaWZpY2F0aW9uX2Vycm9y') {
        alertMessage = {
            icon: 'warning',
            title: 'atenção!',
            text: 'Seu e-mail não pôde ser verificado no momento, entre e tente novamente!',
        }
      }
      if(Object.keys(alertMessage).length > 0) {
        this.$swal
         .fire({
         title: alertMessage.title,
         text: alertMessage.text,
         icon: alertMessage.icon,
         showCancelButton: false,
         confirmButtonColor: "#34c38f",
         confirmButtonText: "Continuar!",
         })
         .then((result) => {
             this.$router.push({ path: "/login"});
         })
      }
  }
};
</script>

<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="d-none d-lg-block col-xl-9">
          <div class="auth-full-bg pt-lg-5 p-4">
            <div class="w-100">
              <div class="bg-overlay"></div>
              <div class="d-flex h-100 flex-column">
                <div class="p-4 mt-auto">
                  <div class="row justify-content-center">
                    <div class="col-lg-7">
                      <div class="text-center">
                        <h4 class="mb-3">
                          <i
                            class="bx bxs-quote-alt-left text-primary h1 align-middle mr-3"
                          ></i
                          ><span class="text-primary">80%</span>+ Em aumento de produtividade
                        </h4>

                            <!-- Info from app -->
                        <div dir="ltr" class="owl-theme">
                          <b-carousel id="carousel-1" :interval="4000">

                            <b-carousel-slide>
                              <p class="font-size-16 mb-4 text-white">
                                "Sistema fantástico com uma tonelada de opções. Economizou diversas horas de trabalho e me ajudou
                               a ter fins de semanas mais tranquilos."
                              </p>
                              <div>
                                <h4 class="font-size-16 text-primary">
                                  Roberto
                                </h4>
                                <p class="font-size-14 mb-0 text-white">Arquiteto de software</p>
                              </div>
                            </b-carousel-slide>


                            <b-carousel-slide>
                              <p class="font-size-16 mb-4 text-white">
                                " Se todos os funcionarios da empresa são tão solícitos quanto a
                                  equipe de suporte, o desenvolvimento será uma experiência agradável.
                                   Vocês são maravilhosos. Mantenham o bom trabalho. "
                              </p>
                              <div>
                                <h4 class="font-size-16 text-primary">
                                  Larissa
                                </h4>
                                <p class="font-size-14 mb-0 text-white">Gerente de projetos</p>
                              </div>
                            </b-carousel-slide>
                          </b-carousel>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-xl-3">
          <div class="auth-full-page-content full-page-wrapper p-md-5 p-4">
            <div class="w-100">
              <div class="d-flex flex-column" style="font-size: 85%;">
                <div class="mb-5 mt-1 mb-md-4 mt-md-0" v-if="companyLogo">
                  <a href="/" class="d-block auth-logo d-flex justify-content-center">
                    <img
                      :src="companyLogo? companyLogo : '/images/dalth-logo.png'"
                      alt=""
                      width="55%"
                      class="auth-logo-dark"
                    />
                    <img
                      :src="companyLogo? companyLogo : '/images/dalth-logo.png'"
                      alt=""
                      width="55%"
                      class="auth-logo-light"
                    />
                  </a>
                </div>
                <div class="my-auto">
                  <div>
                    <h5 class="text-primary">Bem vindo de Volta !</h5>
                    <p class="text-muted">Faça login para continuar no WorkflowPro</p>
                  </div>

                  <div class="mt-4">
                    <b-form
                        class="p-2"
                        method="POST"
                        id="form-login"
                        :action="`${baseUrl}/login`"
                        @submit="formValidate"
                    >
                    <slot />
                      <b-form-group
                        id="fg-email"
                        label="E-mail"
                        label-for="email"
                        class="mb-3"
                        >
                        <b-form-input
                        maxlength="90"
                            id="email"
                            name="email"
                            v-model="form.email"
                            type="text"
                            placeholder="Insira seu email"
                            :class="{ 'is-invalid': submitted && $v.form.email.$error }"
                        ></b-form-input>
                        <div
                            v-if="submitted && $v.form.email.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.form.email.required">Insira um e-mail</span>
                            <span v-else-if="!$v.form.email.email">Insira um e-mail válido</span>
                            <span v-else-if="!$v.form.email.minLength"
                            >O email deve conter no mínimo 6 caracteres</span
                            >
                        </div>
                        </b-form-group>


                      <b-form-group
                    id="fg-password"
                    label="Senha"
                    label-for="password"
                    class="mb-3"
                    >
                    <b-form-input
                        maxlength="20"
                        id="password"
                        v-model="form.password"
                        name="password"
                        type="password"
                        placeholder="Digite a senha"
                        :class="{ 'is-invalid': submitted && $v.form.password.$error }"
                    ></b-form-input>

                    <div
                        v-if="submitted && $v.form.password.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.form.password.required">Insira uma senha</span>
                        <span v-else-if="!$v.form.password.minLength"
                        >A Senha deve conter no mínimo 6 caracteres</span
                        >
                        <span v-else-if="!$v.form.password.maxLength"
                        >A Senha deve conter no máximo 20 caracteres</span
                        >
                    </div>
                    <div class="mt-1">
                        <a href="/password/reset" class="fw-medium text-primary">
                            Esqueceu senha ?
                        </a>
                    </div>
                    </b-form-group>

                    <b-form-group class="mb-3 login-recaptcha">
                        <Recaptcha :sitekey="siteKey" :callback="recaptchaCallback" v-if="showRecaptcha" />
                    </b-form-group>

                      <b-form-checkbox
                        class="form-check"
                        id="customControlInline"
                        name="remember"
                        value="accepted"
                        unchecked-value="not_accepted"
                      >
                        Lembrar
                      </b-form-checkbox>
                      <div class="mt-3 d-grid">
                        <b-button type="submit" variant="primary" class="btn btn-primary btn-block waves-effect waves-light"
                        ><span v-show="!processing">Entrar</span> <span v-show="processing" class="bx bx-loader-alt bx-spin"></span> </b-button>
                      </div>

                    </b-form>
                    <div class="mt-3 text-center">
                      <p>
                        Não possui conta ?

                        <a
                          href="/register"
                          class="fw-medium text-primary"
                        >
                          Cadastre-se agora
                        </a>
                      </p>
                    </div>

                  </div>
                </div>

                <div class="text-center">
                  <p class="mb-0">
                    {{ new Date().getFullYear() }}  © WorkflowPro. Criado por © Dalth Soluções
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container-fluid -->
  </div>
</template>

<style lang="scss" scoped>
::v-deep .carousel-caption {
  position: relative !important;
  right: 0;
  bottom: 1.25rem;
  left: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #495057;
  text-align: center;
}

::v-deep .carousel-indicators li {
  background-color: rgba(85, 110, 230, 0.25);
}

.text-white{
    color: #eee;
}
body{
    min-height: none !important;
}
.full-page-wrapper {
    max-height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    display: block;
    padding: 2rem !important;
}
</style>
