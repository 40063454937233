import axios from 'axios';
import showAlert from "../components/alerts";
import { MASKS } from './masks'

function getToken() {
    let token = document.cookie.split(';').find(index => {
        return index.includes('token=');
    });
    token = token ? token.replace('token=', '') : null;
    if(!token) {
        token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
    }
    return token
}

function getCsrf() {
    let token = document.cookie.split(';').find(index => {
        return index.includes('XSRF-TOKEN=');
    });
    token = token ? token.replace('XSRF-TOKEN=', '') : null;
    return token
}

function getLoginStatus() {
    let status = document.cookie.split(';').find(index => {
        return index.includes('loggedIn=');
    });
    status = status ? status.replace('loggedIn=', '') : null;
    return status === 'dHJ1ZQ==' ? true : false;
}

function getCompanyLogo() {
    let token = document.cookie.split(';').find(index => {
        return index.includes('companylogo=');
    });
    token = token ? token.replace('companylogo=', '') : null;
    return token
}

function setTitle(newTitle = 'WorkflowPro'){
    var title = document.head.querySelector('title').textContent;
        title = title.split(' | ');
        title = newTitle+" | "+title[1];
        document.head.querySelector('title').textContent = title;
}

async function logout(){
    window.location.href = window.location.origin+'/logout'
}

function logoutWithMessage(){
    showAlert(true, 'Sessão expirada');
    setTimeout(()=>{
        logout();
    },200);
}

// axios config
var messageCount = 0;
axios.defaults.withCredentials = true;

// axios config
let token = document.head.querySelector('meta[name="csrf-token"]');
if(token){
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF TOKEN not found');
}
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + getToken();

axios.interceptors.response.use(function (response) { return response;}, function (error) {

    if(error.response.status == 401 || error.response.status == 419){
        var uri = window.location.pathname;
        if(uri.indexOf('login') > -1 || uri.indexOf('password/reset') > -1 || uri.indexOf('register') > -1){
            return Promise.reject(error);
        } else {
            if(messageCount < 1){
                showAlert(true, 'Sessão expirada');
                messageCount++;
                logout();
            } else if(messageCount < 2) {
                messageCount++;
                logout();
            } else if(messageCount < 3) {
                messageCount++;
                logout();
            }
            return Promise.reject(error);
        }
    } else if(error.response.status == 422) {
        showAlert(true, error.response.data.message );
        return Promise.reject(error);
    } else {
        return Promise.reject(error);
    }
  });

export {setTitle};

export default {
    masks: MASKS,
    loggedIn: getLoginStatus(),
    extensions: ['jpg', 'jpeg', 'png', 'gif', 'webp', 'mp4', 'pdf','txt', 'docx', 'xls', 'xlsx'],
    getToken: getToken(),
    getLogo: getCompanyLogo(),
    getHeaders: {
        'content-Type': 'application/json',
        'Accept': 'application/json',
    }

}


