<template>
  <div>
    <i
      class="bx bx-file rem-size-3 d-flex link-cursor cursor-link text-white"
      v-b-modal.modal-aasp-updates
    ></i>
    <b-modal
      lazy
      class="pt-0"
      id="modal-aasp-updates"
      size="xl"
      :title="`Distribuições`"
      scrollable
      title-class="font-18"
      hide-footer
      @hide="clearAll"
      no-close-on-backdrop
      @show="getFolders(workflowId)"
    >
      <List
        v-if="showList"
        :workflow-id="workflowId"
        @select="getUpdates(workflowId, $event), getAutocompleteOptions()"
      />
      <div v-else>
        <div
          v-if="loadAaspUpdates"
          class="d-flex w-100 justify-content-center align-items-center"
          style="height: 70vh"
        >
          <i class="bx bx-loader-alt bx-spin fs-1"></i>
        </div>
        <div
          v-else-if="aaspUpdatesDates.length < 1 && !loadAaspUpdates"
          class="row w-100"
        >
        <b-tabs v-model="tabSelected" @input="getImportsByDay(workflowId, fileName, importCreatedAt, $event)" class="top-tabs sticky-top bg-default" v-if="tabSelected != 0">
             <b-tab title="Não Localizados"></b-tab>
             <b-tab title="Sem fase"></b-tab>
             <b-tab v-for="stage in stagesData" :key="stage.id" :title="stage.option"></b-tab>
         </b-tabs>
         <div
             v-if="loadContent"
             class="d-flex w-100 justify-content-center align-items-center  height-splash"
             >
             <i class="bx bx-loader-alt bx-spin fs-1"></i>
         </div>
          <div class="row justify-content-center align-items-center height-splash" :class="{'d-none': loadContent }">
            <div class="row justify-content-center align-items-center">
              <div class="row justify-content-center align-items-center">
                <div class="col-6 text-center">
                  <h4>Nenhuma atualização encontrada</h4>
                </div>
              </div>
              <div class="row justify-content-center align-items-center">
                <div
                  class="col-2 d-flex justify-content-center align-items-center"
                >
                  <b-button @click.prevent="showList = true" variant="info">
                    <i class="bx bx-arrow-back pe-2"></i> Voltar</b-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
            <!-- AASP updates -->
          <div
            v-for="(aaspDate, aaspDateKey) in aaspUpdatesDates"
            :key="aaspDateKey"
          >
            <div
              class="card-header fw-bold sticky-top bg-default"
              v-if="aaspUpdatesFound[aaspDate].length > 0 || (distributed && aaspUpdatesFound[aaspDate].length == 0)"
            >
              <div class="row">
                <div
                  class="d-flex justify-content-center align-items-center p-1 col-1 back-arrow-container"
                >
                  <a
                    @click.prevent="showList = true"
                    class="bx bx-arrow-back text-decoration-none link-cursor"
                  ></a>
                </div>
                <span
                  class="text-decoration-underline d-flex justify-content-start align-items-center col"
                  >{{ getDateFormat(`${aaspDate} 00:00:00`, false) }}</span
                >
                <span
                  class="text-decoration-underline d-flex justify-content-center align-items-center col"
                  >{{ fileName }}</span
                >
                <div class="d-flex justify-content-end align-items-center col wrapper-btn-complete-width">
                    <div
                      class="d-flex align-items-center wrapper-btn-complete-height"
                      ><span class="pe-2">Distribuídos</span><switches
                      v-model="distributed"
                      :type-bold="false"
                      class="mb-0"
                      :color="!distributed ? 'default' : 'worflow'"
                    ></switches>
                    </div>
                </div>
              </div>
            </div>
            <b-tabs v-model="tabSelected" @input="getImportsByDay(workflowId, fileName, importCreatedAt, $event)" class="top-tabs sticky-top bg-default" v-if="tabSelected != 0">
                <b-tab title="Não Localizados"></b-tab>
                <b-tab title="Sem fase"></b-tab>
                <b-tab v-for="stage in stagesData" :key="stage.id" :title="stage.option"></b-tab>
            </b-tabs>
            <div
                v-if="loadContent"
                class="d-flex w-100 justify-content-center align-items-center  height-splash"
                >
                <i class="bx bx-loader-alt bx-spin fs-1"></i>
            </div>
            <div
              class="card aasp-update-card"
              :class="{
                'text-white-solid': collapseShow == aaspUpdate.id,
                'text-info': aaspUpdate.visualized === false,
                disable: !aaspUpdate.dispatcher_user_id,
                'd-none': loadContent,
              }"
              v-for="(aaspUpdate, aaspUpdateKey) in aaspUpdatesFound[aaspDate]"
              :key="aaspUpdate.id"
            >
              <div class="card-body border rounded">
                <!-- <div class="row"><div class="col-1"><i class="bx bx-check-circle text-success"></i></div></div> -->
                <div
                  class="row mb-1 border-bottom"
                  @click.prevent="toggleCollapse(aaspUpdate)"
                >
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div class="row py-1">
                      <div class="col-4 col-lg-2 col-xl-4">Processo:</div>
                      <div class="col-8 col-lg-10 col-xl-8 text-end">
                        {{
                          aaspUpdate.process
                            .replaceAll("-", "")
                            .replaceAll(".", "")
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div class="row py-1">
                      <div class="col-4 col-lg-2 col-xl-4">Pasta:</div>
                      <div class="col-8 col-lg-10 col-xl-8 text-end">
                        {{ getFolder(aaspUpdate.task) }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div class="row py-1">
                      <div class="col-4 col-lg-2 col-xl-4">Publicação:</div>
                      <div class="col-8 col-lg-10 col-xl-8 text-end">
                        <div class="row">
                          <div class="col">
                            <span>
                              {{ aaspUpdate.date }}
                            </span>
                          </div>
                          <div
                            class="col-3"
                            v-show="aaspUpdate.dispatcher_user_id"
                          >
                            <i
                              class="bx bx-check-circle text-success fs-3 checked-alert"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="row pt-2"
                  @click.prevent="toggleCollapse(aaspUpdate)"
                >
                  <div class="col-12">
                    <h5 class="text-decoration-underline fw-bold">Intimação</h5>
                    <p style="text-align: justify">
                      {{ aaspUpdate.subpoena }}
                    </p>
                  </div>
                </div>
                <div
                  v-show="aaspUpdate.dispatcher_comment"
                  class="row pt-2"
                  @click.prevent="toggleCollapse(aaspUpdate)"
                >
                  <div class="col-12">
                    <div class="row p-0 m-0">
                        <div class="col d-flex justify-content-start p-0"><h5 class="text-decoration-underline fw-bold">Observação </h5>
                            <span class="ps-1" v-if="aaspUpdate.dispatcher_user">- {{ aaspUpdate.dispatcher_user.full_name }} {{ getDateFormat(aaspUpdate.dispatcher_comment_updated_at, true) }}</span>
                        </div>
                    </div>

                    <p style="text-align: justify">
                      {{ aaspUpdate.dispatcher_comment }}
                    </p>
                  </div>
                </div>
                <b-collapse
                  :visible="collapseShow == aaspUpdate.id"
                  class="mt-2 aasp-updates-collapse"
                >
                  <transition name="fade">
                    <div>
                        <b-tabs v-model="actionType">
                            <b-tab title="Distribuições">
                                <div
                                  class="row pt-2 border-top"
                                  v-if="collapseShow == aaspUpdate.id && actionType == 0"
                                >
                                  <div class="col-12" v-if="aaspUpdate.checklist">
                                    <div
                                      class="row border-bottom"
                                      v-for="(
                                        checklistOption, checklistOptionKey
                                      ) in aaspUpdate.checklist.options"
                                      :key="checklistOptionKey"
                                    >
                                      <!-- Title -->
                                      <b-form-group
                                        label="Item"
                                        label-for="aasp-update-deadline"
                                        class="my-2 col-12 col-sm-12 col-md-12 col-lg col-xl-8"
                                        @submit.stop.prevent
                                      >
                                      <Autocomplete
                                        ref="autocomplete-type"
                                        :id="`aasp-update-comment-${aaspUpdate.id}-${checklistOptionKey}`"
                                        :class="[
                                          `aasp-update-comment-${aaspUpdate.id}-${checklistOptionKey}`, 'pt-1'
                                        ]"
                                        :default-value="checklistOption.option"
                                        :search="autocompleteOptions"
                                        placeholder="Distribuição"
                                        aria-label="Distribuição"
                                        base-class="autocomplete-search"
                                        @submit="autocompleteSubmited($event, checklistOption, 'option')"
                                        @input="autocompleteSubmitedUpdate($event, checklistOption, 'option')"
                                        @focus="
                                          removeInvalid(
                                            `aasp-update-comment-${aaspUpdate.id}-${checklistOptionKey}`
                                          )
                                        "
                                        ></Autocomplete>
                                      </b-form-group>

                                      <!-- Deadline -->
                                      <b-form-group
                                        label-align="center"
                                        label="Prazo"
                                        :label-for="`aasp-update-deadline-${aaspUpdate.id}-${checklistOptionKey}`"
                                        class="my-2 col-4 col-sm-4 col-lg-2 col-md-4 col-xl-1"
                                        @submit.stop.prevent
                                      >
                                        <div
                                          class="row"
                                          :class="[
                                            `aasp-update-deadline-${aaspUpdate.id}-${checklistOptionKey}`,
                                          ]"
                                        >
                                          <DatePicker
                                            style="position: absolute; left: 0; top: 0"
                                            :id="`aasp-update-deadline-${aaspUpdate.id}-${checklistOptionKey}`"
                                            class="cell-input cell-edit p-0 ps-1 justify-content-center aasp-updates-datepicker"
                                            :ref="`datepicker_${checklistOptionKey}`"
                                            v-model.lazy="checklistOption.deadline"
                                            :lang="datePikerLang"
                                            type="date"
                                            format="DD/MM/YYYY"
                                            value-type="YYYY-MM-DD 00:00:00"
                                            :range="false"
                                            :default-value="new Date()"
                                            :disabled-date="disableDateRange"
                                            placeholder="00/00/0000 00:00:00"
                                            :open="
                                              opendatepicker[
                                                `aasp-update-deadline-${aaspUpdate.id}-${checklistOptionKey}`
                                              ]
                                            "
                                            @blur="
                                              toggleShowDatepicker(
                                                `aasp-update-deadline-${aaspUpdate.id}-${checklistOptionKey}`
                                              )
                                            "
                                            @change="
                                              removeInvalid(
                                                `aasp-update-deadline-${aaspUpdate.id}-${checklistOptionKey}`
                                              )
                                            "
                                            :showHour="false"
                                            :showMinute="false"
                                            :showSecond="false"
                                          >
                                            <template #footer>
                                              <div class="row justify-content-between pt-1">
                                                <div
                                                  class="col-3 d-flex justify-content-start align-items-center"
                                                >
                                                  <a
                                                    href="#"
                                                    class="btn btn-outline-info"
                                                    @click.prevent="
                                                      (checklistOption.deadline = ''),
                                                        toggleShowDatepicker(
                                                          `aasp-update-deadline-${aaspUpdate.id}-${checklistOptionKey}`
                                                        )
                                                    "
                                                    >Limpar</a
                                                  >
                                                </div>

                                                <div
                                                  class="col-3 d-flex justify-content-center align-items-center"
                                                >
                                                  <a
                                                    href="#"
                                                    class="btn btn-success"
                                                    @click.prevent="
                                                      toggleShowDatepicker(
                                                        `aasp-update-deadline-${aaspUpdate.id}-${checklistOptionKey}`
                                                      )
                                                    "
                                                    >Ok</a
                                                  >
                                                </div>
                                              </div>
                                            </template>
                                          </DatePicker>
                                          <!-- <div class="col-1">
                                                                                </div> -->
                                          <div
                                            class="col-12 aasp-updates-line-height d-flex align-items-center text-center"
                                          >
                                            <span
                                              @click="
                                                toggleShowDatepicker(
                                                  `aasp-update-deadline-${aaspUpdate.id}-${checklistOptionKey}`
                                                )
                                              "
                                              class="d-flex form-control-plaintext text-white-solid p-0 align-items-center justify-content-center"
                                            >
                                              <span
                                                v-if="checklistOption.deadline"
                                                class="font-size-12"
                                                >{{
                                                  getDateFormat(
                                                    checklistOption.deadline,
                                                    false
                                                  )
                                                }}</span
                                              >
                                              <i
                                                v-else
                                                class="bx bx-calendar fs-4 link-cursor"
                                              ></i>
                                            </span>
                                          </div>
                                        </div>
                                      </b-form-group>

                                      <!-- Responsible -->
                                      <b-form-group
                                        label-align="center"
                                        label="Responsável"
                                        label-for="filter-stage-select"
                                        class="my-2 col-4 col-sm-4 col-md-4 col-lg-2 col-xl-2"
                                        @submit.stop.prevent
                                      >
                                        <!-- col-12 col-md-12 col-lg-4 col-md-4 -->
                                        <div
                                          class="row justify-content-center aasp-updates-line-height align-items-center"
                                          :class="[
                                            `aasp-update-responsible-${aaspUpdate.id}-${checklistOptionKey}`,
                                          ]"
                                        >
                                          <b-dropdown
                                            lazy
                                            variant="btn-edit text-decoration-none fs-6 text-secondary p-0 justify-content-center d-flex"
                                            class="col-12"
                                            menu-class="aasp-update-responsible-dropdown"
                                            @show="
                                              removeInvalid(
                                                `aasp-update-responsible-${aaspUpdate.id}-${checklistOptionKey}`
                                              ),
                                                setResponsibleSeleceted(
                                                  checklistOption.alert_user
                                                )
                                            "
                                          >
                                            <template #button-content>
                                              <span>
                                                <div
                                                  v-if="checklistOption.alert_user"
                                                  class="size-avatar aasp-update-responsible-avatar justify-content-center d-flex"
                                                  v-b-tooltip.hover.html="
                                                    contactTooltipData(
                                                      checklistOption.alert_user
                                                    )
                                                  "
                                                >
                                                  <b-img-lazy
                                                    class="rounded-circle avatar-circle avatar-xs delete-on-hover"
                                                    :src="checklistOption.alert_user.avatar"
                                                    v-if="checklistOption.alert_user.avatar"
                                                  />
                                                  <b-avatar
                                                    variant="info"
                                                    :text="
                                                      getInitials(
                                                        checklistOption.alert_user
                                                      )
                                                    "
                                                    class="mr-3 bg-light text-white size-avatar rounded-circle avatar-xs avatar-circle delete-on-hover font-size-10"
                                                    v-else
                                                  />
                                                </div>
                                                <i
                                                  v-else
                                                  class="bx bx-user text-white text-center"
                                                ></i>
                                              </span>
                                            </template>
                                            <b-dropdown-form
                                              style="width: 20vw; z-index: 1"
                                              @submit.stop.prevent
                                              @keypress.enter.stop.prevent
                                              class="bg-default dropdown-search-input"
                                            >
                                              <b-form-group>
                                                <b-form-input
                                                  class="form-input"
                                                  placeholder="Buscar"
                                                  v-model="searchData"
                                                  debounce="200"
                                                ></b-form-input>
                                              </b-form-group>
                                            </b-dropdown-form>
                                            <div
                                              v-show="loadSearchResponsible"
                                              class="w-100 justify-content-center align-items-center load-height"
                                            >
                                              <i
                                                class="bx bx-loader-alt bx-spin font-size-20"
                                              ></i>
                                            </div>
                                            <div v-if="!loadSearchResponsible">
                                              <div
                                                v-for="responsible in responsiblesList"
                                                :key="responsible.id"
                                                :value="responsible.id"
                                              >
                                                <b-dropdown-item
                                                  @click="
                                                    selectResponsible(
                                                      checklistOption,
                                                      responsible
                                                    )
                                                  "
                                                >
                                                  <b-img-lazy
                                                    class="rounded-circle avatar-circle avatar-xs mr-1 delete-on-hover"
                                                    :src="responsible.avatar"
                                                    v-if="responsible.avatar"
                                                  />
                                                  <b-avatar
                                                    variant="info"
                                                    :text="getInitials(responsible)"
                                                    class="mr-3 bg-light text-white size-avatar"
                                                    v-else
                                                  ></b-avatar>
                                                  {{ responsible.full_name }}
                                                </b-dropdown-item>
                                              </div>
                                            </div>
                                          </b-dropdown>
                                        </div>
                                      </b-form-group>

                                      <!-- Add/Remove -->
                                      <b-form-group
                                        label-align="center"
                                        label="_"
                                        class="aasp-update-add-remove-form-group my-2 col-4 col-sm-4 col-md-4 col-lg-2 col-xl-1 text-center px-0"
                                        @submit.stop.prevent
                                      >
                                        <div class="px-0 d-flex col-12">
                                          <i
                                            class="mdi mdi-close-thick text-danger fs-5 aasp-update-padding-close-button d-flex align-items-center"
                                            @click.prevent="
                                              deleteOption(
                                                aaspUpdate,
                                                checklistOptionKey,
                                                checklistOption
                                              )
                                            "
                                          ></i>
                                          <i
                                            class="bx bx-plus-medical d-flex align-items-center"
                                            @click.prevent="addOption(aaspUpdate)"
                                            v-if="
                                              checklistOptionKey ==
                                              aaspUpdate.checklist.options.length - 1
                                            "
                                          ></i>
                                        </div>
                                      </b-form-group>
                                    </div>
                                  </div>
                                  <div class="col-12">
                                    <div class="row justify-content-between">
                                      <!-- Stage -->
                                      <b-form-group
                                        label="Fase"
                                        label-for="filter-stage-select"
                                        class="my-2 col-12 col-md-6 col-lg-4 col-md-4"
                                        @submit.stop.prevent
                                      >
                                        <b-form-select
                                          id="filter-stage-select"
                                          class="form-select"
                                          v-model.lazy="form.stage"
                                          @change="
                                            removeInvalid(
                                              `aasp-update-stage-${aaspUpdate.id}`
                                            )
                                          "
                                          :class="[`aasp-update-stage-${aaspUpdate.id}`]"
                                        >
                                          <template #first>
                                            <b-form-select-option :value="null"
                                              >Selecione</b-form-select-option
                                            >
                                          </template>
                                          <b-form-select-option
                                            v-for="stage in stageList"
                                            :key="stage.id"
                                            :value="stage.id"
                                            >{{ stage.option }}</b-form-select-option
                                          >
                                        </b-form-select>
                                      </b-form-group>
                                      <!-- Save -->
                                      <b-form-group
                                        label="_"
                                        class="aasp-update-add-remove-form-group my-2 col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2"
                                        @submit.stop.prevent
                                      >
                                        <b-button
                                          id="aasp-update-save"
                                          variant="success w-100"
                                          @click.prevent="save(aaspUpdate)"
                                          >Salvar</b-button
                                        >
                                      </b-form-group>
                                    </div>
                                  </div>
                                </div>
                            </b-tab>
                            <b-tab title="Observação">
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <b-textarea v-model="form.dispatcherComment" lazy rows="4" placeholder="Digite uma observação"></b-textarea>
                                    </div>
                                </div>
                                <!-- Save -->
                                <div class="row justify-content-end align-items-center">
                                    <b-form-group
                                            label="_"
                                            class="aasp-update-add-remove-form-group my-2 col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2"
                                            @submit.stop.prevent
                                          >
                                            <b-button
                                              id="aasp-update-save"
                                              variant="success w-100"
                                              @click.prevent="save(aaspUpdate, false, true)"
                                              >Salvar</b-button
                                            >
                                    </b-form-group>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                  </transition>
                </b-collapse>
              </div>
              <div v-if="aaspUpdateKey === aaspUpdatesFound[aaspDate].length - 1"  v-observe-visibility="{
                    callback: (isVisible, entry) => getMoreImportsByDay(isVisible, entry),
                    once: true
                }" class="row"></div>
            </div>
          </div>

          <!-- Not found processes -->
          <div class="aasp-update-not-found">
            <div class="card-header fw-bold sticky-top bg-default"  v-if="tabSelected == 0">
              <div class="row">
                <div
                  class="d-flex justify-content-center align-items-center p-1 col-1 back-arrow-container"
                >
                  <a
                    @click.prevent="showList = true"
                    class="bx bx-arrow-back text-decoration-none link-cursor"
                  ></a>
                </div>
                <span
                  class="text-decoration-underline d-flex justify-content-start align-items-center col fw-bold"
                  >Não Localizados</span
                >
                <span
                  class="text-decoration-underline d-flex justify-content-end align-items-center col"
                  >{{ fileName }}</span
                >
              </div>
            </div>
            <b-tabs v-model="tabSelected" @input="getImportsByDay(workflowId, fileName, importCreatedAt, $event)" class="top-tabs sticky-top bg-default" v-if="tabSelected == 0">
                <b-tab title="Não Localizados"></b-tab>
                <b-tab title="Sem fase"></b-tab>
                <b-tab v-for="stage in stagesData" :key="stage.id" :title="stage.option"></b-tab>
            </b-tabs>
            <div
                v-if="loadContent"
                class="d-flex w-100 justify-content-center align-items-center  height-splash"
                >
                <i class="bx bx-loader-alt bx-spin fs-1"></i>
            </div>
            <div
              class="card aasp-update-card"
              :class="{
                'text-white-solid': collapseShow == aaspUpdate.id,
                disable: !aaspUpdate.deleted_at && aaspUpdate.integrated,
                'd-none': loadContent
              }"
              v-for="(aaspUpdate, aaspUpdateKey) in aaspUpdatesNotFound"
              :key="aaspUpdate.id"
            >
              <div class="card-body border rounded">
                <div class="row mb-1 border-bottom">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div class="row py-1">
                      <div class="col-4 col-lg-2 col-xl-4">Processo:</div>
                      <div class="col-8 col-lg-10 col-xl-8 text-end">
                        {{
                          aaspUpdate.process
                            ? aaspUpdate.process
                                .replaceAll("-", "")
                                .replaceAll(".", "")
                            : ""
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div class="row py-1">
                      <div class="col-4 col-lg-2 col-xl-4">Pasta:</div>
                      <div
                        class="col-8 col-lg-10 col-xl-8 text-end d-flex align-items-center"
                      >
                        <span v-if="getFolder(aaspUpdate.task) != 0">
                          {{ getFolder(aaspUpdate.task) }}</span
                        >
                        <span v-else>
                          <b-dropdown
                            lazy
                            toggle-class="text-decoration-none"
                            menu-class="dropdown-dimensions"
                            variant="btn text-decoration-none text-secondary p-0"
                            no-caret
                          >
                            <template #button-content>
                              <span class="text-info"
                                >Selecione uma pasta<i
                                  class="bx bx-chevron-down"
                                ></i
                              ></span>
                            </template>
                            <b-dropdown-form
                              style="width: 20vw; z-index: 1"
                              @submit.stop.prevent
                              @keypress.enter.stop.prevent
                              class="bg-default dropdown-search-input"
                            >
                              <b-form-group>
                                <b-form-input
                                  v-mask="'########'"
                                  class="form-input"
                                  placeholder="Buscar"
                                  v-model="searchFolderData"
                                  debounce="200"
                                ></b-form-input>
                              </b-form-group>
                            </b-dropdown-form>
                            <b-dropdown-item
                              @click.prevent="
                                folderAssociateToAaspUpdate(folder, aaspUpdate)
                              "
                              v-for="folder in folderOptions"
                              :key="folder.id"
                              >{{
                                `${folder.value} - ${folder.process}`
                              }}</b-dropdown-item
                            >
                          </b-dropdown>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                    <div class="row py-1">
                      <div class="col-4 col-lg-2 col-xl-4">Publicação:</div>
                      <div class="col-8 col-lg-10 col-xl-8 text-end">
                        <div class="row">
                          <div class="col">
                            <span>
                              {{ aaspUpdate.date }}
                            </span>
                          </div>
                          <div
                            class="col-3"
                            v-show="aaspUpdate.dispatcher_user_id"
                          >
                            <i
                              class="bx bx-check-circle text-success fs-3 checked-alert"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row pt-2">
                  <div class="col-12">
                    <h5 class="text-decoration-underline fw-bold">Intimação</h5>
                    <p style="text-align: justify">
                      {{ aaspUpdate.subpoena }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="aaspUpdateKey === aaspUpdatesNotFound.length - 1"  v-observe-visibility="{
                    callback: (isVisible, entry) => getMoreImportsByDay(isVisible, entry),
                    once: true
                }" class="row"></div>
            </div>
          </div>
        </div>
        <div v-if="loadMoreContent" class="row text-center"><i class="bx bx-loader-alt bx-spin fs-1"></i></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import showAlert from "../../components/alerts";
import Switches from "vue-switches";
import List from "./list";
export default {
  components: { List, Switches },
  data() {
    return {
      autocompleteDataOptions: [],
      company: {
        default_limit_date: 180
      },
      tabSelected: 0,
      actionType: 0,
      foldersData: [],
      stagesData: [],
      stageDataDictionary: {},
      fileName: "",
      importCreatedAt: "",
      distributed: false,
      loadAaspUpdates: false,
      showList: true,
      workflowColumns: [],
      folderColumns: [],
      checklistColumn: {},
      stageColumn: {},
      aaspUpdates: {},
      collapseShow: 0,
      opendatepicker: {},
      searchData: "",
      searchFolderData: "",
      withDistribuition: true,
      loadSearchResponsible: false,
      loadContent: false,
      loadMoreContent: false,
      responsibles: [],
      selectedResponsibles: 0,
      notFoundProcessForm: {
        aaspImportId: 0,
        folder: 0,
      },
      form: {
        dispatcherComment: '',
        aaspImportId: 0,
        checklistId: 0,
        taskId: 0,
        stage: null,
        checklistOptions: [],
      },
      page: {
        perPage: 10,
        total: 3,
        baseUrl: "",
        next: ""
      },
    };
  },
  props: ["workflowId"],
  computed: {
    date() {
      return this.getDateFormat(new Date(), false);
    },
    stageList() {
      if (!this.stageColumn) {
        return [];
      }

      return this.stageColumn.options;
    },
    responsiblesList() {
      return this.responsibles.filter(
        (responsible) =>
          this.selectedResponsibles != responsible.id &&
          responsible.user_type_id != 3 &&
          responsible.full_name
            .toLowerCase()
            .indexOf(this.searchData.toLowerCase()) != -1
      );
    },
    aaspUpdatesDates() {
      return Object.keys(this.aaspUpdates);
    },
    aaspUpdatesFound() {
      let dates = Object.keys(this.aaspUpdates);
      let data = {};
      dates.forEach((date) => {
        data[date] = this.aaspUpdates[date].filter((aasp) => {
            if(this.distributed) {
                return !aasp.deleted_at && aasp.integrated && aasp.dispatcher_user_id;
            }
          return !aasp.deleted_at && aasp.integrated;
        });
      });
      return data;
    },
    aaspUpdatesNotFound() {
      let dates = Object.keys(this.aaspUpdates);
      let data = [];
      dates.forEach((date) => {
        data = [
          ...data,
          ...this.aaspUpdates[date].filter((aasp) => {
            return aasp.deleted_at && !aasp.integrated;
          }),
        ];
      });
      return data;
    },
    stageOptions() {
      if (!this.stageColumn) {
        return [];
      }

      return this.stageColumn.options;
    },
    folderOptions() {
      return this.foldersData.filter((folder) => {
        return (
          folder.value
            .toLocaleLowerCase()
            .indexOf(this.searchFolderData.toLocaleLowerCase()) != -1
        );
      });
    },
  },
  methods: {
    folderAssociateToAaspUpdate(folder, aaspUpdate) {
      this.$swal
        .fire({
          title: "Confirma a associação?",
          text: `Associar a atualização à pasta "${folder.value}"?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Sim, associar!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            let data = {
              aasp_import_id: aaspUpdate.id,
              workflow_id: aaspUpdate.workflow_id,
              task_id: folder.task_id,
              folder: folder.value,
            };

            axios
              .post(
                window.location.origin +
                  "/api/v1/plugins/aasp/associate/folder",
                data
              )
              .then((response) => {
                if (!response.data.error) {
                  let aasp = response.data.data;

                  let dates = Object.keys(this.aaspUpdates);
                  dates.forEach((date) => {
                    this.aaspUpdates[date] = this.aaspUpdates[date].map(
                      (aaspUpdate) => {
                        if (aasp.id == aaspUpdate.id) {
                          aasp.visualized = false;
                          return aasp;
                        }
                        return aaspUpdate;
                      }
                    );
                  });
                }
              })
              .catch((error) => {
                console.log("folderAssociateToAaspUpdate error :>> ", error);
              });
          }
        });
    },
    clearAll() {
      this.fileName = "";
      this.showList = true;
      this.workflowColumns = [];
      this.folderColumns = [];
      this.checklistColumn = {};
      this.stageColumn = {};
      this.aaspUpdates = {};
      this.collapseShow = 0;
      this.opendatepicker = {};
      this.searchData = "";
      this.loadSearchResponsible = false;
      this.responsibles = [];
      this.selectedResponsibles = 0;
      this.form = {
        aaspImportId: 0,
        checklistId: 0,
        taskId: 0,
        stage: null,
        checklistOptions: [],
      };
    },
    disableDateRange(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let range_time = 0;
      if(this.company && this.company.default_limit_date) {
          range_time = this.company.default_limit_date * 24 * 3600 * 1000
          return date > new Date(today.getTime() + range_time);
      }

      return false;
    },
    setResponsibleSeleceted(responsible) {
      if (responsible) {
        this.selectedResponsibles = responsible.id;
        return;
      }

      this.selectedResponsibles = 0;
      return;
    },
    selectResponsible(checklistOption, responsible) {
      this.selectedResponsibles = responsible.id;
      this.$set(checklistOption, "alert_user", responsible);
      this.$set(checklistOption, "alert_user_id", responsible.id);
    },
    removeResponsible(responsible) {
      this.$set(checklistOption, "alert_user", null);
    },
    toggleCollapse(aaspUpdate) {
      if (this.collapseShow == aaspUpdate.id) {
        this.collapseShow = 0;
        this.form.aaspImportId = 0;
        this.form.dispatcherComment = '';
        return;
    }

      this.form.dispatcherComment = aaspUpdate.dispatcher_comment;
      if (aaspUpdate.checklist) {
        this.selectedResponsibles = 0;
        this.form.checklistId = aaspUpdate.checklist.id;
        if (aaspUpdate.checklist.options.length < 1) {
          aaspUpdate.checklist.options.push({
            option: "",
            checked: false,
            alert_user_id: null,
            deadline: "",
            defaultOption: true,
          });
        }
      }

      if (aaspUpdate.task) {
        this.form.stage = this.getStage(aaspUpdate.task);
        this.form.taskId = aaspUpdate.task.id;
        this.form.aaspImportId = aaspUpdate.id;
      }

      if(aaspUpdate.dispatcher_comment) {
        this.actionType = 1
      } else {
        this.actionType = 0
      }

      this.collapseShow = aaspUpdate.id;
      this.$set(aaspUpdate, "visualized", true);
      return;
    },
    toggleShowDatepicker(elementId) {
      let element = window.document.querySelector(`#${elementId}`);
      if (!element) return;

      if (element.classList.value.indexOf("d-flex") != -1) {
        this.$set(this.opendatepicker, elementId, false);
        element.classList.add("d-none");
        element.classList.remove("d-flex");
      } else {
        this.$set(this.opendatepicker, elementId, true);
        element.classList.remove("d-none");
        element.classList.add("d-flex");
      }
    },
    addOption: function (aaspUpdate) {
      if (aaspUpdate.checklist) {
        aaspUpdate.checklist.options.push({
          option: "",
          checked: false,
          alert_user_id: null,
          deadline: "",
          defaultOption: true,
        });
      }
    },
    deleteOption: async function (aaspUpdate, index, checklistOption) {
      let callback = true;
      let checklistOptionId = null;

      if (typeof checklistOption.id != "undefined") {
        checklistOptionId = checklistOption.id;
        callback = await this.deleteAlert(index);
      }

      if (callback) {
        let backup = aaspUpdate.checklist.options[index];

        aaspUpdate.checklist.options.splice(index, 1);

        if (aaspUpdate.checklist.options.length < 1) {
          this.addOption(aaspUpdate);
        }

        if (checklistOptionId) {
          let delete_result = await this.deleteChecklistOption(
            checklistOptionId
          );
          if (!delete_result) {
            aaspUpdate.checklist.options.splice(index, 0, backup);
          }
        }
      }
    },
    async deleteAlert(index) {
      let result = await this.$swal.fire({
        title: `Excluir item ${index + 1}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar",
      });

      return result.value ? result.value : false;
    },
    showModal() {
      this.$root.$emit("bv::show::modal", "modal-aasp-updates");
    },
    getWorkflowResponsibles(workflowId) {
      axios
        .get(
          window.location.origin +
            "/api/v1/workflow/task/responsibles/" +
            workflowId
        )
        .then((response) => {
          this.responsibles = response.data.data;
        })
        .catch((error) => {
          console.log("get responsibles error :>> ", error);
        });
    },
    getCompany() {
      axios
        .get(`${window.location.origin}/api/v1/company`)
        .then((response) => {
          this.company = response.data.data;
        })
        .catch((error) => {
          console.log("get responsibles error :>> ", error);
        });
    },
    getFolders(workflowId, search = "") {
      axios
        .post(window.location.origin + "/api/v1/plugins/aasp/show/folders", {
          workflow_id: workflowId,
          search,
        })
        .then((response) => {
          this.foldersData = response.data.data;
        })
        .catch((error) => {
          console.log("get folders error :>> ", error);
        });
    },
    async getStages(workflowId) {
        try {
            let response = await axios
                .post(window.location.origin + "/api/v1/plugins/aasp/show/stages", {
                workflow_id: workflowId
                });
            if(!response.data.error) {
                this.stagesData = response.data.data;
                this.stageDataDictionary = {};
                this.stagesData.forEach((stage, key)=>{
                  this.stageDataDictionary[key+2] = stage.id;
                });
            }

        } catch(error) {
            console.log("get stages error :>> ", error);
        }
    },
    getFolder(task) {
      if (!task) return 0;

      let column_value = task.column_values.find(
        (column_value) =>
          column_value.workflow_column_type_id == 13 &&
          column_value.plugin_id == 1 &&
          column_value.tag == "aasp_updates_folder"
      );

      if (!column_value) {
        return 0;
      }

      return parseInt(column_value.number);
    },
    getStage(task) {
      if (!task) return 0;

      let column_value = task.column_values.find(
        (column_value) =>
          column_value.workflow_column_type_id == 8 &&
          column_value.plugin_id == 1 &&
          column_value.tag == "aasp_updates_stage"
      );
      if (!column_value) {
        return 0;
      }

      return column_value.value;
    },
    getMoreImportsByDay(isVisible, entry) {
        if(!isVisible) return;

        let localStage = this.tabSelected;
        if(this.tabSelected == 1){
            localStage = null;
        }

        if(typeof this.stageDataDictionary[this.tabSelected] != "undefined") {
            localStage = this.stageDataDictionary[this.tabSelected];
        }

        if(this.page.next) {
            this.loadMoreContent = true;
            axios
            .post(this.page.next, {
              workflow_id: this.workflowId,
              file: this.fileName,
              created_at: this.importCreatedAt,
              stage: localStage,
              per_page: this.page.perPage
            })
            .then((response) => {
              if (!response.data.error) {
                let page = response.data.data
                this.page.next = page.next_page_url;

                // order by folder
                let data = {};
                let folders = [];
                let aaspUpdates = page.data;
                let aaspUpdateDates = Object.keys(aaspUpdates);

                aaspUpdateDates.forEach((date) => {
                    data[date] = [];
                    aaspUpdates[date].forEach((aaspUpdate) => {
                        folders.push({
                        id: aaspUpdate.id,
                        value: this.getFolder(aaspUpdate.task),
                        key: date,
                        });
                    });
                });

                folders = folders.sort((a, b) => {
                    if (a.value == b.value) {
                        return 0;
                    }

                    return a.value < b.value ? -1 : 1;
                });

                folders.forEach((folder) => {
                    data[folder.key].push(
                        aaspUpdates[folder.key].find(
                        (aaspUpdate) => aaspUpdate.id === folder.id
                        )
                    );
                });

                aaspUpdateDates.forEach((date) => {
                    if(typeof this.aaspUpdates[date] == "undefined") {
                        this.aaspUpdates[date] = [];
                    }
                    if(data[date]) {
                        let content = [...this.aaspUpdates[date], ...data[date]];
                        this.aaspUpdates[date] =  [...new Map(content.map(item => [item['id'], item])).values()];
                    }

                });

                setTimeout(() => {
                this.loadMoreContent = false;
                }, 500);
              }
            })
            .catch((error) => {
             setTimeout(() => {
                this.loadMoreContent = false;
              }, 500);
              console.log("get more imports by day error :>> ", error);
            });
        }
    },
    getImportsByDay(workflowId, file = "", createdAt = "", stage = null) {
        this.loadContent = true;
        let localStage = 0;
        if(stage == 1){
            localStage = null;
        }
        if(typeof this.stageDataDictionary[stage] != "undefined") {
            localStage = this.stageDataDictionary[stage];
        }

      axios
        .post(window.location.origin + "/api/v1/plugins/aasp/show/day", {
          workflow_id: workflowId,
          file,
          created_at: createdAt,
          stage: localStage,
          per_page: this.page.perPage
        })
        .then((response) => {
          if (!response.data.error) {
            let page = response.data.data
            this.page.next = page.next_page_url;

            // order by folder
            let data = {};
            let folders = [];
            let aaspUpdates = page.data;
            let aaspUpdateDates = Object.keys(aaspUpdates);
            aaspUpdateDates.forEach((date) => {
              data[date] = [];
              aaspUpdates[date].forEach((aaspUpdate) => {
                folders.push({
                  id: aaspUpdate.id,
                  value: this.getFolder(aaspUpdate.task),
                  key: date,
                });
              });
            });

            folders = folders.sort((a, b) => {
              if (a.value == b.value) {
                return 0;
              }

              return a.value < b.value ? -1 : 1;
            });

            folders.forEach((folder) => {
              data[folder.key].push(
                aaspUpdates[folder.key].find(
                  (aaspUpdate) => aaspUpdate.id === folder.id
                )
              );
            });

            this.aaspUpdates = data;

            setTimeout(() => {
              this.loadAaspUpdates = false;
            }, 500);
          }
          this.loadContent = false;
        })
        .catch((error) => {
          this.loadContent = false;
          console.log("get imports by day error :>> ", error);
        });
    },
    getColumnsList(workflowId) {
      const response = axios
        .get(
          `${window.location.origin}/api/v1/workflow/column/list/${workflowId}`
        )
        .then((response) => {
          if (!response.data.error) {
            let columns = response.data.data;
            this.workflowColumns = columns;
            this.stageColumn = columns.find(
              (column) =>
                column.plugin_id == 1 &&
                column.workflow_column_type_id == 8 &&
                column.tag == "aasp_updates_stage"
            );
            this.folderColumn = columns.find(
              (column) =>
                column.plugin_id == 1 &&
                column.workflow_column_type_id == 13 &&
                column.tag == "aasp_updates_folder"
            );

            if (!this.stageColumn) {
              showAlert(true, 'Coluna "Fase" não encontrada');
            }

            if (!this.folderColumn) {
              showAlert(true, 'Coluna "Pasta" não encontrada');
            }

            if (!this.stageColumn || !this.folderColumn) {
              this.$root.$emit("bv::hide::modal", "modal-aasp-updates");
            }
          }
        })
        .catch((error) => {
          this.loadAaspUpdates = false;
        });
    },
    getInitials(user) {
      return `${user.name ? user.name[0] : ""}${
        user.last_name ? user.last_name[0] : ""
      }`;
    },
    async getUpdates(workflowId, data = null) {
      this.loadAaspUpdates = true;
      this.showList = false;
      this.fileName = data ? data.file : "";
      this.importCreatedAt = data ? data.importDate : "";
      let vue = this;

      await this.getStages(workflowId)
      await Promise.all([
        vue.getWorkflowResponsibles(workflowId),
        vue.getColumnsList(workflowId),
        vue.getImportsByDay(workflowId, this.fileName, this.importCreatedAt, this.tabSelected),
      ]);
    },
    removeInvalid(elementClass) {
      let element = window.document.querySelector(`.${elementClass}`);
      if (!element) return;

      element.classList.remove("invalid");
    },
    save(aaspUpdate, withDelete = false, observation = false) {
      this.form.checklistId = aaspUpdate.workflow_cheklist_id;
      this.form.taskId = aaspUpdate.workflow_task_id;
      let errorsCount = 0;

      this.form.checklistOptions = [];
      if(!observation) {
        // Validate
        if (!this.form.checklistId || !aaspUpdate.checklist) {
            showAlert(true, "Checklist não encontrado");
            return;
        }

        if (!this.form.taskId) {
            showAlert(true, "Tarefa não encontrada");
            return;
        }

        this.form.checklistOptions = aaspUpdate.checklist.options;

        aaspUpdate.checklist.options.forEach(
        (checklistOption, checklistOptionKey) => {
            if (!checklistOption.option && !withDelete) {
            let element = window.document.querySelector(
                `.aasp-update-comment-${aaspUpdate.id}-${checklistOptionKey}`
            );

            element.classList.add("invalid");
                showAlert(true, "Digite a descrição do item");
                errorsCount++;
                return;
            }

            let date = checklistOption.deadline.split("-");
            if (date.length < 3 && !withDelete) {
                let element = window.document.querySelector(
                    `.aasp-update-deadline-${aaspUpdate.id}-${checklistOptionKey}`
                );

                element.classList.add("invalid");
                showAlert(true, "Selecione um prazo");
                errorsCount++;
                return;
            }

            if (!checklistOption.alert_user && !withDelete) {
            let element = window.document.querySelector(
                `.aasp-update-responsible-${aaspUpdate.id}-${checklistOptionKey}`
            );

            element.classList.add("invalid");
            showAlert(true, "Selecione um responsável");
            errorsCount++;
            return;
            }
        });

        if (errorsCount > 0) {
            return;
        }

        if (!this.form.stage) {
        let element = window.document.querySelector(
            `.aasp-update-stage-${aaspUpdate.id}`
        );
        element.classList.add("invalid");
        showAlert(true, "Fase não encontrada");
        errorsCount++;
        return;
        }
      }

      // Post Form
      let data = {
        task_id: this.form.taskId,
        aasp_import_id: this.form.aaspImportId,
        checklist_id: this.form.checklistId,
        checklist_options: this.form.checklistOptions,
        stage: this.form.stage,
        dispatcher_comment: this.form.dispatcherComment
      };

      axios
        .post(
          window.location.origin + "/api/v1/plugins/aasp/dispatch/update",
          data
        )
        .then((response) => {
          if (!response.data.error) {
            let data = response.data.data;
            let responseKeys = Object.keys(data);
            responseKeys.forEach((key) => {
              this.$set(aaspUpdate, key, data[key]);
            });

            this.collapseShow = 0;
          }
        })
        .catch((error) => {
          console.log("get aasp updates error :>> ", error);
        });
    },
    async deleteChecklistOption(checklistOptionId) {
      if (!checklistOptionId) return;
      try {
        let result = await axios.delete(
          `${window.location.origin}/api/v1/workflow/columns/checklist/option/${checklistOptionId}`
        );
        if (result.data.error) {
          return false;
        }
        return true;
      } catch (error) {
        return false;
      }
    },
    autocompleteSubmited(input, element, key) {
        this.$set(element, key, input);
    },
    autocompleteSubmitedUpdate(event, element, key) {
        setTimeout(()=>{
            let data  = event.target.value
            this.$set(element, key, data);
        }, 900)
    },
    autocompleteOptions(input, element, key){
        if (typeof input == 'undefined') { return [] }
        if (input.length < 1) { return [] }
        return this.autocompleteDataOptions.filter(dataOption => {
            return dataOption.toLowerCase()
            .indexOf(input.toLowerCase()) != -1
        })
    },
    getAutocompleteOptions(){
        const getTypes = axios.post('/api/v1/plugins/aasp/show/autocomplete/options')
        .then( response => {
            this.autocompleteDataOptions = response.data.data;
        }).catch(error => {
            if(error.response) {
                console.log('error getAutocompleteOptions:>> ', error);
            }
        });
      },
  },
  mounted() {
    this.getCompany();
    this.$watch(
      () => this.searchFolderData,
      (att, old) => {
        this.getFolders(this.workflowId, att);
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.aasp-update-card:hover {
  color: white;
  cursor: pointer;
}
button {
  outline-style: none;
}
</style>
<style>
.aasp-update-responsible-dropdown {
  right: 5px !important;
  left: unset !important;
}
.aasp-update-responsible-avatar:hover {
  margin-top: -5px;
  transition: all 0.5s ease;
}
#modal-aasp-updates * .modal-body {
  padding-top: 0;
}
.aasp-updates-collapse {
  transition: all 0.5s ease;
}
.aasp-updates-datepicker,
.aasp-updates-datepicker * .mx-input,
.aasp-updates-datepicker * .mx-input-wrapper {
  width: 1px !important;
  height: 1px;
  min-width: 0px;
  max-width: 0px;
  min-height: 0px;
  max-height: 0px;
  visibility: hidden;
}
.aasp-updates-line-height {
  height: 36.13px;
}
.aasp-update-add-remove-form-group legend,
.aasp-update-add-remove-form-group label {
  visibility: hidden;
}
.aasp-update-padding-close-button {
  padding-right: 40px !important;
}
.checked-alert {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.back-arrow-container {
  width: 20px;
}
.dropdown-dimensions {
  overflow-y: auto;
  height: 45vh;
}
.top-tabs {
    top: 40px;
}
.height-splash {
    height: calc(100vh - 200px);
}
.checkbox-distribuition-height {
    height: 37px;
}
.checkbox-distribuition-height * .custom-control.custom-checkbox {
    padding-left: 0.25rem !important;
    padding-bottom: 3px !important;
}
.wrapper-btn-complete-width {
    width: 90px;
}
.wrapper-btn-complete-height {
    height: 31px;
}
.wrapper-btn-complete-height .vue-switcher div {
    height: 8px;
    width: 30px;
}

.wrapper-btn-complete-height .vue-switcher div::after {
    height: 15px;
    width: 15px;
    margin-left: -15px;
}

</style>
