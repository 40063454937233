<template>
    <b-dropdown size="sm" ref="dropdown-workspaces"
        variant="outline-secondary form-select d-flex align-items-center justify-content-start ps-2" class="w-100"
        menu-class="workspace-menu">
        <template #button-content>
            <span v-if="(!global && (Object.keys(workspaceData).length > 0 && workspaceData.id != 0)) || (global && (Object.keys(workspace).length > 0 && workspace.id != 0))" class="d-flex overflow-text font-icon">
                <i class="d-flex align-items-center rem-size-3" :class="`${workspaceData.icon ? workspaceData.icon : workspace.icon} me-2`"
                    :style="`color: ${workspaceData.color ? workspaceData.color : workspace.color}`"></i>
                <span class="d-flex align-items-center rem-size-2">{{ workspaceData.name ? workspaceData.name : workspace.name  }}</span>
            </span>
            <span class="d-flex overflow-text font-icon" v-else><i class="d-flex align-items-center rem-size-2 mdi mdi-timetable me-2"></i> <span class="d-flex align-items-center rem-size-2">Workspaces</span></span>
        </template>
        <b-dropdown-form @submit.stop.prevent @keypress.enter.stop.prevent class="search-sticky-top">
            <b-form-group @submit.stop.prevent @keypress.enter.stop.prevent>
                <b-input-group>
                    <b-input-group-prepend>
                        <span class="form-control border-end-0 d-flex justify-content-center align-items-center h-100"><i
                            class="bx bx-search-alt-2 rem-size-3"></i></span>
                    </b-input-group-prepend>
                    <b-form-input v-model="workspacesSearch" class="form-input border-start-0 rem-size-2" placeholder="Buscar"
                        debounce="200"></b-form-input>
                </b-input-group>
            </b-form-group>
        </b-dropdown-form>

        <div class="dropdown-item d-flex justify-content-between" role="menuitem" v-if="global && ((workspaceData.id != -1) || (workspace.id != -1))">
            <a href="#" target="_self" class="col-11 p-1 d-flex align-items-center" @click="selectLocalWorkspace(workspaceData.id == -1 || workspace.id == -1? {}: {id:-1})">
                <i class="bx bxs-star star-color me-2 rem-size-3 " style="color: #f7ca4c"></i>
                <span class="text-white rem-size-2">Favoritos</span>
            </a>
            <div class="col-1 d-flex justify-content-end link-cursor clear-workspace" @click="selectLocalWorkspace({})" v-if="workspaceData.id == -1 || workspace.id == -1">
                <i class="bx bx-check text-success d-flex justify-content-center align-items-center"></i>
            </div>
        </div>
        <div v-if="(!global && (Object.keys(workspaceData).length > 0 && workspaceData.id != 0)) &&  workspaceData.id != -1"
            class="dropdown-item d-flex justify-content-between border-bottom" role="menuitem" style="background-color: #32394e;">
            <a href="#" target="_self" class="col-11 p-1 d-flex align-items-center">
                <i :class="`${workspaceData.icon} me-2 rem-size-3`" :style="`color: ${workspaceData.color}`"></i>
                <span class="text-white rem-size-2">{{ workspaceData.name }}</span>
            </a>
            <div class="col-1 d-flex justify-content-end link-cursor clear-workspace" @click="selectLocalWorkspace({})">
                <i class="bx bx-check text-success d-flex justify-content-center align-items-center"></i>
            </div>
            <hr>
        </div>
        <div v-else-if="(global && (Object.keys(workspace).length > 0 && workspace.id != 0)) && workspace.id != -1"
            class="dropdown-item d-flex justify-content-between border-bottom" role="menuitem" style="background-color: #32394e;">
            <a href="#" target="_self" class="col-11 p-1 d-flex align-items-center">
                <i :class="`${workspace.icon} me-2 rem-size-3`" :style="`color: ${workspace.color}`"></i>
                <span class="text-white rem-size-2">{{ workspace.name }}</span>
            </a>
            <div class="col-1 d-flex justify-content-end link-cursor clear-workspace" @click="selectLocalWorkspace({})">
                <i class="bx bx-check text-success d-flex justify-content-center align-items-center"></i>
            </div>
            <hr>
        </div>
        <div class="dropdown-item d-flex justify-content-between overflow-text" role="menuitem"  v-b-tooltip.hover="localWorkspace.name"
            v-for="localWorkspace in filtredWorkspaces" :key="localWorkspace.id" :class="{'d-none': (!global && (localWorkspace.id == workspaceData.id)) || (global && (localWorkspace.id == workspace.id)) }">
            <a href="#" target="_self" class="col-11 p-1 d-flex align-items-center"
                @click="selectLocalWorkspace((global && (workspace.id == localWorkspace.id)) || (!global && (workspaceData.id == localWorkspace.id))
                    ? {}
                    : localWorkspace
                )">
                <i :class="`${localWorkspace.icon} me-2 rem-size-3`" :style="`color: ${localWorkspace.color}`"></i>
                <span class="text-white rem-size-2 overflow-text">{{ localWorkspace.name }}</span>
            </a>
            <div class="col-1 d-flex justify-content-end link-cursor clear-workspace" @click="selectLocalWorkspace({})"
                v-if="(global && (workspace.id == localWorkspace.id)) || (!global && (workspaceData.id == localWorkspace.id))">
                <i class="bx bx-check text-success d-flex justify-content-center align-items-center"></i>
            </div>
        </div>

    </b-dropdown>
</template>

<script>
import { workspacesComputed, workspacesMethods } from '../state/helpers';
export default {
    data() {
        return {
            workspacesSearch: '',
            workspaceData: {},
            rightValue: 'unset',
        }
    },
    props: {
        global:{
            type: Boolean,
            default: true,
        },
        id: {
            default: 0,
        },
        right:{
            default: 0
        },
    },
    computed: {
        ...workspacesComputed,
        filtredWorkspaces() {
            let idDiference = true;
            return this.workspaces.filter((workspace)=>{
                idDiference = ((this.workspace.id != workspace.id)) || ((this.workspaceData.id != workspace.id))
                return workspace.name.toLowerCase().indexOf(this.workspacesSearch.toLowerCase()) != -1 && idDiference;
            });
    },
    },
    methods: {
        ...workspacesMethods,
        selectLocalWorkspace(workspace) {
            let favorite = {
                    id: -1,
                    name: 'Favoritos',
                    color: '#f7ca4c',
                    icon: 'bx bxs-star',
                    workflows: [],
                    created_at: ''
                };

            if (this.$refs['dropdown-workspaces']) {
                this.$refs['dropdown-workspaces'].hide(true);
            }
            if (this.global) {
                if(favorite.id == workspace.id) {
                    this.selectWorkspace(favorite);
                } else {
                    this.selectWorkspace(workspace);
                }
            } else {
                this.workspaceData = workspace;
            }
            this.workspacesSearch = '';
            this.$emit('select', workspace);
        },
        setPosition() {
            var element = this.$el.querySelector('.workspace-menu')
            if (this.right !== 0) {
                let margin = isNaN(this.right)? this.right: `${this.right}px`;
                element.style.right = margin;
                this.rightValue = margin;
            }
        }
    },
    mounted() {
        // if(this.global) {
        //     this.workspaceData = this.workspace;
        // } else {
        if(!this.global) {
            let clearWorkspace = {
                    id: 0,
                    name: '',
                    color: '',
                    icon: '',
                    workflows: [],
                    created_at: ''
                };

            if(this.id > 0) {
                this.workspaceData = this.workspaces.find((workspace)=> workspace.id == this.id);
                if(!this.workspaceData) {
                    this.workspaceData = clearWorkspace
                }

            } else {
                this.workspaceData = clearWorkspace
            }
        }

        this.setPosition();
        this.$nextTick(()=>{
            var element = this.$el.querySelector('.workspace-menu')
            element.addEventListener('change', ()=> {
                this.setPosition();
            });
        });

        this.$watch(()=> this.selectedWorkspaceId, (att, old)=>{

        })
    }
}
</script>

<style lang="css" scoped>

.overflow-text {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.clear-workspace {
    opacity: 0.9;
    font-size: 20px
}
.clear-workspace:hover {
    opacity: 1;
    font-size: 23px;
    -webkit-transition: opacity 0.2s, font-size 0.2s;
    -moz-transition: opacity 0.2s, font-size 0.2s;
    -o-transition: opacity 0.2s, font-size 0.2s;
    transition: opacity 0.2s, font-size 0.2s;
}
.font-icon {
    /* font-size: 1vw; */
    /* font-size: 0.8vw; */
}
.search-sticky-top {
    position: sticky;
    top: 0;
    background-color: #2a3042;
    z-index: 6;
    padding-top: 10px;
    padding-bottom: 10px;
}
</style>
<style>

.workspace-menu.dropdown-menu.show {
    /* top: 26vh !important; */
    position: fixed !important;
    left: unset !important;
    top: unset !important;
    margin-top: 36.31px;
    height: 53vh;
    overflow-y: auto;
    overflow-x: clip;
    width: 300px;
}
.workspace-menu::-webkit-scrollbar, .workspace-menu::-webkit-scrollbar-button, .workspace-menu::-webkit-scrollbar-track {
    background-color: #2a3042;
    width: 0px;
}
</style>
