<template>

  <b-dropdown
    @show="setZIndex($event, true), dropdownStatus($event, true)"
    @hide="setZIndex($event), dropdownStatus($event, false)"
    lazy
    :disabled="disabled"
    variant="btn-edit text-decoration-none fs-6 text-secondary p-0"
    :menu-class="[
      { 'right-0': dropright },
      'dropdown-person-width dropdown-bottom scr-right',
    ]"
    right
  >
    <template #button-content>
      <span>
        <i class="fas fa-plus-circle text-white"></i>
      </span>
    </template>

    <!-- <li
      role="presentation"
      class="ps-3 pe-3 border-bottom pb-1"
      v-if="selectedsData.length > 0"
    >
      <span class="sub">Selecionados</span>
      <div class="avatar-group">
        <div
          v-b-tooltip.html="contactTooltipData(user)"
          :id="`user-${user.id}`"
          v-for="(user, keyPerson) in selectedsData"
          :key="keyPerson"
          @click="
            (e) =>
              !disabled && remove(columnValue, user)
          "
        >
          <div class="avatar-group-item">
            <a href="javascript: void(0);" class="d-inline-block">
                <b-img-lazy class="rounded-circle avatar-circle delete-on-hover" :src="user.avatar" v-if="user.avatar"/>
              <b-avatar
                variant="info"
                :text="getInitials(user)"
                class="mr-3 bg-light text-white size-avatar rounded-circle avatar-circle delete-on-hover font-size-10"
                v-else
              />
            </a>
          </div>
        </div>
      </div>
    </li> -->
    <b-dropdown-form
      style="z-index: 1"
      @submit.stop.prevent
      @keypress.enter.stop.prevent
      class="bg-default dropdown-search-input w-100"
    >
      <b-form-group>
        <b-form-input
          class="form-input"
          placeholder="Buscar"
          v-model="searchData"
          debounce="200"
        ></b-form-input>
      </b-form-group>
    </b-dropdown-form>
    <div v-show="loadSearchResponsiblesList" class="w-100 justify-content-center align-items-center load-height" >
        <i class="bx bx-loader-alt bx-spin font-size-20"></i>
    </div>
    <div v-if="!loadSearchResponsiblesList && dropdownActive">
        <div
          v-for="(user, userKey) in dataFiltererd"
          :key="userKey"
          :value="user.id"
        >
          <b-dropdown-item @click="save(columnValue, user)">
            <b-img-lazy class="rounded-circle avatar-circle delete-on-hover" :src="user.avatar" v-if="user.avatar"/>
            <b-avatar
              variant="info"
              :text="`${user.name ? user.name[0] : ''}${
                user.last_name ? user.last_name[0] : ''
              }`"
              class="mr-3 bg-light text-white avatar-circle font-size-10"
              v-else
            ></b-avatar>
            <span class="rem-size-2">
            {{
              (user.name ? user.name + " " : "") +
              (user.last_name ? user.last_name : "")
            }}
            </span>
          </b-dropdown-item>
        </div>
    </div>
  </b-dropdown>
</template>

<script>
import { usersOptionsComputed, usersOptionsMethods } from "../../../state/helpers";
export default {
  data() {
    return {
      searchData: "",
      selectedsData: [],
      dropdownActive: false,
    };
  },
  props: {
    columnValue: {
      default: {},
    },
    selecteds: {
      default: [],
    },
    dropright: {
      default: false,
    },
    disabled: {
      default: false,
    },
    personType: {
      default: "",
    },
  },
  computed: {
    ...usersOptionsComputed,
    dataFiltererd() {
      if (!this.dropdownActive) {
        return [];
      }

      let name = "";
      return this.searchResponsiblesList.filter((data) => {
        let type = true;
        if (this.personType) {
          type = data.type == this.personType;
        }

        var keys = Object.keys(data);
        var response = false;
        keys.forEach((keyObject) => {
          if (
            typeof data[keyObject] === "string" ||
            data[keyObject] instanceof String
          ) {
            name = data["name"] + " " + data["last_name"];
            if (
              data[keyObject]
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .indexOf(
                  this.searchData
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ) != -1 ||
              name
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .indexOf(
                  this.searchData
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ) != -1
            ) {
              response = true;
            }
          } else {
            if (data[keyObject] == this.searchData.toLowerCase()) {
              response = true;
            }
          }
        });

        return type && response;
      });
    },
  },
  methods: {
    ...usersOptionsMethods,
    search: function search(data) {
      clearTimeout(search.timeout);

      search.timeout = setTimeout(() => {

        this.getSearchResponsibles({
            workflowId: this.columnValue.workflow_id,
            search: data
        });
      }, 300);
    },
    getInitials(person) {
      if (person.corporate_name) {
        let names = person.corporate_name.split(" ");
        if (names.length > 1) {
          return `${names[0] ? names[0][0] : ""}${names[1] ? names[1][0] : ""}`;
        }
      }

      return `${person.name ? person.name[0] : ""}${
        person.last_name ? person.last_name[0] : ""
      }`;
    },
    save(columnValue, user) {
      this.$emit("save", columnValue, user );
    },
    remove(columnValue, user) {
      this.selectedsData = this.selectedsData.filter((item)=> item.id != user.id);
      this.$emit("remove", user, columnValue.id);
    },
    dropdownStatus(event, active = false) {
        this.search('');
        this.dropdownActive = active
        let base = this.$el.closest('.base');
        if(!base) return
        let element = base.querySelector('.add-person-area');

        if(active && element) {
            element.classList.add('d-flex')
        }

        if(!active && element) {
            element.classList.remove('d-flex')
        }

    }
  },
  beforeMount() {
    this.selectedsData = this.selecteds;
  },
  mounted() {
    this.$watch(
      () => this.searchData,
      (att, old) => {
        this.search(att);
      }
    );
  },
  beforeDestroy() {
    this.searchData = "";
    this.selectedsData = [];
    this.dropdownActive = false;
    this.$el.parentNode.removeChild(this.$el);
  },
};
</script>



<style lang="css" scoped>
.size-avatar {
  width: 32px;
  height: 32px;
}
.dropdown-search-input {
  width: 15vw;
  position: sticky;
  top: 0;
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.bg-default {
  background-color: #2a3042;
}
</style>
<style>
.dropdown-bottom::-webkit-scrollbar-track {
  background: #2a3042 !important;
}

.dropdown-bottom::-webkit-scrollbar-thumb {
  background: #eee !important;
}

.dropdown-bottom {
  bottom: 0 !important;
  height: 38vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scr-right::-webkit-scrollbar {
  width: 6px !important;
}

.scr-right::-webkit-scrollbar-track {
  background: transparent;
  color: black !important;
  border-radius: 10px !important;
}

.scr-right::-webkit-scrollbar-thumb:hover {
  background: black !important;
  opacity: 0.6 !important;
  border-radius: 10px !important;
}

.scr-right::-webkit-scrollbar-thumb {
  background: #c3cbe4 !important;
  opacity: 0.5 !important;
  padding-left: 5px !important;
  border-radius: 10px !important;
}

.bg-default-base {
  background-color: #222736;
}

.dropdown-person-width {
  width: 300px;
}
</style>
