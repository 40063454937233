export default function getPageObject(value) {
    let values = value;
    return {
        current_page: values.current_page,
        first_page_url: values.first_page_url,
        from: values.from,
        last_page: values.last_page,
        last_page_url: values.last_page_url,
        links: values.links,
        next_page_url: values.next_page_url,
        path: values.path,
        prev_page_url: values.prev_page_url,
        per_page: values.per_page,
        to: values.to,
        total: values.total,
    }
}

