<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card" :class="{ 'm-0': workflowId }">
          <div class="card-body" :class="{ 'pt-0': workflowId }">
            <b-form :action="submitUrl" method="POST" @submit="formValidate">
              <div class="row">
                <!-- name -->
                <b-form-group
                  for="name"
                  class="col-form-label col-lg-12"
                  label="Nome"
                >
                  <b-input
                    id="name"
                    name="name"
                    type="text"
                    class="form-control font-size-12"
                    size="sm"
                    :class="{ 'is-invalid': submitted && $v.form.name.$error }"
                    v-model="form.name"
                    placeholder="Insira o nome do workflow..."
                  ></b-input>
                  <div
                    v-if="submitted && $v.form.name.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.name.required"
                      >Insira o nome do workflow</span
                    >
                    <span v-else-if="!$v.form.name.minLength"
                      >O nome deve conter no mínimo 3 caracteres</span
                    >
                    <span v-else-if="!$v.form.name.maxLength"
                      >O nome deve conter no máximo 45 caracteres</span
                    >
                  </div>
                </b-form-group>
              </div>
              <div class="row">
                <!-- Workspace -->
                <b-form-group
                  for="workspace"
                  class="col-form-label col-lg-4 col-12"
                  label="Workspace"
                  v-if="false"
                >
                  <WorkspaceMenuDropdown
                    @select="setWorkspaceId"
                    :global="false"
                    :id="form.workspace_id"
                    v-if="!loading"
                  />
                  <b-button
                    variant="outline-secondary form-select d-flex align-items-center justify-content-start ps-2"
                    size="sm"
                    disabled
                    v-else
                  >
                    <span class="d-flex font-icon"
                      ><i class="mdi mdi-timetable me-2"></i>
                      <span>Workspaces</span></span
                    >
                  </b-button>
                </b-form-group>

                <!-- Files -->
                <b-form-group
                  for="files"
                  class="col-form-label col-lg-6 col-12"
                  label="Arquivo de apoio"
                  @click="openUploadFile"
                >
                  <b-button
                    variant="light"
                    class="w-lg d-flex align-items-center justify-content-center col-12"
                    size="sm"
                    @click="openUploadFile"
                  >
                    <i class="mdi mdi-upload font-size-13"></i>
                    <span
                      class="overflow-text file-btn-text-size font-size-12"
                      >{{ btnUpload }}</span
                    >
                  </b-button>
                  <input
                    type="file"
                    class="d-none"
                    ref="input-files"
                    name="files[]"
                    id="files"
                    @change="uploadFiles"
                    @load="uploadFiles"
                  />
                </b-form-group>

                <!-- Period -->
                <b-form-group
                  for="periods"
                  class="col-form-label col-lg-6 col-12"
                  label="Filtro período"
                >
                  <b-form-select
                    v-model="form.period"
                    class="form-select col-12 font-size-12"
                    size="sm"
                    id="periods"
                  >
                    <b-form-select-option
                      v-for="(period, periodKey) in periods"
                      :key="periodKey"
                      :value="period.value"
                      >{{ period.label }}
                      {{ period.label == 1 ? "dia" : "dias" }}
                    </b-form-select-option>
                  </b-form-select>
                  <div
                    v-if="submitted && $v.form.period.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.period.required"
                      >Insira um periodo padrão ao workflow</span
                    >
                  </div>
                </b-form-group>

                <!-- task limit -->
                <!-- <b-form-group
                  for="task-limit"
                  class="col-form-label col-lg-6 col-12"
                  label="Limite de linhas"
                >
                  <b-form-select
                    v-model="form.taskLimit"
                    class="form-select col-12"
                    id="task-limit"
                  >
                    <b-form-select-option
                      v-for="(limit, limitKey) in taskLimits"
                      :key="limitKey"
                      :value="limit.value"
                      >{{ limit.label }}
                    </b-form-select-option>
                  </b-form-select>
                  <div
                    v-if="submitted && $v.form.taskLimit.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.taskLimit.required"
                      >Insira um limite de linhas para o workflow</span
                    >
                  </div>
                </b-form-group> -->
              </div>

              <!-- <div class="row"> -->
              <!-- Files -->
              <!-- <b-form-group
                  for="files"
                  class="col-form-label col-lg-12 col-12"
                  label="Arquivo de apoio"
                  @click="openUploadFile"
                >
                  <b-button
                    variant="light"
                    class="w-lg d-flex align-items-center justify-content-center col-12"
                    @click="openUploadFile"
                  >
                    <i class="mdi mdi-upload font-size-13"></i>
                    <span class="overflow-text file-btn-text-size">{{
                      btnUpload
                    }}</span>
                  </b-button>
                  <input
                    type="file"
                    class="d-none"
                    ref="input-files"
                    name="files[]"
                    id="files"
                    @change="uploadFiles"
                    @load="uploadFiles"
                  />
                </b-form-group> -->
              <!-- </div> -->

              <div class="row">
                <b-form-group
                  for="users-select"
                  class="col-form-label col-lg-12 col-12"
                  label="Usuários"
                >
                  <multiselect
                    @select="addWorkflowUser($event, true)"
                    @remove="addWorkflowUser($event, false)"
                    v-model.lazy="form.users"
                    :options="availableUsers"
                    :loading="usersLoad.search"
                    id="users-select"
                    label="full_name"
                    track-by="full_name"
                    placeholder="Adicionar usuário"
                    open-direction="bottom"
                    deselect-label="Remover"
                    select-label="Selecionar"
                    :reset-after="false"
                    :multiple="true"
                    :searchable="true"
                    :internal-search="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :options-limit="20"
                    :limit="9000"
                    :limit-text="limitText"
                    :show-no-results="true"
                    :hide-selected="false"
                    :preserve-search="false"
                    @search-change="getUsers({ search: $event, workflowId: 0 })"
                    class="multiselect-sm"
                  >
                    <template slot="option" slot-scope="props">
                      <div
                        class="avatar-group-item"
                        v-b-tooltip="props.option.full_name"
                      >
                        <img
                          v-if="props.option.avatar"
                          :src="props.option.avatar"
                          class="rounded-circle avatar-xs"
                          alt
                        />
                        <b-avatar
                          variant="info"
                          :text="`${
                            props.option.name ? props.option.name[0] : ''
                          }${
                            props.option.last_name
                              ? props.option.last_name[0]
                              : ''
                          }`"
                          class="mr-3 bg-light text-white avatar-circle font-size-10"
                          v-else
                        ></b-avatar>
                        <span class="ps-2">{{ props.option.full_name }}</span>
                      </div>
                    </template>
                    <span slot="noResult">Não encontrado.</span>
                    <span slot="noOptions">Ninguem por aqui.</span>
                  </multiselect>
                </b-form-group>
              </div>

              <div class="row">
                <b-form-group
                  for="description"
                  class="col-form-label col-lg-12"
                  label="Descrição"
                >
                  <textarea
                    id="description"
                    class="form-control"
                    size="sm"
                    rows="3"
                    :class="{
                      'is-invalid': submitted && $v.form.description.$error,
                    }"
                    v-model="form.description"
                    placeholder="Insira uma breve descrição..."
                  ></textarea>
                  <div
                    v-if="submitted && $v.form.description.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.description.required"
                      >Insira uma descrição ao workflow</span
                    >
                    <span v-else-if="!$v.form.description.minLength"
                      >A descrição deve conter no mínimo 5 caracteres</span
                    >
                    <span v-else-if="!$v.form.description.maxLength"
                      >A descrição deve conter no máximo 500 caracteres</span
                    >
                  </div>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  for="block"
                  class="col-form-label col-lg-12"
                  label="Ações"
                >
                  <div class="row">
                    <div
                      class="col-2 mt-n2"
                      v-b-tooltip.top="
                        'Bloquear troca de posição entre colunas'
                      "
                    >
                      <switches
                        v-model="form.block_columns"
                        :type-bold="false"
                        class="mb-0 switch-sm"
                        label="Bloquear colunas"
                        :color="!form.block_columns ? 'default' : 'worflow'"
                        text-enabled
                      ></switches>
                    </div>
                    <div
                      class="col-2 mt-n2"
                      v-b-tooltip.top="'Bloquear troca de posição entre linhas'"
                    >
                      <switches
                        v-model="form.block_tasks"
                        :type-bold="false"
                        class="mb-0 switch-sm"
                        :color="!form.block_tasks ? 'default' : 'worflow'"
                        label="Bloquear linhas"
                      ></switches>
                    </div>
                    <div
                      class="col-2 mt-n2 pe-0"
                      v-b-tooltip.top="
                        'Perguntar ao trocar posição entre grupos'
                      "
                    >
                      <switches
                        v-model="form.ask_group"
                        :type-bold="false"
                        class="mb-0 switch-sm"
                        :color="!form.ask_group ? 'default' : 'worflow'"
                        label="Perguntar ao trocar G."
                      ></switches>
                    </div>
                    <div
                      class="col-2 mt-n2 pe-0"
                      v-b-tooltip.top="
                        'Adicionar logs à exportação'
                      "
                    >
                      <switches
                        v-model="form.export_log"
                        :type-bold="false"
                        class="mb-0 switch-sm"
                        :color="!form.export_log ? 'default' : 'worflow'"
                        label="Exportar com logs"
                      ></switches>
                    </div>
                  </div>
                </b-form-group>
              </div>
              <div class="row mb-4">
                <b-form-group
                  for="block"
                  class="col-form-label col-12 col-md-3"
                  label="Produtividade"
                >
                  <b-form-select
                    v-model="form.metric_column_id"
                    class="form-select col-12 col-md-3 font-size-12"
                    size="sm"
                    id="metric_column_id"
                  >
                    <template #first>
                      <b-form-select-option :value="null"
                        >Métrica</b-form-select-option
                      >
                    </template>
                    <b-form-select-option
                      v-for="(column, columnKey) in metricColumns"
                      :key="columnKey"
                      :value="column.id"
                      >{{ column.name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  for="block"
                  class="col-form-label col-12 col-md-3 hide-label"
                  label="Mínimo"
                >
                  <b-input
                    id="metric_min"
                    type="number"
                    size="sm"
                    placeholder="Min"
                    class="form-control col-12 col-md-3 font-size-12"
                    v-model="form.metric_min"
                  />
                </b-form-group>
                <b-form-group
                  for="block"
                  class="col-form-label col-12 col-md-3 hide-label"
                  label="Médio"
                >
                  <b-input
                    id="metric_avg"
                    type="number"
                    size="sm"
                    placeholder="Med"
                    class="form-control col-12 col-md-3 font-size-12"
                    v-model="form.metric_avg"
                  />
                </b-form-group>
                <b-form-group
                  for="block"
                  class="col-form-label col-12 col-md-3 hide-label"
                  label="Máximo"
                >
                  <b-input
                    id="metric_max"
                    type="number"
                    size="sm"
                    placeholder="Max"
                    class="form-control col-12 col-md-3 font-size-12"
                    v-model="form.metric_max"
                  />
                </b-form-group>
              </div>
              <div class="row justify-content-start">
                <div
                  class="col-12"
                  :class="{
                    'col-lg-2 col-md-2 col-xl-2': workflowId,
                    'col-lg-3 col-md-3 col-xl-3': !workflowId,
                  }"
                >
                  <b-button
                    type="submit"
                    variant="btn btn-primary"
                    class="btn-block col-12 font-size-12"
                    size="sm"
                    >{{ workflowId ? "Salvar" : "Criar" }}</b-button
                  >
                </div>
                <div
                  class="col-12 col-lg-2 col-md-2 col-xl-2 text-end"
                  v-if="workflowId"
                >
                  <b-dropdown
                    variant=" text-decoration-none btn btn-outline-info font-size-12"
                    size="sm"
                    class="col-12"
                  >
                    <template v-slot:button-content>Duplicar</template>
                    <b-dropdown-item id="new-group" @click="duplicate(false)"
                      >Estrutura</b-dropdown-item
                    >
                    <b-dropdown-item @click="duplicate(true)"
                      >Estrutura + dados</b-dropdown-item
                    >
                  </b-dropdown>
                </div>
                <div
                  class="col-12 col-lg-2 col-md-2 col-xl-2 text-end"
                  v-if="workflowId"
                >
                  <a
                    href="#"
                    @click.prevent="
                      (modalTitle = 'Arquivos'),
                        $root.$emit('bv::show::modal', 'modal-edit-files')
                    "
                    class="btn btn-outline-info col-12 btn-block btn-sm font-size-12"
                    >Arquivos</a
                  >
                </div>
                <div
                  class="col-12 col-lg-3 col-md-3 col-xl-3 text-end"
                  v-if="workflowId"
                >
                  <a
                    href="#"
                    @click.prevent="
                      (modalTitle = 'Editar Atalho'),
                        $root.$emit('bv::show::modal', 'modal-shortcuts-edit')
                    "
                    class="btn btn-outline-info col-12 btn-sm font-size-12"
                    >Editar atalhos</a
                  >
                </div>
                <div
                  class="col-12 col-lg-3 col-md-3 col-xl-3 text-end"
                  v-if="workflowId"
                >
                  <a
                    href="#"
                    @click="deleteWorkflow($event, workflowId)"
                    class="btn btn-outline-danger col-12 btn-block btn-sm font-size-12"
                  >
                    Remover workflow</a
                  >
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <!-- Edit task modal -->
    <b-modal
      id="modal-edit-files"
      size="xl"
      :title="modalTitle"
      title-class="font-18"
      hide-footer
    >
      <EditFiles
        :id="workflowId"
        :isChat="false"
        :isAdminArea="true"
        from="edit-workflow"
      />
    </b-modal>

    <!-- Shortcut modal -->
    <b-modal
      id="modal-shortcuts-edit"
      class="scr-right"
      size="lg"
      :title="modalTitle"
      scrollable
      title-class="font-18"
      hide-footer
    >
      <EditWorkflowShortcuts :workflowId="workflowId" />
    </b-modal>
  </div>
</template>

<script>
import config from "@config/config";
import { setTitle } from "@config/config";
import Layout from "@layouts/main";
import PageHeader from "@components/page-header";
import showAlert from "@components/alerts";
import Switches from "vue-switches";
import WorkspaceMenuDropdown from "@components/workspace-menu-dropdown.vue";
import {
  workspacesComputed,
  userComputed,
  userMethods,
  menusMethods,
  columnsComputed,
} from "@state/helpers";
import EditFiles from "./files.vue";
import EditWorkflowShortcuts from "./shortcuts.vue";
import Multiselect from "vue-multiselect";
import axios from "axios";
import { required, minLength, maxLength } from "vuelidate/lib/validators";

/**
 * Workflows-create component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Switches,
    WorkspaceMenuDropdown,
    Multiselect,
    EditFiles,
    EditWorkflowShortcuts,
  },
  data() {
    return {
      modalTitle: "",
      workflow: null,
      submitUrl: window.location.origin + "/api/v1/workflow",
      submitted: true,
      btnUpload: "Enviar",
      loading: false,
      firstCharger: false,
      findUsers: [],
      periods: [
        { value: 0, label: "Todos" },
        { value: 1, label: 1 },
        { value: 7, label: 7 },
        { value: 15, label: 15 },
        { value: 30, label: 30 },
        { value: 60, label: 60 },
      ],
      taskLimits: [
        { value: 0, label: "Todas" },
        { value: 10, label: 10 },
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: 500, label: 500 },
        { value: 1000, label: 1000 },
      ],
      form: {
        name: "",
        description: "",
        period: 0,
        taskLimit: 0,
        files: [],
        workspace_id: 0,
        block_columns: false,
        block_tasks: false,
        ask_group: false,
        export_log: false,
        metric_column_id: null,
        metric_min: null,
        metric_avg: null,
        metric_max: null,
        users: [],
      },

      title: "Novo",
      items: [
        {
          text: "Workflow",
          href: "/",
        },
        {
          text: "Novo",
          active: true,
        },
      ],
    };
  },
  props: {
    workflowId: {
      type: Number,
      default: null,
    },
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(45),
      },
      description: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(500),
      },
      period: {
        // required,
      },
      taskLimit: {
        // required,
      },
    },
  },
  computed: {
    ...workspacesComputed,
    ...userComputed,
    ...columnsComputed,
    availableUsers() {
      let ids = [];
      this.form.users.forEach((user) => {
        ids.push(user.id);
      });
      return this.users.filter((user) => {
        if (ids.indexOf(user.id) != -1) {
          return false;
        }

        return true;
      });
    },
    metricColumns() {
      return this.columns.filter(
        (column) => [7, 12, 13].indexOf(column.workflow_column_type_id) != -1
      );
    },
  },
  methods: {
    ...userMethods,
    ...menusMethods,
    getWorkflow(workflowId) {
      this.loading = true;
      const getWorkflows = axios
        .get(window.location.origin + "/api/v1/workflow/find/" + workflowId)
        .then((response) => {
          var workflow = response.data.data;

          this.form.name = workflow.name;
          this.form.description = workflow.description;
          this.form.block_columns = !workflow.move_columns ? true : false;
          this.form.block_tasks = !workflow.move_tasks ? true : false;
          this.form.ask_group = workflow.ask_group;
          this.form.export_log = workflow.export_log;
          this.form.workspace_id = workflow.workspace_id;
          this.form.taskLimit = workflow.task_limit ? workflow.task_limit : 0;
          this.form.metric_column_id = workflow.metric_column_id ? workflow.metric_column_id : null;
          this.form.metric_min = workflow.metric_min ? workflow.metric_min : null;
          this.form.metric_avg = workflow.metric_avg ? workflow.metric_avg : null;
          this.form.metric_max = workflow.metric_max ? workflow.metric_max : null;
          this.form.period = workflow.filter_period
            ? workflow.filter_period
            : 0;

          this.findUsers = [];
          workflow.users.forEach((content) => {
            if (content.included) {
              this.findUsers.push(content.user_id);
            }
          });
          this.loading = false;
        })
        .catch((error) => {
          if (error.response) {
            this.isRegisterError = true;
            this.axRegError = error.response.data.message;
          }
        });
    },
    fileExplorer(e) {
      document.getElementById("files").click();
    },
    fileOver(e) {
      document.querySelector(".dz-content").classList.add("text-success");
    },
    uploadFiles(e) {
      e.preventDefault();
      this.form.files = e.target.files;
      this.btnUpload = e.target.files[0].name;
    },
    openUploadFile() {
      this.$refs["input-files"].click();
    },
    formValidate(e) {
      this.submitted = true;
      this.$v.$touch();
      e.preventDefault();

      if (!this.$v.$anyError) {
        let formData = new FormData();

        formData.append("workflow_id", this.workflowId ? this.workflowId : 0);
        formData.append("name", this.form.name);
        formData.append("description", this.form.description);
        formData.append("period", this.form.period);
        formData.append("move_columns", !this.form.block_columns ? 1 : "");
        formData.append("move_tasks", !this.form.block_tasks ? 1 : "");
        formData.append("ask_group", this.form.ask_group ? 1 : "");
        formData.append("export_log", this.form.export_log ? 1 : "");
        formData.append("task_limit", this.form.taskLimit);
        formData.append("metric_column_id", this.form.metric_column_id ? this.form.metric_column_id : "" );
        formData.append("metric_min", this.form.metric_min ? this.form.metric_min : "" );
        formData.append("metric_avg", this.form.metric_avg ? this.form.metric_avg : "" );
        formData.append("metric_max", this.form.metric_max ? this.form.metric_max : "" );

        formData.append(
          "files",
          typeof this.form.files[0] !== "undefined" &&
            typeof this.form.files[0] !== undefined
            ? this.form.files[0]
            : 0
        );

        formData.append(
          "workspace_id",
          typeof this.form.workspace_id !== "undefined" &&
            typeof this.form.workspace_id !== undefined
            ? this.form.workspace_id
            : 0
        );

        this.form.users.forEach((user, key) => {
          formData.append(`users[${key}][id]`, user.id);
          formData.append(`users[${key}][name]`, user.name);
          formData.append(`users[${key}][last_name]`, user.last_name);
        });

        let conf = config.getHeaders;
        conf["content-Type"] = "multipart/form-data";

        const newWorkflow = axios
          .post(this.submitUrl, formData, conf)
          .then((response) => {
            if (response.status == 201) {
              showAlert(false, response.data.message);
              if (this.workflowId == null) {
                this.$router.push({
                  path: `/workflow/show/${response.data.data.id}`,
                });
              }
            }
            this.$emit("change", response.data.data);
            this.$root.$emit("bv::hide::modal", "workflow-edit");
            this.getMenu();
          })
          .catch((error) => {
            if (error.response) {
              showAlert(true, error.response.data.message);
            }
          });
      }
    },
    setWorkspaceId(workspace) {
      if (typeof workspace.id != "undefined") {
        this.form.workspace_id = workspace.id;
        return;
      }

      this.form.workspace_id = 0;
    },
    limitText(count) {
      return `E ${count} outros usuários`;
    },
    addWorkflowUser(user, include) {
      var data = {
        user_id: user.id,
        workflow_id: this.workflowId,
        included: include,
      };

      var title = include ? "Confirma a inclusão?" : "Confirma a exclusão?";
      var text = include
        ? user.full_name + " será adicionado ao workflow?"
        : user.full_name + " será removido do workflow?";
      var btnConfirm = include ? "Sim, incluir!" : "Sim, remover!";

      this.$swal
        .fire({
          title: title,
          text: text,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: btnConfirm,
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (!result.value) {
            if (include) {
              this.form.users.pop();
            } else {
              this.form.users.push(user);
            }
          }
        });
    },
    deleteWorkflow(e, workflowId) {
      e.preventDefault();

      this.$swal
        .fire({
          title: "Confirma a exclusão?",
          text: "Seu workflow será movido para lixeira e deletado no periodo de 30 dias",
          input: "email",
          inputAttributes: {
            placeholder: "Digite o nome do workflow",
            refs: "workflow-name-text",
          },
          inputLabel: "Para excluir digite exatamente o nome do workflow",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Sim, deletar!",
          cancelButtonText: "Cancelar",
          inputValidator: (value) => {
            if (!value) {
              return "O campo não pode estar vazio!";
            }
            if (value != this.form.name) {
              return "Nome do workflow incorreto!";
            }
          },
        })
        .then((result) => {
          if (result.value == this.form.name) {
            const deleteWorkflow = axios
              .delete(
                window.location.origin + "/api/v1/workflow/delete/" + workflowId
              )
              .then((response) => {
                if (!response.data.error) {
                  showAlert(false, response.data.message);
                  this.$router.push({ path: `/home` });
                }
              })
              .catch((error) => {
                var error = error.response;
                if (error.status == 401) {
                  showAlert(true, error.data.message);
                }
                console.log(error);
              });
          }
        });
    },
    duplicate(withData = false) {
      this.$swal
        .fire({
          title: "Duplicar workflow?",
          text: "O workflow será duplicado",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Sim, duplicar!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire({
              position: "center-center",
              html: "<i class='bx bx-loader-alt bx-spin' style='font-size: 5rem;'></i><p>Duplicando</p>",
              showCancelButton: false,
              showConfirmButton: false,
              width: 300,
              padding: 50,
            });

            axios
              .get(
                `${window.location.origin}/api/v1/workflow/duplicate/${
                  this.workflowId
                }/${withData ? 1 : 0}`
              )
              .then((response) => {
                if (!response.data.error) {
                  let data = response.data.data;
                  showAlert(false, response.data.message);
                  setTimeout(() => {
                    this.$root.$emit("bv::hide::modal", "workflow_edit");
                    this.$router.push({
                      name: "workflow-show",
                      params: { id: data.id },
                    });
                    this.getMenu();
                  }, 1000);
                }
                this.$swal.close();
              })
              .catch((error) => {
                this.$swal.close();
                var error = error.response;
                showAlert(true, error.data.message);
              });
          }
        });
    },
  },
  created() {
    setTitle(this.title);
    if (this.workflowId) {
      this.getUsers({ search: "", workflowId: 0 });
      this.getWorkflow(this.workflowId);
      this.firstCharger = true;
      this.$watch(
        () => this.usersLoad.search,
        (att, old) => {
          if (this.firstCharger && !att) {
            this.firstCharger = false;
            this.form.users = this.users.filter(
              (user) => this.findUsers.indexOf(user.id) != -1
            );
          }
        }
      );
    } else {
      this.getUsers({ search: "", workflowId: 0 });
    }
  },
  mounted() {
    this.$watch(
      () => this.form.name,
      () => {
        this.form.name = this.form.name.replace(/[^A-zÀ-ú0-9\s]/, "");
      }
    );
  },
};
</script>

<style scoped>
#files {
  outline: none;
  background-color: transparent;
  color: transparent;
}
.file-btn-text-size {
  width: 7vw;
}
.font-icon {
  /* font-size: 1vw; */
  font-size: 0.8vw;
}
</style>
<style>
body {
  min-height: unset !important;
}
</style>
