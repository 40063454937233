var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"mb-3 font-size-12"},[_c('h6',{staticClass:"overflow-text"},[_vm._v(_vm._s(_vm.data.title))]),_vm._v(" "),_c('p',[_vm._v("Descrição: "+_vm._s(_vm.data.description))]),_vm._v(" "),(_vm.data.folder)?_c('p',[_c('span',[_vm._v("Pasta: ")]),_c('span',[_vm._v(_vm._s(_vm.data.folder))])]):_vm._e(),_vm._v(" "),_c('span',[_vm._v("Prazo: ")]),_c('span',{staticClass:"text-danger-scoped",class:{
            'text-warning':
              new Date(_vm.data.deadline).getDate() == _vm.today.getDate() &&
              new Date(_vm.data.deadline).getMonth() == _vm.today.getMonth() &&
              new Date(_vm.data.deadline).getFullYear() == _vm.today.getFullYear() && new Date(_vm.data.deadline) > _vm.today && !_vm.data.complete,
            'text-info': new Date(_vm.data.deadline) > _vm.today && !_vm.data.complete,
            'text-success': _vm.data.complete,
          }},[_vm._v(_vm._s(_vm.getDateFormat(_vm.data.deadline, true)))])])])]),_vm._v(" "),_c('div',{staticClass:"text-end p-3"},[_c('b-button',{attrs:{"variant":"light","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.closeModal.apply(null, arguments)}}},[_vm._v("Sair")]),_vm._v(" "),_c('router-link',{staticClass:"ml-1 btn btn-success btn-sm",attrs:{"to":{ path: _vm.data.redirect }}},[_vm._v("Visualizar\n    ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }