<template>
  <div>
    <b-form-group
      :label="label"
      label-for="folder_select"
      :label-class="{'d-none': !withLabel}"
    >
      <multiselect
        id="folder_select"
        v-model="localData"
        :options="foldersData"
        track-by="id"
        :custom-label="customLabel"
        deselect-label="Remover"
        select-label="Selecionar"
        :reset-after="false"
        :multiple="false"
        :searchable="true"
        :internal-search="false"
        :close-on-select="true"
        :clear-on-select="false"
        :options-limit="30"
        :limit="9000"
        :limit-text="limitText"
        :show-no-results="true"
        :hide-selected="true"
        :preserve-search="false"
        placeholder="Selecione uma pasta"
        :loading="folderLoad"
        @search-change="getFolders(workflowId, $event)"
        @select="validate"
        class="multiselect-sm folder-multiselect"
        :class="{ 'is-invalid': isInvalid }"
      >
        <span slot="noResult">Não encontrado.</span>
        <span slot="noOptions">Ninguem por aqui.</span>
      </multiselect>
    </b-form-group>
  </div>
</template>

<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import { pluginsComputed, pluginsMethods } from "@state/helpers.js";

export default {
  components: {
    Multiselect,
  },
  props: {
    data: {
      default: null,
    },
    workflowId: {
      default: 0,
    },
    label: {
      default: "Pasta",
    },
    withLabel: {
      default: true,
    },
  },
  data() {
    return {
      localData: null,
      folderLoad: false,
      foldersData: [],
    };
  },
  computed: {
    ...pluginsComputed,
    isInvalid() {
      return this.pluginInputError.provisionalExecution;
    },
  },
  methods: {
    ...pluginsMethods,
    limitText(count) {
      return `E ${count} outros`;
    },
    customLabel(data) {
      let label = data.value;
      if (data.suffix) {
        label += data.suffix;
      }
      return label;
    },
    getFolders: function getFolders(workflowId, search = "") {
      clearTimeout(getFolders.timeout);

      getFolders.timeout = setTimeout(() => {
        axios
          .post(window.location.origin + "/api/v1/plugins/aasp/show/folders", {
            workflow_id: workflowId,
            search,
            without_subtasks: true
          })
          .then((response) => {
            this.foldersData = response.data.data.filter((content)=> !content.is_subtask);
          })
          .catch((error) => {
            console.log("get folders error :>> ", error);
          });
      }, 600);
    },
    validate(event) {
      if (this.localData) {
        this.changePluginInputError({ provisionalExecution: false });
      }
    },
  },
  watch: {
    localData(att) {
      this.$emit("update:data", att);
    },
  },
  created() {
    this.localData = this.data;
    this.getFolders(this.workflowId);
  },
};
</script>

<style>
.is-invalid.folder-multiselect .multiselect__tags {
    border-color: #f46a6a !important;
    padding-right: calc(1.5em + 0.94rem) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/%3e%3c/svg%3e") !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}
.is-invalid.folder-multiselect::after {
    content: "Selecione uma pasta";
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f46a6a !important;
}
</style>
