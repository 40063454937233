<template>
  <div class="base h-100 w-100">
    <span @click="toggle($event, true)" class="title-label d-flex justify-content-start align-items-center">{{ value }}</span>
    <input @blur="toggle($event, false)" v-model="value" class="title-input d-none justify-content-start align-items-center " />
    <select name="list" id="list" v-model="value">
      <option :value="null">Selecione</option>
      <option v-for="(option, opkey) in options" :value="option.id" :key="opkey">{{ option.name }}</option>
    </select>
  </div>
</template>

<script>
  export default {
    name:'TitleComponent',

    data() {
      return {
        value: null,
        options: [
            {id: 1, name: 'a fazer'},
            {id: 2, name: 'Em processo'},
            {id: 3, name: 'teste'},
            {id: 4, name: 'Completo'},
          ],
      }
    },
    methods:{
      toggle(event, show = false) {
        let base = event.target.closest('.base');
        if(base) {
          let input = base.querySelector('.title-input')
          let label = base.querySelector('.title-label')

          if(show) {
            input.classList.add('d-flex');
            input.classList.remove('d-none');

            label.classList.add('d-none')
            label.classList.remove('d-flex')

            input.focus();
          } else {
            input.classList.add('d-none');
            input.classList.remove('d-flex');

            label.classList.add('d-flex')
            label.classList.remove('d-none')

          }
        }
      }
    },
    mounted(){
      // this.value = this.columnValue;
    }
  }
</script>

<style lang="scss" scoped>

</style>
