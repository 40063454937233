<template>
    <div v-b-tooltip.hover="'Favorito'">
        <i class="bx star star-color" :class="{'bxs-star' : favorite, 'bx-star': !favorite }" @click="toggleFavorite"></i>
    </div>
</template>

<script>
    import axios from 'axios';
    import { menusMethods } from '../state/helpers';
    export default {
        data(){
            return {
                favorite: false,
            }
        },
        props: {
            workflowId: {
                require: true,
                type: Number,
            },
            isFavorite: {
                type: Boolean,
                default: false
            }
        },
        methods:{
            ...menusMethods,
            toggleFavorite: function toggleFavorite() {
                clearTimeout(toggleFavorite.timeout)
                this.favorite = !this.favorite;
                let data = {
                    favorite: this.favorite,
                    workflow_id: this.workflowId
                };
                toggleFavorite.timeout = setTimeout(()=>{
                    axios.post(window.location.origin + '/api/v1/workflow/favorite', data)
                    .then((response)=>{
                        this.getMenu();
                    })
                    .catch((error)=>{
                        console.log(error);
                    });
                }, 1000);

            },

        },
        mounted() {
            if(this.isFavorite) {
                this.favorite = this.isFavorite
            }
        }
    }
</script>

<style lang="scss" scoped>
.star-color {
    color: #f7ca4c;
}
.star {
    font-size: 0.75rem;
}
.star:hover {
    color: #f7ca4c;
    cursor: pointer;
    font-size: 1rem;

    -webkit-transition: font-size 0.3s;
    -moz-transition: font-size 0.3s;
    -o-transition: font-size 0.3s;
    transition: font-size 0.3s;
}
</style>

