<template>
    <div class="row">
      <div class="col-xl-12">
        <div class="card" :class="{'dahboard-card-border-radius border-start border-end border-bottom': isDashboard}">
          <div class="card-body">
            <h5 class="text-start" v-show="showTitle">Inatividade</h5>

            <div class="row mb-3">
              <!-- Workflows -->
              <b-form-group
                label="Selecione um Workflow"
                label-for="aasp-list-show"
                class="mb-2 mt-2 col-12 col-md-4 col-lg-4 col-xl-2"
                @submit.stop.prevent
              >
                <b-form-select
                  v-model="workflowId"
                  @change="getList($event)"
                  id="aasp-list-show"
                  name="aasp-list-show"
                  class="form-select form-select font-size-12"
                  size="sm"
                >
                  <b-form-select-option
                    v-for="(workflow, workflowKey) in workflows"
                    :key="workflowKey"
                    :value="workflow.id"
                    >{{ workflow.name }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>

              <!-- Users -->
              <b-form-group
                label="Selecione um usuário"
                label-for="list-workflows-show"
                class="mb-2 mt-2 col-12 col-md-4  col-lg-4 col-xl-2"
                @submit.stop.prevent
                v-if="user && user.user_type_id == 1"
              >
                <b-form-select
                  v-model="workflowUserId"
                  @change="getList(workflowId)"
                  id="list-workflows-show"
                  name="list-workflows-show"
                  class="form-select form-select font-size-12"
                  size="sm"
                >
                  <template #first>
                    <b-form-select-option :value="0">Todos</b-form-select-option>
                  </template>
                  <b-form-select-option
                    v-for="user in usersList"
                    :key="user.id"
                    :value="user.id"
                    >{{ user.full_name }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>

              <!-- Search By -->
              <b-form-group
                label="Campo"
                label-for="aasp-list-show"
                class="mb-2 mt-2 col-12 col-md-4 col-lg-4 col-xl-2"
                @submit.stop.prevent
              >
                <b-form-select
                  v-model="searchBy"
                  @change="searchBySelect"
                  id="aasp-list-show"
                  name="aasp-list-show"
                  class="form-select form-select font-size-12"
                  size="sm"
                >
                  <b-form-select-option
                    v-for="(column, columnKey) in searchByOptions"
                    :key="columnKey"
                    :value="column.key"
                    >{{ column.label }}</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>

              <!-- Search -->
              <b-form-group
                label="Localizar"
                label-for="aasp-list-search"
                class="mb-2 mt-2 col-md col-12"
                @submit.stop.prevent
              >
                <b-form-input
                  v-if="searchBy != 'last_update'"
                  type="text"
                  id="aasp-list-search"
                  name="aasp-list-search"
                  placeholder="Busca..."
                  v-model.lazy="search"
                  class="font-size-12"
                  size="sm"
                ></b-form-input>
                <DatePicker
                  id="deadline-datetimepicker"
                  class="font-size-12"
                  v-model.lazy="search"
                  :lang="datePikerLang"
                  type="datetime"
                  format="DD/MM/YYYY HH:mm"
                  value-type="YYYY-MM-DD H:mm:ss"
                  :range="true"
                  :default-value="new Date()"
                  placeholder="00/00/0000 00:00:00"
                  @close="pageControl(currentPage, search)"
                  size="sm"
                  v-else
                ></DatePicker>
              </b-form-group>

              <!-- Show per page -->
              <b-form-group
                label="Mostrar"
                label-for="aasp-list-show"
                class="mb-2 mt-2 col-4 col-md-2  col-lg-2 col-xl-1"
                @submit.stop.prevent
              >
                <b-form-select
                  v-model="perPage"
                  @change="pageControl(currentPage)"
                  id="aasp-list-show"
                  name="aasp-list-show"
                  class="form-select form-select font-size-12"
                  :options="pageOptions"
                  size="sm"
                >
                </b-form-select>
              </b-form-group>
              <b-form-group
                label="_"
                class="mb-2 mt-2 col-4 col-md-2  col-lg-2 col-xl-1 hide-label"
                @submit.stop.prevent
              >
                  <label class="d-inline-flex align-items-center">
                  <a href="#" class="btn btn-outline-info btn-sm font-size-12" @click.prevent="exportDashboard()" :class="{'disabled': exportLoad}">
                      <span v-show="!exportLoad">Exportar</span>
                      <span v-show="exportLoad"><i class="bx bx-loader-alt bx-spin" ></i></span></a>
                  </label>
              </b-form-group>
            </div>

            <div
              v-if="loadList || loadPage"
              class="d-flex w-100 justify-content-center align-items-center"
              :class="{'page-load': loadPage}"
              style="height: 50vh"
            >
              <i class="bx bx-loader-alt bx-spin fs-1"></i>
            </div>
            <b-table
              v-if="!loadList"
              id="aasp-distribution-list-table"
              class="datatables text-decoration-none text-light mxh-49 font-size-12"
              :items="tableData"
              :fields="tableFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              no-local-sorting
              hover
              sticky-header
              responsive
              selectable
              select-mode="single"
              v-on:row-selected="selectData"
            >
              <template #cell(last_update)="data">
                <span
                  v-if="data.value"
                  class="badge font-size-12 status-width d-flex justify-content-center align-items-center bg-info radius-button-border"
                  >{{ getDateFormat(data.value, true) }}</span>
              </template>
              <template #cell(column_2)="data">
                <div class="avatar-group-item w-100 d-flex" v-if="data.value">
                  <a
                    href="javascript: void(0);"
                    class="d-inline-block pl-3 text-white-solid overflow-text w-100"
                    v-b-tooltip.html.top="contactTooltipData(data.value)"
                  >
                    <b-img-lazy
                      class="rounded-circle avatar-circle-sm delete-on-hover"
                      :src="data.value.avatar"
                      v-if="data.value.avatar"
                    />
                    <b-avatar
                      variant="info"
                      :text="getInitials(data.value)"
                      class="mr-3 bg-light text-white avatar-circle-sm rounded-circle delete-on-hover font-size-9"
                      v-else
                    />
                    <span class="pl-3 text-white-solid overflow-text">
                      {{ data.value.full_name }}</span
                    >
                  </a>
                </div>
                <span v-else>-</span>
              </template>
              <template #cell(column_3)="data">
                <span v-if="data.value" class="badge radius-button-border font-size-12 status-width d-flex justify-content-center align-items-center" :class="[data.value.color]">{{ data.value.status }}</span>
                <span v-else>-</span>
              </template>
            </b-table>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination-nav
                      @click.prevent
                      @change="pageControl"
                      v-model="currentPage"
                      :number-of-pages="page.total"
                      base-url="javascript:void(0);"
                      :total-rows="rows"
                      :per-page="perPage"
                      aria-controls="aasp-distribution-list-table"
                    ></b-pagination-nav>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- info Modal -->
      <b-modal
        id="dashboard-modal-info"
        title="Informações"
        title-class="text-white font-18"
        hide-footer
        body-class="p-3"
      >
          <SimpleInfo :data="selectedEvent" modal-id="dashboard-modal-info" @close="closeModal" />

      </b-modal>
    </div>
  </template>

  <script>
  import axios from "axios";
  import { mapActions, mapState } from "vuex";
  import SimpleInfo from '@components/home/dashboard/simple-info'
  import Switches from "vue-switches";

  export default {
      components: {SimpleInfo, Switches},
      props: {
          showTitle: {
              default: true
          },
          isDashboard: {
              default: false
          }
      },
    data() {
      return {
        withComplete: true,
        exportLoad: false,
        workflows: [],
        workflowId: 0,
        workflowUserId: 0,
        currentPage: 1,
        perPage: 25,
        pageOptions: [10, 25, 50, 100],
        page: {
          total: 1,
          baseUrl: "",
        },
        search: "",
        odlSearchBy: "last_update",
        searchBy: "last_update",
        sortBy: "last_update",
        sortDesc: true,
        loadList: false,
        loadPage: false,
        tableData: [],
        tableFields: [],
        selectedEvent: {
          title: "",
          description: "",
          redirect: "",
          deadline: "",
          folder: null,
          complete: false
        },
        orderColumns: {
            'column_9': 'task',
            'column_2': 'responsible',
            'column_3': 'status',
            'column_13': 'folder',
            'last_update': 'last_update',
        }
      };
    },
    computed: {
      today() {
          return new Date();
      },
      rows() {
        return this.tableData.length;
      },
      ...mapState("usersOptions", {
        usersList: (state) => state.usersList,
      }),
      ...mapState("user", {
        user: (state) => state.user,
      }),
      searchByOptions() {
          return this.tableFields.filter((item)=> item.key != "column_2");
      }
    },
    methods: {
      async listWorkflows() {
        const response = axios
          .get(`${window.location.origin}/api/v1/workflow`)
          .then((response) => {
            if (!response.data.error) {
              this.workflows = response.data.data;
              if (this.workflows.length > 0) {
                this.workflowId = this.workflows[0].id;
                this.getList(this.workflowId);
              }
            }
          })
          .catch((error) => {
            console.log("get workflow list error :>> ", error);
          });
      },
      getFields(workflowId) {
        axios.post(`${window.location.origin}/api/v1/dashboard/inactivity/fields`, {workflow_id: workflowId})
            .then((response)=>{
                if(!response.data.error) {
                    let data = response.data.data;
                    this.tableFields = [];

                    data.forEach((column, key)=>{
                        let fieldClass = "text-center align-middle overflow-text cell-dark";
                        if(column.workflow_column_type_id == 9) fieldClass += " max-width-column";
                        this.tableFields.push({
                            key: `column_${column.workflow_column_type_id}`,
                            label: column.name,
                            sortable: true,
                            class: fieldClass,
                        })
                    });

                    this.tableFields.push({
                            key: `last_update`,
                            label: 'Última atualização',
                            sortable: true,
                            class: "text-center align-middle overflow-text cell-dark",
                        });

                }
            })

      },
      getList(workflowId) {
        if(!workflowId) return
        this.getFields(workflowId);
        this.loadList = true;
        axios
          .post(
            `${window.location.origin}/api/v1/dashboard/inactivity`,
            { workflow_id: workflowId, per_page: this.perPage, user_id: this.user.user_type_id == 1 ? this.workflowUserId : this.user.id, complete: this.withComplete }
          )
          .then((response) => {
            if (!response.data.error) {
              let page = response.data.data;
              this.currentPage = page.current_page;
              this.page.total = page.last_page;
              this.page.baseUrl = page.path;

              let tableData = page.data;
              let contentData = [];
              tableData.forEach((task) => {
                let item = {};
                let folder = task.folder ? `${task.folder.number}${task.folder.description ? task.folder.description : ''}`  : '-';
                item[`column_9`] = task.name;
                item[`column_2`] = task.responsible ? task.responsible : null;
                item[`column_3`] = task.status ? task.status : null;
                item[`column_13`] = folder;
                item[`last_update`] = task.updated_at ? task.updated_at : '-';
                item[`task`] = task;

                contentData.push(item);
              });
              this.tableData = contentData;
            }
            setTimeout(() => {
              this.loadList = false;
            }, 100);
          })
          .catch((error) => {
              setTimeout(() => {
              this.loadList = false;
              this.tableData = [];
            }, 100);
            console.log("get aasp dashboard error :>> ", error);
          });
      },
      getFolder(task) {
        let column_value = task.column_values.find(
          (column_value) =>
            column_value.workflow_column_type_id == 13 &&
            column_value.plugin_id == 1 &&
            column_value.tag == "aasp_updates_folder"
        );

        if (!column_value) {
          return 0;
        }

        return parseInt(column_value.number);
      },
      pageControl: function pageControl(page, search = "") {
        clearTimeout(pageControl.timeout);
        pageControl.timeout = setTimeout(() => {

          this.loadPage = true;
          let url = `${this.page.baseUrl}?page=${page}&per_page=${this.perPage}`;
          let payload = {
            workflow_id: this.workflowId,
            per_page: this.perPage,
            search,
            order_by: this.sortBy ? this.orderColumns[this.sortBy] : this.sortBy,
            desc: this.sortDesc,
            search_by: this.searchBy ? this.orderColumns[this.searchBy] : this.searchBy,
            user_id: this.user.user_type_id == 1 ? this.workflowUserId : this.user.id,
            complete: this.withComplete
          };

          axios
            .post(url, payload)
            .then((response) => {
              let page = response.data.data;
              this.currentPage = page.current_page;
              this.page.total = page.last_page;
              this.page.baseUrl = page.path;

              let tableData = page.data;
              let contentData = [];
              tableData.forEach((task) => {
                let item = {};
                let folder = task.folder ? `${task.folder.number}${task.folder.description ? task.folder.description : ''}`  : '-';
                item[`column_9`] = task.name;
                item[`column_2`] = task.responsible ? task.responsible : null;
                item[`column_3`] = task.status ? task.status : null;
                item[`column_13`] = folder;
                item[`last_update`] = task.updated_at ? task.updated_at : '-';
                item[`task`] = task;

                contentData.push(item);
              });

              this.tableData = contentData;
              this.loadPage = false;
            })
            .catch((error) => {
              this.loadPage = false;
              console.error("Erro ao buscar dados:", error);
              this.tableData = [];

            });
        }, 600);
      },
      searchBySelect(evt) {
        if (this.odlSearchBy == "deadline") {
          this.odlSearchBy = evt;
          this.search = "";
          this.searchBy = evt;
          return;
        }

        this.searchBy = evt;
        this.odlSearchBy = evt;
      },
      getInitials(user) {
        return `${user.name ? user.name[0] : ""}${
          user.last_name ? user.last_name[0] : ""
        }`;
      },
      selectData(data) {
        if (data.length > 0) {

          if(data.length < 1) {
              return;
          }

          data = data[0];
          let task = data['task'];
          let redirect = '';
          let description = '';
          if(task) {
            redirect = `/workflow/show/${task.workflow_id}/${task.id}`;
            description = task.description;
          }
          this.selectedEvent.title = data['column_9']
          this.selectedEvent.folder = data['column_13']
          this.selectedEvent.description = description,
          this.selectedEvent.deadline =  data['last_update']
          this.selectedEvent.redirect =  redirect
          this.selectedEvent.complete = data.complete

          this.$root.$emit("bv::show::modal", "dashboard-modal-info");
        }
      },
      closeModal() {
        this.$root.$emit("bv::hide::modal", "dashboard-modal-info");
        this.selectedEvent = {
          title: "",
          description: "",
          deadline: "",
          redirect: "",
          folder: null,
          complete: false,
        };
      },
      async exportDashboard() {
          try{
              if(this.tableData.length < 1) {
                  this.showAlert(true, 'Nenhuma inatividade encontrada');
                  return;
              }

              this.exportLoad = true;
              let payload = {
                  workflow_id: this.workflowId,
                  search: this.search,
                  order_by: this.sortBy,
                  desc: this.sortDesc ? 1 : 0,
                  search_by: this.searchBy,
                  user_id: this.user.user_type_id == 1 ? this.workflowUserId : this.user.id
              };

              let params = '';
              let payloadKeys = Object.keys(payload);
              payloadKeys.forEach((param, key)=> params += key != payloadKeys.length - 1
                  ? `${param}=${payload[param]}&`
                  : `${param}=${payload[param]}`);

                let response = await axios.get(`${window.location.origin}/api/v1/export/dashboard/inactivity?${params}`)
                if(!response.data.error) {
                    let data = response.data.data;
                    var link = document.createElement('a');
                    link.href = data.url;
                    link.setAttribute('download', data.filename);
                    link.setAttribute('target', "_blank");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
                setTimeout(()=>{this.exportLoad = false;}, 600);

          } catch(error) {
              setTimeout(()=>{this.exportLoad = false;}, 600);
              console.log(error);
          }
      },
      showAlert(isError, msg) {
          var icontype = isError? 'error': 'success'
          // msg =  isError? msg : ''
          this.$swal.fire({
          icon: icontype,
          title: msg,
          toast: true,
          position: "top-right",
          showConfirmButton: false,
          iconColor: "white",
          timerProgressBar: true,
          timer: 1500,
          didOpen: (toast) => {
              toast.addEventListener('mouseenter', this.$swal.stopTimer)
              toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          },
          customClass: {
              popup: "colored-toast",
          },
      });
      }
    },
    mounted() {
      this.getList(this.workflowId);
      this.listWorkflows();
      this.$watch(
        () => this.search,
        (att, old) => {
          if (this.searchBy != "last_update") {
            this.pageControl(this.currentPage, this.search);
          }
        }
      );
      this.$watch(
        () => this.sortBy,
        (att, old) => {
          let column = att ? this.orderColumns[att] : att
          this.pageControl(this.currentPage, column);
        }
      );
      this.$watch(
        () => this.sortDesc,
        (att, old) => {
          this.pageControl(this.currentPage, this.search);
        }
      );
      this.$watch(
        () => this.withComplete,
        (att, old) => {
          this.getList(this.workflowId);
        }
      );
    },
  };
  </script>

  <style lang="scss" scoped>
  .bg-danger-scoped {
      --bs-bg-opacity: 1;
      background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
  }
  .bg-date-scoped {
    //   background-color: rgba(42, 48, 66, 0.3);
      background-color: rgba(var(--bs-info-rgb), 0.06);
  }

  .radius-button-border {
      border-radius: 3px;
      height: 90% !important;
  }
  </style>
  <style>
  .page-load {
    height: 66%;
    position: absolute;
    left: 0;
    right: 0;
  }
  .mxh-49 {
    max-height: 49vh;
  }
  #aasp-distribution-list-table * td {
      padding: 1px;
      padding-top: 2px;
      padding-bottom: 2px;
  }

  #aasp-distribution-list-table > thead tr th {
      padding: 3px;
  }

  #aasp-distribution-list-table > thead tr th::before {
      top: -2px;
  }

  #aasp-distribution-list-table > thead tr th::after {
      top: 3px;
  }
  .deadline-badge {
      height: 19px;
  }
  .wrapper-btn-complete-width {
      width: 90px;
  }
  .wrapper-btn-complete-height {
      height: 31px;
  }
  .wrapper-btn-complete-height .vue-switcher div {
      height: 8px;
      width: 30px;
  }

  .wrapper-btn-complete-height .vue-switcher div::after {
      height: 15px;
      width: 15px;
      margin-left: -15px;
  }

  .hide-label legend {
      visibility: hidden;
  }

  .max-width-column {
      width: 250px !important;
      max-width: 250px !important;

  }
  #deadline-datetimepicker * .mx-input {
      height: calc(1.5em + .5rem + 2px) !important;
      font-size: 12px;
  }
  .avatar-circle-sm {
      height: 1.0rem !important;
      width: 1.0rem !important;
  }

  #aasp-distribution-list-table tr {
      height: 25px;
  }

  .font-size-9 {
      font-size: 9px;
  }
  </style>
