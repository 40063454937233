<template>
  <div
    class="d-flex justify-content-center align-items-center cell-height"
    :class="{ 'disabled-opacity': disabled }"
  >
  <!-- :style="{ 'z-index': 1000 }" -->
    <b-dropdown
      lazy
      class="radius-button-border w-100"
      :class="[selectedOption.color]"
      variant=" list-button"
      menu-class="w-100"
      @show="setZIndex($event, true),  dropdownStatus($event, true)"
      @hide="setZIndex($event), dropdownStatus($event, false)"
    >
      <template #button-content>
        <span
          class="list-label text-white font-size-13 h-100 w-100 text-center d-flex justify-content-center align-items-center link-cursor rem-size-2"
          >{{ selectedOption.option }}</span
        >
      </template>

      <b-dropdown-item
        class="options-item-main"
        variant=" ps-1 pe-1"
      >
        <span
          @click="save(task, columnValue, false)"
          class="badge d-block cell-input cell-edit option-item-text d-flex align-items-center justify-content-center bg-secondary list-item rem-size-2"
          >Sem status</span
        >
      </b-dropdown-item>
      <div v-if="dropdownActive">
        <b-dropdown-item
          v-for="(option, optionKey) in column.options"
          variant=" ps-1 pe-1 pt-0"
          :key="optionKey"
          class="options-item-main rem-size-2"
        >
          <span
            @click="save(task, columnValue, option)"
            class="badge d-block cell-input cell-edit option-item-text d-flex align-items-center justify-content-center w-100 list-item rem-size-2"
            :class="[option.color, { 'bg-secondary': !option.color }]"
            >{{ option.option }}</span
          >
        </b-dropdown-item>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dropdownActive: false,
      selectedOption: {
        color: "bg-secondary",
        option: "Selecione",
      },
    };
  },
  props: {
    task: {
      default: null,
    },
    columnValue: {
      default: {},
    },
    disabled: {
      default: false,
    },
    column: {
      default: {},
    },
  },
  methods: {
    ...mapActions('columnValues', ['updateColumnValue']),
    ...mapActions('tasks', ['updateTaskColumnValue','findTask']),
    async save(task, cell, option) {
      // list-label
      let base = this.$el;
      let optionTemp = this.selectedOption;

      if (base) {
        let button = base.querySelector(".list-button");
        let span = base.querySelector(".list-label");
        if (span) {
          let oldColorClass = this.selectedOption.color.replace("bg-");
          button.classList.remove(this.selectedOption.color);
          button.classList.remove(`btn-${oldColorClass}`);
          button.classList.add(option.color ? option.color : "bg-secondary");
        //   if(option) {
        //   }
          span.textContent = option ? option.option : "Selecione";
        }
      }
      this.selectedOption = option
        ? option
        : { color: "bg-secondary", option: "Selecione" };
      let data = {
        group_id: this.task.workflow_group_id,
        task_id: this.task.id,
        column_type_id: this.column.workflow_column_type_id,
        column_value_id: cell.id,
        value: option ? option.id : null,
        description: "",
      };

      let response = await this.updateColumnValue(data);

      if (!response) {
        if (base) {
          let button = base.querySelector(".list-button");
          let span = base.querySelector(".list-label");
          if (option && span) {
            let oldColorClass = option.color.replace("bg-");
            button.classList.remove(option.color);
            button.classList.remove(`btn-${oldColorClass}`);
            button.classList.add(optionTemp.color);
            optionTemp;
            span.textContent = optionTemp.option;
          }
        }
      } else {
        this.updateTaskColumnValue(data);
        if(option.finalization_option) {
            this.findTask(this.task.id)
        }
      }
    },
    dropdownStatus(event, active = false) {
        this.dropdownActive = active
    }
  },
  created() {
      if (this.columnValue.value) {
        let selectedOptionData = this.columnValue.options.find(
          (option) => option.id == this.columnValue.value
        );

        if(selectedOptionData) {
            let selectedOption =  JSON.parse(JSON.stringify(selectedOptionData));

            if(typeof selectedOption != 'undefined') {
                if(!selectedOption['color']) {
                    selectedOption['color'] = 'bg-secondary';
                }

                this.selectedOption = selectedOption;

            }
        }
      }
  },
  mounted() {
    this.$watch(()=>this.columnValue.value,()=>{
        this.selectedOption = this.columnValue.options.find(
        (option) => option.id == this.columnValue.value
      );

      if(this.selectedOption) {
        this.selectedOption = JSON.parse(JSON.stringify(this.selectedOption));
      }


      if(!this.selectedOption.color) {
        this.$set(this.selectedOption, 'color', 'bg-secondary')
      }
    })
  },
  beforeDestroy() {
    this.selectedOption = {};
    this.$el.parentNode.removeChild(this.$el);
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  height: 23px;
  font-size: 11px !important;
  font-weight: 400 !important;
}

.text-white-solid {
  color: white !important;
  opacity: 1;
}
</style>
