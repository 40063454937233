<template>
    <div>
      <div class="cell-input-field">

        <!-- Person -->
        <div v-if="column.workflow_column_type_id == 14" class="cell-height d-flex align-items-center row"
          :class="{ 'justify-content-start': requiredItem.value, 'justify-content-center': !requiredItem.value }"
          style="padding-left: 11px;">
          <b-col cols="9" v-if="requiredItem.value">
            <div class="avatar-group d-flex justify-content-start align-items-center cell-height">
              <div
                v-for="(person, keyperson) in personsOptions.filter((person) => requiredItem.value.indexOf(person.id) != -1)"
                :key="keyperson" @dblclick="removeItem(person.id)">
                <div class="avatar-group-item" v-b-tooltip="`${person.name} ${person.last_name ? person.last_name : ''}`">
                  <a href="javascript: void(0);" class="d-inline-block text-decoration-none fs-5">
                    <img :src="'/storage/' + person.avatar" class="rounded-circle avatar-circle" alt />
                  </a>
                  <span class="pl-3 text-white-solid">
                    {{
                      person.name
                    }}
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="2" class="btn-dropdown cell-height">
            <b-dropdown @show="setTempValue(requiredItem.value)" class="open-responsible-select"
              variant="btn-edit text-decoration-none fs-6 text-secondary p-0" left>
              <template #button-content>
                <span>
                  <i class="fas fa-plus-circle text-white"></i>
                </span>
              </template>

              <b-dropdown-form style="width: 20vw" @submit.stop.prevent @keypress.enter.stop.prevent>
                <b-form-group @submit.stop.prevent @keypress.enter.stop.prevent>
                  <b-form-input class="form-input" placeholder="Buscar" v-model="search" debounce="200"></b-form-input>
                </b-form-group>
              </b-dropdown-form>
              <div v-for="(person, personKey) in filterPersons" :key="personKey" :value="person.id">
                <b-dropdown-item @click="setValue(person.id, column.workflow_column_type_id)"
                  v-if="person.user_type_id != 1 && person.user_type_id != 2" class="text-white-solid">
                  <img :src="'/storage/' + person.avatar" class="rounded-circle avatar-circle mr-1"
                    alt />
                  {{
                    (person.name ? person.name + ' ' : '') + (person.last_name ? person.last_name : '')
                  }}
                </b-dropdown-item>
              </div>
              {{ this.requiredItem.value }}
            </b-dropdown>
          </b-col>
        </div>
        <!-- collaborator -->
        <div v-else-if="column.workflow_column_type_id == 10" class="cell-height d-flex align-items-center row"
          :class="{ 'justify-content-start': requiredItem.value, 'justify-content-center': !requiredItem.value }"
          style="padding-left: 11px;">
          <b-col cols="9" v-if="requiredItem.value">
            <div class="avatar-group d-flex justify-content-start align-items-center cell-height">
              <div
                v-for="(user, keyUser) in workflowCollaborators.filter((user) => requiredItem.value.indexOf(user.id) != -1)"
                :key="keyUser" @dblclick="removeItem(user.id)">
                <div class="avatar-group-item" v-b-tooltip="`${user.name} ${user.last_name ? user.last_name : ''}`">
                  <a href="javascript: void(0);" class="d-inline-block text-decoration-none fs-5">
                      <img :src="user.avatar" class="rounded-circle avatar-circle" alt v-if="user.avatar" />
                      <b-avatar variant="info" :text="`${user.name?user.name[0]:''}${user.last_name?user.last_name[0]:''}`" class="mr-3 bg-light text-white avatar-circle font-size-10" v-else></b-avatar>
                  </a>
                  <span class="pl-3 text-white-solid">
                    {{
                      user.name
                    }}
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="2" class="btn-dropdown cell-height">
            <b-dropdown @show="setTempValue(requiredItem.value)" class="open-responsible-select"
              variant="btn-edit text-decoration-none fs-6 text-secondary p-0" left>
              <template #button-content>
                <span>
                  <i class="fas fa-plus-circle text-white"></i>
                </span>
              </template>

              <b-dropdown-form style="width: 20vw" @submit.stop.prevent @keypress.enter.stop.prevent>
                <b-form-group @submit.stop.prevent @keypress.enter.stop.prevent>
                  <b-form-input class="form-input" placeholder="Buscar" v-model="search" debounce="200"></b-form-input>
                </b-form-group>
              </b-dropdown-form>
              <div v-for="(user, userKey) in filterCollaborators" :key="userKey" :value="user.id">
                <b-dropdown-item @click="setValue(user.id, column.workflow_column_type_id)"
                  v-if="user.user_type_id != 1 && user.user_type_id != 2" class="text-white-solid">
                  <img :src="user.avatar" class="rounded-circle avatar-circle mr-1"
                    alt v-if="user.avatar" />
                    <b-avatar variant="info" :text="`${user.name?user.name[0]:''}${user.last_name?user.last_name[0]:''}`" class="mr-3 bg-light text-white avatar-circle font-size-10" v-else></b-avatar>

                  {{
                    (user.name ? user.name + ' ' : '') + (user.last_name ? user.last_name : '')
                  }}
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </b-col>
        </div>
        <!-- responsible -->
        <div v-else-if="column.workflow_column_type_id == 2" class="cell-height d-flex align-items-center row"
          :class="{ 'justify-content-start': requiredItem.value, 'justify-content-center': !requiredItem.value }"
          style="padding-left: 11px;">
          <b-col cols="9" v-if="requiredItem.value" @dblclick="reset">
            <div class="avatar-group d-flex justify-content-start align-items-center cell-height">
              <div v-for="(user, keyUser) in workflowResponsibles.filter((user) => user.id == requiredItem.value)" :key="keyUser">
                <div class="avatar-group-item" v-b-tooltip="`${user.name} ${user.last_name ? user.last_name : ''}`">
                  <a href="javascript: void(0);" class="d-inline-block text-decoration-none fs-5">
                    <img :src="user.avatar" class="rounded-circle avatar-circle" alt v-if="user.avatar" />
                    <b-avatar variant="info" :text="`${user.name?user.name[0]:''}${user.last_name?user.last_name[0]:''}`" class="mr-3 bg-light text-white avatar-circle font-size-10" v-else></b-avatar>
                  </a>
                  <span class="pl-3 text-white-solid">
                    {{
                      user.name
                    }}
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="2" class="btn-dropdown cell-height">
            <b-dropdown @show="setTempValue(requiredItem.value)" class="open-responsible-select"
              variant="btn-edit text-decoration-none fs-6 text-secondary p-0" left>
              <template #button-content>
                <span>
                  <i class="fas fa-plus-circle text-white"></i>
                </span>
              </template>

              <b-dropdown-form style="width: 20vw" @submit.stop.prevent @keypress.enter.stop.prevent>
                <b-form-group @submit.stop.prevent @keypress.enter.stop.prevent>
                  <b-form-input class="form-input" placeholder="Buscar" v-model="search" debounce="200"></b-form-input>
                </b-form-group>
              </b-dropdown-form>
              <div v-for="(user, userKey) in filterResponsibles" :key="userKey" :value="user.id">
                <b-dropdown-item @click="setValue(user.id)" v-if="user.user_type_id == 1 || user.user_type_id == 2 || user.user_type_id == 4"
                  class="text-white-solid">
                  <img :src="user.avatar" class="rounded-circle avatar-circle mr-1" alt v-if="user.avatar"/>
                    <b-avatar variant="info" :text="`${user.name?user.name[0]:''}${user.last_name?user.last_name[0]:''}`" class="mr-3 bg-light text-white avatar-circle font-size-10" v-else></b-avatar>
                  {{
                    (user.name ? user.name + ' ' : '') + (user.last_name ? user.last_name : '')
                  }}
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </b-col>
        </div>
        <!-- acceptance -->
        <div v-else-if="column.workflow_column_type_id == 4"
          class="cell-base cell-height d-flex align-items-center justify-content-center link-cursor"
          :class="{ 'bg-success': requiredItem.value == 1, 'bg-danger': requiredItem.value == 0, 'bg-secondary': (requiredItem.value != 1 && requiredItem.value != 0) }"
          @click="setValue((requiredItem.value == 1 ? 0 : 1)), $set(requiredItem, 'color', (requiredItem.value == 1 ? 'bg-success' : 'bg-danger'))">
          <b-form-group>
            <div
              class="d-flex align-items-center  justify-content-center fill-element link-cursor acceptance-text text-white">
              <span
                class="d-flex align-items-center  justify-content-center fill-element link-cursor acceptance-text text-white"
                v-if="(requiredItem.value != 1 && requiredItem.value != 0)"> - </span>
              <span
                class="d-flex align-items-center  justify-content-center fill-element link-cursor acceptance-text text-white"
                v-else-if="(requiredItem.value == '1' || requiredItem.value == 1 || requiredItem.value == 'true')">Sim</span>
              <span
                class="d-flex align-items-center  justify-content-center fill-element link-cursor acceptance-text text-white"
                v-else>Não</span>
            </div>
          </b-form-group>
        </div>
        <!-- Lists -->
        <div v-else-if="column.workflow_column_type_id == 8 || column.workflow_column_type_id == 3" class="task-row-shadow">
          <b-dropdown lazy variant="badge p-0 cell-height" class="items-list" style="z-index: 20000;" block
            :menu-class="['menu-class-width', `menu-class-dropdown_${requiredItem.workflow_column_id}`]">
            <template #button-content>
              <div v-for="(item, optionKey) in requiredItem.options" :key="optionKey">
                <span v-if="item.id == requiredItem.value"
                  class=" text-white bg-secondary font-size-13 required-item-block d-flex col-11 align-items-center justify-content-center"
                  :class="[item.color, { 'bg-secondary': !item.color }]">{{
                    item.option
                  }}</span>

              </div>

              <span v-if="!requiredItem.value"
                class="text-white bg-secondary font-size-13 required-item-block d-flex col-11 align-items-center justify-content-center">Selecione</span>
            </template>
            <b-dropdown-item v-for="(option, optionKey) in requiredItem.options" :key="optionKey"
              class="options-item-height options-item-main">
              <span @click="setValue(option.id)"
                class="badge d-flex col-11 align-items-center justify-content-center height-list-option"
                :class="[option.color, { 'bg-secondary': !option.color }]">{{
                  option.option
                }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- Files -->
        <div v-else-if="column.workflow_column_type_id == 6">
          <div class="col">
            <b-row class="justify-content-center align-items-center col-12">
              <b-col cols="9">
                <div v-if="requiredItem.files.length > 0" class="row align-items-center">
                  <b-col cols="9" class="d-flex  justify-content-start">
                    <div v-for="(file, fileKey) in requiredItem.files" :key="fileKey" :class="{ 'd-none': fileKey > 4 }"
                      class="col-3 d-flex align-items-center justify-content-center link-cursor"
                      v-b-tooltip.hover.html="getImage(fileKey, file.extension)">

                      <div class="link-cursor" v-if="fileKey < 5" @dblclick="removeFile(fileKey)">
                        <i v-if="file.extension == 'pdf'" class="mdi mdi-file-pdf font-size-20"></i>
                        <i v-else-if="file.extension == 'docx'" class="mdi mdi-microsoft-word font-size-20"></i>
                        <i v-else-if="file.extension == 'doc'" class="mdi mdi-microsoft-word font-size-20"></i>
                        <i v-else-if="file.extension == 'xls'" class="mdi mdi-file-table-outline font-size-20"></i>
                        <i v-else-if="file.extension == 'xlsx'" class="mdi mdi-file-table-outline font-size-20"></i>
                        <i v-else-if="file.extension == 'txt'" class="bx bxs-file-txt font-size-20"></i>
                        <i v-else-if="file.extension == 'jpg' || file.extension == 'jpeg' || file.extension == 'png' || file.extension == 'webp' || file.extension == 'gif'"
                          class="fas fa-file-image font-size-20"></i>
                        <i v-else-if="file.extension == 'mp4'" class="fas fa-file-video font-size-20"></i>
                        <i v-else class="bx bxs-file-txt font-size-20"></i>
                      </div>
                    </div>
                    <div :class="{ 'd-none': requiredItem.files.length < 6, 'd-flex': requiredItem.files.length > 4 }"
                      class="col-4 align-items-center justify-content-center link-cursor">
                      <span>+ {{ requiredItem.files.length - 5 }}</span>
                    </div>
                  </b-col>
                </div>
              </b-col>

              <b-col cols="1" class="task-file-upload d-flex align-items-center cell-height  ps-1">

                <b-button variant="btn-edit text-decoration-none fs-6 text-secondary pt-0 pb-0"
                  @click.prevent="addFile(requiredItem.id)">
                  <span> <i class="fas fa-plus-circle text-white" style="padding-left: 7px;"></i>
                  </span>
                </b-button>

                <input type="file" :ref="`upload_required_file_${requiredItem.id}`" name="updateFile[]"
                  class="d-none form-control form-control-sm updateFile" :accept="extensionsAccept"
                  @change.prevent="uploadRequiredFile($event)" multiple>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- Ckecklists -->
        <div v-else-if="column.workflow_column_type_id == 11">

          <!-- <b-row class="justify-content-start" v-if="cell.hasChecklist">

            <b-col cols="12" class="p-0 link-cursor"
              @click.prevent="e => checkDisableItem(cell.readonly, cell.workflow_column_id, true) && showCkecklist(row.id, item.id, cell.id, true)">
              <b-progress style="border-radius:unset; background-color: #2d3346;" v-model.lazy="cell.checklistValue"
                :max="100" :class="{ 'success-progress-bar': cell.checklistValue == 100 }" show-progress
                class="cell-height">
                <b-progress-bar :value="cell.checklistValue">
                  <span v-if="cell.checklistValue > 0">{{
                    cell.checklistValue
                  }}%</span>
                </b-progress-bar>
              </b-progress>
            </b-col>
          </b-row>
          <b-row class="justify-content-center" v-else>
            <b-col cols="11">
              <div class="d-flex justify-content-center align-items-center cell-height">
                <b-button @click="showCkecklist(row.id, item.id, cell.id)"
                  variant="btn-edit text-decoration-none fs-6 text-secondary p-0"><span><i
                      class="fas fa-plus-circle text-white"></i></span></b-button>
              </div>
            </b-col>
          </b-row> -->

        </div>

        <!-- Currency -->
        <div v-else-if="(column.workflow_column_type_id == 12)">

          <span @click="showInput = true" v-show="!showInput">
            <money v-model.lazy="requiredItem.value" v-bind="money" aria-disabled="true" disabled
              class="form-control-plaintext cell-title cell-input overflow-text text-white-solid cell-height text-end"
              style="padding-right: 12px;" />
          </span>
          <money v-model.lazy="requiredItem.value" v-bind="money" v-show="showInput"
            @keyup.native.enter="$event.target.blur()" @keyup.native.esc="cancel()"
            @blur.native="showInput = false, setValue(requiredItem.value)"
            class="input-title form-control cell-input cell-edit input-currency cell-height text-end">
          </money>

        </div>
        <!-- Number -->
        <div v-else-if="(column.workflow_column_type_id == 13)" class="d-flex align-items-center text-center">
          <span @click="showInput = true" type="number" v-show="!showInput"
            class="form-control-plaintext cell-title cell-input text-white-solid cell-height">
            {{ requiredItem.value ?
              requiredItem.value : 0 }}
          </span>
          <b-button-group>
            <input :name="requiredItem.id" @keyup.enter="$event.target.blur()"
              @blur="showInput = false, setValue(requiredItem.value)" @keyup.esc="cancel()"
              v-model.lazy="requiredItem.value" type="number"
              class="form-control cell-input cell-edit text-center cell-height align-items-center justify-content-center"
              :placeholder="requiredItem.value ? requiredItem.value : 0" v-show="showInput">
          </b-button-group>
        </div>

        <div v-else-if="column.workflow_column_type_id == 5" class="base-datepicker cell-height">
          <span @click="showInput = true"
            class="d-flex form-control-plaintext cell-title cell-input text-white-solid p-0 cell-height align-items-center justify-content-center">
            <span v-if="requiredItem.value">{{ getDateFormat(requiredItem.value) }}</span>
            <i class="bx bx-calendar fs-4 link-cursor" v-else></i>
          </span>
          <DatePicker class="cell-input cell-edit p-0 ps-1 cell-height justify-content-center visibility-hidden"
            v-model.lazy="requiredItem.value" @close="showInput = false, setValue(requiredItem.value)"
            @keyup.enter="$event.target.blur()" @blur="showInput = false, setValue(requiredItem.value)"
            @keyup.esc="cancel()" :lang="datePikerLang" type="datetime" format="DD/MM/YYYY HH:mm"
            value-type="YYYY-MM-DD H:mm:ss" :range="false" :default-value="new Date()"
            :disabled-date="disableDateFromCompanyConfig" placeholder="00/00/0000 00:00:00" :open="showInput">
            <template #footer>
              <div class="row justify-content-between">
                <div class="col-6 d-flex justify-content-start align-items-center">
                  <a href="#" class="btn btn-success" @click="showInput = false, setValue(requiredItem.value)">Salvar</a>
                </div>
              </div>
            </template>
          </DatePicker>
        </div>

        <!-- title -->
        <div v-else-if="column.workflow_column_type_id == 9"></div>

        <!-- Text/Date/Time -->
        <div v-else
          :class="{ 'd-flex align-items-center text-center': column.workflow_column_type_id != 1 && column.workflow_column_type_id != 9 }">
          <span @click="showInput = true" :type="htmlType[requiredItem.id]"
            :class="{ 'overflow-text': htmlType[requiredItem.id] == 'text', 'd-flex': !showInput, 'd-none': showInput }"
            class="form-control-plaintext cell-title cell-input text-white-solid p-0 cell-height align-items-center justify-content-center">
            <a :href="requiredItem.value" target="_blank" v-if="requiredItem.value && column.workflow_column_type_id == 15">{{ requiredItem.value }}</a>
            <span v-else>{{  requiredItem.value? requiredItem.value : 'Campo obrigatório' }}</span>
          </span>

          <input
            :name="requiredItem.id"
            @keyup.enter="$event.target.blur()"
            @blur="showInput = false, setValue(requiredItem.value)"
            @keyup.esc="showInput = false, cancel()" v-model.lazy="requiredItem.value" v-mask="column.workflow_column_type_id == 7 ? '##:##' : ''"
            class="form-control cell-input cell-edit p-0 ps-1 cell-height"
            :class="{ 'overflow-text': htmlType[requiredItem.id] == 'text', 'd-flex': showInput, 'd-none': !showInput }"
            :type="htmlType[requiredItem.id]" :max="column.workflow_column_type_id == 5 ? '9999-12-31' : null"
            :placeholder="'Digite aqui'">
        </div>
      </div>
    </div>
  </template>

<script>
import config from "@config/config";
import { companiesComputed, workflowsComputed } from "@state/helpers";
import { mapGetters, mapState } from "vuex";
export default {
  components: {},
  props: ["column", "clear"],
  data() {
    return {
      search: "",
      requiredItem: {},
      showInput: false,
      tempValue: "",
      filesUploadPreview: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      htmlType: {},
    };
  },
  methods: {
    setValue(value, type = 0) {
      switch (type) {
        case 14:
        case 10:
          if (
            !this.requiredItem.value ||
            typeof this.requiredItem.value === "undefined"
          ) {
            this.$set(this.requiredItem, "value", []);
          }
          if (this.requiredItem.length < 1) {
            this.$set(this.requiredItem, "value", []);
          }

          this.requiredItem.value.push(value);
          break;

        default:
          this.$set(this.requiredItem, "value", value);
          break;
      }
      this.$emit("select", this.requiredItem);
    },
    toggleShowInput(show = false) {
      this.showInput = show;
      if (show) {
        this.setTempValue(this.requiredItem.value);
      }
    },
    setTempValue(value) {
      this.tempValue = value;
    },
    cancel() {
      this.requiredItem.value = this.tempValue;
    },
    reset() {
      this.requiredItem.value = null;
    },
    removeItem(id) {
      this.requiredItem.value.splice(this.requiredItem.value.indexOf(id), 1);
    },
    addFile(id) {
      this.$refs[`upload_required_file_${id}`].click();
    },

    // Column: File Type: 11
    uploadRequiredFile(e) {
      let files = e.target.files;
      let filesKeys = Object.keys(files);

      filesKeys.forEach((fileKey) => {
        this.requiredItem.files.push(files[fileKey]);
      });
      filesKeys.forEach(async (fileKey) => {
        let base64 = await this.convertBase64(files[fileKey]);
        this.filesUploadPreview.push(base64);
      });

      this.setValue(this.requiredItem.files);

      this.requiredItem.files.forEach((file) => {
        var validate = this.checkFileSize(file);

        if (validate.error) {
          showAlert(true, validate.message);
          return;
        }
      });

      this.requiredItem.files.forEach((file, key) => {
        var typeExplode = file.name.split(".");
        var extension = typeExplode[typeExplode.length - 1];

        this.$set(this.requiredItem.files[key], "extension", extension);
        this.$set(file, "extension", extension);
        if (this.extensionsAccept.indexOf(extension) < 0) {
          showAlert(true, "Tipo não suportado");
          this.requiredItem["files"][key] = null;
          return;
        }
      });
    },
    removeFile(index) {
      this.requiredItem.files.splice(index, 1);
      this.filesUploadPreview.splice(index, 1);
      if (this.requiredItem.files < 0) {
        this.setValue(this.requiredItem.files);
      } else {
        this.setValue(null);
      }
    },
    setExtensions() {
      this.extensionsAccept = "";
      if (config.extensions.length > 0) {
        config.extensions.forEach((extension) => {
          this.extensionsAccept += "." + extension + ",";
        });
      }
    },
    async convertBase64(file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
          resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    },
    getImage(fileKey, extension) {
      // return `<span> Image </span>`
      let content = "";
      switch (extension) {
        case "pdf":
          content = `<i class="mdi mdi-file-pdf font-tooltip"></i>`;
          break;
        case "docx":
        case "doc":
          content = `<i class="mdi mdi-microsoft-word font-tooltip"></i>`;
          break;
        case "xls":
        case "xlsx":
          content = `<i class="mdi mdi-file-table-outline font-tooltip"></i>`;
          break;
        case "jpg":
        case "jpeg":
        case "png":
        case "webp":
        case "gif":
          content = `<img src="${this.filesUploadPreview[fileKey]}" alt="" style="max-height: 200px; ">`;

          break;
        case "mp4":
          content = `<i class="fas fa-file-video font-tooltip"></i>`;
          break;
        default:
          content = `<i class="bx bxs-file-txt font-tooltip"></i>`;
          break;
      }

      return content;
    },

    // Column: Date Type: 5
    disableDateFromCompanyConfig(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      let range_time = 0;
      if (this.company.default_limit_date) {
        range_time = this.company.default_limit_date * 24 * 3600 * 1000;
        return date > new Date(today.getTime() + range_time);
      }

      return false;
    },
  },
  computed: {
    ...companiesComputed,
    ...workflowsComputed,
    ...mapState("columns", {
        columns: (state) => state.columns
    }),
    ...mapState("workflows", {
      workflowResponsibles: (state) => state.workflowResponsibles,
      workflowCollaborators: (state) => state.workflowCollaborators,
    }),
    ...mapState("persons", {
      personsOptions: (state) => state.personsOptions,
    }),
    // ...mapGetters("workflows", ["getItems"]),

    filterResponsibles() {
      var name = "";
      return this.workflowResponsibles.filter((responsible) => {
        name = responsible.name + " " + responsible.last_name;
        return (
          name.toLowerCase().indexOf(this.search.toLowerCase()) != -1 ||
          responsible.email.toLowerCase().indexOf(this.search.toLowerCase()) !=
            -1
        );
      });
    },
    filterCollaborators() {
      var name = "";
      return this.workflowCollaborators.filter((collaborator) => {
        name = collaborator.name + " " + collaborator.last_name;
        return (
          name.indexOf(this.search.toLowerCase()) != -1 ||
          collaborator.email.toLowerCase().indexOf(this.search.toLowerCase()) !=
            -1
        );
      });
    },
    filterPersons() {
      return this.personsOptions.filter((data) => {
        let type = true;
        if (this.column.person_type) {
          type = data.type == this.column.person_type;
        }

        var keys = Object.keys(data);
        var response = false;
        keys.forEach((keyObject) => {
          if (
            typeof data[keyObject] === "string" ||
            data[keyObject] instanceof String
          ) {
            if (
              data[keyObject]
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .indexOf(
                  this.search
                    .toLowerCase()
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                ) != -1
            ) {
              response = true;
            }
          } else {
            if (data[keyObject] == this.search.toLowerCase()) {
              response = true;
            }
          }
        });

        return type && response;
      });
    },
  },
  created() {
    this.setExtensions();
    let localItem = JSON.stringify(this.column);
    this.requiredItem = JSON.parse(localItem);
    if (this.column.use_default_value) {
      this.setValue(this.column.default_value);
    }

    this.$set(this.requiredItem, "files", []);

    // this.getItems.forEach((item) => {
    //   this.htmlType[item.id] = item.html_type;
    // });
    this.columns.forEach((item) => {
      this.htmlType[item.id] = item.html_type;
    });
  },
  beforeDestroy() {
    this.setValue("");
  },
};
</script>

<style scoped>
.required-item-block {
  width: 100%;
  height: 30px;
}

.height-list-option {
  height: 20px;
}

.z-index-top {
  z-index: 90000;
}

.thumb {
  width: 200px;
  height: 200px;
}

.visibility-hidden {
  visibility: hidden;
}
</style>
<style>
.tooltip-inner {
  max-width: unset !important;
  padding: 0.4rem 0.4rem !important;
}

.btn-dropdown {
  position: absolute;
  right: 19px;
  display: flex;
}
</style>
