<template>
    <div class="base">
      <span
        @click="toggleShow(true)"
        class="number-label form-control-plaintext cell-title cell-input text-white-solid p-0 cell-height align-items-center justify-content-center d-flex rem-size-2"
        :class="{ 'disabled-opacity': disabled}"
      >0</span>

      <input
        @keyup.enter="$event.target.blur()"
        @blur="save()"
        @keyup.esc="cancel"
        type="text"
        :disabled="disabled || column.iterable || column.tag == 'workflow_ticket_id'"
        class="number-input form-control cell-input cell-edit p-0 ps-1 cell-height text-center d-none rem-size-2"
        placeholder="0"
      />
    </div>
  </template>

  <script>
//   import IMask from "imask";
//   import config from "../../../config/config";
  import { mapActions } from "vuex";
  import IMask from "imask";
  import config from "../../../config/config";
//   import showAlert from '../../../components/alerts'

  export default {
    data() {
      return {
        backupValue: "",
      };
    },
    props: {
      column: {
        default: {},
      },
      columnValue: {
        default: {},
      },
      task: {
        default: {},
      },
      disabled: {
        default: false,
      },
    },
    methods: {
      ...mapActions('columnValues', ['updateColumnValue']),
      ...mapActions('tasks', ['updateTaskColumnValue', 'updateTask', 'setShowSubtasks']),
      cancel(e) {
        let base = this.$el;

        if (base) {
          let span = base.querySelector(".number-label");
          let input = base.querySelector(".number-input");
          input.value = parseInt(this.backupValue);
          if((this.column.iterable || this.column.tag == 'workflow_ticket_id') && this.columnValue.description && this.task.heritage) span.textContent = `${this.backupValue} - ${columnValue.description}`;
          else span.textContent = this.backupValue;
          span.classList.remove("d-none");
          span.classList.add("d-flex");
          input.classList.remove("d-flex");
          input.classList.add("d-none");
        }
      },
      toggleShow(showInput = false) {
        if(this.disabled || this.column.iterable || this.column.tag == 'workflow_ticket_id') return;

        let base = this.$el;

        if (base) {
          let input = base.querySelector(".number-input");
          let span = base.querySelector(".number-label");
          if (showInput) {
            IMask(input, config.masks.number);
            span.classList.remove("d-flex");
            span.classList.add("d-none");
            input.classList.remove("d-none");
            input.classList.add("d-flex");
            this.backupValue = (input.value);
            input.value = ((span.textContent).replace(/[^0-9]/g, ''));
            input.focus();
          } else {
            span.classList.remove("d-none");
            span.classList.add("d-flex");
            input.classList.remove("d-flex");
            input.classList.add("d-none");

            if(input.value && this.column.iterable && this.columnValue.description && this.task.heritage)
                span.textContent = `${parseInt(input.value)} - ${this.columnValue.description}`;
            else if (input.value)
                span.textContent = parseInt(input.value);

            const imaskInstance = input.dataset.imask;
            if (imaskInstance) imaskInstance.destroy();
          }
        }
      },
      async save() {
        let base = this.$el;
        if (base) {
          let input = base.querySelector(".number-input");
          if (input) {
              let value = (input.value.replace(/[^0-9]/g, ''));

              let data = {
                  group_id: this.task.workflow_group_id,
                  task_id: this.task.id,
                  column_type_id: this.column.workflow_column_type_id,
                  column_value_id: this.columnValue.id,
                  column_id: this.column.id,
                  value: value,
                  description: '',
              }

              this.updateColumnValue(data)
              this.updateTaskColumnValue(data);

              // Uncomment if you enable editing for iterables
              //   if(!this.task.heritage && this.column.iterable) {

              //     let show = this.task.show_subtasks;

              //     if(this.task.show_subtasks) {
              //         this.setShowSubtasks({task: this.task, data: false});
              //     }

              //     await this.updateTask(data);

              //     if(show) {
              //         setTimeout(()=>{this.setShowSubtasks({task: this.task, data: true})}, 500);
              //     }
              //   }
          }
        }
        this.toggleShow();
      },
    },
    mounted() {

      let input = this.$el.querySelector(".number-input");
      let span = this.$el.querySelector(".number-label");
      input.value = parseInt(this.columnValue.number);
      this.backupValue = this.columnValue.number;

      if (this.columnValue.number) {
          if((this.column.iterable || this.column.tag == 'workflow_ticket_id') && this.columnValue.description && this.task.heritage)
            span.textContent = `${this.columnValue.number} - ${this.columnValue.description}`;
          else
            span.textContent = this.columnValue.number;
      }
      if (this.columnValue.number) {
          setTimeout(()=>{ input.value = parseInt(this.columnValue.number)},100);
      }

    },
    beforeDestroy () {
      this.backupValue = "";
      this.value = "";
      this.$el.parentNode.removeChild(this.$el);
    }
  };
  </script>

  <style lang="scss" scoped>
  .disabled-opacity-number {
    opacity: 0.6;
  }
  .disabled-opacity-number:hover {
    color: white;
    opacity: 1;
  }

  </style>
