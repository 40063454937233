<script>
import config from "../../../config/config";
import PageHeader from "../../../components/page-header";
import showAlert from '../../../components/alerts'
import axios from 'axios';
import { userComputed } from '../../../state/helpers';

export default {
    components: {},
    data(){
        return {
            companyLogo: localStorage.getItem('logo'),
            selectedTab: 0,
            tableData: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            maskSearch: '',
            sortBy: "created_at",
            sortDesc: false,
            fields: [

                { key: "file",label: "Arquivo", sortable: false, class: "text-center align-middle cell-dark" },
                { key: "name",label: "Nome", sortable: true, class: "text-center align-middle cell-dark" },
                { key: "created_at",label: "Enviado", sortable: true, class:'text-center align-middle cell-dark' },
                { key: "management",label: "Editar", sortable: true, class: "text-center align-middle cell-dark" },
            ],
            formSearch:{
              search: "",
              statusSelect:{
                  selected: null,
                  options: [
                  { value: null, text: 'Todos' },
                  { value: 1, text: 'ativo' },
                  { value: 2, text: 'inativo' }
                ],
              }
            },
            files: {
                updateFiles: {}
            }
        }
    },
    props:{
        id: {},
        isChat:{
            default: false
        },
        isAdminArea:{
            default: false
        },
        from:{
            type: String,
            default: '',
        },
        isRequired: {
            default: false
        },
        itemName: {
            default: ''
        }
    },
    methods:{
            onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
            },
            handleInput(value, data) {
            this.editableDataItems[data.index][data.field.key] = value;
        },

        deleteFile(uuid){

            if(this.isRequired && this.tableData.length < 2) {
                showAlert(true,  `O campo "${this.itemName}" é obrigatório`);
                return;
            }

            var data = {'uuid': uuid};

            var title = 'Confirma a exclusão?';
            var text =  "O arquivo será removido permanentemente do workflow?";
            var btnConfirm = "Sim, remover!";
            this.$swal
            .fire({
              title: title,
              text: text,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: btnConfirm,
              cancelButtonText: "Cancelar",
            })
            .then((result) => {
              if (result.value) {
                const getResponsibleOptions = axios
                    .delete(
                    window.location.origin + "/api/v1/files/"+uuid,
                    config.getHeaders
                    )
                    .then((response) => {
                        showAlert(false, response.data.message);
                        var fileDeleted = this.tableData.find(file => file.uuid == uuid);
                        this.tableData.forEach((file, key) =>{
                            if(file.uuid == uuid){
                                this.tableData.splice(key, 1);
                            }
                        });
                        this.getFiles(this.id)
                    })
                    .catch((error) => {
                        showAlert(true, error.response.data.message);
                        console.log(error);
                    });
              }
            });


        },
        getFiles(id){
            let url = window.location.origin + "/api/v1/files/task/"+id;

            if(this.from == 'edit-workflow') {
                url = window.location.origin + "/api/v1/files/workflow/"+id;
            }

            const setTaskUpdate = axios
                .get(url, config.getHeaders)
                .then((response) => {
                    if (!response.data.error) {
                        var files = response.data.data;
                        this.tableData = [];
                        var uuids = [];
                        files.forEach((file)=>{
                            var date = this.getDateFormat(file.created_at)
                            uuids.push(file.uuid);
                            var typeExplode = (file.file_name).split('.');
                            var fileExtension = typeExplode[typeExplode.length-1];
                            this.tableData.push({uuid: file.uuid,extension: fileExtension, hasHistory: file.history_exists, userId: file.user_id, deletable: !file.history_exists && (file.user_type_id == 1 || file.user_type_id == 2 || file.user_type_id == 4), file: '', name: file.file_name, created_at: date, delete: false});

                        });

                        this.showUploadedFile(uuids);

                    }
                })
                .catch((error) => {
                if (error.response) {
                    showAlert(false, error.response.data.message);
                }
                });
        },
        showUploadedFile(uuids){
            if(uuids.length > 0){
                const showUpload = axios
                .post(
                window.location.origin + "/api/v1/files",
                {id: uuids[0]},
                config.getHeaders
                )
                .then((response) => {
                    this.files.updateFiles[uuids[0]] = document.location.origin +'/'+response.data.data.path;
                    uuids.shift();
                    this.showUploadedFile(uuids);
                    return;
                })
                .catch((error) => {
                    if (error.response) {
                        console.log(error.response);
                    }
                });

            }

            this.setFilesData();

        },
        setFilesData(){
            this.tableData.forEach(data =>{
                data.file = this.files.updateFiles[data.uuid];
            });
        },
        downloadFile(uuid){

            const download = axios
            .post(
            window.location.origin + "/api/v1/files",
            {id: uuid},
            config.getHeaders
            )
            .then((response) => {
                var downloadUrl = document.location.origin +'/'+response.data.data.path;
                var fileLink = document.querySelector('a.download');
                fileLink.href = downloadUrl;
                fileLink.setAttribute('download', response.data.data.file_name);
                fileLink.setAttribute('target', "_blank");
                fileLink.click();
            })
            .catch((error) => {
            if (error.response) {
                this.isRegisterError = true;
                this.axRegError = error.response.data.message;
            }
            });
        },

    },
    computed: {
        ...userComputed,
        tableDataByTab(){
            if(this.from != '') {
                return this.tableData.filter((file)=> !file.hasHistory);
            }

            if(this.selectedTab == 1) {
                return this.tableData.filter((file)=>{
                    if(this.isChat && !this.isAdminArea) {
                        return !file.hasHistory && file.userId == this.user.id && (file.user_type_id != 1 && file.user_type_id != 2);
                    } else {
                        return !file.hasHistory && !file.deletable
                    }
                });
            }

            if(this.selectedTab == 2) {
                return this.tableData.filter((file)=> !file.hasHistory && file.deletable);
            }

            if(this.isChat && !this.isAdminArea) {
                return this.tableData.filter((file)=> file.hasHistory && !file.deletable);
            }

            return this.tableData.filter((file)=> file.hasHistory);

        },
        rows() {
            return this.tableDataByTab.length;
        },
      },
    mounted(){
        this.getFiles(this.id);
    }
}
</script>

<template>
    <div v-if="files.updateFiles">
        <a href="" class="d-none download" download></a>
        <section name="search" class="sticky-top bg-default search-top">
            <div class="col-sm-auto col-md-12">
               <b-form-group class="form-text" name="search" label="Busca" label-for="search">
                   <b-form-input id="search" class="mb-3 font-size-12" size="sm" placeholder="Buscar" v-model.lazy="formSearch.search"  v-mask="maskSearch"></b-form-input>
               </b-form-group>
            </div>
            <b-tabs content-class="mt-3" class="font-size-12" v-model="selectedTab" v-if="this.from == ''">
                <b-tab title="Atividades" active></b-tab>
                <b-tab title="Formulário"></b-tab>
                <b-tab title="Apoio" v-if="user.user_type_id == 1 || user.user_type_id == 2 || user.user_type_id == 4"></b-tab>
            </b-tabs>
        </section>
        <b-table
          id="files-datatable"
          class="datatables scr-right"
          :items="tableDataByTab"
          :fields="fields"
          :responsive="true"
          :sticky-header="true"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter="formSearch.search"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          v-if="rows > 0"
        >
        <template #cell(file)="row">

            <div v-if="row.item.uuid != null && (row.item.extension == 'jpg' || row.item.extension == 'jpeg' ||row.item.extension == 'png' ||row.item.extension == 'gif' ||row.item.extension == 'webp')" class="d-flex align-items-center justify-content-center mb-2">
            <div>
                <div v-if="files.updateFiles[row.item.uuid] != null || files.updateFiles[row.item.uuid] != 'undefined'">
                    <b-img-lazy :src="files.updateFiles[row.item.uuid]" class="rounded img-fluid max-height-image"></b-img-lazy>
                </div>
            </div>
            </div>
            <div v-else-if="row.item.uuid != null && (row.item.extension == 'mp4')" class="d-flex align-items-center justify-content-center mb-2">
                <div v-if="files.updateFiles[row.item.uuid] != null || files.updateFiles[row.item.uuid] != 'undefined'"  class="max-height-image">
                    <video controls name="media" :src="files.updateFiles[row.item.uuid]" class="max-height-image"></video>
                </div>
            </div>
            <div class="d-flex align-items-center justify-content-center mb-2" v-else>
                <i v-if="row.item.extension == 'pdf'" class="mdi mdi-file-pdf font-size-2-rem"></i>
                <i v-else-if="row.item.extension == 'docx'" class="mdi mdi-microsoft-word font-size-2-rem"></i>
                <i v-else-if="row.item.extension == 'doc'" class="mdi mdi-microsoft-word font-size-2-rem"></i>
                <i v-else-if="row.item.extension == 'xls'" class="mdi mdi-file-table-outline font-size-2-rem"></i>
                <i v-else-if="row.item.extension == 'xlsx'" class="mdi mdi-file-table-outline font-size-2-rem"></i>
                <i v-else-if="row.item.extension == 'txt'" class="bx bxs-file-txt font-size-2-rem"></i>
            </div>
        </template>
        <template #cell(management)="row">
            <div class="row align-items-center justify-content-center">
                <b-col cols="3" @click="downloadFile(row.item.uuid)" class="link-cursor">
                    <span class="text-white"><i class="bx bx-download fs-4 align-middle"></i></span>
                </b-col>
                <b-col cols="3" v-if="row.item.deletable && (!isChat || isAdminArea)"  @click="deleteFile(row.item.uuid)" class="link-cursor">
                    <span class="text-white"><i class="fas fa-trash-alt  fs-4 align-middle"></i></span>
                </b-col>
            </div>
        </template>

    </b-table>
    <div class="row justify-content-center align-items-center datatables" v-else>
        <div class="col-2 text-center">
            <img :src="companyLogo? `${companyLogo}` : '/images/dalth-logo.png'" alt height="80" />
        </div>
    </div>
    <div class="row  mt-3">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers float-end">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination v-if="rows > 0" v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="files-datatable"></b-pagination>
          </ul>
        </div>
      </div>
    </div>
    </div>
</template>
<style scoped>
.btn-mangement{
    width: 6vw;
    height: 3vh;
    border-radius: 4px;
}
.font-size-2-rem{
    font-size: 2rem;
}
.max-height-image{
    max-height: 15vh;
}
.link-cursor{
cursor: pointer;
}
.search-top {
    top: 4px;
}
.datatables {
    height: 56vh;
    max-height: none !important;
}

.activity .table-responsive .b-table-sticky-header {
    overflow-y: unset;
}
.cell-dark{
    background: #2A3042 !important;
    color: #c3cbe4 !important;
    width: 12%;
}

</style>


