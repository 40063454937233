<template>
    <div>
        <router-view></router-view>
    </div>
</template>
<script>
export default {

}
</script>
<!-- global css -->
<style src="../css/show-view.css"></style>
<style src="../css/custom-layout-sizes.css"></style>
