<template>
  <LazyComponent wrapper-tag="div" @submit.prevent  v-if="columnValue && !column.hidden && loadTaskId != task.id">

     <!-- Responsible -->
     <ResponsibleColumn v-if="columnValue.workflow_column_type_id == 2"
        :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
        :task="task"
        :group="group"
        :column="column"
        :column-value="columnValue"
        :dropright="dropright"
    />

    <!-- Persons -->
    <PersonColumn  v-else-if="columnValue.workflow_column_type_id == 14"
        :class="{'disabled-opacity': checkDisableItem(column.readonly, columnValue.workflow_column_id)}"
        :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
        :task="task"
        :group="group"
        :column-value="columnValue"
        :column="column"
        :dropright="dropright"
    />

    <!-- Collaborators -->
    <CollaboratorColumn  v-else-if="columnValue.workflow_column_type_id == 10"
       :class="{'disabled-opacity': checkDisableItem(column.readonly, columnValue.workflow_column_id)}"
       :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
       :task="task"
       :group="group"
       :column-value="columnValue"
       :column="column"
       :dropright="dropright"
    />

    <!-- List / Status -->
    <ListColumn v-else-if="columnValue.workflow_column_type_id == 8 || columnValue.workflow_column_type_id == 3"  class="task-row-shadow"
    :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
      :column="column"
      :task="task"
      :column-value="columnValue"
    />

    <!-- Currency -->
    <CurrencyColumn v-else-if="(columnValue.workflow_column_type_id == 12)"
      :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
      :column="column"
      :column-value="columnValue"
      :task="task"
    />

    <!-- Link -->
    <LinkColumn v-else-if="columnValue.workflow_column_type_id == 15"
         :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
         :task="task"
         :column="column"
         :column-value="columnValue"
         :group="group"
    />

    <!-- Date -->
    <DateColumn  v-else-if="columnValue.workflow_column_type_id == 5" class="base-datepicker"
        :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
        :task="task"
        :group="group"
        :column="column"
        :column-value="columnValue"
        :disable-date-from-company-config="disableDateFromCompanyConfig"
    />

    <!-- Time / Duration -->
    <TimeColumn v-else-if="columnValue.workflow_column_type_id == 7"
        :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
        :column="column"
        :column-value="columnValue"
        :task="task"
    />

    <!-- Title -->
    <TitleColumn v-else-if="columnValue.workflow_column_type_id == 9"
      :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
      :column="column"
      :column-value="columnValue"
      :task="task"
      :user="user"
      @openDrawer="openDrawer"
    />

    <!-- Text -->
    <TextColumn v-else-if="columnValue.workflow_column_type_id == 1"
        :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
        :column="column"
        :column-value="columnValue"
        :task="task"
    />
    <!-- Acceptance -->
    <AcceptanceColumn v-else-if="columnValue.workflow_column_type_id == 4"
        :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
        :column="column"
        :column-value="columnValue"
        :task="task"
    />
    <!-- File -->
    <FileColumn v-else-if="columnValue.workflow_column_type_id == 6"
        :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
        :column="column"
        :column-value="columnValue"
        :task="task"
        :extensions-accept="extensionsAccept"
        :group="group"
    />
    <!-- Number -->
    <NumberColumn v-else-if="columnValue.workflow_column_type_id == 13"
        :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
        :column="column"
        :column-value="columnValue"
        :task="task"
        :group="group"
    />
    <ChecklistColumn v-else-if="columnValue.workflow_column_type_id == 11"
        :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
        :column="column"
        :column-value="columnValue"
        :task="task"
    />
    <!-- Workflow -->
    <WorkflowColumn  v-else-if="columnValue.workflow_column_type_id == 16"
      :class="{'disabled-opacity': checkDisableItem(column.readonly, columnValue.workflow_column_id)}"
      :disabled="checkDisableItem(column.readonly, columnValue.workflow_column_id)"
      :task="task"
      :group="group"
      :column-value="columnValue"
      :column="column"
      :dropright="dropright"
    />
    <template #placeholder>
        <!-- <span
            v-if="column.workflow_column_type_id == 9"
            class="title-label
            form-control-plaintext
            cell-title
            cell-input
            overflow-text
            text-white-solid
            p-0
            ps-1
            align-items-center
            cell-height
            d-flex"
        >{{ task.name }}</span>
        <span
            v-else
            class="title-label
            form-control-plaintext
            cell-title
            cell-input
            overflow-text
            text-white-solid
            p-0
            ps-1
            align-items-center
            cell-height
            d-flex
            justify-content-center
            text-center"
        >
            <span class="text-center" v-if="column.workflow_column_type_id == 1
                || column.workflow_column_type_id == 7
                || column.workflow_column_type_id == 15
            ">{{ columnValue.value }}</span>
            <span class="text-center" v-else-if="column.workflow_column_type_id == 12"
            >R$ {{ columnValue.currency }}</span>
            <span class="text-center" v-else-if="column.workflow_column_type_id == 13"
            >{{ columnValue.number }}</span>
            <span v-else class="placeholder-glow col-12 text-center d-flex justify-content-center align-items-center cell-height">
                <div class="placeholder col-11 placeholder-border-radius"></div>
            </span>
        </span> -->

        <!-- <span v-else class="animated-placeholder col-12">
            <div class="animated-background"></div>
        </span> -->
    </template>
  </LazyComponent>
</template>

<script>
import { mapState } from 'vuex';
    import {
        TextColumn,
        LinkColumn,
        PersonColumn,
        TimeColumn,
        CurrencyColumn,
        TitleColumn,
        ListColumn,
        DateColumn,
        ResponsibleColumn,
        CollaboratorColumn,
        AcceptanceColumn,
        FileColumn,
        NumberColumn,
        ChecklistColumn,
        WorkflowColumn
    } from './index'

    export default {
        name: 'RenderColumns',
        components: {
            TextColumn,
            LinkColumn,
            PersonColumn,
            TimeColumn,
            CurrencyColumn,
            TitleColumn,
            ListColumn,
            DateColumn,
            ResponsibleColumn,
            CollaboratorColumn,
            AcceptanceColumn,
            FileColumn,
            NumberColumn,
            ChecklistColumn,
            WorkflowColumn
        },
        props: {
            disabled: {
                default: false,
            },
            column: {
                default: {},
            },
            columnValue: {
                default: {},
            },
            task: {
                default: {},
            },
            group: {
                default: {},
            },
            dropright: {
                default: false,
            },
            user: {
                default: null
            },
            userItemDisable: {
                default: {}
            },
            disableDateFromCompanyConfig: {
                default: false,
            },
            extensionsAccept: {
                default: '',
            },

        },
        computed: {
            ...mapState( 'tasks', {
                showTitles: (state)=> state.showTitles,
                loadTaskId: (state)=> state.tasksProcessing.loadTaskId,
            })
        },
        methods: {
            checkDisableItem(readonly, workflow_column_id, invertResult = false){
                if(!this.user) return true;
                if(!this.user) return true;
            let result = ((readonly && this.user.user_type_id != 1) && (readonly && this.userItemDisable[workflow_column_id]));
                if(invertResult) {
                    return !result;
                }

                return result;
            },
            openDrawer(data) {
                this.$emit('openDrawer', data);
            }
        }

    }
</script>

<style lang="scss" scoped>
.disabled-opacity {
    opacity: 0.6;
}

.disabled-opacity:hover {
    color: #556ee6 !important;
    opacity: 0.6 !important;
}

.placeholder-border-radius {
    border-radius: 10px;
}
</style>
