import axios from "axios";

export const state = {
    processing: false,
    company: {},
}

export const mutations = {
    GET_COMPANY(state, data) {
      state.company = data;
    },
    CHANGE_COMPANY(state, data) {
      state.company = data;
    },
    CHANGE_PROCESSING(state, data) {
      state.processing = data;
    },

}

export const actions = {
  async getCompany({ commit }) {
    try {
        commit('CHANGE_PROCESSING', true);
        const response = await axios.get(window.location.origin+"/api/v1/company")

        if(!response.data.error){
            const data = response.data.data;
            let company = data;

            // LOGO
            let path = data.logo_filepath
            company.companyLogo = path;
            localStorage.setItem('logo', path);

            // ICON
            path = data.icon_filepath
            company.companyLogoIcon = path;
            // localStorage.removeItem('logo_icon');
            localStorage.setItem('logo_icon', path);

            commit('GET_COMPANY', company);
        }

        setTimeout(()=>{
            commit('CHANGE_PROCESSING', false);
        }, 100);
    } catch(error){
        commit('CHANGE_PROCESSING', false);
    }
  },
  changeCompany({ commit }, { searchNavbar }) {
    commit('CHANGE_SEARCH_NAVBAR', searchNavbar);
  },
}
