export default (tasks, ordination)=> {
    tasks.sort((a, b) => {

        switch (ordination.typeId) {
            default:
                var a_value = a.sequence;
                var b_value = b.sequence;

                if (ordination.orderAsc) {
                    if (a_value < b_value) {
                        return -1;
                    }
                    if (a_value > b_value) {
                        return 1;
                    }
                    return 0;
                } else {
                    if (a_value > b_value) {
                        return -1;
                    }
                    if (a_value < b_value) {
                        return 1;
                    }
                    return 0;
                }
                break;
            case 0:
                break;
            case 9:
                if (ordination.orderAsc) {

                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return -1
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return 1
                    }
                    return 0
                } else {
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                        return -1
                    }
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                        return 1
                    }
                    return 0
                }
                break;
            case 1:
                var a_value = a.values.find((value) => { return value.workflow_column_id == ordination.columnId })
                var b_value = b.values.find((value) => { return value.workflow_column_id == ordination.columnId })
                if (ordination.orderAsc) {
                    if (a_value.value.toLowerCase() < b_value.value.toLowerCase()) {
                        return -1;
                    }
                    if (a_value.value.toLowerCase() > b_value.value.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                } else {
                    if (a_value.value.toLowerCase() > b_value.value.toLowerCase()) {
                        return -1;
                    }
                    if (a_value.value.toLowerCase() < b_value.value.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }
                break;
            case 2:
                // Ordenate for name
                var a_value = a.responsibles.length > 0 ? a.responsibles[0].name + ' ' + a.responsibles[0].last_name : ''
                var b_value = b.responsibles.length > 0 ? b.responsibles[0].name + ' ' + b.responsibles[0].last_name : ''

                if (ordination.orderAsc) {
                    if (a_value.toLowerCase() < b_value.toLowerCase()) {
                        return -1;
                    }
                    if (a_value.toLowerCase() > b_value.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                } else {
                    if (a_value.toLowerCase() > b_value.toLowerCase()) {
                        return -1;
                    }
                    if (a_value.toLowerCase() < b_value.toLowerCase()) {
                        return 1;
                    }
                    return 0;
                }
                break;
            case 3:
                // Ordenate for sequence
                var a_value = a.values.find((value) => { return value.workflow_column_id == ordination.columnId })
                var b_value = b.values.find((value) => { return value.workflow_column_id == ordination.columnId })

                if (ordination.orderAsc) {
                    var a_option = a_value.options.find((item) => { return item.id == a_value.value });
                    var b_option = b_value.options.find((item) => { return item.id == b_value.value });
                    var a_option_value = a_option == undefined ? a_value.options.length + 2 : parseInt(a_option.sequence);
                    var b_option_value = b_option == undefined ? b_value.options.length + 2 : parseInt(b_option.sequence);

                    if (a_option_value < b_option_value) {
                        return -1;
                    }

                    if (a_option_value > b_option_value) {
                        return 1;
                    }

                    return 0;
                } else {
                    var a_option = a_value.options.find((item) => { return item.id == a_value.value });
                    var b_option = b_value.options.find((item) => { return item.id == b_value.value });
                    var a_option_value = a_option == undefined ? a_value.options.length + 2 : a_option.sequence;
                    var b_option_value = b_option == undefined ? b_value.options.length + 2 : b_option.sequence;

                    if (a_option_value > b_option_value) {
                        return -1;
                    }

                    if (a_option_value < b_option_value) {
                        return 1;
                    }
                    return 0;
                }
                break;

            case 8:
                // Ordenate for option name
                var a_value = a.values.find((value) => { return value.workflow_column_id == ordination.columnId })
                var b_value = b.values.find((value) => { return value.workflow_column_id == ordination.columnId })

                if (ordination.orderAsc && a_value.value) {

                    var a_option = a_value.options.find((item) => { return item.id == a_value.value });
                    var b_option = b_value.options.find((item) => { return item.id == b_value.value });
                    var a_option_value = a_option == undefined ? '' : a_option.option.toLowerCase();
                    var b_option_value = b_option == undefined ? '' : b_option.option.toLowerCase();

                    if (a_option_value < b_option_value) {
                        return -1;
                    }

                    if (a_option_value > b_option_value) {
                        return 1;
                    }

                    return 0;
                } else {
                    var a_option = a_value.options.find((item) => { return item.id == a_value.value });
                    var b_option = b_value.options.find((item) => { return item.id == b_value.value });
                    var a_option_value = a_option == undefined ? '' : a_option.option.toLowerCase();
                    var b_option_value = b_option == undefined ? '' : b_option.option.toLowerCase();

                    if (a_option_value > b_option_value) {
                        return -1;
                    }

                    if (a_option_value < b_option_value) {
                        return 1;
                    }
                    return 0;
                }
                break;
            case 4:
            case 5:
            case 7:
                var a_value = a.values.find((value) => { return value.workflow_column_id == ordination.columnId })
                var b_value = b.values.find((value) => { return value.workflow_column_id == ordination.columnId })
                if (ordination.typeId == 4) {
                    var a_value = parseInt(a_value.value) ? 0 : 1;
                    var b_value = parseInt(b_value.value) ? 0 : 1;
                } else {
                    var a_value = a_value.value;
                    var b_value = b_value.value;
                }

                if (ordination.orderAsc) {
                    if (a_value < b_value) {
                        return -1;
                    }
                    if (a_value > b_value) {
                        return 1;
                    }
                    return 0;

                } else {
                    if (a_value > b_value) {
                        return -1;
                    }
                    if (a_value < b_value) {
                        return 1;
                    }
                    return 0;

                }
                break;
            case 6:
                var a_value = a.values.find((value) => { return value.workflow_column_id == ordination.columnId })
                var b_value = b.values.find((value) => { return value.workflow_column_id == ordination.columnId })

                var a_value = a_value.files.length;
                var b_value = b_value.files.length;

                if (ordination.orderAsc) {
                    if (a_value < b_value) {
                        return -1;
                    }
                    if (a_value > b_value) {
                        return 1;
                    }
                    return 0;

                } else {
                    if (a_value > b_value) {
                        return -1;
                    }
                    if (a_value < b_value) {
                        return 1;
                    }
                    return 0;

                }
                break;
            case 10:
                var a_value = a.collaborators.length;
                var b_value = b.collaborators.length;

                if (ordination.orderAsc) {
                    if (a_value < b_value) {
                        return -1;
                    }
                    if (a_value > b_value) {
                        return 1;
                    }
                    return 0;
                } else {
                    if (a_value > b_value) {
                        return -1;
                    }
                    if (a_value < b_value) {
                        return 1;
                    }
                    return 0;
                }
                break;
            case 11:
                var a_value = a.values.find((value) => { return value.workflow_column_id == ordination.columnId })
                var b_value = b.values.find((value) => { return value.workflow_column_id == ordination.columnId })

                if (ordination.orderAsc) {
                    if (a_value.checklistValue < b_value.checklistValue) {
                        return -1;
                    }
                    if (a_value.checklistValue > b_value.checklistValue) {
                        return 1;
                    }
                    return 0;

                } else {
                    if (a_value.checklistValue > b_value.checklistValue) {
                        return -1;
                    }
                    if (a_value.checklistValue < b_value.checklistValue) {
                        return 1;
                    }
                    return 0;

                }
                break;
            case 12:
                var a_value = a.values.find((value) => { return value.workflow_column_id == ordination.columnId })
                var b_value = b.values.find((value) => { return value.workflow_column_id == ordination.columnId })
                if (ordination.orderAsc) {
                    if (a_value.currency < b_value.currency) {
                        return -1;
                    }
                    if (a_value.currency > b_value.currency) {
                        return 1;
                    }
                    return 0;

                } else {
                    if (a_value.currency > b_value.currency) {
                        return -1;
                    }
                    if (a_value.currency < b_value.currency) {
                        return 1;
                    }
                    return 0;

                }
                break;
            case 13:
                var a_value = a.values.find((value) => { return value.workflow_column_id == ordination.columnId })
                var b_value = b.values.find((value) => { return value.workflow_column_id == ordination.columnId })
                if (ordination.orderAsc) {
                    if (parseInt(a_value.number) < parseInt(b_value.number)) {
                        return -1;
                    }
                    if (parseInt(a_value.number) > parseInt(b_value.number)) {
                        return 1;
                    }
                    return 0;

                } else {
                    if (parseInt(a_value.number) > parseInt(b_value.number)) {
                        return -1;
                    }
                    if (parseInt(a_value.number) < parseInt(b_value.number)) {
                        return 1;
                    }
                    return 0;

                }
                break;

        }
        return 0
    })

}
