<template>
    <div class="task-group-item item task-observer"
    :class="{'subtask-row': row.heritage}"
    :key="row.id"
    :data-group="group.id"
    :data-task="row.id"
    :id="`task_${row.id}`">

        <div class="task-row task-row-shadow bg-default d-flex border-start border-top border-bottom"
            :class="{'is-task-row': !row.heritage}"
            :id="row.id"
            :key="1"
        >
            <!-- Subtask indicator -->
            <div class="cell-height subtask-ul-border left-freez bg-default-base m-0 subtask-indicator-left"
                :class="`border-task-${group.color}`" v-show="row.heritage">
                <div class="subtask-ul w-100" :class="`border-task-${group.color}`"></div>
            </div>


            <div class="cell-height col-5 my-auto  p-0 left-freez bg-default mt-0 mb-0 group-indicator-item-margind task-row-shadow"
                :class="[{ 'left-subtask-indicator subtask-title-left wdt-subtask-checkbox': row.heritage, 'is-task-row': !row.heritage }, { 'wdt-70 left-70': !row.heritage }, `border-task-${group.color}`]"
            >
                <!-- Group color indicator -->
                <div class="d-flex align-items-center">
                    <div :class="[{ 'bg-secondary': !group.color, 'group-indicator-height-subtask top-0': row.heritage }, `bg-${group.color}`]" class="group-indicator p-0 m-0 group-indicator-height-middle">
                    </div>
                </div>

                <!-- Add subtasks Button -->
                <div :id="`add_subtasks_${row.id}`" class="col-12 p-0 ps-1 d-flex align-items center justify-content-start cell-height column-value-item" >
                  <a @click.prevent="showSubtasks(row)"
                    data-bs-toggle="tooltip" data-bs-html="true" :title="`Subtarefas`"
                    class="btn-link text-decoration-none fs-6 text-secondary p-0 font-size-20 align-items center justify-content-start ps-1"
                    :class="{ 'd-none': row.heritage, 'w-100': !row.heritage }">
                    <i
                      class="subtask-arrow"
                      :class="[{ 'bx bx-chevron-right': (!row.show_subtasks && openSubtasks.indexOf(row.id) == -1), 'bx bx-chevron-down': (row.show_subtasks || openSubtasks.indexOf(row.id) != -1), 'dont-have-subtask subtask-arrow-display-none': row.subtasks.length == 0 }, `text-${group.color}`]"></i>
                  </a>

                    <!-- Select task Button -->
                    <div class="col-6 d-flex align-items-center cell-chekbox custom-control custom-checkbox p-0 ps-1"
                    :class="[{ 'ms-3': row.heritage }]">
                    <span class="bx bx-loader-alt bx-spin" :class="{'d-none': tasksProcessing.loadTaskId != row.id}"></span>
                    <input type="checkbox" class="custom-control-input rem-size-2"
                        :id="`${row.id}`"
                        :class="{'d-none': tasksProcessing.loadTaskId == row.id}"
                        @change="setElementSelected({ 'id': row.id, 'value': true, 'subtask': !!row.heritage, 'group_id': group.id, 'have_subtask': row.subtasks.length > 0 }, $event.target.checked === true)"
                        :unchecked-value="{ 'id': row.id, 'value': false, 'subtask': !!row.heritage, 'group_id': group.id, 'have_subtask': row.subtasks.length > 0 }"
                        :value="{ 'id': row.id, 'value': true, 'subtask': !!row.heritage, 'group_id': group.id, 'have_subtask': row.subtasks.length > 0  }"
                        :data-subtask="!!row.heritage" :data-have-subtask=" row.subtasks.length > 0"
                        :data-group="group.id"
                        :checked="tasksSelecteds[`${row.id}`]"
                        />
                    </div>
                </div>

            </div>
            <slot />

        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';

    export default {
        props: {
            row:{
                default: {id:0}
            },
            group:{
                default: {id:0}
            },
        },
        data() {
            return {
                selected: {},
                elementSelecteds: []
            }
        },
        computed: {
            ...mapState('tasks', {
                selectAll: (state) => state.selectAll,
                tasksSelecteds: (state) => state.tasksSelecteds,
                showBottomMenu: (state) => state.showBottomMenu,
                tasksProcessing: (state) => state.tasksProcessing,
                openSubtasks: (state) => state.openSubtasks,
            })
        },
        methods:{
            ...mapActions('tasks', ['toggleSelectTask', 'toggleBottomMenu', 'setShowSubtasks']),

            showSubtasks(row){
                this.setShowSubtasks({
                    data: !row.show_subtasks,
                    task: row
                });
            },
            setElementSelected(value, selected = false){
                setTimeout(()=>{
                let data = {
                    selected: selected,
                    taskId: value.id,
                    value: value
                }

                this.toggleSelectTask(data)

                }, 100);

            },
        }
    }
</script>

<style lang="scss" scoped>

.group-indicator-height-subtask {
    height: 100% !important;
}
.top-0 {
    top: 0;
}
</style>

<style src="../../../../css/show-view-scoped.css" scoped></style>
