import axios from "axios";
import ordinateTasks from "./support/ordinateTasks";
import getPageObject from "./support/getPageObject";
import Vue from 'vue';
import showAlert from "@components/alerts";
import config from '../../config/config.js'

let baseEndpoint = `${window.location.origin}/api/v1/workflow/task`;
let tasksByWorkflow = `${window.location.origin}/api/v1/workflow/find/tasks`;

async function groupsPromise(data) {
   return await axios.post(`${window.location.origin}/api/v1/workflow/group/find`, data)

}
async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export const state = {
    openSubtasks: [],
    tasksLimit: 200,
    tasksSelectedLimit: 100,
    showTasksSelectedLimitModal: false,
    dragItemIndex: null,
    dragItem: null,
    sortableList: {},

    calledPage: {},
    rowPage: {},
    showTitles: true,
    showBottomMenu: false,
    task: {},
    tasks: {},
    searchTasksList: [],
    selectAll: false,
    selectAllTaskGroupId: 0,
    tasksSelecteds: {},
    hideAllTasks: false,

    tasksProcessing:{
        loadTaskId: 0,
        loadTask: false,
        loadStoreTask: false,
        search: false,
        loadMore: false
    },

    page: {
        current_page: 1,
        next_page_url: null
    },

    ordination: {
        load: false,
        ordinate: false,
        orderAsc: true,
        columnId: 0,
        typeId: 0
    },

    filter: {
        workflow_id: 0,
        isCustom: false,
        openFilter: false,
        unread: false,
        column: null,
        columnId: null,
        columnOption: null,
        period: 0,
        search: '',
        dateStart: '',
        dateEnd: '',
        status: '',
        search_token: '',
        orderBy: null,
        orderDesc: false,
        advanced: [],
        periods: [
          1, 7, 15, 30, 60
        ],
      },

    taskDataOptions: {
        isChat:false,
        taskSelected: null,
        taskGroupSelected: null,
        taskCellSelected: null,
        taskColumn: null,
        fastMode: null,
        taskSelectedItemName: '',
        taskSelectedItemRequired: false,
        modalTitle: "Arquivos",
    }
}

export const mutations = {

    CHANGE_SELECT_ALL(state, data) {
        state.tasksSelecteds = data;
        if(Object.keys(data).length == 0) {
            state.showBottomMenu = false
            state.selectAll = false
            state.selectAllTaskGroupId = 0
        }
    },
    CHANGE_SHOW_BOTTOM_MENU(state, data) {
        state.showBottomMenu = data;
    },
    CHANGE_SELECT_TASK(state, data) {
        let tasksSelecteds = Object.keys(state.tasksSelecteds);
        let task = tasksSelecteds.find((selected)=> selected == data.taskId);
        if(task) {
            Vue.delete(state.tasksSelecteds, data.taskId)
            return
        }
        Vue.set(state.tasksSelecteds, data.taskId, data.value);
        return
    },

    CHANGE_PAGE(state, data) {
        state.page = data
    },

    CHANGE_TASKS(state, data) {
        state.tasks = data
    },

    CHANGE_TASK_SEARCH(state, data) {
        state.searchTasksList= data
    },

    CHANGE_MORE_TASKS_WITH_VALUES(state, data) {
        let groups = Object.keys(state.tasks);
        let updatedTask = null;
        groups.forEach((group) => {

            if(data[group]) {
                state.tasks[group].map((stateTask)=>{
                    updatedTask = data[group].find((utask)=> utask.id == stateTask);
                    return updatedTask ?? stateTask
                });
            //   state.tasks[group] = [...state.tasks[group], ...data[group]]

            }

        });
    },

    CHANGE_MORE_TASKS(state, data) {

      let groups = Object.keys(state.tasks);

      groups.forEach((group) => {

        if(data[group]) {

            let content = [...state.tasks[group], ...data[group]];
            state.tasks[group] =  [...new Map(content.map(item => [item['id'], item])).values()];
        }

      });

    },

    CHANGE_TASK(state, data) {
        state.task = data;

    },

    DELETE_TASK(state, dataId) {
        let groups = Object.keys(state.tasks);
          groups.forEach((group)=> {
            state.tasks[group].forEach((task, key) =>{
                if(task.id == dataId) {
                    state.tasks[group].splice(key, 1);
                }
            })
        });
    },

    DELETE_TASKS_BY_INDEX(state, data) {
        let groups = Object.keys(data);
        if(!groups) return

        groups.forEach((group)=> {
            state.tasks[group] = state.tasks[group].filter((task, key)=> data[group].indexOf(key) < 0 )
        })
    },

    STORE_TASK(state, {data, preppend = false}){
        if(data.heritage) {
          let task = state.tasks[data.workflow_group_id].find((task)=> task.id == data.heritage);
          if(task) {
            task.subtasks.push(data)
            return;
          }

        }

        if(preppend) {
            state.tasks[data.workflow_group_id].unshift(data);
            return;
        }

        state.tasks[data.workflow_group_id].push(data);
    },

    UPDATE_SUBTASKS(state, {data, task}){
        let localTask = state.tasks[data.group_id].find((localTask)=> localTask.id == task.id);

        if(localTask) {
            localTask.subtasks.forEach((subtask)=>{
                let updatedSubtask = task.subtasks.find((uSubtask)=>subtask.id == uSubtask.id);
                let subtaskKeys = Object.keys(subtask);
                subtaskKeys.forEach((key)=> {
                    Vue.set(subtask, key, updatedSubtask[key]);
                });

            });
        }
    },

    UPDATE_TASK(state, data){

        let localTask = state.tasks[data.workflow_group_id].find((localTask)=> localTask.id == data.id);
        let localSubtask = null;

        if(!localTask){
            localSubtask = state.tasks[data.workflow_group_id].find((localTask) => {  return localTask.subtasks.find((subtask)=>{ return data.id == subtask.id }) });
        }

        if(localSubtask) {
            localTask = localSubtask.subtasks.find((subtask)=>{return data.id == subtask.id});
        }

        if(localTask) {
            let keys = Object.keys(localTask );
            keys.forEach((key)=>{
                if(typeof data[key] == 'array') {
                    let arrayData = [];
                    data[key].forEach((item)=>{
                        arrayData.push(item);
                    });

                    Vue.set(localTask, key, arrayData)
                } else {

                    Vue.set(localTask, key, data[key])
                }
            });
        }

        if(state.task && state.task.id == data.id) {
            state.task = data;
        }
    },
    UPDATE_TASK_RESPONSIBLE(state, data){

        var localTask = state.tasks[data.groupId].find((localTask => { return localTask.id == data.taskId }));
        if(!localTask){
            localTask = state.tasks[data.groupId].find((localTask) => {  return localTask.subtasks.find((subtask)=>{ return data.taskId == subtask.id }) });
            localTask = localTask.subtasks.find((subtask)=>{return data.taskId == subtask.id});
        }

        if(!localTask) return;

        if(localTask) {
            Vue.set(localTask, 'responsibles', [data.user])
        }

    },
    REMOVE_TASK_COLLABORATOR(state, {task, user}) {
        let localTask = null;

        if(typeof state.tasks[task.workflow_group_id] !== undefined) {
            localTask = state.tasks[task.workflow_group_id].find((item)=> item.id == task.id);
        }

        if(task) {

            Vue.set(localTask, 'collaborators', localTask.collaborators.filter((collaborator)=> collaborator.id != user.id))
        }
    },
    UPDATE_TASK_PERSONS(state, data){

        let tasks = state.tasks[data.groupId];
        if(!tasks) return

        let task = tasks.find((task)=> task.id == data.taskId);
        if(!task) return

        Vue.set(task, 'persons', data.persons);
    },

    UPDATE_TASK_COLUMN_VALUE(state, data) {

        let tasks = state.tasks[data.groupId];
        if(!tasks) return

        let task = tasks.find((task)=> task.id == data.taskId);
        if(!task) return

        if(data.columnTypeId == 9) {
            task.name = data.value
        }

        Vue.set(task, 'column_values', data.columnValues)
    },
    UPDATE_TASK_SEQUENCE(state, {from, to}){
        let task = state.tasks[from.groupId].find((task) => task.id == from.taskId);
        if(task) {
            state.tasks[from.groupId].splice(from.index, 1);
            state.tasks[to.groupId].splice(to.index, 0, task);
            task.workflow_group_id = to.groupId;
        }
    },
    APPEND_TASKS(state, data) {
        let groups = Object.keys(data);
        groups.forEach((groupId)=>{
            if (typeof state.tasks[groupId] == undefined || state.tasks[groupId] == 'undefined') {
                Vue.set(state.tasks, groupId, []);
              }

              state.tasks[groupId].push(...data[groupId])

        })

    },

    TOGGLE_SUBTASK_VISIBILITY(state, data) {
        let task = state.tasks[data.task.workflow_group_id].find((task)=> task.id == data.task.id);

        if(task) {

            if(data.data) {
                Vue.set(task, 'show_subtasks', true);
                state.openSubtasks.splice(state.openSubtasks.indexOf(task.id), 1);
                state.openSubtasks.push(task.id);
            } else {
                Vue.set(task, 'show_subtasks', false);
                state.openSubtasks = state.openSubtasks.filter((id)=> id != task.id);
            }
        }

    },

    SEARCH_TASK(state, data) {

        let clearTaskName = '';
        let clearSearchData = data.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        state.tasks = state.tasks.filter((item)=>{
            if(typeof item.name === 'string' || item.name instanceof String){
                clearTaskName = item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                if(clearTaskName.indexOf(clearSearchData) != -1 || item.id.toString().indexOf(data) != -1){
                    return true;
                }

            } else {

                if(item.name.toString().indexOf(data.toLowerCase()) != -1 || item.id.toString().indexOf(data) != -1){
                    return true;
                }

            }
        })
    },

    CHANGE_TASKS_GROUPS(state, data) {

        let currentDataGroups = Object.keys(data.currentGroups);
        let currentData = [];
        let movedTasks = {};

        currentDataGroups.forEach((groupId)=>{
           currentData = [...currentData, ...data.currentGroups[groupId].map((taskId)=> { return {groupId: parseInt(groupId), taskId: taskId}})]
        });

        currentDataGroups.forEach((groupId) => {
            state.tasks[groupId] =  state.tasks[groupId].filter((task)=>{
                let currentTask = currentData.find((localData) => localData.taskId == task.id);
                if(currentTask) {
                    task.workflow_group_id = data.groupId;
                    if(!movedTasks[currentTask.groupId]) {
                        movedTasks[currentTask.groupId] = [];
                    }
                    movedTasks[currentTask.groupId].push(task);
                    return false;
                }
                return true
            })
        });

        Object.keys(movedTasks).forEach((groupId) => {
           state.tasks[data.groupId].push(...movedTasks[groupId])
        })



    //    let groups = Object.keys(state.tasks);
    //    groups.forEach((group) => {
    //         state.tasks[group].forEach((task, key)=> {

    //             if(data.currentGroups[group] && data.currentGroups[group].indexOf(task.id) != -1 && group != data.groupId) {
    //                 task.workflow_group_id = data.groupId;
    //                 task.sequence = state.tasks[group].length + 1;

    //                 state.tasks[data.groupId].push(task);
    //             }

    //         });

    //    });

    //    let currentGroupsKeys = Object.keys(data.currentGroups);

    //    groups.forEach((group) => {

    //         state.tasks[group] = state.tasks[group].filter((task)=> {

    //             if(data.currentGroups[group]) {

    //                 if(data.currentGroups[group].indexOf(task.id) != -1 && group != data.groupId) {//&& task.workflow_group_id != data.groupId
    //                     task.workflow_group_id = group;
    //                     return false;

    //                 }
    //             }

    //             return true;

    //         });

    //     });

    },
    ROLLBACK_CHANGE_TASKS_GROUPS(state, data) {
         let currentDataGroups = Object.keys(data.currentGroups);
         let currentData = [];
         currentDataGroups.forEach((groupId)=>{
            currentData = [...currentData, ...data.currentGroups[groupId].map((taskId)=> { return {groupId: groupId, taskId: taskId}})]

         });

        let rollbackItems = {};
        state.tasks[data.groupId] =  state.tasks[data.groupId].filter((task)=>{
            let currentTask = currentData.find((localData) => localData.taskId == task.id);

            if(currentTask) {
                task.workflow_group_id = currentTask.groupId;
                if(!rollbackItems[currentTask.groupId]) {
                    rollbackItems[currentTask.groupId] = [];
                }
                rollbackItems[currentTask.groupId].push(task);
                return false;
            }

            return true;

         })
         Object.keys(rollbackItems).forEach((groupId) => {
            state.tasks[groupId].push(...rollbackItems[groupId])
         })

    },

    // FILTER
    CHANGE_FILTER(state, data) {
        state.filter = data
    },

    CHANGE_CALLED_PAGE(state, data) {
        state.calledPage = data;
    },
    CHANGE_ROW_PAGE(state, data) {
        state.rowPage = data;
    },

    // ORDINATION
    CHANGE_ORDINATION(state, data) {

        if (typeof data.load !== 'undefined') state.ordination.load = data.load;
        if (typeof data.ordinate !== 'undefined') state.ordination.ordinate = data.ordinate;
        if (typeof data.orderAsc !== 'undefined') state.ordination.orderAsc = data.orderAsc;
        if (typeof data.columnId !== 'undefined') state.ordination.columnId = data.columnId;
        if (typeof data.typeId !== 'undefined') state.ordination.typeId = data.typeId;
    },

    ORDINATE_TASKS(state, group) {
        state.tasks = ordinateTasks(state.tasks, state.ordination)
    },

    CHANGE_TASKS_PROCESSING(state, data) {
      state.tasksProcessing = data;
    },
    CHANGE_TASKS_PROCESSING_LOAD(state, data) {
      state.tasksProcessing.loadTask = data;
    },
    CHANGE_TASK_PROCESSING_LOAD(state, data) {
      state.tasksProcessing.loadTaskId = data;
    },
    CHANGE_STORE_TASKS_PROCESSING_LOAD(state, data) {
      state.tasksProcessing.loadStoreTask = data;
    },
    CHANGE_TASKS_PROCESSING_SEARCH(state, data) {
      state.tasksProcessing.search = data;
    },
    CHANGE_TASKS_PROCESSING_MORE(state, data) {
      state.tasksProcessing.loadMore = data;
    },

    TOGGLE_TITLES_VISIBILITY(state, data) {
      state.showTitles = data;
    },

    // Drag and drop
    TASK_DROP(state, data) {
      const taskID = data.event.dataTransfer.getData("taskID");
      const groupID = data.event.dataTransfer.getData("groupID");

      const item = state.tasks[groupID].find((item) => item.id == taskID);
      item.workflow_group_id = data.groupId;
    },
    TASK_DRAG_OVER(state, data) {
        if(data.index != state.dragItemIndex) {
          let itemsWithoutDrag = state.tasks[state.dragItem.workflow_group_id].filter((item)=> item.id != state.dragItem.id);
          itemsWithoutDrag.splice(data.index, 0, state.dragItem)
          state.tasks[state.dragItem.workflow_group_id] = itemsWithoutDrag;
        }
    },
    TASK_DRAG_START(state, data) {
        state.dragItem = data.task
        state.dragItemIndex = data.taskKey
        state.sortableList = data.sortableList;
    },

    APPEND_UPLOADED_FILE(state, {task, file}) {
        var localTask = state.tasks[task.workflow_group_id].find((localTask => { return localTask.id == task.id }));
        if(!localTask){
            localTask = state.tasks[task.workflow_group_id].find((localTask) => {  return localTask.subtasks.find((subtask)=>{ return task.id == subtask.id }) });
            localTask = localTask.subtasks.find((subtask)=>{return task.id == subtask.id});
        }

        if(!localTask) return;

        localTask.files.push(file);

    },
    CHANGE_TASK_FILES_OPTIONS(state, {task, column, clear, isChat}) {
        if(clear) {
            state.taskDataOptions.isChat = false;
            state.taskDataOptions.taskSelected = null;
            state.taskDataOptions.taskGroupSelected = null;
            state.taskDataOptions.taskSelectedItemName = '';
            state.taskDataOptions.taskSelectedItemRequired = false;
            return;
        }

        state.taskDataOptions.isChat = isChat;
        state.taskDataOptions.taskSelected = task.id;
        state.taskDataOptions.taskGroupSelected = task.workflow_group_id;
        state.taskDataOptions.taskSelectedItemName = column.name;
        state.taskDataOptions.taskSelectedItemRequired = column.required? true : false;
    },
    CHANGE_TASK_CHECKLISTS_OPTIONS(state, {task, column, columnValueId, fastMode, clear}) {
        if(clear) {
            state.taskDataOptions.taskSelected = null;
            state.taskDataOptions.taskGroupSelected = null;
            state.taskDataOptions.taskCellSelected = null;
            state.taskDataOptions.fastMode = false;
            state.taskDataOptions.taskColumn = null;
            state.taskDataOptions.modalTitle = "Novo Checklist";

            return;
        }

        state.taskDataOptions.taskSelected = task.id;
        state.taskDataOptions.taskGroupSelected = task.workflow_group_id;
        state.taskDataOptions.taskColumn = column;
        state.taskDataOptions.taskCellSelected = columnValueId;
        state.taskDataOptions.fastMode = fastMode;
        state.taskDataOptions.modalTitle = fastMode ? "Checklists" : "Novo Checklist";
    },

    APPEND_TASK_GROUP(state, id) {
       Vue.set(state.tasks, id, []);
    },
    RESET_PAGE(state) {
        state.page = {
            current_page: 1,
            next_page_url: null
        }
        state.calledPage = {};
    },
    CHANGE_TASK_LIMIT_MODAL(state, data) {
        state.showTasksSelectedLimitModal = data;
    },
    TOGGLE_HIDE_ALL_TASKS(state, data){
        state.hideAllTasks = data;
    }
}

export const actions = {
    toggleHideAllTasks({commit, state}, data) {
        commit('TOGGLE_HIDE_ALL_TASKS', data);
    },
    // Drag and drop

    onTaskDrop({state, commit}, data) {
      commit('TASK_DROP', data)
    },

    onTaskStartDrag({state, commit}, data) {
      setTimeout(()=> data.event.target.classList.add('task-dragging'), 0)

      data.event.dataTransfer.dropEffect = "move";
      data.event.dataTransfer.effectAllowed = "move";
      data.event.dataTransfer.setData("taskID", data.task.id);
      data.event.dataTransfer.setData("groupID", data.groupId);
      data.event.dataTransfer.setData("itemkey", data.taskKey);
      data.sortableList = {};

      let groups = Object.keys(state.tasks);
      groups.forEach((group)=>{
          data.sortableList[group] = window.document.querySelector(`.sortable-list-${group}`);
      })
      commit('TASK_DRAG_START', data)

    },
    onTaskDragEnd({state, commit}, data){
        setTimeout(()=> data.event.target.classList.remove('task-dragging'), 0)
    },
    onTaskDragOver({state, commit}, data) {
        // return
      const dragItem = state.sortableList[data.groupId].querySelector(".task-dragging")
      const tasks = [...state.sortableList[data.groupId].querySelectorAll(".task-item:not(.task-dragging)")]
      let nextTask = tasks.find((task)=>{
        let taskRect = task.getBoundingClientRect()
        return data.event.clientY <= taskRect.top + task.offsetHeight / 2;

      });

      if(nextTask) {
        let sortableList =  window.document.querySelector(`.sortable-list-${data.groupId} .vue-recycle-scroller__item-wrapper`);
        sortableList.insertBefore(dragItem.closest('.vue-recycle-scroller__item-view'), nextTask.closest('.vue-recycle-scroller__item-view'));

      }
    },

    setShowSubtasks({ commit, dispatch }, {task, data, forceClose = false} ) {
        let payload = {};
        let taskKeys = Object.keys(task);
        let hasSubtasks = false;

        if(typeof task.subtasks != 'undefined') {
            hasSubtasks = task.subtasks.length > 0;
        }

        taskKeys.forEach((key)=> {
            payload[key] = task[key];
        });


        if(hasSubtasks || forceClose) {
            payload.show_subtasks = data;
            payload.task_id = payload.id;
            payload.force_update = true;
            dispatch('updateTask', payload);
        }

        commit('TOGGLE_SUBTASK_VISIBILITY', {task, data})
    },
    setTasks({ commit }, data) {
        commit('CHANGE_TASKS',  data)
    },
    async listTasksByWorkflow({ state, commit, dispatch }, data) {

            commit('CHANGE_TASKS_PROCESSING_LOAD', true);

            const groupsResponse = await groupsPromise(data)

            let groups = [];

            if(!groupsResponse.data.error) {
                groups = groupsResponse.data.data
                dispatch('resetCalledPageByGroups', groups);
            }

            if(groups.length < 1) {
                return;
            }

            const response = axios.post(`${baseEndpoint}/find`, data)
            .then((response)=>{
                if(!response.data.error && ((response.data.search_token && response.data.search_token == state.filter.search_token) || !state.filter.search_token)) {
                    let page = JSON.parse(JSON.stringify(state.page))
                    let calledPage = JSON.parse(JSON.stringify(state.calledPage));
                    let tasks = response.data.data;
                    let content = {}
                    groups.forEach((group)=>{
                        content[group.id] = [];
                    })

                    tasks.data.forEach((task)=>{
                        if(content[task.workflow_group_id]) {
                            content[task.workflow_group_id].push(task)
                            calledPage[task.workflow_group_id] = tasks.current_page;
                            page[task.workflow_group_id] = {
                                current_page: tasks.current_page,
                                next_page_url: tasks.next_page_url,
                            }
                        } else {
                            content[task.workflow_group_id] = [];
                            content[task.workflow_group_id].push(task)
                        }
                    });

                    commit('CHANGE_TASKS',  content)
                    commit('CHANGE_PAGE', page)
                    commit('CHANGE_CALLED_PAGE', calledPage);
                }

                commit('CHANGE_TASKS_PROCESSING_LOAD', false);

            })
            .catch((error)=>{
                console.log('listTasksByWorkflow error :', error );
                commit('CHANGE_TASKS_PROCESSING_LOAD', false);
            });


    },

    async loadMoreTasks({ state, commit, dispatch }, data) {
      try {

        if(typeof state.page[data.group_id].next_page_url !== 'undefined' && state.page[data.group_id].next_page_url && state.calledPage[data.group_id] + 1 == data.page) {
            let page = JSON.parse(JSON.stringify(state.page));
            let calledPage = JSON.parse(JSON.stringify(state.calledPage));

            calledPage[data.group_id] = calledPage[data.group_id] + 1;

            commit('CHANGE_TASKS_PROCESSING_MORE', true);
            commit('CHANGE_CALLED_PAGE', calledPage);


            dispatch('setRowPage', {groupId: data.group_id, index: data.index});
            const response = await axios.post(state.page[data.group_id].next_page_url, data)
            if (!response.data.error) {
                let tasks = response.data.data;
                let content = {}
                tasks.data.forEach((task)=>{
                    if(content[task.workflow_group_id]) {
                        content[task.workflow_group_id].push(task)
                    } else {
                        content[task.workflow_group_id] = [];
                        content[task.workflow_group_id].push(task)
                    }
                });

                calledPage[data.group_id] = tasks.current_page;
                commit('CHANGE_CALLED_PAGE', calledPage);
                page[data.group_id] =  getPageObject(tasks);
                commit('CHANGE_MORE_TASKS',  content)
                commit('CHANGE_PAGE', page)
                commit('CHANGE_TASKS_PROCESSING_MORE', false);

                if(calledPage[data.group_id] > 1) {
                    dispatch('sortTasks');
                }

                return true;
              }

            }

            commit('CHANGE_TASKS_PROCESSING_MORE', false);
            return false;

      } catch(error) {
        console.error('erro ao carregar mais tasks', error);
        commit('CHANGE_TASKS_PROCESSING_MORE', false);
        return false;
      }
    },
    resetCalledPageByGroups({commit, dispatch}, groups) {
        let calledPage = JSON.parse(JSON.stringify(state.calledPage));
        let rowPage = JSON.parse(JSON.stringify(state.rowPage));
        let page = {};

        groups.forEach((group)=>{
            calledPage[group.id] = 0;
            rowPage[group.id] = 0;
            page[group.id] = {
                current_page: 0,
                next_page_url: `${baseEndpoint}/find?page=1&group_id=${group.id}`

            };
        });

        commit('CHANGE_CALLED_PAGE', calledPage);
        commit('CHANGE_ROW_PAGE', rowPage)
        commit('CHANGE_PAGE', page)
    },
    setRowPage({ commit }, {groupId, index}) {
        let rowPage = JSON.parse(JSON.stringify(state.rowPage));
        rowPage[groupId] = index
        commit('CHANGE_ROW_PAGE', rowPage)
    },

    async findTask({ state, commit }, taskId) {
        commit('CHANGE_TASK_PROCESSING_LOAD', taskId);

        const response = await axios.get(`${baseEndpoint}/${taskId}`)
        .then((response)=>{
            if(!response.data.error) {
                commit('CHANGE_TASK', response.data.data)
                commit('UPDATE_TASK', response.data.data)
            }

            commit('CHANGE_TASK_PROCESSING_LOAD', 0);
        })
        .catch(({response})=>{
            commit('CHANGE_TASK_PROCESSING_LOAD', 0);
        });
    },

    async searchTasks({ state, commit }, { workflowId, columnId = null, search = '', taskId = null, newColumn = false }) {

        commit('CHANGE_TASKS_PROCESSING_SEARCH', true);
        let data = {workflow_id: workflowId, workflow_column_id: columnId, search, task_id: taskId,new_column: newColumn };
        axios.post(`${baseEndpoint}/search`, data)
        .then((response)=>{
            if(!response.data.error) {
                commit('CHANGE_TASK_SEARCH', response.data.data);
                commit('CHANGE_TASKS_PROCESSING_SEARCH', false);
                return;
            }

            commit('CHANGE_TASK_SEARCH', []);
            commit('CHANGE_TASKS_PROCESSING_SEARCH', false);
        })
        .catch(({response})=>{
            commit('CHANGE_TASKS_PROCESSING_SEARCH', false);
        });
    },

    async deleteTask({ state, commit }, taskId) {
        commit('CHANGE_TASKS_PROCESSING_LOAD', true);

        const response = await axios.delete(`${baseEndpoint}/${taskId}`)
        .then((response)=>{
            if(!response.data.error) {
                commit('DELETE_TASK', taskId)
            }

            commit('CHANGE_TASKS_PROCESSING_LOAD', false);
        })
        .catch(({response})=>{
            commit('CHANGE_TASKS_PROCESSING_LOAD', false);
        });
    },

    async storeTask({ state, commit, dispatch }, data) {
      try {
          commit('CHANGE_STORE_TASKS_PROCESSING_LOAD', true);
          let taskId = null
          const response = await axios.post(`${baseEndpoint}`, data)
          if(!response.data.error) {
              let data = response.data.data;
              commit('STORE_TASK', {data: response.data.data})
              if(data.id) {
                taskId = data.id;
              }

              if(data.heritage) {
                let parent = state.tasks[data.workflow_group_id].find((task)=> task.id == data.heritage);
                if(parent) {
                    dispatch('setShowSubtasks', {task: parent, data: true});
                }
              }

          }

          commit('CHANGE_STORE_TASKS_PROCESSING_LOAD', false);
          return taskId;

      } catch(error) {
        console.log(error);
          commit('CHANGE_STORE_TASKS_PROCESSING_LOAD', false);
          return null;

      }
    },

    async updateTask({ state, commit }, data) {
        commit('CHANGE_TASK_PROCESSING_LOAD', data.task_id);

        const response = await axios.patch(`${baseEndpoint}/${data.task_id}`, data)
        .then((response)=>{
            if(!response.data.error) {
                commit('UPDATE_TASK', response.data.data)
                commit('CHANGE_TASK_PROCESSING_LOAD', 0);
                return true;
            }

            commit('CHANGE_TASK_PROCESSING_LOAD', 0);
            return false;
        })
        .catch(({response})=>{
            commit('CHANGE_TASK_PROCESSING_LOAD', 0);
            return false;
        });
    },

    async updateSubtasks({ state, commit }, data) {
        try {
            commit('CHANGE_TASK_PROCESSING_LOAD', data.task_id);

            let tasks = JSON.parse(JSON.stringify(state.tasks));
            let task =  tasks[data.group_id].find((task)=> task.id == data.task_id);
            if(task) {
                task.subtasks.forEach((subtask)=> {
                    let subtaskColumnValues = subtask.column_values.find((columnValue)=> columnValue.workflow_column_id == data.column_id && columnValue.workflow_task_id == subtask.id);
                    if(subtaskColumnValues) {

                        if(data.column_type_id == 12) {
                            subtaskColumnValues.currency = data.value;
                        } else if(data.column_type_id == 13) {
                            subtaskColumnValues.number = data.value;
                        } else {
                            subtaskColumnValues.value = data.value;
                        }
                    }

                });


                commit('UPDATE_SUBTASKS', {data, task})

                if(task.show_subtasks) {
                    commit('TOGGLE_SUBTASK_VISIBILITY', {task, data: false});
                    setTimeout(()=> {commit('TOGGLE_SUBTASK_VISIBILITY', {task, data: true})}, 100);
                }
            }

            setTimeout(()=> {commit('CHANGE_TASK_PROCESSING_LOAD', 0)}, 100);
        } catch(error) {
            commit('CHANGE_TASK_PROCESSING_LOAD', 0);
        }
    },

    async updateTaskResponsible({ state, commit }, {groupId, taskId, user}) {

        let backupResponsibles = [];
        let task = null;

        if(typeof state.tasks[groupId] !== undefined) {
            task = state.tasks[groupId].find((item)=> item.id == taskId);
        }

        if(task) {
            backupResponsibles = task.responsibles;
        }

        let data = {
           user_id: user.id,
           task_id: taskId
        }
        commit('UPDATE_TASK_RESPONSIBLE', {groupId: groupId, taskId: taskId, user: user});
        const response = axios.patch(`${baseEndpoint}/responsible`, data)
        .then((response)=>{
            commit('CHANGE_TASKS_PROCESSING_LOAD', false);
        })
        .catch(({response})=>{
            if(backupResponsibles.length > 0) {
                let backupResponsible = backupResponsibles[0];
                commit('UPDATE_TASK_RESPONSIBLE', {groupId: groupId, taskId: taskId, user: backupResponsible});
            }
        });
    },

    async updateTaskPersons({ state, commit }, {persons, taskId, groupId}) {
        commit('UPDATE_TASK_PERSONS', {persons, taskId, groupId});
    },

    async updateTaskColumnValue({ state, commit }, {group_id, task_id, column_type_id, column_value_id, value, description}) {
        let tasks = state.tasks[group_id];
        if(!tasks) return

        let task = tasks.find((task)=> task.id == task_id);
        if(!task) return

        let columnValues = JSON.parse(JSON.stringify(task.column_values));
        if(!columnValues) return

        columnValues.map((columnValue)=> {
            if(columnValue.id == column_value_id) {
                columnValue.description = description;
                if(column_type_id == 12) {
                    columnValue.currency = value;
                } else if(column_type_id == 13) {
                    columnValue.number = value;
                } else {
                    columnValue.value = value;
                }
            }

            return columnValue;
        });

        commit('UPDATE_TASK_COLUMN_VALUE', {taskId: task_id, groupId: group_id, columnValues, columnTypeId: column_type_id, value})

    },

    async searchTask({ state, commit }, data) {
        commit('CHANGE_TASKS_PROCESSING_LOAD', true);

        const response = axios.get(`${baseEndpoint}/search/${data}`)
        .then((response)=>{
            if(!response.data.error) {
                commit('SEARCH_TASK', response.data.data)
            }

            commit('CHANGE_TASKS_PROCESSING_LOAD', false);
        })
        .catch(({response})=>{
            commit('CHANGE_TASKS_PROCESSING_LOAD', false);
        });

    },

    /** @param ordination -  Object for ordinate tasks {load, ordinate, orderAsc, columnId, typeId} */
    setOrdinationTasks({ commit }, ordination) {
        commit('CHANGE_ORDINATION', ordination);
    },

    setSelectAllTasks({ state, commit }, {value, groupId}) {
        let limit = state.tasksSelectedLimit;
        let overSelect = false;
        let data = {};
        if(value){
            // state.tasksSelecteds = state.tasksSelecteds.filter((selected)=>  selected.group_id !== groupId)

            state.tasks[`${groupId}`].forEach((task, key)=>{
                // Vue.set(state.tasksSelecteds, `${task.id}`, true);
                // state.tasksSelecteds.push({ 'id': task.id, 'value': true, 'subtask': !!task.heritage, 'group_id': groupId, 'have_subtask': task.subtasks.length > 0  });

                // if(key < limit) {
                    data[task.id] = {
                        'id': task.id,
                        'value': true,
                        'subtask': !!task.heritage,
                        'group_id': groupId,
                        'have_subtask': task.subtasks.length > 0
                    };

                    data = {...state.tasksSelecteds, ...data};
                // } else {
                //     overSelect = true;
                // }

            });
        } else {
            data = {};
            let selecteds = Object.keys(state.tasksSelecteds);
            selecteds.forEach((taskId)=>{
                let task = state.tasksSelecteds[taskId];
                if(task.group_id !== groupId) {
                    data[task.id] = task;
                }
            });
        }

        // if(overSelect) {
        //     showAlert(true, `Limete de ${limit} selecionados atingido`);
        // }

        commit('CHANGE_SELECT_ALL', data);
    },

    toggleBottomMenu({commit}, value) {
        commit('CHANGE_SHOW_BOTTOM_MENU', value)
    },
    toggleSelectTask({commit}, {taskId, selected, value}) {
        let tasksSelecteds = Object.keys(state.tasksSelecteds);
        let task = tasksSelecteds.find((selected)=> selected == taskId);
        let data = {...state.tasksSelecteds};
        if(task) {
            delete data[taskId];
            // Vue.delete(state.tasksSelecteds, data.taskId)
            commit('CHANGE_SELECT_ALL', data);
            return
        }

        data[taskId] = value;
        // Vue.set(state.tasksSelecteds, data.taskId, data.value);
        commit('CHANGE_SELECT_ALL', data);
        return

        commit('CHANGE_SELECT_TASK', {taskId, selected, value});
    },

    clearSelectedTasks({ commit }) {
        commit('CHANGE_SELECT_ALL', {});

        // Uncheck the select all checkbox
        let elements = window.document.querySelectorAll('.select-all input[type="checkbox"]');
        console.log('elements :>> ', elements);
        elements.forEach((element) => {
            if(element.checked) {
                element.click();
            }
        });
    },

    async duplicateSelectedRows({ commit, state, dispatch }, { withData = false, withInteractions = false }) {
        let checkLimit = await dispatch('checkSelectedLimit');
        if(!checkLimit) {
            return
        }

        var dataItems = [];

        let keys = Object.keys(state.tasksSelecteds);
        keys.forEach((key)=>{
           let element = state.tasksSelecteds[key];
            if (element.value) {
                dataItems.push(element.id);
            }
        })

        var data = {
          withData: withData,
          withInteractions: withInteractions,
          task_ids: dataItems
        }

        if (dataItems.length > 0) {

          Vue.swal
            .fire({
              position: "center-center",
              html: "<i class='bx bx-loader-alt bx-spin' style='font-size: 5rem;'></i><p>Duplicando</p>",
              showCancelButton: false,
              showConfirmButton: false,
              width: 300,
              padding: 50,
            });

          const duplicate = axios.post('/api/v1/workflow/task/duplicate', data)
            .then((response) => {
              if (!response.data.error) {
                let tasksByGroups = {};
                let groups = [];
                response.data.data.forEach((task) => {
                  if (typeof tasksByGroups[task.workflow_group_id] == undefined || typeof tasksByGroups[task.workflow_group_id] == 'undefined') {
                    tasksByGroups[task.workflow_group_id] = [];
                  }

                  tasksByGroups[task.workflow_group_id].push(task)
                  if(groups.indexOf(task.workflow_group_id) < 0) {
                    groups.push(task.workflow_group_id);
                  }
                });


                commit('APPEND_TASKS', tasksByGroups);
                commit('CHANGE_SELECT_ALL', {});
                groups.forEach((groupId)=>{
                    let element = window.document.querySelector(`#select_all_${groupId}`);
                    if(element && element.checked )  {
                        element.checked = false;
                    }
                });
                Vue.swal.close();
              }
            })
            .catch((error) => {
              Vue.swal.close();
              console.log(error);
              if (error.response) {
                showAlert(true, error.response.data.message);
              }
            });
        }

      },

    async convertSelectedTasks({ commit, state, dispatch }, {convertToSubtask, parentTaskId = null}) {
        let checkLimit = await dispatch('checkSelectedLimit');
        if(!checkLimit) {
            return
        }

        var data = {
            subtask_convert: convertToSubtask,
            parent_task_id: parentTaskId,
            tasks_ids: []
        };

        let keys = Object.keys(state.tasksSelecteds);
        keys.forEach((key)=>{
           let element = state.tasksSelecteds[key];

            if (element.value) {

                data.tasks_ids.push(element.id);

            }

        });

      Vue.swal
        .fire({
          title: "Confirma a conversão?",
          text: "Converter para "+(convertToSubtask? 'subtarefa?':'tarefa?'),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Sim, converter!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            if (data.tasks_ids.length > 0) {
              Vue.swal
                .fire({
                  position: "center-center",
                  html: "<i class='bx bx-loader-alt bx-spin' style='font-size: 5rem;'></i><p>Convertendo</p>",
                  showCancelButton: false,
                  showConfirmButton: false,
                  width: 300,
                  padding: 50,
                });

              const convertSelectedRows = axios
                .post(window.location.origin + "/api/v1/workflow/task/convert", data)
                .then((response) => {
                  if (!response.data.error) {
                    let task = response.data.data;
                    showAlert(false, '');

                    if(convertToSubtask) {
                        commit('UPDATE_TASK', task);
                        data.tasks_ids.forEach((id)=>{
                            commit('DELETE_TASK', id);
                        })

                        commit('CHANGE_SELECT_ALL', {});
                        return;
                    }

                    let tasks = state.tasks;
                    let groups = Object.keys(tasks);
                    let content = {};
                    task.show_subtasks = true;

                    groups.forEach((group)=>{
                        let parentTask = tasks[group].find((task) => {
                            return task.subtasks.find((subtask)=>{
                                return data.tasks_ids.indexOf(subtask.id) != -1
                            })
                        });

                        if(parentTask) {
                            content[group] = parentTask.subtasks.filter((task)=> data.tasks_ids.indexOf(task.id) != -1);
                            content[group].map((task)=>{
                                task.heritage = null;
                            });

                        }
                    });

                    commit('UPDATE_TASK', task);
                    commit('APPEND_TASKS', content);
                    commit('CHANGE_SELECT_ALL', {});
                  }
                  Vue.swal.close();
                })
                .catch((error) => {
                    Vue.swal.close();
                    console.error(error);
                  if (error.response) {
                    showAlert(true, error.response.data.message);
                  }
                });
            }
          }
        });
    },
    async deleteSelectedTasks({ commit, state, dispatch }) {
        let checkLimit = await dispatch('checkSelectedLimit');
        if(!checkLimit) {
            return
        }

        var data = [];
        var hasSubtasks = false;
        let keys = Object.keys(state.tasksSelecteds);
        keys.forEach((key)=>{

           let element = state.tasksSelecteds[key];

            if (element.value) {

                data.push(element.id);

            }

            if (element.have_subtask) {

                hasSubtasks = true;

            }
        })

        Vue.swal
          .fire({
            title: "Confirma a exclusão?",
            text: hasSubtasks
                ? "A seleção possui subtarefas que serão deletadas, você não será capaz de reverter isso!"
                : "Você não será capaz de reverter isso!",

            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Sim, deletar!",
            cancelButtonText: "Cancelar",
          })
          .then((result) => {
            if (result.value) {
              if (keys.length > 0) {
                Vue.swal
                .fire({
                  position: "center-center",
                  html: "<i class='bx bx-loader-alt bx-spin' style='font-size: 5rem;'></i><p>Removendo</p>",
                  showCancelButton: false,
                  showConfirmButton: false,
                  width: 300,
                  padding: 50,
                });

                const deleteSelectedRows = axios
                  .post(window.location.origin + "/api/v1/workflow/task/delete/multiple", { task_ids: data })
                  .then((response) => {
                    if (!response.data.error) {

                      let tasks = JSON.parse(JSON.stringify(state.tasks));
                      let groups = Object.keys(tasks);
                      let content = {};

                      groups.forEach((group)=>{
                          tasks[group].forEach((task)=>{
                            if(typeof task.subtasks != 'undefined') {
                                task.subtasks = task.subtasks.filter((subtask)=> data.indexOf(subtask.id) < 0);
                                if(task.subtasks.length == 0 && task.show_subtasks) {
                                    dispatch('setShowSubtasks', {task, data: false, forceClose: true })
                                }
                            }
                          });

                          let filteredTasks = tasks[group].filter((task)=> data.indexOf(task.id) < 0);

                          content[group] = filteredTasks
                      });

                      commit('CHANGE_TASKS', content);
                      commit('CHANGE_SELECT_ALL', {});
                    }
                    Vue.swal.close();
                  })
                  .catch((error) => {
                    Vue.swal.close();
                    if (error.response) {
                      console.log(error.response);
                      showAlert(true, error.response.data.message);
                    }
                  });
              }
            }
          });
      },
      async moveSelectedTasks({ commit, state, dispatch }, group) {
        let checkLimit = await dispatch('checkSelectedLimit');
        if(!checkLimit) {
            return
        }

        Vue.swal
        .fire({
          title: "Confirma?",
          text: "Mover tarefas"+(group? ' selecionadas para o grupo "'+group.name + '"?' :'?'),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Sim, mover!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
              // open load modal
              Vue.swal
              .fire({
                  position: "center",
                  html: "<i class='bx bx-loader-alt bx-spin' style='font-size: 5rem;'></i><p>Movendo</p>",
                  showCancelButton: false,
                  showConfirmButton: false,
                  width: 300,
                  padding: 50,
              });

              setTimeout(()=>{
                  let currentGroups = {};
                  let tasksIds = Object.keys(state.tasksSelecteds);
                  if(!tasksIds) return;

                  tasksIds.forEach((taskId)=>{
                      let selectedItem = state.tasksSelecteds[taskId]
                      if(!currentGroups[selectedItem.group_id]) {
                          currentGroups[selectedItem.group_id] = [];
                      }

                      currentGroups[selectedItem.group_id].push(parseInt(taskId));

                  });

                  commit('CHANGE_TASKS_GROUPS', {groupId: group.id, currentGroups})
                  commit('CHANGE_SELECT_ALL', {});
                  Vue.swal.close();

                  let data = {
                    group_id: group.id,
                    tasks_ids: tasksIds
                  }
                  axios.post(`${baseEndpoint}/move`, data)
                    .catch((error)=>{
                        Vue.swal.close();
                        console.log(error);
                        commit('ROLLBACK_CHANGE_TASKS_GROUPS', {groupId: group.id, currentGroups})

                    });
              }, 100)

          };
        });


      },
      updateTasksColumnSequence({ commit, state }, data) {

        let sequence = 0;
        let header = null;
        let groups = Object.keys(state.tasks);
        let tasks = JSON.parse(JSON.stringify(state.tasks));
        groups.forEach((group)=>{
            tasks[group].forEach((task)=>{
                // sort cells
                task.column_values.forEach((value)=>{
                    header = data.find((item)=> item.header_id == value.workflow_column_id);
                    value.sequence = header.sequence
                });

                task.column_values.sort((a, b)=>{
                    if(a.sequence < b.sequence) {
                        return -1;
                    }
                    if(a.sequence > b.sequence) {
                        return 1;
                    }
                    return 0
                });

                // sort sum row
                // this.columnSum[group].sort((a, b)=>{
                //     let aIndex = data.findIndex((item)=> item.header_id == a.column_id)
                //     let bIndex = data.findIndex((item)=> item.header_id == b.column_id)

                //     if(aIndex < bIndex) {
                //         return -1;
                //     }
                //     if(aIndex > bIndex) {
                //         return 1;
                //     }
                //     return 0
                // });

            });
        });

        commit('CHANGE_TASKS', tasks);
      },
      setFilter({commit, state, dispatch}, {
            workflowId,
            limit = null,
            search = '',
            period = null,
            status = null,
            dateStart = null,
            dateEnd = null,
            unread = false,
            column = null,
            columnOption = null,
            orderBy = null,
            orderDesc = false,
            tasksIds = [],
            advanced = [],
            searchWithToken = false
        }
        ) {

        let localFilter = JSON.parse(JSON.stringify(state.filter))
        localFilter.dateStart = dateStart ? dateStart : null;
        localFilter.dateEnd = dateEnd ? dateEnd : null;
        localFilter.period = period ? period : null;
        localFilter.status = status ? status : null;
        localFilter.workflow_id = workflowId;
        localFilter.unread = unread;
        localFilter.limit = limit;
        localFilter.column = column;
        localFilter.column_id = column ? column.id : null;
        localFilter.columnOption = columnOption;
        localFilter.search = search;
        localFilter.page = 1;
        localFilter.tasks_ids = tasksIds;
        localFilter.order_by = orderBy;
        localFilter.order_desc = orderDesc;
        localFilter.advanced = advanced;

        if(searchWithToken) {
            let timestemps = (new Date()).valueOf();
            localFilter.search_token = btoa(`${timestemps}${search}`);
        }

        commit('CHANGE_FILTER', localFilter);
        dispatch('listTasksByWorkflow', localFilter);
    },
    setFilterCustom({commit, state}, data) {

        let localFilter = JSON.parse(JSON.stringify(state.filter))
        localFilter.isCustom = data;
        localFilter.period = 'Personalizado';
        commit('CHANGE_FILTER', localFilter);

    },
    clearFilter({commit}) {
        let filter = {
          workflow_id: 0,
          isCustom: false,
          openFilter: false,
          unread: false,
          column: null,
          column_id: null,
          columnOption: null,
          search: '',
          dateStart: '',
          dateEnd: '',
          status: '',
          search_token: '',
          orderBy: null,
          orderDesc: false,
          advanced: [],
        }

        commit('CHANGE_FILTER', filter);
    },
    uploadFileToTask({ commit, state }, {task, files}) {
        let data = new FormData();

        data.append('task_id', task.id);
        data.append('file', files !== null ? files[0] : '');

        let conf = config.getHeaders;
        conf['content-Type'] = 'multipart/form-data'

        const setTaskUpdate = axios
            .post(`${window.location.origin}/api/v1/files/save`, data, conf)
            .then((response) => {
            if (!response.data.error) {
                commit('APPEND_UPLOADED_FILE', {
                    task,
                    file: response.data.data
                })
                return true;
            }
            })
            .catch((error) => {
                if(error.response.status == 422) {
                    showAlert(true, error.response.data.message);
                }
                return false;
            });
    },
    setTaskFilesOptions({ commit, state }, data) {
        commit('CHANGE_TASK_FILES_OPTIONS', data)
    },
    setTaskChecklistsOptions({ commit, state }, data) {
        commit('CHANGE_TASK_CHECKLISTS_OPTIONS', data)
    },
    appendTaskGroup({ commit, state }, data) {
        commit('APPEND_TASK_GROUP', data)
    },
    websocketUpdateTask({ commit, state }, {task, preppend = false}) {
        commit('CHANGE_TASK_PROCESSING_LOAD', task.id);
        let localTask = state.tasks[task.workflow_group_id].find((localTask)=> localTask.id == task.id);
        let localSubtask = null;

        if(!localTask){
            localSubtask = state.tasks[task.workflow_group_id].find((localTask) => {  return localTask.subtasks.find((subtask)=>{ return task.id == subtask.id }) });
        }

        if(localSubtask) {
            localTask = localSubtask.subtasks.find((subtask)=>{return task.id == subtask.id});
        }

        if(!localTask && !task.heritage) {
            commit('STORE_TASK', {data: task, preppend});
            setTimeout(()=> {commit('CHANGE_TASK_PROCESSING_LOAD', 0)}, 100);
            return;
        }

        commit('UPDATE_TASK', task);
        setTimeout(()=> {commit('CHANGE_TASK_PROCESSING_LOAD', 0)}, 100);
    },
    saveCurrentTaskSequence({state, dispatch}) {
        dispatch('setOrdinationTasks', {load: true});
        let update = [];
        let groups = Object.keys(state.tasks);
        groups.forEach((groupId)=>{
            state.tasks[groupId].forEach((task, key)=>{
                update.push({
                    taskId: task.id,
                    groupId: task.workflow_group_id,
                    index: key
                });
            })
        });

        axios.patch(`${baseEndpoint}/sequence`, {tasks: update})
        .then((response)=>{
            if(!response.data.error) {
                dispatch('setOrdinationTasks', {load: false});
            }
        }).catch((error)=>{
            console.error(error);
        });
    },
    sortTaskSequence({ commit, state }, {from, to, parentId = null}) {
        commit('UPDATE_TASK_SEQUENCE', {from, to});

        let update = [];
        let stop = 0;
        if(parentId) {
            let task = state.tasks[to.groupId].find((task)=> task.id == parentId);
            if(task) {
                task.subtasks.forEach((subtask, key)=> {
                    update.push({
                        taskId: subtask.id,
                        groupId: subtask.workflow_group_id,
                        index: key
                    });
                });
            }

        } else {
            state.tasks[to.groupId].forEach((task, key)=> {

                if(stop < 3) {
                    update.push({
                        taskId: task.id,
                        groupId: task.workflow_group_id,
                        index: key
                    });
                }

                if(stop > 0) {
                    stop++;
                }

                if(task.id == from.taskId) {
                    stop = 1
                }
            });
        }



        axios.patch(`${baseEndpoint}/sequence`, {tasks: update})
            .then((response)=>{
                if(response.data.error) {
                    let toTask = {};
                    state.tasks[to.groupId].forEach((task, key)=> {
                        if(task.id == from.taskId) {
                            toTask.taskId = task.id;
                            toTask.groupId = to.groupId;
                            toTask.index = key;
                        }
                    });

                    commit('UPDATE_TASK_SEQUENCE', {from: toTask, to: from  });
                }
            }).catch((error)=>{
                console.log(error);

                let toTask = {};
                state.tasks[to.groupId].forEach((task, key)=> {
                    if(task.id == from.taskId) {
                        toTask.taskId = task.id;
                        toTask.groupId = to.groupId;
                        toTask.index = key;
                    }
                });

                commit('UPDATE_TASK_SEQUENCE', {from: toTask, to: from  });
            });
    },
    async checkSelectedLimit({state, commit}){
        let tasksSelecteds = Object.keys(state.tasksSelecteds);
        if(tasksSelecteds.length > state.tasksSelectedLimit) {
            commit('CHANGE_TASK_LIMIT_MODAL', true)
            return false
        }

        return true;
    },
    toggleTasksLimitModal({state, commit}, data) {
        commit('CHANGE_TASK_LIMIT_MODAL', data)
    },

    removeColumnValueByColumnId({state, commit}, columnId) {
        let tasks = JSON.parse(JSON.stringify(state.tasks));
        let groups = Object.keys(tasks);

        groups.forEach((groupId)=>{
            tasks[groupId].forEach((task)=>{
                task.column_values = task.column_values.filter((columnValue)=> columnValue.workflow_column_id != columnId);
                task.subtasks.forEach((subtask)=> {
                    subtask.column_values = subtask.column_values.filter((columnValue)=> columnValue.workflow_column_id != columnId);
                });
            });
        });

        commit('CHANGE_TASKS', tasks);
    },

    clearTasks({commit}) {
        commit('CHANGE_TASKS', {});
    },

    removeTaskCollaborator({commit}, {task, user}) {
        commit('REMOVE_TASK_COLLABORATOR', {task, user});
    },

    removeTask({commit,dispatch}, taskId) {
        let tasks = JSON.parse(JSON.stringify(state.tasks));
        let groups = Object.keys(tasks);
        let content = {};
        let foundTask = false;

        groups.forEach((group)=> {
            tasks[group].forEach((task)=>{
              if(typeof task.subtasks != 'undefined') {
                  task.subtasks = task.subtasks.filter((subtask)=> taskId != subtask.id);
                  foundTask = !!task.subtasks.find((subtask)=> taskId == subtask.id) || foundTask;

                  if(task.subtasks.length == 0 && task.show_subtasks) {
                      dispatch('setShowSubtasks', {task, data: false, forceClose: true })
                  }
              }
            });

            let filteredTasks = tasks[group].filter((task)=> taskId != task.id);
            foundTask = !!tasks[group].find((task)=> taskId == task.id) || foundTask;

            content[group] = filteredTasks
        });

        if(foundTask) {
            commit('CHANGE_TASKS', content);
        }

    },


    sortTasks({state, commit}) {
        var groupKeys = Object.keys(state.tasks);
        let tasks = JSON.parse(JSON.stringify(state.tasks))
        groupKeys.forEach((key)=>{
        if (tasks[key].length < 1) {
            return tasks;
        }
            var data = tasks[key].sort((a, b) => {

                switch(state.ordination.typeId){
                case 0:
                default:
                    var a_value = a.sequence;
                    var b_value = b.sequence;

                    if(state.ordination.orderAsc){
                      if(a_value < b_value) {
                          return -1;
                      }
                      if(a_value > b_value) {
                          return 1;
                      }
                      return 0;
                    } else {
                      if(a_value > b_value) {
                          return -1;
                      }
                      if(a_value < b_value) {
                          return 1;
                      }
                      return 0;
                    }
                    break;
                case 9:
                    if(state.ordination.orderAsc){

                        if(a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1
                        }
                        if(a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1
                        }
                        return 0
                    } else {
                        if(a.name.toLowerCase() > b.name.toLowerCase()) {
                            return -1
                        }
                        if(a.name.toLowerCase() < b.name.toLowerCase()) {
                            return 1
                        }
                        return 0
                    }
                    break;
                case 1:
                    var a_value = a.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    if(state.ordination.orderAsc){
                        if(a_value.value.toLowerCase() < b_value.value.toLowerCase()) {
                            return -1;
                        }
                        if(a_value.value.toLowerCase() > b_value.value.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    } else {
                        if(a_value.value.toLowerCase() > b_value.value.toLowerCase()) {
                            return -1;
                        }
                        if(a_value.value.toLowerCase() < b_value.value.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    }
                    break;
                case 2:
                    // Ordenate for name
                    var a_value =  a.responsibles.length > 0? a.responsibles[0].name + ' ' + a.responsibles[0].last_name : ''
                    var b_value =  b.responsibles.length > 0? b.responsibles[0].name + ' ' + b.responsibles[0].last_name : ''

                    if(state.ordination.orderAsc){
                      if(a_value.toLowerCase() < b_value.toLowerCase()) {
                        return -1;
                      }
                      if(a_value.toLowerCase() > b_value.toLowerCase()) {
                          return 1;
                      }
                      return 0;
                    } else {
                      if(a_value.toLowerCase() > b_value.toLowerCase()) {
                        return -1;
                      }
                      if(a_value.toLowerCase() < b_value.toLowerCase()) {
                          return 1;
                      }
                      return 0;
                      }
                    break;
                case 3:
                    // Ordenate for sequence
                    var a_value = a.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })

                    if(state.ordination.orderAsc){
                        var a_option = a_value.options.find((item)=>{ return item.id == a_value.value});
                        var b_option = b_value.options.find((item)=>{ return item.id == b_value.value});
                        var a_option_value = a_option == undefined? a_value.options.length + 2 : parseInt(a_option.sequence);
                        var b_option_value = b_option == undefined? b_value.options.length + 2 : parseInt(b_option.sequence);

                        if(a_option_value < b_option_value) {
                            return -1;
                        }

                        if(a_option_value > b_option_value) {
                            return 1;
                        }

                        return 0;
                    } else {
                        var a_option = a_value.options.find((item)=>{ return item.id == a_value.value});
                        var b_option = b_value.options.find((item)=>{ return item.id == b_value.value});
                        var a_option_value = a_option == undefined? a_value.options.length + 2 : a_option.sequence;
                        var b_option_value = b_option == undefined? b_value.options.length + 2 : b_option.sequence;

                        if(a_option_value > b_option_value) {
                            return -1;
                        }

                        if(a_option_value < b_option_value) {
                            return 1;
                        }
                        return 0;
                    }
                    break;

                case 8:
                    // Ordenate for option name
                    var a_value = a.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })

                    if(state.ordination.orderAsc && a_value.value){

                        var a_option = a_value.options.find((item)=>{ return item.id == a_value.value});
                        var b_option = b_value.options.find((item)=>{ return item.id == b_value.value});
                        var a_option_value = a_option == undefined? 0 : a_option.sequence;
                        var b_option_value = b_option == undefined? 0 : b_option.sequence;

                        if(a_option_value < b_option_value) {
                            return -1;
                        }

                        if(a_option_value > b_option_value) {
                            return 1;
                        }

                        return 0;
                    } else {
                        var a_option = a_value.options.find((item)=>{ return item.id == a_value.value});
                        var b_option = b_value.options.find((item)=>{ return item.id == b_value.value});
                        var a_option_value = a_option == undefined? 0 : a_option.sequence;
                        var b_option_value = b_option == undefined? 0 : b_option.sequence;

                        if(a_option_value > b_option_value) {
                            return -1;
                        }

                        if(a_option_value < b_option_value) {
                            return 1;
                        }
                        return 0;
                    }
                    break;
                case 4:
                case 5:
                case 7:
                    var a_value = a.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    if(state.ordination.typeId == 4){
                        var a_value = parseInt(a_value.value)? 0 : 1;
                        var b_value = parseInt(b_value.value)? 0 : 1;
                    } else {
                        var a_value = a_value.value;
                        var b_value = b_value.value;
                    }

                    if(state.ordination.orderAsc){
                        if(a_value < b_value) {
                            return -1;
                        }
                        if(a_value > b_value) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if(a_value > b_value) {
                            return -1;
                        }
                        if(a_value < b_value) {
                            return 1;
                        }
                        return 0;

                    }
                    break;
                case 6:
                    var a_value = a.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })

                    var a_value = a_value.files.length;
                    var b_value = b_value.files.length;

                    if(state.ordination.orderAsc){
                      if(a_value < b_value) {
                          return -1;
                      }
                      if(a_value > b_value) {
                          return 1;
                      }
                      return 0;

                    } else {
                      if(a_value > b_value) {
                          return -1;
                      }
                      if(a_value < b_value) {
                          return 1;
                      }
                      return 0;

                    }
                    break;
                case 10:
                    var a_value = a.collaborators.length;
                    var b_value = b.collaborators.length;

                    if(state.ordination.orderAsc){
                      if(a_value < b_value) {
                          return -1;
                      }
                      if(a_value > b_value) {
                          return 1;
                      }
                      return 0;
                    } else {
                      if(a_value > b_value) {
                          return -1;
                      }
                      if(a_value < b_value) {
                          return 1;
                      }
                      return 0;
                    }
                    break;
                case 11:
                    var a_value = a.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })

                    if(state.ordination.orderAsc){
                        if(a_value.checklistValue < b_value.checklistValue) {
                            return -1;
                        }
                        if(a_value.checklistValue > b_value.checklistValue) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if(a_value.checklistValue > b_value.checklistValue) {
                            return -1;
                        }
                        if(a_value.checklistValue < b_value.checklistValue) {
                            return 1;
                        }
                        return 0;

                    }
                    break;
                case 12:
                    var a_value = a.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    if(state.ordination.orderAsc){
                        if(a_value.currency < b_value.currency) {
                            return -1;
                        }
                        if(a_value.currency > b_value.currency) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if(a_value.currency > b_value.currency) {
                            return -1;
                        }
                        if(a_value.currency < b_value.currency) {
                            return 1;
                        }
                        return 0;

                    }
                    break;
                case 13:
                    var a_value = a.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.column_values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    if(state.ordination.orderAsc){
                        if(parseInt(a_value.number) < parseInt(b_value.number)) {
                            return -1;
                        }
                        if(parseInt(a_value.number) > parseInt(b_value.number)) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if(parseInt(a_value.number) > parseInt(b_value.number)) {
                            return -1;
                        }
                        if(parseInt(a_value.number) < parseInt(b_value.number)) {
                            return 1;
                        }
                        return 0;

                    }
                    break;

                }
              return 0
            })
        });

        commit('CHANGE_TASKS', tasks)

    }

}

export const getters = {
    hasSubtasksSelected(state){
        let hasSubtasks = 0;
        Object.keys(state.tasksSelecteds).forEach((taskId)=>{
            if(state.tasksSelecteds[taskId].subtask) {
                hasSubtasks++
            }
        });

        return hasSubtasks != 0
    },

    sortTask(state) {
        var groupKeys = Object.keys(state.tasks);
        groupKeys.forEach((key)=>{
        if (state.tasks[key].length < 1) {
            return state.tasks;
        }
            var data = state.tasks[key].sort((a, b) => {

                switch(state.ordination.typeId){
                default:
                    var a_value = a.sequence;
                    var b_value = b.sequence;

                    if(state.ordination.orderAsc){
                      if(a_value < b_value) {
                          return -1;
                      }
                      if(a_value > b_value) {
                          return 1;
                      }
                      return 0;
                    } else {
                      if(a_value > b_value) {
                          return -1;
                      }
                      if(a_value < b_value) {
                          return 1;
                      }
                      return 0;
                    }
                    break;
                case 0:
                    break;
                case 9:
                    if(state.ordination.orderAsc){

                        if(a.name.toLowerCase() < b.name.toLowerCase()) {
                            return -1
                        }
                        if(a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1
                        }
                        return 0
                    } else {
                        if(a.name.toLowerCase() > b.name.toLowerCase()) {
                            return -1
                        }
                        if(a.name.toLowerCase() < b.name.toLowerCase()) {
                            return 1
                        }
                        return 0
                    }
                    break;
                case 1:
                    var a_value = a.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    if(state.ordination.orderAsc){
                        if(a_value.value.toLowerCase() < b_value.value.toLowerCase()) {
                            return -1;
                        }
                        if(a_value.value.toLowerCase() > b_value.value.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    } else {
                        if(a_value.value.toLowerCase() > b_value.value.toLowerCase()) {
                            return -1;
                        }
                        if(a_value.value.toLowerCase() < b_value.value.toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    }
                    break;
                case 2:
                    // Ordenate for name
                    var a_value =  a.responsibles.length > 0? a.responsibles[0].name + ' ' + a.responsibles[0].last_name : ''
                    var b_value =  b.responsibles.length > 0? b.responsibles[0].name + ' ' + b.responsibles[0].last_name : ''

                    if(state.ordination.orderAsc){
                      if(a_value.toLowerCase() < b_value.toLowerCase()) {
                        return -1;
                      }
                      if(a_value.toLowerCase() > b_value.toLowerCase()) {
                          return 1;
                      }
                      return 0;
                    } else {
                      if(a_value.toLowerCase() > b_value.toLowerCase()) {
                        return -1;
                      }
                      if(a_value.toLowerCase() < b_value.toLowerCase()) {
                          return 1;
                      }
                      return 0;
                      }
                    break;
                case 3:
                    // Ordenate for sequence
                    var a_value = a.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })

                    if(state.ordination.orderAsc){
                        var a_option = a_value.options.find((item)=>{ return item.id == a_value.value});
                        var b_option = b_value.options.find((item)=>{ return item.id == b_value.value});
                        var a_option_value = a_option == undefined? a_value.options.length + 2 : parseInt(a_option.sequence);
                        var b_option_value = b_option == undefined? b_value.options.length + 2 : parseInt(b_option.sequence);

                        if(a_option_value < b_option_value) {
                            return -1;
                        }

                        if(a_option_value > b_option_value) {
                            return 1;
                        }

                        return 0;
                    } else {
                        var a_option = a_value.options.find((item)=>{ return item.id == a_value.value});
                        var b_option = b_value.options.find((item)=>{ return item.id == b_value.value});
                        var a_option_value = a_option == undefined? a_value.options.length + 2 : a_option.sequence;
                        var b_option_value = b_option == undefined? b_value.options.length + 2 : b_option.sequence;

                        if(a_option_value > b_option_value) {
                            return -1;
                        }

                        if(a_option_value < b_option_value) {
                            return 1;
                        }
                        return 0;
                    }
                    break;

                case 8:
                    // Ordenate for option name
                    var a_value = a.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })

                    if(state.ordination.orderAsc && a_value.value){

                        var a_option = a_value.options.find((item)=>{ return item.id == a_value.value});
                        var b_option = b_value.options.find((item)=>{ return item.id == b_value.value});
                        var a_option_value = a_option == undefined? 0 : a_option.sequence;
                        var b_option_value = b_option == undefined? 0 : b_option.sequence;

                        if(a_option_value < b_option_value) {
                            return -1;
                        }

                        if(a_option_value > b_option_value) {
                            return 1;
                        }

                        return 0;
                    } else {
                        var a_option = a_value.options.find((item)=>{ return item.id == a_value.value});
                        var b_option = b_value.options.find((item)=>{ return item.id == b_value.value});
                        var a_option_value = a_option == undefined? 0 : a_option.sequence;
                        var b_option_value = b_option == undefined? 0 : b_option.sequence;

                        if(a_option_value > b_option_value) {
                            return -1;
                        }

                        if(a_option_value < b_option_value) {
                            return 1;
                        }
                        return 0;
                    }
                    break;
                case 4:
                case 5:
                case 7:
                    var a_value = a.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    if(state.ordination.typeId == 4){
                        var a_value = parseInt(a_value.value)? 0 : 1;
                        var b_value = parseInt(b_value.value)? 0 : 1;
                    } else {
                        var a_value = a_value.value;
                        var b_value = b_value.value;
                    }

                    if(state.ordination.orderAsc){
                        if(a_value < b_value) {
                            return -1;
                        }
                        if(a_value > b_value) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if(a_value > b_value) {
                            return -1;
                        }
                        if(a_value < b_value) {
                            return 1;
                        }
                        return 0;

                    }
                    break;
                case 6:
                    var a_value = a.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })

                    var a_value = a_value.files.length;
                    var b_value = b_value.files.length;

                    if(state.ordination.orderAsc){
                      if(a_value < b_value) {
                          return -1;
                      }
                      if(a_value > b_value) {
                          return 1;
                      }
                      return 0;

                    } else {
                      if(a_value > b_value) {
                          return -1;
                      }
                      if(a_value < b_value) {
                          return 1;
                      }
                      return 0;

                    }
                    break;
                case 10:
                    var a_value = a.collaborators.length;
                    var b_value = b.collaborators.length;

                    if(state.ordination.orderAsc){
                      if(a_value < b_value) {
                          return -1;
                      }
                      if(a_value > b_value) {
                          return 1;
                      }
                      return 0;
                    } else {
                      if(a_value > b_value) {
                          return -1;
                      }
                      if(a_value < b_value) {
                          return 1;
                      }
                      return 0;
                    }
                    break;
                case 11:
                    var a_value = a.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })

                    if(state.ordination.orderAsc){
                        if(a_value.checklistValue < b_value.checklistValue) {
                            return -1;
                        }
                        if(a_value.checklistValue > b_value.checklistValue) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if(a_value.checklistValue > b_value.checklistValue) {
                            return -1;
                        }
                        if(a_value.checklistValue < b_value.checklistValue) {
                            return 1;
                        }
                        return 0;

                    }
                    break;
                case 12:
                    var a_value = a.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    if(state.ordination.orderAsc){
                        if(a_value.currency < b_value.currency) {
                            return -1;
                        }
                        if(a_value.currency > b_value.currency) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if(a_value.currency > b_value.currency) {
                            return -1;
                        }
                        if(a_value.currency < b_value.currency) {
                            return 1;
                        }
                        return 0;

                    }
                    break;
                case 13:
                    var a_value = a.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    var b_value = b.values.find((value)=>{ return value.workflow_column_id == state.ordination.columnId })
                    if(state.ordination.orderAsc){
                        if(parseInt(a_value.number) < parseInt(b_value.number)) {
                            return -1;
                        }
                        if(parseInt(a_value.number) > parseInt(b_value.number)) {
                            return 1;
                        }
                        return 0;

                    } else {
                        if(parseInt(a_value.number) > parseInt(b_value.number)) {
                            return -1;
                        }
                        if(parseInt(a_value.number) < parseInt(b_value.number)) {
                            return 1;
                        }
                        return 0;

                    }
                    break;

                }
              return 0
            })

            state.tasks[key] = data;
        });


        return state.tasks;
    }
}
