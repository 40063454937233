<script>
/**
 * Page-header component
 */
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
      <div class="col col-sm-12 col-md-12 col-12 page-title-box d-flex align-items-center justify-content-start p-0">
          <span class="mb-0 rem-size-3 fw-bold text-white">{{ title }}</span>
          <slot />
      </div>
      <div class="col page-title-box d-flex align-items-center justify-content-end p-0">
        <!-- <div class="page-title-right">
          <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
        </div> -->
      </div>
  </div>
  <!-- end page title -->
</template>
