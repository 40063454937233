<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="mb-3 font-size-12">
          <h6 class="overflow-text">{{ data.title }}</h6>

          <p>Descrição: {{ data.description }}</p>
          <p v-if="data.folder"><span>Pasta: </span><span>{{ data.folder }}</span> </p>
          <span>Prazo: </span
          ><span
            class="text-danger-scoped"
            :class="{
              'text-warning':
                new Date(data.deadline).getDate() == today.getDate() &&
                new Date(data.deadline).getMonth() == today.getMonth() &&
                new Date(data.deadline).getFullYear() == today.getFullYear() && new Date(data.deadline) > today && !data.complete,
              'text-info': new Date(data.deadline) > today && !data.complete,
              'text-success': data.complete,
            }"
            >{{ getDateFormat(data.deadline, true) }}</span
          >
        </div>
      </div>
    </div>
    <div class="text-end p-3">
      <b-button variant="light" size="sm" @click.prevent="closeModal">Sair</b-button>
      <router-link :to="{ path: data.redirect }" class="ml-1 btn btn-success btn-sm"
        >Visualizar
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      today: new Date(),
    };
  },
  props: {
    modalId: {
      default: "",
    },
    data: {
      default: {
        title: "",
        description: "",
        deadline: "",
        redirect: "",
        folder: null,
        complete: false
      },
    },
  },
  methods: {
    closeModal() {
      this.$root.$emit("bv::hide::modal", this.modalId);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.text-danger-scoped {
  --bs-bg-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
}
</style>
