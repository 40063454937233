<script>
import axios from "axios";
import Multiselect from "vue-multiselect";
import config from "../../../config/config";
import showAlert from "../../../components/alerts";

export default {
    components:{
        Multiselect
     },
    props: {
        workflowId: {
            default: 1
        },
        workflowFormUuid: {
            default: null
        },
    },
  data() {
    return {
      workflowFormId:null,
      previewImage: null,
      columnsLoad: false,
      workflowColumns: [],
      withDescriptionOptions: [{value: true, label: 'Sim'}, {value: false, label: 'Não'}],
      ExternalLink: '',
      groupOptions: [{}],
      value: {},
      hidden: {},
      form: {
        logoFile: null,
        title: null,
        groupSelect: null,
        ticketColumnSelected: null,
        withDescription: {value: true, label: 'Sim'},
      },
      forceTextColumnTypes: [2, 3, 4, 6, 8, 10, 11, 14, 15, 16]
    };
  },
  computed: {
    ticketColumns() {
        return this.workflowColumns.filter((column)=> {
            return column.iterable && column.workflow_column_type_id == 13;
        });
    }
  },
  methods: {
    openLogoFile: function () {
      this.$refs["logo-file"].click();
    },
    pickFile: function () {
      var input = this.$refs["logo-file"];
      var files = this.$refs["logo-file"].files;
      this.form.logoFile = this.$refs["logo-file"].files[0];

      if (files && files[0]) {
        let reader = new FileReader();
        reader.onload = (event) => {
          this.previewImage = event.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.$emit("input", files[0]);
      }
    },
    getWorkflowColumns: function (workflow_id) {
        this.columnsLoad = true
      const workflowColumns = axios
        .get(
          window.location.origin + "/api/v1/workflow/form/columns/" + workflow_id,
          config.getHeaders
        )
        .then((response) => {
          if (!response.data.error) {
            this.workflowColumns = response.data.data;
            var hidden = new Object;
            this.workflowColumns.forEach(item =>{
                if(item != null)
                hidden[item.id.toString()] = false;
            });
            this.hidden = hidden;

            this.getForm(this.workflowFormId);
          }
          this.columnsLoad = false
        })
        .catch((error) => {
            this.columnsLoad = false
          if (error.response) {
            showAlert(false, error.response.data.message);
          }
        });
    },
    getForm: function (form_id) {
        if(form_id) {
            const getForm = axios
              .get(
                window.location.origin + "/api/v1/workflow/form/show/" + form_id + "/1",
                config.getHeaders
              )
              .then((response) => {
                  var  data = response.data.data;
                if (!data.error) {

                    this.form.title = data.title;

                    if(!data.with_description) {
                        this.form.withDescription = {value: false, label: 'Não'};
                    } else {
                        this.form.withDescription = {value: true, label: 'Sim'};
                    }

                    this.groupOptions.forEach(option => {
                        if(option.id == data.workflow_group_id){
                            this.form.groupSelect = option;
                        }
                    });

                    this.workflowColumns.forEach((column)=> {
                        if(column.id == data.ticket_column_id) {
                            this.form.ticketColumnSelected = column
                        }
                    });

                    data.items_with_hidden.forEach(item =>{
                      if(item != null)
                      this.hidden[item.workflow_column_id.toString()] = item.hidden == 0? false : true;
                    });

                  this.getFormLogo(form_id);

                }
              })
              .catch((error) => {
                if (error.response) {
                  showAlert(false, error.response.data.message);
                }
              });
        }
    },
    getFormLogo: function(workflowFormId){
        const getFormLogo = axios
        .get(
          window.location.origin + "/api/v1/workflow/form/logo/" + workflowFormId,
          config.getHeaders
        )
        .then((response) => {
          if (!response.data.error) {
            this.previewImage = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            showAlert(false, error.response.data.message);
          }
        });
    },
    getGroupSelect: function(workflow_id){
        const getGroupSelect = axios
        .post(
          window.location.origin + "/api/v1/workflow/groups/" + workflow_id,
          {workflow_id: workflow_id},
          config.getHeaders
        )
        .then((response) => {
          if (!response.data.error) {
              var options = [];
              response.data.data.forEach(option => {
                  options.push({value: option.id, text: option.name });
              });
            this.groupOptions = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            showAlert(false, error.response.data.message);
          }
        });
    },
    setSelectLable: function({id, option}){
        return `${option}`
    },
    setGroupSelectLable: function(option){
        return `${option.name}`
    },
    postForm: function(e){
        e.preventDefault();
        if(!this.form.title){
            showAlert(true, 'insira um titulo');
            return;
        }
        if(!this.form.groupSelect){
            showAlert(true, 'Selecione um grupo padrão');
            return;
        }
        var workflowFormId = typeof this.workflowFormId == "undefined"? "" : this.workflowFormId;
        let data = new FormData();
        data.append('workflow_id',  this.workflowId);
        data.append('workflow_form_id', workflowFormId);
        data.append('workflow_group_id', this.form.groupSelect.id);
        data.append('form_title', this.form.title);
        data.append('form_items', JSON.stringify(this.hidden));
        data.append('form_logo_file', this.form.logoFile);
        data.append('ticket_column_id', this.form.ticketColumnSelected ? this.form.ticketColumnSelected.id : 0);
        data.append('with_description', !this.form.withDescription ? 0 : (this.form.withDescription.value ? 1 : 0));

        let conf = config.getHeaders;
        conf['content-Type'] = 'multipart/form-data';

        const postForm = axios
        .post(
          window.location.origin + "/api/v1/workflow/form",
          data,
          config.getHeaders
        )
        .then((response) => {
          if (!response.data.error) {
              showAlert(false, response.data.message);
              this.$root.$emit('bv::hide::modal', 'modal-form-build')
          }
        })
        .catch((error) => {
          if (error.response) {
            showAlert(true, error.response.data.message);
          }
        });

    },
    removeWorkflowForm(formId){

        this.$swal
        .fire({
          title: "Confirma a exclusão do formulário?",
          text: "Você não será capaz de reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            this.$swal
          .fire({
            position: "center-center",
            html: "<i class='bx bx-loader-alt bx-spin' style='font-size: 5rem;'></i><p>Excluindo</p>",
            showCancelButton: false,
            showConfirmButton: false,
            width: 300,
            padding: 50,
          });

          const postForm = axios.delete(window.location.origin + "/api/v1/workflow/form/"+formId)
            .then((response) => {
                if (!response.data.error) {
                    showAlert(false, response.data.message);
                    this.$root.$emit('bv::hide::modal', 'modal-form-build')
                }
                this.$swal.close();
            })
            .catch((error) => {
                this.$swal.close();
                if (error.response) {
                    showAlert(true, error.response.data.message);
                }
            });

          }
        });
    },
    getHtmlType(item) {
      if(this.forceTextColumnTypes.indexOf(item.workflow_column_type_id) != -1) {
        return 'text';
      }

      return item.html_type
    }
  },
  mounted() {
    this.workflowFormId = this.workflowFormUuid? this.workflowFormUuid : this.$route.params.uuid;
    this.ExternalLink =  window.location.origin + "/form/show/"+this.workflowFormId;
    this.getWorkflowColumns(this.workflowId);
    this.getGroupSelect(this.workflowId);
  },
};
</script>
<template>
  <div class="container-fluid">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card">
        <b-form @submit="postForm">

            <div class="card-body p-0">
                <div class="row justify-content-center d-none">

                    <div class="col-2">
                      <input
                        type="file"
                        class="d-none"
                        ref="logo-file"
                        name="logo-file"
                        id="logo-file"
                        @change="pickFile"
                        @load="pickFile"
                        accept="image/*"
                      />
                      <a
                        href="javascript: void(0);"
                        class="d-inline-block text-decoration-none fs-5"
                        @click="openLogoFile"
                      >
                        <div class="avatar-xl profile-user-wid mb-5">
                          <span class="avatar-title rounded-circle bg-light">
                            <b-img
                              fluid-grow
                              rounded="circle"
                              alt="company-logo"
                              :src="previewImage ? previewImage : '/images/logo.png'"
                              data-holder-rendered="true"
                              height="115"
                              width="115"
                              ref="logo"
                            ></b-img>
                          </span>
                        </div>
                      </a>
                    </div>

                </div>

            <div class="container-fluid">
              <b-row>
                <div class="col-12">

                    <h4 class="card-title">Controle</h4>
                    <div class="row">
                        <div class="col-12 col-md-6 col-xl-6 col-sm-12">
                        <div class="row">
                            <div class="col-10">
                            <b-form-group
                                label="Titulo do formulário"
                                label-for="title-form"
                                class=""
                            >
                                <b-form-input
                                type="text"
                                class="form-control bg-input font-size-12"
                                id="title-form"
                                name="title-form"
                                placeholder="Digite um titulo para o formulário"
                                v-model="form.title"
                                size="sm"
                                ></b-form-input>
                            </b-form-group>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6 col-xl-6 col-sm-12">
                        <div class="row">
                            <div class="col-10">
                            <b-form-group
                                label="Grupo Padrão"
                                label-for="group"
                                class=""
                            >
                             <multiselect class="multiselect-sm" v-model="form.groupSelect" :options="groupOptions" :custom-label="setGroupSelectLable"></multiselect>
                            </b-form-group>
                            </div>
                            <div class="col-2"></div>
                        </div>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6 col-xl-6 col-sm-12">
                            <div class="row">
                                <div class="col-10">
                                <b-form-group
                                    label="Com Descrição"
                                    label-for="with-description"
                                    class=""
                                >
                                <multiselect id="with-description" class="multiselect-sm" v-model="form.withDescription" :options="withDescriptionOptions" label="label"></multiselect>
                                </b-form-group>
                                </div>
                                <div class="col-2"></div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-xl-6 col-sm-12" v-if="ticketColumns.length > 0">
                            <div class="row">
                                <div class="col-10">
                                <b-form-group
                                    label="Ticket ID"
                                    label-for="ticket-id"
                                    class=""
                                >
                                <multiselect id="ticket-id" class="multiselect-sm" v-model="form.ticketColumnSelected" :options="ticketColumns" label="name"></multiselect>
                                </b-form-group>
                                </div>
                                <div class="col-2"></div>
                            </div>
                        </div>
                    </div>

                    <hr class="py-0 my-0 mb-2" v-if="workflowColumns.length > 0">
                </div>

              </b-row>
              <div v-if="columnsLoad" class="row justify-content-center align-items-center" style="height: calc(100vh - 500px);"> <div class="col text-center"><i class="bx bx-loader-alt bx-spin fs-1"></i></div> </div>
              <b-row v-if="workflowColumns.length > 0">
                  <div class="col-12">
                  <div class="card">
                      <div class="card-body p-0">
								<h4 class="card-title">Colunas</h4>
                          <div class="row">

                            <div
                            class="col-12 col-md-6 col-xl-6 col-sm-12 pb-1"
                            v-for="(item, itemKey) in workflowColumns"
                            :key="itemKey"
                            :class="{'d-none': item? item.workflow_column_type_id == 9 || item.workflow_column_type_id == 11 : false}"
                            >

                            <div class="row" v-if="item.workflow_column_type_id == 8 || item.workflow_column_type_id == 3">
                                <div class="col-10">
                                    <b-form-group
                                    :label="item.name"
                                    :label-for="`item_${item.id}`"
                                    class=""
                                >
                                    <multiselect class="multiselect-sm" v-model="value[item.id]" :options="item.options" :custom-label="setSelectLable"></multiselect>
                                    </b-form-group>
                                </div>
                                <div class="col-1">
                                <b-form-group class="">
                                    <label :for="`hidden_${item.id}`" class="mt-n3">Ocultar</label>
                                    <div>
                                    <div class="form-check form-check-primary ">
                                        <input v-model="hidden[item.id]" type="checkbox" :id="`hidden_${item.id}`" :name="`hidden_${item.id}`" class="form-check-input mx-auto" value="1">
                                    </div>
                                    </div>
                                </b-form-group>
                                </div>
                                <div class="col-1"></div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-10">
                                <b-form-group
                                    :label="item.name"
                                    :label-for="`item_${item.id}`"
                                    class=""
                                >
                                    <b-form-input
                                    :type="getHtmlType(item)"
                                    class="form-control bg-input font-size-12"
                                    :id="`item_${item.id}`"
                                    :name="`${item.id}`"
                                    :placeholder="item.name"
                                    size="sm"
                                    ></b-form-input>
                                </b-form-group>
                                </div>
                                <div class="col-1">
                                <b-form-group class="" >
                                    <div v-if="item.workflow_column_type_id != 9">
                                        <label :for="`hidden_${item.id}`" class="mt-n3">Ocultar</label>
                                        <div>
                                        <div class="form-check form-check-primary ">
                                            <input v-model="hidden[item.id]" type="checkbox" :id="`hidden_${item.id}`"  :name="`hidden_${item.id}`" class="form-check-input mx-auto" value="1">
                                        </div>
                                        </div>
                                    </div>
                                </b-form-group>
                                </div>
                                <div class="col-1"></div>
                            </div>
                            </div>
                          </div>
                      </div>
                  </div>
                  </div>
              </b-row>
              <b-row>
                <b-col cols="6" class="d-flex justify-content-start">
                    <b-button size="sm" type="submit" variant="info mt-4 font-size-12">{{workflowFormId ? 'Salvar' : 'Criar'}} Formulário</b-button>
                </b-col>
                <b-col cols="6" class="d-flex justify-content-end">
                    <b-button size="sm" type="submit" variant="outline-danger mt-4 font-size-12" @click.prevent="removeWorkflowForm(workflowFormId)" v-if="workflowFormId">Remover</b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-form>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
  </div>
</template>
<style scoped>
.bg-card{
    background-color: #222736;
}
.font-link-col{
    margin-top: 37px;
    font-size: 11px;
}
.font-link{
    color: #eeeEEE;
}
</style>
