<script>
import Vue from "vue";
import i18n from "../../../i18n";
import axios from "axios";
import config from "../../../config/config";
import showAlert from "../../alerts";
import ContentBox from "../../../components/content-box";
import simplebar from "simplebar-vue";
import VueEasyLightbox from "vue-easy-lightbox";
import bxIcons from "../../../config/bxIconsClasses";
import VideoRender from "../../video-render.vue"
import { companiesComputed } from "@state/helpers"
import Editor from '@tinymce/tinymce-vue'
import { mapActions } from "vuex";

export default {
    components: {
        simplebar,
        VueEasyLightbox,
        ContentBox,
        Editor,
    },
    data() {
        return {
            user: {},
            historyUsers: [],
            uploadProgressItems: [],
            mentionLoad: false,
            skipClose: 0,
            task: null,
            bxIconsClasses: bxIcons,
            shortcutsOptions: [],
            pendency: false,
            extensionsAccept: '',
            workflowTaskActivity: {},
            workflowTaskActivityId: null,
            uploadCounter: 1,
            encodedFile: null,
            mentionTags: [
                {name: 'Comment', delimiter: '#'}
            ],

            helpEditor:{
                show: false,
                images: []
            },
            inputTime: {
                hours: null,
                minutes: null,
            },
            ckEditorInstance: null,
            uploadedFilesInfo: [],
            uploadedIndexes: [],
            lastFileIndex: 0,
            reply: {},
            workflowTaskActivityEdit: false,
            workflowTaskActivityForm: {
                id: null,
                delete: false,
                updateMessage: '',
                updateTime: '',
                updateTimeOperator: true,
                uploadFiles: [],
                localizers: [],
            },
            // ligthbox
            lightbox: {
                taskUpdateLigthBoxImages: [],
                indexLightbox: 0,
                showLightbox: false
            },
            showDownload: [],
            taskUpdateImages: {},
            taskUpdateImagesByLocalizer: {},
            taskUpdateImagesUuids: [],
            showEmojiContainer: false,
            showShortcutContainer: false,
            tinyMcefileLocalizer: [],
            fileLocalizer: {},
            dragFile:false,
            contactTooltipTarget: '',
            contactTooltipContent: '',
            contactTooltipShow: false,
            uploadProgressItemsFields: [
                { key: "index", label: "#", sortable: false, class: "text-start align-middle checkbox cell-dark d-none" },
                { key: "name", label: "Nome", sortable: false, class: "text-start align-middle checkbox cell-dark" },
                { key: "progress", label: "Progresso", sortable: false, class: "text-center align-middle checkbox cell-dark" },
            ],
            tinymcePlugins: ['preview', 'fullscreen', "searchreplace", "emoticons", "autolink",  "image", "link", "lists",  "media", "paste", "table", "visualblocks", "wordcount", "mention"],
            tinymceToolbar: 'fullscreen | preview formatselect bold italic underline strikethrough | align | lineheight indent outdent emoticons undo redo| numlist bullist image media table | link fontsizeselect fontselect forecolor backcolor searchreplace ',
        };
    },
    computed: {
        ...companiesComputed,
        tinyConfig() {
            if(this.mentionLoad) {
                return {}
            }

            let extensionsAccept = this.extensionsAccept;
            let taskId = this.taskId;
            let userId = this.user.id;
            let mentionUsers = [];
            let mentionTags = [];
            let vue = this;

            this.historyUsers.forEach(user => {
                user.delimiter = '@';
                mentionUsers.push(user);
            });

            this.mentionTags.forEach(tag => {
                tag.full_name = tag.name;
                mentionTags.push(tag);
            });

            return {
                // General
                // external_plugins: {},
                entity_encoding : "raw",
                default_link_target: "_blank",
                menubar: false,
                language: 'pt_BR',
                skin: 'skot-dark',
                content_css: 'skot-dark',
                resize: false,
                fontsize_formats: '8px 9px 10px 11px 12px 14px 18px 24px 30px 36px 48px 60px 72px 96px',
                content_style: `p, ul li, ol li { font-size: 12px; } ul, ol { font-size: inherit; }`, // Set font default value to paragraph
                // Tags
                mentions: {
                    queryBy: 'full_name',
                    delimiter: ['@', '#'],
                    source: function(query, process, delimiter) {
                        let data = [];
                        if (delimiter === '@') {
                            data = mentionUsers;
                        }
                        if(delimiter === '#') {
                            data = mentionTags
                        }
                        process(data)
                    },
                    highlighter: function(text) {
                        //make matched block italic
                        return text.replace(new RegExp('(' + this.query + ')', 'ig'), function ($1, match) {
                            return '<i><b>' + match + '</b></i>';
                        });
                    },
                    insert: function(item) {
                        vue.skipClose = 1;
                        let localizer = '';
                        let id = ''
                        if(item.localizer !== undefined) {
                            localizer = `data-localizer="${item.localizer}"`;
                            id = `id="${item.localizer}"`;
                        }
                        return '<a href="#" class="mention" '+localizer+' '+id+'>'+ item.delimiter + item.full_name + '</a>';

                    },
                    render: function(item) {
                        vue.skipClose = 1;
                        if (item.delimiter == '@') {
                            let initials = `${item.name ? item.name[0] : ''}${ item.last_name ? item.last_name[0] : ''}`;
                            let noAvatar = `<span class="b-avatar mr-3 bg-light text-white badge-info rounded-circle avatar-circle me-1"><span class="b-avatar-text font-size-10"><span>${initials}</span></span></span>`;
                            let renderAvatar = `<img src="${item.avatar}" alt="" class="rounded-circle avatar-circle me-1">`;
                            let avatar = item.avatar ? renderAvatar : noAvatar;
                            return '<li>' + '<a href="javascript:void(0);" class="d-flex align-items-center py-2">'+avatar+'<span>' + item.full_name + '</span></a>' + '</li>';
                        }

                        if(item.delimiter == '#') {
                            return '<li>' + '<a href="javascript:void(0);" class="d-flex align-items-center"><span>' + item.full_name + '</span></a>' + '</li>';
                        }
                    }
                },

                // Upload
                block_unsupported_drop: true,
                images_upload_credentials: true,
                paste_data_images: false,
                paste_block_drop: false,
                smart_paste: false,
                images_upload_base_path: window.location.origin,
                images_upload_url: window.location.origin + '/api/v1/workflow/task/history/upload',
                file_picker_types: 'file image media',
                automatic_uploads: true,
                image_title: true,
                file_picker_callback: function(callback, value, meta) {
                    var input = document.createElement('input');
                    input.setAttribute('type', 'file');

                    // Provide file and text for the link dialog
                    if (meta.filetype == 'file') {
                        input.setAttribute('accept', 'txt, pdf, docx, xls, xlsx');
                    }

                    // Provide image and alt text for the image dialog
                    if (meta.filetype == 'image') {
                        input.setAttribute('accept', 'image/*');
                    }

                    // Provide alternative source and posted for the media dialog
                    if (meta.filetype == 'media') {
                        input.setAttribute('accept', 'video/mp4');
                    }
                    input.onchange = async function () {
                        var file = this.files[0];

                        let upload = await vue.upload(file);
                        if(!upload.error) {
                            // let link = upload.data.link.replaceAll(window.location.origin + '/workflow/show/files?', 'files?');
                            let link = upload.data.link.replaceAll(window.location.origin + '/workflow/show/files?', window.location.pathname);
                            let filename = upload.data.link.split('/')
                            let name = ''
                            if(filename.length > 0) {
                                name = filename[filename.length - 1]
                            }

                            vue.tinyMcefileLocalizer.push({url: link, localizer: upload.data.localizer});
                            return callback(link, { title: name });

                        }
                        return failure(upload.data.message, { remove: true });
                    };
                    input.click();
                },
                images_upload_handler: async (blobInfo, success, failure, progress) => {

                    let upload = await vue.upload(blobInfo.blob());
                    if(upload.error) {
                       return failure(upload.data.message, { remove: true });
                    }

                    let link = upload.data.link.replaceAll(window.location.origin + '/workflow/show/files?', window.location.pathname);
                    vue.tinyMcefileLocalizer.push({url: link, localizer: upload.data.localizer});
                    return  success(link);
                },
                init_instance_callback: function (editor) {},
                setup: function (editor) {
                    editor.on('keydown', (evt) => {
                        let task_finalized = this.task ? this.task.finalized : false;
                        if(evt.ctrlKey && evt.keyCode == 13 && !task_finalized){
                            evt.preventDefault();
                            vue.setTaskUpdate();
                        }
                    });

                    editor.on('NodeChange', (e) => {
                        e.element.parentNode.querySelectorAll('img:not([data-localizer])').forEach(img => {
                            let url = img.src;
                            let data = vue.tinyMcefileLocalizer.find((data) => url.indexOf(data.localizer) != -1);

                            if(data) {
                                img.setAttribute('data-localizer', data.localizer);
                            }

                          });
                    })
                }
            }
        }
    },
    props: {
        taskId: {
            type: Number,
            default: 1
        },
        userArea: {
            type: Boolean,
            default: false
        },
        showLog: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ...mapActions('user', ['markNotificationsAsReadByTask']),
        checkUpload(e) {
            var files = [];
            this.skipClose = 2;
            if(e.type == 'paste') {
                e.preventDefault();
                e.stopPropagation();
                files = [...e.clipboardData.files];
            } else if (e.type == 'drop') {
                e.preventDefault();
                e.stopPropagation();
                files = [...e.dataTransfer.files];
            }

            if(files.length > 0) {
                this.uploadProgressItems = [];
                files.forEach((file, key) => {
                    this.uploadProgressItems.push({index: key, name: file.name, progress: 0});
                });
                this.$root.$emit('bv::show::modal', 'progress-upload-modal')
                files.forEach(async (file, key)=>{

                    let response = await this.upload(file, null, key);
                    let source = '';
                    if(response) {
                        let link = response.data.link.replaceAll(window.location.origin + '/workflow/show/files?', 'files?');
                        if(file.type.toLowerCase().indexOf('image') != -1) {

                            source = `<p><img data-localizer="${response.data.localizer}" src="${link}" title="${file.name}" width="217"  alt="${file.name}" data-mce-src="${link}" data-mce-selected="1"></p>`;

                        } else if(file.type.toLowerCase().indexOf('video') != -1) {

                            source += `<p><video controls="controls" width="300"  data-localizer="${response.data.localizer}">`;
                            source +=     `<source src="${link}">`;
                            source += `</video></p>`;

                        } else {
                            source = `<p><a  data-localizer="${response.data.localizer}" title="${file.name}" href="${link}" data-mce-href="${link}" data-mce-selected="inline-boundary" >${file.name}</a></p>`
                        }

                        var editor = tinymce.get("tinyeditor");
                        editor.execCommand('mceInsertContent', false, source);

                    }
                });

            }
        },
        async upload(file, progress = null, index = 0) {
            try {
                var url = window.location.origin + '/api/v1/workflow/task/history/upload';
                var formData = new FormData();

                var localizer = await this.getLocalizerName();
                var uploadProgressItem = this.uploadProgressItems.find((file)=> file.index == index);
                this.workflowTaskActivityForm.localizers.push(localizer);

                formData.append('file', file, file.name);
                formData.append('task_id', this.taskId);
                formData.append('localizer', localizer);

                let upload = await axios.post(url, formData, {
                    "Content-Type": "multipart/form-data",
                    "Accept": "application/json",
                    onUploadProgress: (event) => {
                            let progress = Math.round((event.loaded * 100) / event.total);
                            if(uploadProgressItem) {
                                uploadProgressItem.progress = progress;
                            }
                        }

                    },

                )

                let data = upload.data.data;
                if(file.type.indexOf('image/') != -1) {
                    this.taskUpdateImages[localizer] = data;
                    this.lightbox.taskUpdateLigthBoxImages.push(data);
                }

                return {error: false, message: data.message, data: {link: data, localizer: localizer}}

            } catch(error) {
                this.workflowTaskActivityForm.localizers.pop();
                return {error: true, message: error.response.data.message, data: ''}
            }

        },
        async getLocalizerName() {
            var now = (new Date()).getTime();
            var localizer = `file__${now}${this.uploadCounter}`;
            this.uploadCounter++;
            return localizer;
        },
        // User
        getUser() {
            let url = window.location.origin + '/api/v1/user';
            const userData = axios.post(url, null, config.getHeaders)
                .then(response => {
                    this.user = response.data;
                });
        },
        getHistoryUsers(taskId) { //workflow/task/history
            this.mentionLoad = true;
            let url = window.location.origin + '/api/v1/workflow/task/history/users';
            const userData = axios.post(url, {task_id: taskId}, config.getHeaders)
                .then(response => {
                    this.mentionLoad = false;
                    this.historyUsers = response.data.data;
                });
        },
        // ligthbox
        setIndexLightbox(data) {
            let index = this.lightbox.taskUpdateLigthBoxImages.indexOf(data);
            this.lightbox.indexLightbox = index ? index : 0;
            this.lightbox.showLightbox = true;
        },
        getTaskImages(taskId) {
            const showUpload = axios.post(
                    window.location.origin + "/api/v1/files/task/",
                    { task_id: taskId },
                    config.getHeaders
                ).then((response)=>{

                    response.data.data.forEach((file)=> {
                        this.taskUpdateImages[file.localizer] = file.link;
                        if (this.lightbox.taskUpdateLigthBoxImages.indexOf(this.taskUpdateImages[file.localizer]) < 0) {

                            this.lightbox.taskUpdateLigthBoxImages.push(this.taskUpdateImages[file.localizer]);
                        }
                    })

                })
        },

        //tasks
        getTask(task_id) {
            const getTask = axios
                .get(
                    window.location.origin + "/api/v1/workflow/task/show/" + task_id,
                    config.getHeaders
                )
                .then((response) => {

                    if (!response.data.error) {
                        this.task = response.data.data;
                        this.getShortcuts(this.task.workflow_id)
                        this.usersOptions(this.task.workflow_id);
                    }
                })
                .catch((error) => {

                    if (error.response) {
                        showAlert(true, error.response.data.message);
                    }
                });
        },
        getTaskUpdate(task_id, noImages = false) {
            this.workflowTaskActivityId = task_id;
            const getWorkflowTaskActivity = axios
                .get(
                    `${window.location.origin}/api/v1/workflow/task/history/${task_id}/${this.showLog ? 1 : 0}`,
                    config.getHeaders
                )
                .then((response) => {

                    if (!response.data.error) {
                        this.workflowTaskActivity = response.data.data;
                        this.checkPendency(this.workflowTaskActivity);

                        if (!noImages) {

                            var uuids = [];
                            var dateKeys = Object.keys(this.workflowTaskActivity);
                            if (dateKeys.length > 0) {
                                dateKeys.forEach(dateKey => {
                                    this.workflowTaskActivity[dateKey].forEach((iteration, key) => {
                                        iteration.files.forEach((file)=>{
                                            this.showDownload[iteration.id] = true;
                                            this.taskUpdateImagesByLocalizer[file.localizer] = file.uuid;
                                            if (file.uuid && (file.support_file_extension == 'jpg' || file.support_file_extension == 'jpeg' || file.support_file_extension == 'png' || file.support_file_extension == 'gif' || file.support_file_extension == 'webp' || file.support_file_extension == 'mp4')) {
                                                this.taskUpdateImagesUuids.push(file.uuid);
                                                uuids.push(file.uuid);
                                            }
                                        });
                                    });
                                });
                            }
                            // this.showUploadedFile(this.taskUpdateImagesUuids);
                        }

                        // this.openRightDrawer = true;
                        if(this.$route.params.activity && window.location.href.indexOf('?scroll') == -1) {
                            setTimeout(() => {

                                this.goToActivity(this.$route.params.activity);

                            }, 100);
                            return;
                        } else {

                            setTimeout(() => { this.historyScrollBottom() }, 100);
                        }

                    }
                })
                .catch((error) => {

                    if (error.response) {
                        showAlert(true, error.response.data.message);
                    }
                });
        },
        goToActivity(activityId, mention = false) {
            let element = this.$el.querySelector(`#iteration_${activityId}`);
            if(element) {
                this.$scrollTo(element, 1000, {
                    container: '.current-scroll-container .simplebar-wrapper .simplebar-mask .simplebar-offset .simplebar-content-wrapper',
                    x: false,
                    y: true,
                    offset: -50,
                });
                setTimeout(()=> { element.classList.add('flash')}, 1000);

                setTimeout(()=> {
                    element.classList.remove('flash');
                }, 1500);

                if(mention) {
                    setTimeout(()=> {
                        window.location.href.replace(`${activityId}`, '999999')
                        var anchor = document.createElement('a');
                        anchor.setAttribute('href', '#?scroll');
                        anchor.click();
                    }, 1500);
                }
            } else {
                setTimeout(() => { this.historyScrollBottom() }, 100);
            }
        },
        renderVideos(){
            let localizers = Object.keys(this.taskUpdateImagesByLocalizer);
            localizers.forEach((localizer)=>{
                this.localizerRender(localizer);

            });
        },
        localizerRender(localizer) {
            if(localizer) {
                let video = this.$el.querySelector(`a.video.${localizer}`);

                if(video) {
                    let src = this.taskUpdateImages[this.taskUpdateImagesByLocalizer[localizer]];
                    const mountNode = window.document.createElement('div')
                    mountNode.id = localizer;
                    video.before(mountNode);
                    video.classList.add('d-flex');
                    video.classList.add('justify-content-center');
                    let videoRenderComp = Vue.extend(VideoRender);
                    let rendering = new videoRenderComp({propsData: {url: src}}).$mount(`#${localizer}`);
                }
            }
        },
        deleteActivity(iteration) {
            let vue = this;
            this.$swal
                .fire({
                title: "Excluir mensagem?",
                text: "Você não será capaz de reverter isso!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sim, excluir!",
                cancelButtonText: "Cancelar",
                })
                .then((result) => {
                if (result.value) {
                    vue.workflowTaskActivityForm.id = iteration.id;
                    vue.workflowTaskActivityForm.delete = true;
                    vue.setTaskUpdate();

                }
            });
        },
        cancelEdit() {
            this.workflowTaskActivityForm.id = null;
            this.workflowTaskActivityForm.updateMessage = '';
            this.helpEditor.show = true;
            setTimeout(()=>{
                var editor = tinymce.get("tinyeditor");
                editor.focus();
                editor.selection.select(editor.getBody(), true);
                editor.selection.collapse(false);
            },500)

        },
        editIteration(iteration) {
            this.workflowTaskActivityForm.updateMessage = iteration.message;
            this.workflowTaskActivityForm.id = iteration.id;

            if(!this.helpEditor.show) {
                this.helpEditor.show = true;

                setTimeout(()=>{
                    var editor = tinymce.get("tinyeditor");
                    editor.focus();
                    editor.selection.select(editor.getBody(), true);
                    editor.selection.collapse(false);
                },500)

            }

        },
        // TinyMCE Editor
        setTaskUpdate() {
            let data = new FormData();
            let workflowTaskActivityForm = JSON.parse(JSON.stringify(this.workflowTaskActivityForm))
            let message = workflowTaskActivityForm.updateMessage;
            setTimeout(()=> { this.$set(this.workflowTaskActivityForm, 'updateMessage', '') }, 100);

            // mentions
            let mentions = [];
            this.historyUsers.forEach(user => {
                if(message.toLocaleLowerCase().indexOf((`@${user.full_name}`).toLocaleLowerCase()) != -1) {
                    mentions.push(user)
                }
            });

            // tags
            let tags = [];
            this.mentionTags.forEach((tag)=> {
                if(message.toLocaleLowerCase().indexOf((`${tag.delimiter}${tag.name}`).toLocaleLowerCase()) != -1) {
                    tags.push(tag)
                }
                // If not select tag into taglist
                if(message.toLocaleLowerCase().indexOf((`<span id="autocomplete-delimiter">${tag.delimiter}</span><span id="autocomplete-searchtext"><span class="dummy">${tag.name}`).toLocaleLowerCase()) != -1) {
                    tags.push(tag)
                }

            });

            data.append('id', this.workflowTaskActivityForm.id ? this.workflowTaskActivityForm.id : 0);
            data.append('delete', this.workflowTaskActivityForm.delete ? 1 : 0);
            data.append('task_id', this.workflowTaskActivityId);
            data.append('message', message);
            data.append('additional_time', this.workflowTaskActivityForm.updateTime);
            data.append('operator', this.workflowTaskActivityForm.updateTimeOperator? 1 : 0);

            mentions.forEach((user, key)=>{
                data.append(`mentions[${key}]`, user.id);
            });

            tags.forEach((tag, key)=>{
                data.append(`tags[${key}]`, `${tag.delimiter}${tag.name}`);
            });

            this.workflowTaskActivityForm.localizers.forEach((localizer, key)=>{
                if(message.toLocaleLowerCase().indexOf((`${localizer}`).toLowerCase()) != -1) {
                    data.append(`localizers[${key}]`, localizer);
                }
            });

            if(Object.keys(this.reply).length > 0) {
                data.append('reply', this.reply.id? this.reply.id : 0);
            }

            message = message.replaceAll(window.location.origin + '/workflow/show/', '/');
            message = message.replaceAll('/workflow/show/', '/');
            message = message.replaceAll('/workflow/user/view/', '/');
            let hasComment = message.toLocaleLowerCase().indexOf((`#comment`).toLowerCase()) != -1;
            let conf = config.getHeaders;
            conf['content-Type'] = 'multipart/form-data'
            if(message || this.workflowTaskActivityForm.updateTime || this.workflowTaskActivityForm.delete){

                const setTaskUpdate = axios
                    .post(
                        window.location.origin + "/api/v1/workflow/task/history",
                        data,
                        config.getHeaders
                    )
                    .then((response) => {
                        if (!response.data.error) {

                            if(typeof this.workflowTaskActivity['Hoje'] != undefined && !this.workflowTaskActivityForm.id) {
                                this.workflowTaskActivity['Hoje'].push({
                                    user_id: this.user.id,
                                    workflow_task_id: this.task.id,
                                    message: message,
                                    support_file_id: null,
                                    subtraction: false,
                                    additional_time: null,
                                    request: false,
                                    is_closing: false,
                                    response: false,
                                    read: true,
                                    last_read: 0,
                                    response_user_id: null,
                                    old_status_id: null,
                                    deleted_at: null,
                                    created_at: new Date(),
                                    updated_at: new Date(),
                                    copy: false,
                                    comment: hasComment,
                                    creation_time: "00:00",
                                    user: this.user,
                                    files: []
                                });
                            }
                            // this.getTaskUpdate(this.workflowTaskActivityId);

                            // this.workflowTaskActivityId = null;
                            this.workflowTaskActivityForm = {
                                id: null,
                                delete: false,
                                updateMessage: '',
                                updateTime: '',
                                updateTimeOperator: true,
                                uploadFiles: [],
                                localizers: [],
                            }
                            this.inputTime = {
                                hours: null,
                                minutes: null,
                            };

                            this.reply = {};

                            this.lastFileIndex = 0;
                            setTimeout(() => { this.historyScrollBottom() }, 100);
                        }
                    })
                    .catch((error) => {
                        this.workflowTaskActivityForm.updateMessage = message;
                        if (error.response) {
                            showAlert(true, error.response.data.message);
                        }
                    });
            }
        },
        textAutosave: function textAutosave(debounce = 2000) {
            var vue = this;
            clearTimeout(textAutosave.timeout);
            textAutosave.timeout = setTimeout(function() {
                let data = {
                    message: vue.workflowTaskActivityForm.updateMessage,
                    localizers: vue.workflowTaskActivityForm.localizers,
                };

                localStorage.setItem(`autosave-${vue.user.id}-${vue.taskId}`, JSON.stringify(data));

            },debounce);

        },
        uploadFile(e) {
            e.preventDefault();

            // file uploaded indexes
            var fileIndex = this.checkFileIndexes(this.workflowTaskActivityForm.updateMessage);

            // image uploaded indexes
            var ckIndexesImages = this.checkImageIndexes(this.workflowTaskActivityForm.updateMessage);
            // file + image uploade indexes
            var includedImageIndexes = ckIndexesImages.concat(fileIndex);

            if(e.type == "drop"){

                e.dataTransfer.files.forEach((file)=>{
                    this.workflowTaskActivityForm.uploadFiles.push(file);
                });
                this.helpEditor.show = true
                this.$refs.updateFile.value = "";
            } else if (e.type == "paste") {
                e.clipboardData.files.forEach((file)=>{
                    this.workflowTaskActivityForm.uploadFiles.push(file);
                });
                this.helpEditor.show = true
                this.$refs.updateFile.value = "";
            } else {
                e.target.files.forEach((file)=>{
                    this.workflowTaskActivityForm.uploadFiles.push(file);
                });
                this.$refs.updateFile.value = "";
            }

            var files = [];
            this.uploadedFilesInfo = [];
            var lastFileIndex = 0;
            this.workflowTaskActivityForm.uploadFiles.forEach((upImage, key)=>{
                var now = (new Date()).getTime();

                if (includedImageIndexes.indexOf(key) < 0 && key >= this.lastFileIndex) {
                    var typeExplode = (upImage.name).split('.');
                    var extension = typeExplode[typeExplode.length - 1];
                    var src = '';
                    var extensionsImages = this.extensionsAccept;
                    extensionsImages = extensionsImages.replaceAll('mp4', '');
                    extensionsImages = extensionsImages.replaceAll('pdf', '');
                    extensionsImages = extensionsImages.replaceAll('txt', '');
                    extensionsImages = extensionsImages.replaceAll('docx', '');
                    extensionsImages = extensionsImages.replaceAll('xls', '');
                    extensionsImages = extensionsImages.replaceAll('xlsx', '');
                    if (extensionsImages.indexOf(extension) >= 0) {

                        var reader = new FileReader();
                        reader.readAsDataURL(upImage);
                        reader.onload = () => {
                            src = reader.result;
                            if(!src){
                                src = URL.createObjectURL(upImage);
                            }
                            let uploadImage = `<figure class="image ck-widget"><img src="${src}" alt="${key} _:${typeExplode[0]}::file__${now}${key}"></figure>`
                            this.workflowTaskActivityForm.updateMessage += uploadImage;
                        };
                    } else {
                        this.workflowTaskActivityForm.updateMessage += `<a class="ck-link_selected" href="#ckfile/${key}/${extension}">${upImage.name}</a>`;
                    }
                    if (this.extensionsAccept.indexOf(extension) < 0) {
                        showAlert(true, 'Tipo não suportado');
                        this.workflowTaskActivityForm.uploadFiles.splice(key, 1);
                        this.uploadedIndexes.splice(key, 1)
                    }

                    var blob = upImage.slice(0, upImage.size, upImage.type);
                    var upImage = new File([blob], `file__${now}${key}`+'-|-'+upImage.name.replaceAll(' ','_'), {type: upImage.type});

                    this.fileLocalizer[key] = `file__${now}${key}`;
                }
                files.push(upImage);
                this.uploadedIndexes.push(key)
                lastFileIndex++;
            });
            this.lastFileIndex = lastFileIndex;

            this.workflowTaskActivityForm.uploadFiles = files;

        },
        addFileUpdate(e, event) {
            e.preventDefault();
            this.$refs.updateFile.click();
        },
        checkImageIndexes(htmlString){
            var messageHtml = new DOMParser().parseFromString(`<body>${htmlString}</body>`, 'text/html');
            var messageImages = messageHtml.querySelectorAll('img');
            var imageIndexes = [];
            messageImages.forEach((image, key)=>{
                var alt = image.alt;
                if(alt){
                    var splitAlt = alt.split('::');
                    splitAlt = splitAlt[0].split(' _:');
                    imageIndexes.push(parseInt(splitAlt[0]));
                }
            });
            return imageIndexes;
        },
        checkFileIndexes(htmlString){
            var messageHtml = new DOMParser().parseFromString(`<body>${htmlString}</body>`, 'text/html');
            var messageFiles = messageHtml.querySelectorAll('a');
            var fileIndexes = [];
            messageFiles.forEach((image, key)=>{
                var alt = image.getAttribute("href");
                if(alt){
                    var splitHref = alt.split('ckfile/');
                    if(splitHref[0] == '#'){
                        let index = splitHref[1].split('/');
                        fileIndexes.push(parseInt(index[0]));
                    }
                }
            });
            return fileIndexes;
        },
        historyScrollBottom() {
            var container = this.$el.querySelector('.current-scroll-container');

            if (container != null) {
                container = container.querySelector('.simplebar-content-wrapper');
                var chatHeight = parseInt(container.scrollHeight) + 500;
                container.scrollTo({ top: chatHeight, behavior: "smooth" });
            }
        },
        // close task
        closeTask(taskId) {
            var title = 'Encerrar Ticket?';
            var text = "Será enviada a mensagem para fechamento do ticket";
            var btnConfirm = "Sim, fechar!";
            this.$swal
                .fire({
                    title: title,
                    text: text,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: btnConfirm,
                    cancelButtonText: "Cancelar",
                })
                .then((result) => {
                    if (result.value) {
                        const getResponsibleOptions = axios
                            .post(
                                window.location.origin + "/api/v1/workflow/task/close",
                                { taskId: taskId },
                                config.getHeaders
                            )
                            .then((response) => {
                                showAlert(false, response.data.message);
                                this.getTaskUpdate(this.workflowTaskActivityId);
                                setTimeout(() => { this.getTask(this.taskId) }, 200);
                            })
                            .catch((error) => {
                                showAlert(true, error.response.data.message);
                                console.log(error);
                            });
                    }
                });
        },
        async reponseClosing(taskId, itetrationId, value) {
            var title = value ? 'Encerrar Ticket?' : 'Continuar atendimento?';
            var text = "Confirma " + (value ? 'encerrar?' : 'não encerrar?');
            var btnConfirm = "Sim";
            let swalResponse = null;
            let reason = '';

            if(!value) {
                // continue
                swalResponse = await this.$swal
                .fire({
                    title: title,
                    text: "Digite em poucas palavras o motivo",
                    input: 'text',
                    inputAttributes: {
                        placeholder: 'Digite aqui o motivo',
                        refs: 'workflow-name-text'
                    },
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: 'Continuar',
                    cancelButtonText: "Cancelar",
                    inputValidator: (value) => {
                        if (!value) {
                            return 'O campo não pode estar vazio!'
                        }
                    }
                })

                if(!swalResponse.isConfirmed)
                    return

                reason = swalResponse.value;

            } else {
                // close
                swalResponse = await this.$swal
                .fire({
                    title: title,
                    text: text,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: btnConfirm,
                    cancelButtonText: "Cancelar",
                })

                if(!swalResponse.isConfirmed)
                    return
            }

            // post form
            if(swalResponse) {
                var data = {
                    taskId: taskId,
                    taskHistoryId: itetrationId,
                    value: value,
                    reason: reason
                }

                const getResponsibleOptions = axios
                .post(window.location.origin + "/api/v1/workflow/task/close/confirm", data, config.getHeaders)
                .then((response) => {
                    showAlert(false, response.data.message);
                    this.getTaskUpdate(this.workflowTaskActivityId);
                    setTimeout(() => { this.getTask(this.taskId) }, 200);
                })
                .catch((error) => {
                    showAlert(true, error.response.data.message);
                    console.log(error);
                });
            }

        },
        async responseMotivation(title = '', text = '', btnConfirm = ''){
            var title = title? title : 'Continuar atendimento?';
            var text = text? text :"Digite em poucas palavras o motivo da reabertura";
            var btnConfirm = btnConfirm? title : 'Continuar';
            this.$swal
                .fire({
                    title: title,
                    text:  text,
                    input: 'text',
                    inputAttributes: {
                        placeholder: 'Digite aqui o motivo',
                        refs: 'workflow-name-text'
                    },
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: btnConfirm,
                    cancelButtonText: "Cancelar",
                    inputValidator: (value) => {
                        if (!value) {
                            return 'O campo não pode estar vazio!'
                        }
                    }
                })
                .then((result) => {
                    return result.value
                });
        },
        // Reopen task
        reopenTask(taskId) {
            var title = 'Reabrir?';
            var text = "Digite em poucas palavras o motivo da reabertura";
            var btnConfirm = "Sim, reabrir!";
            this.$swal
                .fire({
                    title: title,
                    text: text,
                    input: 'text',
                    inputAttributes: {
                        placeholder: 'Digite aqui o motivo',
                        refs: 'workflow-name-text'
                    },
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: btnConfirm,
                    cancelButtonText: "Cancelar",
                    inputValidator: (value) => {
                        if (!value) {
                            return 'O campo não pode estar vazio!'
                        }
                    }
                })
                .then((result) => {
                    if (result.value) {
                        var data = {
                            taskId: taskId,
                            message: result.value,
                        };
                        const getResponsibleOptions = axios
                            .post(
                                window.location.origin + "/api/v1/workflow/task/reopen",
                                data,
                                config.getHeaders
                            )
                            .then((response) => {
                                showAlert(false, response.data.message);
                                this.getTaskUpdate(this.workflowTaskActivityId);
                                this.getTask(this.taskId)
                            })
                            .catch((error) => {
                                showAlert(true, error.response.data.message);
                                console.log(error);
                            });
                    }
                });
        },
        // default
        setExtensions() {
            this.extensionsAccept = '';
            if (config.extensions.length > 0) {
                config.extensions.forEach((extension) => {
                    this.extensionsAccept += '.' + extension + ','
                });
            }
        },
        downloadFile(id, byIterationId = false) {

            if(id){

                const download = axios
                    .post(
                        window.location.origin + "/api/v1/files",
                        { id: id, byIterationId: byIterationId },
                        config.getHeaders
                    )
                    .then((response) => {
                        var downloadUrl = document.location.origin + '/' + response.data.data.path;
                        var fileLink = document.querySelector('a.download');
                        fileLink.href = downloadUrl;
                        fileLink.setAttribute('download', response.data.data.file_name);
                        fileLink.setAttribute('target', "_blank");
                        fileLink.click();
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.isRegisterError = true;
                            this.axRegError = error.response.data.message;
                        }
                    });
            }

        },
        checkPendency(data){
            if(data){
                var pendency = 0;
                var dateKeys = Object.keys(data);
                if (dateKeys.length > 0) {
                    dateKeys.forEach(dateKey => {
                        data[dateKey].forEach((iteration, key) => {
                            if(iteration.request && iteration.response === null){
                                pendency++;
                            }
                        });
                    });
                }
                if(pendency > 0) {
                    this.pendency = true;
                } else {
                    this.pendency = false;
                }
            }
        },
        toggleEditor(){
            let finalized = this.task ? this.task.finalized : false;
            if(!finalized) {
                this.helpEditor.show = !this.helpEditor.show;
            }

        },
        selectEmoji(emoji) {
            this.helpEditor.show = true;
            var clearMessage = this.workflowTaskActivityForm.updateMessage.replace('<p></p>','');
            var moveToEnd = this.workflowTaskActivityForm.updateMessage == ''? true:false;

            this.$refs['ck_editor'].$_instance.model.change( writer => {
                const insertPosition = this.$refs['ck_editor'].$_instance.model.document.selection.getFirstPosition();
                writer.insertText( emoji.data, { }, insertPosition );
                this.$refs['ck_editor'].$_instance.editing.view.focus();
            });
            if(moveToEnd){
                this.$refs['ck_editor'].$_instance.model.change( writer => {
                    // createPositionAt(itemOrPosition,  offset ). [ offset ] : Number | 'end' | 'before' | 'after'
                    writer.setSelection( writer.createPositionAt( this.$refs['ck_editor'].$_instance.model.document.getRoot(), 1 ) );
                });
            }

            this.$refs['ck_editor'].$_instance.editing.view.focus();
            this.showEmojiContainer = false;
        },
        // shortcuts
        getShortcuts(workflowId){
            const getShortcuts = axios
                .get(window.location.origin + "/api/v1/workflow/shortcut/availables/"+workflowId)
                .then((response) => {
                    let data = response.data.data;
                    data.forEach((el, key)=>{
                        el.title = el.name;
                        el.class = el.icon_class+' '+el.icon_color;

                        this.$set(this.shortcutsOptions, key, el);
                    });
                })
                .catch((error) => {
                    showAlert(true, error.response.data.message);
                    console.log(error);
                });
        },
        dispatchMessage(e){
            let time = e.additional_time? e.additional_time : '';
            this.workflowTaskActivityForm = {
                updateMessage: e.message,
                updateTime: time,
                updateTimeOperator: e.operator,
                uploadFiles: [],
                localizers: [],
            };
            this.showShortcutContainer = false
            this.setTaskUpdate();
        },
        toggleContentBoxShortcut(){
            this.showShortcutContainer = !this.showShortcutContainer;
            if(this.showShortcutContainer){
               setTimeout(()=>{
                   this.$refs['content-box-shortcut'].$el.focus();
                }, 50);
            }
        },
        toggleCopy(e, show = false){
            var element = e.target.querySelector('.copy-element');
            if(element) {
                if(show) {
                    element.classList.remove('d-none');
                } else {
                    element.classList.add('d-none');
                }
            }
        },
        setDownloadListener() {

            let activeListener = false;
            let element = window.document.querySelector('.update-message-container');

            if(element) {
                element.addEventListener('click', (event, a)=>{
                    let content = event.target;
                    if(content.classList.value.indexOf('file-download') != -1) {
                        let classes = content.classList.value.split(' ');
                        let localizer = classes.find((el)=> {return el.indexOf('file_') != -1});
                        if(typeof this.taskUpdateImagesByLocalizer[localizer] !== 'undefined') {
                            this.downloadFile(this.taskUpdateImagesByLocalizer[localizer], false);
                        }
                    }
                })
                activeListener = true;
            }
            if(!activeListener) {
                setTimeout(()=>{
                    this.setDownloadListener();
                }, 1000);
            }
        },
        clearUploadProgressItems(){
            this.uploadProgressItems = [];
        },
        inputTimeIncrement(isMinute = false) {
            if(isMinute) {
                if(this.inputTime.minutes < 59) {
                    this.inputTime.minutes++;
                    return;
                }

                return;
            }

            if(this.inputTime.hours < 99) {
                this.inputTime.hours++;
                return;
            }

        },
        inputTimeDecrement(isMinute = false) {
            if(isMinute) {
                if(this.inputTime.minutes > 0) {
                    this.inputTime.minutes--;
                    return;
                }

                return;
            }

            if(this.inputTime.hours > 0) {
                this.inputTime.hours--;
                return;
            }
        },
        inputTimeFilter(event, isMinute = false) {
            if (/^[A-zÁ-ú]/.test(event.key) && event.keyCode != 46 && event.keyCode != 8 && event.keyCode != 13) {
                event.preventDefault();
            }
        },
        pad(value) {
            if(!value) {
                return ''.padStart(2, '0');
            }
            return value.toString().padStart(2, '0');
        },
        closeEditor(e) {

            if(this.skipClose) {
                this.skipClose--;
                return;
            }

            this.helpEditor.show = false
        },
        checkEditMode(editMode, user, iterationUser) {
            let response = false;

            if(!iterationUser) {
                return response;
            }

            switch(editMode) {
                case 1:
                    response = (user.id == iterationUser.id);
                    break;
                case 2:
                    response = (user.user_type_id == 1 && iterationUser.user_type_id != 3) || user.id == iterationUser.id;
                    break;
                case 3:
                    response = (user.user_type_id == 1 && iterationUser.user_type_id != 3)
                        || (user.user_type_id == 2 && iterationUser.user_type_id != 3 && iterationUser.user_type_id != 1)
                        || (user.user_type_id == 4 && iterationUser.user_type_id != 3 && iterationUser.user_type_id != 1)
                        || user.id == iterationUser.id;
                    break;
            }

            return response;
        },
        setReply(iteration, cancelEdit = false) {
            this.reply = iteration
            if(cancelEdit) {
                this.cancelEdit();
            }
        },
        joinActivityWebsocket(taskId) {
            Echo.private(`private.${this.currentSubdomain}.${taskId}`)
                .listen('.PrivateEvent', (data) => {
                    this.task = data.history.task;
                    if(this.user.user_type_id > 2) {
                        let keys = Object.keys(data.history.chat);
                        keys.forEach((key)=>{
                            data.history.chat[key] = data.history.chat[key].filter((activity)=> !activity.comment);
                        });
                        this.workflowTaskActivity = data.history.chat;
                    } else {
                        this.workflowTaskActivity = data.history.chat;
                    }
                    this.checkPendency(this.workflowTaskActivity);
                    let fileUuids = data.history.files;
                    let files = fileUuids.filter((file)=> this.taskUpdateImagesUuids.indexOf(file.uuid) < 0 )
                    let uuids = [];
                    let showDownloadKeys = Object.keys(this.showDownload);
                    files.forEach((file)=>{
                        this.taskUpdateImagesByLocalizer[file.localizer] = file.uuid;
                        uuids.push(file.uuid);
                        if(showDownloadKeys.indexOf(file.workflow_task_activity_id) < 0) {
                            this.showDownload[file.workflow_task_activity_id] = true;
                        }
                    });

                    // this.showUploadedFile(uuids, true);

                    setTimeout(() => { this.historyScrollBottom() }, 100);
                    setTimeout(() => { this.markNotificationsAsReadByTask(this.taskId); }, 100);
                });
        }
    },
    mounted() {
        this.joinActivityWebsocket(this.taskId);

        this.$nextTick(()=>{
            this.setDownloadListener();
            window.document.addEventListener('click', (event)=> {
                if(event.target.tagName == 'IMG') {
                    let localizer = event.target.dataset.localizer;
                    this.setIndexLightbox(this.taskUpdateImages[localizer]);
                }

            });
            window.document.addEventListener('mouseover', (event)=>{
                var element = event.target;
                if(element.tagName == 'A') {
                    let localizer = element.dataset.localizer;
                    if(localizer !== undefined) {
                            this.contactTooltipShow = false;

                            let user = this.historyUsers.find((user)=> user.localizer == localizer);
                            if(user) {
                                element.setAttribute('id', localizer);
                                this.contactTooltipTarget = localizer;
                                this.contactTooltipContent = this.contactTooltipData(user);
                                setTimeout(()=>{this.contactTooltipShow = true;});


                            } else {
                                // this.contactTooltipShow = false;
                            }
                        }

                }
            });
        });


        this.$watch(()=> this.workflowTaskActivityForm.updateMessage, (newText, oldText) => {
            this.textAutosave();
        });
        this.$watch(()=> this.mentionLoad, (att, old) => {
           if(!att) {
                let data = JSON.parse(localStorage.getItem(`autosave-${this.user.id}-${this.taskId}`));
                if(data) {
                    this.workflowTaskActivityForm.updateMessage = data.message;
                    this.workflowTaskActivityForm.localizers = data.localizers;
                }
           }
        });

        this.$watch(()=> this.inputTime.hours, (newData, oldData) => {
            this.workflowTaskActivityForm.updateTime = `${this.pad(this.inputTime.hours)}:${this.pad(this.inputTime.minutes)}`;
        });
        this.$watch(()=> this.inputTime.minutes, (newData, oldData) => {
            this.workflowTaskActivityForm.updateTime = `${this.pad(this.inputTime.hours)}:${this.pad(this.inputTime.minutes)}`;
        });

        setTimeout(() => { this.markNotificationsAsReadByTask(this.taskId); }, 500);
    },
    beforeMount(){
        this.setExtensions();
        this.getUser();
        if (this.$route.params.task_id) {
            this.taskId = this.$route.params.task_id;
        }
        this.getHistoryUsers(this.taskId);
        this.getTask(this.taskId);
        this.getTaskUpdate(this.taskId);
        this.getTaskImages(this.taskId);

        this.$watch(() => this.showLog, (att, old) => {
            this.getTask(this.taskId);
        this.getTaskUpdate(this.taskId);
        this.getTaskImages(this.taskId);
        });
    },
    beforeDestroy(){
        window.Echo.leave(`private.${this.currentSubdomain}.${this.taskId}`);
    }
};
</script>

<template>
    <div v-if="Object.keys(user).length > 0">
        <b-tooltip
            :show.sync="contactTooltipShow"
            ref="contact-tooltip"
            id="contact-tooltip"
            :target="contactTooltipTarget"
            placement="left"
            style="z-index: 90000"
            >
        <div v-html="contactTooltipContent"></div>
        </b-tooltip>
        <!-- {{ workflowTaskActivity }} -->
        <a href="" class="d-none download" download></a>
        <div class="chat-container">
            <div class="chat-conversation chat-start-content p-0" :class="{'chat-container-max-height': helpEditor.show}">
                <simplebar data-simplebar data-simplebar-auto-hide="true"
                    class="current-scroll-container chat-position"
                    ref="current">
                    <ul class="list-unstyled" v-for="(update, updateKey) in workflowTaskActivity" :key="updateKey">
                        <li>
                            <div class="chat-day-title">
                                <span class="title" :v-model="updateKey">{{ updateKey }}</span>
                            </div>
                        </li>

                        <li v-for="(iteration, iterationKey) in update" :key="iterationKey"
                            :class="{ 'right': iteration.user_id == user.id, 'd-none': iteration.request && iteration.is_closing && !userArea }" :id="`iteration_${iteration.id}`">
                            <div class="conversation-list d-flex flex-direction-column" :class="{'w-100': iteration.message.indexOf('move') > -1, 'mw-100':iteration.message.indexOf('move') < 0, 'flex-row-reverse justify-content-end': iteration.user_id != user.id }">
                                <div class="ctext-wrap message-container" :class="{'bg-log text-log': iteration.is_log}">
                                    <div class="conversation-name"  v-if="iteration.user_id == user.id">

                                        <span class="sub text-secondary" v-if="iteration.is_log"><i>Log</i></span>
                                        <span class="sub text-secondary" v-if="iteration.deleted_at"><i class="text-danger">Excluído</i></span>
                                        <span class="sub text-secondary" v-if="iteration.edited"><i>Editado</i></span>
                                        <span class="sub text-warning" v-if="iteration.comment"><span class="copy-element me-1"> Comentário</span><i class="bx bx-comment-dots bx-flip-horizontal"></i> </span>
                                        <span class="sub text-primary" v-if="iteration.copy" @mouseenter="toggleCopy($event, true)" @mouseleave="toggleCopy($event, false)"><span class="copy-element d-none"> Cópia</span> <i class="bx bx-copy-alt"></i></span>
                                        <span class="ms-0 font-size-13" v-if="iteration.deleted_at" v-b-tooltip.lefttop="`Excluido em ${getDateFormat(iteration.deleted_at)}`">{{ iteration.user != undefined ? iteration.user.full_name :"WorkflowPro™"}}</span>
                                        <span class="ms-0 font-size-13" v-else-if="!iteration.edited">{{ iteration.user != undefined ? iteration.user.full_name :"WorkflowPro™"}}</span>
                                        <span class="ms-0 font-size-13" v-else-if="iteration.edited" v-b-tooltip.lefttop="`${user.user_type_id != 3 && iteration.editor? iteration.editor.full_name + ' - ' : '' }${getDateFormat(iteration.updated_at)}`">{{ iteration.user != undefined ? iteration.user.full_name :"WorkflowPro™"}}</span>
                                        <div class="activity-menu activity-menu-right" v-if="!iteration.is_log">
                                            <b-dropdown variant=" btn text-decoration-none p-0 m-0" no-caret menu-class="activity-menu-dropdown-right" v-if="!iteration.deleted_at || showDownload[iteration.id]">
                                                <template v-slot:button-content>
                                                    <i class="bx bx-dots-vertical-rounded text-white activity-menu-dropdown-icon"></i>
                                                </template>
                                                <b-dropdown-item v-if="!iteration.deleted_at && Object.keys(reply).length < 1" @click="editIteration(iteration)"><i class="fas fa-pencil-alt activity-menu-edit"></i>Editar</b-dropdown-item>
                                                <b-dropdown-item v-if="!iteration.deleted_at && !workflowTaskActivityForm.id" @click="setReply(iteration, true)"><i class="fas fa-reply activity-menu-edit"></i>Responder</b-dropdown-item>
                                                <b-dropdown-item v-if="showDownload[iteration.id]" @click="downloadFile(iteration.files.length == 1 ? iteration.files[0].uuid: iteration.id, iteration.files.length == 1 ? false :true)"><i class="bx bxs-download pe-1"></i>{{iteration.files.length > 1? 'Baixar tudo':'Baixar'}}</b-dropdown-item>
                                                <b-dropdown-divider v-if="!iteration.deleted_at"></b-dropdown-divider>
                                                <b-dropdown-item v-if="!iteration.deleted_at" @click="deleteActivity(iteration)"><i class="bx bx-trash pe-1"></i>Excluir</b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                    </div>
                                    <div class="conversation-name"  v-else>
                                        <div class="activity-menu activity-menu-left"  v-if="iteration.user_id !== null">
                                            <b-dropdown variant=" btn text-decoration-none p-0 m-0" no-caret menu-class="activity-menu-dropdown-left">
                                                <template v-slot:button-content>
                                                    <i class="bx bx-dots-vertical-rounded text-white activity-menu-dropdown-icon"></i>
                                                </template>
                                                <b-dropdown-item v-if="!iteration.deleted_at && checkEditMode(company.edit_mode, user, iteration.user) && Object.keys(reply).length < 1" @click="editIteration(iteration)"><i class="fas fa-pencil-alt activity-menu-edit"></i>Editar</b-dropdown-item>
                                                <b-dropdown-item v-if="!iteration.deleted_at && !workflowTaskActivityForm.id" @click="setReply(iteration, true)"><i class="fas fa-reply activity-menu-edit"></i>Responder</b-dropdown-item>
                                                <b-dropdown-item v-if="showDownload[iteration.id]" @click="downloadFile(iteration.files.length == 1 ? iteration.files[0].uuid: iteration.id, iteration.files.length == 1 ? false :true)"><i class="bx bxs-download pe-1"></i>{{iteration.files.length > 1? 'Baixar tudo':'Baixar'}}</b-dropdown-item>
                                            </b-dropdown>
                                        </div>
                                        <span class="me-0 font-size-13" v-if="iteration.deleted_at" v-b-tooltip.righttop="`Excluido em ${getDateFormat(iteration.deleted_at)}`">{{ iteration.user != undefined ? iteration.user.full_name :"WorkflowPro™"}}</span>
                                        <span class="me-0 font-size-13" v-else-if="!iteration.edited">{{ iteration.user != undefined ? iteration.user.full_name :"WorkflowPro™"}}</span>
                                        <span class="me-0 font-size-13" v-else-if="iteration.edited" v-b-tooltip.righttop="`${user.user_type_id != 3 && iteration.editor? iteration.editor.full_name + ' - ' : '' }${getDateFormat(iteration.updated_at)}`">{{ iteration.user != undefined ? iteration.user.full_name :"WorkflowPro™"}}</span>
                                        <span class="sub text-warning" v-if="iteration.comment"><i class="bx bx-comment-dots me-1"></i><span class="copy-element"> Comentário</span> </span>
                                        <span class="sub text-primary" v-if="iteration.copy" @mouseenter="toggleCopy($event, true)" @mouseleave="toggleCopy($event, false)"><i class="bx bx-copy-alt"></i><span class="copy-element d-none"> Cópia</span> </span>
                                        <span class="sub text-secondary" v-if="iteration.edited"><i>Editado</i></span>
                                        <span class="sub text-secondary" v-if="iteration.deleted_at"><i class="text-danger">Excluído</i></span>
                                        <span class="sub text-secondary" v-if="iteration.is_log"><i>Log</i></span>
                                    </div>

                                    <!-- Reply -->
                                    <div class="row link-cursor" v-if="iteration.reply" @click.prevent="goToActivity(iteration.reply.id)">
                                        <div class="col-12 h-100 align-chat-x">
                                            <div class="card border-end-0 border-top-0 border-bottom-0 border-success border-4">
                                                <div class="card-body py-0 pt-2">
                                                    <div class="conversation-name-reply">
                                                        <span class="ms-0 font-size-13">{{ iteration.reply.user? iteration.reply.user.full_name : 'Anônimo'}}</span>
                                                    </div>
                                                    <div class="text-align-justify font-size-12 line-break-anywhere">
                                                        <div v-html="iteration.reply.message" class="reply-content overflow-text"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="iteration.deleted_at" class="text-align-justify font-size-12 line-break-anywhere"></div>
                                    <div v-else v-html="iteration.message" class="text-align-justify font-size-12 line-break-anywhere"></div>
                                    <div
                                        v-if="iteration.request && user.id != iteration.user_id  && iteration.is_closing && !iteration.response_user_id">

                                        <b-row class="justify-content-center pt-3 border-top border-secondary">

                                            <b-col cols="6" md="6" class="d-flex  justify-content-center" v-if="task.finalized">
                                                <b-button variant="danger" class="w-75"
                                                    @click="reponseClosing(taskId, iteration.id, false)">Não</b-button>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div
                                        v-else-if="iteration.request && user.id != iteration.user_id && (user.user_type_id == 1 || user.user_type_id == 2 || user.user_type_id == 4) && !iteration.is_closing">
                                        <b-row class="justify-content-center pt-3 border-top border-secondary">
                                            <b-col cols="12" class="d-flex  justify-content-center">
                                                <span class="w-75 text-center fw-bold font-size-15 text-info">Reabertura Solicitada</span>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div
                                        v-else-if="iteration.request && iteration.response_user_id && !iteration.is_closing">
                                        <b-row class="justify-content-center pt-3 border-top border-secondary">
                                            <b-col cols="12" class="d-flex  justify-content-center">
                                                <span class="w-75 text-center fw-bold font-size-15 text-info">Reabertura
                                                    Solicitada</span>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div v-else-if="iteration.request && !iteration.response_user_id && userArea">
                                        <b-row class="justify-content-center pt-3 border-top border-secondary">
                                            <b-col cols="12" class="d-flex justify-content-center">
                                                <span class="w-75 text-center fw-bold font-size-15" :class="[`text-${(task.color? task.color.replace('bg-','') : 'secondary')}`]">{{
                                                            task.status
                                                    }}</span>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div
                                        v-else-if="iteration.request && iteration.response_user_id && iteration.is_closing && userArea">
                                        <b-row class="justify-content-center pt-3 border-top border-secondary">
                                            <b-col cols="12" class="d-flex  justify-content-center">
                                                <span class="w-75 text-center fw-bold font-size-15"
                                                    :class="[`text-${(task.color? task.color.replace('bg-','') : 'secondary')}`]">{{
                                                            task.status
                                                    }}</span>
                                            </b-col>
                                        </b-row>
                                    </div>

                                    <p class="chat-time mb-0 d-flex align-items-center justify-content-between" :class="{ 'flex-row-reverse': iteration.user_id != user.id }">
                                        <span class="text-success" v-show="!iteration.subtraction && iteration.additional_time && !iteration.deleted_at"><i class="bx bx-plus align-middle me-1" v-show="iteration.additional_time"></i>{{ iteration.additional_time }}</span>
                                        <span class="text-danger" v-show="iteration.subtraction && iteration.additional_time && !iteration.deleted_at"> - {{ iteration.additional_time }}</span>
                                        <span>
                                            <i class="bx bx-time-five align-middle me-1"></i>
                                            {{ iteration.creation_time }}
                                        </span>
                                    </p>
                                </div>
                            </div>

                            <div class="chat-day-title update" v-if="iteration.id == iteration.last_read">
                                <span class="title">Atualizações não lidas</span>
                            </div>
                        </li>

                        <input type="text" class="d-none chat-focus" name="focus" ref="messagefocus" id="messagefocus">
                    </ul>
                </simplebar>
            </div>
        </div>

        <div class="row type-message-container" :class="{ 'justify-content-center align-items-center p-3': userArea }" @dragover="dragFile = true, helpEditor.show = true">
            <div class="col  bg-chat-"
                :class="{ 'chat-zindex chat-footer pt-3 pb-3 margin-left-12': !userArea, 'p-3': userArea }">
                <!-- Reply -->
                <transition name="jump" tag="div">
                    <div class="row" v-if="Object.keys(reply).length > 0">
                        <div class="col-12 h-100 align-chat-x" :class="{'reply-bottom-user-area': userArea && helpEditor.show }">
                            <div class="card border-end-0 border-top-0 border-bottom-0 border-success border-4" :class="{'reply-border-user-area': userArea }">
                                <div class="card-body py-0 pt-2">
                                    <div class="conversation-name-reply">
                                        <span class="ms-0 font-size-13">{{ reply.user? reply.user.full_name : 'Anônimo'}}</span>
                                        <div class="activity-menu activity-menu-right">
                                            <a href="#" @click.prevent="setReply({})"><i class="bx bx-x text-danger"></i></a>
                                        </div>
                                    </div>
                                    <div class="text-align-justify font-size-12 line-break-anywhere">
                                        <div v-html="reply.message" class="reply-content overflow-text"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <form action="#" class="col-12 chat-input-section pt-3" @submit.prevent="setTaskUpdate">
                    <div class="row mb-3" :class="{ 'align-chat-x w-100': !userArea }">

                        <div class="col-12" :class="{'pe-0': !userArea}" v-if="task">
                            <b-input ref="edit-input-button" @focus="toggleEditor" v-show="!helpEditor.show" :placeholder="(task? (typeof task.finalized != undefined && !task.finalized? 'Digite sua mensagem' : 'Atendimento encerrado') : 'Digite sua mensagem')"  :disabled="task.finalized"></b-input>
                            <div class="position-relative ck-container" v-show="helpEditor.show">

                                <Editor
                                    ref="tinyeditor"
                                    name="tinyeditor"
                                    id="tinyeditor"
                                    :init="tinyConfig"
                                    :disabled="task? (typeof task.finalized != undefined && !task.finalized? false : true) : false"
                                    :plugins="tinymcePlugins"
                                    :toolbar="tinymceToolbar"
                                    v-model="workflowTaskActivityForm.updateMessage"
                                    @ondragover="dragFile = true"
                                    @ondrop="dragFile = false, checkUpload($event)"
                                    @onpaste="dragFile = false, checkUpload($event)"
                                    @onblur="closeEditor($event)"
                                    v-if="!mentionLoad"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-between font-size-19">
                        <b-col cols="7" class="d-flex justify-content-start align-items-center">
                            <div class="d-flex align-items-center justify-content-start link-cursor" v-if="!userArea">
                                <!-- left user area -->
                            </div>
                            <div class="col-3 col-md-2" v-if="!userArea">
                                <div class="d-flex align-items-center justify-content-start" v-if="!userArea && ((inputTime.hours != 0 && inputTime.hours != null) || (inputTime.minutes != 0 && inputTime.minutes != null))">
                                    <span v-if="workflowTaskActivityForm.updateTimeOperator" class="font-size-20 text-success">+</span>
                                    <span v-else class="font-size-20 text-danger">-</span>
                                    <span class="ms-1">{{ workflowTaskActivityForm.updateTime }}</span>
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="5" class="d-flex justify-content-end">
                            <b-col cols="4" md="2" class="d-flex align-items-center justify-content-end" v-if="!userArea">
                                <b-dropdown id="dropdown-update-time" ref="dropdown-update-time" variant=" btn btn-primary  btn-rounded bg-primary chat-send waves-effect waves-light send-btn sq-32" menu-class="menu-time" toggle-class="text-decoration-none" no-caret  v-b-tooltip.left="'Tempo'">
                                        <template v-slot:button-content>
                                            <i class="bx bx bx-time-five fs-2"></i>
                                        </template>
                                        <div class="card mb-0">
                                            <div class="card-body">
                                                <h6>{{ workflowTaskActivityForm.updateTimeOperator ? 'Adicionar' : 'Remover'}} tempo</h6>
                                                <div class="row mt-4">
                                                    <div class="col-1 col-md-1 d-flex align-items-center justify-content-start link-cursor">
                                                        <div class="d-flex align-items-center justify-content-start link-cursor" v-if="!userArea">
                                                            <span @click="workflowTaskActivityForm.updateTimeOperator = !workflowTaskActivityForm.updateTimeOperator" v-if="workflowTaskActivityForm.updateTimeOperator" class="font-size-20 text-success">+</span>
                                                            <span @click="workflowTaskActivityForm.updateTimeOperator = !workflowTaskActivityForm.updateTimeOperator" v-else class="font-size-20 text-danger">-</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-9  col-md-9 d-flex">
                                                        <div class="row align-items-center justify-content-between">
                                                            <div class="col-6">
                                                                <a href="javascript:void(0)" class="d-flex justify-content-center" @click.prevent="inputTimeIncrement()"><i class="bx bx-chevron-up"></i></a>
                                                                <input
                                                                    type="text"
                                                                    v-model="inputTime.hours"
                                                                    v-mask="'##'"
                                                                    @keydown="inputTimeFilter($event, true)"
                                                                    ref="updateTimeHours"
                                                                    name="updateTime"
                                                                    id="updateTime"
                                                                    placeholder="00"
                                                                    class="form-control-plaintext text-center input-arrow-less"
                                                                    :disabled="task ? task.finalized : false"
                                                                    @keydown.tab.prevent="()=> {$refs['updateTimeMinutes'].focus()}"
                                                                    >
                                                                <a href="javascript:void(0)" class="d-flex justify-content-center" @click.prevent="inputTimeDecrement()"><i class="bx bx-chevron-down"></i></a>
                                                            </div>
                                                            <div class="col-6">
                                                                <a href="javascript:void(0)" class="d-flex justify-content-center" @click.prevent="inputTimeIncrement(true)"><i class="bx bx-chevron-up"></i></a>
                                                                <input
                                                                    max="59"
                                                                    type="text"
                                                                    v-mask="[/[0-5]/,/[0-9]/]"
                                                                    v-model="inputTime.minutes"
                                                                    @keydown="inputTimeFilter($event, true)"
                                                                    ref="updateTimeMinutes"
                                                                    name="updateTime"
                                                                    id="updateTime"
                                                                    placeholder="00"
                                                                    class="form-control-plaintext text-center input-arrow-less"
                                                                    :disabled="task ? task.finalized : false"
                                                                    @keydown.tab.prevent="()=> {$refs['updateTimeOkButton'].focus()}"
                                                                    >
                                                                <a href="javascript:void(0)" class="d-flex justify-content-center" @click.prevent="inputTimeDecrement(true)"><i class="bx bx-chevron-down"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center justify-content-center mt-4">
                                                    <div class="col-12 d-grid gap-2">
                                                        <a href="#"
                                                            ref="updateTimeOkButton"
                                                            @click.prevent="$refs['dropdown-update-time'].hide(true)"
                                                            @keydown.tab.prevent="()=> {$refs['updateTimeHours'].focus()}"
                                                            class="text-decoration-none btn btn-success btn-small">ok</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-dropdown>
                            </b-col>
                            <transition name="jump" tag="div" v-if="user.user_type_id == 1 || user.user_type_id == 2 || user.user_type_id == 4">
                                <ContentBox @select="dispatchMessage" :items="shortcutsOptions" right="6%" :searchable="false" title="Atalhos para mensagem"  v-show="showShortcutContainer" class="position-right index-5" tabindex="0"  @focusout.native="showShortcutContainer = false" ref="content-box-shortcut"></ContentBox>
                            </transition>
                            <b-col cols="4" md="2" class="d-flex align-items-center justify-content-end" v-if="(user.user_type_id == 1 || user.user_type_id == 2 || user.user_type_id == 4) && shortcutsOptions.length > 0">
                                <b-button
                                    v-b-tooltip.left="'Atalhos'"
                                    @mousedown.left.prevent="toggleContentBoxShortcut"
                                    variant=" btn btn-primary  btn-rounded bg-primary chat-send waves-effect waves-light send-btn">
                                     <i class="fas fa-share-square"></i>
                                </b-button>
                            </b-col>

                            <b-col cols="4" md="2" class="d-flex align-items-center justify-content-end btn-activity-submit" v-b-tooltip.left="`CTRL + Enter`">
                                <b-button type="submit"
                                    variant=" btn btn-primary btn-rounded chat-send waves-effect waves-light send-btn"
                                    :disabled="task ? task.finalized : false">
                                     <i class="fas fa-pencil-alt" v-if="workflowTaskActivityForm.id && !workflowTaskActivityForm.delete"></i> <i v-else class="mdi mdi-send"></i>
                                </b-button>
                            </b-col>
                            <b-col cols="4" md="2" class="d-flex align-items-center justify-content-end" v-b-tooltip.left="`Cancelar edição`" v-if="workflowTaskActivityForm.id && !workflowTaskActivityForm.delete">
                                <b-button @click.prevent="cancelEdit" variant=" btn btn-danger btn-rounded chat-send waves-effect waves-light send-btn" :disabled="!workflowTaskActivityForm.id || workflowTaskActivityForm.delete">
                                     <i class="bx bx-x fs-2"></i>
                                </b-button>
                            </b-col>
                        </b-col>
                    </div>
                </form>
            </div>
        </div>
        <b-modal id="progress-upload-modal" title="upload" title-class="font-18" ok-only @hide="clearUploadProgressItems">
                <div>
                    <div class="col-12">
                        <b-table :items="uploadProgressItems" responsive thead-class="d-none" borderless :fields="uploadProgressItemsFields">
                            <template #cell(index)="row">
                                <h6>{{ row.item.name }}</h6>
                            </template>
                            <template #cell(name)="row">
                                <h6>{{ row.item.name }}</h6>
                            </template>
                            <template #cell(progress)="row">
                                <b-progress v-model.lazy="row.item.progress" :max="100"
                                 show-progress class="cell-height bg-secondary" :class="{ 'success-progress-bar': row.item.progress == 100 }">
                                  <b-progress-bar :value="row.item.progress">
                                    <span v-if="row.item.progress > 0">{{
                                      row.item.progress
                                    }}%</span>
                                  </b-progress-bar>
                                </b-progress>
                            </template>
                        </b-table>
                    </div>
                </div>
        </b-modal>
        <input type="file" ref="updateFile" name="updateFile[]" id="updateFile" multiple="true" class="d-none form-control form-control-sm updateFile" :accept="extensionsAccept" @change="uploadFile">
        <vue-easy-lightbox :visible="lightbox.showLightbox" :imgs="lightbox.taskUpdateLigthBoxImages"
            :index="lightbox.indexLightbox" @hide="lightbox.showLightbox = false"></vue-easy-lightbox>
    </div>
</template>

<style>
.conversation-name-reply {
    color: #50a5f1;
    margin-bottom: 4px;
}
.reply-content, .reply-content * {
    max-height: 60px;
    max-width: 100%;
    height: auto;
    width: auto;
    display: inline-flex;
}
.reply-content p {
    margin-left: 4px;
    white-space: break-spaces !important;
    text-overflow: ellipsis !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 20px;     /* fallback */
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.reply-content p:has(> img) {
    overflow: unset !important;
    max-height: 60px !important;
}

.reply-content * img {
    height: auto;
    max-width: -webkit-fill-available !important;
    max-width: -moz-available !important;
    padding-bottom: 5px;
    position: relative;
    right: 14px;
}

.reply-content p:has(> img):nth-child(n + 3) {
    display: none;
}

.align-chat-x {
    right: 1px;
    left: 1px;
    width: 98.5%;
}

.reply-border-user-area {
    border-top: 1px solid rgb(195, 203, 228, 0.3)!important;
    border-bottom: 1px solid rgb(195, 203, 228, 0.3) !important;
    border-right: 1px solid rgb(195, 203, 228, 0.3) !important;
}
.reply-bottom-user-area {
    bottom: 174px;
}
.conversation-list img {
    max-width: 100%;
    height: auto;
}
.hide-editor .tox.tox-tinymce {
    display: none !important;
}
.tox-fullscreen {
    z-index: 5;
}
.btn-activity-submit {
    z-index: 6;
}
.sub{
    margin-top: -0.4rem;
    margin-bottom: 0.5rem;
    font-size: 80%;
    opacity: 0.7;
    color: #c3cbe4;
}
.text-align-justify{
    text-align: justify !important;
}
.position-right{
    /* right: 27%; */
    right: 20%;
    position: absolute;
}
.index-5 {
    z-index: 5;
}

.message-container {
    min-width: 11vw;
}

/** Chat */
.current-scroll-container .simplebar-wrapper .simplebar-mask .simplebar-offset .simplebar-content-wrapper .simplebar-content{
    position: relative;
}

.current-scroll-container .simplebar-wrapper .simplebar-mask .simplebar-offset .simplebar-content-wrapper {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}


.chat-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 96.5%;
    /* padding: 0% 2% 0% 3%; */
    padding: 8px;
}
.margin-left-12{
    margin-left: 12px;
}
.chat-zindex {
    z-index: 0;
}

.close-task:hover {
    color: 244, 106, 159;
}

.ck.ck-balloon-panel {
    z-index: 1009;
}
/** Ligthbox */
.image-ligthbox {
    width: 19.5rem;
}

.link-cursor {
    cursor: pointer;
}
.font-size-2-rem {
    font-size: 2rem;
}
figcaption.ck-editor__editable.ck-editor__nested-editable {
    min-height: unset !important;
}
input#updateTime::-webkit-calendar-picker-indicator {
    filter: invert(1);
    margin-right: 6px;
}
.emoji-picker{
    position: absolute;
    bottom: 17px;
    right: 51%;
}
.grid-emojis .emoji {
    border: unset !important;
}
.send-btn{
    height: 2rem;
    width: 2rem;
    padding: 0;
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
}

.scr-right::-webkit-scrollbar {
    width: 6px !important;
}

.scr-bottom::-webkit-scrollbar {
    height: 6px;
}

.scr-right::-webkit-scrollbar-track {
    background: transparent !important;
    color: #4a4a4a !important;
    border-radius: 10px !important;
}

.scr-right::-webkit-scrollbar-thumb:hover {
    background: #4a4a4a !important;
    opacity: 0.6 !important;
    border-radius: 10px !important;
}

.tox-statusbar__branding, .tox-promotion {
    display: none;
}
.rte-autocomplete {
    z-index: 3000;
}
.line-break-anywhere {
    line-break: anywhere;
}
.mce-content-body {
    color: #eee !important;
    background-color: #464f6b !important;
}
iframe body .mce-content-body {
    color: #eee !important;
    background-color: #464f6b !important;
}
.flash .conversation-list .ctext-wrap{
    animation: blink 1.5s ease;
}

@keyframes blink{
   0%{
     opacity: 1;
   }
   25%{
     opacity: 0;
   }
   50%{
     opacity: 1;
   }
   75%{
     opacity: 0;
   }
   100%{
     opacity: 1;
   }
 }
 .menu-time {
    bottom: 0 !important;
    top: unset !important;
    right: 0 !important;
    left: unset !important;

}
.menu-time.dropdown-menu.show {
     top: unset !important;
 }
.input-arrow-less {
    -moz-appearance: textfield;
    appearance: textfield;
    margin: 0;
}
.input-arrow-less::-webkit-inner-spin-button, .input-arrow-less::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.chat-start-content {
    bottom: 130px;
    /* bottom: 20vh; */
    position: absolute;
    left: 2vw;
    right: 2vw;
    height: auto;
    top: 100px;
}
.chat-start-content-description-top * .chat-start-content {
    top: 180px !important;
}

.tox-navobj {
    background-color: #fff !important;
}

.activity-menu {
    position: absolute;
    top: 6px;
}
.activity-menu-dropdown-icon {
    font-size: 13px !important;
    width: 100%;
    height: 100%;
}
.activity-menu-right {
    right: 5px;
}
.activity-menu-left {
    left: 5px;
}
.activity-menu-dropdown-right{
    top: 100% !important;
    right: 0 !important;
    left: unset !important;
}
.activity-menu-dropdown-left {
    top: 100% !important;
    left: 0 !important;
    right: unset !important;
}
.activity-menu-edit {
    font-size: 9px !important;
    padding-right: 8px;
}
.sq-32 {
    width: 32px !important;
    height: 32px !important;
}
.chat-day-title.update{
    color: #e3cba2 !important;
}
.chat-day-title.update::before{
    background-color: #e3cba2 !important;
}
.chat-position {
    max-height: 100% !important;
}
.chat-container-max-height {
    max-height: calc(100% - 376px);
}
.chat-conversation .conversation-list {
    display: inline-block !important;
}
.bg-log {
    background: rgba(145, 166,238, 0.05) !important;
}
.text-log {
    color: rgba(var(--bs-secondary-rgb), 0.6);
}
.ctext-wrap {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 14px 28px, rgba(0, 0, 0, 0.08) 0px 10px 10px;
}

/* simplebar */
.current-scroll-container .simplebar-vertical .simplebar-visible {
    position: absolute;
    content: ' ';
    background: #a6b0cf;
    border-radius: 7px;
    left: 7px;
    right: 2px;
    opacity: 0.5;
    transition: opacity .2s linear;

}

</style>
