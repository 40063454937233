<template>
    <div :class="{'disabled-opacity': disabled}">
        <div class="d-flex align-items-center cell-height w-100" v-if="task.checklists.length > 0">
        <div class="col-12 p-0 link-cursor"
            @click.prevent="showCkecklist(task, column, columnValue.id, true)">
            <b-progress style="border-radius:unset; background-color: #2d3346;"
            v-model.lazy="checklistProgess" :max="100"
            :class="{ 'success-progress-bar': checklistProgess == 100 }"
            show-progress class="cell-height">
            <b-progress-bar :value="checklistProgess">
                <span v-if="checklistProgess > 0">{{
                checklistProgess
                }}%</span>
            </b-progress-bar>
            </b-progress>
        </div>
        </div>
        <div class="row justify-content-center" v-else>
        <div class="col-11">
            <div
            class="d-flex justify-content-center align-items-center cell-height">
            <b-button @click="showCkecklist(task, column, columnValue.id)"
                variant="btn-edit text-decoration-none fs-6 text-secondary p-0"
                :disabled="disabled"><span><i
                    class="fas fa-plus-circle text-white"></i></span></b-button>
            </div>
        </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';

    export default {
        props: {
            column: {
                default: {},
            },
            columnValue: {
                default: {},
            },
            task: {
                default: {},
            },
            disabled: {
                default: false,
            },
        },
        computed: {
            checklistProgess() {
                let total = 0;
                let complete = 0;
                return (this.task.checklists.reduce(function(accumulator,checklist){
                    total += checklist.options.length
                    complete += checklist.complete
                    return (complete * 100) / total
                },0)).toFixed(2);
            }
        },
        methods: {
            ...mapActions('tasks', ['setTaskChecklistsOptions']),
            showCkecklist(task, column, columnValueId, fastMode = false) {
                this.setTaskChecklistsOptions({task, column, columnValueId, fastMode, clear: false});
                this.$root.$emit('bv::show::modal', 'modal-checklist')
            },
        }

    }
</script>

<style lang="scss" scoped>

</style>


