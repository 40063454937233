<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import axios from "axios";
import Multiselect from "vue-multiselect";
import showAlert from "../../../components/alerts";
import config from "../../../config/config";
import draggable from "vuedraggable";
import { companiesComputed , userComputed, userMethods} from "../../../state/helpers";
import { mapState, mapActions } from "vuex";

import {
    required,
    minLength
} from "vuelidate/lib/validators";

export default {
    components: {
        Multiselect,
        VueFormWizard: FormWizard,
        TabContent,
        draggable
    },
    props: {
        taskId: {
            type: Number,
        },
        workflowId: {
            type: Number,
            default: 0
        },
        fastMode: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            // user: null,
            submitted: false,
            editMode: false,
            modalEditableMode: false,
            checklist: { name: '', id: null },
            checklists: [],
            createdBy: '',
            selectedChecklist: { name: '', id: null, options: [], defaultOption: true },
            selectedChecklistId: null,
            checklistOptions: [{ value: null, text: 'selecione' }],
            saveChecklistDisabled: false,
            showOptions: false,
            drag: false,
            showDatepicker: {},
            selectedDatepicker: null,
            searchDropdown: '',
            hasDeadline: true,
            queryChecklist: null,
            queryChecklistOption: null,
            titleSuggestion: '',
        };
    },
    validations: {

        checklist: {
            name: {
                required,
                minLength: minLength(2)
            }
        }
    },
    methods: {
        ...mapActions('workflows', ['collaboratorOptions']),
        ...userMethods,
        // getUser() {

        //     let url = window.location.origin + '/api/v1/user';
        //     const userData = axios.post(url, null, config.getHeaders)
        //         .then(response => {
        //             this.user = response.data;
        //         });

        // },
        // checklist methods
        saveChecklist(taskId, name, e = null, checklistId = null) {
            if (e) e.preventDefault();


            if (!taskId) {
                showAlert(false, 'Tarefa não selecionada');
            }

            if (name) {
                this.saveChecklistDisabled = true;
                this.submitted = true;
                this.$v.$touch();
                if (!this.$v.$anyError) {

                    const getStates = axios.post(window.location.origin + '/api/v1/workflow/columns/checklist/save', { task_id: taskId, name: name, checklist_id: checklistId })
                        .then(response => {
                            var data = response.data;
                            if (!data.error) {
                                var checklist = data.data;
                                this.getChecklists(this.taskId);
                                this.checklist.name = "";
                                showAlert(false, data.message);
                                this.$emit('change', checklist);
                                // this.$root.$emit('bv::hide::modal', 'modal-checklist')

                                // select the new checklist
                                this.selectedChecklistId =  checklist.id
                                if(this.selectedChecklistId){
                                    setTimeout(()=>{
                                        this.selectedChecklist = this.checklists.find(e => e.id == this.selectedChecklistId);
                                        if(this.selectedChecklist){
                                            this.createdBy = this.selectedChecklist.creator.name;
                                            this.modalEditableMode = true;
                                            this.editMode = true;
                                            this.addOption();
                                            this.showOptions = true;
                                        }
                                        this.modalEditableMode = true;
                                    },600)
                                }
                            }
                            this.saveChecklistDisabled = false;
                        }).catch(error => {
                            if (error.response) {
                                showAlert(true, error.response.data.message);
                            }
                            this.saveChecklistDisabled = false;
                        });
                }
            }
        },
        getChecklists(taskId) {
            if (!taskId) {
                showAlert(false, 'Tarefa não selecionada');
            }

            const getChecklists = axios.get(window.location.origin + '/api/v1/workflow/columns/checklist/show/task/' + taskId)
                .then(response => {
                    var data = response.data;
                    if (!data.error) {
                        this.hasDeadline = data.data.hasDeadline;

                        var checklists = data.data.checklists;
                        this.checklists = checklists;
                        this.checklistOptions = [{ value: null, text: 'selecione' }];
                        checklists.forEach((checklist) => {
                            this.checklistOptions.push({ value: checklist.id, text: checklist.name });
                        });

                        if(this.queryChecklist) {
                            this.selectedChecklistId = this.queryChecklist;
                            this.checkOptions();
                        }
                    }
                }).catch(error => {
                    if (error.response) {
                        showAlert(true, error.response.data.message);
                    }
                });
        },
        deleteChecklist(checklistId) {
            if (checklistId) {

                this.$swal
                    .fire({
                        title: "Excluir checklist " + this.selectedChecklist.name + "?",

                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#34c38f",
                        cancelButtonColor: "#f46a6a",
                        confirmButtonText: "Sim, deletar!",
                        cancelButtonText: "Cancelar",
                    })
                    .then((result) => {
                        if (result.value) {
                            const getStates = axios.delete(window.location.origin + '/api/v1/workflow/columns/checklist/' + checklistId)
                                .then(response => {
                                    var data = response.data;
                                    if (!data.error) {
                                        showAlert(false, data.message);
                                        this.$root.$emit('bv::hide::modal', 'modal-checklist')
                                    }
                                    this.getChecklists(this.taskId);
                                    setTimeout(() => {
                                        if (this.checklistOptions.length > 0) {
                                            this.selectedChecklistId = this.checklistOptions[1].value;
                                            this.selectedChecklist = this.checklistOptions[1];
                                            this.checkOptions();
                                        } else {
                                            this.selectedChecklistId = this.checklistOptions[0].value;
                                            this.selectedChecklist = this.checklistOptions[0];
                                            this.checkOptions();
                                        }
                                    }, 100);
                                }).catch(error => {
                                    if (error.response) {
                                        showAlert(true, error.response.data.message);
                                    }
                                    this.getChecklists(this.taskId);
                                });
                        }
                    });
            } else {
                showAlert(true, 'Selecione um checklist')
            }
        },
        // checklist option methods
        saveChecklistOption(checklist_id, element, isButtonNew = false, alert = false, index = false, toggle = false) {
            var option_id = element.id ? element.id : null;
            var option = element.option;
            var checked = element.checked;
            var deadline = element.deadline;
            var alertUserId = element.alert_user_id;

            if (!option) {
                showAlert(true, 'digite uma opção');
                return;
            } else {
                var isUpdate = !!option_id;
                var data = { checklist_id: checklist_id, options: [{ option: option, checked: checked, checklist_option_id: option_id, alert_user_id: alertUserId, deadline: deadline }] }
                const getStates = axios.post(window.location.origin + '/api/v1/workflow/columns/checklist/option', data)
                    .then(response => {
                        var data = response.data;
                        if (!data.error) {
                            if(alert){
                                showAlert(false, data.message);
                            }
                            if(toggle){
                                this.$emit('update', data.data)
                            }
                            this.$set(this.selectedChecklist.options, index, data.data[0])
                            var item = this.selectedChecklist.options.find((option)=>{ return option.id == data.data[0].id});
                            this.$set(item, 'updater', data.data[0].updater)
                            this.$set(item, 'updated_at', data.data[0].updated_at)

                            if (isButtonNew) {
                                this.addOption();
                            }

                        }
                    }).catch(error => {
                        if (error.response) {
                            showAlert(true, error.response.data.message);
                        }
                    });
            }

        },
        deleteChecklistOption(option_id) {
            if (option_id) {
                const getStates = axios.delete(window.location.origin + '/api/v1/workflow/columns/checklist/option/' + option_id)
                    .then(response => {
                        var data = response.data;
                        if (!data.error) {
                            showAlert(false, data.message);
                        }
                    }).catch(error => {
                        if (error.response) {
                            showAlert(true, error.response.data.message);
                        }
                    });
            }

        },
        addOption: function () {
            this.selectedChecklist.options.push({ option: "", checked: false, alert_user_id: null, deadline: '',  defaultOption: true});
        },
        deleteOption: function (index, option_id) {
            this.deleteChecklistOption(option_id)
            this.selectedChecklist.options.splice(index, 1);
        },
        checkOptions: function () {
            if (this.selectedChecklistId) {
                this.showOptions = true;
                this.selectedChecklist = this.checklists.find(e => e.id == this.selectedChecklistId);
                this.createdBy = this.selectedChecklist.creator.name;
                if (this.selectedChecklist.options.length == 0 && this.modalEditableMode) {
                    // this.editMode = true;
                    this.addOption();
                }
                if(this.queryChecklistOption) {
                    this.goToOption(this.queryChecklistOption)
                }
            } else {
                this.showOptions = false;
                this.selectedChecklist = this.checklistOptions[0];
                this.createdBy = '';
            }
        },
        goToOption(optionId) {
            setTimeout(()=> {
                let element = this.$el.querySelector(`#checklist_option_${optionId}`);
                let container = window.document.querySelector('.modal-body');

                if(element) {
                    this.$scrollTo(element, 500, {
                        container: container,
                        x: false,
                        y: true,
                        offset: -50,
                    });
                    setTimeout(()=> { element.classList.add('flash')}, 500);

                    setTimeout(()=> {
                        element.classList.remove('flash');
                    }, 100);
                }
            }, 200)
        },

        // sequence
        updateSequence(list) {
            this.drag = false
            var data = [];

            list.forEach((el, key)=>{
                if(el.id == 'undefined' || el.id == null){
                    list.splice(key, 1);
                }
           });
            list.forEach((option, key) => {
                data.push({ option_id: option.id, sequence: key })
            });

            data = { sequence: data };

            const updateGroup = axios
                .patch(
                    window.location.origin + "/api/v1/workflow/columns/checklist/option/sequence",
                    data,
                    config.getHeaders
                )
                .then((response) => {

                    if (!response.data.error) {
                        this.getChecklists(this.taskId);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response) {
                        showAlert(true, error.response.data.message);
                    }
                });
        },
        // Replicate checklist for all tasks
        replicateChecklist(checklistId){
            this.$swal
            .fire({
                title: 'Replicar checklist "' + this.selectedChecklist.name + '"?',
                text: 'O checklist será replicado para todas tarefas do workflow, esta ação não poderá ser revertida ',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sim, replicar!",
                cancelButtonText: "Cancelar",
            })
            .then((result) => {
                if(result.value){
                    this.$swal
                    .fire({
                        position: "center-center",
                        html: "<i class='bx bx-loader-alt bx-spin' style='font-size: 5rem;'></i><p>Replicando</p>",
                        showCancelButton: false,
                        showConfirmButton: false,
                        width: 300,
                        padding: 50,
                    });

                    const replicateChecklist = axios.post(
                        window.location.origin + "/api/v1/workflow/columns/checklist/replicate",
                        {checklist_id: checklistId},
                        config.getHeaders
                    ).then((response) => {
                        if (!response.data.error) {
                            this.$emit('replicate', response.data.data);
                            this.$swal.close();
                            showAlert(false, '');
                        }
                    }).catch((error) => {
                        console.log(error);
                        if (error.response) {
                            showAlert(true, error.response.data.message);
                        }
                    })
                }
            });
        },
        toggleEditMode(){
            this.editMode = !this.editMode;
        },
        toggleModalEditableMode(){
            this.modalEditableMode = !this.modalEditableMode;
            if(this.modalEditableMode){
                this.editMode = false;
            }
        },
        toggleChecklistOption(selectedChecklistId, optionId, index){
            this.$refs[`checklist_option_${optionId}`][0].$el.querySelector('input').click();
            var field = this.selectedChecklist.options.find(option =>  option.id == optionId);
            setTimeout(()=>{
                this.saveChecklistOption(selectedChecklistId, field, false,false, index, true)
            }, 200);
        },
        disableDateFromCompanyConfig(date){
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            let range_time = 0;
            if(this.company.default_limit_date) {
                range_time = this.company.default_limit_date * 24 * 3600 * 1000
                return date > new Date(today.getTime() + range_time);
            }

            return false;
        },
        toggleShowDatepicker(id) {
           this.$set(this.showDatepicker, `${id}`, !this.showDatepicker[`${id}`])
           let pickerKeys = Object.keys(this.showDatepicker);
           pickerKeys.forEach((key)=>{
                if(key != id) {
                    this.showDatepicker[key] = false;
                }
           });

        },
        selectDatepicker(cellId){
            this.selectedDatepicker = cellId
        },
        setAlertUser(field, user) {
            this.$set(field, 'alert_user_id', user.id);
            this.$set(field, 'alert_user', user);
        },
        setTitleSuggestion() {
            if(!this.checklist.name) {
                this.$set(this.checklist, 'name', this.titleSuggestion);
            }
        },
        formatLocalDate(date) {
            return `${this.pad(date.getDate())}/${this.pad(date.getMonth() + 1)}/${date.getFullYear()}`;
        },
        pad(valor) {
            return valor.toString().padStart(2, '0');
        },

    },
    computed: {
        ...companiesComputed,
        ...userComputed,
        dragOptions() {
            return {
                animation: 40,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            };
        },
        ...mapState('workflows', {
            workflow: (state) => state.workflow,
        }),
        ...mapState('tasks', {
            taskDataOptions: (state) => state.taskDataOptions,
        }),
        // ...mapState('usersOptions', {
        //     collaboratorsList: (state) => state.collaboratorsList,
        //     responsiblesList: (state) => state.responsiblesList,
        // }),
        filterUsers() {
            var name = '';
            // let users = [...this.collaboratorsList, ...this.responsiblesList]
            return this.users.filter((user) => {
                name = user.full_name;
                return name.toLowerCase().indexOf(this.searchDropdown.toLowerCase()) != -1 || user.email.toLowerCase().indexOf(this.searchDropdown.toLowerCase()) != -1;
            })
        },
    },
    mounted() {
        if(this.fastMode){
            this.modalEditableMode = true;
        } else {
            this.modalEditableMode = false;
        }

        this.queryChecklist = this.$route.query.checklist
            ? this.$route.query.checklist
            : null;

        this.queryChecklistOption = this.$route.query.checklist_option
            ? this.$route.query.checklist_option
            : null;

        // this.collaboratorOptions();
        this.getUsers({ search: "", workflowId: this.workflowId });
        this.getChecklists(this.taskId);
        this.getUser();

        this.$nextTick(()=>{
            // Close datatimepicker on press enter key
            window.addEventListener('keydown', (event)=>{
                if(event.keyCode == 13 && this.selectedDatepicker != undefined && this.selectedDatepicker){
                    var base = this.$refs[`datepicker_${this.selectedDatepicker}`][0].$el.closest('.base-datepicker').querySelector('input');
                    if(base) {
                        var blur = new MouseEvent("blur", {
                            bubbles: true,
                            cancelable: false,
                            view: window,
                            button: 2,
                            buttons: 0,
                            clientX: base.getBoundingClientRect().x,
                            clientY: base.getBoundingClientRect().y
                        });
                        base.dispatchEvent(blur);
                    }
                }
            });
        });

        if(this.taskDataOptions.taskColumn) {
            if(this.taskDataOptions.taskColumn.name) {
                let today = new Date();
                let formattedDate = this.formatLocalDate(today);
                this.titleSuggestion = `${this.taskDataOptions.taskColumn.name} ${formattedDate}`;

            }
        }
    },
};
</script>
<template>
    <div class="container">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
            <div class="card">

                <div class="card-body pt-0 px-0">
                    <div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-group row mb-3" v-if="!modalEditableMode">
                                    <b-form-group
                                        for="users-select"
                                        class="col col-md"
                                        label="Novo checklist"
                                    >
                                        <form @submit.prevent="saveChecklist(taskId, checklist.name, $event)">
                                            <b-input id="itemName" type="text" class="form-control input-sm-height" name="itemName"
                                                @focus="setTitleSuggestion"
                                                placeholder="Nome"
                                                :class="{ 'is-invalid': submitted && $v.checklist.name.$error }"
                                                v-model="checklist.name"></b-input>
                                        </form>
                                        <div v-if="submitted && $v.checklist.name.$error" class="invalid-feedback">
                                            <span v-if="!$v.checklist.name.required">Insira um nome para o
                                                checklist</span>
                                            <span v-else-if="!$v.checklist.name.minLength">O nome do checklist deve
                                                conter no mínimo 2 caracteres</span>
                                        </div>
                                    </b-form-group>
                                    <b-form-group
                                        for="users-select"
                                        class="col-1 col-md-1 hide-label mxw-icons"
                                        label="_"
                                    >
                                        <b-button size="sm" variant=" text-decoration-none p-0 pt-1"
                                            @click="saveChecklist(taskId, checklist.name, $event)"
                                            :disabled="saveChecklistDisabled">
                                            <i class="fas fa-plus-square fs-5"></i>
                                        </b-button>
                                    </b-form-group>
                                    <b-form-group
                                        for="users-select"
                                        class="col-1 col-md-1 hide-label mxw-icons"
                                        label="_"
                                        v-if="!modalEditableMode && checklistOptions.length > 1"
                                    >
                                        <b-button size="sm" variant=" text-decoration-none p-0 pt-1" @click.prevent="toggleModalEditableMode()">
                                            <i class="fas fa-pencil-alt pen-color" :class="{ 'bx-tada': !modalEditableMode , 'text-info': modalEditableMode }"></i>
                                        </b-button>
                                    </b-form-group>

                                </div>


                                <div class="form-group row mb-3 pe-3" v-if="modalEditableMode">
                                    <b-form-group
                                        for="users-select"
                                        class="col col-md"
                                        label="Editar checklist"
                                    >
                                        <b-select size="sm" @change="checkOptions" v-model="selectedChecklistId"
                                            class="form-select input-sm-height  font-size-12" :options="checklistOptions"></b-select>
                                    </b-form-group>

                                    <b-form-group
                                        for="users-select"
                                        class="col-1 col-md-1 text-center p-0 hide-label mxw-icons"
                                        label="_"
                                        v-if="modalEditableMode && selectedChecklist && (user && user.id == selectedChecklist.user_id)"
                                    >
                                        <b-button variant=" text-decoration-none" @click.prevent="toggleEditMode()">
                                            <i class="fas fa-pencil-alt pen-color text-center padding-end-015"
                                                :class="{ 'bx-tada': selectedChecklist.id ? selectedChecklist.options.length < 2 && !editMode : false, 'text-info': editMode }"></i>
                                        </b-button>

                                    </b-form-group>
                                    <b-form-group
                                        for="users-select"
                                        class="col-1 col-md-1 text-center p-0 hide-label mxw-icons"
                                        label="_"
                                        v-if="modalEditableMode && (user && user.id == selectedChecklist.user_id)"
                                    >
                                        <b-button variant=" text-decoration-none pe-3"
                                            @click.prevent="deleteChecklist(selectedChecklistId)">
                                            <i class="fas fa-trash-alt fs-5 trash-color" :class="{'pe-2': !hasDeadline}"></i>
                                        </b-button>
                                    </b-form-group>

                                    <b-form-group
                                        for="users-select"
                                        class="col-1 col-md-1 text-center p-0 hide-label mxw-icons"
                                        label="_"
                                    >
                                        <b-button variant=" text-decoration-none" @click.prevent="toggleModalEditableMode()">
                                            <i class="fas fa-plus-square fs-5 add-color"></i>
                                        </b-button>
                                    </b-form-group>
                                </div>

                                <div v-if="showOptions && modalEditableMode">
                                    <draggable group="options" draggable=".item" :list="selectedChecklist.options"
                                        @start="drag = true" @end="updateSequence(selectedChecklist.options)"
                                        v-bind="dragOptions">
                                        <div class="form-group row py-2 item checklist-options border-bottom pe-1"
                                            :id="`checklist_option_${field.id ? field.id : 0 }`"
                                            v-for="(field, index) in selectedChecklist.options" :key="field.id"  v-b-tooltip.hover="field.updater?`Atualizado por: ${(field.updater? field.updater.name : null)} - ${getDateFormat(field.created_at)}`:`Criado por: ${(createdBy? createdBy : null)} - ${getDateFormat(field.created_at)}`">
                                            <b-col class="d-flex justify-content-start align-items-center">
                                                 <!-- Checkbox -->
                                                 <b-button variant="text-decoration-none border-unset"
                                                    class="border-unset"
                                                    @click="toggleChecklistOption(selectedChecklist.id, field.id, false, index)"
                                                    :disabled="!field.option"
                                                    :class="{ 'd-none': !field.option && !editMode }">
                                                    <div class="d-flex align-items-center my-auto">
                                                        <div class="rounded-circle avatar-circle circle-color-select d-flex align-items-center justify-content-center"
                                                            :class="{ 'bg-secondary': field.checked == 0, 'bg-success': field.checked == 1 }"
                                                            alt="">
                                                            <i class="fas fa-check text-secondary" v-show="field.checked"></i>
                                                        </div>
                                                    </div>
                                                </b-button>
                                                <b-checkbox :unchecked-value="false" :value="true"  :ref="`checklist_option_${field.id}`" class="d-none" v-model="field.checked"></b-checkbox>
                                                <input v-if="editMode" id="itemName" type="text" class="form-control font-size-12"
                                                    name="itemName" maxlength="255" placeholder="Digite uma opção"
                                                    v-model="field.option"
                                                    @keydown.enter="$event.target.blur()"
                                                    @blur="saveChecklistOption(selectedChecklist.id, field, false, true, index)" />
                                                <span v-else class="font-size-12 col-12 col-md-12"
                                                    :class="{ 'text-decoration-line-through': field.checked }">{{ field.option }}</span>
                                            </b-col>
                                            <!-- <div class="col-1 base-datepicker d-flex justify-content-center align-items-center">
                                            </div> -->
                                            <div :class="{'col-1 col-md-1': !hasDeadline, 'col-12 col-md-3': hasDeadline}" class="d-flex justify-content-center align-items-center base-datepicker">
                                                <!-- Datepicker -->
                                                <span
                                                  v-if="hasDeadline"
                                                  @click="toggleShowDatepicker(field.id)"
                                                  class="d-flex form-control-plaintext text-white-solid p-0 align-items-center justify-content-end pe-3">
                                                  <span v-if="field.deadline" class="font-size-12">{{  getDateFormat(field.deadline) }}</span>
                                                  <i class="bx bx-calendar fs-5 link-cursor" :class="{'padding-end-user-avatar': field.alert_user}" v-else></i>
                                                </span>
                                                <DatePicker
                                                        :class="{ 'd-flex': showDatepicker[`${field.id}`] ? showDatepicker[`${field.id}`] : false, 'd-none': showDatepicker[`${field.id}`] ? !showDatepicker[`${field.id}`] : true }"
                                                        class="cell-input cell-edit p-0 ps-1 justify-content-center"
                                                        :ref="`datepicker_${field.id}`"
                                                        v-model.lazy="field.deadline"
                                                        :lang="datePikerLang"
                                                        type="datetime"
                                                        format="DD/MM/YYYY HH:mm"
                                                        value-type="YYYY-MM-DD H:mm:ss"
                                                        :range="false"
                                                        :default-value="new Date()"
                                                        :disabled-date="disableDateFromCompanyConfig"
                                                        placeholder="00/00/0000 00:00:00"
                                                        :open="showDatepicker[`${field.id}`] ? showDatepicker[`${field.id}`] : false"
                                                        @pick="selectDatepicker(field.id)"
                                                        @blur="toggleShowDatepicker(field.id), saveChecklistOption(selectedChecklist.id, field, false, false, index)"
                                                        v-if="hasDeadline"
                                                    >
                                                    <template #footer>
                                                        <div class="row justify-content-between pt-1">
                                                            <div class="col-3 d-flex justify-content-start align-items-center">
                                                                <a href="#" class="btn btn-outline-info" @click.prevent="field.deadline = '', toggleShowDatepicker(field.id), saveChecklistOption(selectedChecklist.id, field, false, false, index)">Limpar</a>
                                                            </div>

                                                            <div class="col-3 d-flex justify-content-end align-items-center">
                                                                <a href="#" class="btn btn-success" @click.prevent="toggleShowDatepicker(field.id), saveChecklistOption(selectedChecklist.id, field, false, false, index)">Salvar</a>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </DatePicker>

                                                <!-- Alert User -->
                                                <b-dropdown
                                                  variant="btn-edit text-decoration-none fs-6 text-secondary p-0"
                                                  menu-class="checklist-drop-right" v-if="hasDeadline">
                                                  <template #button-content>
                                                    <span v-if="!field.alert_user">
                                                      <i class="bx bx-user text-white padding-x-09"></i>
                                                    </span>
                                                    <div v-else class="px-2" style="padding-left: 13px;">
                                                      <img v-if="field.alert_user.avatar" :src="field.alert_user.avatar" class="rounded-circle avatar-circle mr-1" alt />
                                                        <b-avatar variant="info" :text="`${field.alert_user.name?field.alert_user.name[0]:''}${field.alert_user.last_name?field.alert_user.last_name[0]:''}`" class="mr-3 bg-light text-white avatar-circle font-size-10" v-else></b-avatar>
                                                  </div>
                                                  </template>
                                                  <b-dropdown-form style="width: 20vw" @submit.stop.prevent
                                                    @keypress.enter.stop.prevent>
                                                    <b-form-group>
                                                      <b-form-input class="form-input" placeholder="Buscar"
                                                        v-model="searchDropdown" debounce="250"></b-form-input>
                                                    </b-form-group>
                                                  </b-dropdown-form>
                                                  <div v-for="(user, userKey) in filterUsers"
                                                    :key="userKey">
                                                    <b-dropdown-item @click="setAlertUser(field, user), saveChecklistOption(selectedChecklist.id, field, false, false, index)" v-if="[1,2].indexOf(user.user_type_id) != -1">
                                                      <img v-if="user.avatar" :src="user.avatar"
                                                        class="rounded-circle avatar-circle mr-1" alt />
                                                        <b-avatar variant="info" :text="`${user.name?user.name[0]:''}${user.last_name?user.last_name[0]:''}`" class="mr-3 bg-light text-white avatar-circle font-size-10" v-else></b-avatar>
                                                      {{
                                                        (user.full_name ? user.full_name : '')
                                                      }}
                                                    </b-dropdown-item>
                                                  </div>
                                                </b-dropdown>

                                                <div class="d-flex align-items-center justify-content-end"
                                                    v-if="index != (selectedChecklist.options.length - 1) && editMode">
                                                    <b-button variant="text-decoration-none"
                                                        @click="deleteOption(index, field.id)"><b
                                                            class="mdi mdi-close-thick text-danger fs-5"></b></b-button>
                                            </div>
                                                <div class="d-flex align-items-center justify-content-end"
                                                    v-else-if="index == (selectedChecklist.options.length - 1) && editMode">
                                                    <b-button variant="text-decoration-none"
                                                        @click="addOption"
                                                        >
                                                        <i class="bx bx-plus-medical" style="padding-right: 2.5px;"></i></b-button>
                                            </div>
                                            </div>

                                        </div>
                                    </draggable>
                                </div>
                            </div>
                            <!-- end col -->
                        </div>
                        <!-- end row -->
                    </div>
                </div>
                <b-row cols="justify-content-between" v-show="createdBy">
                    <b-col class="text-start">
                        <span>Criado por: {{ createdBy }}</span>
                    </b-col>
                    <b-col class="text-end" v-show="editMode">
                        <!-- <a href="#" class="text-decoration-none" @click.prevent="replicateChecklist(selectedChecklistId)">Replicar Checklist</a> -->
                    </b-col>
                </b-row>
                <!-- end card-body -->
            </div>
            <!-- end card -->
        </div>
    </div>
</template>
<style scoped>
.circle-color {
    height: 1rem;
    width: 1rem;
}

.circle-color-select {
    height: 1.5rem;
    width: 1.5rem;
    font-size: 20px;
}

@media (max-width: 576px) {
    .circle-color-select {

        height: 1rem;
        width: 1rem;
        font-size: 10px;
    }
}

.scr-right::-webkit-scrollbar {
    width: 6px;
}

.scr-right::-webkit-scrollbar-track {
    background: transparent;
    color: black;
    border-radius: 10px;
}

.scr-right::-webkit-scrollbar-thumb:hover {
    background: black;
    opacity: 0.6;
    border-radius: 10px;
}

.scr-right::-webkit-scrollbar-thumb {
    background: #c3cbe4;
    opacity: 0.5;
    padding-left: 5px;
    border-radius: 10px;
}

.margin-left-2px {
    margin-left: 2px;
}

.trash-color:hover {
    color: #f46a6a;
}

.pen-color:hover {
    color: #50a5f1;
}

/* drag and drop effects */
.flip-list-move {
    transition: transform 0.5s;
}

.no-move {
    transition: transform 0s;
}

.ghost {
    opacity: 0.3;
    background: #2a3144;
}
.cell-edit {
    width: 0px !important;
}

.flash {
    animation: blink 1.5s ease;
}

@keyframes blink{
   0%{
     opacity: 1;
   }
   25%{
     opacity: 0;
   }
   50%{
     opacity: 1;
   }
   75%{
     opacity: 0;
   }
   100%{
     opacity: 1;
   }
 }

</style>
<style>
.checklist-drop-right {
    right: 0px !important;
    left: unset !important;
}
.padding-x-09 {
    padding-right: .9rem !important;
    padding-left: .9rem !important;
}
.padding-end-015 {
    padding-right: 0.15rem;
}
.padding-end-user-avatar {
    padding-right: 2px;
}
.mxw-icons {
    max-width: 43px;
}
</style>
