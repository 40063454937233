<template>
    <div class="base">
      <span

        @click="toggleShow(true)"
        class="text-label form-control-plaintext cell-title cell-input text-white-solid p-0 cell-height align-items-center justify-content-start text-start d-flex overflow-text px-2 rem-size-2"
      >Digite aqui</span>

      <input
        @keyup.enter="$event.target.blur()"
        @blur="save()"
        @keyup.esc="cancel"
        type="text"
        :disabled="disabled"
        maxlength="255"
        class="text-input form-control cell-input cell-edit p-0 ps-1 cell-height text-center d-none rem-size-2"
        placeholder="Digite aqui"
      />
    </div>
  </template>

  <script>
  import IMask from "imask";
//   import config from "../../../config/config";
  import { mapActions } from "vuex";
//   import showAlert from '../../../components/alerts'

  export default {
    data() {
      return {
        backupValue: "",
      };
    },
    props: {
      column: {
        default: {},
      },
      columnValue: {
        default: {},
      },
      task: {
        default: {},
      },
      disabled: {
        default: false,
      },
    },
    methods: {
      ...mapActions('columnValues', ['updateColumnValue']),
      ...mapActions('tasks', ['updateTaskColumnValue', 'updateTask']),
      cancel(e) {
        let base = this.$el;

        if (base) {
          let span = base.querySelector(".text-label");
          let input = base.querySelector(".text-input");
          input.value = this.backupValue;
          span.textContent = this.backupValue;
          span.classList.remove("d-none");
          span.classList.add("d-flex");
          input.classList.remove("d-flex");
          input.classList.add("d-none");
        }
      },
      toggleShow(showInput = false) {
        let base = this.$el;

        if (base) {
          let input = base.querySelector(".text-input");
          let span = base.querySelector(".text-label");
          if (showInput) {
            // IMask(input, { mask: config.masks.time });
            span.classList.remove("d-flex");
            span.classList.add("d-none");
            input.classList.remove("d-none");
            input.classList.add("d-flex");
            this.backupValue = input.value;
            input.focus();
          } else {
            span.classList.remove("d-none");
            span.classList.add("d-flex");
            input.classList.remove("d-flex");
            input.classList.add("d-none");

            span.textContent = input.value ? input.value : 'Digite aqui';
          }
        }
      },
      async save() {
        let base = this.$el;
        if (base) {
          let input = base.querySelector(".text-input");
          if (input) {
              let value = input.value;

              let data = {
                  group_id: this.task.workflow_group_id,
                  task_id: this.task.id,
                  column_type_id: this.column.workflow_column_type_id,
                  column_value_id: this.columnValue.id,
                  value: value,
                  description: ''
              }

              if(!await this.updateColumnValue(data)) {
                this.cancel();
                return;
              }

              if(this.column.is_zipcode) {
                this.updateTaskColumnValue(data);
                setTimeout(()=> { this.updateTask({task_id: this.task.id}) }, 500);
                this.toggleShow();
                return;
              }

              this.updateTaskColumnValue(data);

          }
        }
        this.toggleShow();
      },
    },
    mounted() {

      let input = this.$el.querySelector(".text-input");
      let span = this.$el.querySelector(".text-label");
      input.value = this.columnValue.value;
      this.backupValue = this.columnValue.value;

      if(this.column.mask && input) {
          IMask(input, this.column.mask.value);
      }
      if (this.columnValue.value) {
          span.textContent = this.columnValue.value;
      }
      if (this.columnValue.value) {
          setTimeout(()=>{ input.value = this.columnValue.value},100);
      }

    },
    beforeDestroy () {
      this.backupValue = "";
      this.value = "";
      if(this.$el.parentNode && this.$el) {
        this.$el.parentNode.removeChild(this.$el);
      }
    }
  };
  </script>

  <style lang="scss" scoped>
  </style>
