<template>
    <div class="d-flex align-items-center justify-content-center mb-2">
        <div class="ratio ratio-1x1 image-ligthbox">
            <video controls name="media" :src="url"></video>
        </div>
    </div>
</template>

<script>
    export default {
        props:['url']
    }
</script>

