<script>
import showAlert from "../../../components/alerts";
import Multiselect from "vue-multiselect";
import config from "../../../config/config";
import axios from "axios";
import {Money} from  "../../../asset/v-money";
import simplebar from "simplebar-vue";
import 'simplebar/dist/simplebar.min.css';
import { workflowsComputed, workflowsMethods, usersOptionsComputed, usersOptionsMethods, pluginsComputed, pluginsMethods, columnsComputed } from '../../../state/helpers'
import IMask from "imask";
import cep from 'cep-promise'

// App Plugins
import plugins from '@plugins/';

export default {
  components: { Multiselect, Money, simplebar, FoldersList: plugins.foldersList },
  props: ['propWorkflowFormId', 'forceGroupId', 'internal'],
  data() {
    return {
      workflowFormId: null,
      workflowForm: null,
      formLogo: "/images/logo.svg",
      datetimePikerShow: {},
      personsLoad: false,
      workflowDataLoad: 0,
      responsibleSelected: {},
      masks: null,
      columnsMasks: {},
      title: {
        label: 'Título',
        value: '',
        disabled: false
      },
      form: {
        name: null,
        email: null,
        phone: null,
        description: '',
        title: null,
        debug: null,
        files: [],
        select: [],
        columns: {},
        money: {},
        persons: {},
        child_workflow_data: {},
        provisionalExecution: null
      },
      money: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: '',
          precision: 2,
          masked: false
      },
      number:{
        decimal: '',
        thousands: '',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
      },
      zipcodeColumns: {},
      columnsLoad: false
    };
  },
  computed: {
    ...usersOptionsComputed,
    ...workflowsComputed,
    ...pluginsComputed,
    ...columnsComputed,
    workflowResponsibleUsersList() {
        return this.usersList.filter((user)=>{
            return user.user_type_id < 3 || user.user_type_id == 4;
        })
    },
    labelDescription() {
        let data = 'Descrição'
        // if(this.checkPlugin(1)) {
        //     data = 'Motivo da recisão'
        // }
        return data
    },
    labelPlaceholder() {
        let data = 'Insira uma descrição'
        // if(this.checkPlugin(1)) {
        //     data = 'Insira o motivo da recisão'
        // }
        return data
    },
    showDescription() {
        return this.workflowForm ? this.workflowForm.with_description : false
    }
  },
  methods: {
    ...usersOptionsMethods,
    ...workflowsMethods,
    ...pluginsMethods,
    limitText(count) {
      return `E ${count} outros`;
    },
    hideFormInPaternComponent (id) {
      this.$emit('hide-form', { showForm: false, taskId: id})
    },
    getForm: function (workflowFormId) {
      this.columnsLoad = true;
      const getGroupSelect = axios
        .get(
          window.location.origin + "/api/v1/workflow/form/show/" + workflowFormId,
          config.getHeaders
        )
        .then((response) => {
          if (!response.data.error) {
            // SET DATA
            let data = response.data.data;
            this.workflowForm = data;

            if(this.workflowForm.title) {
                let title = this.workflowForm.column_title;
                this.title.label = title.name;
                if(title.use_default_value) {
                    this.form.name = title.default_value;
                    this.title.disabled = true;
                }
            }

            // SET MASKS
            setTimeout(()=>{
                try {
                    this.workflowForm.columns.forEach((column)=>{
                        let input = this.$el.querySelector(`#item_${column.id}`);
                        if(column.mask && input) {
                            IMask(input, column.mask.value);
                        }
                    });
                } catch(error) {
                    console.log('insert masks error :>> ', error);
                }
            }, 100);

            // SET ZIPCODE DATA
            let zipcodeColumns = this.workflowForm.columns.filter((column)=> column.is_zipcode);
            let zipcodeColumnData = {};
            zipcodeColumns.forEach((zipcodeColumn)=>{
                zipcodeColumnData[zipcodeColumn.workflow_column_id] = {};
                let configuration = zipcodeColumn.additional_configuration_data;
                configuration.forEach((configurationColumn)=>{
                    zipcodeColumnData[zipcodeColumn.workflow_column_id][configurationColumn.tag] = configurationColumn;
                });
            });

            this.zipcodeColumns = zipcodeColumnData;

            // GET USERS
            this.getUsersList(this.workflowForm.workflow_id);
          }
          this.columnsLoad = false;
        })
        .catch((error) => {
          this.columnsLoad = false;
          if (error.response) {
            showAlert(false, error.response.data.message);
          }
        });
    },
    updateInput(evt, base, key) {
        this.$set(base, key, evt.target.value);
    },
    getFormLogo: function (workflowFormId) {
      const getFormLogo = axios
        .get(
          window.location.origin + "/api/v1/workflow/form/logo/" + workflowFormId,
          config.getHeaders
        )
        .then((response) => {
          if (!response.data.error) {
            this.formLogo = response.data.data;

            if (response.data.data) this.$refs["logo"].src = response.data.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            showAlert(false, error.response.data.message);
          }
        });
    },
    setWorkflowTaskLable: function (data) {
      return `${data.name}`;
    },
    setSelectLable: function (select) {
      return `${select.option}`;
    },
    setTask: function (e) {
      e.preventDefault();

      if(!this.form.name){
        showAlert(true, 'Insira um titulo');
        return;
      }

      if(this.checkPlugin(1) && this.pluginForm.provisionalExecution && !this.form.provisionalExecution){
        showAlert(true, 'Selecione uma pasta');
        this.changePluginInputError({provisionalExecution: true});
        return;
      }

    //   if(!this.form.description){
    //     showAlert(true, this.labelPlaceholder);
    //     return;
    //   }

      var workflowFormId =
        typeof this.workflowFormId == "undefined" ? "" : this.workflowFormId;
      var data = {
        workflow_id: this.workflowForm.workflow_id,
        workflow_group_id: this.forceGroupId ? this.forceGroupId : this.workflowForm.workflow_group_id,
        workflow_form_id: workflowFormId,
        title: this.form.title,
        task_name: this.form.name,
        phone: this.form.phone,
        email: this.form.email,
        description: this.form.description,
      };

      // parse to multipart formdata
      var objectKeys = Object.keys(data);
      let formData = new FormData();

      objectKeys.forEach((objectKey) =>{
          formData.append(objectKey, data[`${objectKey}`]);
      });
      this.form.files.forEach((el, key)=>{
        formData.append(`files[${key}]`, el);
      });
      var columns = new Object();
      this.form.select.forEach((item, key) => {
        if (item != null) {
            formData.append(`columns[${item.workflow_form_id}]`, item.id);
        }
      });

      let responsibleSelected = Object.keys(this.responsibleSelected);
      objectKeys = Object.keys(this.form.columns);
      objectKeys.forEach((objectKey) => {
        if (this.form.columns[`${objectKey}`] != null) {
          formData.append(`columns[${objectKey}]`, this.form.columns[`${objectKey}`] );
          if(typeof  this.form.columns[`${objectKey}`] === 'object') {
            if(responsibleSelected.indexOf(objectKey) != -1) {
                formData.append(`columns[${objectKey}]`, this.form.columns[`${objectKey}`]['id'] );
            }
          }
        }
      });

      objectKeys = Object.keys(this.form.persons);
      objectKeys.forEach((objectKey) => {
        if (this.form.persons[`${objectKey}`] != null) {
            this.form.persons[`${objectKey}`].forEach((person, personKey)=>{
                let personAttributeKeys =  Object.keys(person);
                personAttributeKeys.forEach((personAttributeKey) => {
                    formData.append(`columns[${objectKey}][${personKey}][${personAttributeKey}]`, person[personAttributeKey] );
                });
            });
        }
      });

      objectKeys = Object.keys(this.form.child_workflow_data);
      objectKeys.forEach((objectKey) => {
        if (this.form.child_workflow_data[`${objectKey}`] != null) {
            this.form.child_workflow_data[`${objectKey}`].forEach((data, dataKey)=>{
                let dataAttributeKeys =  Object.keys(data);
                dataAttributeKeys.forEach((dataAttributeKey) => {
                    formData.append(`columns[${objectKey}][${dataKey}][${dataAttributeKey}]`, data[dataAttributeKey] );
                });
            });
        }
      });

      objectKeys = Object.keys(this.form.money);
      objectKeys.forEach((objectKey) => {
        if (this.form.money[`${objectKey}`] != null) {
          formData.append(`columns[${objectKey}]`, this.form.money[`${objectKey}`] );
        }
      });

      if(this.form.provisionalExecution && this.checkPlugin(1)) {
        objectKeys = Object.keys(this.form.provisionalExecution);
        objectKeys.forEach((objectKey) => {
            formData.append(`provisional_execution[${objectKey}]`, this.form.provisionalExecution[`${objectKey}`] );
        });
      }

      // Display the key/value pairs
      // for (var pair of formData.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1]);
      // }

      const setTask = axios
        .post(
          window.location.origin + "/api/v1/workflow/task/form",
          formData,
          config.getHeaders
        )
        .then((response) => {
          if (!response.data.error) {
            showAlert(false, response.data.message);
            this.$root.$emit('bv::hide::modal', 'modal-form-show');
            this.hideFormInPaternComponent(response.data.data.id);

            if(this.checkPlugin(1)) {

                setTimeout(()=> {
                    let task = response.data.data;
                    let columnValue = task.column_values.find(
                        (columnValue) =>
                        columnValue.workflow_column_type_id == 13 &&
                        columnValue.plugin_id == 1 &&
                        columnValue.tag == "aasp_updates_folder"
                    );

                    let folder = 0
                    if (columnValue) {
                        folder = parseInt(columnValue.number);
                    }

                    this.$swal.fire({
                        title: `Pasta ${folder} criada com sucesso`,
                        icon: "success",
                        allowOutsideClick: false,
                        background: '#2a3042',
                        confirmButtonColor: "#556ee6",
                        customClass: {
                            title: 'text-white',
                        }
                    });
                }, 100);

                this.changePluginForm({provisionalExecution: false})

            }
          }
        })
        .catch((error) => {
          if (error.response) {
            showAlert(true, error.response.data.message);
          }
        });
    },
    inputResponsible(data, itemId) {
        if(data) {
            this.$set(this.responsibleSelected, itemId, data.id);
            return;
        }
        this.$set(this.responsibleSelected, itemId, null);
    },
    setFiles(e, id){
        this.form.files.push(e.target.files[0]);
    },
    clearFile(id){
        this.$refs[`input-files-${id}`][0].value = "";
        this.form.files = []
    },
    openUploadFile(id){
        this.$refs[`input-files-${id}`][0].click();
    },
    setAcceptangeStatus(columnId){
      this.$refs[`acceptance_${columnId}`][0].$el.querySelector('input').click();
    },
    numberDecrement(value){
        var base = value? value : 0;
        return parseInt(base) - 1;

    },
    numberIncrement(value){
        var base = value? value : 0;
        return parseInt(base) + 1
    },

    searchPersons: async function searchPersons(data, type, item) {
        clearTimeout(searchPersons.timeout);
        searchPersons.timeout = setTimeout(async ()=>{
            this.personsLoad = true;
            axios.post(`${window.location.origin}/api/v1/person/search`, {search: data, type })
            .then(persons => {
                if(!persons.data.error) {
                    this.$set(item, 'persons', persons.data.data);
                }
                this.personsLoad = false;

            })
            .catch(error => {
                this.personsLoad = false;

            })
        }, 600);

    },
    searchColumnWorkflow: async function searchColumnWorkflow(search, item) {
        clearTimeout(searchColumnWorkflow.timeout);
        searchColumnWorkflow.timeout = setTimeout(async ()=>{

            this.workflowDataLoad = item.id;

            let content = {
                workflow_id: item.child_workflow_id,
                workflow_column_id: item.workflow_column_id,
                search
            };

            axios.post(`${window.location.origin}/api/v1/workflow/task/search`, content)
            .then((response)=>{
                if(!response.data.error) {
                    this.$set(item, 'child_workflow_data', response.data.data);
                    this.workflowDataLoad = 0;
                    return;
                }

                this.$set(item, 'child_workflow_data', []);
                this.workflowDataLoad = 0;
            })
            .catch((error)=>{
                this.workflowDataLoad = 0;
                console.log('searchColumnWorkflow error :>>',  error);
            });


        }, 800);

    },
    getWorkflowTaskInitials(text = '') {
        let name = '';
        if(text.length > 0) {
            name = text[0];
        }

        let textSplited = text.split(' ');

        if(textSplited.length > 1) {
            name += textSplited[textSplited.length - 1][0];
        }

        return name;
    },
    searchCollaborators() {

    },
    searchResponsibles() {

    },
    getMasks() {
        axios.get(`${window.location.origin}/api/v1/workflow/column/masks`)
        .then((response)=> {
            if(!response.data.error) {
                this.masks = {};
                response.data.data.forEach(mask => {
                    this.$set(this.masks, mask.id, mask)
                });
            }
        })
        .catch((error) =>{
            console.log('getMasks error :>> ', error);
        });
    },
    async getZipcode(evt, item) {
        let value = evt.target.value;
        if(typeof this.zipcodeColumns[item.workflow_column_id] == 'undefined' || !value) {
            return
        }

        let zipcodeColumn = this.zipcodeColumns[item.workflow_column_id];
        let zipcode = value.replace(/[^0-9]/g,'');

        if(zipcode.length == 8) {
            this.getForZipcode(zipcode, zipcodeColumn);
        }
    },

    getForZipcode: async function getForZipcode(zipcode, zipcodeColumn, debounce = 600){
        clearTimeout(getForZipcode.timeout)
        getForZipcode.timeout = setTimeout(()=>{
            cep(zipcode).then((address)=>{
                let addressKeys = Object.keys(address);
                let bindings = {
                    street: 'zipcode_street',
                    logradouro: 'zipcode_street',
                    neighborhood: 'zipcode_neighborhood',
                    bairro: 'zipcode_neighborhood',
                    city: 'zipcode_city',
                    localidade: 'zipcode_city',
                    state: 'zipcode_state',
                    uf: 'zipcode_state',
                };

                addressKeys.forEach((key)=>{
                    if(typeof bindings[key] != 'undefined') {

                        if(typeof zipcodeColumn[bindings[key]] != 'undefined') {

                            let zipcodeLocalColumn = zipcodeColumn[bindings[key]];
                            let column = this.workflowForm.columns.find(
                                (column)=> column.workflow_column_id == zipcodeLocalColumn.column_id
                            );

                            if(column) {
                                let columnElement = this.$el.querySelector(`#item_${column.id}`);
                                if(columnElement) {
                                    columnElement.value = address[key];
                                    this.$set(this.form.columns, column.id, address[key]);
                                }

                            }
                        }
                    }

                });

            }).catch((error)=>{
                console.log(error);
            });
        }, debounce);

    },
  },
  created() {
    this.getMasks();
  },
  mounted() {
    this.workflowFormId = this.propWorkflowFormId
      ? this.propWorkflowFormId
      : this.$route.params.uuid;
    this.getForm(this.workflowFormId);
    this.getFormLogo(this.workflowFormId);
  },
  beforeDestroy() {
    this.changePluginForm({provisionalExecution: false})
  }
};
</script>
<template>
  <div class="container-fluid">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12">
      <div class="card mb-0 card-main">
          <div class="row sticky-top m-0">
              <slot name="back-button"></slot>
          </div>
        <div class="bg-gradient profile-margin-top" v-if="!internal">
            <div class="col-12 d-flex text-end align-items-end justify-content-end">
                <div class="text-white pe-4">
                <h5 class="text-white font-size-12">{{ workflowForm? workflowForm.title : 'Novo' }}</h5>
                <p class="font-size-10 mb-1">{{ internal ? 'Preencha o formulário' : 'Preencha o formulário para ser atendido' }} </p>
              </div>
              <input
                  type="file"
                  class="d-none"
                  ref="logo-file"
                  name="logo-file"
                  id="logo-file"
                  accept="image/*"
                />
                <a
                  href="javascript: void(0);"
                  class="d-inline-block text-decoration-none fs-5 p-1"
                >
                <span class="avatar-title rounded-circle bg-light">
                  <img
                    class="rounded-circle image-fluid avatar-sm"
                    alt="company-logo"
                    src="/images/icon_dalth.png"
                    ref="logo"
                    data-holder-rendered="true"
                    height="115"
                    width="115"
                  />
                </span>

                </a>
          </div>
        </div>

        <div class="card-body main-card-body  scr-right" :class="{'pt-4': !internal, 'pt-1': internal}">

          <b-form @submit="setTask">
            <b-row>
              <div class="col-12">
                <b-row >
                  <div class="card m-0">
                    <div class="card-body p-0">
                      <div class="row">
                        <div class="col-12 col-md-12 col-xl-12 col-sm-12 mb-2">
                          <div class="row">
                            <div class="col-12">
                              <b-form-group
                                :label="title.label"
                                label-for="name-form"
                                class=""
                              >
                                <b-form-input
                                  type="text"
                                  class="form-control bg-input font-size-12"
                                  id="name-form"
                                  name="name-form"
                                  :placeholder="`Digite um(a) ${title.label}`"
                                  v-model="form.name"
                                  :disabled="title.disabled"
                                  size="sm"
                                ></b-form-input>
                              </b-form-group>
                            </div>
                            <div class="col" v-if="checkPlugin(1) && hasFolder(columns)">
                                <b-form-checkbox @change="changePluginForm({provisionalExecution: $event})" id="provisional-execution" class="input-sm-height d-flex align-items-center m-0 provisional-execution  mt-2 mb-n2">Execução provisória</b-form-checkbox>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-12 col-xl-12 col-sm-12 mb-2" v-if="pluginForm.provisionalExecution">
                            <FoldersList :data.sync="form.provisionalExecution" :workflow-id="workflowForm.workflow_id" :with-label="false" />
                        </div>
                        <div class="col-12 col-md-12 col-xl-12 col-sm-12" v-if="showDescription">
                          <div class="row">
                            <div class="col-12">
                              <b-form-group
                                :label="labelDescription"
                                label-for="description-form"
                                class=""
                              >
                              <b-textarea
                                  type="text"
                                  class="form-control bg-input"
                                  id="description-form"
                                  name="description-form"
                                  :placeholder="labelPlaceholder"
                                  v-model="form.description"
                                  size="sm"
                              ></b-textarea>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </b-row>
                <b-row>
                  <div class="col-12 p-0">
                    <div class="card mb-0">
                      <div class="card-body pb-0">
                        <div v-if="columnsLoad" class="row justify-content-center align-items-center" style="height: calc(100vh - 500px);"> <div class="col text-center"><i class="bx bx-loader-alt bx-spin fs-1"></i></div> </div>
                          <div v-if="workflowForm != null">
                            <div v-if="workflowForm.columns.length > 1">
                                <hr class="py-0 my-0 mb-2" />
                            </div>
                            <div class="row">
                                    <div
                                      v-for="(item, itemKey) in workflowForm.columns"
                                      :key="itemKey"
                                      class="col-12 col-md-6 col-xl-6 col-sm-12 pb-1"
                                      :class="{'d-none': item? ((item.type.id == 2 || item.type.id == 3 || item.type.id == 10 ) && !internal) || item.type.id == 9 || item.type.id == 11 ||  item.type.id == 10 || item.hidden : false}">
                                    <div v-if="item">
                                      <div v-if="((!item.hidden && item.type.id != 2 && item.type.id != 3 && item.type.id != 10 && item.type.id != 11  && !internal) || (internal && (item.type.id != 9 && item.type.id != 10 && item.type.id != 11)))">
                                        <div class="row" v-if="item.type.id == 8 || item.type.id == 3">
                                          <div class="col-12 px-1">
                                            <b-form-group
                                              :label="item.name"
                                              :label-for="`item_${item.id}`"
                                              class=""
                                            >
                                              <multiselect
                                                v-model="form.select[item.workflow_column_id]"
                                                :options="item.options"
                                                :custom-label="setSelectLable"
                                                placeholder="Selecione uma opção"
                                                class="multiselect-sm"
                                              ></multiselect>
                                            </b-form-group>
                                          </div>
                                          <div class="col-2"></div>
                                        </div>
                                        <div class="row" v-else-if="item.type.id == 14">
                                          <div class="col-12 px-1">
                                            <b-form-group
                                              :label="item.name"
                                              :label-for="`item_${item.id}`"
                                              class=""
                                            >
                                              <multiselect
                                                v-model="form.persons[item.id]"
                                                :options="item.persons"
                                                label="full_name"
                                                track-by="id"
                                                deselect-label="Remover"
                                                select-label="Selecionar"
                                                :reset-after="false"
                                                :multiple="true"
                                                :searchable="true"
                                                :internal-search="false"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :options-limit="30"
                                                :limit="9000"
                                                :limit-text="limitText"
                                                :show-no-results="true"
                                                :hide-selected="false"
                                                :preserve-search="false"
                                                placeholder="Selecione uma opção"
                                                :loading="personsLoad"
                                                @search-change="searchPersons($event, item.person_type, item)"
                                                class="multiselect-sm"
                                              >

                                              <template slot="option" slot-scope="props">
                                                    <div class="avatar-group-item" v-b-tooltip="props.option.full_name">
                                                    <img
                                                        v-if="props.option.avatar"
                                                        :src="props.option.avatar"
                                                        class="rounded-circle avatar-circle"
                                                        alt
                                                    />
                                                    <b-avatar
                                                        size="1.5rem"
                                                        variant="info avatar-circle"
                                                        :text="`${
                                                        props.option.name ? props.option.name[0] : ''
                                                        }${
                                                        props.option.last_name
                                                            ? props.option.last_name[0]
                                                            : ''
                                                        }`"
                                                        class="mr-3 bg-light text-white avatar-circle font-size-10"
                                                        v-else
                                                    ></b-avatar>
                                                    <span class="ps-2">{{ props.option.full_name }}</span>
                                                    </div>
                                                </template>
                                                <span slot="noResult">Não encontrado.</span>
                                                <span slot="noOptions">Ninguem por aqui.</span>

                                              </multiselect>
                                            </b-form-group>
                                          </div>
                                          <div class="col-2"></div>
                                        </div>
                                        <div class="row" v-else-if="item.type.id == 16">
                                          <div class="col-12 px-1">
                                            <b-form-group
                                              :label="item.name"
                                              :label-for="`item_${item.id}`"
                                              class=""
                                            >
                                              <multiselect
                                                v-model="form.child_workflow_data[item.id]"
                                                :options="item.child_workflow_data"
                                                :custom-label="setWorkflowTaskLable"
                                                track-by="id"
                                                deselect-label="Remover"
                                                select-label="Selecionar"
                                                :reset-after="false"
                                                :multiple="true"
                                                :searchable="true"
                                                :internal-search="false"
                                                :close-on-select="false"
                                                :clear-on-select="false"
                                                :options-limit="30"
                                                :limit="9000"
                                                :limit-text="limitText"
                                                :show-no-results="true"
                                                :hide-selected="false"
                                                :preserve-search="false"
                                                placeholder="Selecione uma opção"
                                                :loading="workflowDataLoad == item.id"
                                                @search-change="searchColumnWorkflow($event, item)"
                                                class="multiselect-sm"
                                              >

                                              <template slot="option" slot-scope="props">
                                                    <div class="avatar-group-item" v-b-tooltip="props.option.name">

                                                    <b-avatar
                                                        size="1.5rem"
                                                        variant="info avatar-circle"
                                                        :text="getWorkflowTaskInitials(props.option.name)"
                                                        class="mr-3 bg-light text-white avatar-circle font-size-10"
                                                    ></b-avatar>
                                                    <span class="ps-2">{{ props.option.name }}</span>
                                                    </div>
                                                </template>
                                                <span slot="noResult">Não encontrado.</span>
                                                <span slot="noOptions">Ninguem por aqui.</span>

                                              </multiselect>
                                            </b-form-group>
                                          </div>
                                          <div class="col-2"></div>
                                        </div>
                                        <div class="row" v-else-if="item.type.id == 2">
                                          <div class="col-12 px-1">
                                            <b-form-group
                                              :label="item.name"
                                              :label-for="`item_${item.id}`"
                                              class=""
                                            >
                                              <multiselect
                                                v-model="form.columns[item.id]"
                                                @input="inputResponsible($event, item.id)"
                                                :options="workflowResponsibleUsersList"
                                                label="full_name"
                                                track-by="id"
                                                deselect-label="Remover"
                                                select-label="Selecionar"
                                                :reset-after="false"
                                                :multiple="false"
                                                :searchable="true"
                                                :internal-search="true"
                                                :close-on-select="true"
                                                :clear-on-select="false"
                                                :options-limit="30"
                                                :limit="9000"
                                                :limit-text="limitText"
                                                :show-no-results="true"
                                                :hide-selected="false"
                                                :preserve-search="false"
                                                placeholder="Selecione uma opção"
                                                class="multiselect-sm"
                                              >

                                              <template slot="option" slot-scope="props">
                                                    <div class="avatar-group-item" v-b-tooltip="props.option.full_name">
                                                    <img
                                                        v-if="props.option.avatar"
                                                        :src="props.option.avatar"
                                                        class="rounded-circle avatar-circle"
                                                        alt
                                                    />
                                                    <b-avatar
                                                        size="1.5rem"
                                                        variant="info"
                                                        :text="`${
                                                        props.option.name ? props.option.name[0] : ''
                                                        }${
                                                        props.option.last_name
                                                            ? props.option.last_name[0]
                                                            : ''
                                                        }`"
                                                        class="mr-3 bg-light text-white avatar-circle font-size-10"
                                                        v-else
                                                    ></b-avatar>
                                                    <span class="ps-2">{{ props.option.full_name }}</span>
                                                    </div>
                                                </template>
                                                <span slot="noResult">Não encontrado.</span>
                                                <span slot="noOptions">Ninguem por aqui.</span>

                                              </multiselect>
                                            </b-form-group>
                                          </div>
                                          <div class="col-2"></div>
                                        </div>
                                        <div v-else-if="item.type.id == 4" class="">
                                          <div class="col-12 px-1">
                                              <b-form-group
                                                :label="item.name"
                                                :label-for="`item_${item.id}`"
                                                class="">

                                                <div class="d-flex align-items-center  justify-content-center fill-element link-cursor rounded input-sm-height" :class="{'bg-success': form.columns[item.id] == 1, 'bg-danger': form.columns[item.id] == 0 || form.columns[item.id] == null}" @click="setAcceptangeStatus(item.id)">
                                                    <span v-show="(form.columns[item.id] == 1)" class="text-white btn">Sim</span>
                                                    <span v-show="(form.columns[item.id] == 0 || form.columns[item.id] == null)" class="text-white btn">Não</span>
                                                </div>
                                                <!-- <input :ref="`acceptance_${item.id}`" class="d-none" type="checkbox" v-model="form.columns[item.id]"> -->
                                                <b-checkbox unchecked-value="0" value="1"  :ref="`acceptance_${item.id}`" class="d-none" type="checkbox" v-model="form.columns[item.id]"></b-checkbox>
                                              </b-form-group>
                                          </div>
                                        </div>
                                         <div class="row" v-else-if="(item.type.id == 12)">
                                          <div class="col-12 px-1">
                                            <b-form-group
                                              :label="item.name"
                                              :label-for="`item_${item.id}`"
                                              class=""
                                            >
                                            <Money
                                              v-bind="money"
                                              v-model="form.money[item.id]"
                                              :id="`item_${item.id}`"
                                              :name="`${item.id}`"
                                              class="form-control bg-input text-white-solid text-end input-sm-height" />
                                            </b-form-group>
                                          </div>
                                          <div class="col-2"></div>
                                        </div>

                                        <div class="row" v-else-if="item.type.id == 6">
                                          <div class="col-12 px-1">
                                            <b-form-group
                                              :label="item.name"
                                              :label-for="`item_${item.id}`"
                                              class=""
                                            >
                                            <b-button size="sm" variant="light font-size-12" class="w-50" @click="openUploadFile(item.id)">
                                                <i class="mdi mdi-upload d-block font-size-16"></i> <span>{{ form.files.length }} Selecionado{{form.files.length>1 || form.files.length == 0? 's': ''}}</span>
                                            </b-button>
                                            <b-button size="sm" @click="clearFile(item.id)" variant="text-decoration-none font-size-12" :class="{'text-danger': form.files.length > 0}">Limpar</b-button>
                                            <input
                                             type="file"
                                             class="d-none"
                                             :ref="`input-files-${item.id}`"
                                             name="files[]"
                                             id="files"
                                             @change="setFiles($event, item.id)"
                                             @load="setFiles($event, item.id)"
                                            />
                                            </b-form-group>
                                          </div>
                                          <div class="col-2"></div>
                                        </div>
                                        <div class="row"  v-else-if="(item.type.id == 13)">
                                          <div class="col-12 px-1">
                                            <b-form-group
                                              :label="item.name"
                                              :label-for="`item_${item.id}`"
                                              class=""
                                            >
                                            <b-button-group class="col-12">
                                                <b-button size="sm" @click="(form.columns[item.id] = numberDecrement(form.columns[item.id]))" variant="danger input-sm-height">-</b-button>
                                                <Money
                                                  v-bind="number"
                                                  v-model="form.columns[item.id]"
                                                  :id="`item_${item.id}`"
                                                  :name="`${item.id}`"
                                                  :placeholder="form.columns[item.id]? form.columns[item.id] : 0"
                                                  class="form-control bg-input text-center font-size-12 input-sm-height"  />
                                                <b-button size="sm"  @click="(form.columns[item.id] = numberIncrement(form.columns[item.id]))" variant="success input-sm-height">+</b-button>
                                            </b-button-group>

                                            </b-form-group>
                                          </div>
                                          <div class="col-2"></div>
                                        </div>
                                        <div class="row"  v-else-if="item.type.id == 5">
                                          <div class="col-12 px-1 form-datatimepicker-base">
                                              <label :for="`item_${item.id}`">{{  item.name }}</label>
                                              <DatePicker
                                                  :id="`item_${item.id}`"
                                                  :name="`${item.id}`"
                                                  v-model.lazy="form.columns[`${item.id}`]"
                                                  :lang="datePikerLang"
                                                  type="datetime"
                                                  format="DD/MM/YYYY HH:mm"
                                                  value-type="YYYY-MM-DD H:mm:ss"
                                                  :range="false"
                                                  placeholder="00/00/0000 00:00:00"
                                                  :open="datetimePikerShow[`${item.id}`] != 'undefined'? datetimePikerShow[`${item.id}`] : false"
                                                  @click="$set(datetimePikerShow, `${item.id}`, true)"
                                                  @focus="$set(datetimePikerShow, `${item.id}`, true)"
                                                  class="datepicker-sm"
                                              >
                                              <template #footer>
                                                  <div class="row justify-content-between">
                                                      <div class="col-6 d-flex justify-content-start align-items-center">
                                                          <a href="#" @click="form.columns[`${item.id}`] = '', $set(datetimePikerShow, `${item.id}`, false)" >Limpar</a>
                                                      </div>
                                                      <div class="col-6 d-flex justify-content-end align-items-center">
                                                          <a href="#" class="btn btn-outline-success" @click="$set(datetimePikerShow, `${item.id}`, false)" >Ok</a>
                                                      </div>
                                                  </div>

                                              </template>
                                              </DatePicker>
                                          </div>
                                          <div class="col-2"></div>
                                        </div>
                                        <div class="row" v-else-if="item.type.id == 1 || item.type.id == 15">
                                          <div class="col-12 px-1">
                                            <b-form-group
                                              :label="item.name"
                                              :label-for="`item_${item.id}`"
                                              class=""
                                            >
                                              <input
                                                :type="item.type.html_type"
                                                class="bg-input font-size-12 input-sm-height form-control"
                                                :id="`item_${item.id}`"
                                                :name="`${item.id}`"
                                                :placeholder="item.name"
                                                @keyup="updateInput($event, form.columns, item.id)"
                                                @blur="getZipcode($event, item)"
                                              />
                                            </b-form-group>
                                          </div>
                                          <div class="col-2"></div>
                                        </div>
                                        <div class="row" v-else-if="item.type.id != 8 && item.type.id != 3">
                                          <div class="col-12 px-1">
                                            <b-form-group
                                              :label="item.name"
                                              :label-for="`item_${item.id}`"
                                              class=""
                                            >
                                            <b-form-input
                                                v-model="form.columns[item.id]"
                                                :type="item.type.html_type"
                                                class="form-control bg-input font-size-12"
                                                :id="`item_${item.id}`"
                                                :name="`${item.id}`"
                                                :placeholder="item.name"
                                                size="sm"
                                              ></b-form-input>
                                            </b-form-group>
                                          </div>
                                          <div class="col-2"></div>
                                        </div>
                                      </div>
                                    </div>
                                    </div>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-row>
                <b-row class="p-2 btn-send">
                  <b-col align-self="end" cols="auto" class="px-1">
                      <div v-if="!propWorkflowFormId || (propWorkflowFormId && internal)">
                          <b-button
                            type="submit"
                            variant="btn btn-primary  outline-light fomr-send-button-size "
                            size="sm"
                            >Enviar
                          </b-button>
                      </div>
                      <div v-else>
                          <slot />
                      </div>
                  </b-col>
                </b-row>
              </div>
            </b-row>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
/* hide type number spins */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.link-cursor {
    cursor: pointer;
}
/* datetimepicker */
.form-datatimepicker-base .mx-datepicker .mx-input-wrapper input{
    height: 36.13px;
}
.profile-margin-top {
    margin-top: -18.92px;
}
.bg-gradient {
    /* background-image: linear-gradient(to left top, #556ee6, #647ae9, #7286ed, #8093ef, #8e9ff2); */
    /* background: rgba(86, 110, 230, 1.0) !important;
    background: radial-gradient(at center top, rgba(86, 110, 230, 1.0), rgba(48, 60, 114, 1.0)) !important; */
    background: rgba(85, 110, 230);
}
.fomr-send-button-size {
    width: 135px;
    height: 35px;
}
</style>
