<template>
    <div class="base ps-1 pe-1">
      <div
        class="row justify-content-start align-items-center"
        v-if="task.responsibles.length > 0 && ready"
      >
        <div class="responsible-container">
          <div class="avatar-group cell-height">
            <!-- persons avatars -->
            <div
              :id="`user-${user.id}`"
              class="d-flex justify-content-start align-items-center col-12"
              v-for="(user, keyUser) in task.responsibles"
              :key="keyUser"
            >
              <div class="avatar-group-item" v-if="keyUser < 5">
                <a href="javascript: void(0);" data-bs-toggle="tooltip" data-bs-html="true" :title="`${user.name}`">
                  <b-img-lazy class="rounded-circle avatar-circle delete-on-hover" :src="user.avatar" v-if="user.avatar"/>
                  <b-avatar
                    variant="info"
                    :text="getInitials(user)"
                    class="mr-3 bg-light text-white avatar-circle rounded-circle delete-on-hover font-size-10"
                    v-else
                  />
                </a>
            </div>
            <div class="d-flex ps-1 text-white-solid overflow-text">
                <span class="text-white-solid overflow-text rem-size-2"> {{ `${user.name} ${user.last_name}` }}</span>
            </div>

            </div>
          </div>
        </div>
        <b-col
          cols="2"
          class="justify-content-center align-items-center cell-height add-person-area"
        >
          <ResponsibleDropdown
            v-if="dropdownReady"
            :columnValue="columnValue"
            :selecteds="task.responsibles"
            :disabled="disabled"
            :dropright="dropright"
            @save="save"
            @remove="remove"
          />
        </b-col>
      </div>

      <span
        v-else
        class="person-label form-control-plaintext cell-title cell-input text-white-solid p-0 cell-height align-items-center justify-content-center d-flex"
      >
        <ResponsibleDropdown
          v-if="dropdownReady"
          :columnValue="columnValue"
          :selecteds="task.responsibles"
          :disabled="disabled"
          :dropright="dropright"
          @save="save"
          @remove="remove"
        />
      </span>
    </div>
  </template>

    <script>
  import ResponsibleDropdown from "./ResponsibleDropdown";
  import { tasksMethods } from "../../../state/helpers";

  export default {
    components: {
      ResponsibleDropdown,
    },
    data() {
      return {
        backupValue: "",
        responsibles: [],
        showBox: false,
        ready: false,
        dropdownReady: true
      };
    },
    props: {
      column: {
        default: {},
      },
      columnValue: {
        default: {},
      },
      disabled: {
        default: false,
      },
      dropright: {
        default: false,
      },
      task: {
        default: {},
      },
      group: {
        default: {},
      },
    },
    methods: {
      ...tasksMethods,
      getInitials(person) {
        if (person.corporate_name) {
          let names = person.corporate_name.split(" ");
          if (names.length > 1) {
            return `${names[0] ? names[0][0] : ""}${names[1] ? names[1][0] : ""}`;
          }
        }

        return `${person.name ? person.name[0] : ""}${
          person.last_name ? person.last_name[0] : ""
        }`;
      },
      cancel(e) {
        let base = this.$el;

        if (base) {
          let span = base.querySelector(".person-label");
          let input = base.querySelector(".person-input");
          input.value = this.backupValue;
          span.textContent = this.backupValue;
          span.classList.remove("d-none");
          span.classList.add("d-flex");
          input.classList.remove("d-flex");
          input.classList.add("d-none");
        }
      },

      async save(columnValue, user) {
        let data = {
            groupId: this.group.id,
            taskId: columnValue.workflow_task_id,
            user: user,
        }

        this.updateTaskResponsible(data);
      },

      async remove(id, person, columnValueId) {
        try {

          let name = person.full_name
          let data = {
            id: id,
            person_id: person.id,
            column_value_id: columnValueId,
          };

          this.$swal
            .fire({
              title: "Confirma a remoção?",
              text: `A pessoa ${name.trim()} será removido`,
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#34c38f",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Sim, remover!",
              cancelButtonText: "Cancelar",
            })
            .then((result) => {
              if (result.value) {
                let validate = this.validator(
                  { value: this.persons.length > 1 ? this.persons.length : null },
                  this.required
                );
                if (!validate) {
                  return;
                }
                this.removeWorkflowTaskPerson(data);
                this.$set(this, `persons`, this.persons.filter((item)=> item.id != person.id));
                this.dropdownReady = false;
                setTimeout(()=>{this.dropdownReady = true},200)
              }
            });
        } catch (error) {
          console.log(error);
        }
      },
      validator(data, required) {
          if (!required) return true;

          let errorCount = 0;
          let element = null;
          let errorElement = null;

          element = window.document.body.querySelector(`#required_item_${this.column.id}_${this.group.id}_${this.columnValue.id}`);

          if ((data.value === '' || data.value === null || data.value === undefined)) {

              errorCount++;
              if (element)
                  element.closest('.validate').classList.add('invalid')
          } else {
              if (element)
                  element.closest('.validate').classList.remove('invalid')
          }

          if (errorCount === 1) {
              errorElement = element;
          }

          if (errorElement) {
              errorElement.focus();
              this.$scrollTo(errorElement, 1000, {
                  container: '.container-task-group .simplebar-wrapper .simplebar-mask .simplebar-offset .simplebar-content-wrapper',
                  x: true,
                  y: false,
                  offset: -400,
              });
              showAlert(true, `O campo "${this.column.name}" é obrigatório`, 2000);
          }

          setTimeout(()=>{
              if(this.link !== '' || this.link !== null || this.link !== undefined){
                  element.closest('.validate').classList.remove('invalid');
              }
          }, 3000);
          return errorCount == 0;
      },
    },
    mounted() {
    //   if (this.columnValue.responsibles.length < 4) {
    //     this.persons = this.columnValue.responsibles;
    //   } else {
    //     this.persons.push(this.columnValue.responsibles[0]);
    //     this.persons.push(this.columnValue.responsibles[1]);
    //     this.persons.push(this.columnValue.responsibles[2]);
    //     this.persons.push(this.columnValue.responsibles[3]);
    //   }
      //   let input = this.$el.querySelector(".person-input");
      //   let span = this.$el.querySelector(".person-label");
      //   input.value = this.columnValue.value;
      //   this.backupValue = this.columnValue.value;
      //   if (this.columnValue.value) {
      //       span.textContent = this.columnValue.value;
      //   }
      // if (input) {
      // }
      setTimeout(()=>{
          this.ready = true;
      }, 600)
    },
    beforeDestroy () {
      this.backupValue = "";
      this.persons = [];
      this.showBox = false;
      this.$el.parentNode.removeChild(this.$el);

    }
  };
  </script>
  <style lang="scss" scoped>
  .add-person-area {
    display: none;
    overflow: visible !important;
  }
  .base:hover * .add-person-area {
    display: flex;
  }
  .responsible-container {
    flex: 0 0 auto;
    width: 100%;
  }
  .base:hover * .responsible-container, .base:has(.add-person-area.d-flex)  * .responsible-container {
    flex: 0 0 auto;
    width: 75%;
  }

  .scr-right::-webkit-scrollbar {
    width: 6px !important;
  }

  .scr-right::-webkit-scrollbar-track {
    background: transparent;
    color: black !important;
    border-radius: 10px !important;
  }

  .scr-right::-webkit-scrollbar-thumb:hover {
    background: black !important;
    opacity: 0.6 !important;
    border-radius: 10px !important;
  }

  .scr-right::-webkit-scrollbar-thumb {
    background: #c3cbe4 !important;
    opacity: 0.5 !important;
    padding-left: 5px !important;
    border-radius: 10px !important;
  }

  .dropdown-menu-item-z-index {
    z-index: 10000;
  }

  .text-white-solid {
    color: white !important;
    opacity: 1;
  }
  .overflow-text {
      overflow: hidden !important;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
  }
  .w-90 {
    width: 90%;
  }
</style>
