<template>
  <div class="base">
    <div
      class="row justify-content-start align-items-center"
      v-if="columnValue.persons.length > 0 && ready"
    >
      <b-col cols="9">
        <div class="avatar-group cell-height">
          <!-- persons avatars -->
          <!-- v-b-tooltip.html="contactTooltipData(person)" -->
          <div
            :id="`user-${person.id}`"
            v-for="(person, keyPerson) in persons"
            :key="keyPerson"
            @click="
              (e) =>
                !disabled &&
                removePerson(
                  person.pivot.id,
                  person,
                  columnValue.id,
                )
            "
          >
            <div class="avatar-group-item" v-if="keyPerson < 5">
              <a href="javascript: void(0);" class="d-inline-block" data-bs-toggle="tooltip" data-bs-html="true" :title="`${person.full_name}`">
                <img
                  :v-lazy="person.avatar"
                  v-if="person.avatar"
                  class="rounded-circle avatar-circle delete-on-hover"
                  alt
                />
                <b-avatar
                  variant="info"
                  :text="getPersonInitials(person)"
                  class="mr-3 bg-light text-white avatar-circle rounded-circle delete-on-hover font-size-10"
                  v-else
                />
              </a>
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        cols="2"
        class="justify-content-center align-items-center cell-height add-person-area"
      >
        <PersonDropdown
          v-if="dropdownReady"
          :columnValue="columnValue"
          :selected-persons="persons"
          :disabled="disabled"
          :dropright="dropright"
          :person-type="column.person_type"
          @save="savePerson"
          @remove="removePerson"
        />
      </b-col>
    </div>

    <span
      v-else
      @click="toggleShow(true)"
      class="person-label form-control-plaintext cell-title cell-input text-white-solid p-0 cell-height align-items-center justify-content-center d-flex"
    >
      <PersonDropdown
        v-if="dropdownReady"
        :columnValue="columnValue"
        :selected-persons="persons"
        :disabled="disabled"
        :dropright="dropright"
        :person-type="column.person_type"
        @save="savePerson"
        @remove="removePerson"
      />
    </span>
  </div>
</template>

  <script>
import PersonDropdown from "./PersonDropdown.vue";
import { personsMethods } from "../../../state/helpers";
import { mapActions } from "vuex";

export default {
  components: {
    PersonDropdown,
  },
  data() {
    return {
      backupValue: "",
      persons: [],
      showBox: false,
      ready: false,
      dropdownReady: true
    };
  },
  props: {
    column: {
      default: {},
    },
    columnValue: {
      default: {},
    },
    disabled: {
      default: false,
    },
    dropright: {
      default: false,
    },
    task: {
      default: {},
    },
    group: {
      default: {},
    },
  },
  methods: {
    ...personsMethods,
    ...mapActions('tasks', ['updateTaskPersons']),
    getPersonInitials(person) {
      let text = (`${person.name} ${person.last_name}`).trim();
      let name = '';
      if (person.corporate_name) {
        text = person.corporate_name.trim();
      }
      if(text.length > 0) {
          name = text[0].toUpperCase();
      }

      let textSplited = text.split(' ');

      if(textSplited.length > 1) {
          if(textSplited[textSplited.length - 1]) {
              name += textSplited[textSplited.length - 1][0].toUpperCase();
          }
      }

      return name;
    },
    cancel(e) {
      let base = this.$el;

      if (base) {
        let span = base.querySelector(".person-label");
        let input = base.querySelector(".person-input");
        input.value = this.backupValue;
        span.textContent = this.backupValue;
        span.classList.remove("d-none");
        span.classList.add("d-flex");
        input.classList.remove("d-flex");
        input.classList.add("d-none");
      }
    },
    async savePerson(data) {
        this.persons.push(data.person)
        let response = await this.saveWorkflowTaskPerson({
          columnId: data.columnValue.workflow_column_id,
          columnValueId: data.columnValue.id,
          personId: data.person.id,
        })

        if(!response) {
            this.$set(this, `persons`, this.persons.filter((person)=> person.id != data.person.id));
        } else {
            this.updateTaskPersons({persons: this.persons, taskId: this.task.id, groupId: this.task.workflow_group_id});
        }
        this.dropdownReady = false;
        setTimeout(()=>{this.dropdownReady = true},200)
    },
    async removePerson(id, person, columnValueId) {
      try {

        let name = person.full_name
        let data = {
          id: id,
          person_id: person.id,
          column_value_id: columnValueId,
        };
        this.$swal
          .fire({
            title: "Confirma a remoção?",
            text: `A pessoa ${name.trim()} será removido`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Sim, remover!",
            cancelButtonText: "Cancelar",
          })
          .then((result) => {
            if (result.value) {
              let validate = this.validator(
                { value: this.persons.length > 1 ? this.persons.length : null },
                this.required
              );
              if (!validate) {
                return;
              }
              this.removeWorkflowTaskPerson(data);
              this.$set(this, `persons`, this.persons.filter((item)=> item.id != person.id));

              this.updateTaskPersons({persons: this.persons, taskId: this.task.id, groupId: this.task.workflow_group_id});

              this.dropdownReady = false;
              setTimeout(()=>{this.dropdownReady = true},200)
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    validator(data, required) {
        if (!required) return true;

        let errorCount = 0;
        let element = null;
        let errorElement = null;

        element = window.document.body.querySelector(`#required_item_${this.column.id}_${this.group.id}_${this.columnValue.id}`);

        if ((data.value === '' || data.value === null || data.value === undefined)) {

            errorCount++;
            if (element)
                element.closest('.validate').classList.add('invalid')
        } else {
            if (element)
                element.closest('.validate').classList.remove('invalid')
        }

        if (errorCount === 1) {
            errorElement = element;
        }

        if (errorElement) {
            errorElement.focus();
            this.$scrollTo(errorElement, 1000, {
                container: '.container-task-group .simplebar-wrapper .simplebar-mask .simplebar-offset .simplebar-content-wrapper',
                x: true,
                y: false,
                offset: -400,
            });
            showAlert(true, `O campo "${this.column.name}" é obrigatório`, 2000);
        }

        setTimeout(()=>{
            if(this.link !== '' || this.link !== null || this.link !== undefined){
                element.closest('.validate').classList.remove('invalid');
            }
        }, 3000);
        return errorCount == 0;
    },
    toggleShow(showInput = false) {
      //   let base = this.$el;
      //   if (base) {
      //     let input = base.querySelector(".person-input");
      //     let span = base.querySelector(".person-label");
      //     if (showInput) {
      //       IMask(input, { mask: config.masks.time });
      //       span.classList.remove("d-flex");
      //       span.classList.add("d-none");
      //       input.classList.remove("d-none");
      //       input.classList.add("d-flex");
      //       this.backupValue = input.value;
      //       input.focus();
      //     } else {
      //       span.classList.remove("d-none");
      //       span.classList.add("d-flex");
      //       input.classList.remove("d-flex");
      //       input.classList.add("d-none");
      //       if (input.value) span.textContent = input.value;
      //       const imaskInstance = input.dataset.imask;
      //       if (imaskInstance) imaskInstance.destroy();
      //     }
      //   }
    },
    save() {
      let base = this.$el;
      if (base) {
        let input = base.querySelector(".person-input");
      }
      this.toggleShow();
    },
    close () {
      // destroy the vue listeners, etc
      this.$destroy();

      // remove the element from the DOM
      this.$el.parentNode.removeChild(this.$el);
    }
  },
  mounted() {
    if (this.columnValue.persons.length < 4) {
      this.persons = this.columnValue.persons;
    } else {
      this.persons.push(this.columnValue.persons[0]);
      this.persons.push(this.columnValue.persons[1]);
      this.persons.push(this.columnValue.persons[2]);
      this.persons.push(this.columnValue.persons[3]);
    }
    //   let input = this.$el.querySelector(".person-input");
    //   let span = this.$el.querySelector(".person-label");
    //   input.value = this.columnValue.value;
    //   this.backupValue = this.columnValue.value;
    //   if (this.columnValue.value) {
    //       span.textContent = this.columnValue.value;
    //   }
    // if (input) {
    // }
    setTimeout(()=>{
        this.ready = true;
    }, 600)
  },
  beforeDestroy () {
    // this.close()
    this.backupValue = "";
    this.persons = [];
    this.showBox = false;
    this.$el.parentNode.removeChild(this.$el);
    // this.$root.$el.parentNode.removeChild(this.$el)
    // this.$destroy();

    //   // remove the element from the DOM
    //   this.$el.parentNode.removeChild(this.$el);
  }
};
</script>
<style lang="scss" scoped>
.size-avatar {
  width: 32px;
  height: 32px;
}
.add-person-area {
  display: none;
  overflow: visible !important;
}
.base:hover * .add-person-area {
  display: flex;
}

.scr-right::-webkit-scrollbar {
  width: 6px !important;
}

.scr-right::-webkit-scrollbar-track {
  background: transparent;
  color: black !important;
  border-radius: 10px !important;
}

.scr-right::-webkit-scrollbar-thumb:hover {
  background: black !important;
  opacity: 0.6 !important;
  border-radius: 10px !important;
}

.scr-right::-webkit-scrollbar-thumb {
  background: #c3cbe4 !important;
  opacity: 0.5 !important;
  padding-left: 5px !important;
  border-radius: 10px !important;
}

.dropdown-menu-item-z-index {
  z-index: 10000;
}
</style>
