/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require("./bootstrap");
import Vue from "vue";
window.Vue = Vue;

import { BootstrapVue } from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import VueMask from "v-mask";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import i18n from "./i18n";
import router from './router/route';
import axios from "axios";
import config from "./config/config";
import App from './App.vue';
import 'sweetalert2/dist/sweetalert2.min.css';
import "simplebar/dist/simplebar.min.css";
import 'bootstrap-vue/dist/bootstrap-vue.css';
import money from './asset/v-money';
import store from './state/store'
import Autocomplete from '@trevoreyre/autocomplete-vue'
// import '@trevoreyre/autocomplete-vue/dist/style.css'
import DatePicker from '@huggydigital/huggy-datepicker';
import '@huggydigital/huggy-datepicker/index.css';
import '@huggydigital/huggy-datepicker/locale/pt-br';
import VueEasyLightbox from "vue-easy-lightbox";
import LazyComponent from "v-lazy-component/vue2";
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import InfiniteLoading from 'vue-infinite-loading';

import { ObserveVisibility } from 'vue-observe-visibility'

var VueScrollTo = require('vue-scrollto');

Vue.prototype.$isDev = process.env.MIX_APP_ENV !== "production";
Vue.config.devtools = Vue.prototype.$isDev;
Vue.config.debug = Vue.prototype.$isDev;
Vue.config.silent = !Vue.prototype.$isDev;

window.bootstrap = require("bootstrap");

let sweetlertOptions = {
    customClass: {
        title: 'rem-size-3',
        popup: 'rem-size-2',
        input: 'rem-size-2',
        inputLabel: 'rem-size-2',
        validationMessage: 'rem-size-2',
        confirmButton: 'rem-size-2',
        denyButton: 'rem-size-2',
        cancelButton: 'rem-size-2',
        closeButton: 'rem-size-2',
    }
}

Vue.use(money, {precision: 4});
Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.use(VueMask);
Vue.use(Vuelidate);
Vue.use(Autocomplete);
Vue.use(DatePicker);
Vue.use(VueScrollTo);
Vue.use(VueSweetalert2, sweetlertOptions);
Vue.use(VueEasyLightbox);
Vue.use(LazyComponent);
Vue.use(VueVirtualScroller);
Vue.use(InfiniteLoading);

Vue.directive('observe-visibility', ObserveVisibility);
// Vue.directive('tooltip', function(el, binding) {
//     $(el).tooltip({
//         title: binding.value,
//         placement: binding.arg,
//         trigger: 'hover'
//     })
// });


Vue.component(
    "dynamic-component",
    require("./components/dynamic-component").default
);



/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import "./views";
import Layouts from "./mixins/layouts.mixin";
import utilsMixin  from "./mixins/utils.mixin";

Vue.mixin(utilsMixin)
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

let host = window.location.host.indexOf(':') != -1? window.location.host.split(':')[0] : window.location.host;

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.MIX_PUSHER_APP_KEY,
  wsHost: host,
  authEndpoint: process.env.MIX_PUSHER_APP_SCHEME+'://'+window.location.host+'/api/broadcasting/auth',
  encrypted: true,
  forceTLS: false,
  wsPort: process.env.MIX_PUSHER_PORT,
  wssPort: process.env.MIX_PUSHER_PORT,
  disableStats: true,
  enabledTransports: ['ws', 'wss', 'flash'],
  auth: {
    headers: {
      authorization: 'Bearer ' + config.getToken,
      Accept: 'application/json',
      'X-App-ID': process.env.MIX_PUSHER_APP_ID,
    }
  }
});

const app = new Vue({
    router,
    store,
    el: "#app",
    mixins: [Layouts, utilsMixin],
    i18n,
    components: {App},
});
