<script>
import MetisMenu from "metismenujs/dist/metismenujs";
import { menusComputed, menusMethods, userComputed, workspacesComputed, workspacesMethods } from "../state/helpers";
import { mapActions, mapState } from "vuex";
import workspaceMenuDropdown from "./workspace-menu-dropdown";
import Multiselect from 'vue-multiselect';

/**
 * Side-nav component
 */
export default {
    components:{
        Multiselect,
        workspaceMenuDropdown
    },
    data() {
        return {
            teste: 0,
            menuWorkflowSearch: '',
            isColapsed: localStorage.getItem('isColapsedMenu'),
            workspacesSearch: '',
        }
    },
 props: {
      isConfig: {
        type: Boolean,
        default: false,
        }
    },
 computed:{
     ...menusComputed,
     ...userComputed,
     ...workspacesComputed,
     ...mapState('workspaces', {
        workspaces: (state)=> state.workspaces
     }),
     menuItemsFilter() {
        if(this.menuItems == 'undefined') {
            return [];
        }

        let workspaceId = 0;
        if(Object.keys(this.workspace).length > 0){
            workspaceId = this.workspace.id;
        }
        return this.menuItems.filter((item)=>{
            let search = item.label.toLowerCase().indexOf(this.menuWorkflowSearch.toLowerCase()) != -1;
            if(item.isWorkflow && workspaceId == 0) {
                return search;
            }
            if(item.isWorkflow && workspaceId != 0) {
                if(this.workspace.id == -1 && item.favorite) {
                    return search;
                }

                if(this.workspace.workflows.find((workflow) => workflow.id == item.workflowId) !== undefined) {
                    return search;
                }

                return false;
            }
            return true;
        });
     },
     filtredWorkspaces() {
        return this.workspaces.filter((workspace)=>{
            return workspace.name.toLowerCase().indexOf(this.workspacesSearch.toLowerCase()) != -1 && this.workspace.id != workspace.id;
        });
     }
 },
 created(){
     this.getWorkspaces();
    this.getMenu();
 },
  mounted: function () {
    this.renderMenu(3);
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.renderMenu();
      }
    );

    this.$watch(
      () => this.menuItems,
      (toParams, previousParams) => {
          let hasFavorite = this.menuItems.find((item) => item.favorite);
          let favorite = { id: -1, name: 'Favoritos', color: '#f7ca4c', icon: 'bx bxs-star', workflows: [], created_at: ''};
          if(localStorage.getItem('workspace-loads') == 0) {
            if(hasFavorite) {
                this.selectWorkspace(favorite);
                localStorage.setItem('workspace-loads', 1);
            }
          } else {
            let workspaceId = localStorage.getItem('workspace-selected');
            if(workspaceId != -1 && workspaceId !== undefined && workspaceId != 'undefined') {
                let workspace = this.workspaces.find((workspace) => workspace.id == workspaceId)
                if(workspace) {
                    this.selectWorkspace(workspace);
                }

            } else {
                if(hasFavorite)
                    this.selectWorkspace(favorite);
            }

          }
      }
    );

  },

  methods: {
    ...menusMethods,
    ...workspacesMethods,
    ...mapActions('workspaces', ['selectWorkspace']),
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    },
    renderMenu(tries = 0) {
        // eslint-disable-next-line no-unused-vars
        var menu = this.menuSettings;
        var menuRef = new MetisMenu("#side-menu");
        var links = document.getElementsByClassName("side-nav-link-ref");
        var matchingMenuItem = null;
        const paths = [];

        for (var i = 0; i < links.length; i++) {
            paths.push(links[i]["pathname"]);
        }
        var itemIndex = paths.indexOf(window.location.pathname);
        if (itemIndex === -1) {
            const strIndex = window.location.pathname.lastIndexOf("/");
            const item = window.location.pathname.toString();
            matchingMenuItem = links[paths.indexOf(item)];

            if(tries > 0 && !matchingMenuItem) {
                setTimeout(()=>{this.renderMenu(tries-1)},3000);
            }
        } else {
            matchingMenuItem = links[itemIndex];
        }

        if (matchingMenuItem) {
            let actives = window.document.querySelectorAll('#side-menu .mm-active');
            actives.forEach((item)=>{
                item.classList.remove("mm-active");
            });
            matchingMenuItem.classList.add("active");
            var parent = matchingMenuItem.parentElement;

        /**
         * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
         * We should come up with non hard coded approach
         */
        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement.closest("ul");
            if (parent2 && parent2.id !== "side-menu") {
            parent2.classList.add("mm-show");

            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("mm-active");
                var childAnchor = parent3.querySelector(".has-arrow");
                var childDropdown = parent3.querySelector(".has-dropdown");
                if (childAnchor) childAnchor.classList.add("mm-active");
                if (childDropdown) childDropdown.classList.add("mm-active");

                const parent4 = parent3.parentElement;
                if (parent4 && parent4.id !== "side-menu") {
                parent4.classList.add("mm-show");
                const parent5 = parent4.parentElement;
                if (parent5 && parent5.id !== "side-menu") {
                    parent5.classList.add("mm-active");
                    const childanchor = parent5.querySelector(".is-parent");
                    if (childanchor && parent5.id !== "side-menu") {
                    childanchor.classList.add("mm-active");
                    }
                }
              }
            }
          }
        }
      }
    }
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="item in menuItemsFilter">
        <!-- title -->
        <span v-if="item.isTitle && !item.disable" :key="item.id" class="bg-default rem-size-2"  :class="{'sticky-menu menu-top-1': item.id == 3 && !isColapsedMenu}">
            <li class="menu-title bg-default " v-if="item.isTitle && !item.disable" :key="item.id" style="padding-right: 0.75rem !important; padding-left: 0.75rem !important;">{{ $t(item.label) }}</li>
            <!-- Search -->
            <section  v-if="((user.user_type_id == 2 || user.user_type_id == 4) && item.id == 3) && (!isColapsed || !isColapsedMenu)" class="mb-1">
                <b-input-group class="side-nav-search-group">
                    <b-input-group-prepend>
                        <span class="form-control bg-default border-0 d-flex justify-content-center align-items-center h-100 padding-end-search"><i
                            class="bx bx-search-alt-2 ps-1 py-1 search-icon-size"></i></span>
                    </b-input-group-prepend>
                    <b-input v-model="menuWorkflowSearch" placeholder="Buscar" class="form-control side-nav-search bg-default ps-1  me-2 rem-size-2" id="side-nav-input-search" ></b-input>
                </b-input-group>
            </section>

            <!-- workspaces -->
            <section name="workspace"  v-if="((user.user_type_id == 2 || user.user_type_id == 4) && item.id == 3) && (!isColapsed || !isColapsedMenu)" class="mb-1">
                <b-input-group class="px-3">
                    <div class="col-12">
                        <workspaceMenuDropdown />
                    </div>
                </b-input-group>
            </section>
            <span v-if="((user.user_type_id == 2 || user.user_type_id == 4) && item.id == 3)" class="d-flex justify-content-center px-1">
                <hr class="col-12" />
            </span>
        </span>

        <!-- Items -->
        <li v-if="!item.isTitle && !item.isLayout  && !item.disable" :key="item.id" class="" :class="{
            'sticky-menu menu-top-0 menu-top-normalize': item.id == 2 && !isColapsedMenu,
            'sticky-menu menu-top-2': item.id == 4 && !isColapsedMenu
        }">
          <a
            v-if="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent"
            :class="{
                'has-arrow': !item.badge,
                'has-dropdown': item.badge,
                'pt-0 pb-0': item.isWorkflow,
                'overflow-text': !isColapsed,
                'px-1 justify-content-start menu-item-padding-left': !isColapsedMenu,
                'side-nav-link-ref d-flex align-items-center padding-x-colapsed-menu router-link-active justify-content-start': isColapsedMenu
            }"
          >
            <i class="rem-size-3" :class="`${item.icon}`" v-if="item.icon"></i>
            <span>{{ $t(item.label) }}</span>
            <span
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              v-if="item.badge"
            >{{ $t(item.badge.text)}}</span>
          </a>

           <router-link :to="{path: item.link}" v-if="!hasItems(item)"
                class="side-nav-link-ref d-flex align-items-center padding-x-colapsed-menu rem-size-2"
                :class="{'pt-0 pb-0': item.isWorkflow, 'overflow-text': !isColapsed, 'px-1 justify-content-start': !isColapsedMenu, 'justify-content-start': isColapsedMenu}"
            >
                <i class="rem-size-3" :class="[`${item.icon}`,{'ps-2 py-0': (!isColapsed || !isColapsedMenu)}]" v-if="item.icon"></i>
                <span v-if="item.label.length > 15 && item.isWorkflow" v-b-tooltip.hover.top="item.label" class="overflow-text ps-2">{{ item.label }}</span>
                <span v-else-if="item.isWorkflow" class="ps-2 overflow-text ">{{ item.label }}</span>
                <span v-else class="ps-2">{{ $t(item.label) }}</span>
                <span
                :class="[`badge rounded-pill bg-${item.badge.variant} float-end overflow-text`]"
                v-if="item.badge"
                >{{$t(item.badge.text)}}</span>
            </router-link>

            <!-- Search -->
            <section v-if="item.id == 4  && (!isColapsed || !isColapsedMenu)" class="mb-1">
                <b-input-group class="side-nav-search-group">
                    <b-input-group-prepend>
                        <span class="form-control bg-default border-0 d-flex justify-content-center align-items-center h-100 padding-end-search"><i
                            class="bx bx-search-alt-2 ps-1 py-1 search-icon-size"></i></span>
                    </b-input-group-prepend>
                    <b-input v-model="menuWorkflowSearch" placeholder="Buscar" class="form-control side-nav-search bg-default ps-1   me-2 rem-size-2" id="side-nav-input-search" ></b-input>
                </b-input-group>
            </section>

            <!-- workspaces -->
            <section name="workspace" v-if="item.id == 4  && (!isColapsed || !isColapsedMenu)" class="mb-1">
                <b-input-group class="ps-2 pe-0">
                    <div class="col-10">
                        <workspaceMenuDropdown />
                    </div>
                    <div class="col-2 d-flex justify-content-end">
                        <router-link :to="{name: 'workspaces'}" class="text-decoration-none opacity opacity-7 p-1 ps-1 d-flex justify-content-center align-items-center"><i class="bx bx-dots-horizontal text-white opacity opacity-9 "></i></router-link>
                    </div>
                </b-input-group>
            </section>
            <span v-if="item.id == 4" class="d-flex justify-content-center px-1">
                <hr class="col-12" />
            </span>
          <ul v-if="hasItems(item)" class="sub-menu ps-3" aria-expanded="false">
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <router-link
                :to="{path: subitem.link}"
                v-if="!hasItems(subitem)"
                class="side-nav-link-ref"
                :class="{'px-1': (!isColapsed || !isColapsedMenu)}"
              ><span class="overflow-text"><i :class="`${subitem.icon}`" v-if="subitem.icon"></i><span>{{ $t(subitem.label) }} </span> </span> </router-link>

              <router-link :to="{path: subitem.link}" v-if="!hasItems(subitem) && subitem.parentId == 4">{{ $t(subitem.label) }}</router-link>
              <router-link
                :to="{path: 'javascript:void(0);'}"
                v-if="hasItems(subitem)"
                class="side-nav-link-a-ref has-arrow"
              >{{ $t(subitem.label) }}</router-link>
              <ul v-if="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li v-for="(subSubitem, index) of subitem.subItems" :key="index">
                  <router-link :to="{path: subSubitem.link}" class="side-nav-link-ref " :class="{'px-1': (!isColapsed || !isColapsedMenu)}">{{ $t(subSubitem.label) }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
<style scoped>
.overflow-text {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.side-nav-search  {
    border-color: transparent;
    padding-left: 7px;
}
.side-nav-search-group:hover {
    opacity: 1;
    color: white;
}
.margin-n-1 {
    margin-left: -4px;
}
.opacity-7 {
    opacity: 0.7;
}
.opacity:hover {
    opacity: 1;
}
.menu-top-0 {
    top: 15px;
}
.menu-top-1 {
    top:  56px;
}
.menu-top-2 {
    top: 93px;
}

.sticky-menu {
    position: sticky;
    z-index: 7;
    background-color: #2a3042;
}
.menu-item-padding-left {
    padding-left: 13px !important;
}
.padding-x-colapsed-menu {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
}
.search-icon-size {
    font-size: 0.8rem !important;
}
.padding-end-search {
    padding-right: 7.5px;
}
.menu-top-normalize {
    /* top: 0px !important;
    padding-top: 15px; */
}
</style>
