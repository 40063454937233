<script>
import { Bar } from "vue-chartjs";
import "chartjs-plugin-annotation";

export default {
  extends: Bar,
  props: {
    labels: {
      type: Array,
      default: [],
    },
    datasets: {
      type: Array,
      default: [],
    },
    maintainAspectRatio: {
      type: Boolean,
      default: true,
    },
    responsive: {
      type: Boolean,
      default: true,
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
    legendPosition: {
      type: String,
      default: "left",
    },
    legendAlign: {
      type: String,
      default: "center",
    },
    legendColor: {
      type: String,
      default: "#fff",
    },
    legendWidth: {
      type: Number,
      default: 15,
    },
    indicatorMin: {
      type: Number,
      default: 0,
    },
    indicatorAvg: {
      type: Number,
      default: 0,
    },
    indicatorMax: {
      type: Number,
      default: 0,
    },
    scales: {
      default: null,
    },
  },
  data() {
    return {
      chartInstance: null,
      legendStates: [],
    };
  },
  methods: {
    getAnnotations() {
      let annotations = [];
      if (this.indicatorMin) {
        annotations.push({
          drawTime: "afterDraw",
          id: "a-line-1",
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: this.indicatorMin,
          borderColor: "#556ee6",
          borderWidth: 1,
          label: {
            content: `${this.indicatorMin}`,
            enabled: true,
            position: "right",
            backgroundColor: "#556ee6",
          },
        });
      }

      if (this.indicatorAvg) {
        annotations.push({
          drawTime: "afterDraw",
          id: "a-line-2",
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: this.indicatorAvg,
          borderColor: "#f1b44c",
          borderWidth: 1,
          label: {
            content: `${this.indicatorAvg}`,
            enabled: true,
            position: "right",
            backgroundColor: "#f1b44c",
          },
        });
      }

      if (this.indicatorMax) {
        annotations.push({
          drawTime: "afterDraw",
          id: "a-line-3",
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: this.indicatorMax,
          borderColor: "#E9374B",
          borderWidth: 1,
          label: {
            content: `${this.indicatorMax}`,
            enabled: true,
            position: "right",
            backgroundColor: "#E9374B",
          },
        });
      }

      return annotations;
    },
    getScales() {
        let scales = this.scales;

        if(!scales) {
            scales = {
                xAxes: [
                  {
                    gridLines: {
                      color: "#c3cbe41a",
                    },
                    ticks: {
                      fontColor: '#fff',
                    },
                  },
                ],
                yAxes: [
                  {
                    gridLines: {
                      color: "#c3cbe41a",
                    },
                    ticks: {
                      beginAtZero: true,
                      fontColor: '#fff',
                    },
                  },
                ],
            }
        }

        return scales;
    }
  },
  mounted() {
    let annotations = this.getAnnotations();
    let scales = this.getScales();
    this.renderChart(
      {
        labels: this.labels,
        // Ex: ['Desktops', 'Tablets']

        datasets: this.datasets,
        // Ex:
        // [
        //   {
        //     label: "Sales Analytics",
        //     backgroundColor: "rgba(52, 195, 143, 0.8)",
        //     borderColor: "rgba(52, 195, 143, 0.8)",
        //     borderWidth: 1,
        //     hoverBackgroundColor: "rgba(52, 195, 143, 0.9)",
        //     hoverBorderColor: "rgba(52, 195, 143, 0.9)",
        //     data: [65, 59, 81, 45, 56, 80, 50, 20]
        //   }
        // ]
      },
      {
        color: this.legendColor,
        maintainAspectRatio: this.maintainAspectRatio,
        responsive: this.responsive,
        scales: scales,
        // Ex:
        // scales: {
        //   xAxes: [
        //     {
        //       gridLines: {
        //         color: "rgba(166, 176, 207, 0.1)",
        //       },
        //       barPercentage: 0.4,
        //     },
        //   ],
        //   yAxes: [
        //     {
        //       gridLines: {
        //         color: "rgba(166, 176, 207, 0.1)",
        //       },
        //     },
        //   ],
        // },

        annotation: {
          drawTime: "afterDatasetsDraw",
          annotations: annotations,
        },
        legend: {
          display: this.showLegend,
          position: this.legendPosition,
          align: this.legendAlign,
          labels: {
            boxWidth: this.legendWidth,
            fontColor: this.legendColor,
          },
        },
      }
    );

    // Get chart instace
    // this.chartInstance = this.$data._chart;
  },
};
</script>
