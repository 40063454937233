<script>

import PageHeader from '../../../../components/page-header.vue'
import axios from 'axios';
import config from '../../../../config/config'
import showAlert from "../../../../components/alerts";
import { mapActions } from 'vuex';
import Switches from "vue-switches";

import {
    required,
    email,
    minLength,
    sameAs,
    maxLength,
} from "vuelidate/lib/validators";

/**
 * Register component
 */
export default {
  components: {PageHeader, Switches },
  data() {
    return {
      submitUrl: window.origin+ "/api/v1/user",
      typesOptions: [{value: null, text: 'selecione'}],
      axRegError: "",
      regError: "",
      isRegisterError: false,
      registerSuccess: false,
      dateInputMax: '9999-12-31',
      dateInputMin: '1902-01-01',
      previewImage: '',
      title: "Usuários",
      updatePassword: false,
      items: [
            {
                text: "Manutenção",
                href: "/maintenance",
            },
            {
                text: "Usuários",
                active: true,
            },
      ],
      form:{
          email: "",
          name: "",
          lname: "",
          birth: "",
          cellphone: "",
          password: "",
          password_confirmation: "",
          type: null,
          logoFile: null,
          avatar: "",
          email_notification: true,
          mute_notifications: false
      },
      submitted: true,

    };
  },
  props: {
    userId: {
            type:Number,

        },
    isProfile: {default: false}
    },
  validations: {
      form:{
          email:{
              required,
              email,
              minLength: minLength(6),
          },
          name:{
              required,
              minLength: minLength(3),
              maxLength: maxLength(15)
          },
          lname:{
              required,
              minLength: minLength(3),
              maxLength: maxLength(20)
          },
          birth:{
              required,
              minLength: minLength(10),
              maxLength: maxLength(10)
          },
          cellphone:{
              required,
              minLength: minLength(14),
              maxLength: maxLength(15)
          },
          type:{
            //   required
          },
          password: {
            minLength: minLength(8),
            maxLength: maxLength(20),
          },
          password_confirmation: {
            minLength: minLength(8),
            maxLength: maxLength(20),
            sameAsPassword: sameAs("password")
          }
      }
  },
  methods: {
    ...mapActions('user', ['getUser']),
      openLogoFile: function () {
      this.$refs["logo-file"].click();
      },
      pickFile: function () {
      var input = this.$refs["logo-file"];
      var files = this.$refs["logo-file"].files;
      this.form.logoFile = this.$refs["logo-file"].files[0];

      if (files && files[0]) {
          let reader = new FileReader();
          reader.onload = (event) => {
          this.previewImage = event.target.result;
          };
          reader.readAsDataURL(files[0]);
          this.$emit("input", files[0]);
      }
      },
      formValidate(e) {
        this.submitted = true;
        this.$v.$touch();
        e.preventDefault();

        if(!this.$v.$anyError){

            let data = new FormData();
            if(this.userId){
                data.append('user_id', this.userId);
            }

            if(this.form.email) data.append('email', this.form.email);
            if(this.form.name) data.append('name',  this.form.name);
            if(this.form.lname) data.append('last_name', this.form.lname);
            if(this.form.birth) data.append('birth', this.form.birth);
            if(this.form.cellphone) data.append('cellphone', this.form.cellphone);
            if(this.form.type) data.append('user_type_id', this.form.type);
            if(this.form.password) data.append('password', this.form.password);
            if(this.form.password_confirmation) data.append('password_confirmation', this.form.password_confirmation);
            if(this.form.logoFile) data.append('avatar', this.form.logoFile);
            data.append('mute_notifications', this.form.mute_notifications ? 1 : 0);
            data.append('email_notification', this.form.email_notification ? 1 : 0);

            if(!this.userId){
                const register = axios.post(this.submitUrl+'/save', data, config.getHeaders)
                .then( response => {

                    if(!response.data.error){
                        this.getUser();
                        showAlert(false, response.data.message);
                        this.$root.$emit('bv::hide::modal', 'modal-user')
                    }

                }).catch(error => {
                    if(error.response) {
                        showAlert(true, error.response.data.message);
                    }
                })
            } else {
                data.append('_method', 'PATCH');
                const register = axios.post(this.submitUrl+'/'+this.userId, data, config.getHeaders)
                .then( response => {

                    if(!response.data.error){
                        this.getUser();
                        showAlert(false, response.data.message);
                        this.$root.$emit('bv::hide::modal', 'modal-user')
                    }

                }).catch(error => {
                    if(error.response) {
                        showAlert(true, error.response.data.message);
                    }
                })
            }
        }

    },

    getUserTypes(){
        const getUserTypes = axios.get(window.location.origin+'/api/v1/user/types', config.getHeaders)
        .then( response => {

            response.data.forEach(element => {
                var userType = {value: element.id, text: element.type}

                this.typesOptions.push(userType)
            });

        }).catch(error => {
            if(error.response) {
                this.isRegisterError = true
                this.axRegError = error.response.data.message;
            }
        });
      },
      getSelectedUser(userId){
        const getUser = axios.post(window.location.origin+'/api/v1/user/'+userId, config.getHeaders)
        .then( response => {
            var user = response.data;
            if(user){
               this.form = {
                    email: user.email,
                    name: user.name,
                    lname: user.last_name,
                    birth: user.birth,
                    cellphone: user.cellphone,
                    type: user.user_type_id,
                    email_notification: user.email_notification,
                    mute_notifications: user.mute_notifications,
                }
                if(user.avatar) {
                    this.previewImage = user.avatar;
                }
            }
        }).catch(error => {
            if(error.response) {
                this.isRegisterError = true
                this.axRegError = error.response.data.message;
            }
        });
      },
      setRangeDateInput(){
        var date = new Date();
        this.form.birth = this.formatDate(date);
        this.dateInputMax = this.formatDate(date);
        date.setFullYear(date.getFullYear() - 120);
        this.dateInputMin = this.formatDate(date);
        },
        formatDate(date) {
            return `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(date.getDate())}`;
        },
        pad(valor) {
            return valor.toString().padStart(2, '0');
        },
        toggleUpdatePassword() {
            if(this.isProfile) {
                this.updatePassword = !this.updatePassword;
            }

            if(!this.updatePassword) {
                this.form.password_confirmation = "";
                this.form.password = ""
            }
        }
  },
  created(){

  },
  mounted() {
    this.setRangeDateInput();
    this.isRegisterError = !!this.regError;
    this.getUserTypes();

    if(this.userId){
        this.getSelectedUser(this.userId);
    }

    this.updatePassword = !this.isProfile;
  },
};
</script>

<template>
    <div>
    <!-- <PageHeader :title="title" :items="items" /> -->
        <div class="row justify-content-center user-form-component">
    <div class="col-md-12 col-lg-12 col-xl-12">
      <div class="card overflow-hidden m-0" :class="{'p-3': isProfile}">
        <div class="card-body p-0">
          <div class="d-flex justify-content-center">
            <input
                  type="file"
                  class="d-none"
                  ref="logo-file"
                  name="logo-file"
                  id="logo-file"
                  @change="pickFile"
                  @load="pickFile"
                  accept="image/*"
                />
            <a href="javascript: void(0);"
               class="d-inline-block text-decoration-none fs-5"
               @click="openLogoFile">
              <div class="avatar-xl mb-4">
                <span class="avatar-title rounded-circle bg-light">
                    <b-img v-if="previewImage"
                        rounded="circle"
                        alt="foto usuario"
                        :src="previewImage ? previewImage : '/images/logo.svg'"
                        data-holder-rendered="true"
                        height="100%"
                        width="100%"
                        ref="logo"
                      ></b-img>
                      <div v-else>
                        <i class="fas fa-camera fs-2"></i>
                      </div>
                </span>
              </div>
            </a>
          </div>

          <b-alert v-model="registerSuccess"  variant="success" dismissible>
              Cadastro efetuado com sucesso.
            </b-alert>

          <b-alert v-model="isRegisterError" variant="danger" dismissible>
              <span class="text-white" v-if="isRegisterError && regError">{{ regError }}</span>
              <span class="text-white" v-if="isRegisterError && axRegError">{{ axRegError }}</span>
              </b-alert>

          <b-form :action="submitUrl" method="POST" @submit="formValidate">
            <slot />

            <!-- email -->
            <b-form-group id="email-group" label="E-mail" label-for="email" class="mb-3">
                <b-form-input
                type="email"
                class="form-control font-size-12"
                id="email"
                placeholder="Digite seu e-mail"
                :class="{ 'is-invalid': submitted && $v.form.email.$error }"
                v-model="form.email"
                name="email"
                size="sm"
                ></b-form-input>
              <div v-if="submitted && $v.form.email.$error" class="invalid-feedback">
                <span v-if="!$v.form.email.required">Insira um e-mail</span>
                <span v-else-if="!$v.form.email.email">Insira um e-mail válido</span>
                <span v-else-if="!$v.form.email.minLength">O email deve conter no mínimo 6 caracteres</span>
              </div>
             </b-form-group>

             <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    class="mb-3"
                    label="Nome"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      type="text"
                      placeholder="Digite seu nome"
                      v-model="form.name"
                      class="font-size-12"
                      :class="{ 'is-invalid': submitted && $v.form.name.$error }"
                      size="sm"
                    ></b-form-input>
                    <div
                        v-if="submitted && $v.form.name.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.form.name.required">Insira seu nome</span>
                        <span v-else-if="!$v.form.name.minLength">O nome deve conter no mínimo 3 caracteres</span>
                        <span v-else-if="!$v.form.name.maxLength">O nome deve conter no mínimo 15 caracteres</span>
                    </div>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <b-form-group
                      class="mb-3"
                      label="Sobrenome"
                      label-for="lastname"

                    >
                      <b-form-input
                        id="lastname"
                        type="text"
                        placeholder="Digite seu sobrenome"
                        v-model="form.lname"
                        class="font-size-12"
                       :class="{ 'is-invalid': submitted && $v.form.lname.$error }"
                       size="sm"
                      ></b-form-input>
                      <div v-if="submitted && $v.form.lname.$error" class="invalid-feedback">
                        <span v-if="!$v.form.lname.required">Insira seu sobrenome</span>
                        <span v-else-if="!$v.form.lname.minLength">O sobrenome deve conter no mínimo 3 caracteres</span>
                        <span v-else-if="!$v.form.lname.maxLength">O sobrenome deve conter no máximo 20 caracteres</span>
                      </div>
                    </b-form-group>
                  </div>
                </div>
              </div>

              <div class="row mb-3">
                <b-form-group id="birth-group" label="Nascimento" label-for="birth" class="col-md-6">
                <b-form-input
                    id="birth"
                    v-model="form.birth"
                    name="birth"
                    type="date"
                    placeholder="00/00/0000"
                    :max="dateInputMax"
                    :min="dateInputMin"
                    class="font-size-12"
                    :class="{ 'is-invalid': submitted && $v.form.birth.$error }"
                    size="sm"
                ></b-form-input>
                <div v-if="submitted && $v.form.birth.$error" class="invalid-feedback">
                    <span v-if="!$v.form.birth.required">Insira sua Data de nascimento</span>
                    <span v-else-if="!$v.form.birth.minLength">A data de nascimento deve conter 8 caracteres</span>
                    <span v-else-if="!$v.form.birth.maxLength">A data de nascimento deve conter 8 caracteres</span>
                </div>
                </b-form-group>
                <b-form-group id="cellphone-group" v-mask="'(##) #####-####'" label="Telefone" label-for="cellphone" class="col-md-6">
                <b-form-input
                    id="cellphone"
                    v-model="form.cellphone"
                    name="cellphone"
                    type="text"
                    placeholder="(00) 00000-0000"
                    class="font-size-12"
                    :class="{ 'is-invalid': submitted && $v.form.cellphone.$error }"
                    size="sm"
                ></b-form-input>
                <div v-if="submitted && $v.form.cellphone.$error" class="invalid-feedback">
                    <span v-if="!$v.form.cellphone.required">Insira um telefone válido</span>
                    <span v-else-if="!$v.form.cellphone.minLength">O telefone deve conter no mínimo 10 caracteres</span>
                    <span v-else-if="!$v.form.cellphone.maxLength">O telefone deve conter no máximo 11 caracteres</span>
                </div>
                </b-form-group>

              </div>
              <a href="#" @click.prevent="toggleUpdatePassword" v-if="!userId || isProfile"><span v-if="isProfile">Alterar senha</span><i v-if="isProfile" class="mdi mdi-chevron-down d-none d-xl-inline-block"></i></a>
              <b-collapse id="collapse-password" class="mb-3" v-model="updatePassword" v-if="!userId || isProfile">
              <div class="row" v-if="!userId || isProfile">
                    <b-form-group id="password-group" label="Senha" label-for="password" class="col-md-6 mb-3">
                    <b-form-input
                        id="password"
                        v-model="form.password"
                        name="password"
                        type="password"
                        placeholder="Digite uma Senha"
                        class="font-size-12"
                        :class="{ 'is-invalid': submitted && $v.form.password.$error }"
                        :required="!isProfile"
                        size="sm"
                    ></b-form-input>
                    <div v-if="submitted && $v.form.password.$error" class="invalid-feedback">
                        <span v-if="!$v.form.password.required">Insira uma senha</span>
                        <span v-else-if="!$v.form.password.minLength">A senha deve conter no mínimo 8 caracteres</span>
                        <span v-else-if="!$v.form.password.maxLength">A senha deve conter no mínimo 20 caracteres</span>
                        <span v-else-if="!$v.form.password.sameAsPassword">As senhas são diferentes</span>
                    </div>
                    </b-form-group>
                    <b-form-group label="Confirme a Senha" label-for="password-confirm" class="col-md-6 mb-3">
                    <b-form-input
                        id="password-confirm"
                        v-model="form.password_confirmation"
                        name="password_confirmation"
                        type="password"
                        placeholder="Confirme a senha"
                        class="font-size-12"
                        :class="{ 'is-invalid': submitted && $v.form.password_confirmation.$error }"
                        :required="!isProfile"
                        size="sm"
                    ></b-form-input>
                    <div v-if="submitted && $v.form.password_confirmation.$error" class="invalid-feedback">
                        <span v-if="!$v.form.password_confirmation.required">Insira uma senha</span>
                        <span v-else-if="!$v.form.password_confirmation.minLength">A senha deve conter no mínimo 8 caracteres</span>
                        <span v-else-if="!$v.form.password_confirmation.maxLength">A senha deve conter no mínimo 20 caracteres</span>
                        <span v-else-if="!$v.form.password_confirmation.sameAsPassword">As senhas são diferentes</span>
                    </div>
                    </b-form-group>
                </div>
            </b-collapse>

              <div class="row mb-3">
                <div class="col-md-6" v-if="!isProfile">
                  <b-form-group class="" label="Tipo" label-for="type">
                    <b-form-select
                      class="form-select bg-input font-size-12"
                      id="type"
                      name="type"
                      v-model="form.type"
                      :options="typesOptions"
                      :class="{ 'is-invalid': submitted && $v.form.type.$error }"
                      size="sm"
                    >
                    </b-form-select>
                    <span
                      v-if="!$v.form.type.required && $v.form.type.$error"
                      class="text-danger">Selecione um tipo
                    </span>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group class=" m-0" label="Notificações">
                    <div class="row">

                        <div class="mt-n2" :class="{'col-3': !isProfile, 'col-1': isProfile}" v-b-tooltip.top="'Receber notificações por e-mail'">
                            <switches
                                v-model="form.email_notification"
                                :type-bold="false"
                                class="mb-0"
                                :color="!form.email_notification ? 'default' : 'worflow'"
                                label="E-mail"
                            ></switches>
                        </div>

                        <div class="mt-n2" :class="{'col-3': !isProfile, 'col-1': isProfile}" v-b-tooltip="form.mute_notifications ? 'Ativar sinais sonoros' : 'Inativar sinais sonoros'">
                            <switches
                                v-model="form.mute_notifications"
                                :type-bold="false"
                                class="mb-0"
                                :color="!form.mute_notifications ? 'default' : 'worflow'"
                                label="Silenciar"
                            ></switches>
                        </div>
                    </div>
                  </b-form-group>
                </div>
              </div>
            <div class="mt-5 d-grid">
              <b-button type="submit" variant="primary" class="btn-block font-size-12" size="sm"><span v-if="!userId">Cadastrar</span><span v-else>Salvar</span> </b-button>
            </div>
          </b-form>
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
    </div>
</template>
<style>
.height-36 {
    height: 36px;
}
label[for=email_notification], label[for=mute_notifications]  {
    margin: 0 !important;
}

.user-form-component * input, .user-form-component * select {
    color: white !important;
}

.user-form-component * input::placeholder{
    color: white !important;
    opacity: 1;
}
</style>
