import axios from "axios";
import getPageObject from './support/getPageObject';
import Vue from 'vue'

let baseEndpoint = `${window.location.origin}/api/v1/workflow/column/value`;

export const state = {
    columnValue: {},
    columnValues: {},
    page: {
        current_page: 1
    },
    columnValuesProcessing: {
        loadColumnValue: false,
        loadMore: false
    },
}

export const mutations = {

    CHANGE_PAGE_COLUMN_VALUES(state, data) {
        state.page = data
    },
    CHANGE_COLUMN_VALUES(state, data) {
        state.columnValues = data
    },

    CHANGE_COLUMN_VALUE(state, data) {
        state.columnValue = data
    },
    CHANGE_MORE_COLUMN_VALUE(state, data) {
        state.columnValues = {...state.columnValues, ...data}
    },

    DELETE_COLUMN_VALUE(state, dataId) {
        state.columnValues = state.columnValues.filter((columnValue) => columnValue.id != dataId);
        if (state.columnValue && state.columnValue.id == dataId) {
            state.columnValue = {};
        }
    },

    STORE_COLUMN_VALUE(state, data) {
        state.columnValues.push(data);
    },

    UPDATE_COLUMN_VALUE(state, data) {
        let columnValue = null;
        if(state.columnValues[`task_${data.workflow_task_id}`]) {
            columnValue = state.columnValues[`task_${data.workflow_task_id}`][`column_${data.workflow_column_id}`];
        }

        if (columnValue) {
            let keys = Object.keys(columnValue);
            keys.forEach((key) => {
                if(typeof data[key] !== undefined) {
                    columnValue[key] =  data[key];
                }

            });
        }

        if (state.columnValue && state.columnValue.id == data.id) {
            state.columnValue = columnValue;
        }
    },

    SEARCH_COLUMN_VALUE(state, data) {
        state.columnValues = data;
        let clearName = '';
        let clearSearchData = data.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        state.columnValues = state.columnValues.filter((item) => {
            if (typeof item.name === 'string' || item.name instanceof String) {
                clearName = item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                if (clearName.indexOf(clearSearchData) != -1 || item.id.toString().indexOf(data) != -1) {
                    return true;
                }

            } else {

                if (item.name.toString().indexOf(data.toLowerCase()) != -1 || item.id.toString().indexOf(data) != -1) {
                    return true;
                }

            }
        })
    },

    CHANGE_COLUMN_VALUES_PERSON(state, data) {
        let id = data.columnValue.id;
        let taskId = data.columnValue.workflow_task_id;
        if(state.columnValues[`task_${taskId}`]) {
            state.columnValues[`task_${taskId}`][`column_${id}`].persons.push(data.person);
            // Vue.$set(state.columnValues[`task_${taskId}`], `column_${id}`, data.columnValue);
        }

    },
    REMOVE_COLUMN_VALUES_PERSON(state, data) {
        let id = data.columnValue.id;
        let taskId = data.columnValue.workflow_task_id;
        if(state.columnValues[`task_${taskId}`]) {

            // state.columnValues[`task_${taskId}`][`column_${id}`].persons.push(data.person);
            Vue.set(state.columnValues[`task_${taskId}`][`column_${id}`], 'persons', state.columnValues[`task_${taskId}`][`column_${id}`].persons.filter((person)=> person.id != data.person.id));
        }

    },

    CHANGE_COLUMN_VALUES_COLLABORATOR(state, data) {
        let columnId = data.columnValue.workflow_column_id;
        let taskId = data.columnValue.workflow_task_id;

        if(state.columnValues[`task_${taskId}`]) {
            state.columnValues[`task_${taskId}`][`column_${columnId}`].collaborators.push(data.collaborator);
        }

    },

    REMOVE_COLUMN_VALUES_COLLABORATOR(state, data) {
        let columnId = data.columnValue.workflow_column_id;
        let taskId = data.columnValue.workflow_task_id;
        if(state.columnValues[`task_${taskId}`]) {
            Vue.set(state.columnValues[`task_${taskId}`][`column_${columnId}`], 'collaborators', state.columnValues[`task_${taskId}`][`column_${columnId}`].collaborators.filter((collaborator)=> collaborator.id != data.collaborator.id));
        }

    },

    CHANGE_COLUMN_VALUES_PROCESSING(state, data) {
        state.columnValuesProcessing.loadColumnValue = data;
    },
    CHANGE_COLUMN_VALUES_PROCESSING_MORE(state, data) {
        state.columnValuesProcessing.loadMore = data;
    },
}

export const actions = {
    async setListColumnValues({ state, commit }, content) {
        try {
            commit('CHANGE_COLUMN_VALUES_PROCESSING', true);
            commit('CHANGE_COLUMN_VALUES', content.data);
            commit('CHANGE_PAGE_COLUMN_VALUES', getPageObject(content));
            commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
        } catch(error) {
            console.log('error', error);
            commit('CHANGE_COLUMN_VALUES_PROCESSING', false);

        }
    },
    async listColumnValues({ state, commit }, workflowId) {

        commit('CHANGE_COLUMN_VALUES_PROCESSING', true);

        const response = axios.get(`${baseEndpoint}/list/${workflowId}`)
            .then((response) => {
                if (!response.data.error) {
                    let values = response.data.data;

                    commit('CHANGE_COLUMN_VALUES', values.data)

                    commit('CHANGE_PAGE_COLUMN_VALUES', getPageObject(values))
                }

                commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
            })
            .catch(({ response }) => {
                commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
            });

    },

    async loadMoreColumnValues({ state, commit }, data) {
        try {

            if(typeof state.page.next_page_url !== undefined) {

            commit('CHANGE_COLUMN_VALUES_PROCESSING_MORE', true);

            const response = await axios.get(`${baseEndpoint}/list/${data.workflow_id}?page=${state.page.current_page + 1}&workflow_id=${data.workflow_id}&per_page=${data.limit}`)
            if (!response.data.error) {
                let values = response.data.data;

                commit('CHANGE_MORE_COLUMN_VALUE', values.data)
                commit('CHANGE_PAGE_COLUMN_VALUES', getPageObject(values))
            }

            commit('CHANGE_COLUMN_VALUES_PROCESSING_MORE', false);
            return true;
            }
        } catch(error) {
            console.error(error);
            commit('CHANGE_COLUMN_VALUES_PROCESSING_MORE', false);
            return false;
        }

    },

    async findColumnValue({ state, commit }, columnValueId) {

        commit('CHANGE_COLUMN_VALUES_PROCESSING', true);

        const response = await axios.get(`${baseEndpoint}/${columnValueId}`)
            .then((response) => {
                if (!response.data.error) {
                    commit('CHANGE_COLUMN_VALUE', response.data.data)
                }

                commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
            })
            .catch(({ response }) => {
                commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
            });

    },

    async findColumnValueByTask({ state, commit }, taskId) {

        try {
            commit('CHANGE_COLUMN_VALUES_PROCESSING', true);
            const response = await axios.get(`${baseEndpoint}/find/${taskId}`)
            if (!response.data.error) {
                commit('CHANGE_MORE_COLUMN_VALUE', response.data.data)
            }

            commit('CHANGE_COLUMN_VALUES_PROCESSING', false);

        } catch(error) {
            console.error(error);
            commit('CHANGE_COLUMN_VALUES_PROCESSING', false);

        }
    },

    async deleteColumnValue({ state, commit }, columnValueId) {
        commit('CHANGE_COLUMN_VALUES_PROCESSING', true);

        const response = await axios.delete(`${baseEndpoint}/${columnValueId}`)
            .then((response) => {
                if (!response.data.error) {
                    commit('DELETE_COLUMN_VALUE', columnValueId)
                }

                commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
            })
            .catch(({ response }) => {
                commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
            });
    },

    async storeColumnValue({ state, commit }, data) {
        commit('CHANGE_COLUMN_VALUES_PROCESSING', true);

        const response = await axios.post(`${baseEndpoint}/${columnValueId}`, data)
            .then((response) => {
                if (!response.data.error) {
                    commit('STORE_COLUMN_VALUE', response.data.data)
                }

                commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
            })
            .catch(({ response }) => {
                commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
            });

    },

    /**
     *
     * @param { Object } data { column_value_id: Number, value: Any, description: String }
     * @returns { Boolean } Boolean If not have erros
     */
    async updateColumnValue({ state, commit }, data) {
        commit('CHANGE_COLUMN_VALUES_PROCESSING', true);
        try {
            const response = await axios.patch(`${baseEndpoint}/save`, data)
            if (!response.data.error) {
                commit('UPDATE_COLUMN_VALUE', response.data.data)
                return true
            }
            commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
            return false
        } catch(error) {
            console.log('error', error);
            commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
            return false

        }
    },

    async searchColumnValue({ state, commit }, data) {
        commit('CHANGE_COLUMN_VALUES_PROCESSING', true);

        const response = await axios.get(`${baseEndpoint}/search/${data}`)
            .then((response) => {
                if (!response.data.error) {
                    commit('SEARCH_COLUMN_VALUE', response.data.data)
                }

                commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
            })
            .catch(({ response }) => {
                commit('CHANGE_COLUMN_VALUES_PROCESSING', false);
            });

    },
    addColumnValuePerson({commit}, {person, columnValue}) {
        let data = {
            columnValue: columnValue,
            person: person
        }
        commit('CHANGE_COLUMN_VALUES_PERSON', data);
    },
    removeColumnValuePerson({commit}, {person, columnValue}) {
        let data = {
            columnValue: columnValue,
            person: person
        }
        commit('REMOVE_COLUMN_VALUES_PERSON', data);
    },

    addColumnValueCollaborator({commit}, {collaborator, columnValue}) {
        let data = {
            columnValue: columnValue,
            collaborator: collaborator
        }
        commit('CHANGE_COLUMN_VALUES_COLLABORATOR', data);
    },
    removeColumnValueCollaborator({commit}, {collaborator, columnValue}) {
        let data = {
            columnValue: columnValue,
            collaborator: collaborator
        }
        commit('REMOVE_COLUMN_VALUES_COLLABORATOR', data);
    }

}
