<template>
  <b-dropdown
    @show="setZIndex($event, true), dropdownStatus($event, true)"
    @hide="setZIndex($event), dropdownStatus($event, false)"
    lazy
    :disabled="disabled"
    variant="btn-edit text-decoration-none fs-6 text-secondary p-0"
    :menu-class="[
      { 'right-0': dropright },
      'dropdown-person-width dropdown-bottom scr-right',
    ]"
    right
  >
    <!-- Dropdown button -->
    <template #button-content>
      <span class="d-flex">
        <i class="fas fa-plus-circle text-white"></i>
      </span>
    </template>

    <!-- Dropdown form -->
    <b-dropdown-form
      style="width: 20vw; z-index: 1"
      @submit.stop.prevent
      @keypress.enter.stop.prevent
      class="bg-default dropdown-search-input"
    >
      <b-form-group>
        <b-form-input
          class="form-input"
          placeholder="Buscar"
          v-model="searchData"
          debounce="200"
        ></b-form-input>
      </b-form-group>
    </b-dropdown-form>

    <!-- Dropdown load -->
    <div
      v-show="tasksProcessing.search"
      class="w-100 justify-content-center align-items-center load-height"
    >
      <i class="bx bx-loader-alt bx-spin font-size-20"></i>
    </div>

    <!-- Dropdown data -->
    <div v-if="!tasksProcessing.search && dropdownActive">
      <div
        v-for="(data, keyData) in dataFiltererd"
        :key="keyData"
        :value="data.id"
      >
        <b-dropdown-item @click="save(data)" :disabled="selecteds.indexOf(data.id) != -1" :class="{'bg-success bg-soft': selecteds.indexOf(data.id) != -1}" class="rem-size-2">
          {{ data.name }}
        </b-dropdown-item>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  data() {
    return {
      loadSearchList: false,
      searchData: "",
      collaborators: [],
      dropdownActive: false,
      searchList: [],
    };
  },
  props: {
    workflowId: {
      default: {},
    },
    columnValue: {
      default: {},
    },
    selectedCollaborators: {
      default: [],
    },
    dropright: {
      default: false,
    },
    disabled: {
      default: false,
    },
    selecteds: {
        default: []
    }
  },
  computed: {
    ...mapState('tasks', {
        tasksProcessing: (state)=> state.tasksProcessing,
        searchTasksList: (state)=> state.searchTasksList
    }),

    dataFiltererd() {
      if (!this.dropdownActive) {
        return [];
      }

      this.searchList = JSON.parse(JSON.stringify(this.searchTasksList));
      return this.searchList;

    },
  },
  methods: {
    ...mapActions('tasks', ['searchTasks']),
    search: function search(data) {
      clearTimeout(search.timeout);

      search.timeout = setTimeout(() => {

        this.searchTasks({
            workflowId: this.workflowId,
            columnId: this.columnValue.workflow_column_id,
            search: data
        });

      }, 300);
    },
    dropdownStatus(event, active = false) {
      this.search("");
      this.dropdownActive = active;
      let base = this.$el.closest(".base");
      if (!base) return;
      let element = base.querySelector(".add-area");

      if (active && element) {
        element.classList.add("d-flex");
      }

      if (!active && element) {
        element.classList.remove("d-flex");
      }
    },
    save(task) {
        this.$emit("save", task );
    }
  },
  mounted() {
    this.$watch(
      () => this.searchData,
      (att, old) => {
        this.search(att);
      }
    );
  },
};
</script>

<style lang="scss" scoped>

.dropdown-search-input {
  width: 15vw;
  position: sticky;
  top: 0;
  padding-top: 1vh;
  padding-bottom: 1vh;
}
.bg-default {
  background-color: #2a3042;
}
</style>

<style lang="scss">
.dropdown-bottom::-webkit-scrollbar-track {
  background: #2a3042 !important;
}

.dropdown-bottom::-webkit-scrollbar-thumb {
  background: #eee !important;
}

.dropdown-bottom {
  bottom: 0 !important;
  height: 38vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.scr-right::-webkit-scrollbar {
  width: 6px !important;
}

.scr-right::-webkit-scrollbar-track {
  background: transparent;
  color: black !important;
  border-radius: 10px !important;
}

.scr-right::-webkit-scrollbar-thumb:hover {
  background: black !important;
  opacity: 0.6 !important;
  border-radius: 10px !important;
}

.scr-right::-webkit-scrollbar-thumb {
  background: #c3cbe4 !important;
  opacity: 0.5 !important;
  padding-left: 5px !important;
  border-radius: 10px !important;
}

.bg-default-base {
  background-color: #222736;
}

.dropdown-person-width {
  width: 300px;
}
.load-height {
    height: 100px;
    position: absolute;
    top: auto;
    bottom: 0;
    text-align: center;
}

</style>
