 <script>

export default {
};
</script>
 <template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h1 class="display-2 fw-medium">
              <i class="bx bx-buoy bx-spin text-primary display-3"></i>
            </h1>
            <h4 class="text-uppercase">Sistema em manutenção</h4>
            <h5>O sistema esta passando por melhorias, voltaremos em alguns instantes</h5>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <div>
            <img src="/images/error-img.png" alt class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
