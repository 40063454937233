import axios from "axios";
import Vue from  'vue'
let baseEndpoint = `${window.location.origin}/api/v1/workflow/group`;

export const state = {
    dragGroup: false,

    group: {},
    groups: [],
    columnSum: {},
    showAllTasks: true,
    zIndexBase: {
        groupCount: [],
        cardHeader: [],
        cardBody: 0,
    },
    groupsProcessing: {
        loadGroup: false,
        search: false,
    },
}

export const mutations = {

    CHANGE_GROUPS(state, data) {
        state.groups = typeof data != 'undefined' && typeof data !== undefined ? data : []
        state.zIndexBase.cardHeader = [];
        state.zIndexBase.cardBody = [];
        state.zIndexBase.groupCount = Math.ceil(state.groups.length * 2);

        var controller = state.zIndexBase.groupCount;

        for (let i = 0; i <= state.zIndexBase.groupCount; i++) {

          if ((controller % 2) == 0) {
            state.zIndexBase.cardHeader.push(controller)
          } else {
            state.zIndexBase.cardBody.push(controller);
          }
          controller--;
        }
    },

    CHANGE_GROUP(state, data) {
        state.group = data
    },

    DELETE_GROUP(state, dataId) {
        state.groups = state.groups.filter((group) => group.id != dataId);
        if (state.group && state.group.id == dataId) {
            state.group = {};
        }
    },

    STORE_GROUP(state, data) {
        data.show_tasks = true;
        state.groups.push(data);
    },

    UPDATE_GROUP(state, data) {

        let group = state.groups.find((group) => group.id == data.id);
        if (group) {
            let keys = Object.keys(group);
            keys.forEach((key) => {
                Vue.set(group, key, data[key]);
            });
        }

        if (state.group && state.group.id == data.id) {
            state.group = data;
        }
    },

    SEARCH_GROUP(state, data) {
        state.groups = data;
        let clearName = '';
        let clearSearchData = data.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        state.groups = state.groups.filter((item) => {
            if (typeof item.name === 'string' || item.name instanceof String) {
                clearName = item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");

                if (clearName.indexOf(clearSearchData) != -1 || item.id.toString().indexOf(data) != -1) {
                    return true;
                }

            } else {

                if (item.name.toString().indexOf(data.toLowerCase()) != -1 || item.id.toString().indexOf(data) != -1) {
                    return true;
                }

            }
        })
    },

    CHANGE_COLUMN_SUM(state, data) {
        state.columnSum = data;
    },

    CHANGE_GROUPS_PROCESSING(state, data) {
        state.groupsProcessing.loadGroup = data;
    },

    CHANGE_GROUPS_PROCESSING_SEARCH(state, data) {
        state.groupsProcessing.search = data;
    },

    CHANGE_DRAG_GROUPS(state, data) {
        state.setDragGroups = data;
    },

    CHANGE_SHOW_TASKS_GROUPS(state, data) {
        state.groups.forEach((group)=>{
            group.show_tasks = data;
        });
    },
    TOGGLE_ALL_SHOW_TASKS_GROUPS(state, data) {
        state.showAllTasks = data;
    },

    CHANGE_SHOW_TASKS_BY_GROUP(state, {groupId, show}) {
        let group = state.groups.find((group) => group.id == groupId)
        if(!group) return;

        Vue.set(group, 'show_tasks', show);

    },
}

export const actions = {

    async listGroups({ commit }, workflowId) {
        commit('CHANGE_GROUPS_PROCESSING', true);
        let data = { workflow_id: workflowId }
        const response = axios.post(`${baseEndpoint}/find`, data)
            .then((response) => {
                if (!response.data.error) {
                    commit('CHANGE_GROUPS', response.data.data);
                }
                commit('CHANGE_GROUPS_PROCESSING', false);
            })
            .catch(({ response }) => {
                console.log('groups', error);
                commit('CHANGE_GROUPS_PROCESSING', false);

            });
    },
    setGroups({commit}, data) {
        commit('CHANGE_GROUPS', data);
    },
    setDragGroups({commit}, data) {
        commit('CHANGE_DRAG_GROUPS', data);
    },
    updateGroupsSequence({dispatch, commit, state}, groups) {
        let current = state.groups;
        dispatch('setGroups', groups);

        var data = [];
        groups.forEach((group, key) => {
            data.push({ group_id: group.id, sequence: key })
        });

        axios.patch(`${baseEndpoint}/sequence`, data)
            .then((response) => {})
            .catch((error) => {
                console.log(error);
                dispatch('setGroups', current)
            });

    },
    toggleShowTasks({commit, state}, data) {
          commit('TOGGLE_ALL_SHOW_TASKS_GROUPS', data);
    },
    toggleShowTasksByGroup({commit,}, {groupId, show}) {
          commit('CHANGE_SHOW_TASKS_BY_GROUP', {groupId, show});
    },

    async findGroup({ commit }, groupId) {
        commit('CHANGE_GROUPS_PROCESSING', true);

        const response = axios.get(`${baseEndpoint}/${groupId}`)
            .then((response) => {
                if (!response.data.error) {
                    commit('CHANGE_GROUP', response.data.data)
                }

                commit('CHANGE_GROUPS_PROCESSING', false);
            })
            .catch(({ response }) => {
                commit('CHANGE_GROUPS_PROCESSING', false);
            });
    },

    async deleteGroup({ commit }, groupId) {

        try {

            commit('CHANGE_GROUPS_PROCESSING', true);

            const response = await axios.delete(`${baseEndpoint}/${groupId}`);
            if (!response.data.error) {
                commit('DELETE_GROUP', groupId)
                return true
            }
            commit('CHANGE_GROUPS_PROCESSING', false);
            return false
        } catch(error) {
            commit('CHANGE_GROUPS_PROCESSING', false);
            return false
        }

    },

    async storeGroup({ commit, dispatch }, data) {
        try {
            commit('CHANGE_GROUPS_PROCESSING', true);

            const response = await axios.post(`${baseEndpoint}`, data)
            if (!response.data.error) {
                commit('STORE_GROUP', response.data.data)
                return response.data.data.id;
            }

            commit('CHANGE_GROUPS_PROCESSING', false);
            return 0;

        } catch(error) {
            commit('CHANGE_GROUPS_PROCESSING', false);
            return 0;

        }

    },

    async updateGroup({ commit }, data) {
        commit('CHANGE_GROUPS_PROCESSING', true);

        const response = await axios.put(`${baseEndpoint}`, data)
            .then((response) => {
                if (!response.data.error) {
                    commit('UPDATE_GROUP', response.data.data)
                }

                commit('CHANGE_GROUPS_PROCESSING', false);
            })
            .catch(({ response }) => {
                commit('CHANGE_GROUPS_PROCESSING', false);
            });
    },

    async searchGroup({ commit }, data) {
        commit('CHANGE_GROUPS_PROCESSING_SEARCH', true);

        const response = axios.get(`${baseEndpoint}/search/${data}`)
            .then((response) => {
                if (!response.data.error) {
                    commit('SEARCH_GROUP', response.data.data)
                }

                commit('CHANGE_GROUPS_PROCESSING', false);
            })
            .catch(({ response }) => {
                commit('CHANGE_GROUPS_PROCESSING', false);
            });
    },
    getAllGroupTotal({ commit }, data) {

        axios.post(`${baseEndpoint}/tasks/total/all`, data)
            .then((response) => {
                commit('CHANGE_COLUMN_SUM', response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },
    getGroupTotal({ commit }, data) {

        var data = {
            group_id: groupId,
            period: this.filter.period,
            dateStart: this.filter.dateStart,
            dateEnd: this.filter.dateEnd,
            status: this.filter.status,
        }

        const getGroupTotal = axios
            .post(`${baseEndpoint}/tasks/total`, data)
            .then((response) => {
                if (this.workflow.column_sum != undefined) {
                    this.$set(this.workflow.column_sum, groupId, response.data.data[groupId])

                } else {
                    this.$set(this.workflow, column_sum, []);
                    this.$set(this.workflow.column_sum, groupId, response.data.data[groupId])
                }

            })
            .catch((error) => {
                showAlert(true, error.response.data.message);
                console.log(error);
            });
    },

    toggleAllGroups({ commit }, data) {
        commit('CHANGE_SHOW_TASKS_GROUPS', data.show);
        axios.patch(`${baseEndpoint}/tasks/show/all/`, data)
            .catch((error) => {
                console.log('Error toggleAllGroups :>> ', error.response.data.message);
                data.show = !data.show;
                commit('CHANGE_SHOW_TASKS_GROUPS', data);
            });

    },

    clearGroups({commit}) {
        commit('CHANGE_GROUPS', []);
        commit('CHANGE_GROUP', {});
    }

}

export const getters = {
    allGroupsColapsed(state) {
        let total =  state.groups.length;
        let colapseds = state.groups.filter((group)=> !group.show_tasks).length;

      return total == colapseds;
    }
  }
