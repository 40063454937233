<template>
  <div class="base">
    <span

      @click="toggleShow(true)"
      class="time-label form-control-plaintext cell-title cell-input text-white-solid p-0 cell-height align-items-center justify-content-center d-flex rem-size-2"
    >00:00</span>

    <input
      @keyup.enter="$event.target.blur()"
      @blur="save()"
      @keyup.esc="cancel"
      type="text"
      :disabled="disabled"
      maxlength="7"
      class="time-input form-control cell-input cell-edit p-0 ps-1 cell-height text-center d-none rem-size-2"
      placeholder="00:00"
    />
  </div>
</template>

<script>
import IMask from "imask";
import config from "../../../config/config";
import { mapActions } from "vuex";
import showAlert from '../../../components/alerts'

export default {
  data() {
    return {
      backupValue: "",
      value: "",
    };
  },
  props: {
    column: {
      default: {},
    },
    columnValue: {
      default: {},
    },
    task: {
      default: {},
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    ...mapActions('columnValues', ['updateColumnValue']),
    ...mapActions('tasks', ['updateTaskColumnValue']),
    cancel(e) {
      let base = this.$el;

      if (base) {
        let span = base.querySelector(".time-label");
        let input = base.querySelector(".time-input");
        input.value = this.backupValue;
        span.textContent = this.backupValue;
        span.classList.remove("d-none");
        span.classList.add("d-flex");
        input.classList.remove("d-flex");
        input.classList.add("d-none");
      }
    },
    toggleShow(showInput = false) {
      let base = this.$el;

      if (base) {
        let input = base.querySelector(".time-input");
        let span = base.querySelector(".time-label");
        if (showInput) {
          IMask(input, { mask: config.masks.time });
          span.classList.remove("d-flex");
          span.classList.add("d-none");
          input.classList.remove("d-none");
          input.classList.add("d-flex");
          this.backupValue = input.value;
          input.focus();
        } else {
          span.classList.remove("d-none");
          span.classList.add("d-flex");
          input.classList.remove("d-flex");
          input.classList.add("d-none");

          if(input.value) span.textContent = input.value;
          const imaskInstance = input.dataset.imask;
          if(imaskInstance) imaskInstance.destroy();
        }
      }
    },
    save() {
      let base = this.$el;
      if (base) {
        let input = base.querySelector(".time-input");
        if (input) {
            let value = input.value;

            let validateTimeInput = value.replaceAll(/[^\d]+/g, '');
            if((validateTimeInput.length < 4 && validateTimeInput.length > 0) ||( validateTimeInput.length > 0 && value.indexOf(':') < 0) ) {
                showAlert(true, 'formato inválido (hhh:mm)');
                return;
            }

            if(value.length == 0) {
                value = "00:00"
            }

            let data = {
                group_id: this.task.workflow_group_id,
                task_id: this.task.id,
                column_type_id: this.column.workflow_column_type_id,
                column_value_id: this.columnValue.id,
                value: value,
                description: '',
            }

            this.updateColumnValue(data);
            this.updateTaskColumnValue(data);
        }
      }
      this.toggleShow();
    },
  },
  mounted() {

    let input = this.$el.querySelector(".time-input");
    let span = this.$el.querySelector(".time-label");
    input.value = this.columnValue.value;
    this.backupValue = this.columnValue.value;

    if (this.columnValue.value) {
        span.textContent = this.columnValue.value;
    }
    if (this.columnValue.value) {
        setTimeout(()=>{ input.value = this.columnValue.value},100);
    }

  },
  beforeDestroy () {
    this.backupValue = "";
    this.value = "";
    this.$el.parentNode.removeChild(this.$el);
  }
};
</script>

<style lang="scss" scoped>
</style>
