<template>
  <div class="row">
    <a href="" class="d-none download" download></a>
    <div class="col-xl-12">
      <div class="card">
        <div class="card-body">
          <div class="row mt-4">
            <!-- Search -->
            <div class="col-sm-12 col-md-8">
              <div id="tickets-table_filter" class="dataTables_filter text-md-start row">
                <label class="d-flex align-items-center col-md-3 col-12">
                  <b-form-select class="form-control form-control" ref="select-column-field"
                    v-model="filters.workflow">
                    <b-select-option :value="null">Selecione um workflow</b-select-option>
                    <b-select-option v-for="(workflow, workflowOptionKey) in workflows" :key="workflowOptionKey"
                      :value="workflow.id">{{ workflow.name }}</b-select-option>
                  </b-form-select>
                </label>
                <label class="d-flex align-items-center col-md-4 col-10">
                  <b-form-input v-model="filters.search" type="search" placeholder="Buscar..."
                    class="form-control form-control"></b-form-input>
                </label>
                <label class="d-flex align-items-center base-datepicker col-2 col-md-1">
                  <i class="bx bx-calendar fs-4 link-cursor ms-2" @click="showDatapicker = true" v-b-tooltip.hover="periodTooltip ? periodTooltip : 'Selecione um periodo'" ></i>
                  <DatePicker
                    :class="{ 'd-flex': showDatapicker, 'd-none': !showDatapicker }"
                    class="datepicker"
                    :lang="datePikerLang"
                    type="datetime"
                    format="DD/MM/YYYY HH:mm" value-type="YYYY-MM-DD H:mm:ss"
                    :range="true" :default-value="new Date()"
                    placeholder="00/00/0000 00:00:00"
                    :open="showDatapicker"
                    v-model="filters.period"
                    >
                    <template #footer>
                      <div class="row justify-content-between">
                        <div class="col-6 d-flex justify-content-start align-items-center">
                          <a href="#" class="btn btn-outline-info"
                            @click="filters.period = [], showDatapicker = false">Limpar</a>
                        </div>
                        <div class="col-6 d-flex justify-content-end align-items-center">
                          <a href="#" class="btn btn-success"
                            @click="showDatapicker = false">selecinar</a>
                        </div>
                      </div>
                    </template>
                  </DatePicker>
                </label>
                <label class="d-flex align-items-center ps-3 base-datepicker font-size-10 col-md-4 col-6" v-if="filters.period">{{ periodTooltip }}</label>
              </div>
            </div>
            <!-- Show per page -->
            <div class="col-sm-12 col-md-4">
              <div id="tickets-table_length" class="dataTables_length text-md-end">
                <label class="d-inline-flex align-items-center">
                  Mostrar&nbsp;
                  <b-form-select v-model="perPage" class="form-select form-select"
                    :options="pageOptions"></b-form-select>&nbsp;
                </label>
                <label class="d-inline-flex align-items-center">
                  <a href="#" class="btn btn-outline-info" @click.prevent="exportDashboard()" :class="{'disabled': exportLoad}">
                    <span v-show="!exportLoad">Exportar</span>
                    <span v-show="exportLoad"><i class="bx bx-loader-alt bx-spin" ></i></span></a>
                </label>
              </div>
            </div>

            <!-- End search -->
            <div class="table-responsive mb-0">
              <b-table id="user-tasks-table" class="datatables" :items="tableDataFiltred" :fields="fields" responsive="sm" :per-page="perPage"
                :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filters"
                :filter-included-fields="filterOn" @filtered="onFiltered" :filter-function="filterTable">
                <template v-slot:cell()="data" >
                <router-link :to="{ path: `workflow/user/view/${filters.workflow}/${data.item.id}` }" class="text-white link">
                   <span v-if="data.field.key != 'status'" class="font-size-12">{{ data.value ? data.value : '-' }}</span>
                   <span v-else class="badge font-size-12 status-width d-flex justify-content-center align-items-center" :class="[data.value? statusColor[getColorKey(data.value)]: '']">{{ data.value ? data.value : '-' }}</span>
                </router-link>
                </template>
            </b-table>
            <div class="col-12  d-flex align-items-center justify-content-center" v-if="!filters.workflow" ref="selectWorkflowButton">
                      <div class="card bg-card alert-height col-12">
                          <div class="card-body  d-flex align-items-center justify-content-center">
                              <h5>Selecione uma workflow</h5>
                          </div>
                      </div>
                  </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="user-tasks-table"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props:{
    userId:{
      default: 0,

    }
  },
  data() {
    return {
      tableData: {},
      statusColor: {},
      exportLoad: false,
      title: "Meus tickets",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", label: "Código", sortable: true, class: "text-center align-middle overflow-text"},
        { key: "task", label: "Tarefa", sortable: true, class: "text-center align-middle overflow-text"},
        { key: "responsible", label: "Responsável", sortable: true, class: "text-center align-middle overflow-text"},
        { key: "time", label: "Tempo da tarefa", sortable: true, class: "text-center align-middle overflow-text"},
        { key: "status", label: "Status", sortable: true, class: "text-center align-middle overflow-text"},
      ],
      workflows: [],
      workflowSelected: null,
      showDatapicker: false,
      filters: {
        workflow: null,
        search: '',
        period: []
      },
    }
  },
  computed:{
    periodTooltip(){
      if(this.filters.period.length == 2) {
        let dateStart = this.getDateFormat(this.filters.period[0]);
        let dateEnd = this.getDateFormat(this.filters.period[1]);
        return `De ${dateStart} à ${dateEnd}`
      }
      return ''
    },
    rows() {
        if(!this.filters.workflow) {
          return 0;
        }
      return this.tableDataFiltred.length;
    },
    tableDataFiltred(){
        if(!this.filters.workflow) {
          return [];
        }
        return this.tableData[this.filters.workflow];
    }
  },
  methods:{
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData(userId){
        let data = {user_id: userId};
        let response = await axios.post(window.location.origin+'/api/v1/user/workflows/tasks', data)
        .catch();
        response = response.data.data;

        let keys = Object.keys(response)
        // let keys = Object.keys(this.response)
        this.workflows = [];
        keys.forEach((key, index)=>{
            let content = response[key]
            this.filters.workflow = content.id;
            let workflow = { id: content.id, name: content.name };
            this.workflows.push(workflow)

            this.$set(this.tableData, key, []);
           content.tasks.forEach((taskData)=>{
               let task = {
                  id: taskData.id,
                  task: taskData.name,
                  responsible: taskData.responsible,
                  time: taskData.time,
                  created_at: taskData.created_at,
                  status: taskData.status.name
               };

               this.$set(this.statusColor, taskData.status.name.replace(' ', '_'), taskData.status.color);
               this.tableData[key].push(task);

           });

        });
    },
    filterTable(data, filters){
        if(filters.period.length == 2){
            let dateStart = new Date(filters.period[0]);
            let dateEnd = new Date(filters.period[1]);
            let dateElement = new Date(data.created_at);
            if(dateElement < dateStart || dateElement > dateEnd) {
              return false
            }
          }

            var keys = Object.keys(data);
            try {
                var response = false;
                keys.forEach((keyObject)=>{
                    if(typeof data[keyObject] === 'string' || data[keyObject] instanceof String){
                        if(data[keyObject].toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(filters.search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) != -1){
                            response = true;
                        }
                    } else {
                        if(data[keyObject] == filters.search.toLowerCase()){
                            response = true;
                        }
                    }
                });
                return response;
            } catch (error) {
                console.log(error);
            }
    },
    async exportDashboard() {
        try{
            this.exportLoad = true;
            let data = {
                workflow_id: this.filters.workflow,
                search: this.filters.search,
                period: this.filters.period,
            };
            let response = await axios.post(window.location.origin+'/api/v1/export/user/dashboard', data)
            var fileLink = document.querySelector('a.download');
            fileLink.href = response.data.data.url;
            fileLink.setAttribute('download', response.data.data.file_name);
            fileLink.setAttribute('target', "_blank");
            fileLink.click();
            this.exportLoad = false;
        } catch(error) {
            this.exportLoad = false;
            console.log(error);
        }
    },
    getColorKey(value) {
        if(!value) {
            return ''
        }
        return value.replace(' ', '_')
    }
  },
  mounted(){
    if(this.userId) {
        this.getData(this.userId)
    }
  }
}
</script>

<style scoped>
.alert-height{
    height: 57vh !important;
    border: 2px dashed;
    border-radius: 10px;
}
</style>
<style>
table#user-tasks-table tbody td {
    padding-top: .2rem;
    padding-bottom: .2rem;
    max-width: 380px;
}
.datepicker .mx-input-wrapper input, .datepicker .mx-input-wrapper .mx-icon-calendar {
    visibility: hidden;
}
.datepicker .mx-input-wrapper {
  width: 1px;
  height: 1px;
}
.datepicker {
  border: none;
}
.status-width {
    width: 100%;
    height: 30px;
}
</style>


