<template>
  <div class="row" v-if="advancedFiltersData.length > 0">
    <div
      class="col d-flex justify-content-start align-items-center py-1 rem-size-2"
    >
      <span
        class="filter-tag mb-0"
        v-for="(filter, filterKey) in advancedFiltersData"
        :key="filterKey"
      >
        <span v-if="filter.column">
          <span>{{ filter.column.name }} </span>
          <i
            class="filter-tag-icon mdi mdi-close-thick"
            @click="
              removeFilterData({
                index: filterKey,
                id: typeof filter.id != undefined && typeof filter.id != 'undefined'
                  ? filter.id
                  : null
              }),
              setFilter(mergeFilter)
            "
          ></i
        ></span>
      </span>
    </div>
  </div>
</template>

<script>
import { filtersComputed, filtersMethods } from "../state/helpers";
import { mapState, mapActions } from "vuex";

export default {
    data() {
        return {
            data: []
        }
    },
    computed:{
        ...filtersComputed,
        ...mapState("tasks", {
            globalFilter: (state) => state.filter,
        }),
        mergeFilter() {
            let filter = JSON.parse(JSON.stringify(this.globalFilter));
            filter.workflowId = filter.workflow_id;
            filter.orderBy = filter.order_by;
            filter.orderDesc = filter.order_desc;
            filter.advanced = this.advancedFiltersData;
            return filter;
        },
    },
    methods: {
        ...filtersMethods,
        ...mapActions("tasks", ["setFilter"]),
    }
};
</script>

<style lang="scss" scoped>
</style>
