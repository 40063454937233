<template>
  <div class="row">
    <div class="col-xl-12">
      <div
        class="card"
        :class="{
          'dahboard-card-border-radius border-start border-end border-bottom':
            isDashboard,
        }"
      >
        <div class="card-body">
          <h5 class="text-start" v-show="showTitle">Produtividade</h5>
          <div class="row mb-3">
            <!-- Workflows -->
            <b-form-group
              label="Selecione um Workflow"
              label-for="aasp-list-show"
              class="mb-2 mt-2 col-12 col-md-3 col-lg-3 col-xl-2"
              @submit.stop.prevent
            >
              <b-form-select
                v-model="localWorkflow"
                @change="getData($event)"
                id="aasp-list-show"
                name="aasp-list-show"
                class="form-select form-select font-size-12"
                size="sm"
              >
                <b-form-select-option
                  v-for="(workflow, workflowKey) in workflowsWithDateStart"
                  :key="workflowKey"
                  :value="workflow"
                  >{{ workflow.name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>

            <!-- Users -->
            <b-form-group
              label="Selecione um usuário"
              label-for="list-workflows-show"
              class="mb-2 mt-2 col-12 col-md-3 col-lg-3 col-xl-2"
              @submit.stop.prevent
              v-if="user && user.user_type_id == 1"
            >
              <b-form-select
                v-model="workflowUserId"
                @change="getData(localWorkflow, false)"
                id="list-workflows-show"
                name="list-workflows-show"
                class="form-select form-select font-size-12"
                size="sm"
              >
                <template #first>
                  <b-form-select-option :value="0">Todos</b-form-select-option>
                </template>
                <b-form-select-option
                  v-for="user in usersList"
                  :key="user.id"
                  :value="user.id"
                  >{{ user.full_name }}</b-form-select-option
                >
              </b-form-select>
            </b-form-group>


            <!-- periods -->
            <b-form-group
              label="Período"
              label-for="aasp-list-period"
              class="mb-2 mt-2 col-12 col-md col-lg-3 col-xl-2"

            >
              <b-form-select
                v-model="periodType"
                class="form-select col-12 font-size-12"
                size="sm"
                id="periods"
                @change="selectPeriod($event, localWorkflow)"
              >
                <b-form-select-option
                  v-for="(period, periodKey) in periods"
                  :key="periodKey"
                  :value="period"
                  >{{ period != 0 ? period : "Personalizado" }}
                  {{ period != 0 ? "dias" : "" }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>

            <!-- period -->
            <b-form-group
              label="Período"
              label-for="aasp-list-period"
              class="mb-2 mt-2 col-md col-12 hide-label-periods"
              @submit.stop.prevent
              v-if="periodType == 0"
            >
              <DatePicker
                id="deadline-datetimepicker"
                class="font-size-12"
                v-model.lazy="period"
                :lang="datePikerLang"
                type="date"
                format="DD/MM/YYYY"
                value-type="YYYY-MM-DD H:mm:ss"
                :range="true"
                :default-value="new Date()"
                placeholder="00/00/0000 00:00:00"
                :disabled-date="disablePeriodLimit"
                @close="getData(localWorkflow, period)"
                @pick="setDatePeriodStart"
                :clearable="false"
                size="sm"
              ></DatePicker>
            </b-form-group>

            <div
              v-if="periodType != 0"
              class="d-none d-xl-block d-xxl-block d-lg-block d-md-block col-md"
            ></div>
            <b-form-group
              label="_"
              class="mb-2 mt-2 col-12 col-md-1 col-lg-1 col-xl-1 hide-label p-sm-0 px-3 text-center"
              @submit.stop.prevent
            >
              <label class="d-inline-flex align-items-center">
                <a
                  href="#"
                  class="btn btn-outline-info btn-sm font-size-12"
                  @click.prevent="exportChart()"
                  :class="{ disabled: exportLoad }"
                >
                  <span v-show="!exportLoad">Exportar</span>
                  <span v-show="exportLoad"
                    ><i class="bx bx-loader-alt bx-spin"></i></span
                ></a>
              </label>
            </b-form-group>
          </div>
          <div class="row">
            <div class="d-inline-flex justify-content-end pe-4">
              <div
                :class="{ 'pe-2': datasetKey + 1 != chartData.datasets.length }"
                v-for="(dataset, datasetKey) in chartData.datasets"
                :key="datasetKey"
                @click="selectChartLabel(datasetKey)"
              >
                <span
                  class="d-flex align-items-center text-white rem-size-2 link-cursor"
                >
                  <div
                    :style="`background-color: ${dataset.backgroundColor}`"
                    class="chart-label-indicator me-1"
                  ></div>
                  <span
                    :class="{
                      'text-decoration-line-through':
                        chartLabelSelected.indexOf(datasetKey) != -1,
                    }"
                    >{{ dataset.label }}</span
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <BarChart
              ref="bar-chart-productivity"
              :labels="chartData.labels"
              :datasets="chartDatasets"
              :responsive="true"
              :maintainAspectRatio="false"
              :showLegend="false"
              legendPosition="top"
              legendAlign="end"
              :indicatorMin="metrics.min"
              :indicatorAvg="metrics.avg"
              :indicatorMax="metrics.max"
              v-if="!loadList"
            />
            <span v-else class="text-center">
              <i class="bx bx-loader-alt bx-spin fs-1"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Bar } from "vue-chartjs";
import { mapActions, mapState } from "vuex";
import { workflowsComputed, workflowsMethods } from "../../../state/helpers";
import BarChart from "../../charts/BarChart";

export default {
  components: { BarChart },
  data() {
    return {
      chartLabelSelected: [],
      loadList: false,
      exportLoad: false,
      localWorkflow: null,
      workflowUserId: 0,
      metrics: {
        column: null,
        min: null,
        avg: null,
        max: null,
      },
      chartData: {
        fontColor: "#fff",
        labels: [],
        datasets: [],
        scales: null,
      },
      periodRangeLimitInDays: 365,
      startPeriodDate: null,
      period: null,
      periods: [30, 60, 90, 0],
      periodType: 30,
    };
  },
  props: {
    showTitle: {
      default: true,
    },
    isDashboard: {
      default: false,
    },
  },
  computed: {
    ...workflowsComputed,
    ...mapState("usersOptions", {
      usersList: (state) => state.usersList,
    }),
    ...mapState("user", {
      user: (state) => state.user,
    }),
    chartDatasets() {
      return this.chartData.datasets.filter((dataset, key) => {
        return this.chartLabelSelected.indexOf(key) == -1;
      });
    },
    workflowsWithDateStart() {
      return this.workflows.filter(
        (workflow) => workflow.has_date_start_column
      );
    },
  },
  methods: {
    ...workflowsMethods,
    ...mapActions("usersOptions", ["getUsersList"]),
    getData(workflow, getUser = true) {
      if (!workflow) return;

      if (getUser) {
        this.getUsersList(workflow.id);
      }

      this.loadList = true;

      this.metrics.min = workflow.metric_min;
      this.metrics.avg = workflow.metric_avg;
      this.metrics.max = workflow.metric_max;

      let data = {
        workflow_id: this.localWorkflow.id,
        workflow_user_id: this.workflowUserId,
        period: this.period,
        metric_column_id: workflow.metric_column_id,
      };
      axios
        .post(`${window.location.origin}/api/v1/dashboard/productivity`, data)
        .then((response) => {
          if (!response.data.error) {
            let data = response.data.data;
            this.chartData.labels = data.labels;
            this.chartData.labels = data.labels;
            this.chartData.datasets = [];
            data.datasets.forEach((dataset) => {
              this.chartData.datasets.push({
                label: dataset.name,
                backgroundColor: dataset.color,
                borderColor: dataset.color,
                data: dataset.data,
              });
            });
          }
          setTimeout(() => {
            this.loadList = false;
          }, 100);
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
    selectChartLabel(index) {
      if (this.chartLabelSelected.indexOf(index) != -1) {
        this.chartLabelSelected.splice(
          this.chartLabelSelected.indexOf(index),
          1
        );
        return;
      }

      this.chartLabelSelected.push(index);
    },
    async exportChart() {
      try {
        this.exportLoad = true;
        let element = this.$refs["bar-chart-productivity"];
        if (element) {
          let chart = element._data._chart;
          if (chart) {
            let image = chart.toBase64Image();
            image = await this.changeBackgroundColor(image, "#2a3042");
            if (image) {
              var a = document.createElement("a");
              a.href = image;
              a.download = `Produtividade_${this.localWorkflow.name}.png`;
              a.click();
            }
          }
        }
        setTimeout(() => {
          this.exportLoad = false;
        }, 100);
      } catch (error) {
        setTimeout(() => {
          this.exportLoad = false;
        }, 100);
      }
    },
    async changeBackgroundColor(base64Image, backgroundColor) {
      return await new Promise((resolve, reject) => {
        const img = new Image();
        img.src = base64Image;
        const margin = 20;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          canvas.width = img.width + 2 * margin;
          canvas.height = img.height + 2 * margin;

          ctx.fillStyle = backgroundColor;
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          const x = (canvas.width - img.width) / 2;
          const y = (canvas.height - img.height) / 2;

          ctx.drawImage(img, x, y);

          const newBase64Image = canvas.toDataURL("image/png");
          resolve(newBase64Image);
        };

        img.onerror = (err) => {
          reject(err);
        };
      });
    },
    selectPeriod(period, worflow) {
      if (period == 0) {
        this.period = null;
        return;
      }

      let date = new Date();
      let dateEnd = this.formatDate(date);

      date = new Date(date.setDate(date.getDate() - period));

      let dateStart = this.formatDate(date);
      this.period = [dateStart, dateEnd];

      this.getData(worflow, this.period);
    },
    formatDate(date) {
      return `${date.getFullYear()}-${this.pad(date.getMonth() + 1)}-${this.pad(
        date.getDate()
      )} 00:00:00`;
    },
    pad(valor) {
      return valor.toString().padStart(2, "0");
    },
    disablePeriodLimit(date) {
      let today = new Date();
      if (!this.startPeriodDate) {
        return date > today;
      }

      let upStarDate = new Date(this.startPeriodDate);

      let upLimitDate = new Date(
        upStarDate.setDate(upStarDate.getDate() + this.periodRangeLimitInDays)
      );

      let downStarDate = new Date(this.startPeriodDate);

      let downLimitDate = new Date(
        downStarDate.setDate(downStarDate.getDate() - this.periodRangeLimitInDays)
      );

      return date > upLimitDate || date < downLimitDate || date > today

    },
    setDatePeriodStart(evt) {
      this.startPeriodDate = evt;
    },
  },
  beforeMount() {
    this.selectPeriod(this.periodType);
    this.listWorkflows();
    if (this.workflowsWithDateStart.length > 0) {
      this.localWorkflow = this.workflowsWithDateStart[0];
    }

    this.$watch(
      () => this.workflowsWithDateStart,
      (att, old) => {
        if (!this.localWorkflow && att.length > 0) {
          this.localWorkflow = att[0];
        }
      }
    );

    this.getData(this.localWorkflow);

  },
  watch: {
    chartDatasets(att) {
      let element = this.$refs["bar-chart-productivity"];
      if (element) {
        this.loadList = true;
        setTimeout(() => {
          this.loadList = false;
        }, 200);
      }
    },
  },
};
</script>

<style>
.chart-label-indicator {
  width: 14.5px;
  height: 12px;
}
.hide-label-periods label {
  visibility: hidden;
}
</style>
