<template>
   <!-- v-tooltip:top="`#${task.id} - ${task.name}`" -->
  <div class="base d-flex px-1"  data-bs-toggle="tooltip" data-bs-html="true" :title="`#${task.id} - ${task.name}`">
    <span
      @click="toggleShow(true)"
      class="title-label form-control-plaintext cell-title cell-input overflow-text text-white-solid p-0 ps-1 align-items-center cell-height d-flex rem-size-2"
    ></span>
    <b-input
      @keyup.enter="$event.target.blur()"
      @blur="save()"
      @keyup.esc="cancel"
      type="text"
      :disabled="disabled"
      maxlength="255"
      placeholder="-"
      class="title-input form-control cell-input cell-edit p-0 ps-1 cell-height text-start d-none rem-size-2"
      :value="task.name"
      v-once
    ></b-input>
    <div style=" top: 0; bottom: 0; margin-top: auto; margin-bottom: auto; height: 30px;background: transparent;"
      class="col-1 d-flex align-items-center justify-content-end left-freez bg-default cell-height">
      <span v-if="task.subtasks.length > 0" class="d-flex align-items-center bg-default">
        <b-button
          @click="task.show_subtasks != 'undefined' ? $set(task, 'show_subtasks', !task.show_subtasks) : $set(task, 'show_subtasks', true)"
          right
          variant="btn-edit text-decoration-none fs-6 text-secondary p-0 pe-1 ps-1 d-flex align-items-center justify-content-center">
          <span class="badge bg-secondary text-white rounded-pill rem-size-1"
            style="color: #222736 !important;">{{
              task.subtasks.length
            }}</span>
        </b-button>
      </span>
      <span v-if="typeof user.unread_notifications_by_task === 'object'" class="d-flex align-items-center bg-default" >
        <b-button
          @click="openDrawer(task, columnValue)"
          right
          v-if="(typeof user.unread_notifications_by_task[task.id] !== 'undefined' && user.unread_notifications_by_task[task.id].length > 0)"
          variant="btn-edit text-decoration-none fs-6 text-secondary p-0 pe-1 ps-1 d-flex align-items-center justify-content-center">
          <span class="badge bg-primary text-white rounded-pill rem-size-1">{{
              user.unread_notifications_by_task[task.id].length
            }}</span>
        </b-button>
      </span>
      <!-- <span class="d-flex align-items-center bg-default">
        <i
           class="bx bx-link-alt opacity-hover link-cursor pe-1 link-cursor font-size-15"
           :class="{'text-success': copying}"
           v-b-tooltip.top="!copying ? `${localeOrigin}/workflow/show/${task.workflow_id}/${task.id}` : 'Copiado!'"
           @click.prevent="copyToClipboard(`${localeOrigin}/workflow/show/${task.workflow_id}/${task.id}`)"
         ></i>
      </span> -->
      <!-- v-b-tooltip.hover  -->
      <span :title="!task.description? 'Clique e adicione uma descrição' : 'Atividades'" class="d-flex align-items-center bg-default"
         ref="descriptionsalert">
         <b-button :ref="`show_task_${task.id}`" @click="openDrawer(task, columnValue)" right
           variant="btn-edit text-decoration-none fs-6 text-secondary p-0 d-flex align-items-center justify-content-center">
           <i class="bx bxs-chat rem-size-2" :class="{'text-warning-09': !task.description}"></i>
         </b-button>
       </span>

    </div>
  </div>
</template>

  <script>
import config from "../../../config/config";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      backupValue: "",
      value: "",
    };
  },
  props: {
    column: {
      default: {},
    },
    task: {
      default: {},
    },
    columnValue: {
      default: {},
    },
    user: {
      default: {},
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    ...mapActions('columnValues', ['updateColumnValue']),
    ...mapActions('tasks', ['updateTaskColumnValue']),
    cancel(e) {
      let base = this.$el;

      if (base) {
        let span = base.querySelector(".title-label");
        let input = base.querySelector(".title-input");
        input.value = this.backupValue;
        span.textContent = this.backupValue;
        span.classList.remove("d-none");
        span.classList.add("d-flex");
        input.classList.remove("d-flex");
        input.classList.add("d-none");
      }
    },
    toggleShow(showInput = false) {
      let base = this.$el;

      if (base) {
        let input = base.querySelector(".title-input");
        let span = base.querySelector(".title-label");
        if (showInput) {
          span.classList.remove("d-flex");
          span.classList.add("d-none");
          input.classList.remove("d-none");
          input.classList.add("d-flex");
          this.backupValue = input.value;
          input.focus();
        } else {
          span.classList.remove("d-none");
          span.classList.add("d-flex");
          input.classList.remove("d-flex");
          input.classList.add("d-none");
          span.textContent = input.value;
        }
      }
    },
    save() {
      let base = this.$el;
      if (base) {
        let input = base.querySelector(".title-input");
        if (input) {
            let value = input.value;
            let data = {
                group_id: this.task.workflow_group_id,
                task_id: this.task.id,
                column_type_id: this.column.workflow_column_type_id,
                column_value_id: this.columnValue.id,
                value: value,
                description: '',
            }
            this.updateColumnValue(data);
            this.updateTaskColumnValue(data);
        }
      }
      this.toggleShow();
    },
    openDrawer(task, columnValue) {
        this.$emit('openDrawer', task, columnValue);
    }
  },
  mounted() {
    this.backupValue = this.task.name;
    let input = this.$el.querySelector(".title-input");
    let span = this.$el.querySelector(".title-label");

    if (span) {
      span.textContent = this.task.name;
    }

    if (input) {
      input.value = this.task.name;
    }

  },
  beforeDestroy () {
    this.backupValue = "";
    this.value = "";
    this.$el.parentNode.removeChild(this.$el);
  }
};
</script>

  <style lang="scss" scoped>
.text-white-solid {
  color: white !important;
  opacity: 1;
}
</style>
