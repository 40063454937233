<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props:{
    labels: {
        type: Array,
        default: []
    },
    datasets: {
        type: Array,
        default: []
    },
    maintainAspectRatio:{
        type: Boolean,
        default: true
    },
    responsive: {
        type: Boolean,
        default: true
    },
  },
  mounted() {
    this.renderChart(
      {
        labels: this.labels, // ex: ['Desktops', 'Tablets']
        datasets: this.datasets,
        // Ex:
        // [
        //   {
        //     data: [300, 180],
        //     backgroundColor: ['#34c38f', '#ebeff2'],
        //     hoverBackgroundColor: ['#34c38f', '#ebeff2'],
        //     hoverBorderColor: '#fff',
        //   },
        // ]
      },
      {
        maintainAspectRatio: this.maintainAspectRatio,
        responsive: this.responsive,
        legend: {
            display: true,
            position: 'left',
            labels:{
                boxWidth: 15,
                fontColor: '#fff',
            }
        },
      }
    )
  },
}
</script>
