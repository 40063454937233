import axios from 'axios'
import Vue from 'vue';
export const state = {
    user: {},
    users: [],
    workflowUsers: [],
    usersLoad: {
        search: false,
    },
    userLoad: {
        user: false,
        notification: false,
        readAll: false
    }
}

export const mutations = {
    CHANGE_USER(state, value) {
        state.user = value;
    },
    CHANGE_USERS(state, value) {
        state.users = value;
    },
    CHANGE_NOTIFICATIONS(state, value) {
        state.user.notifications = value;
    },
    ADD_NOTIFICATIONS(state, value) {
        state.user.notifications.data.unshift(value);

        if (value.read) {
            state.user.unread_notifications = state.user.unread_notifications > 0 ? state.user.unread_notifications - 1 : state.user.unread_notifications;
        } else {
            state.user.unread_notifications = state.user.unread_notifications + 1;
        }

        if(value.workflow_task_id) {
            if(typeof state.user !== 'object') {
                state['user'] = {};
            }
            if(typeof state.user.unread_notifications_by_task !== 'object') {
                state.user['unread_notifications_by_task'] = {};
            }

            if(typeof state.user.unread_notifications_by_task[value.workflow_task_id] !== 'undefined') {
                state.user.unread_notifications_by_task[value.workflow_task_id].push(value);
            } else {
                Vue.set(state.user.unread_notifications_by_task, value.workflow_task_id, []);
                state.user.unread_notifications_by_task[value.workflow_task_id].push(value);
            }
        }
    },
    UPDATE_NOTIFICATIONS(state, value) {
        state.user.notifications.current_page = value.current_page;
        state.user.notifications.last_page = value.last_page;
        state.user.notifications.next_page_url = value.next_page_url;
        state.user.notifications.per_page = value.per_page;
        value.data.forEach(dataValue => {
            state.user.notifications.data.push(dataValue);
        });
    },
    UPDATE_NOTIFICATION(state, notification) {
        let oldNotification = state.user.notifications.data.find((oldNotification) => oldNotification.uuid == notification.uuid);
        let keys = Object.keys(oldNotification);
        keys.forEach((key) => {
            oldNotification[key] = notification[key];
        })

        if (oldNotification.read) {
            state.user.unread_notifications = state.user.unread_notifications > 0 ? state.user.unread_notifications - 1 : state.user.unread_notifications;
        } else {
            state.user.unread_notifications = state.user.unread_notifications + 1;
        }
    },
    CHANGE_LOAD_USER(state, value) {
        state.userLoad.user = value;
    },
    CHANGE_LOAD_NOTIFICATION(state, value) {
        state.userLoad.notification = value;
    },
    CHANGE_LOAD_READ_ALL(state, value) {
        state.userLoad.readAll = value;
    },
    CHANGE_USERS_LOAD_SEARCH(state, value) {
        state.usersLoad.search = value;
    },
    RESET_UNREAD_COUNTER(state, notifications){
        state.user.unread_notifications = 0;

    },
    CLEAR_UNREAD_NOTIFICATIONS(state){
        Vue.set(state.user, 'unread_notifications_by_task', {});
    },
    CHANGE_MUTE_NOTIFICATIONS(state, value) {
        state.user.mute_notifications = value;
    },
    CHANGE_MARK_AS_READ_BY_TASK(state, value) {
        let read_notifications_ids = value.notifications.map((notification)=>  notification.id);

        if(typeof state.user.unread_notifications_by_task !== 'object') {
            state.user['unread_notifications_by_task'] = {};
        }

        if(typeof state.user.unread_notifications_by_task[value.workflow_task_id] !== 'undefined') {
            state.user.unread_notifications_by_task[value.workflow_task_id] =
                state.user.unread_notifications_by_task[value.workflow_task_id]
                    .filter((notification)=> read_notifications_ids.indexOf(notification.id) == -1)
        }

        if(typeof state.user.notifications.data !== 'undefined') {
            state.user.notifications.data.forEach((notification)=>{
                if(read_notifications_ids.indexOf(notification.id) != -1) {
                    notification.read = true;
               }
            });

            let count = state.user.unread_notifications - read_notifications_ids.length;
            state.user.unread_notifications =
                state.user.unread_notifications > 0
                    ? (count >= 0 ? count : 0)
                    : state.user.unread_notifications;
        }

    }
}

export const getters = {
    typeId(state) {
        return state.user.user_type_id
    },
    getNotificationsUnreadByTask: (state) => (taskId) => {

        if(typeof state.user.unread_notifications_by_task === 'object') {
            if(typeof state.user.unread_notifications_by_task[taskId] !== 'undefined') {
                var tasks = state.user.unread_notifications_by_task[taskId]
                    .filter((notification)=> taskId == notification.workflow_task_id);
                return tasks.length;
            }
        }

        return 0;
    },
    checkUserTaskPermissions: (state) => (task) => {
        if(!state.user) {
            return false
        }

        if(state.user.user_type_id == 1 || state.user.user_type_id == 2) {
            return true
        }

        if(state.user.user_type_id == 4) {
            let isResponsible = !!task.responsibles.find((responsible) => responsible.user_id == state.user.id);
            let isCollaborator = !!task.collaborators.find((collaborator)=> collaborator.user_id == state.user.id);
            let isOwner = task.user_creator ? task.user_creator.id == state.user.id : false

            return isResponsible || isCollaborator || isOwner;
        }

        return false
    }
}

export const actions = {
    getUser({ commit, dispatch }) {
        axios.post(window.location.origin + '/api/v1/user')
            .then((response) => {

                const user = response.data;
                commit('CHANGE_USER', user);
                dispatch('getNotifications');
            });
    },
    getUsers:  function getUsers({ commit }, {search, workflowId}) {
        commit('CHANGE_USERS_LOAD_SEARCH', true);
        clearTimeout(getUsers.timeout);

        getUsers.timeout = setTimeout(()=>{
            var data = { search: search, workflow_id: workflowId }
            axios.post( window.location.origin + "/api/v1/workflow/search/users", data)
                .then((response) => {
                    const users = response.data.data;
                    commit('CHANGE_USERS', users);
                    commit('CHANGE_USERS_LOAD_SEARCH', false);
                });

        }, 1000);
    },
    getNotifications({ commit, state }) {
        axios.post(window.location.origin + '/api/v1/notification/user', { user_id: state.user.id, per_page: state.user.per_page })
            .then((response) => {
                let data = response.data.data;
                let notifications = {};

                notifications.current_page = data.current_page;
                notifications.last_page = data.last_page;
                notifications.next_page_url = data.next_page_url;
                notifications.data = data.data;
                notifications.per_page = data.per_page;

                commit('CHANGE_NOTIFICATIONS', notifications);
            });
    },
    getMoreNotifications({ commit, dispatch, state }) {
        if (typeof state.user.notifications == 'undefined') {
            dispatch('getUser');
            return;
        }
        dispatch('changeLoadNotification', true)
        axios.post(state.user.notifications.next_page_url, { user_id: state.user.id, per_page: state.user.per_page })
            .then((response) => {
                let data = response.data.data;
                let notifications = {};

                notifications.current_page = data.current_page;
                notifications.last_page = data.last_page;
                notifications.next_page_url = data.next_page_url;
                notifications.data = data.data;
                notifications.per_page = data.per_page;

                commit('UPDATE_NOTIFICATIONS', notifications);

            }).finally(() => {
                dispatch('changeLoadNotification', false)
            });
    },
    updateNotification({ commit, state }, notification) {
        let oldNotification = state.user.notifications.data.find((oldNotification) => oldNotification.uuid == notification.uuid);
        commit('UPDATE_NOTIFICATION', notification);

        axios.patch(window.location.origin + '/api/v1/notification', notification)
            .catch((error) => {
                commit('UPDATE_NOTIFICATION', oldNotification);
            })
    },
    async markAllNotificationsAsRead({commit, dispatch, state}) {
        try{
            commit('CHANGE_LOAD_READ_ALL', true);
            let response = await axios.post(window.location.origin + '/api/v1/notification/user/read/all', { user_id: state.user.id })
            commit('CHANGE_LOAD_READ_ALL', false);
            commit('RESET_UNREAD_COUNTER');
            dispatch('getNotifications');
            commit('CLEAR_UNREAD_NOTIFICATIONS');

        } catch(error) {
            commit('CHANGE_LOAD_READ_ALL', false);
        }
    },
    markNotificationsAsReadByTask({commit}, taskId) {
        axios.post(
            window.location.origin + '/api/v1/notification/user/read/task',
            {workflow_task_id: taskId}
        )
        .then((response) => {
            let notifications = response.data.data;
            let data = {
                workflow_task_id: taskId,
                notifications: notifications
            };

            commit('CHANGE_MARK_AS_READ_BY_TASK', data)
        })
        .catch((error)=>{
            let data = {
                workflow_task_id: taskId,
                notifications: []
            };
            commit('CHANGE_MARK_AS_READ_BY_TASK', data)
        })
    },
    addNotification({ commit }, notification) {
        commit('ADD_NOTIFICATIONS', notification);
    },
    changeLoadNotification({ commit }, value) {
        commit('CHANGE_LOAD_NOTIFICATION', value)
    },
    changeLoadUser({ commit }, value) {
        commit('CHANGE_LOAD_USER', value)
    },
    async muteNotifications({ commit, dispatch }, mute) {
        try {
            commit('CHANGE_MUTE_NOTIFICATIONS', mute);
            await axios.post(window.location.origin + '/api/v1/user/mute/notifications', { mute: mute });
            dispatch('getUser');
        } catch(error) {
            commit('CHANGE_MUTE_NOTIFICATIONS', !mute);
        }
    },
    playNotificationAudio({state}) {
        if(!state.user.mute_notifications) {
            let notificationAudio = new Audio();
            notificationAudio.src = '/audios/notification.mp3';
            notificationAudio.play();
        }
    }
}
