<template>
    <div>
        <div class="row p-4">
            <div class="col-12 col-md-12 p-0">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-11">
                                <b-form-group class="form-text" name="workflow-id" label="Nome do dashboard" label-for="search">
                                    <b-input-group >
                                        <b-input class="font-size-12 form-control" size="sm" v-model="form.name" name="name" id="search" placeholder="Nome do dashboard" :class="{ 'is-invalid': submitted && $v.form.name.$error }"></b-input>
                                        <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
                                            <span v-if="!$v.form.name.required">Insira um nome</span>
                                            <span v-else-if="!$v.form.name.maxLength">O nome deve conter no máximo 25 caracteres</span>
                                            <span v-else-if="!$v.form.name.minLength">O nome deve conter no mínimo 3 caracteres</span>
                                        </div>
                                    </b-input-group>
                                </b-form-group>
                            </div>
                            <div class="col-1">
                                <b-form-group class="form-text" name="workflow-id" label="Padrão" label-for="default">
                                    <b-input-group class="check-default-container d-flex align-items-center b-custom-control-sm">
                                        <div class="form-check form-check-primary">
                                            <input
                                                class="form-check-input check-default"
                                                type="checkbox"
                                                id="default"
                                                :checked="form.default"
                                                v-model="form.default"
                                            />
                                        </div>
                                    </b-input-group>
                                </b-form-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <b-input-group label="Nome do dashboard">
                                </b-input-group>
                                <b-form-group class="form-text" name="workflow-id" label="Selecine um workflow" label-for="workflows">
                                    <b-select
                                    class="form-control font-size-12" id="workflows" name="workflows" ref="workflows" size="sm" v-model="form.workflowId" @change="getWorkflowColumns"
                                    :class="{ 'is-invalid': submitted && $v.form.workflowId.$error }"
                                    >
                                        <b-select-option v-for="(workflow, workflowKey) in options.workflows" :key="workflowKey" :value="workflow.id">{{ workflow.name }}</b-select-option>
                                    </b-select>
                                    <span
                                        v-if="!$v.form.workflowId.required && $v.form.workflowId.$error"
                                        class="invalid-feedback"
                                        >Selecione um workflow</span>
                              </b-form-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-12">
                <div class="row">
                    <div class="col-12  d-flex align-items-center justify-content-center" v-if="options.columns.length < 1" @click="showWorkflows" ref="selectWorkflowButton">
                        <div class="card bg-card alert-height col-12">
                            <div class="card-body  d-flex align-items-center justify-content-center">
                                <h5>Selecione um workflow</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" v-for="(column, columnKey) in options.columns" :key="columnKey" @click="toggleColumn(column)">

                            <div class="card bg-card" :key="column.id"  v-if="!column.selected">
                                <div class="card-body d-flex align-items-center justify-content-center" :class="{'blocked': form.columns.length == 7}">
                                    {{ column.name }}
                                </div>
                            </div>


                        <transition-group name="toggle" v-else>
                            <div  class="card bg-card-selected"  :key="column.id">
                                <div class="card-body  d-flex align-items-center justify-content-center">
                                    {{ column.name }}
                                </div>
                            </div>
                        </transition-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 justify-content-center align-items-center">
                <button class="btn btn-primary col-12 btn-sm"  @click.prevent="saveDashboard">
                    Salvar
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { required, minLength, maxLength} from "vuelidate/lib/validators";
import showAlert from "../../alerts.js";
import axios from "axios";

export default {
    data(){
        return {
            title: 'Dashboard',
            submitted: false,
            options:{
                workflows: [
                    {id: null, name: 'Selecione'}
                ],
                columns: [],
            },
            form:{
                id: null,
                name: '',
                workflowId: null,
                default: false,
                columns: []
            }
        }
    },
    props:{
        edit:{
            type: Boolean,
            default: false
        },
        id:{
            type: [Number, null],
            default: null
        }
    },
    validations: {
      form:{
          name:{
              required,
              minLength: minLength(3),
              maxLength: maxLength(25)
          },
          workflowId:{
              required,
          }

      }
    },
    methods: {
        toggleColumn(column){
            var findColumn = false;
            if(this.form.columns.length > 0){
                findColumn = this.form.columns.find((formColumn, key)=>{ return formColumn.id == column.id});
                findColumn = findColumn === undefined || findColumn === 'undefined'? false : true;
            }

            if(findColumn){
                this.$set(this.form, 'columns',  this.form.columns.filter((formColumn, key)=>{ return formColumn.id != column.id}));
                this.toggleColumnStatus(column.id);
            } else {
                if(this.form.columns.length < 7){
                    this.form.columns.push(column)
                    this.toggleColumnStatus(column.id);
                }
            }

            // pulse effect cancel
            var cards = this.$el.querySelectorAll('.bx-flashing');
            for (let i = 0; i < cards.length; i++) {
                cards[i].classList.remove('bx-flashing');
            }


        },
        toggleColumnStatus(columnId){
            var toggleColumnStatus = this.options.columns.find((formColumn, key)=>{ return formColumn.id == columnId});
            this.$set(toggleColumnStatus, 'selected', !toggleColumnStatus.selected );
        },

        // get options
        async getWorkflows(){
            const workflows = await axios.get('/api/v1/workflow');
            if(!workflows.data.error){
                var data = workflows.data.data;
                data.forEach(workflow => {
                    //{id: null, name: 'Selecione'},
                    this.options.workflows.push({id: workflow.id, name: workflow.name});
                });
            }
        },
        async getWorkflowColumns(workflowId, reset = true){
            if(reset){
                this.$set(this.options,'columns', []);
                this.$set(this.form,'columns', []);
            }
            if(workflowId){
                const workflowColumns = await axios.get('/api/v1/workflow/columns/'+workflowId);
                if(!workflowColumns.data.error){
                    var data = workflowColumns.data.data;
                    data.forEach(column => {
                        //{id: null, name: 'Selecione'},
                        if(column.workflow_column_type_id != 6 && column.workflow_column_type_id != 11){
                            this.options.columns.push({id: column.id, dashboardColumnId: 0, name: column.name, selected: false});
                        }
                    });
                    this.form.columns.forEach((column)=>{
                        var optionColumn = this.options.columns.find((option)=>{return option.id == column.id});
                        if(optionColumn){
                            this.$set(optionColumn, 'dashboardColumnId', column.id);
                            this.$set(optionColumn, 'selected', true);
                        }
                    });
                }
            }
        },
        saveDashboard(){
            this.$v.$touch();
            this.submitted = true;

            // pulse effect start
            if(this.form.columns.length < 1){
                var cards = this.$el.querySelectorAll('.bg-card');
                for (let i = 0; i < cards.length; i++) {
                    cards[i].classList.add('bx-flashing');
                }
                return;
            }
            if(!this.$v.$anyError){
                var columns = [];
                this.form.columns.forEach(column =>{
                    columns.push({id: column.id, dashboard_column_id: column.dashboardColumnId});
                });

                var data = {
                    dashboard_id: this.form.id,
                    name: this.form.name,
                    workflowId: this.form.workflowId,
                    default: this.form.default,
                    columns: columns,
                };

                const saveDashboard = axios
                .post(
                window.location.origin+'/api/v1/dashboard',
                data
                )
                .then((response) => {
                if (!response.data.error) {

                    showAlert(false, response.data.message);
                    this.$root.$emit('bv::hide::modal', 'modal-dashboard-edit')

                    this.form = {
                        name: '',
                        workflowId: null,
                        columns: []
                    }
                }
                })
                .catch((error) => {
                    if (error.response) {
                        showAlert(true, error.response.data.message);
                    }
                });
            }
        },
        showWorkflows(e){
            this.$refs['workflows'].focus();
        },
        getDashboard(id){
            const getDashboard = axios
              .get( "/api/v1/dashboard/find-columns/" + id)
              .then((response) => {
                if (!response.data.error) {
                    var data = response.data.data;
                    this.form.id = data.id
                    this.form.name = data.name
                    this.form.workflowId = data.workflow_id
                    this.form.default = data.default
                    this.$set(this.options,'columns', []);
                    data.columns.forEach((item) =>{
                        this.form.columns.push({dashboardColumnId: item.id, id: item.workflow_column_id, name: item.name, selected: true});
                    });
                    this.getWorkflowColumns(data.workflow_id, false);
                }
              })
              .catch((error) => {
                if (error.response) {
                  showAlert(true, error.response.data.message);
                }
              });
        }
    },
    mounted(){
        this.getWorkflows();

        if(this.edit){
            this.getDashboard(this.id)
        }
        this.$nextTick(() => {
            var selectWorkflowButton = this.$refs['selectWorkflowButton'];
            var selectWorkflowSection = this.$refs['workflows'].$el;

            selectWorkflowButton.addEventListener('mousedown', function(event){
                var evt = event
                setTimeout(()=>{
                    selectWorkflowSection.blur(evt)
                },100)
            });
        });
    }

}
</script>
<style lang="css" scoped>
.bg-card{
    min-height: 79px;
    background-color: #32394e;
    opacity: 0.8;
}

.bg-card:hover{
    background-color: #32394e;
    opacity: 1;
    color: white;
    cursor: pointer;
}
.bg-card-selected{
    min-height: 79px;
    background-color: #556ee6;
    color: white;
    opacity: 0.8;
}
.bg-card-selected:hover{
    color: white;
    cursor: pointer;
    opacity: 1;
}
.blocked {
    opacity: 0.5;

}
.blocked:hover {
    opacity: 0.5;
    cursor: not-allowed! important;
    color: #c3cbe4;
}
.alert-height{
    height: calc(100vh - 500px) !important;
    border: 2px dashed;
    border-radius: 10px;
}
/* Fade effect card */
.toggle-enter-active,
.toggle-leave-active {
  transition: opacity 0.5s ease;
}

.toggle-enter-from,
.toggle-leave-to {
  opacity: 0;
}
.check-default-container{
    height: 28px;
    padding-left: 3px;
}
.check-default{
    height: 17px;
    width: 17px;
}
</style>
