import fileImport from './file-import.vue'
import consult from './consult.vue'
import dashboard from './dashboard.vue'
import foldersList from './folders-list.vue'

const components = {
    fileImport,
    consult,
    dashboard,
    foldersList
}
export default components
