<template>
     <!-- BEGIN: Columns Headers -->
     <div class="row fw-bold border-start border-top border-bottom sticky-top headers-top-anchor header-colors ms-0" v-show="!hideAllTasks">
       <div
         class="col-1 p-0 left-freez  mt-0 mb-0 wdt-70 left-70 group-indicator-item-margin header-colors"
         :class="`border-task-${groupColor}`" v-show="!hideAllTasks" style="width: 84px; margin-left: -1px !important;">
         <div class="row justify-content-end h-100 cell-height align-items-center m-0">
           <!-- <div :class="[{ 'bg-secondary': !groupColor }, `bg-${groupColor}`]"
             class="group-indicator p-0 m-0 group-indicator-height-middle">
           </div> -->
           <!-- Group color indicator -->
           <div class="d-flex align-items-center cell-height">
               <div :class="[{ 'bg-secondary': !groupColor }, `bg-${groupColor}`]" class="col-2 group-indicator p-0 m-0 group-indicator-height-middle ps-1">
               </div>
               <div class="col-5 ms-0 me-1">
                 <div class="">

                   <div class="dropdown">
                     <a href="#" class="dropdown-toggle text-decoration-none text-secondary p-0"
                       type="button" :id="`task-context-${groupId}`" data-bs-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false">
                       <i class="mdi mdi-dots-vertical"></i>
                     </a>
                     <div class="dropdown-menu p-0 mt-2" :aria-labelledby="`task-context-${groupId}`" style="height: 31px">

                       <!-- level #2 -->
                       <div class="dropdown dropend">
                         <!-- Show columns -->
                         <a class="dropdown-item dropdown-toggle ms-0" href="#" id="dropdown-layouts"
                           data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                           :class="{ 'disabled': !hasHiddenItems, 'avatar-opacity': !hasHiddenItems }"
                            @mouseover="showHiddenItems = true"><span
                             class="d-flex align-items-center justify-content-start"><span>Mostrar
                               coluna</span> <i class="bx bx-chevrons-right"></i></span></a>
                         <div class="dropdown-menu ml-100 top-n11 p-0" aria-labelledby="dropdown-layouts" :class="{ 'd-none': !hasHiddenItems }" v-if="showHiddenItems">
                           <span v-for="(hiddenItem, keyHiddenItem) in columnsHiddenItems"
                             :key="keyHiddenItem">
                             <a @click="localUnhideColumn(hiddenItem.id)" class="dropdown-item h-4vh" href="#"
                               v-if="hiddenItem.hidden">
                               <span class="d-flex align-items-center justify-content-start"><div class="col">{{
                                 hiddenItem.name
                               }}</div><!--b-col cols="2" class="d-flex align-items-center"><i class="bx bx-show"></i></div --></span>
                             </a>
                           </span>

                         </div>
                       </div><!-- /level #2 -->
                     </div>
                   </div>
                 </div>
               </div>

               <div class="col-6 d-flex align-items-center" style="padding-left: 7px;">
                   <b-checkbox class="cell-chekbox select-all rem-size-2" :id="`select_all_${groupId}`"  @change="selectAllTasks" :value="{group_id: groupId, selected: true}" :unchecked-value="{group_id: groupId, selected: false}" :checked="selectAll && selectAllTaskGroupId == groupId">
                 </b-checkbox>
               </div>
           </div>
         </div>
       </div>
       <div class="col">
         <draggable :group="`list-${groupId}`" v-model="localColumns"  :disabled="!workflow.move_columns"
           class="row h-100 row-normalizer flex-nowrap" draggable=".headeritem" handle=".drag"
           v-bind="dragOptions" @start="drag = true"
           @end="updateWorkflowHeaderSequence(columns, $el)">
           <ResizeCol v-for="(col, colKey) in columns"
             @isDragging="startResizeColumn($event, col.id)"
             @dragging="resizeColumn($event, col.id, col.workflow_column_type_id)"
             :width="col.width"
             :maxWidth="col.workflow_column_type_id == 9 ? 682 : 400"
             :minWidth="col.workflow_column_type_id == 9 ? 282 : 168"
             :ref="`resizer_component_width_${col.id}`"
             :key="col.name"
             class="resize-cell-base resizer-main border-start border-end-header col-normalizer cell-height header-colors"
             :class="[`resizer_component_width_${col.id}`, { 'd-none': col.hidden, 'left-freez left-140 ': colKey == 0, 'mnw-col headeritem drag': col.workflow_column_type_id != 9, 'mnw-col-title': col.workflow_column_type_id == 9 && true, 'show-fix': col.id == ordination.columnId}]"
             :data-coll-type="col.workflow_column_type_id"
             >
             <div class="col-2 d-flex justify-content-start align-items-center header-option-field" :class="{'header-option-base': ordinationEditShowComputed || columnsProcessing.loadColumnId == col.id }">
                <!-- href="#" @click.prevent="sortTaskBy($event, col.id, col.workflow_column_type_id, !ordination.orderAsc)" -->
               <a
                   href="#" @click.prevent="ordinateTasks($event, col, ordination, true )"
                   class="btn-link text-decoration-none fs-6 text-white header-ordination position-absolute option-field-ordination-button"
                   :class="{'show-fix': col.id == ordination.columnId}"
                    v-if="!ordination.load"
                   >
                   <!-- <i :class="[{ 'bx bx-chevron-right': !group.show_tasks || hideAllTasks }, { 'bx bx-chevron-down': group.show_tasks || !hideAllTasks }, `text-${groupColor}`]"></i> -->
                   <i :class="[{ 'bx bx-chevron-up text-white':  col.id == ordination.columnId && !ordination.orderAsc }, { 'bx bx-chevron-down text-white':  col.id == ordination.columnId && ordination.orderAsc }, { 'bx bx-chevron-down':   col.workflow_column_type_id != ordination.columnId }, 'fw-bold fs-5']"></i>
               </a>
               <span class="bx bx-loader-alt bx-spin load-hidden" :class="{'ordination-load': ordination.load || columnsProcessing.loadColumnId == col.id}"></span>
               <span
                 class="clean badge bg-hover-info bg-option-field-badge text-dark rounded-pill clean-badge header-ordination-clean d-none"
                 @click="cleanOrdination"
                 >Limpar</span>
               <span
                 class="save badge bg-hover-info bg-option-field-badge text-dark rounded-pill save-badge header-ordination-save d-none"
                 :class="{'visibility-hidden': workflow.order_by_column_id == ordination.columnId && workflow.order_desc == !ordination.orderAsc}"
                 @click="saveOrdinationSequence(col, !ordination.orderAsc)"
                 >Salvar</span>

             </div>
             <div class="col-8 d-flex justify-content-center align-items-center h-100" @click.prevent="ordinateTasks($event, false)">
               <span class="overflow-text text-center text-white-solid rem-size-2">{{
                 col.name
               }}</span>
             </div>
             <div class="col-2 d-flex justify-content-end align-items-end header-option-field">
               <b-dropdown lazy right
                 variant="btn-link text-decoration-none fs-6 text-secondary justify-content-end p-0"
                 class="header-option-button" id="header-option-button" :menu-class="{'right-0': colKey + 2 >= columns.length}">
                 <template v-slot:button-content>
                   <i class="dripicons-dots-3 col-icon"></i>
                 </template>

                 <b-dropdown-item @click="changeColumn(col)" class="p-0 rem-size-2"><i
                     class="fas fa-pencil-alt"></i>
                   <span class="pl-3">Editar</span>
                 </b-dropdown-item>
                 <b-dropdown-divider
                   v-if="col.workflow_column_type_id != 9 && col.workflow_column_type_id != 2 && col.workflow_column_type_id != 3">
                 </b-dropdown-divider>
                 <b-dropdown-item @click="localRemoveColumn(col.id)" class="p-0 rem-size-2"
                   v-if="col.workflow_column_type_id != 9 && col.workflow_column_type_id != 2 && col.workflow_column_type_id != 3 && !col.locked">
                   <i class="fas fa-trash-alt"></i> <span class="pl-3">Excluir</span>
                 </b-dropdown-item>
               </b-dropdown>
             </div>

           </ResizeCol>
           <div
             class="overflow-add-col p-0 drag col d-flex justify-content-center align-items-center">
             <b-button @click="changeColumn(null)"
               variant=" d-flex justify-content-center text-decoration-none fs-6 p-0 text-secondary">
               <i class="fas fa-plus-square rem-size-2"></i>
             </b-button>
           </div>
         </draggable>
       </div>

        <!-- add col modal -->
        <!-- <b-modal id="modal-set-item" size="xl" :title="modalTitle" scrollable title-class="font-18" hide-footer>
            <SetItem :workflowId="workflow.id" :colId="selectedColId" />
        </b-modal> -->

     </div>
</template>

<script>
    import draggable from "vuedraggable";
    import { ResizeCol } from "custom-vue-resizer";
    import { mapActions, mapState } from "vuex";
    import { columnsComputed } from "../../../state/helpers";
    // import SetItem from "@components/workflows/management/column.vue";

    // setSelectAllTasks
    export default {
        components: {
            draggable,
            ResizeCol,
            // SetItem

        },
        data() {
            return {
                modalTitle: '',
                selectedColId: null,
                drag: false,
                resizeColStatus: false,
                showHiddenItems: false,
                resizerGuide: 0,
                ordinationEditShow: false,
                localWorkflow: {
                    order_by_column_id: null,
                    order_desc: false
                }
                // ordination:{
                //   load: false,
                //   ordinate: false,
                //   orderAsc: true,
                //   columnId: 0,
                //   typeId: 0
                // },
            }
        },
        props:['workflow', 'hideTasks', 'groupColor', 'groupId'],
        computed:{
            ...columnsComputed,
            ...mapState('tasks', {
                selectAll: (state) => state.selectAll,
                selectAllTaskGroupId: (state) => state.selectAllTaskGroupId,
                ordination: (state) => state.ordination,
                filter: (state) => state.filter,
            }),
            hideAllTasks() {
                return this.hideTasks;
            },
            hasHiddenItems() {
                return !!this.columns.find((column)=> column.hidden);
            },
            columnsHiddenItems() {
                return this.columns.filter((column)=> column.hidden);
            },
            ordinationEditShowComputed() {
                return this.ordinationEditShow;
            },
            dragOptions() {
                return {
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            },
            localColumns: {

                get() {
                    return this.columns
                },
                set(value) {
                    this.setColumns(value)
                }
            }
        },
        methods: {
            ...mapActions('columns', ['updateColumnWidth', 'updateColumnSequence', 'setColumns', 'unhideColumn', 'deleteColumn', 'removeColumn']),
            ...mapActions('tasks', ['setSelectAllTasks', 'updateTasksColumnSequence', 'setOrdinationTasks', 'sortTasks', 'saveCurrentTaskSequence', 'removeColumnValueByColumnId', 'setFilter']),
            ...mapActions('workflows', ['updateWorkflow']),

            changeColumn(col) {
                let title = "Nova Coluna";
                let colId = null;
                let type = null;

                if(col) {
                    if(col.id) {
                        title = "Editar Coluna";
                        colId = col.id;
                        type = col.workflow_column_type_id
                    }
                }

                // let title = col.id ? "Editar Coluna" : "Nova Coluna";
                // let colId = col.id ? col.id : null;
                // let type = col.workflow_column_type_id ? col.workflow_column_type_id : null ;
                this.$emit('changeColumn', colId, type,  title);
            },
            async localRemoveColumn(columnId) {
                let response = await this.deleteColumn(columnId);
                if(response) {
                    this.removeColumnValueByColumnId(columnId)
                    this.removeColumn(columnId);
                }
            },

            selectAllTasks(data) {
                setTimeout(()=>{
                    let content = {
                        value: data.selected,
                        groupId: data.group_id
                    }
                    this.setSelectAllTasks(content);
                }, 10);
            },
            ordinateTasks(event, column, ordination, show = true){
                if(typeof ordination == 'undefined') return
                this.$el.querySelectorAll('.header-option-base').forEach((element)=> element.classList.remove('header-option-base'))
                this.ordinationEditShow = show
                var element = event.target.closest('.header-option-field');
                if(element) {
                    var clean = element.querySelector('.clean');
                    var save = element.querySelector('.save');
                    setTimeout(()=>{
                        if(show) {
                            element.classList.add('header-option-base');
                            clean.classList.remove('d-none');
                            save.classList.remove('d-none');

                        }
                    }, 300);
                }

                let data = {
                    load: false,
                    ordinate: true,
                    orderAsc: !ordination.orderAsc,
                    columnId: column.id,
                    typeId: column.workflow_column_type_id
                }

                this.setOrdinationTasks(data)
                let filter = JSON.parse(JSON.stringify(this.filter));
                filter.orderBy = column.id;
                filter.orderDesc = !ordination.orderAsc;
                filter.workflowId = this.workflow.id;
                this.setFilter(filter);
                // this.sortTasks();

            },
            cleanOrdination(event, updateElement = true){

                let data = {
                    orderAsc: true,
                    columnId: 0,
                    typeId: 0
                }

                let workflow = JSON.parse(JSON.stringify(this.workflow));
                workflow.order_by_column_id = null;
                workflow.order_desc = false;
                workflow.workflow_id = workflow.id;
                this.updateWorkflow(workflow)

                this.setOrdinationTasks(data);
                let filter = JSON.parse(JSON.stringify(this.filter));
                filter.orderBy = data.columnId;
                filter.orderDesc = data.orderAsc;
                filter.workflowId = this.workflow.id;
                this.setFilter(filter);
                // this.sortTasks();

                this.$el.querySelectorAll('.header-option-base')
                    .forEach((element)=> element.classList.remove('header-option-base'));
            },
            saveOrdinationSequence(column, desc = false) {
                this.saveCurrentTaskSequence();

                let workflow = JSON.parse(JSON.stringify(this.workflow));
                workflow.order_by_column_id = column.id;
                workflow.order_desc = desc;
                workflow.workflow_id = workflow.id;
                this.updateWorkflow(workflow)

                // setTimeout(()=>{
                //     this.$el.querySelectorAll('.header-option-base')
                //         .forEach((element)=> element.classList.remove('header-option-base'));
                // }, 500);
            },
            localUnhideColumn(columnId) {
                this.showHiddenItems = false;
                this.unhideColumn(columnId);

            },
            updateWorkflowHeaderSequence(columns, $el) {
                let data = [];
                columns.forEach((header, key) => {
                    data.push({ header_id: header.id, sequence: key })
                });

                this.updateColumnSequence();
                this.updateTasksColumnSequence(data);

            },
            resizeColumn(e, colId, type) {

                this.resizeColStatus = true;
                this.resizerGuide = e;
                // if(type == 9){
                //     if(window.document.querySelector('.resizer_width_title')) {
                //         window.document.querySelectorAll('resizer_width_title').forEach((element, key)=>{
                //             element.style.width = `${e}px`;
                //         });
                //     }

                //     if(e < 282){
                //         this.resizerGuide = 282;
                //     }
                //     if(e > 682){
                //         this.resizerGuide = 682;
                //     }
                // } else {
                //     if(e < 168){
                //         this.resizerGuide = 168;
                //     }
                //     if(e > 400){
                //         this.resizerGuide = 400;
                //     }
                // }


                if (type == 9) {
                    if (window.document.querySelectorAll('.resizer_width_title')) {
                        window.document.querySelectorAll('.resizer_width_title').forEach((element, key) => {
                            element.style.width = `${e}px`;
                        });
                    }
                    if (window.document.querySelectorAll('.resizer_width_title_subtask')) {
                    window.document.querySelectorAll('.resizer_width_title_subtask').forEach((element, key) => {
                        element.style.width = `${e - 42}px`;
                    });
                    }
                }

                window.document.querySelectorAll(`.resizer_component_width_${colId}`).forEach((component, key) => {
                    component.style.width = `${e}px`;
                });



                window.document.querySelectorAll(`.resizer_width_${colId}`).forEach((element, key) => {
                    if (element.classList.value.indexOf('mnw-col-title-sub') > -1) {
                    element.style.width = `${e - 42}px`;
                    } else {
                    element.style.width = `${e}px`;
                    }
                });

                // Resize the status and list dropdown to cell width
                var menuDropdowns = window.document.querySelectorAll(`.menu-class-dropdown_${colId} .options-item-main`);
                if (menuDropdowns) {
                    for (var i = 0; i < menuDropdowns.length; i++) {
                    menuDropdowns[i].style.width = `${e - 10}px`;
                    }
                }
            },
            startResizeColumn(e, colId) {

                if (e) {
                    if ( window.document.querySelectorAll(`.resizer_width_${colId}`)) {
                        window.document.querySelectorAll(`.resizer_width_${colId}`).forEach((element, key) => {
                            element.classList.add('resizer_border_right');
                        });
                    }

                    window.document.querySelectorAll(`.resizer_component_width_${colId}`).forEach((component, key) => {
                        component.classList.add('resizer_border_right');
                        component.classList.remove('header-colors');
                    });

                    if (this.$el.querySelectorAll('.resizer_width_title_subtask')) {
                        this.$el.querySelectorAll('.resizer_width_title_subtask').forEach((element, key) => {
                            element.classList.add('resizer_border_right');
                        });
                    }

                } else {
                    if ((this.resizerGuide > 0)) {
                        // save width here
                        this.localUpdateColumnWidth(colId, this.resizerGuide)
                    }

                    this.resizeColStatus = false
                    if ( window.document.querySelectorAll(`.resizer_width_${colId}`)) {
                        window.document.querySelectorAll(`.resizer_width_${colId}`).forEach((element, key) => {
                            element.classList.remove('resizer_border_right');
                        });
                    }

                    window.document.querySelectorAll(`.resizer_component_width_${colId}`).forEach((component, key) => {
                        component.classList.remove('resizer_border_right');
                        component.classList.add('header-colors');
                    });

                    if (window.document.querySelectorAll('.resizer_width_title_subtask')) {
                        window.document.querySelectorAll('.resizer_width_title_subtask').forEach((element, key) => {
                            element.classList.remove('resizer_border_right');
                        });
                    }

                }

                this.resizerGuide = 0;
            },

            async localUpdateColumnWidth(colId, width) {
                let response = await this.updateColumnWidth({colId, width});
                if(response) {

                }

            },
        },
    }
</script>

<style lang="scss" scoped>
.border-end-header {
    border-right: 1px solid #32394e;
}
.visibility-hidden {
    visibility: hidden;
}
</style>
<style>

.header-option-button, .option-field-ordination-button, .header-ordination, .header-ordination-clean, .header-ordination-save, .load-hidden{
    display: none;
}

.resize-cell-base:hover * .header-option-button, .resize-cell-base:hover * .header-ordination, .header-option-base  .load-hidden.ordination-load  {
    display: block;
}

.header-option-base .header-ordination-clean, .header-option-base .header-ordination-save {
    display: block;
}
.show-fix:hover * .clean, .show-fix:hover * .save {
    display: block !important;
}
/*
.load-hidden {
    display: none;
}
.header-option-base  .load-hidden.ordination-load {
    display: unset !important;
} */

/* .resize-cell-base:hover * #header-option-button, .resize-cell-base:hover * .header-ordination, .resize-cell-base:hover * .header-ordination-clean, .resize-cell-base:hover * .header-ordination-save{
    display: block;
}

.header-option-field .clean, .header-option-field .save, .header-option-field  .option-field-ordination-button  {
    display: none;
}

.header-option-base .clean, .header-option-base .save, .header-option-field:hover  .option-field-ordination-button {
    display: block;
} */

.headers-top-anchor {
    /* top: 70px; */
    top: 122px;
    transition: all 0.2s ease;
}
.container-group-top-distance-menu * .headers-top-anchor {
    /* top: 168px !important; */
    top: 177px !important;
}
</style>

<style src="../../../../css/show-view-scoped.css"></style>

